import { Accordion } from '@mui/material';
import { useEffect } from 'react';
import {
  KagamiEmbedddedAccordionPresentationProps,
  KagamiEmbeddedAccordionPresentationModel
} from '../model/KagamiEmbeddedAccordionPresentationModel';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiEmbeddedFormStyle } from '../style/KagamiEmbeddedAccordionPresentationStyle';
import { useKagamiFormModelState } from '../../../../form-presenation/model/KagamiFormPresentationModel';
import { getFormModel } from '../../../../../controller/KagamiFormController';
import { handleSingleEmbedFormLoad, isViewTemplate } from '../../../../../service/FormService';
import { InitialEmbedHeader, KagamiEmbedAccordionContent } from '../builder/EmbeddedFormBuilder';
import { buildNestedEmbedPresentation } from '../../../../FormBuilder';
import { getPresenationIdFromPresentation } from '../../../../../../../service/PresentationService';
import delay from 'lodash/delay';

export const buildEmbeddedAccordionComponent = (
  kagamiEmbeddedAccordionModel: KagamiEmbeddedAccordionPresentationModel
) => {
  return (
    <KagamiEmbeddedAccordionPresentation
      key={getPresenationIdFromPresentation(kagamiEmbeddedAccordionModel.presentation)}
      embeddedAccordionPresentationModel={kagamiEmbeddedAccordionModel}
    />
  );
};

const useStyles = makeStyles(KagamiEmbeddedFormStyle);

const KagamiEmbeddedAccordionPresentation = (props: KagamiEmbedddedAccordionPresentationProps) => {
  let classes = useStyles();
  const pruleId = getPresenationIdFromPresentation(props.embeddedAccordionPresentationModel.presentation);
  let embeddedFormstate = useKagamiFormModelState(props.embeddedAccordionPresentationModel.createState);
  props.embeddedAccordionPresentationModel.state = embeddedFormstate;
  getFormModel().formControlStates[props.embeddedAccordionPresentationModel.formEntityId] = embeddedFormstate;
  buildNestedEmbedPresentation(props.embeddedAccordionPresentationModel);

  useEffect(()=>{
    /// Note : Making onload call for single embed because we made the accordion by default expanded
    if(!props.embeddedAccordionPresentationModel.isNested && !isViewTemplate() && props.embeddedAccordionPresentationModel.visible){
     delay(() => {
      handleSingleEmbedFormLoad(props.embeddedAccordionPresentationModel,props.embeddedAccordionPresentationModel.isFirstOnloadCall)
      props.embeddedAccordionPresentationModel.isFirstOnloadCall = false;
     },200)
    }
  },[])

  /// Note : Removed state binding of readonly for embed accordion to avoid unnecessary rendering of embed content
  if (!embeddedFormstate.isVisible) {
    return null;
  } else {
    return (
      <div key={pruleId} className={classes.root}>
        <Accordion
          key={pruleId}
          elevation={0}
          className={classes.accordion}
          onChange={(event : any,expanded : boolean)=> {
            if(expanded && !props.embeddedAccordionPresentationModel.isNested && !isViewTemplate()){
              embeddedFormstate.setExpanded(expanded)
              handleSingleEmbedFormLoad(props.embeddedAccordionPresentationModel,props.embeddedAccordionPresentationModel.isFirstOnloadCall)
              props.embeddedAccordionPresentationModel.isFirstOnloadCall = false;
            }
            else{
              embeddedFormstate.setExpanded(expanded)
            }
          }}
          defaultExpanded = {true}
        >
          <InitialEmbedHeader
            key={pruleId}
            embeddedAccordionPresentationModel={props.embeddedAccordionPresentationModel}
            classes={classes}
            readOnly = {props.embeddedAccordionPresentationModel.readonly}
          />
          <KagamiEmbedAccordionContent
            key={pruleId}
            embeddedAccordionPresentationModel={props.embeddedAccordionPresentationModel}
            classes={classes}
            readOnly={props.embeddedAccordionPresentationModel.readonly}
          />
        </Accordion>
      </div>
    );
  }
};
