import { KagamiTabNavigationModel } from '../model/KagamiTabNavigationModel';
import buildTabs from '../ui/KagamiTabNavigation';

let tabModel: KagamiTabNavigationModel;

export const buildNavigationTabs = (tabnavigationModel: KagamiTabNavigationModel) => {
  tabModel = tabnavigationModel;
  return buildTabs(tabnavigationModel);
};

export function getTabNavigationModel() {
  return tabModel;
}

export function setTabNavigationModel(tabnavigationModel: KagamiTabNavigationModel) {
  tabModel = tabnavigationModel;
}
