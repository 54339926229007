import { createState } from '@hookstate/core';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, InputAdornment, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { KagamiStateManager } from '../../../../../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../../../../../constants/SystemConstants';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import { getProcessName } from '../../../../../../../../../../navigator/builder/Menu/controller/KagamiMenuController';
import { makeOnFilterCall } from '../../../../../../../service/ProcessService';
import { KagamiSelectFieldStyle } from '../../../../../../controls/select/styles/KagamiSelectFieldStyle';
import { getKagamiListModel } from '../../../../../controller/KagamiListController';
import { KagamiListModel } from '../../../../../model/KagamiListModel';
import { searchRecord } from '../../../../../service/kagamiListService';
import { KagamiGridModel } from '../../../../grid/model/KagamiGridModel';
import { getKagamiListFilterModel } from '../../../controller/KagamiListFilterController';
import { KagamiListFilterModel, useListFiltersState } from '../../../model/KagamiListFilterModel';
import { getFilterTypeIfHavingDatasource } from '../util/kagamiPresentationFilterUtil';
import { GetListFilterListProps, KagamiPresentationFilterModel, usePresentationFiltersState } from '../model/KagamiPresentationFilterModel';
import { kagamiPresentationFilterstyle } from "../styles/KagamiPresentationFilterStyle"
import { KagamiPresentationNumberFilterBuilder } from './KagamiPresentationNumberFilterBuilder';

export const getListFiltersList = (kagamiListModel: KagamiListModel, kagamiPresentationFilterModel: KagamiPresentationFilterModel, kagamiListFilterModel: KagamiListFilterModel, setFilterData: any) => {
  return <GetFilterDataList kagamiListModel={kagamiListModel} kagamiPresentationFilterModel={kagamiPresentationFilterModel} kagamiListFilterModel={kagamiListFilterModel} setFilterData={setFilterData} />
}

const GetFilterDataList = (props: GetListFilterListProps) => {
  const [filterOptions, setFilterOptions] = useState(props.kagamiPresentationFilterModel.filterDataForSearchableAttributes);
  const [dataLoaded, setDataLoaded] = useState(false)
  const classes = makeStyles(kagamiPresentationFilterstyle)();
  const { kagamiListModel, kagamiPresentationFilterModel, kagamiListFilterModel } = props;
  const listFilterAppliedState = KagamiStateManager.getStateToHolder(SystemConstants.CLEAR_LIST_FILTER_STATE);
  kagamiPresentationFilterModel.state = usePresentationFiltersState(createState(kagamiPresentationFilterModel));

  let processName = kagamiListModel.processName;
  let presentationId = kagamiListModel.presentation.presentationId;
  let filterAttrName: string = kagamiPresentationFilterModel.attributeName;
  let listModelPresentationId = kagamiListModel.presentation.presentationId;
  let searchObj = kagamiListFilterModel.searchObj;
  let searchProcessName = kagamiPresentationFilterModel.state.searchProcessName;
  let checked = kagamiPresentationFilterModel.state?.checkedList;
  let numberFilterControlType: any = kagamiPresentationFilterModel?.dataRecordsForPresentationAttribute?.htmlControl;
  let lovFilterType: any = getFilterTypeIfHavingDatasource(kagamiPresentationFilterModel);

  if (numberFilterControlType !== "number" && lovFilterType !== "number") {
	  React.useEffect(() => {
	    if (CommonUtils.isEmpty(filterOptions)) {
	      let kagamiListModel: KagamiListModel = getKagamiListModel();
	      let runtimeInput: any;
	      if (kagamiListModel instanceof KagamiGridModel && CommonUtils.isNotEmpty(kagamiListModel.searchValue)) {
	        runtimeInput = [{ "id": "RuntimeInput", searchKey: kagamiListModel.searchValue }]
	      }
	      makeOnFilterCall(processName, presentationId, filterAttrName, runtimeInput, searchObj).then((filterResponse) => {
	        let filterDataObj = filterResponse?.data?.constructOutputData?.detailedObjects || {};
	        let dropdwonValues = filterDataObj[filterAttrName];
	        setFilterOptions(dropdwonValues);
	        props.setFilterData(dropdwonValues)
	        setDataLoaded(true)
	      })
	    }
	    else {
	      setDataLoaded(true)
	    }
	    if (CommonUtils.isNotEmpty(searchObj) && searchObj.hasOwnProperty(filterAttrName)) {
	      kagamiPresentationFilterModel.state.SetCheckedList(searchObj[filterAttrName]['like'])
	    }
	  }, []);
  }

  let validateItemSelected = (item: any) => moment(item, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid() ? moment(item).format('DD-MM-YYYY') : item;
  return (
    <div className={`${classes.filtersListBody} ${classes.numberFilterListBody}`}>
      {
        (numberFilterControlType === "number" || lovFilterType === "number") ? 
        <>
          <KagamiPresentationNumberFilterBuilder presentationNumberProps = {props}/>
        </>
        :
        <>
          <div>
            <TextField
              placeholder="Search..." size="small"
              value={searchProcessName}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon
                      sx={{
                        color: (theme) => theme.palette.secondary.main
                      }}
                    ></SearchIcon>
                  </InputAdornment>
                )
              }}
              onChange={(e: any) => kagamiPresentationFilterModel.state.SetSearchProcessName(e.target.value)}
            />
          </div>
          <List className={classes.listItems}>

            {dataLoaded ?
              CommonUtils.isNotEmpty(filterOptions) ?
                filterOptions
                  .filter((item: any) => validateItemSelected(item).toString().match(new RegExp(searchProcessName, "i")))
                  .map((item: any) => {

                    const labelId = `checkbox-list-label-${item}`;

                    return (
                      <ListItem
                        key={item}
                        disablePadding
                      >
                        <ListItemButton role={undefined} onClick={handleToggle(validateItemSelected(item), filterAttrName, searchObj, listFilterAppliedState, props.kagamiListModel, listModelPresentationId, kagamiPresentationFilterModel,props.kagamiListModel.selectedFilterModel)}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              size='small'
                              checked={checked.indexOf(validateItemSelected(item)) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText className={classes.listItem} id={labelId} primary={validateItemSelected(item)} />
                        </ListItemButton>
                      </ListItem>
                    )
                  }) :
                <ListItem >
                  <Typography variant='caption' color="action">
                    NO OPTIONS
                  </Typography>
                </ListItem>
              :
              <div><FilterLoader /></div>}
          </List>
        </>
      }
    </div>
  );
};

export const FilterLoader = () => {
  const classes = makeStyles(kagamiPresentationFilterstyle)();
  return (
    <div className={classes.filterLoading}>
      <CircularProgress className={classes.filterCircle} />
    </div>
  );
};

export const handleToggle = (value: string, filterAttrName: any, searchObj: any, listFilterAppliedState: any, kagamiListModel: any, listModelPresentationId: any, kagamiPresentationFilterModel: any,selectedFilterModel:any) => () => {
  let  selectedFilterState = KagamiStateManager.getStateToHolder(SystemConstants.SELECTED_FILTER_STATE);
  let listFiltersModel = getKagamiListFilterModel();
  let checked = kagamiPresentationFilterModel.state.checkedList;
  const currentIndex = checked.indexOf(value);
  console.log("filterAttrName", filterAttrName)
  const newChecked = [...checked];  
  if (filterAttrName in searchObj) {
    console.log('This key exist.');
    if (currentIndex === -1) {
      newChecked.push(value);
      searchObj[filterAttrName]['like'].push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      searchObj[filterAttrName]['like'].splice(currentIndex, 1);
    }
  } else {
    console.log('This key does not exist.');
    searchObj[filterAttrName] = {}
    searchObj[filterAttrName]['like'] = [];
    searchObj[filterAttrName]['between'] = [];
    if (currentIndex === -1) {
      newChecked.push(value);
      searchObj[filterAttrName]['like'].push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      searchObj[filterAttrName]['like'].splice(currentIndex, 1);
    }
  }
  searchObj[filterAttrName]['like'] = newChecked;
  kagamiPresentationFilterModel.state.SetCheckedList(newChecked);
  settingLabelForFilter(filterAttrName, value, selectedFilterState);
  if (newChecked.length > 0) {
    if (listFiltersModel) {
      listFiltersModel.presentationFiltersApplied.push(kagamiPresentationFilterModel);
    }
  }

  let falsyArray: any[] = [];
  const allFilterkeys = Object.keys(searchObj);
  for (let filterKey of allFilterkeys) {
    searchObj[filterKey]['like'] && searchObj[filterKey]['like'].length > 0 ? falsyArray.push('true') : falsyArray.push('false');
  }

  kagamiListModel.pageNumber = 1;
  CommonUtils.isNotEmpty(falsyArray) && falsyArray.includes('true')
    ? listFilterAppliedState.SetIsEnabled(true) : listFilterAppliedState.SetIsEnabled(false);
  searchRecord(kagamiListModel, listModelPresentationId, null, null, searchObj, false);
};

export function settingLabelForFilter(
  filterAttrName: any, 
  value: any, 
  selectedFilterState: any
) {
  const selectedValues : any[] = JSON.parse(JSON.stringify(selectedFilterState.getSelectedFilterValue()));
  if(CommonUtils.isNotEmpty(selectedValues) && CommonUtils.isNotEmpty(selectedValues[filterAttrName])){
    const index = selectedValues[filterAttrName].indexOf(value);
    if (index !== -1) {
      selectedValues[filterAttrName].splice(index, 1);
      if (selectedValues[filterAttrName].length === 0) {
        delete selectedValues[filterAttrName];
      }
    } else {
      selectedValues[filterAttrName].push(value);
    }
  } else{
    selectedValues[filterAttrName] = [value];
  }
  selectedFilterState.setSelectedFilterValue(selectedValues);
}