import { createState } from '@hookstate/core';
import {
  BarReportModel,
  BarReportModelProps,
  useBarReportModelState
} from '../model/BarReportModel';
import { makeBar } from '../service/BarReportService';
import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import { CircularProgress } from '@mui/material';

export const BuildBarReportView = (barReportModel: BarReportModel) => {
  // return <>In progress</>
  return (
    <KagamiBarChartReportView key={'bar-chart-report'} barReportModel={barReportModel}></KagamiBarChartReportView>
  );
};


const KagamiBarChartReportView = (props: BarReportModelProps) => {
  let barReportModel = props.barReportModel;
  const chartComponent:any = React.useRef(null);
  let barChartState = useBarReportModelState(createState(barReportModel));
  barReportModel.state = barChartState;

  React.useEffect(() => {
    getReportDataForDynamicReports(props.barReportModel)
  }, [])

  const containerRef = React.useCallback((node:any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => { 
      chartComponent.current?.chart.reflow();
    });
    resizeObserver.observe(node);
  }, []);
  
  if(!barChartState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" />
  }else{
    if(barReportModel.reportData){
  const barConfig :any = makeBar(barReportModel);

  return (
    <div ref={containerRef} className="ag-theme-alpine dashboard-highchart kgm-report-hw">
         <Chart ref={chartComponent} highcharts={Highcharts} options={barConfig} />
      </div>
  );
}else{
  return <p className='NoDataFound'>No Data Found</p>
}
  }
};
