import { State, useState } from '@hookstate/core';

export class EmbeddedRowCountModel {
  dataCount: any;
  state: any;
  isNested: any;
  formEntityId: any;
  presentationType: string;
  constructor() {
    this.dataCount = 0;
    this.formEntityId = '';
    this.isNested = false;
    this.presentationType = '';
  }
}

export interface EmbeddedDataRecordModelProps {
  embeddedDataRecordModel: EmbeddedRowCountModel;
}

export const wrapDataRecordsState = (state: State<EmbeddedRowCountModel>) => {
  const dataRecordsCountState = state;
  return {
    getDataCount() {
      return dataRecordsCountState.dataCount.get();
    },
    setDataCount(dataCountLegnth: any) {
      dataRecordsCountState.dataCount.set(dataCountLegnth);
    }
  };
};

// export const useEmbeddedDataRecordModelState =(state: State<EmbeddedRowCountModel>)=>wrapDataRecordsState(useState(state))

export const useEmbeddedDataRecordModelState = (state: State<EmbeddedRowCountModel>, formEntityId: any) => {
  let tabsState = wrapDataRecordsState(useState(state));

  // KagamiStateManager.addStateToHolder(formEntityId, tabsState);

  return tabsState;
};
