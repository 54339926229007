import * as React from 'react';
import { createState, useState } from '@hookstate/core';
import {
  DayBookReportModel,
  DayBookReportModelProps,
  useDayBookReportModelState
} from '../model/DayBookReportModel';
import { makeReport, makeLedgerData, getData, makeFooterRowData, handledColumnsBasedOnProjects } from '../service/DayBookReportService';
import { buildLedgerDetailsModal } from '../../modal/controller/ledgerDetailsModalController';
import { handleOnLedgerClick } from '../controller/DayBookReportController';
import { StaticReportActions } from '../../../../../../staticReportFilters/ui/StaticReportActions';
import { StaticReportGrid } from '../../../../../../staticReportFilters/ui/StaticReportGrid';


export const BuildDayBookReportView = (dayBookReportModel: DayBookReportModel) => {
  return (
    <DayBookReportView key="{'DayBookReportView'}" dayBookReportModel={dayBookReportModel} />
  );
};


const DayBookReportView = (props: DayBookReportModelProps) => {
  const onLedgerDetails = (row: any) => {
    let ledgerInputs: any = {
      invoiceNo: row.data.docno,
      transType: row.data.transtype,
      reportType: inputReportConfig.reportConfigSummary.type,
      currency: undefined,
      translation: props?.dayBookReportModel?.languageTranslation || {}
    };

    handleOnLedgerClick(ledgerInputs, row);
  }

  let runReportConfig: any = useState({
    loadReport: false,
    loadFilterData: true,
    showLoader: false,
    reportInfo: {}
  });

  let dayBookReportModel = props.dayBookReportModel;
  let translation: any = dayBookReportModel.languageTranslation || {};

  let dayBookState = useDayBookReportModelState(createState(dayBookReportModel));
  dayBookReportModel.state = dayBookState;
  let staticFilterData: any;
  if (dayBookReportModel?.reportData?.staticFilterData) {
    staticFilterData = dayBookReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo: any = {}
  if (dayBookReportModel?.reportData?.gridReportInfo) {
    gridReportInfo = dayBookReportModel.reportData['gridReportInfo'];
  }

  let inputReportConfig = (props.dayBookReportModel.reportConfig);
  React.useEffect(() => {
    runReport();
  }, [])

  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo = makeReport(dayBookReportModel, inputReportConfig);
    let ledgerData = await getData(dayBookReportModel, inputReportConfig);
    let rowData = await makeLedgerData(dayBookReportModel, ledgerData);
    let footerRowData = await makeFooterRowData(dayBookReportModel, ledgerData);
    handledColumnsBasedOnProjects(reportInfo, ledgerData);
    reportInfo['pinnedBottomRowData'] = footerRowData;
    reportInfo['rowData'] = rowData;
    reportInfo['dbLedgerData'] = ledgerData;
    const obj = Object.assign({}, reportInfo);
    props.dayBookReportModel.reportData['gridReportInfo'] = obj;
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }

  let config = { reportModel: dayBookReportModel, inputReportConfig, gridReportInfo, runReportConfig, onLedgerDetails };
  return (
    <div className="Report-MuiFormControl-root" style={{ width: '100%', background: '#fff', padding: '10px' }} >
      {buildLedgerDetailsModal(props.dayBookReportModel.modelPopup)}
      <StaticReportActions config={config} ></StaticReportActions>
      <StaticReportGrid config={config}></StaticReportGrid>
    </div>
  );
};
