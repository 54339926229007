import {IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { getValueForGridDate, setActiveRowNode } from '../../service/GridService';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import moment from 'moment';
import { SystemConstants } from '../../../../../../../../../../../../constants/SystemConstants';
import { parseDateToUTCString } from '../../../../../../../../../../../../utils/DateUtils';
import makeStyles from '@mui/styles/makeStyles';
import { cellRendererCustomComponentsStyle } from '../../style/CellRendererCustomComponents';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { getMaxDate, getMinDate } from '../../../../../controls/date/service/DateService';


 

export default forwardRef((props: any, ref: any) => { 
  setActiveRowNode(props,props.gridModel)
  const classes = makeStyles(cellRendererCustomComponentsStyle)() 
  let renderedValue = getValueForGridDate(props.node,props.attributePresentation);
  console.log("renderedValue", renderedValue);
  const [open, setOpen] = React.useState(false);
  // if(props.value =="Invalid date"){
    // props.value="";
  // }
  // if(props.value && props.value != 'Invalid date'){
  // // if(props.value){
  //   renderedValue = new Date(props.value);
  //   console.log("changed renderedValue", renderedValue);
  // }

  const [ displayValue, setDisplayValue ] = React.useState(renderedValue); 
  // const [ displayValue, setDisplayValue ] = React.useState(getValueForGridDate(props.gridModel,props.attributePresentation)) 
  
  let maxDate: any = props.attributePresentation.uiSettings ? getMaxDate(props.attributePresentation.uiSettings, props.kagamiDateModel) : null;
  let minDate: any = props.attributePresentation.uiSettings ? getMinDate(props.attributePresentation.uiSettings, props.kagamiDateModel) : null;

  function onChangeHandler(newValue: any) {
    console.log('called onchange handler'); 
    const newDate = moment(newValue).format(SystemConstants.DD_MM_YYYY);
    if(CommonUtils.isEmpty(props.gridModel.activeRowNode)){
      props.gridModel.activeRowNode = props.node;
    }
    setDisplayValue(newValue) ;
    props.node.data[props.column.colId] = parseDateToUTCString(newValue, props.kagamiDateModel.controlType);; 
    props.onCellValueChange(
      props.gridModel,
      props.gridModel.activeRowNode,
      props.attributePresentation.onChangeRequired,
      props.column.colId
    );
  }
  // console.log(typeof displayValue , displayValue);
  useImperativeHandle(ref, () => {
    console.log('imperative handler');
    return {
      getValue: () => {
        console.log('imperative handler in value');
        return displayValue;
      },
      afterGuiAttached: () => {
        console.log('imperative handler in gui');
        let renderedDateValue :any = '';
        // if(props.value =="Invalid date"){
          // props.value="";
        // }
        // if(props.value){
        if(props.value && props.value != 'Invalid date'){
          renderedDateValue = new Date(props.value);
        }
        setDisplayValue(renderedDateValue);
        // setDisplayValue(getValueForGridDate(props.gridModel,props.attributePresentation));
      },
      refreshEditor:()=>{
        let renderedDateValue :any = getValueForGridDate(props.node,props.attributePresentation)
        setDisplayValue(renderedDateValue);
      }
    };
  });
 
return (
      <div className={classes.dateEditorFeild}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        
        <DatePicker open={open} 
        
          // onOpen={() => { setDisplayValue(getValueForGridDate(props.gridModel,props.attributePresentation)) }}
          onClose={() => setOpen(false)}  
          value={displayValue}    
          views={['year', 'month' ,'day']}
          inputFormat= "dd/MM/yyyy"
          maxDate={maxDate}
          minDate={minDate}
          onChange={(newvalue:any) => {
            // const newDate = moment(newvalue).format('YYYY-MM-DD')
            // onChangeHandler(newDate)
          }} 
          onAccept={ (newvalue:any) => {
            // setDisplayValue(newvalue)
            //   const newDate = moment(newvalue).format('DD/MM/yyyy')
              onChangeHandler(newvalue)
          }}
          renderInput={(params:any) => 
          <TextField  focused  size="small" {...params}   fullWidth
          InputProps={{
            startAdornment: <CalendarMonthOutlinedIcon fontSize='small' color="action" />,
            endAdornment: (
              <InputAdornment position="end">
                {displayValue !== null
                  ? <IconButton size="small" onClick={() => {
                    // setDisplayValue(null)
                    onChangeHandler(null)
                    }} style={{marginRight:'-15px'}}>
                    <ClearOutlinedIcon fontSize='small' />
                  </IconButton>
                  : <></>
                }
              </InputAdornment>
            )
          }}
          
          onClick={() => setOpen(true)} 
          /> 
          
          }
          
           
        />
      </LocalizationProvider>
    </div>
)
});
