import { APIService } from './ApiService';
import { getDmsCreateUrl, getDMSDeleteURL, getDmsViewUrl, getDownloadFiles } from './URLService';

export class FileUrlService {
  static async uploadImage(entityId: string, attrName: string, filename: string) {
    let formData: FormData = new FormData();
    formData.append('entityId', entityId);
    formData.append('attrName', attrName);
    formData.append('fileDetail', filename);
    let url = getDmsCreateUrl();
    try {
      var response = await APIService.getData('POST', url, formData);
      console.log(response.data);
      if (response != null && response !== '') {
        return response;
      } else {
        var errorMap = [];
        errorMap.push('data', 'size error');
        return errorMap;
      }
    } catch (e: any) {
      console.log(e.message);
    }
  }

  static async deleteImage(docId: string) {
    var url = getDMSDeleteURL(docId);
    var response = APIService.getData('GET', url, null);
    console.log(response.data);
    if (response != null && response !== '') {
      return response;
    }
  }

  static async downloadImage(fileId: any) {
    var url = getDownloadFiles(fileId);
    var response = APIService.getData('GET', url, null);
    console.log(response.data);
    if (response != null && response !== '') {
      return response;
    }
  }
}
