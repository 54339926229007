import { State, useState } from "@hookstate/core";

export class LedgerDetailsModalModel{
    title:string;
    content:any;
    isOpen:boolean;
    handleOk:any;
    handleClose:any;
    maxWidth:any;
    pdfIconVisible:boolean;
    state:any;
    langTranslation: any;
    constructor(){
        this.title = '';
        this.isOpen = false;
        this.maxWidth = 'lg';
        this.pdfIconVisible = false;
    }
}

export interface LedgerDetailsModalModelProps{
    ledgerDetailsModalModel:LedgerDetailsModalModel;
}

export const wrapLedgerDetailsState = (state: State<LedgerDetailsModalModel>) => {
    let reportState = state;
    return {
        get isOpen(){
            return reportState.isOpen.get();
        },
        setOpen(isOpen:boolean){
            reportState.isOpen.set(isOpen)
        }
    }
}

export const useLedgerDetailsState = (state: State<LedgerDetailsModalModel>) => {
    return wrapLedgerDetailsState(useState(state))
}