import { CommonUtils } from "../../../../../../../../../../../utils/CommonUtils";
import { getEntityConsumedForListFilter, retrieveAttributePresentation } from "../../../../../service/PresentationService";
import { getKagamiListModel } from "../../../controller/KagamiListController";
import { KagamiListFilterModel } from "../model/KagamiListFilterModel";
import { buildClearFilterButton } from "./clearFilterButton/controller/KagamiClearFiltersController";
import { buildpresentationfilter } from "./kagamiPresentationFilter/controller/KagamiPresentationFilterController";
import { KagamiPresentationFilterModel } from "./kagamiPresentationFilter/model/KagamiPresentationFilterModel";
import { buildPresentationObjForWidget } from "./listPresentationFilterWidget/controller/ListFilterWidgetController";

export const listPresentationFilters = (kagamiListFilterModel: KagamiListFilterModel) => {
  return buildListPresentationFilters(kagamiListFilterModel);
}

export const buildListPresentationFilters: any = (kagamiListFilterModel: KagamiListFilterModel) => {
  const presentationFiltersComponents = [];
  const kagamiListModel = getKagamiListModel();
  let attributePresentations = retrieveAttributePresentation(kagamiListModel.onStartData);

  presentationFiltersComponents.push(
    attributePresentations.map((attributePresentation: any) => {
      let filterAttrName: string = attributePresentation.attrName;
      if (attributePresentation?.source?.parent?.for_attr) {
        let entityConsumed: string = getEntityConsumedForListFilter(attributePresentation.source.parent)
        let forAttributes: any[] = attributePresentation.source.parent.for_attr;
        if (CommonUtils.isNotEmpty(forAttributes)) {
          let index: number = forAttributes.length > 1 && forAttributes[1].type !== 'file' ? 1 : 0
          let displayAttribute: string = forAttributes[index].name;
          filterAttrName = entityConsumed.concat('.', displayAttribute)
        }
      }
      let presentationFilterModel: KagamiPresentationFilterModel = new KagamiPresentationFilterModel(
        attributePresentation,
        [],
        filterAttrName
      );
      return kagamiListFilterModel.isWidget ? buildPresentationObjForWidget(presentationFilterModel, kagamiListFilterModel) : buildpresentationfilter(presentationFilterModel, kagamiListFilterModel);
    })
  );
  if (!kagamiListFilterModel.isWidget) {
    presentationFiltersComponents.push(buildClearFilterButton(kagamiListFilterModel.clearFiltersModel, kagamiListFilterModel))
  }
  console.log('presentationFiltersComponents', presentationFiltersComponents)
  return presentationFiltersComponents;
};
