 export const ENV_URL = getUrl();
 export const ENV_DOMAIN = getDomain();

// export const ENV_URL = getUrl() || "https://192.168.1.51:12001/SwissGarnier/";
// export const ENV_URL = getUrl() || "https://intraruntime.kagamierp.com:12001/SwissGarnier/";
// export const ENV_URL = getUrl() || "http://tasktracker.swissgarnier.com/SwissGarnier-Live";
// export const ENV_DOMAIN = getDomain() || "SwissGarnier";

// export const ENV_URL = getUrl() || 'https://dcgn2local.kagamierp.com:12006/DCGN5_CHANGES/';
// export const ENV_URL = "https://dcgn2local.kagamierp.com/DCGN5_CHANGES/"
// export const ENV_URL = "https://dcgn.kagamierp.com/DCGN1/"
// export const ENV_DOMAIN = 'DCGN5_CHANGES';

// export const ENV_URL = getUrl() || "https://dev3.kagamierp.com:12008/HRMS_GROUP/";
// export const ENV_URL = getUrl() || "https://ess.kagamierp.com/HRMS_GROUP/"
// export const ENV_DOMAIN = getDomain() || "HRMS_GROUP";

// export const ENV_URL = getUrl() || "https://wdclonpremise.kagamierp.com:12003/kagami-generated_WDCL_WC/";
// export const ENV_DOMAIN = getDomain() || "kagami-generated_WDCL_WC";

// export const ENV_URL = getUrl() || "https://dcglonpremise.kagamierp.com:12001/kagami-generated_dcgl/";
// export const ENV_DOMAIN = getDomain() || "kagami-generated_dcgl";

// export const ENV_URL = getUrl() || "https://demo.kagamierp.com/ShilpaCare/";
// export const ENV_URL = getUrl() || "https://182.71.38.164:12003/kagami-generated_ShilpaCare/"
// export const ENV_DOMAIN = getDomain() || "ShilpaCare";

// export const ENV_URL = getUrl() || "https://intraruntime.kagamierp.com:12007/MAPL_CHANGES/";
// export const ENV_DOMAIN = getDomain() || "MAPL_CHANGES";

// export const ENV_URL = getUrl() || "https://dev2.kagamierp.com:12010/pnr_big_bang/";
// export const ENV_URL = getUrl() || "https://intraruntime.kagamierp.com:12003/PnR_live/"
// export const ENV_URL = getUrl() || "https://65.2.27.47:12002/PnR_live/"
// export const ENV_DOMAIN = getDomain() || "PnR_live";

// export const ENV_URL = getUrl() || "https://dev4.kagamierp.com:12004/ERP_Viswakrithi/";
// export const ENV_DOMAIN = getDomain() || "ERP_Viswakrithi";

// export const ENV_URL = getUrl() || "https://wdclonpremise.kagamierp.com:12003/WDCL_WC/";
// export const ENV_DOMAIN = getDomain() || "WDCL_WC";

// export const ENV_URL = getUrl() || "https://dct.kagamierp.com:12003/dct_live/";
// export const ENV_URL = getUrl() || "https://dct.kagamierp.com/dct_live/"
// export const ENV_DOMAIN = getDomain() || "dct_live";

// export const ENV_URL = getUrl() || "https://localonpremise.kagamierp.com:12001/TogoProjectTesting/";
// export const ENV_URL = getUrl() || "https://wacemlocal.kagamierp.com:12002/Togo_Project/";
// export const ENV_URL = getUrl() || "https://fortia.kagamierp.com/fortia_live/"
// export const ENV_URL = getUrl() || "https://fortia.kagamierp.com:12003/fortia_live/"
// export const ENV_DOMAIN = getDomain() || "TogoProjectTesting";

// export const ENV_URL = getUrl() || 'https://leocem.kagamierp.com:12002/LEOCEM_Live/';
// export const ENV_URL = getUrl() || "https://leocemlocal.kagamierp.com/LEOCEM_UAT/";
// export const ENV_DOMAIN = getDomain() || 'LEOCEM_Live';

// export const ENV_URL = getUrl() || "https://dcmdlocal.kagamierp.com:12001/DCM/";
// export const ENV_DOMAIN = getDomain() || "DCM";

// export const ENV_URL = getUrl() || "https://dev3.kagamierp.com:12001/SaaSKagamiVGS/";
// export const ENV_URL = getUrl() || "https://demo.kagamierp.com:12007/SaaSKagamiVGS/"
// export const ENV_URL = 'https://192.168.1.128:9099/SaaSKagamiVGS/';
// export const ENV_DOMAIN = getDomain() || 'SaaSKagamiVGS';

// export const ENV_URL = getUrl() || "https://vgs.kagamierp.com/HRMS_VGS/";
// export const ENV_DOMAIN = getDomain() || "HRMS_VGS";

// export const ENV_URL = getUrl() || 'https://intraruntime.kagamierp.com:12015/idc_live/';
// export const ENV_DOMAIN = getDomain() || "idc_live";

// export const ENV_URL = getUrl() || 'https://intraruntime.kagamierp.com:12012/HRMS_VGS/';
// export const ENV_DOMAIN = getDomain() || "HRMS_VGS";

// export const ENV_URL = getUrl() || 'https://localonpremise.kagamierp.com:12020/ERP_WBC/';
// export const ENV_DOMAIN = getDomain() || "ERP_WBC";

// export const ENV_URL = getUrl() || 'https://demo.kagamierp.com:12005/DAL/';
// export const ENV_DOMAIN = getDomain() || "DAL";

// export const ENV_URL = getUrl() || "https://95.216.201.5:12002/srinivasa_live/";
// export const ENV_DOMAIN = getDomain() || 'srinivasa_live';

// export const ENV_URL = getUrl() || "";
// export const ENV_DOMAIN = getDomain() || ;

function getUrl() {
  var uri = window.location;
  if (uri && uri.host && uri.pathname) {
    let domain: string = uri.pathname.split('/')[1] !== '' ? uri.pathname.split('/')[1] + '/' : '';
    var url: string = uri.protocol + '//' + uri.host + '/' + domain; //uri.pathname.split('/')[1]+'/';
    console.log('url is ' + url);
    return url.includes('localhost') ? '' : url;
  }
  return '';
}

function getDomain() {
  var uri = window.location;
  if (uri && uri.host && uri.pathname) {
    var domain: string = uri.pathname.split('/')[1];
    return uri.host.includes('localhost') ? '' : domain;
  }
  return '';
}
