import * as React from 'react';
import { createState ,useState } from '@hookstate/core';
import {
  TrialBalanceReportModel,
  TrialBalanceReportModelProps,
  useTrialBalanceReportModelState
} from '../model/TrialBalanceReportModel';
import { makeReport,makeReportData ,getData}  from '../service/TrialBalanceReportService';
import { StaticReportActions } from '../../../../../../staticReportFilters/ui/StaticReportActions';
import { StaticReportGrid } from '../../../../../../staticReportFilters/ui/StaticReportGrid';

export const BuildTrialBalanceReportView = (trialBalanceReportModel: TrialBalanceReportModel) => {
  return (
    <TrialBalanceReportView key={'trial-balance-report'} trialBalanceReportModel={trialBalanceReportModel}></TrialBalanceReportView>
  );
};


const TrialBalanceReportView = (props: TrialBalanceReportModelProps) => {

  let runReportConfig:any = useState({
    loadReport : false,
    loadFilterData:true,
    showLoader:false,
    reportInfo:{}
  });

  let trialBalanceReportModel = props.trialBalanceReportModel;
  let trialBalanceChartState = useTrialBalanceReportModelState(createState(trialBalanceReportModel));
  trialBalanceReportModel.state = trialBalanceChartState;
  let staticFilterData :any;
  if(trialBalanceReportModel?.reportData?.staticFilterData) {
    staticFilterData  = trialBalanceReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo:any ={}
  if(trialBalanceReportModel?.reportData?.gridReportInfo) {
    gridReportInfo  = trialBalanceReportModel.reportData['gridReportInfo'];
  }
  let inputReportConfig= (props.trialBalanceReportModel.reportConfig);
  
  React.useEffect(() => {
    runReport();
  }, [])

  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo =  makeReport(trialBalanceReportModel);
    let trailBalanceReportData =  await getData(trialBalanceReportModel,inputReportConfig)
    let rowData = await makeReportData(trialBalanceReportModel,trailBalanceReportData)
    reportInfo['rowData']= rowData;
    reportInfo['reportDBData']= trailBalanceReportData;
    const obj = Object.assign({}, reportInfo);
    props.trialBalanceReportModel.reportData['gridReportInfo'] = obj;
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }
  const gridRef:any = React.useRef();
  const expandAll = React.useCallback(() => {
    if(!trialBalanceReportModel.collapseAll){
      gridRef.current!.api.expandAll();
      trialBalanceReportModel.collapseAll=true;
    }else{
      gridRef.current!.api.collapseAll();
      trialBalanceReportModel.collapseAll=false;
    }
    
  }, []);
  let config = {reportModel : trialBalanceReportModel, inputReportConfig , gridReportInfo, runReportConfig , expandAll, gridRef};
  
  return (
    <div className="Report-MuiFormControl-root" style={{width:'100%' ,background: '#fff',padding: '10px'}}>
      <StaticReportActions config={config} ></StaticReportActions>
      <StaticReportGrid config={config}></StaticReportGrid>
      </div>
  );
};
