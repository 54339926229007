import { KagamiPresentationFilterModel } from '../model/KagamiPresentationFilterModel';

export const numericComparisonFilters: any = [
    { id: 'equal', label: 'Equal to', symbol: '=', type: 'number' },
    { id: 'notEqual', label: 'Not Equal to', symbol: '!=', type: 'number' },
    { id: 'lessThan', label: 'Less than', symbol: '<', type: 'number' },
    { id: 'lessThanEqual', label: 'Less than or equal to', symbol: '<=', type: 'number' },
    { id: 'greaterThan', label: 'Greater than', symbol: '>', type: 'number' },
    { id: 'greaterThanEqual', label: 'Greater than or equal to', symbol: '>=', type: 'number' },
];

export const numericComparisonRangeFilters: any = [
    { id: 'range-from', label: 'From', type: 'number', range: 'Min', symbol: ':' },
    { id: 'range-to', label: 'To', type: 'number', range: 'Max', symbol: ':' }
];

export const getFilterTypeIfHavingDatasource = (kagamiPresentationFilterModel: KagamiPresentationFilterModel) => {
    let attributePRule = kagamiPresentationFilterModel?.dataRecordsForPresentationAttribute || {};
    if (attributePRule.htmlControl === 'search' || attributePRule.htmlControl === 'select' || attributePRule.htmlControl === 'multiselect') {
        if (attributePRule?.displayAttributes?.length === 1) {
            let fieldName = attributePRule.displayAttributes[0];
            let fieldDetails = attributePRule.source?.parent?.for_attr || [];
            let fieldDetail = fieldDetails.find((fd: any) => fd.name === fieldName);
            if (fieldDetail) {
                return fieldDetail.type;
            }
        }
    }
    return 'text';
}