import { CommonUtils } from "../../../../../../../../../../utils/CommonUtils";

export class FileValidator {
  static  errorText:any;
  static validate( validations:any, file:any, allowedFiles:any, fileModel: any) {
    // let formModel : KagamiFormModel = getFormModel();
    // let controlValue : any = formModel.mainEntityId !== fileModel.entityId ? file : fileModel.controlValue
    if (Object.keys(validations).length !== 0) {
      FileValidator.errorText = null
      const validationOrder = ['notNull', 'fileFormat', 'fileSize'];
      for (const k of validationOrder) {
        if (validations[k]) {
          switch(k) {
            case 'notNull':
              FileValidator.notNullValidation(file, fileModel);
              break;

            case 'fileFormat' :
              if (file?.name) {
                FileValidator.formatValidation(allowedFiles, file, fileModel);
              }
              break;

            case 'fileSize':
              if (file instanceof Object) {
                let fileSize = file?.size ?? 0;
                let fileSizeInKb = fileSize/1024;
                FileValidator.fileSizeValidation(fileSizeInKb, validations[k]);
              }
              break;
    
            default :
                break;
          }
        }
      
        if(CommonUtils.isNotEmpty(FileValidator.errorText)) {
          break;
        }
      }
    }
    return FileValidator.errorText;
  }

    
static notNullValidation(value:any, fileModel:any) {
      value ? FileValidator.errorText = null : FileValidator.errorText = fileModel.controlDisplayName + 'field is mandatory';
}
static formatValidation(allowedFiles:any, file:any, fileModel: any) {
  let extension:string;
    if (allowedFiles.formats != "") {
      extension = file.name.substring(file.name.lastIndexOf('.') + 1);
      if (fileModel.isEmbddedField) {
        let fileFormats = allowedFiles.formats.split(',');
        let fileTypeFound = fileFormats.find((item: any) => item === extension);
        if (fileTypeFound) {
          FileValidator.errorText = null;
        } else {
          FileValidator.errorText = 'File Upload failed... ' + file?.name + ' file type is not supported'
          // .supports only ' + allowedFiles.formats;
        }
      } else {
        allowedFiles.formats.includes(extension.toLocaleLowerCase())
          ? (FileValidator.errorText = null)
          : (FileValidator.errorText =
              file?.name + ' file type is not supported. supports only ' + allowedFiles.formats);
      }
    } else {
      allowedFiles.formats.includes('')
        ? (FileValidator.errorText = null)
        : (FileValidator.errorText =
            file?.name + ' file type is not supported. supports only ' + allowedFiles.formats);
    }
  }

  static fileSizeValidation(fileSize: any, sizeMap: any) {
    if (CommonUtils.isNotEmpty(sizeMap['min']) && CommonUtils.isNotEmpty(sizeMap['max'])) {
      if (fileSize < Number(sizeMap['min']) || fileSize > Number(sizeMap['max'])) {
        FileValidator.errorText = `Please upload a file between `
            + `${sizeMap['min'] < 1024 ? sizeMap['min'] + ' KB' : (sizeMap['min']/1024).toFixed(2) + ' MB'}`
            + ` and ${sizeMap['max'] < 1024 ? sizeMap['max'] + ' KB' : (sizeMap['max']/1024).toFixed(2) + ' MB'}`;
      }
    } else {
      for (const size in sizeMap) {
        switch (size) {
          case 'min':
            FileValidator.errorText = CommonUtils.isNotEmpty(sizeMap[size]) && fileSize < Number(sizeMap[size]) ?
              `Please upload a file with a size of at least '${sizeMap[size] < 1024 ? sizeMap[size] + ' KB' : (sizeMap[size]/1024).toFixed(2) + ' MB'}`
            : null;
            break;
          case 'max':
            FileValidator.errorText = CommonUtils.isNotEmpty(sizeMap[size]) && fileSize > Number(sizeMap[size]) ?
               `Please upload a file less than' ${sizeMap[size] < 1024 ? sizeMap[size] + ' KB' : (sizeMap[size]/1024).toFixed(2) + ' MB'}`
            : null;
            break;
          }
        if(CommonUtils.isNotEmpty(FileValidator.errorText)) {
          break;
        }      
      }
    }
  }

}