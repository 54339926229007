import { KagamiStateManager } from '../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../constants/SystemConstants';
import { executeProcess } from '../../navigator/builder/Menu/controller/KagamiMenuController';
import { buildDashboard } from '../builder/dashboard/controller/KagamiDashboardController';
import { KagamiDashboardModel } from '../builder/dashboard/model/KagamiDashboardModel';
import {
  buildReportLandingPageController,
  getLandingPageModel
} from '../builder/reports/landingPage/controller/ReportsLandingPageController';
import { ReportLandingPageModel } from '../builder/reports/landingPage/model/ReportsLandingPageModel';
import { KagamiBodyModel } from '../model/KagamiBodyModel';
import { buildBodyComponent } from '../ui/KagamiBody';
import { getAllFavouriteReportsData } from '../builder/dashboard/service/DashboardService';
import { CommonUtils } from '../../../../../utils/CommonUtils';
import { getTabNavigationModel } from '../../header/Tabs/controller/KagamiTabNavigationController';
import { loadProcessContent } from '../builder/process/controller/KagamiProcessController';
import { appContext } from '../builder/process/service/PolicyExecutionService';
import { isEnableTabs } from '../builder/process/service/PresentationService';
import { getHomeModel } from '../../../controller/KagamiHomeController';
import { BodyContent } from '../../../model/KagamiHomeModel';

export function buildBody(bodyModel: KagamiBodyModel): JSX.Element {
  return buildBodyComponent(bodyModel);
}

export function renderBody(bodyModel: KagamiBodyModel) {
  if (bodyModel.processName == null) {
    getAllFavouriteReportsData(bodyModel.dashboardModel);
    renderDashboardPage(bodyModel.dashboardModel);
  } else {
    executeProcess(bodyModel.processName);
  }
}

export function renderDashboardPage(dashboardModel: KagamiDashboardModel) {
  if (isEnableTabs(appContext)) {
    const content: JSX.Element | null = buildDashboard(dashboardModel);
    KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(buildDashboard(dashboardModel));  
    let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
    let tabNavigationModel = getTabNavigationModel();
    let activeTab = JSON.parse(JSON.stringify(tabNavigationState.getActiveTab));
    if (activeTab === '') {
      activeTab = 'Dashboard';
    }
    if (activeTab) {
      let activeModel = dashboardModel;
      if (activeModel instanceof KagamiDashboardModel) {
        tabNavigationModel.tabState = {
          ...tabNavigationModel.tabState,
          [activeTab]: {
            content: content,
            activeModel: activeModel
          }
        };
      }
    }
  } else {
    KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(buildDashboard(dashboardModel));
  }
}

export function renderProcessPage(content: JSX.Element) {
  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(content);
}

export function renderReportPage(addTab?: any) {
  if (isEnableTabs(appContext)) {
    let reportLandingPageModel: ReportLandingPageModel = new ReportLandingPageModel();
    const content: JSX.Element | null = buildReportLandingPageController(reportLandingPageModel);
    if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
      loadProcessContent(content);
    } else {
      KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
        buildReportLandingPageController(reportLandingPageModel)
      );
    }
    let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
    let tabNavigationModel = getTabNavigationModel();
    let activeTab = JSON.parse(JSON.stringify(tabNavigationState.getActiveTab));
    if (activeTab === 'Reports') {
      let activeModel = getLandingPageModel();
      if (activeModel instanceof ReportLandingPageModel) {
        tabNavigationModel.tabState = {
          ...tabNavigationModel.tabState,
          [activeTab]: {
            content: content,
            activeModel: activeModel
          }
        };
      }
    }
  } else {
    if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
      KagamiStateManager.removeStateToHolder(SystemConstants.PROCESS_STATE);
    }
    let reportLandingPageModel: ReportLandingPageModel = new ReportLandingPageModel();
    KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
      buildReportLandingPageController(reportLandingPageModel)
    );
    // KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setNotificationIconVisible(false);
    // KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setReportIconVisible(false);
  }
  getHomeModel()?.state.setCurrentBodyContent(BodyContent.REPORTS);
  let reportLandingPageModel : ReportLandingPageModel = new ReportLandingPageModel()
  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
    buildReportLandingPageController(reportLandingPageModel)
  );
  // KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setNotificationIconVisible(false);
  // KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setReportIconVisible(false);
}

// unused code
export function renderReport(content: JSX.Element) {
  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(content);
}

// unused code
export function renderDataMigrationPage() {
  let reportLandingPageModel: ReportLandingPageModel = new ReportLandingPageModel();
  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
    buildReportLandingPageController(reportLandingPageModel)
  );
  // KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setNotificationIconVisible(false);
  // KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setReportIconVisible(false);
}
