import { KagamiBeanUtils } from '../../../../../../../../../utils/KagamiBeanUtils';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { loadProcessContent, onSubmitCall } from '../../../controller/KagamiProcessController';
import { KagamiProcessRequestModel } from '../../../model/KagamiProcessRequestModel';
import { EventType, KagamiSubProcessRequestModel } from '../../../model/KagamiSubProcessRequestModel';
import { RuntimeInput } from '../../../model/RuntimeInput';
import {
  getAttributeName,
  getEntityAssociatedAttribute,
  getEntityConsumedInAttribute,
  getEntityConsumed,
  isOnCancelRequiredForPresentation,
  getHtmlControl,
  isVisibleAttribute,
  extractDropdownOption,
  getMainEntityIdForPresentation,
  isTypeText,
  isLovTypeControl,
  isMatrisAxisX,
  differentCurrenyFormats,
  // differentCurrenyFormats
} from '../../../service/PresentationService';
import { updateErrorContent } from '../../../service/ProcessPageService';
import {
  onCancelSuccess,
  onChangeSuccess,
  onEmbedOnLoadSuccess,
  onSearchSuccess,
  onSubProcessCall
} from '../../../service/SubProcessService';
import { KagamiDateModel } from '../../controls/date/model/kagamiDateModel';
import { KagamiFileModel } from '../../controls/file/model/KagamiFileModel';
import { KagamiControlModel } from '../../controls/model/kagamiControlModel';
import { KagamiRadioModel } from '../../controls/radio/model/kagamiRadioModel';
import { KagamiSelectModel } from '../../controls/select/model/kagamiSelectModel';
import { KagamiTextModel } from '../../controls/textbox/model/kagamiTextModel';

import {
  resetEmbedFormPresentation,
  updateEmbeddedList
} from '../builder/embedded-presentation/builder/embedded-accordion-presentation/controller/KagamiEmbeddedAccordionPresentationController';
import { KagamiFormPresentationModel } from '../builder/form-presenation/model/KagamiFormPresentationModel';
import { PresentationHierarchy } from '../builder/hierarchy/PresentationHierarchy';
import {
  callLocalPolices,
  getFormModel,
  getPreviousFormModel,
  handleFormReset,
  setFormModel,
  showServerError
} from '../controller/KagamiFormController';
import { KagamiFormData } from '../model/formData';
import { KagamiFormModel } from '../model/KagamiFormModel';
import { convertDecimalValueToMinutes, isTypeDate } from '../../../../../../../../../utils/DateUtils';
import { v4 as uuidv4 } from 'uuid';
import { KagamiEmbeddedFormPresentationModel } from '../model/KagamiEmbeddedFormPresentationModel';
import {
  hideLoader,
  showLoader
} from '../../../../../../../../../../components/loader/controller/KagamiLoaderController';
import { getKagamiListModel } from '../../list/controller/KagamiListController';
import { KagamiGridModel } from '../../list/builder/grid/model/KagamiGridModel';
import { handleServerError, loadGroupContent } from '../../group/controller/KagamiGroupController';
import { KagamiExpandedEmbeddedPresentationModel } from '../builder/embedded-presentation/builder/expanded-embedded-presentation/model/KagamiExpandedEmbeddedPresentationModel';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import { KagamiNumberModel } from '../../controls/numberTextbox/model/KagamiNumberModel';
import { KagamiGroupModel } from '../../group/model/KagamiGroupModel';
import { getGroupModel } from '../../group/service/GroupService';
import { buildHierarchy } from '../builder/hierarchy/PresentationHierarchyBuilder';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { computeDisplayString } from '../../list/builder/grid/service/FieldHelper';
import { getDispPresentationRuleConfig } from '../../list/builder/grid/service/ProcessHelperService';
import { handleMatrixPresentation, isMatrixFormTrue } from '../builder/embedded-presentation/service/MatrixPresentationService';
import { KagamiEmbeddedAccordionPresentationModel } from '../builder/embedded-presentation/builder/embedded-accordion-presentation/model/KagamiEmbeddedAccordionPresentationModel';
import { onSearchCall, validateSelectTextBox } from '../../controls/select/controller/kagamiSelectController';
import { onTriggerClick } from '../../Triggers/service/KagamiTriggersService';
import debounce from 'lodash/debounce';
import { useRef } from 'react';
import _, { delay } from 'lodash';
import { showServerErrorPopUp } from '../../../../../../../../../controller/KagamiApplicationController';

export function getRecordId(kagamiFormModel: KagamiFormModel, formPresentationModel: KagamiFormPresentationModel) {
  let entityId: string = formPresentationModel.formEntityId;
  let recordId: string = formPresentationModel.formDataIdentifier;
  let formData: KagamiFormData = kagamiFormModel.formData;
  if (entityId === formData.mainEntityId) {
    if (formData) {
      recordId = formData.formData['id'];
    }
  } else if (recordId == null || recordId === '') {
    if (formPresentationModel.isListPresentation) {
      recordId = 'temp_' + uuidv4().toString().replace('-', '');
      formPresentationModel.hasChanges = true;
      addChildObjectInFormData(kagamiFormModel, entityId, recordId);
    } else {
      let parentEntityId = kagamiFormModel.formData.entityHierarchies.get(formPresentationModel.presentationId).parentEntity;
      let parent = getActiveFormRecord(kagamiFormModel, parentEntityId);
      if (parent && CommonUtils.isNotEmpty(parent[entityId])) {
        recordId = parent[entityId]['id'];
      } else {
        recordId = 'temp_' + uuidv4().toString().replace('-', '');
        if(CommonUtils.isEmpty(formPresentationModel.onLoadData)) formPresentationModel.onLoadData = {id : recordId}
        addChildObjectInFormData(kagamiFormModel, entityId, recordId);
      }
    }
  }
  else{
    /// Note : for single embed form if record id is there in model but it is not included in form data then we are adding this data to form data
    let embedFormPresentationModel : KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[entityId];
    if(embedFormPresentationModel && !embedFormPresentationModel.isListPresentation){
      addChildObjectInFormData(kagamiFormModel, entityId, recordId);
    }
  }
  return recordId;
}

function getChangeForFormModel(kagamiFormModel: KagamiFormModel, formPresentationModel: KagamiFormPresentationModel) {
  let changeFor;
  let entityId = formPresentationModel.formEntityId;
  let record = getActiveFormRecord(kagamiFormModel, entityId);
  if (record) {
    _getUnSyncedFormRecord(kagamiFormModel, entityId);
    changeFor = _getChangeForDetail(kagamiFormModel, entityId, 'id', record.get('id'));
  }
  return changeFor;
}

export function handleOnLoad2(
  kagamiFormModel: KagamiFormModel,
  formPresentationModel: KagamiFormPresentationModel,
  response: Map<string, string>
) {
  let changeFor;
  let entityId = formPresentationModel.formEntityId;
  let record = getActiveFormRecord(kagamiFormModel, entityId);
  if (record) {
    _getUnSyncedFormRecord(kagamiFormModel, entityId);
    changeFor = _getChangeForDetail(kagamiFormModel, entityId, 'id', record.get('id'));
  }
  renderOnLoadResponse(kagamiFormModel, changeFor);
}

function getControlValue(controlModel: KagamiControlModel, value: any) {
  let controlValue = value != null ? value : 'removed_';
  if (controlModel instanceof KagamiSelectModel && controlModel.controlType === 'multiselect') {
    let selectedValues = value.filter((item: any) => !item.id.includes('removed_'));
    controlValue = selectedValues;
  }
  return controlValue;
}

export function updateTotalHoursinPivot(kagamiFormModel: KagamiFormModel,control: KagamiControlModel,record: any){
  let formPresentationModel = kagamiFormModel.formControls[control.entityId]
  let attribute = control.attributeName;
  let controlValue = control.controlValue

    let gridModel: KagamiGridModel = formPresentationModel.kagamiGridModels.get(control.entityId)
    let activeNode : any
    gridModel.gridApi.forEachNode((rowNode: any) => {
      if(record.id === rowNode.data.id){
        activeNode = rowNode
      } 
  });
    activeNode.data[attribute] = parseInt(controlValue);
    var diff:number = (CommonUtils.isNotEmpty(record[attribute]) ? record[attribute] : 0) - controlValue;
    let totalHours : number = CommonUtils.isNotEmpty(activeNode.data.hours) ? activeNode.data.hours:0
    record['hours'] = Number((totalHours - diff).toFixed(1));
    activeNode.data['hours'] = Number((totalHours - diff).toFixed(1))
    updateEditingValues([activeNode],gridModel)
}

export function handleOnChange(kagamiFormModel: KagamiFormModel, control: KagamiControlModel, value: any) {
  let entityId = control.entityId;
  let attribute = control.attributeName;
  let controlValue = control.controlValue
  let record: any = getActiveFormRecord(kagamiFormModel, entityId);
  let formPresentationModel = kagamiFormModel.formControls[control.entityId]
  if(formPresentationModel instanceof KagamiEmbeddedFormPresentationModel && !formPresentationModel.isListPresentation && control.controlValue!==""){
    kagamiFormModel.formControls[control.entityId].hasChanges = true
  }else{
    kagamiFormModel.formControls[control.entityId].hasChanges = false;
  }
  if(kagamiFormModel.isPivot && control.attributePresentation.matrixAxis === 'x'){
    updateTotalHoursinPivot(kagamiFormModel,control,record)
  }
  let unSynced: any = _getUnSyncedFormRecord(kagamiFormModel, entityId);
  if (record != null) {
    // record[attribute] = value != null ? value : 'removed_';
    record[attribute] = getControlValue(control, value);
  }
  unSynced[attribute] = value != null ? value : 'removed_';
  if (control instanceof KagamiSelectModel && (control.controlType === 'search' || control.controlType === 'select')) {
    record[control.entityConsumed] = CommonUtils.isNotEmpty(control.associatedObject)
      ? control.associatedObject
      : SystemConstants.REMOVED_;
    unSynced[control.entityConsumed] = CommonUtils.isNotEmpty(control.associatedObject)
      ? control.associatedObject
      : SystemConstants.REMOVED_;
  } else if (control instanceof KagamiRadioModel && CommonUtils.isNotEmpty(control.entityConsumed)) {
    record[control.entityConsumed] = CommonUtils.isNotEmpty(control.associatedObject)
      ? control.associatedObject
      : SystemConstants.REMOVED_;
    unSynced[control.entityConsumed] = CommonUtils.isNotEmpty(control.associatedObject)
      ? control.associatedObject
      : SystemConstants.REMOVED_;
  }
  if(kagamiFormModel.isPivot && CommonUtils.isNotEmpty(kagamiFormModel.pivotConsumedEntity)){
    kagamiFormModel.formData.changedData[kagamiFormModel.pivotConsumedEntity] = kagamiFormModel.pivotLOV ?? []
  }
  // making network call even when searchfield has error message
  if (CommonUtils.isEmpty(control.errorMessage) || control.controlType === 'radio'|| control.controlType === 'search' || control.controlType === 'select') {
    if (!fireOnChangeEventIfRequired(kagamiFormModel, control)) {
      callLocalPolices(entityId, kagamiFormModel);
    }
  }
}

export function handleGridOnChange(
  kagamiFormModel: KagamiFormModel,
  cellData: any,
  gridControlModel: KagamiControlModel,
  params?: any
) {
  let entityId = cellData.entityId;
  let attributeName = cellData.attributeName;
  let controlValue = cellData.controlValue;
  let record: any = getActiveFormRecord(kagamiFormModel, entityId, cellData.recordId);
  let unsynced: any = _getUnSyncedFormRecord(kagamiFormModel, entityId);
  if (record !== null) {
    if(kagamiFormModel.isPivot && cellData.matrixAxis === 'x'){
      var diff:number = (CommonUtils.isNotEmpty(record[attributeName]) ? record[attributeName] : 0) - controlValue;
      let totalHours : number = CommonUtils.isNotEmpty(params.data.hours) ? params.data.hours:0;
      record['hours'] = Number((totalHours - diff).toFixed(1));
      params.node.setDataValue('hours', record['hours']);
    }
    record[attributeName] = controlValue !== null ? controlValue : 'removed_';
    populateConsumedEntity(record, controlValue, kagamiFormModel, gridControlModel);
  }
  unsynced[attributeName] = controlValue !== null ? controlValue : 'removed_';
  populateConsumedEntity(unsynced, controlValue, kagamiFormModel, gridControlModel);
  if(kagamiFormModel.isPivot && CommonUtils.isNotEmpty(kagamiFormModel.pivotConsumedEntity)){
    kagamiFormModel.formData.changedData[kagamiFormModel.pivotConsumedEntity] = kagamiFormModel.pivotLOV ?? []
  }
  if (!fireOnChangeEventIfRequired(kagamiFormModel, cellData, params)) {
    callLocalPolices(entityId, kagamiFormModel);
  }
}

function populateConsumedEntity(
  record: any,
  controlValue: any,
  kagamiFormModel: KagamiFormModel,
  gridControlModel: KagamiControlModel
) {
  if (gridControlModel.controlType === 'search' || gridControlModel.controlType === 'select') {
    let entityConsumed: string = getEntityConsumedInAttribute(gridControlModel.attributePresentation);
    record[entityConsumed] = kagamiFormModel.formData.data[entityConsumed].find((obj: any) => obj.id === controlValue);
    if (!record[entityConsumed]) {
      record[entityConsumed] = SystemConstants.REMOVED_;
    }
  }
} 
  export function handleOnSubmit(formModel: KagamiFormModel ) {
  _getUnSyncedFormRecord(formModel, formModel.formData.mainEntityId);
  const processRequestModel: KagamiProcessRequestModel = new KagamiProcessRequestModel(
    formModel.processName,
    formModel.isGroupForm ? loadGroupContent : loadProcessContent,
    showServerError
  );
  processRequestModel.fromDashboard = false;
  if(formModel.pickItemDetail){
    console.log('pick item detailed objects');
    processRequestModel.data = formModel.formData.changedData;
    let prevFormModel : KagamiFormModel | undefined = getPreviousFormModel();
    if(prevFormModel){
        setFormModel(prevFormModel);  
        _getUnSyncedFormRecord(prevFormModel,prevFormModel.mainEntityId);
        processRequestModel.data[prevFormModel.mainEntityId] = prevFormModel.formData.changedData[prevFormModel.mainEntityId]
    }
  }
  else{
    processRequestModel.data = formModel.formData.changedData;
  }
  processRequestModel.submitCall = true;
  processRequestModel.processStepName = formModel.processStepName;
  if (formModel.isGroupForm) processRequestModel.isGroupTemplateNetworkCall = true;
  if(formModel.pickItemDetail) processRequestModel.isPickItemCall = true
  onSubmitCall(processRequestModel);
}

export function _handleLovSelection(control: any, record: any, unSynced: any) {
  // if (control === KagamiSearchModel) {
  let value = control.associatedObject;
  let associatedAttribute = control.attributePresentation;
  record[associatedAttribute] = value != null ? value : 'removed_';
  unSynced[associatedAttribute] = value != null ? value : 'removed_';
  //  }
}

function renderOnLoadResponse(formModel: KagamiFormModel, response: any) {
  let formData = formModel.formData;
  if (
    null != response
    //&& !ResponseInterceptor.interceptConstructError(Get.context, response)
  ) {
    formData.clearChangedData();
    let constructOutputData = response.data;
    _mergeContextData(formData, constructOutputData.detailedObjects);
    _mergeFormData(formData, constructOutputData.detailedObjects);
    //formData.detailedObjects = constructOutputData?.detailedObjects;
    //formPresentationModel.dataLoaded.value = true;
    // KagamiProcessController.startUiSubProcessIfReceived(response);
  }
}
let deTime:number = 100;
function fireOnChangeEventIfRequired(formModel: KagamiFormModel, control: KagamiControlModel | any, params?: any) {
  let changeFor: any = _getChangeForDetail(formModel, control.entityId, control.attributeName, control.controlValue);
  if (control.onChangeRequired) {
    showLoader();
    let entityId = control.entityId;
    let entityConsumed = control.entityConsumed;
    let attribute = control.attributeName;
    let attrName = isMatrisAxisX(control) ?  control['pivotOnChangeAttr'] : control.attributeName;
    let entityPresentation: any = formModel.formControls[control.entityId].presentation;
    let requestModel = new KagamiSubProcessRequestModel(
      formModel.processName,
      attrName,
      entityPresentation,
      changeFor,
      EventType.ONCHANGE,
      onChangeSuccess,
      updateErrorContent
    );
    requestModel.data = formModel.formData.changedData;
    let callSub:boolean = true;
    if(control.onChangeRequired && entityConsumed in formModel.formData.detailedObjects && formModel.formData.detailedObjects[entityConsumed].length === 1){
      let pendingCallsCount = 'pendingCallsCount';
      let lovSeqCall:any = localStorage.getItem('lovChangeSeqCall');
      if(lovSeqCall != null){
          lovSeqCall = JSON.parse(lovSeqCall);
          let pcc:number = lovSeqCall[pendingCallsCount]
          pcc=pcc+1;
          lovSeqCall={[pendingCallsCount]:pcc};
          callSub = false;
          deTime = pcc * 100;
          if(pcc === 2){
            console.log('*** lovChangeSeqCall is 2');
            handleSequencialCallsDebounce_first(requestModel, formModel, control, params);
          }
          else if(pcc === 3){
            console.log('*** lovChangeSeqCall is 3');
            handleSequencialCallsDebounce_second(requestModel, formModel, control, params);
          }
          else{
            console.log('*** lovChangeSeqCall is else');
            handleSequencialCalls(requestModel, formModel, control, params);
          }
      }
      else {
          lovSeqCall = {};
          lovSeqCall={pendingCallsCount:1};
      }
      localStorage.setItem('lovChangeSeqCall', JSON.stringify(lovSeqCall));
    }
    if(callSub){
      console.log('*** lovChangeSeqCall is Default');
      handleSequencialCalls(requestModel, formModel, control, params);
    }
    return true;
    //
  }
  return false;
}

function handleSequencialCalls(requestModel: KagamiSubProcessRequestModel, formModel:any, control:any,params?: any){
  handleSequencialCalls_subProcess(requestModel, formModel, control,params);
}

const handleSequencialCallsDebounce_first =  debounce((requestModel: KagamiSubProcessRequestModel, formModel:any,control:any, params?: any)=> {
  handleSequencialCalls_subProcess(requestModel, formModel, control,params);
},200)

const handleSequencialCallsDebounce_second =  debounce((requestModel: KagamiSubProcessRequestModel , formModel:any,control:any,params?: any)=> {
  handleSequencialCalls_subProcess(requestModel, formModel, control,params);
},300)

function handleSequencialCalls_subProcess(requestModel: KagamiSubProcessRequestModel , formModel:any,control:any,params?: any){
  let entityId = control.entityId;
  let attribute = control.attributeName;

  onSubProcessCall(requestModel, formModel, control)
  .then((response) => {
      let pendingCallsCount = 'pendingCallsCount';
      let lovSeqCall:any = localStorage.getItem('lovChangeSeqCall');
      if(lovSeqCall != null){
        lovSeqCall = JSON.parse(lovSeqCall);
        let pcc:number = lovSeqCall[pendingCallsCount]
        pcc=pcc-1;
        console.log('*** lovChangeSeqCall (-) :'+pcc);
        if(pcc === 0){
          console.log('*** Deleting lovChangeSeqCall');
          localStorage.removeItem('lovChangeSeqCall');
        }
        else{
          lovSeqCall={[pendingCallsCount]:pcc};
          localStorage.setItem('lovChangeSeqCall', JSON.stringify(lovSeqCall));
        }
      }
    if (params?.colDef?.headerComponentParams?.$$colConfig?.requestInProcess) {
      params.colDef.headerComponentParams.$$colConfig.requestInProcess = false;
      params.colDef.headerComponentParams.$$colConfig?.handleRequestProcess(
        params.colDef.headerComponentParams.$$colConfig.kagamiGridModel,
        false
      );
    }
    hideLoader();
    if (response != null) {
      if (
        response.data &&
        response.data['constructOutputData'] != null &&
        response.data['properties'] != null &&
        response.data['properties']['attrSequence'] != null
      ) {
        let getLocalStorageOnChangeSequenceData:any = localStorage.getItem('attributeSequence');
        let parseLocalStorageOnChangeSequenceData:any = JSON.stringify(JSON.parse(getLocalStorageOnChangeSequenceData));
        let getTimeStampValueForOnChangeSequence = response.data['properties']['attrSequence'].timeStampKey;
        if(CommonUtils.isNotEmpty(parseLocalStorageOnChangeSequenceData)){
          let getParseAttributeSequenceArrayOfObjects = JSON.parse(getLocalStorageOnChangeSequenceData);
          if(CommonUtils.isNotEmpty(getParseAttributeSequenceArrayOfObjects)){
            getParseAttributeSequenceArrayOfObjects.filter((getParseAttributeSequenceArrayOfObject:any) => {
               if(getParseAttributeSequenceArrayOfObject.timeStampKey === getTimeStampValueForOnChangeSequence){
                let removeAttributeSequenceObject =  getParseAttributeSequenceArrayOfObjects.filter((obj:any) => obj.timeStampKey !==getTimeStampValueForOnChangeSequence);
                if(removeAttributeSequenceObject.length > 0){
                  localStorage.setItem(SystemConstants.ATTRIBUTESEQUENCE , JSON.stringify(removeAttributeSequenceObject))
                }else{
                  localStorage.removeItem( SystemConstants.ATTRIBUTESEQUENCE )
                }
                requestModel.onSuccess(response.data, control);
                if(control instanceof KagamiSelectModel && CommonUtils.isNotEmpty(control.inputValue) && (CommonUtils.isEmpty(control.controlValue) || (control.controlType === 'multiselect'))){
                  control.inputValue = '';
                  onSearchCall(control, '');
                }
                }
            })
          }
        }
      } else if (response.data && response.data['constructError'] != null) {
        showServerError(response.data['constructError']);
      }
    }
  })
  .catch((err: any) => {
    hideLoader();
    console.log("***ERROR");
    console.log(err);
  });
}

export function fireOnCancelEventIfRequired(
  formModel: KagamiFormModel,
  formPresentationModel: KagamiFormPresentationModel
) {
  if (isOnCancelRequiredForPresentation(formPresentationModel.presentation)) {
    showLoader();
    let formData = formModel.formData;
    let entityHierarchy = formData.entityHierarchies.get(formPresentationModel.presentationId);
    let changeFor = _getChangeForDetail(formModel, formPresentationModel.formEntityId, null, null,true);
    let requestModel: KagamiSubProcessRequestModel = new KagamiSubProcessRequestModel(
      formModel.processName,
      '',
      formPresentationModel.presentation,
      changeFor,
      EventType.ONCANCEL,
      onCancelSuccess,
      updateErrorContent
    );
    requestModel.data = formData.changedData;
    onSubProcessCall(requestModel, formModel, '')
      .then((response) => {
        hideLoader();
        if (response && null != response) {
          if (response.data && response.data['constructOutputData']) {
            requestModel.onSuccess(response.data, formPresentationModel);
          } else if (response.data && response.data['constructError'] && response.data['constructError'] != null) {
            showServerError(response.data['constructError']);
          }
        }
      })
      .catch((err: any) => {
        hideLoader();
        console.log(err);
      });
    return true;
  }
}

export function _getChangeForDetail(kagamiFormModel: KagamiFormModel, entityId: string, attribute: any, value: any,isRemoveRecord : boolean =false) {
  let changeFor: any = {};
  let inFocusIds = kagamiFormModel.inFocusFormDataIdentifiers;
  let formData = kagamiFormModel.formData;
  // changeFor = {'id': inFocusIds[formData.mainEntityId]};
  changeFor[formData.mainEntityId] = { id: inFocusIds[formData.mainEntityId] };
  let parentChangeFor: any = changeFor[formData.mainEntityId]; 
  if (entityId !== formData.mainEntityId) {
    let presentationModel : KagamiFormPresentationModel = kagamiFormModel.formControls[entityId];
    let entityHierarchy = formData.entityHierarchies.get(presentationModel.presentationId);
    for (let i = 1; i < entityHierarchy.levelList.length; i++) {
      let hierarchy = entityHierarchy.levelList[i];
      let formPresentationModel : KagamiFormPresentationModel = kagamiFormModel.formControls[hierarchy];
      let hierarchyPRuleId : string = formPresentationModel.presentationId;
      let childChangeFor: any = {};
      childChangeFor['id'] = hierarchy === entityId && isRemoveRecord ? SystemConstants.REMOVED_+inFocusIds[hierarchy] : inFocusIds[hierarchy];
      if (formData.entityHierarchies.get(hierarchyPRuleId).isListPrule) {
        parentChangeFor[hierarchy] = [];
        if(formData.formData[hierarchy] !== undefined){
          formData.formData[hierarchy].map((x:any) => {
          let isEmbedCheckBox:boolean = CommonUtils.isEmpty(kagamiFormModel.embedCheckBox) ? false : kagamiFormModel.embedCheckBox.isEmbedCheckBox;
          if(x.id === childChangeFor['id'] && isEmbedCheckBox){
            // childChangeFor['raisePI'] = x.raisePI;
            childChangeFor[kagamiFormModel.embedCheckBox.colId] = kagamiFormModel.embedCheckBox.colVal;
          }
          })
        }
        parentChangeFor[hierarchy].push(childChangeFor);
      } else {
        parentChangeFor[hierarchy] = childChangeFor;
      }
      parentChangeFor = childChangeFor;
    }
  }
  if (attribute != null) {
    let formPresentationModel : KagamiFormPresentationModel = kagamiFormModel.formControls[entityId]
    if(formPresentationModel){
      let controlModel : KagamiControlModel = formPresentationModel.formControls[entityId+'.'+attribute]
      if(controlModel===undefined && formPresentationModel instanceof KagamiEmbeddedFormPresentationModel){
         let gridModel : KagamiGridModel | undefined = formPresentationModel.kagamiGridModels.get(formPresentationModel.formEntityId);
         if(gridModel){
          controlModel = gridModel.gridControls[attribute]
         }
      }    
      if((controlModel  && controlModel.controlType === 'multiselect' && value instanceof Array && CommonUtils.isNotEmpty(value))){
        let multiselectValues : any[] = [];
         value.map((record : any) => {
           if(!record.id.includes(SystemConstants.REMOVED_)){
             multiselectValues.push({id : record.id});
           }
         });
         parentChangeFor[attribute] = multiselectValues;
      }
      else{
        parentChangeFor[attribute] = CommonUtils.isNotEmpty(value) ? value : SystemConstants.REMOVED_;
      }
    }
    else{
      parentChangeFor[attribute] = CommonUtils.isNotEmpty(value) ? value : SystemConstants.REMOVED_;
    }
  }
  return changeFor;
}

export function getActiveFormRecord(kagamiFormModel: KagamiFormModel, entityId: string, formDataId?: string) {
  let inFocusIds = kagamiFormModel.inFocusFormDataIdentifiers;
  let formData = kagamiFormModel.formData;
  let txnEntity = formData.formData;
  if (entityId === formData.mainEntityId) {
    return txnEntity;
  } else {
    let parent = txnEntity;
    let embedFormPresentationModel : KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[entityId];
    let entityHierarchy: PresentationHierarchy = formData.entityHierarchies.get(embedFormPresentationModel.presentationId);
    for (let i = 1; i < entityHierarchy.levelList.length; i++) {
      if (parent == null) break;
      let hierarchy = entityHierarchy.levelList[i];
      if (parent[hierarchy] instanceof Array) {
        parent = parent[hierarchy].filter(
          (e: any) =>
            e['id'] ===
            (entityId === hierarchy && CommonUtils.isNotEmpty(formDataId) ? formDataId : inFocusIds[hierarchy])
        );
        if (parent !== undefined) {
          parent = parent[0];
        }
      } 
      else if(parent[hierarchy] === undefined){
        /// Note :  for single embed form we are going to create record id if there is no data for it
        let embedFormPresentationModel : KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[hierarchy];
        if(!embedFormPresentationModel.isListPresentation){
          let recordId : string = getRecordId(kagamiFormModel,kagamiFormModel.formControls[hierarchy])
          /// Note : Here we are overriding onload data if there is only id in onload data
          ///  because in few cases while resetting single embed onload data id will be cleared
          /// so whatever the id we generate after that should be assigned to single embed onload data
          if(CommonUtils.isNotEmpty(embedFormPresentationModel.onLoadData) && Object.keys(embedFormPresentationModel.onLoadData).length === 1){
            embedFormPresentationModel.onLoadData =  clone(parent[hierarchy]);
          }
          parent = parent[hierarchy]
        }
        else{
          parent = [];
        }
      }
      else {
        parent = parent[hierarchy];
      }
      if (entityId === hierarchy) {
        return parent;
      }
    }
  }
  return null;
}

export function cancelEmbedRecordChanges(oldRecord: any, kagamiFormModel: KagamiFormModel, entityId: string) {
  let formDataId = oldRecord.id;
  let formData: KagamiFormData = kagamiFormModel.formData;
  let inFocusIds = kagamiFormModel.inFocusFormDataIdentifiers;
  let txnEntity = formData.formData;
  if (CommonUtils.isEmpty(formData.changedData)) {
    _getUnSyncedFormRecord(kagamiFormModel, kagamiFormModel.mainEntityId);
  }
  let changedData = formData.changedData;
  let changedEntity = changedData[kagamiFormModel.mainEntityId][0];
  let parent = txnEntity;
  let embedFormPresentationModel : KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[entityId];
  let entityHierarchy: PresentationHierarchy = formData.entityHierarchies.get(embedFormPresentationModel.presentationId);
  for (let i = 1; i < entityHierarchy.levelList.length; i++) {
    if (parent == null) break;
    let hierarchy = entityHierarchy.levelList[i];
    if (parent[hierarchy] instanceof Array) {
      changedEntity[hierarchy] = changedEntity[hierarchy] ?? [];
      changedEntity[hierarchy].splice(0, 0, { id: SystemConstants.REMOVED_ + oldRecord.id });
      // changedEntity[hierarchy].push({id : SystemConstants.REMOVED_+oldRecord.id})
      let unsyncedRecord = _getUnSyncedFormRecord(kagamiFormModel, entityId);
      let activeRecord = getActiveFormRecord(kagamiFormModel, entityId);
      if (unsyncedRecord) {
        let key: any, value: any;
        for ([key, value] of Object.entries(activeRecord)) {
          if (key === 'id') continue;
          if (oldRecord[key] === null || oldRecord[key] === undefined) {
            unsyncedRecord[key] = SystemConstants.REMOVED_;
            activeRecord[key] = null;
          } else {
            unsyncedRecord[key] = oldRecord[key];
            activeRecord[key] = oldRecord[key];
          }
        }
      }
    }
  }
}

export function getActiveContext(formModel: KagamiFormModel, entityId: string, formDataId?: string) {
  let activeContext;
  let txnEntity = formModel.formData.formData;
  activeContext = formModel.formData.data;
  activeContext[formModel.formData.mainEntityId] = [txnEntity];
  if (entityId !== formModel.formData.mainEntityId) {
    let parent = txnEntity;
    let embedFormPresentationModel : KagamiEmbeddedFormPresentationModel = formModel.formControls[entityId];
    let entityHierarchy = formModel.formData.entityHierarchies.get(embedFormPresentationModel.presentationId);
    for (let i = 1; i < entityHierarchy.levelList.length; i++) {
      if (parent == null) break;
      let hierarchy = entityHierarchy.levelList[i];
      parent =
        parent[hierarchy] instanceof Array
          ? (parent[hierarchy] as Array<any>).find(
              (e: any) =>
                e['id'] ==
                (CommonUtils.isNotEmpty(formDataId) ? formDataId : formModel.inFocusFormDataIdentifiers[hierarchy])
            )
          : (parent = parent[hierarchy]);
      if (CommonUtils.isNotEmpty(parent)) {
        activeContext[hierarchy] = [parent];
      }
    }
  }
  return activeContext;
}

export function clearEmbeddedRecord(formPresentationModel: KagamiFormPresentationModel) {
  let kagamiFormModel: KagamiFormModel = getFormModel();
  let entityId = formPresentationModel.formEntityId;
  let recordId = formPresentationModel.formDataIdentifier;
  let formData = kagamiFormModel.formData;
  let entityHierarchy = formData.entityHierarchies.get(formPresentationModel.presentationId);
  let parent: any = getActiveFormRecord(kagamiFormModel, entityHierarchy.parentEntity);
  let unSyncedParent = _getUnSyncedFormRecord(kagamiFormModel, entityHierarchy.parentEntity);
  if (formPresentationModel.isListPresentation) {
    if (parent[entityId] instanceof Array) {
      let recordToBeRemoved: any | undefined = parent[entityId].find((e: any) => e['id'] === recordId);
      if (recordToBeRemoved !== undefined) {
        let recordIndex = parent[entityId].indexOf(recordToBeRemoved);
        parent[entityId].splice(recordIndex, 1);
      }
    }
    let unSyncedChild = { id: 'removed_' + recordId };
    if (unSyncedParent[entityId] == undefined) {
      unSyncedParent[entityId] = [];
    }
    if (unSyncedParent[entityId] instanceof Array) {
      let recordToBeRemoved: any | undefined = unSyncedParent[entityId].find((e: any) => e['id'] === recordId);
      if (recordToBeRemoved !== undefined) {
        let recordIndex = unSyncedParent[entityId].indexOf(recordToBeRemoved);
        unSyncedParent[entityId].splice(recordIndex, 1);
      }
      unSyncedParent[entityId].push(unSyncedChild);
    }
  } else {
    delete parent[entityId];
    unSyncedParent[entityId] = 'removed_';
  }
}

export function getFormRecords(formPresentationModel: KagamiFormPresentationModel) {
  let entityId = formPresentationModel.formEntityId;
  let kagamiFormModel: KagamiFormModel = getFormModel();
  let formData: KagamiFormData = kagamiFormModel.formData;
  if (entityId === formData.mainEntityId) {
    let txnEntity = formData.formData;
    return [txnEntity];
  } else {
    let entityHierarchy: PresentationHierarchy = formData.entityHierarchies.get(formPresentationModel.presentationId);
    if (entityHierarchy) {
      let formDataId: string = '';
      let parentEntity : string = entityHierarchy.parentEntity;
      if (formPresentationModel instanceof KagamiEmbeddedFormPresentationModel && formPresentationModel.isNested) {
        formDataId = formPresentationModel.parentEmbedIdForNestedEmbed;
        parentEntity = formPresentationModel.parentEmbedEntityId;
        // formDataId = kagamiFormModel.inFocusFormDataIdentifiers[formPresentationModel.parentEmbedEntityId]
        // formDataId = kagamiFormModel.formControls[formPresentationModel.parentEmbedEntityId].formDataIdentifier;
      }
      let parent = CommonUtils.isNotEmpty(formDataId)
        ? getActiveFormRecord(kagamiFormModel, parentEntity, formDataId)
        : getActiveFormRecord(kagamiFormModel, parentEntity);
      return parent != null ? parent[entityId] : null;
    }
  }
}

export function _getUnSyncedFormRecord(kagamiFormModel: KagamiFormModel, entityId: string, embeddedPageChange?: boolean) {
  let inFocusIds = kagamiFormModel.inFocusFormDataIdentifiers;
  let formData = kagamiFormModel.formData;
  let unSyncedData: any = formData.changedData;
  if (unSyncedData[formData.mainEntityId] === undefined || unSyncedData[formData.mainEntityId] == null) {
    let unsyncIdMap: Map<string, any> = new Map();
    unsyncIdMap.set('id', inFocusIds[formData.mainEntityId]);
    unSyncedData[formData.mainEntityId] = [];
    unSyncedData[formData.mainEntityId].push({ id: inFocusIds[formData.mainEntityId] });
  }
  unSyncedData = unSyncedData[formData.mainEntityId][0];
  if (entityId !== formData.mainEntityId) {
    let embedFormPresentationModel : KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[entityId]
    let entityHierarchy = formData.entityHierarchies.get(embedFormPresentationModel.presentationId);
    for (let i = 1; i < entityHierarchy.levelList.length; i++) {
      let hierarchy = entityHierarchy.levelList[i];
      let embedModel : KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[hierarchy]
      if (formData.entityHierarchies.get(embedModel.presentationId).isListPrule) {
        unSyncedData[hierarchy] = unSyncedData[hierarchy] ?? [];
        if ((unSyncedData[hierarchy] as Array<any>).find((e) => e['id'] === inFocusIds[hierarchy]) === undefined) {
          // unSyncedData[hierarchy] = {'id': inFocusIds[hierarchy]};
          if(embeddedPageChange) {
            unSyncedData[hierarchy];
          } else {
            unSyncedData[hierarchy].push({ id: inFocusIds[hierarchy] });
          }
          let isEmbedCheckBox:boolean = CommonUtils.isEmpty(kagamiFormModel.embedCheckBox) ? false : kagamiFormModel.embedCheckBox.isEmbedCheckBox;
          if(formData.formData[hierarchy] !== undefined){
            formData.formData[hierarchy].map((x:any) => {
              for (let index = 0; index < unSyncedData[hierarchy].length; index++) {
                if(unSyncedData[hierarchy][index].id === x.id && isEmbedCheckBox){
                  unSyncedData[hierarchy][index][kagamiFormModel.embedCheckBox.colId] = kagamiFormModel.embedCheckBox.colVal;
                }
              }
            });
          }
        }
        unSyncedData = (unSyncedData[hierarchy] as Array<any>).find((e) => e['id'] === inFocusIds[hierarchy]);
      } else {
        if (
          unSyncedData[hierarchy] === undefined ||
          unSyncedData[hierarchy] === null ||
          CommonUtils.isRemoved(unSyncedData[hierarchy])
        ) {
          unSyncedData[hierarchy] = { id: inFocusIds[hierarchy] };
        }
        unSyncedData = unSyncedData[hierarchy];
      }
    }
  }
  return unSyncedData;
}

export function _mergeContextData(formData: KagamiFormData, detailedObjects: any) {
  if (detailedObjects) {
    /// Note : not assigning detailed objects to form data detailed objects because we are using these attribute to store onload data w.r.t entityId
    //   formData.detailedObjects = detailedObjects;
    for (let entity of Object.keys(detailedObjects)) {
      formData.data[entity] = detailedObjects[entity];
    }
  } else {
    console.log('warning::: detailed objects is received as empty ..');
  }
}

export function _mergeFormData(formData: KagamiFormData, detailedObject: any,isExplicit : boolean = false,removeRecords : boolean = false) {
  if (detailedObject && detailedObject[formData.mainEntityId]) {
    let formObject = formData.formData;
    let changedObject = formData.changedData;
    let responseObject = detailedObject[formData.mainEntityId][0];
    if (responseObject !== undefined) {
      _mergeObjects(responseObject, formObject,isExplicit,removeRecords);
    }
  }
}

function _mergeObjects(source: any, target: any,isExplicit : boolean = false,removeRecords : boolean = false) {
  let property: any;
  let value: any;
  if (source)
    for ([property, value] of Object.entries(source)) {
      if (property != null) {
        let targetvalue = target[value];
        try {
          if (
            value instanceof Map &&
            CommonUtils.isNotEmpty(target[property]) &&
            !CommonUtils.isRemoved(targetvalue) &&
            target[property]['id'] == value.get('id')
          ) {
            _mergeObjects(value, target[property]);
          } else if (value instanceof Array && value && target[property] && !CommonUtils.isRemoved(targetvalue)) {
            let sourceIds = value.map((e: any) => e['id'].toString());
            let targetIds = target[property].map((e: any) => e['id'].toString());
            let removeRecordsIds : any[] = [];
            let anyOneRecordMatchesTheId = false;
            for (let sourceId of sourceIds) {
              if (targetIds.includes(sourceId)) {
                  anyOneRecordMatchesTheId = true;
                  break;
              }
            }
            if(removeRecords){
              for(let tarId of targetIds){
                if(!sourceIds.includes(tarId)){
                  removeRecordsIds.push(tarId)
                }
              }
            }
           
            if(isExplicit){
               target[property] = value;
            }
            else if (anyOneRecordMatchesTheId) {
              let childSource: any;
              for (childSource of value) {
                let childTarget = target[property].find((e: any) => e['id'] === childSource['id']);
                if (childTarget != null) {
                  _mergeObjects(childSource, childTarget);
                } else {
                  target[property].push(childSource);
                }
                if(CommonUtils.isNotEmpty(removeRecordsIds) && target[property] instanceof Array){
                  for(let recordId of removeRecordsIds){
                    let removeRecordIndex : any = target[property].findIndex((e:any) => e.id === recordId)
                    if(removeRecordIndex !== -1){
                      target[property].splice(removeRecordIndex,1)
                    }
                  }
                }
              }
            } 
            else {
              target[property] = value;
            }
          } else if (
            value instanceof Object &&
            CommonUtils.isNotEmpty(target[property]) &&
            !CommonUtils.isRemoved(targetvalue) &&
            target[property]['id'] == value['id']
          ) {
            _mergeObjects(value, target[property]);
          } else if (value === 'removed_') {
            target[property] = 'removed_';
          } else {
            target[property] = value;
          }
        } catch (error) {
        }
        
      } else {
        target.remove([property.key]);
      }
    }
}

function addChildObjectInFormData(formModel: KagamiFormModel, entityId: string, recordId: string) {
  let formData = formModel.formData;
  let inFocusIds = formModel.inFocusFormDataIdentifiers;
  let parentObject = formData.formData;
  if (entityId !== formData.mainEntityId) {
    let embedFormPresentationModel : KagamiEmbeddedFormPresentationModel = formModel.formControls[entityId];
    let entityHierarchy = formModel?.formData?.entityHierarchies?.get(embedFormPresentationModel.presentationId);
    for (let i = 1; i < entityHierarchy.levelList.length; i++) {
      let hierarchy = entityHierarchy.levelList[i];
      let embedPresentationModel : KagamiEmbeddedFormPresentationModel = formModel.formControls[hierarchy];
      if (entityId !== hierarchy) {
        if (formData.entityHierarchies.get(embedPresentationModel.presentationId).isListPrule) {
          parentObject = (parentObject[hierarchy] as Array<any>).find((e) => e['id'] === inFocusIds[hierarchy]);
        } else {
          parentObject = parentObject[hierarchy];
        }
      }
    }
    let childObject = { id: recordId };
    if (entityHierarchy.isListPrule) {
      if (parentObject && CommonUtils.isEmpty(parentObject[entityId])) {
        parentObject[entityId] = [];
        parentObject[entityId].push(childObject);
      } else {
        parentObject[entityId].push(childObject);
      }
    } else {
      /// Note : if there is no data available for single embed form then only we are adding record id object to form data if not we use previous available data only
      if(CommonUtils.isEmpty(parentObject[entityId])){
        parentObject[entityId] = childObject;
      }
    }
  }
}

export function searchRecords(
  formModel: KagamiFormModel,
  presentationId: any,
  control: KagamiSelectModel,
  searchQuery: any,
  asyncSearch: boolean
): any {
  showLoader(true);
  let runtimeInput: RuntimeInput = new RuntimeInput();
  runtimeInput.id = 'RuntimeInput';
  runtimeInput.searchKey = searchQuery;
  let requestModel: KagamiSubProcessRequestModel = new KagamiSubProcessRequestModel(
    formModel.processName,
    control.attributePresentation,
    getAttributeName(control.attributePresentation),
    null,
    EventType.ONSEARCH,
    onSearchSuccess,
    updateErrorContent
  );
  requestModel.presentationId = presentationId;
  requestModel.runtimeInput = runtimeInput;
  _getUnSyncedFormRecord(formModel,control.entityId);
  requestModel.data = formModel.formData.changedData;
  requestModel.changeFor = _getChangeForDetail(formModel,control.entityId,null,null);
  onSubProcessCall(requestModel, formModel, control, control.pageNumber)
    .then((response) => {
      hideLoader();
      if (response != null) {
        if (response.data && response.data['constructOutputData'] != null) {
          control.verbProperties = response.data['constructOutputData']['verbProperties'];
          if (asyncSearch) {
            onAsyncSearchSuccess(response.data['constructOutputData'], control);
          } else {
            requestModel.onSuccess(response.data);
          }
        } else if (response.data && response.data['constructError'] != null) {
          showServerError(response.data['constructError']);
        }
      }
    })
    .catch((err: any) => {
      hideLoader();
      console.log(err);
    });
}

function onAsyncSearchSuccess(response: any, control: KagamiSelectModel) {
  if (control.isEmbddedField && control.isGridField) {
    if (CommonUtils.isNotEmpty(response.detailedObjects[control.attributePresentation.entityConsumed])) {
      const setOptions = KagamiStateManager.getStateToHolder(control.entityId + '.' + control.attributeName);
      setOptions((prev: any) => {
        return [...prev, ...response.detailedObjects[control.attributePresentation.entityConsumed]];
      });
    }
  } else {
    if (CommonUtils.isNotEmpty(response.detailedObjects[control.entityConsumed])) {
      let formModel: KagamiFormModel = getFormModel();
      formModel.formData.data[control.entityConsumed] = [
        ...formModel.formData.data[control.entityConsumed],
        ...response.detailedObjects[control.entityConsumed]
      ];
      // console.log(formModel.formData.data)
      // let formPresentationModel : any = formModel.formControls[control.entityId];
      // updateFormLovControls(formPresentationModel,response.detailedObjects,control.attributeName)
      control.lovRecords = formModel.formData.data[control.entityConsumed];
      control.state.setControlValue(control.controlValue);
    }
  }
}

function setOnLoadData(kagamiFormModel: KagamiFormModel, entityId: string) {
  let formPresentationModel = kagamiFormModel.formControls.get(entityId);
  let activeRecord = getActiveFormRecord(kagamiFormModel, entityId);
  let clonedRecord = activeRecord;
  formPresentationModel.onLoadData = clonedRecord;
}

export function cancelEmbedChanges(formPresentationModel: KagamiEmbeddedFormPresentationModel) {
  let kagamiFormModel: KagamiFormModel = getFormModel();
  if (formPresentationModel.isNewRecord) {
    clearEmbeddedRecord(formPresentationModel);
  } else {
    let oldRecord = formPresentationModel.oldEditRecord;
    cancelEmbedRecordChanges(oldRecord, kagamiFormModel, formPresentationModel.formEntityId);
  }
  resetEmbedFormPresentation(formPresentationModel);
  fireOnCancelEventIfRequired(kagamiFormModel, formPresentationModel);
}

export function prepareUnSyncForReset(kagamiFormModel: KagamiFormModel, entityId: string) {
  let formPresentationModel: KagamiFormPresentationModel = kagamiFormModel.formControls[entityId];
  let activeRecord = getActiveFormRecord(kagamiFormModel, entityId);
  let unSyncedRecord = _getUnSyncedFormRecord(kagamiFormModel, entityId);
  let onLoadRecord = entityId !== kagamiFormModel.mainEntityId ?getOnloadDataForEmbedForm(entityId,kagamiFormModel) : cloneDeep(kagamiFormModel.onLoadData);
  let onLoadLov = kagamiFormModel.formData.detailedObjects
  _mapUnSyncedProperties(activeRecord, unSyncedRecord, onLoadRecord);
  let defaultLovData = _mapUpdateLovRecords(kagamiFormModel.formData.data,onLoadLov)
  if(entityId === kagamiFormModel.mainEntityId){
    kagamiFormModel.formData.changedData[kagamiFormModel.mainEntityId].splice(1, 0, {
      id: SystemConstants.REMOVED_ + activeRecord.id
    });
    kagamiFormModel.formData.formData =  cloneDeep(kagamiFormModel.onLoadData);
    kagamiFormModel.formData.data = defaultLovData;
  }
  else{
    let clonedActiveRecord =  cloneDeep(activeRecord);
    let embedModel : KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[entityId]
    let formControls : any = embedModel.formControls
    for(let [property,value] of Object.entries(clonedActiveRecord)){
      let controlModel : KagamiControlModel = formControls[entityId+'.'+property]
      if(controlModel){
        if(onLoadRecord[property] === undefined){
          if(controlModel.controlType === 'number'){
            activeRecord[property] = 0
          }
          else {
            activeRecord[property]  = null;
          }
      }
      else if(onLoadRecord[property] !== value){
        activeRecord[property] = onLoadRecord[property]
      }
      }
      else if(value instanceof Object &&(CommonUtils.isEmpty(onLoadRecord[property]) || CommonUtils.isRemoved(onLoadRecord[property]))){
        activeRecord[property] = SystemConstants.REMOVED_
      }
    }

    console.log(kagamiFormModel.formData.formData);
  }
  // console.log('un-synced .. $unSyncedRecord');
}

function getOnloadDataForEmbedForm(entityId : string,kagamiFormModel : KagamiFormModel){
  let embedFormModel : KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[entityId];
  let onLoadRecord : any;
  if(embedFormModel){
    onLoadRecord= embedFormModel.onLoadData;
  }else { if(Array.isArray(kagamiFormModel.formData.detailedObjects[entityId])){
    onLoadRecord =  cloneDeep(kagamiFormModel.formData.detailedObjects[entityId][0]);
 }else {
    onLoadRecord =  cloneDeep(kagamiFormModel.formData.detailedObjects[entityId]);
 }}
 
  return onLoadRecord;
}

export function refreshForm(entityId: string) {
  let kagamiFormModel: KagamiFormModel = getFormModel();
  let formPresentationModel: KagamiFormPresentationModel = kagamiFormModel.formControls[entityId];
  let activeRecord: any = getActiveFormRecord(kagamiFormModel, entityId);
  for (let [controlKey, control] of Object.entries(formPresentationModel.formControls)) {
    if (control instanceof KagamiControlModel) {
      let controlValue = activeRecord[control.attributeName];
      control.controlValue = CommonUtils.isRemoved(controlValue) ? null : controlValue;
      control.validation = null;
      control.errorMessage = null;
      if (control instanceof KagamiSelectModel) {
        let associatedAttribute: string = getEntityConsumed(control.attributePresentation);
        let associateObject: any =
          activeRecord[associatedAttribute] instanceof Map ? activeRecord[associatedAttribute] : null;
        if (associateObject == null) {
          associateObject = getAssociatedRecord(kagamiFormModel, control);
          control.associatedObject = associateObject;
        }
      }
      kagamiFormModel.formControlStates[controlKey].setControlValue(control.controlValue);
      control.previouseControlValue = kagamiFormModel.formControlStates[controlKey].controlValue;
    }
  }
}

function  _mapUpdateLovRecords(currentLovData: any, defaultLov: any){
  let resetLovData : any = {}
  for(let entityConsumed in currentLovData){
    if(CommonUtils.isEmpty(defaultLov[entityConsumed])){
      resetLovData[entityConsumed] = [];
    }
    else{
      resetLovData[entityConsumed] = defaultLov[entityConsumed];
    }
    }
    return resetLovData;
  }
 
// }

function _mapUnSyncedProperties(activeRecord: any, unSyncedRecord: any, onLoadRecord: any) {
  // unSyncedRecord = new Map();
  for (let [property, value] of Object.entries(activeRecord)) {
    if (property === 'id') {
      unSyncedRecord[property] = value;
      continue;
    } else if (
      onLoadRecord &&
      (CommonUtils.isEmpty(onLoadRecord[property]) || CommonUtils.isRemoved(onLoadRecord[property]))
    ) {
      // unSyncedRecord[property] = SystemConstants.REMOVED_
      if (value instanceof Array) {
        unSyncedRecord[property] = [];
      } else if (value instanceof Object) {
        let unSyncedChild = {};
        let activeChild: any = value;
        let onLoadChild: any = {};
        unSyncedRecord[property] = SystemConstants.REMOVED_;
        // if (activeChild['id'] == onLoadChild['id']) {
        // unSyncedChild = _mapUnSyncedProperties(activeChild, unSyncedChild, onLoadChild);
        // unSyncedRecord[property] = unSyncedChild;
        // }
      } else {
        unSyncedRecord[property] = SystemConstants.REMOVED_;
      }
    } else if (value instanceof Array) {
      let unSyncedChildren = new Array();
      let onLoadChildren: Array<any> = onLoadRecord[property];
      for(let i=0;i<onLoadChildren.length;i++){
        let onLoadChild : any = onLoadChildren[i];
        let activeChild = value.find((e : any) => e['id'] === onLoadChild['id'])
        if(activeChild === undefined){
          unSyncedChildren.push(onLoadChild)
        }
        else{
          let unsyncChild : any = _mapUnSyncedProperties(activeChild, {}, onLoadChild)
          unSyncedChildren.push(unsyncChild);
        }
      }
      // for (let i = 0; i < value.length; i++) {
      //   let activeChild: any = value[i];
      //   let onLoadChild = onLoadChildren.find((e) => e['id'] == activeChild['id']);
      //   if (onLoadChild == undefined) {
      //     unSyncedChildren.push({ id: SystemConstants.REMOVED_ + activeChild['id'] });
      //   } else {
      //     let unSyncedChild: any = _mapUnSyncedProperties(activeChild, {}, onLoadChild);
      //     unSyncedChildren.push(unSyncedChild);
      //   }
      // }
      unSyncedRecord[property] = unSyncedChildren;
    } else if (value instanceof Map || value instanceof Object) {
      // TODO no need to handle lovs
      let unSyncedChild = new Map();
      let activeChild: any = value;
      let onLoadChild: any = onLoadRecord[property];
      if (activeChild['id'] == onLoadChild['id']) {
        unSyncedChild = _mapUnSyncedProperties(activeChild, unSyncedChild, onLoadChild);
        unSyncedRecord[property] = unSyncedChild;
      } else {
        unSyncedRecord[property] = CommonUtils.clone(onLoadChild[property]);
      }
    } else if (onLoadRecord[property] != value) {
      unSyncedRecord[property] = onLoadRecord[property];
    } else {
      unSyncedRecord[property] = onLoadRecord[property];
    }
  }
  return unSyncedRecord;
}

function getAssociatedRecord(formModel: KagamiFormModel, control: KagamiSelectModel) {
  let associatedEntity = getEntityConsumedInAttribute(control.attributePresentation);
  let associatedAttribute = getEntityAssociatedAttribute(control.attributePresentation);
  let contextualRecords = formModel.formData.data[associatedEntity] ?? [];
  return contextualRecords.find((e: any) => e[associatedAttribute] === control.controlValue);
}

export function updateFormControls(object: any, entityIdentifier: string, formModel: KagamiFormModel,currentEntityId ?: string,isSingleEmbedOnload ?: boolean,isReset? : boolean) {
  let formPresentationModel = formModel.formControls[entityIdentifier];
  if(formPresentationModel.isNested){
      /// Note : Incase of inline we need the context nested model with id and in parent embed popup we need
      /// just the embed model. To cover this 2 scenarios we are doing the following
    let parentEmbedModel : KagamiEmbeddedFormPresentationModel = formModel.formControls[formPresentationModel.parentEmbedEntityId]
    if(!parentEmbedModel.isActiveForm){
      formPresentationModel = formModel.formControls[entityIdentifier+'.'+formModel.inFocusFormDataIdentifiers[formPresentationModel.parentEmbedEntityId]]
    }
  }
  if (CommonUtils.isNotEmpty(object) && object['id'] === formModel.inFocusFormDataIdentifiers[entityIdentifier]) {
    for (let [property, value] of Object.entries(object)) {
      if (property === 'id') continue;
      let embeddedDataEntry = false;
      var controlValue: any = value;
      let controlIdentifier = entityIdentifier + '.' + property;
      if (controlValue instanceof Map || controlValue instanceof Array || controlValue instanceof Object) {
        if (
          controlValue instanceof Object &&
          !(controlValue instanceof Array) && formModel.formControls[property] !== undefined && 
          formModel.formData.entityHierarchies.get(formModel.formControls[property].presentationId)
        ) {
          let embedFormPresentationModel : KagamiEmbeddedFormPresentationModel = formModel.formControls[property]
          if(embedFormPresentationModel && currentEntityId === embedFormPresentationModel.formEntityId && isSingleEmbedOnload){
            embedFormPresentationModel.onLoadData =  cloneDeep(controlValue);
          }
          if(CommonUtils.isNotEmpty(controlValue['id']) && embedFormPresentationModel.formDataIdentifier !== controlValue['id']){
            embedFormPresentationModel.formDataIdentifier = controlValue['id']
            updateInFocusMap(embedFormPresentationModel.formEntityId,controlValue['id'],formModel)
          }
          updateFormControls(controlValue, property, formModel);
          /// Note : updating isFirst onload call and expanded flags to update the data and execute policies to work even after reset
          if(isReset !== undefined && isReset && embedFormPresentationModel instanceof KagamiEmbeddedAccordionPresentationModel){
            if(embedFormPresentationModel.state){
              embedFormPresentationModel.isFirstOnloadCall = true;
              embedFormPresentationModel.state.setExpanded(false)
            }
          }
          embeddedDataEntry = true;
        } else if (controlValue instanceof Array && formModel.formControls[property] !== undefined &&formModel.formData.entityHierarchies.get(formModel.formControls[property].presentationId)) {
          let formObject = controlValue.find((e) => e['id'] === formModel.inFocusFormDataIdentifiers[property]);
          if (formObject !== undefined && !isReset) {
            let embedFormPresentationModel = formModel.formControls[property];
            if(embedFormPresentationModel.isNested){
            let parentEmbedModel : KagamiEmbeddedFormPresentationModel = formModel.formControls[embedFormPresentationModel.parentEmbedEntityId]
            if(!parentEmbedModel.isActiveForm){
              embedFormPresentationModel = formModel.formControls[property+'.'+formModel.inFocusFormDataIdentifiers[embedFormPresentationModel.parentEmbedEntityId]] ?? embedFormPresentationModel
            }
          }
            let gridModel: KagamiGridModel = embedFormPresentationModel.kagamiGridModels.get(
              embedFormPresentationModel.formEntityId
            );
            /// Note : To populate the data w.r.t to modal or grid inline we are using active form.
            /// To update the data in the modal popup we need to call form controls. For that we are using active form otherwise we will update the data in grid in lines
            if (gridModel) {
              if (embedFormPresentationModel.isActiveForm) {
                updateFormControls(formObject, property, formModel);
              } else {
                if (embedFormPresentationModel.isNested) {
                  let parentEmbedModel: KagamiEmbeddedFormPresentationModel =
                    formModel.formControls[embedFormPresentationModel.parentEmbedEntityId];
                  if (!parentEmbedModel.isActiveForm && CommonUtils.isNotEmpty(gridModel.parentEmbedIdForNestedEmbed)) {
                    embedFormPresentationModel =
                      formModel.formControls[gridModel.mainEntityId + '.' + gridModel.parentEmbedIdForNestedEmbed];
                  }
                }
                /// Note : We should not use grid api functions if the grid is not in the context. We update embed list
                /// based on grid is destroyed or not. If grid is destroyed we directly update the embed list without using grid api funcitons
                if (gridModel.gridApi.destroyCalled) {
                  embedFormPresentationModel.onLoadData = controlValue;
                  updateEmbeddedList(embedFormPresentationModel);
                } else {
                  updateEmbedGridControls(gridModel, controlValue, embedFormPresentationModel.isNested,embedFormPresentationModel);
                }
              }
            } else {
              updateFormControls(formObject, property, formModel);
            }
            if(isReset && gridModel){
              gridModel.activeRowNode = null;
            }
          } else {
            let embedFormPresentationModel = formModel.formControls[property];
            if(embedFormPresentationModel){
              if(embedFormPresentationModel.isNested ){
                let parentEmbedModel : KagamiEmbeddedFormPresentationModel = formModel.formControls[embedFormPresentationModel.parentEmbedEntityId]
                if(!parentEmbedModel.isActiveForm){
                  embedFormPresentationModel = formModel.formControls[embedFormPresentationModel.formEntityId+'.'+object['id']];
                }
              }
                embedFormPresentationModel.onLoadData = controlValue;
                updateEmbeddedList(embedFormPresentationModel);
                if(isReset){
                  let gridModel : KagamiGridModel = embedFormPresentationModel.kagamiGridModels.get(embedFormPresentationModel.formEntityId);
                  if(gridModel && CommonUtils.isNotEmpty(gridModel.activeRowNode)){
                    gridModel.activeRowNode = null
                  }
                }
            }
          }
          embeddedDataEntry = true;
        } else {
          if (formModel.formControls[property]) {
            formPresentationModel = formModel.formControls[property];
            if (formPresentationModel != null) {
              updateFormControls(controlValue, property, formModel);
            }
          }
        }
      }
      if (!embeddedDataEntry) {
        let control = formPresentationModel.formControls[controlIdentifier];
        controlValue = controlValue === SystemConstants.REMOVED_ ? null : controlValue;
        if (control && control != null) {
          if (control.pickAttribute) {
            updateFormControlForPickAttributes(control, object, formModel);
          } else {
            if (control instanceof KagamiDateModel) {
              if (isTypeDate(formModel.formControlStates[controlIdentifier].controlType)) {
                formModel.formControlStates[controlIdentifier]?.setControlValue(controlValue);
              }
            } else if (control instanceof KagamiSelectModel) {
              /// Note : populating single lov value as control value for select and search controls
              if(controlValue === null && control.lovRecords.length === 1 && (control.controlType === 'search' || control.controlType === 'select') && object[property] !== SystemConstants.REMOVED_){
                controlValue =control.controlType === 'search' ?control.lovRecords[0][control.entityAssociatedAttribute] : control.lovRecords[0].id;
                control.associatedObject = control.lovRecords[0];
                formModel.formControlStates[controlIdentifier]?.setControlValue(controlValue);
                validateSelectTextBox(control,controlValue);
              }
              else{
                control.controlValue = controlValue;
                let associatedEntity = getEntityConsumedInAttribute(control.attributePresentation);
                control.associatedObject = getAssociatedObject(object, associatedEntity, controlValue,control.entityAssociatedAttribute);
                if (control.controlType === 'multiselect') {
                  let selectedValues = controlValue?.filter((item: any) => !item.id.includes('removed_'));
                  formModel.formControlStates[controlIdentifier]?.setSelectValues(selectedValues);
                }
                if (control.previouseControlValue !== control.controlValue) {
                  formModel.formControlStates[controlIdentifier]?.setControlValue(controlValue);
                }
                control.previouseControlValue = formModel.formControlStates[controlIdentifier]?.controlValue;
              }
              
            } else if (control instanceof KagamiRadioModel) {
              // control.controlValue = controlValue;
              let associatedEntity = getEntityConsumedInAttribute(control.attributePresentation);

              if (CommonUtils.isEmpty(control.selectValues)) {
                control.controlValue = controlValue;
                let associatedObject = getAssociatedObject(object, associatedEntity, controlValue,getEntityAssociatedAttribute(control.attributePresentation));
                formModel.formControlStates[controlIdentifier].setSelectValues(
                  CommonUtils.isNotEmpty(associatedObject) ? [associatedObject] : []
                );
              } else if (CommonUtils.isEmpty(formModel.formData.data[associatedEntity])) {
                control.controlValue = controlValue;
                let associatedObject = getAssociatedObject(object, associatedEntity, controlValue,getEntityAssociatedAttribute(control.attributePresentation));
                formModel.formControlStates[controlIdentifier].setSelectValues(
                  CommonUtils.isNotEmpty(associatedObject) ? [associatedObject] : []
                );
              } else {
                control.associatedObject = getAssociatedObject(object,associatedEntity,controlValue,getEntityAssociatedAttribute(control.attributePresentation))
                formModel.formControlStates[controlIdentifier]?.setControlValue(controlValue);
              }
            } else {
              if (control.pickAttribute) {
                updateFormControlForPickAttributes(control, object, formModel);
              } else {
                if (CommonUtils.isNotEmpty(control.errorMessage)) control.errorMessage = null;
                if (control.isGridField) {
                  let gridModel = getKagamiListModel();
                  if (gridModel instanceof KagamiGridModel && gridModel.activeRowNode !== null) {
                    let activeRowNode: any = gridModel.activeRowNode;
                    activeRowNode.data[control.attributeName] = controlValue;
                    // activeRowNode.setDataValue(control.attributeName,controlValue)
                  }
                }
                if (control.controlType === 'number') {
                    if(hasTimeConversion(control)){
                   // conversion of decimals to Time
                  let displayValue: any;
                  if (controlValue?.toString().includes('.')) {
                    displayValue=  convertDecimalValueToMinutes(controlValue)
                    controlValue = displayValue;
                  } else if (controlValue === 0) {
                    controlValue = '00:00';
                  }else{
                    displayValue=  convertDecimalValueToMinutes(controlValue)
                    controlValue = displayValue;
                  }
                }
                else{
                      controlValue = controlValue === null ? 0 : controlValue;
                    }
                }
               /// Note : if contact value is becoming null we still need to have dial code as control value otherwise 
               /// country code will get cleared in phone number control
                if(control instanceof KagamiTextModel && control.isContact && CommonUtils.isEmpty(controlValue)){
                       controlValue = control.dialCode;
                } else if(control instanceof KagamiFileModel){
                control.docInfo = formModel.docInfo[control.attributeName]

              }
            
            formModel.formControlStates[controlIdentifier]?.setControlValue(controlValue);
                control.previouseControlValue = formModel.formControlStates[controlIdentifier]?.controlValue;
              }
            }
          }
        }
      }
    }
    // makeSingleLovOnChangeIfMissed(formPresentationModel,object,formModel)
    //The above makeSingleLovOnChangeIfMissed functionality helps to send onChange call automatecally without cliking when there is have single LOV record but this feature create a issue because of that commented above line.
    updateFormControlsForPickAttributes2(object, entityIdentifier);
  } else if (
    formModel.mainEntityId !== entityIdentifier &&
    CommonUtils.isEmpty(formModel.inFocusFormDataIdentifiers[entityIdentifier]) &&
    !formPresentationModel.isListPresentation
  ) {
    if (object && object['id']) {
      formPresentationModel.onLoadData = object;
      formPresentationModel.formDataIdentifier = object['id'];
      updateInFocusMap(entityIdentifier, object['id'], formModel);
      updateFormControls(object, entityIdentifier, formModel);
    }
  }
}

function hasTimeConversion(controlModel : KagamiNumberModel){
  return  CommonUtils.isNotEmpty(controlModel.uiSettings) && controlModel.uiSettings.conversion === 'hh:mm'
}

function makeSingleLovOnChangeIfMissed(formPresentationModel : KagamiFormPresentationModel,object : any,formModel :KagamiFormModel){
  let formControls : any = formPresentationModel.formControls;
  let controlIdentifier : string;
  let controlModel : KagamiControlModel | any;
  for([controlIdentifier,controlModel] of Object.entries(formControls)){
    if(controlModel instanceof KagamiSelectModel &&( controlModel.controlType === 'search' || controlModel.controlType === 'select')){
      if( CommonUtils.isEmpty(controlModel.controlValue)  && controlModel.lovRecords.length === 1 && CommonUtils.isEmpty(controlModel.inputValue) && CommonUtils.isNotEmpty(controlModel.previouseControlValue)){
               if(CommonUtils.isNotEmpty(object[controlModel.entityConsumed]) &&CommonUtils.isNotEmpty(object[controlModel.entityConsumed])&&object[controlModel.attributeName]==='removed_'){
                  controlModel.controlValue = controlModel.controlType === 'search' ? object[controlModel.entityConsumed][controlModel.entityAssociatedAttribute] : object[controlModel.entityConsumed].id;
                  controlModel.associatedObject = object[controlModel.entityConsumed];
               }
               else{
                controlModel.controlValue =controlModel.controlType === 'search' ?controlModel.lovRecords[0][controlModel.entityAssociatedAttribute] : controlModel.lovRecords[0].id;
                controlModel.associatedObject = controlModel.lovRecords[0];
               }
                formModel.formControlStates[controlIdentifier].setControlValue(controlModel.controlValue);
                if(!controlModel.readonly){
                  validateSelectTextBox(controlModel,controlModel.controlValue);
                }
      }
    }
  }
}

export function updateEmbedGridControls(kagamiGridModel: KagamiGridModel, dataRecords: any, isNestedGrid: boolean,embedFormPresentationModel : KagamiEmbeddedFormPresentationModel) {
  let activeRowNode: any = kagamiGridModel.activeRowNode;
  let rowNodesToRefresh: any[] = [];
  let property, value: any;
  let newRowData : any[] =[];

  let focusedCell: any = kagamiGridModel?.gridApi?.getFocusedCell();
  let gridRowNodes : any[] = []
  kagamiGridModel?.gridApi?.forEachNode((rowNode : any,index:number) => {
    gridRowNodes.push(rowNode);
  })
  let formModel: KagamiFormModel = getFormModel();
  if (activeRowNode || CommonUtils.isNotEmpty(kagamiGridModel.parentEmbedIdForNestedEmbed) || !isNestedGrid ) {
    for (let record of dataRecords) {
      console.log('record id is '+record.id);
      activeRowNode = gridRowNodes.find((node: any) => node.data.id === record.id);
      if(activeRowNode){
        rowNodesToRefresh.push(activeRowNode);
      }
      else{
        newRowData.push(record);
      }
      if(activeRowNode){
        for ([property, value] of Object.entries(record)) {
          if (property === "id") {
            continue;
          }
  
          if (value instanceof Array) {
            activeRowNode.data[property] = value;
            let embedModel: KagamiEmbeddedFormPresentationModel = formModel.formControls[property];
            if (embedModel && embedModel.isNested) {
              let nestedEmbedModel = formModel.formControls[embedModel.formEntityId + '.' + record.id];
              if (nestedEmbedModel) {
                nestedEmbedModel.onLoadData = value;
                let nestedGridModel: KagamiGridModel | undefined = nestedEmbedModel.kagamiGridModels.get(
                  nestedEmbedModel.formEntityId
                );
                if (nestedGridModel) {
                  if (CommonUtils.isNotEmpty(nestedGridModel.gridApi) && !nestedGridModel.gridApi.destroyCalled) {
                    updateEmbedGridControls(nestedGridModel, value, true,nestedEmbedModel);
                  }
                }
              }
            } else {
              let controlModel : KagamiControlModel = kagamiGridModel.gridControls[property];
              if(controlModel){
                let attributePresentation = controlModel.attributePresentation;
                if (attributePresentation && getHtmlControl(attributePresentation) === 'multiselect' && activeRowNode) {
                  let selectedValues = value.filter((item: any) => !item.id.includes('removed_'));
                  activeRowNode.data[property] = selectedValues;
                }
              }
            }
          }
          else if( (value instanceof Object)){
             if(CommonUtils.isNotEmpty(activeRowNode)  ) {
               activeRowNode.data[property] = value  
             }
          }
          
          else {
            value = value === SystemConstants.REMOVED_ ? null : value;
            let controlModel : KagamiControlModel = kagamiGridModel.gridControls[property];
            if(CommonUtils.isNotEmpty(controlModel)){
              let attributePresentation = controlModel.attributePresentation;
            if (attributePresentation) {
              let htmlControl = getHtmlControl(attributePresentation);
              if (activeRowNode) {
                if (activeRowNode.data[property] === undefined) {
                  if (htmlControl === 'search') {
                    let colDef: any = kagamiGridModel?.gridApi?.getColumnDef(property);
                    if (colDef.cellEditorParams) {
                      colDef.cellEditorParams.associatedObject = getAssociatedObject(
                        record,
                        getEntityConsumed(attributePresentation),
                        value,
                        getEntityAssociatedAttribute(controlModel.attributePresentation)
                      );
                    }
                  }
                  if (activeRowNode.data[property] !== value) {
                    activeRowNode.data[property] = value;
                  }
                } else if (activeRowNode.data[property] !== value) {
                  if (
                    isTypeText(htmlControl) &&
                    focusedCell &&
                    focusedCell.rowIndex === activeRowNode.rowIndex &&
                    focusedCell.column.colId === property
                  ) {
                    const currentCell = kagamiGridModel?.gridApi!?.getFocusedCell();
                    if(currentCell){
                      let clonedActiveNode : any = _.clone(kagamiGridModel.activeRowNode);
                      kagamiGridModel?.gridApi!?.stopEditing();
                      delay(()=> {
                        /// Note : rowEditingStopped making the active row node as null so we are re assigning it here
                        kagamiGridModel.activeRowNode = clonedActiveNode;
                      },200)
                      kagamiGridModel?.gridApi!?.startEditingCell({
                        rowIndex: currentCell?.rowIndex,
                        colKey: currentCell?.column.colId
                      });
                    }
                  }
                  if(isLovTypeControl(htmlControl)){
                    let entityConsumed : string = getEntityConsumedInAttribute(controlModel.attributePresentation)
                    let associatedObject = CommonUtils.isNotEmpty(record[entityConsumed]) ? record[entityConsumed] : null
                    activeRowNode.data[entityConsumed] = associatedObject;
                  }
                  activeRowNode.data[property] = value;
                }
              }
            }
          }
          }
        }
      }
    }
    
    if (CommonUtils.isEmpty(rowNodesToRefresh) || isCompleteNewNestedRecords(rowNodesToRefresh)) {
      if (CommonUtils.isNotEmpty(kagamiGridModel.parentEmbedIdForNestedEmbed)) {
        let nestedEmbedModel: KagamiEmbeddedFormPresentationModel =
          formModel.formControls[kagamiGridModel.mainEntityId + '.' + kagamiGridModel.parentEmbedIdForNestedEmbed];
        updateEmbeddedList(nestedEmbedModel);
      } else {
        if(CommonUtils.isNotEmpty(newRowData)){
          embedFormPresentationModel.onLoadData = dataRecords;
         updateEmbeddedList(embedFormPresentationModel);
        }
        updateEditingValues(rowNodesToRefresh,kagamiGridModel);
      }
    } else {
      if(CommonUtils.isNotEmpty(newRowData)){
        embedFormPresentationModel.onLoadData = dataRecords;
       updateEmbeddedList(embedFormPresentationModel);
      }
      else{
        /// Note: In case the inline added records in nested are deleted in parent embed popup then to update it to the 
        /// inline grid we are utilizing this function
        removeRowsIfFoundAny(dataRecords,kagamiGridModel,embedFormPresentationModel)
        kagamiGridModel?.gridApi?.refreshCells({rowNodes : rowNodesToRefresh});
        if(kagamiGridModel.isPivot) {
          kagamiGridModel?.gridApi?.refreshClientSideRowModel();
        }
      }
      updateEditingValues(rowNodesToRefresh,kagamiGridModel);
    }
  }
  else{
    if (embedFormPresentationModel !== undefined) {
      embedFormPresentationModel.onLoadData = dataRecords;
      updateEmbeddedList(embedFormPresentationModel);
    }
  }
}

const removeRowsIfFoundAny = (dataRecords : any[],kagamiGridModel : KagamiGridModel,embedFormPresentationModel : KagamiEmbeddedFormPresentationModel) => {
  let renderedNodes : any[] = kagamiGridModel?.gridApi?.getRenderedNodes().filter((node: any) => (!node.id.includes('detail') && node.data !== undefined));
  let rowNode : any;
  let nodesToDelete : any[] = [];
  for(let node of renderedNodes){
    rowNode  = dataRecords.find((record: any) => record.id === node.data.id);
    if(!rowNode){
      nodesToDelete.push(node.data)
    }
  }
  if(nodesToDelete.length > 0){
    kagamiGridModel.gridApi.applyTransaction({remove : nodesToDelete});
    updateEmbeddedList(embedFormPresentationModel)
  }
  // return nodesToDelete.length > 0
}

const updateEditingValues = (rowNodesToRefresh:any,kagamiGridModel : any)=>{
  const activeRowNode= kagamiGridModel.activeRowNode;
  let editNode : any = CommonUtils.isEmpty(activeRowNode) ? rowNodesToRefresh[0] : activeRowNode;
  let allRowControls : any[] = editNode?.beans?.rowRenderer?.allRowCtrls;
  let hasCellRenderer : boolean = false;
  if(CommonUtils.isNotEmpty(allRowControls)){
  let activeRowControl : any = allRowControls.find((rowControl : any) => rowControl.rowNode.rowIndex === editNode.rowIndex)
  activeRowControl?.centerCellCtrls?.list?.forEach((cellCtrl:any) =>{
    const cellComp = cellCtrl?.cellComp;
    const editor = cellComp?.getCellEditor();
    const input = editor?.eInput;
    const colDef = cellCtrl?.column?.colDef;
    let value = editNode?.data[colDef?.field];
    if(cellCtrl.isCellRenderer){
      hasCellRenderer = true;
    }
    if(colDef?.field.includes('.')){
      value = KagamiBeanUtils.getProperty(colDef?.field, editNode.data);
      editNode.data[colDef?.field]= value;
    }
    if(input){
      input?.setValue(value);
    } else if(editor?.refreshEditor) {
      editor?.refreshEditor();
    }
  })
  /// Note : If the grid has cell renderers it wont update by normal refresh cells, we have to pass force as true to update renderers
if(!hasCellRenderer) {
  kagamiGridModel.gridApi.refreshCells({ rowNodes: [editNode],force : hasCellRenderer });
} else {
  const currentCell = kagamiGridModel.gridApi!.getFocusedCell();
  kagamiGridModel.gridApi.refreshCells({ rowNodes: rowNodesToRefresh,force : hasCellRenderer });
  if(kagamiGridModel.isNested && currentCell){
    kagamiGridModel.gridApi!.startEditingCell({
      rowIndex: currentCell?.rowIndex,
      colKey: currentCell?.column.colId
    });
  }
}
if(kagamiGridModel.isPivot) {
    //kagamiGridModel.gridApi.recomputeAggregates();
    kagamiGridModel.gridApi.refreshClientSideRowModel();
  }
}
}

function isCompleteNewNestedRecords(rowNodes: any[]) {
  let isNewData: boolean = false;
  for (let node of rowNodes) {
    if (!node && !isNewData) isNewData = true;
  }
  return isNewData;
}

function getEmbedGridFields(kagamiGridModel: KagamiGridModel) {
  let embedGridFields: any[] = [];
  let attributePresentation: any;
  for (attributePresentation of Object.values(kagamiGridModel.presentation.presentationRules)) {
    if (attributePresentation['@type'] === 'FieldPresentation' && isVisibleAttribute(attributePresentation)) {
      embedGridFields.push(getAttributeName(attributePresentation));
    }
  }
  return embedGridFields;
}

export function getAssociatedObject(mainObject: any, associatedEntity: any, controlValue : any,associatedAttr : string) {
  let associatedObject: any;
  if (controlValue !== null) {
    if (mainObject && mainObject[associatedEntity] && mainObject[associatedEntity] !== SystemConstants.REMOVED_ && mainObject[associatedEntity][associatedAttr] === controlValue) {
      associatedObject = mainObject[associatedEntity];
    } else {
      let formModel: KagamiFormModel = getFormModel();
      let lovRecords: any[] = formModel.formData.data[associatedEntity];
      if (lovRecords) associatedObject = lovRecords.find((record: any) => record.id === controlValue);
    }
  }
  return associatedObject;
}

function updateFormControlsForPickAttributes2(object: any, entityIdentifier: string) {
  let formPresentationModel: KagamiFormPresentationModel = getFormModel().formControls[entityIdentifier];
  for (let [controlKey, control] of Object.entries(formPresentationModel.formControls)) {
    if (control instanceof KagamiControlModel) {
      if (control.pickAttribute) {
        let pickAttributeValue: string = KagamiBeanUtils.getProperty(control.attributeName, object);
        /// Note : commented null check because in case of onchange if we get null values we need to update them to controls
        // if (CommonUtils.isNotEmpty(pickAttributeValue)) {
          if (
            control instanceof KagamiTextModel ||
            control instanceof KagamiDateModel ||
            control instanceof KagamiNumberModel ||
            control instanceof KagamiSelectModel
          ) {
            control.previouseControlValue = pickAttributeValue;
            getFormModel().formControlStates[control.controlIdentifier].setControlValue(pickAttributeValue);
          } else if(control instanceof KagamiFileModel ) {
            console.log('datatestabc')
            let formModel = getFormModel();
            control.docInfo = formModel.docInfo
            
            // control.previouseControlValue = pickAttributeValue;
            formModel.formControlStates[control.controlIdentifier].setControlValue(pickAttributeValue); 
          }
          else {
            console.log('pick attribute not supported for' + control.attributeName);
          }
      }
    }
  }
}

function expandRowIfAny(gridModel: KagamiGridModel, formModel: KagamiFormModel) {
  console.log('row expanding');
}

function updateFormControlForPickAttributes(control: KagamiControlModel, object: any, formModel: any) {
  let pickAttributeValue = KagamiBeanUtils.getProperty(control.attributeName, object);
  if (pickAttributeValue != null) {
    pickAttributeValue = pickAttributeValue === 'removed_' ? null : pickAttributeValue;
    if (
      control instanceof KagamiTextModel ||
      control instanceof KagamiDateModel ||
      control instanceof KagamiFileModel
    ) {
      control.previouseControlValue = pickAttributeValue;
      formModel.formControlStates[control.controlIdentifier].setControlValue(pickAttributeValue);
    } else {
      console.log('pick attribute not supported for' + control.attributeName);
    }
  }
}

export function afterOnLoadCallIsSuccessful(entityId: string, formModel: any) {
  let formData = formModel.formData;
  if (formData.detailedObjects) {
    if (formData.detailedObjects[entityId]) {
      updateInFocusMap(entityId, formData.detailedObjects[entityId][0]['id'], formModel);
    }
  }
  setOnLoadData(formModel, entityId);
  _updateControlsWithFormData(formModel, entityId);
}

export function afterOnCancelCallIsSuccessful(detailedObject: any) {
  _updateControlsWithChangedData(detailedObject);
}

function _updateControlsWithChangedData(detailedObjects: any) {
  let kagamiFormModel: KagamiFormModel = getFormModel();
  let formData: KagamiFormData = kagamiFormModel.formData;
  updateLovControls(detailedObjects, kagamiFormModel);
  if (CommonUtils.isNotEmpty(detailedObjects)) {
    let mainEntities: Array<any> = detailedObjects[formData.mainEntityId];
    if (CommonUtils.isNotEmpty(mainEntities)) {
      let mainObjectId: string = kagamiFormModel.inFocusFormDataIdentifiers[formData.mainEntityId];
      let mainObject: any = mainEntities.find((e) => e['id'] == mainObjectId);
      if (mainObject) {
        updateFormControls(mainObject, formData.mainEntityId, kagamiFormModel);
      } else {
        console.log('warning:: main entity data not received.. ' + mainEntities);
      }
    }
  }
}

export function updateInFocusMap(entityId: string, recordId: string, formModel: any) {
  if (entityId != null && recordId != null) {
    formModel.inFocusFormDataIdentifiers[entityId] = recordId;
  }
}

export function _updateControlsWithFormData(formModel: KagamiFormModel, entityId: string,isSingleEmbedOnload : boolean = false) {
  for(let entity of Object.keys(formModel.inFocusFormDataIdentifiers)){
    let activeRecord = getActiveFormRecord(formModel, entity);
    updateLovControls(formModel.formData.data, formModel);
    updateFormControls(activeRecord, entity, formModel,entityId,isSingleEmbedOnload);
  }
   
}

export function updateLovControls(detailedObjects: any, formModel: KagamiFormModel) {
  if (detailedObjects) {
    for (let [key, value] of Object.entries(formModel.inFocusFormDataIdentifiers)) {
      // console.log(key, value);
      let formPresentationModel = formModel.formControls[key];
      /// Note : fetching nested embed model based on embed view whether it is inline or modal
      if(formPresentationModel.isNested){
        let parentEmbedId : string = formModel.inFocusFormDataIdentifiers[formPresentationModel.parentEmbedEntityId]
        let parentEmbedModel : KagamiEmbeddedFormPresentationModel = formModel.formControls[formPresentationModel.parentEmbedEntityId]
        if(!parentEmbedModel.isActiveForm){
          formPresentationModel = formModel.formControls[formPresentationModel.formEntityId+'.'+parentEmbedId]
        }
      }
      if (
        formPresentationModel instanceof KagamiEmbeddedFormPresentationModel ||
        formPresentationModel instanceof KagamiExpandedEmbeddedPresentationModel
      ) {
        let gridModel: KagamiGridModel | undefined = formPresentationModel.kagamiGridModels.get(
          formPresentationModel.formEntityId
        );
        /// Note : isActiveForm boolean is used to update lov controls in modal popup if default embedded form view is inform
        if (formPresentationModel.isActiveForm) {
          updateFormLovControls(formPresentationModel, detailedObjects, key);
        } else if (gridModel) {
          if (!gridModel.gridApi.destroyCalled) {
            updateGridLovControls(gridModel, detailedObjects, formPresentationModel);
          }
        } else {
          updateFormLovControls(formPresentationModel, detailedObjects, key);
        }
      } else {
        updateFormLovControls(formPresentationModel, detailedObjects, key);
      }
    }
  }
}

function updateGridLovControls(kagamiGridModel: KagamiGridModel, detailedObjects: any,embedFormModel : KagamiEmbeddedFormPresentationModel) {
  // console.log('updating grid lov controls');
  try {
    let attributeName: string;
    let gridControlModel: any;
    let applicableHtmlControls: any[] = ['search', 'radio', 'select','multiselect'];
    for ([attributeName, gridControlModel] of Object.entries(kagamiGridModel.gridControls)) {
      let htmlControl = getHtmlControl(gridControlModel.attributePresentation);
      if (
        gridControlModel.attributePresentation['@type'] === 'FieldPresentation' &&
        applicableHtmlControls.includes(htmlControl) &&
        gridControlModel.visible
      ) {
        let entityConsumed: any = getEntityConsumedInAttribute(gridControlModel.attributePresentation);
        if (entityConsumed in detailedObjects) {
          if (detailedObjects[entityConsumed]) {
            let colDef: any = kagamiGridModel.gridApi.getColumnDef(attributeName);
            if (CommonUtils.isNotEmpty(colDef)) {
              colDef.cellEditorParams.options = detailedObjects[entityConsumed];
              let columnState = KagamiStateManager.getStateToHolder(
                kagamiGridModel.mainEntityId + '.' + gridControlModel.attributeName
              );
              if (columnState) {
                let controlModel : any = embedFormModel.formControls[embedFormModel.formEntityId+'.'+attributeName]
                if(controlModel && controlModel instanceof KagamiSelectModel)
                controlModel.lovRecords = detailedObjects[entityConsumed]
                columnState(detailedObjects[entityConsumed]);
              }
              // kagamiGridModel.gridApi.refreshCells({ rowNodes: [kagamiGridModel.activeRowNode] });
            }
          }
        }
      }
    }
    // kagamiGridModel.gridApi.refreshCells({ rowNodes: [kagamiGridModel.activeRowNode] });
  } catch (error: any) {
    console.log('got error while iterating over attribute presentations');
  }
}

function updateFormLovControls(formPresentationModel: KagamiFormPresentationModel, detailedObjects: any, key: string) {
  let formModel: KagamiFormModel = getFormModel();
  if (CommonUtils.isNotEmpty(formModel.formControlStates) && formPresentationModel) {
    console.time('Update Form LOV Controls');
    for (key in formPresentationModel.formControls) {
      let control = formPresentationModel.formControls[key];
      if(control instanceof KagamiRadioModel || control instanceof KagamiSelectModel) {
        if (control instanceof KagamiRadioModel) {
          let entityConsumed = getEntityConsumedInAttribute(control.attributePresentation);
          if (entityConsumed in detailedObjects) {
            // TODO compare list to check if there's any change
            // control.selectValues = ;
            if (detailedObjects[entityConsumed] && detailedObjects[entityConsumed].length !== 0) {
              formModel.formControlStates[control.controlIdentifier].setSelectValues(detailedObjects[entityConsumed]);
            }
          }
        } else if (control instanceof KagamiSelectModel) {
          let entityConsumed = getEntityConsumedInAttribute(control.attributePresentation);
          if (entityConsumed in detailedObjects) {
            if (detailedObjects[entityConsumed] !== undefined) {
              control.lovRecords = detailedObjects[entityConsumed];
              if (control.controlType === 'multiselect') {
                formModel.formControlStates[control.controlIdentifier]?.setSelectValues(control.controlValue);
              } else {
                formModel.formControlStates[control.controlIdentifier]?.setControlValue(control.controlValue);
              }
              // formModel.formControlStates[control.controlIdentifier].setLovRecords(detailedObjects[entityConsumed]);
            }
          }
        }
      }
    }
    console.timeEnd('Update Form LOV Controls');
  }
}

export function handleSingleEmbedFormLoad(embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel,isFirstOnloadCall : boolean = false) {
  /// Note : Removed onload required condition to avoid unnecessary issues while opening list view in accordion
  // if (!embeddedFormPresentationModel.isListPresentation || embeddedFormPresentationModel.onloadRequired) {
  //   handleEmbeddedFormLoad(embeddedFormPresentationModel, embeddedFormPresentationModel.processName);
  // }
  let isMatrixForm: boolean = false;
  isMatrixForm = isMatrixFormTrue(embeddedFormPresentationModel);
  if (!embeddedFormPresentationModel.isListPresentation) {
    handleEmbeddedFormLoad(embeddedFormPresentationModel, embeddedFormPresentationModel.processName,isFirstOnloadCall);
  } else if (embeddedFormPresentationModel.onloadRequired && isMatrixForm) {
     handleMatrixPresentation(embeddedFormPresentationModel)
  }
}

export function handleMultiEmbedFormLoad(
  embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel,
  processName: string
) {
  handleEmbeddedFormLoad(embeddedFormPresentationModel, processName);
}

export function handleEmbeddedFormLoad(
  embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel,
  processName: string,
  isFirstOnloadCall : boolean = false
) {
  let kagamiFormModel: KagamiFormModel = getFormModel();
  let recordId: string;
  if (embeddedFormPresentationModel.formDataIdentifier !== '') {
    recordId = embeddedFormPresentationModel.formDataIdentifier;
  } else {
    recordId = getRecordId(kagamiFormModel, embeddedFormPresentationModel);
    embeddedFormPresentationModel.formDataIdentifier = recordId;
  }
  updateInFocusMap(embeddedFormPresentationModel.formEntityId, recordId, kagamiFormModel);
  embeddedFormPresentationModel.formDataIdentifier = recordId;
  let changeFor: any;
  let entityId: string = embeddedFormPresentationModel.formEntityId;
  let record = getActiveFormRecord(kagamiFormModel, entityId);
  if (record && record != null) {
    _getUnSyncedFormRecord(kagamiFormModel, entityId);
    changeFor = _getChangeForDetail(kagamiFormModel, entityId, 'id', record['id']);
  }
  if(kagamiFormModel.isPivot && CommonUtils.isNotEmpty(kagamiFormModel.pivotConsumedEntity)){
    kagamiFormModel.formData.changedData[kagamiFormModel.pivotConsumedEntity] = kagamiFormModel.pivotLOV ?? []
  }
  if (embeddedFormPresentationModel.onloadRequired) {
    showLoader();
    let entityPresentation: any = embeddedFormPresentationModel.presentation;
    let requestModel: KagamiSubProcessRequestModel = new KagamiSubProcessRequestModel(
      processName,
      '',
      entityPresentation,
      changeFor,
      EventType.ONLOAD,
      onEmbedOnLoadSuccess,
      updateErrorContent
    );
    requestModel.data = kagamiFormModel.formData.changedData;
    onSubProcessCall(requestModel, kagamiFormModel, '')
      .then((response: any) => {
        hideLoader();
        kagamiFormModel.formData.clearChangedData();
        kagamiFormModel.embedCheckBox = {};
        if (response && response.data && response.data['constructOutputData'] != null) {
          requestModel.onSuccess(response.data, embeddedFormPresentationModel,isFirstOnloadCall);
        } else if (response.data && response.data['constructError'] != null) {
          showServerError(response.data['constructError']);
        }
      })
      .catch((err: any) => {
        hideLoader();
        console.log(err);
      });
  } else {
    delay(()=>{
      callLocalPolices(embeddedFormPresentationModel.formEntityId, kagamiFormModel);
    },100)
  }
}

export function isSupportedTemplateForSections(uiTemplate: string) {
  let supportedTemplates : any [] = ['treeForm','treeEdit','treeView']
  return supportedTemplates.includes(uiTemplate);
}

export function getSelectedValueForReadOnlySearch(kagamiSearchModel: KagamiSelectModel) {
  let dispPresentationRuleConfig = getDispPresentationRuleConfig(kagamiSearchModel.attributePresentation);
  let selectedValue = '';
  let visibleAttributes = dispPresentationRuleConfig.$$visibleAttributes?.length > 0 ? dispPresentationRuleConfig.$$visibleAttributes : ((dispPresentationRuleConfig.$$visibleAttributes?.length ? dispPresentationRuleConfig.$$visibleAttributes:[]));
  if (visibleAttributes?.length) {
    selectedValue = computeDisplayString(kagamiSearchModel.associatedObject, visibleAttributes || [], "View");
  }else {
        selectedValue = extractDropdownOption(
        kagamiSearchModel.attributePresentation,
        kagamiSearchModel.associatedObject
      );
  }
  return CommonUtils.isNotEmpty(selectedValue) ? selectedValue : '--';
}

export function commaSeparatedValue(controlValue: any, props?: any) {
  let getAppSettingCammaSeprated:any = localStorage.getItem('currencyCammaSeprated');
  let getAppSettingCammaSepratedCode:any =JSON.parse(getAppSettingCammaSeprated);
  let value;
  let newvalue;
  let splitedNumberValue:any;
  if (CommonUtils.isNotEmpty(controlValue)) {
    // if (props.kagamiNumberModel.internationalCurreny) {
    //   let nf = new Intl.NumberFormat('en-US');
    //   let newNo = nf.format(controlValue);
    //   newvalue = newNo;
    if(CommonUtils.isNotEmpty(props.kagamiNumberModel) && CommonUtils.isNotEmpty(props.kagamiNumberModel.currencyFormat)) {
    newvalue = differentCurrenyFormats(controlValue, props.kagamiNumberModel?.currencyFormat)
    } 
    else {
    if (typeof controlValue === 'string') {
      if (controlValue !== '0') {
        if (controlValue.includes('.')) {
          if (controlValue.includes('.') && controlValue.length > 3) {
            value = controlValue.replace(/,/g, '').split('.');
            splitedNumberValue = value[0]
            newvalue = getAppSettingCammaSepratedCode === 'en_US'  || getAppSettingCammaSepratedCode ===  "fr_FR"  ?splitedNumberValue.toLocaleString() + '.' + value[1] : splitedNumberValue.toLocaleString('en-IN') + '.' + value[1];
          } else if (controlValue.includes('.') && controlValue.length >= 3) {
            value = controlValue.replace(/,/g, '').split('.');
            splitedNumberValue = value[0]
            newvalue = getAppSettingCammaSepratedCode === 'en_US' || getAppSettingCammaSepratedCode ===  "fr_FR"   ? splitedNumberValue.toLocaleString() + '.' + value[1] : splitedNumberValue.toLocaleString('en-IN') + '.' + value[1];
          } else {
            value = parseFloat(controlValue.replace(/,/g, ''));
            newvalue = getAppSettingCammaSepratedCode === 'en_US' || getAppSettingCammaSepratedCode ===  "fr_FR"  ? value.toLocaleString() : value.toLocaleString('en-IN')
          }
        } else if (controlValue.charAt(0) === '-' && controlValue.length === 1) {
          value = controlValue;
          newvalue = value;
        } else {
          if (controlValue.length > 3) {
            value = parseFloat(controlValue.replace(/,/g, ''));
            newvalue = getAppSettingCammaSepratedCode === 'en_US' || getAppSettingCammaSepratedCode ===  "fr_FR"  ? value.toLocaleString() : value.toLocaleString('en-IN');
          } else {
            value = parseFloat(controlValue.replace(/,/g, ''));
            newvalue = getAppSettingCammaSepratedCode === 'en_US' || getAppSettingCammaSepratedCode ===  "fr_FR"  ? value.toLocaleString() : value.toLocaleString('en-IN');
          }
        }
      } else {
        newvalue = '';
      }
    } else {
      let convertControlValue = controlValue.toString();
      if (convertControlValue !== '0') {
        if (convertControlValue.includes('.')) {
          if (convertControlValue.includes('.') && convertControlValue.length > 3) {
            value = convertControlValue.replace(/,/g, '').split('.');
            splitedNumberValue = value[0]
            newvalue = getAppSettingCammaSepratedCode === 'en_US' || getAppSettingCammaSepratedCode ===  "fr_FR"   ? Number(splitedNumberValue).toLocaleString() + '.' + value[1] : Number(splitedNumberValue).toLocaleString('en-IN') + '.' + value[1];
          } else {
            value = parseFloat(convertControlValue.replace(/,/g, ''));
            newvalue = getAppSettingCammaSepratedCode === 'en_US' || getAppSettingCammaSepratedCode ===  "fr_FR"  ? value.toLocaleString() : value.toLocaleString('en-IN');
          }
        } else if (convertControlValue.charAt(0) === '-' && convertControlValue.length === 1) {
          value = convertControlValue;
          newvalue = value;
        } else {
          if (convertControlValue.length > 3) {
            value = parseFloat(convertControlValue.replace(/,/g, ''));
            newvalue = getAppSettingCammaSepratedCode === 'en_US' ||getAppSettingCammaSepratedCode ===  "fr_FR" ? value.toLocaleString() : value.toLocaleString('en-IN');
            console.log('newvalue', newvalue)
          } else {
            value = parseFloat(convertControlValue.replace(/,/g, ''));
            newvalue = getAppSettingCammaSepratedCode === 'en_US' || getAppSettingCammaSepratedCode ===  "fr_FR"  ? value.toLocaleString() : value.toLocaleString('en-IN');
          }
        }
      } else {
        newvalue = '';
      }
    }
  } 
}else {
    newvalue = '';
  }
  return newvalue;
}

export function isViewTemplate() {
  let viewTemplates: string[] = ['pChild', 'treeView'];
  let formModel: KagamiFormModel = getFormModel();
  return viewTemplates.includes(formModel.uiTemplate);
}

export function showGroupBackButton(formPresentationModel: any) {
  let showBackButton: boolean = false;
  let groupModel: KagamiGroupModel = getGroupModel();
  if ((formPresentationModel?.isGroupForm || formPresentationModel?.isGroupListModel) && (groupModel?.activeGroupModel.listTemplate != 'list' && groupModel?.activeGroupModel.listTemplate != 'grid')) {
    showBackButton = groupModel.processList.length > 1;
  }
  return showBackButton;
}

export function BackButtonToshowOrNot(templateType: string) {
  let TemplateType: string[] = ['list', 'pChild'];
  return TemplateType.includes(templateType);
}

export function isAllowableTemplate(uiTemplate: string) {
  let notAllowableTemplates: string[] = ['pChild', 'treeView'];
  return !notAllowableTemplates.includes(uiTemplate);
}

export function updateFormHierarchyAndOnloadData(kagamiFormModel: KagamiFormModel) {
  kagamiFormModel.formData.clearFormData();
  let hierarchyMap: Map<string, PresentationHierarchy> = buildHierarchy(kagamiFormModel.onStartData);
  kagamiFormModel.formData.mainEntityId = getMainEntityIdForPresentation(kagamiFormModel.onStartData);

  if (
    kagamiFormModel.onStartData['constructOutputData']['detailedObjects'] !== undefined &&
    kagamiFormModel.onStartData['constructOutputData']['detailedObjects'][kagamiFormModel.mainEntityId] !== undefined
  ) {
    let mainObject: any =
      kagamiFormModel.onStartData['constructOutputData']['detailedObjects'][kagamiFormModel.mainEntityId][0];
    kagamiFormModel.formData.data = kagamiFormModel.onStartData['constructOutputData']['detailedObjects'];
    kagamiFormModel.formData.detailedObjects =  cloneDeep(
      kagamiFormModel.onStartData['constructOutputData']['detailedObjects']
    );

    let mainObjectMap: any = new Map();
    if (mainObject && mainObject != null) {
      for (const id in mainObject) {
        mainObjectMap[id] = mainObject[id];
      }
    } else {
      let tempId: string = 'temp_' + uuidv4().toString().replace('-', '');
      mainObjectMap['id'] = tempId;
    }
    kagamiFormModel.onLoadData = CommonUtils.isNotEmpty(mainObject) ?  cloneDeep(mainObject) : {};
    kagamiFormModel.formData.formData = mainObjectMap;
    kagamiFormModel.formData.formDataId = mainObjectMap['id'];
    kagamiFormModel.inFocusFormDataIdentifiers[kagamiFormModel.mainEntityId] = mainObjectMap['id'];
  }

  kagamiFormModel.formData.entityHierarchies = hierarchyMap;
}

export function getNonFieldProcessName(nonFieldPresentation: any) {
  let processName: string = '';
  if (
    CommonUtils.isNotEmpty(nonFieldPresentation.metaData) &&
    CommonUtils.isNotEmpty(nonFieldPresentation.metaData['linkProcess'])
  ) {
    processName = nonFieldPresentation.metaData['linkProcess']['processName'];
  }
  return processName;
}

export function handleEmbededReset (embedFormPresentationModel : any){
   handleFormReset(embedFormPresentationModel.formEntityId)
   handleEmbeddedFormLoad(embedFormPresentationModel,embedFormPresentationModel.processName)
}

export function isEmbedDataModified(embedEntityId : string,formModel : KagamiFormModel){
  let embedModel : KagamiEmbeddedFormPresentationModel = formModel.formControls[embedEntityId];
  let dataModified : boolean = false;
  if(embedModel.isListPresentation){
    dataModified = CommonUtils.isNotEmpty(formModel.formData.formData[embedEntityId])
  }
  else{
    let activeRecord : any = getActiveFormRecord(formModel,embedEntityId)
    let onLoadData : any = embedModel.onLoadData;
    dataModified = !( isEqual(onLoadData,activeRecord));
  }
  return dataModified;
}

export function isFormTemplate(templateType : string){
  let formTypes : string[] = ['form','edit','pcEmbedForm','pcEmbedEdit','treeForm','treeEdit']
  return formTypes.includes(templateType);
}

export function isSingleLovOnchangeRequired(kagamiSelectModel : KagamiSelectModel){
  let makeOnchange : boolean = false;
  let formData : KagamiFormData = getFormModel().formData
  if( CommonUtils.isEmpty(kagamiSelectModel.controlValue) && kagamiSelectModel.lovRecords.length === 1 &&  formData.formData[kagamiSelectModel.attributeName] !== SystemConstants.REMOVED_){
    makeOnchange = true;
  }
  return makeOnchange;
}

export function  handleGroupBackButton  ()  {
  let triggerModel = getGroupModel().kagamiTriggerModel;
  if (triggerModel !== null) {
    onTriggerClick([], triggerModel, handleServerError, loadGroupContent, true, true);
  }
};

export function getActiveRecordForEmbedEditors(rowData:any,kagamiGridModel : KagamiGridModel){
  let formModel : KagamiFormModel = getFormModel();
  let activeRecord : any;
        if(CommonUtils.isNotEmpty(kagamiGridModel.parentEmbedIdForNestedEmbed)){
          let nestedEmbedModel : KagamiEmbeddedFormPresentationModel = formModel.formControls[kagamiGridModel.mainEntityId+'.'+kagamiGridModel.parentEmbedIdForNestedEmbed]
          if(nestedEmbedModel){
            let parentEmbedRecord : any = getActiveFormRecord(formModel,nestedEmbedModel.parentEmbedEntityId,nestedEmbedModel.parentEmbedIdForNestedEmbed)
            if(parentEmbedRecord){
              activeRecord = (parentEmbedRecord[kagamiGridModel.mainEntityId]).filter((record:any) => record.id == rowData['id'])
            }
            if(!activeRecord){
            activeRecord =   getActiveFormRecord(formModel,kagamiGridModel.mainEntityId)
            }
          }
        }
        else{
         activeRecord = getActiveFormRecord(formModel,kagamiGridModel.mainEntityId)
        }
        return activeRecord;
}

export function isDependentControlVisible(dependentOnEntity : string,dependentOnAttr : string){
    let visible : boolean = true;
    let kagamiFormModel : KagamiFormModel = getFormModel();
    let formPresentationModel : KagamiFormPresentationModel = kagamiFormModel.formControls[dependentOnEntity]
    if(CommonUtils.isNotEmpty(formPresentationModel)){
      let dependentOnAttrControlModel : KagamiControlModel = formPresentationModel.formControls[dependentOnEntity+'.'+dependentOnAttr]
      if(CommonUtils.isNotEmpty(dependentOnAttrControlModel)){
        visible = dependentOnAttrControlModel.visible
     }
    }
    return visible;
  }

export function handleGridAutoSizeColumns(kagamiGridModel :any) {
  let columnSizesArr = [];
  for (let index = 0; index < kagamiGridModel.textWidth.length; index++) {
    let textWidthObj = Object.assign({}, kagamiGridModel.textWidth[index]);
    let keys = _.keys(textWidthObj)
    let temp: any = {};
    _.forEach(keys, function (key) {
      if (key !== "minWidth") {
        temp[key] = textWidthObj[key];
      } else {
        temp[key] = isNaN(textWidthObj[key]) ? null : textWidthObj[key];
      }
    })
    columnSizesArr.push(temp);
  }
  return columnSizesArr;
}

export const customErrorCheck =(data:any) => {
  if (data?._errorCode) {
    showServerErrorPopUp(data)
  }
}
