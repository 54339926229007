import CloseIcon from '@mui/icons-material/Close';
import { Tabs } from '@mui/material';
import { KagamiStateManager } from '../../../../../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../../../../../constants/SystemConstants';
import { getKagamiListFilterModel } from '../../../controller/KagamiListFilterController';
import { handleToggle } from '../../kagamiPresentationFilter/builder/KagamiPresentationFilterBuilder';
import { SelectedFilterModel, SelectedFilterProps, useSelectedFilterState } from '../selectedFilterModel/selectedFIlterModel';
import { createState } from '@hookstate/core';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import { handleOnNumberFilterSearch } from '../../kagamiPresentationFilter/builder/KagamiPresentationNumberFilterBuilder';
import makeStyles from '@mui/styles/makeStyles';
import { selectedFilterStyle } from '../styles/selectedFilterStyle';
import { getFilterTypeIfHavingDatasource } from '../../kagamiPresentationFilter/util/kagamiPresentationFilterUtil';

export const SelectedFilter = (kagamiListModel: any, selectedFilterModel: SelectedFilterModel) => {
  return (
    <KagamiSelectorFilter kagamiListModel={kagamiListModel} selectedFilterModel={selectedFilterModel} />
  );
};

export const KagamiSelectorFilter = (props: SelectedFilterProps) => {
  const selectedFilterState: any = useSelectedFilterState(createState(props?.selectedFilterModel));
  props.selectedFilterModel.state = selectedFilterState;
  const filterData: any = JSON.parse(JSON.stringify(props?.selectedFilterModel?.filterCheckedData));
  console.log(filterData);
  const listFiltersModel = getKagamiListFilterModel();
  const listFilterAppliedState = KagamiStateManager.getStateToHolder(SystemConstants.CLEAR_LIST_FILTER_STATE);
  const classes = makeStyles(selectedFilterStyle)();
  console.log("SelectedFilterValue : ",selectedFilterState?.getSelectedFilterValue());
  return CommonUtils.isNotEmpty(selectedFilterState.getSelectedFilterValue()) ? (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      className={classes.selectedTabs}
    >
      {Object.keys(filterData).map((item: any) => {
        const selectedFilterValues = [...filterData[item]];
        const presentationFilterModel = listFiltersModel?.presentationFiltersApplied?.find(
          (e) => e.attributeName === item
        );

        if (presentationFilterModel) {
          const filterLabel = presentationFilterModel?.dataRecordsForPresentationAttribute?.label;
          const numberFilterControlType: any = presentationFilterModel?.dataRecordsForPresentationAttribute?.htmlControl;
          let lovFilterType: any = getFilterTypeIfHavingDatasource(presentationFilterModel);

          return (
            <div key={item} className={classes.selectedTabItemBoxes}>
              <div className={classes.selectedTabOpertorLabel}>{filterLabel}:</div>
              {selectedFilterValues?.map((filterValue: any, index: any) => (
                <div key={`${item}-${index}`} className={classes.selectedTabsContent}>
                  {(numberFilterControlType === "number" || lovFilterType === "number") ?
                    <>
                     {
                      filterValue?.symbol !== ":" ? 
                      <>
                        <span>{filterValue?.symbol}</span>
                        <span className={classes.selectedTabOpertorFieldValue}>{filterValue?.value}</span>
                      </>
                      :  
                      <>
                        <span>{filterValue?.value?.Min}</span>
                        <span className={classes.selectedTabRangeFieldSign}> - </span>
                        <span>{filterValue?.value?.Max}</span>
                      </>
                     }
                      <CloseIcon
                        onClick={() => handleOnNumberFilterSearch(
                          listFiltersModel?.searchObj,
                          props?.kagamiListModel,
                          props?.kagamiListModel?.presentation?.presentationId,
                          { id: filterValue?.id, value: filterValue?.value, symbol: filterValue?.symbol },
                          item, 
                          true 
                        )}
                        className={classes.selectedTabCloseIcon}
                      />

                    </> :
                    <>
                      <span>{filterValue}</span>
                      <CloseIcon
                        onClick={handleToggle(
                          filterValue,
                          item,
                          listFiltersModel?.searchObj,
                          listFilterAppliedState,
                          props?.kagamiListModel,
                          props?.kagamiListModel.presentation.presentationId,
                          listFiltersModel?.presentationFiltersApplied?.find((e) => e.attributeName === item) ?? props?.selectedFilterModel?.presentationFilterModel,
                          props?.selectedFilterModel
                        )}
                        className={classes.selectedTabCloseIcon}
                      />
                    </>
                  }
                </div>
              ))}
            </div>
          );
        }
        return null;
      })}
    </Tabs>
  )
    :null
};
