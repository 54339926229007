import makeStyles from '@mui/styles/makeStyles';
import { DashboardModelProps, DashboardType, KagamiDashboardModel } from '../model/KagamiDashboardModel';

import { KagamiDashboardStyle } from '../style/KagamiDashboard';
import InfoIcon from '@mui/icons-material/Info';
import {
  buildDashboardNotificationSections,
} from './NotificationBuilder';
import { buildDashboardFavouriteSection } from './FavouriteBuilder';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { DashboardGrid } from '../ui/KagamiDashboard';
import { CircularProgress } from '@mui/material';
import no_info from '../../../../../../../../assets/images/no_info.svg';
import { KagamiDashboardCardModel } from '../../../../../../../../components/cards/model/KagamiDashboardCardModel';
import { buildDashboardCard } from '../../../../../../../../components/cards/controller/KagamiDashboardCardController';
import { translateToLocalistaion } from '../../process/service/PresentationService';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { getHomeModel } from '../../../../../controller/KagamiHomeController';


export function buildDashboardSection(dashboardModel: KagamiDashboardModel) {
  const favouritesData = dashboardModel.state.getfavouritesData;
  const homeModel = getHomeModel();
  let dashboardComponents: any[] = [];
  if (homeModel.currentDashboardScreen === DashboardType.REPORTS) {
    const reportAndFavouriteSection = [...dashboardModel.reportDataUx];
    // const dashboardFavSection = buildDashboardFavouriteSection(favouritesData); 
    // reportAndFavouriteSection.push(dashboardFavSection)
    dashboardComponents.push(<DashboardGrid key={'dashboard-all-layout'} component={reportAndFavouriteSection} dashboardModel={dashboardModel}/>)
  } else if (homeModel.currentDashboardScreen === DashboardType.NOTIFICATIONS) {
    if( CommonUtils.isNotEmpty(dashboardModel.notificationData)) {
      dashboardComponents.push(buildDashboardNotificationSections(dashboardModel)); // 1
    } else {
      const dashInfoAndFavSection = [];
      const dashboardInfoNotification = buildInfoNotificationSection(dashboardModel)  // 3
      dashInfoAndFavSection.push(dashboardInfoNotification)
      if(CommonUtils.isNotEmpty(favouritesData)){
        const dashboardFavSection = buildDashboardFavouriteSection(favouritesData);
        dashInfoAndFavSection.push(dashboardFavSection)
      }
      dashboardComponents.push(<DashboardGrid key={'dashboard-info-and-fav-layout'} component={dashInfoAndFavSection} dashboardModel={dashboardModel}/>);
    }
  } 
  // else {  // not mandatory portion
  //   //dummy accordion till dashboard response comes
  //   const dashInfoNotiAndFavSection = [];
  //   const dashboardInfoNotification = buildInfoNotificationSection(dashboardModel)  // 2
  //   dashInfoNotiAndFavSection.push(dashboardInfoNotification);
  //   if(CommonUtils.isNotEmpty(favouritesData)){
  //     const dashboardFavSection = buildDashboardFavouriteSection(favouritesData);
  //     dashInfoNotiAndFavSection.push(dashboardFavSection)
  //   }
  //   // dashboardComponents.push(dashInfoNotiAndFavSection);
  //   dashboardComponents.push(<DashboardGrid key={'dashboard-default-layout'} component={dashInfoNotiAndFavSection} dashboardModel={dashboardModel}/>);
  // }
  return dashboardComponents;
}


export const buildInfoNotificationSection = (dashboardModel:KagamiDashboardModel) => {
  return <DashboardInfoNotificationSection key={SystemConstants.INFO_WIDGET_KEY} dashboardModel= {dashboardModel}/>;
};

const useStyles = makeStyles(KagamiDashboardStyle);
const DashboardInfoNotificationSection = (props:DashboardModelProps) => {
  const dashboardModel = props.dashboardModel;
  let translations = dashboardModel?.containerData?.otherProperties?.translation;
  const classes = useStyles();

  const title: JSX.Element = (
    <div className={classes.dashboard_icon_header}>
      <InfoIcon className={classes.dashboard_icon}></InfoIcon>
      <span className={classes.dashboard_header}>{translateToLocalistaion(translations, 'INFO')}</span>
    </div>
  );

  const content: JSX.Element = (dashboardModel.state.isDataLoaded) ? 
    <>
      <div className={classes.dashboardInfoBackgroundIcon}>
        <img src={no_info} alt="NO NOTIFICATIONS" height={'100%'} width={'100%'}/>
      </div>
      <p className={classes.noInfoText}>Hey, You have no info to show</p>
    </>
  : <div style={{display:'flex', alignItems:'center'}}><CircularProgress style={{height:'15px', width:'15px', marginRight:'5px'}}/> LOADING NOTIFICATIONS...!</div>;
  const dashboardCardModel = new KagamiDashboardCardModel(title, content);
  return <div className={`col_4 ${classes.dashboardCard}`} style={{width: 'inherit', height: 'inherit'}}>{buildDashboardCard(dashboardCardModel)}</div>;
};
