import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { KagamiListModel } from '../../../model/KagamiListModel';
import { KagamiGridPaginationModel } from '../builder/pagination/model/KagamiGridPaginationModel';
import { KagamiModalModel } from '../../../../../../../../../../../../components/modal/model/KagamiModalModel';

export class KagamiGridModel extends KagamiListModel {
  paginationModel: KagamiGridPaginationModel;
  gridApi?: any;
  gridColumnApi?: any;
  defaultEmbedFormView: string;
  activeRowNode?: any;
  nestedEmbedModels: any[];
  isMasterDetailEnabled?: boolean;
  paginationForList: boolean;
  paginationForEmbedForm: boolean;
  isListEmbedPopModel?: boolean;
  embedGridControls?: any;
  kagamiModalModel: KagamiModalModel;
  gridControls: any;
  isReadOnly: any;
  showGridDataCount: boolean;
  parentEmbedIdForNestedEmbed: string;
  isEmbedModel: boolean;
  hasHyperlink: boolean;
  isPivot: boolean;
  textWidth: any[];
  isAutoAdjust: boolean;
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  isFirst: boolean;
  rowId: number;
  lastRenderedRowNode: any;
  scrollDataLoaded: boolean;
  scrollEnd: boolean;
  searchValue: string;
  totalPages: number;
  skipValidate: boolean;
  embeddedPageSize: number;
  constructor(
    processName: string,
    processStepName: string,
    mainEntityId: string,
    presentation: any,
    paginationModel: KagamiGridPaginationModel
  ) {
    super(processName, processStepName, null, mainEntityId);
    this.state = null;
    this.presentation = presentation;
    this.paginationModel = paginationModel;
    this.gridApi = null;
    this.gridColumnApi = null;
    this.defaultEmbedFormView = 'modal';
    this.activeRowNode = null;
    this.nestedEmbedModels = [];
    this.isMasterDetailEnabled = false;
    this.isListEmbedPopModel = false;
    this.embedGridControls = {};
    this.kagamiModalModel = new KagamiModalModel();
    this.gridControls = {};
    this.isReadOnly = false;
    this.parentEmbedIdForNestedEmbed = '';
    this.showGridDataCount = true;
    this.isEmbedModel = false;
    this.hasHyperlink = false;
    this.isPivot = false;
    this.textWidth = [];
    this.isAutoAdjust = true;
    this.pageNumber = 1;
    this.pageSize = 0;
    this.totalRecords = 0;
    this.totalPages = 0;
    this.isFirst = false;
    this.rowId = 0;
    this.scrollDataLoaded = true;
    this.scrollEnd = false;
    this.searchValue = '';
    this.skipValidate = false;
    this.embeddedPageSize = 0;
    this.paginationForList = false;
    this.paginationForEmbedForm = false;
  }
}

export interface KagamiGridProps {
  kagamiGridModel: KagamiGridModel;
}
export const wrapKagamiGridState = (state: State<KagamiGridModel>) => {
  let listState = state;

  return {
    getListLoaded() {
      return listState.isListLoaded.get();
    },
    setListLoaded(value: boolean) {
      listState.isListLoaded.set(value);
    },
    gettotalRecords() {
      return listState.totalRecords.get();
    },
    settotalRecords(value: number) {
      listState.totalRecords.set(value);
    },

    get masterDetailEnabled() {
      let embedFormModel: any;
      let nestedEmbedModels: any[] = listState.nestedEmbedModels.get();
      for (embedFormModel of nestedEmbedModels) {
        if (embedFormModel.visible) {
          return true;
        }
      }
      return listState.isMasterDetailEnabled.get();
    },
    setMasterDetaileEnabled(isEnable: boolean) {
      listState.isMasterDetailEnabled.set(isEnable);
    }
  };
};

export const useKagamiGridState = (state: State<KagamiGridModel>, listIdentifier: string) => {
  const kagamiGridState = wrapKagamiGridState(useState(state));
  KagamiStateManager.addStateToHolder(listIdentifier, kagamiGridState);
  return kagamiGridState;
};
