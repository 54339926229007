import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';

export class KagamiBreadcrumbsModel {
  activeModel: any;
  backwardCall: boolean;
  breadcrumbs: CrumbType[];
  isOnLoadRequired: boolean;
  state: any;
  showBreadCrumb: boolean;
  constructor() {
    this.backwardCall = false;
    this.isOnLoadRequired = false;
    this.breadcrumbs = [];
    this.showBreadCrumb = true;
  }

  resetBreadcrumbs() {
    this.breadcrumbs = [];
    this.activeModel = null;
    this.backwardCall = false;
    this.isOnLoadRequired = false;
  }
}

export interface KagamiBreadcrumbsModelProps {
  kagamiBreadcrumbsModel: KagamiBreadcrumbsModel;
}

export type CrumbType = {
  headerName: string;
  activeModel: any;
  detailedObjects: any;
};

export const useKagamiBreadcrumbsState = (state: State<KagamiBreadcrumbsModel>) => {
  let breadcrumbState = useState(state);
  KagamiStateManager.addStateToHolder(SystemConstants.BREADCRUMBS_STATE, breadcrumbState);
  return {
    get isOnLoadRequired() {
      return breadcrumbState.isOnLoadRequired.get();
    },
    setOnLoadRequired(isOnLoadRequired: boolean) {
      breadcrumbState.isOnLoadRequired.set(isOnLoadRequired);
    }
  };
};
