import delay from 'lodash/delay';
import cloneDeep from 'lodash/cloneDeep';
import { KagamiModalModel } from '../../../../../../../../../../../../../../components/modal/model/KagamiModalModel';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import { isVisibleAttribute, retrievePresentation } from '../../../../../../../service/PresentationService';
import { reBuildGridView } from '../../../../../../list/builder/grid/controller/KagamiGridController';
import { KagamiGridModel } from '../../../../../../list/builder/grid/model/KagamiGridModel';
import { getFirstEditableColumn } from '../../../../../../list/builder/grid/service/GridUtilService';
import { setKagamiListModel } from '../../../../../../list/controller/KagamiListController';
import { getFormModel, showValidationError } from '../../../../../controller/KagamiFormController';
import { KagamiEmbeddedFormPresentationModel } from '../../../../../model/KagamiEmbeddedFormPresentationModel';
import { KagamiFormModel } from '../../../../../model/KagamiFormModel';
import {
  cancelEmbedChanges,
  clearEmbeddedRecord,
  fireOnCancelEventIfRequired,
  getFormRecords,
  getRecordId,
  handleEmbeddedFormLoad,
  updateEmbedGridControls,
  updateInFocusMap,
  _updateControlsWithFormData
} from '../../../../../service/FormService';
import { validate } from '../../../../../service/FormValidationService';
import { KagamiExpandedEmbeddedPresentationModel } from '../../expanded-embedded-presentation/model/KagamiExpandedEmbeddedPresentationModel';
import { getEmbedHeaderForModal, getExpandedEmbedContentForModal } from '../builder/EmbeddedFormBuilder';
import { KagamiEmbeddedAccordionPresentationModel } from '../model/KagamiEmbeddedAccordionPresentationModel';
import { buildEmbeddedAccordionComponent } from '../ui/KagamiEmbeddedAccordionPresentation';
import { getDynamicModalWidth } from '../../../../../../../../../../../../../../components/modal/controller/KagamiModalController';
import { Breakpoint } from '@mui/material';
import { KagamiGridPaginationModel } from '../../../../../../list/builder/grid/builder/pagination/model/KagamiGridPaginationModel';
import { getEmbedTotalRecords, getEmbedStartRecord, getEmbedEndRecord } from '../../../../../../list/service/kagamiListService';
import { appContext } from '../../../../../../../service/PolicyExecutionService';

export function buildEmbeddedAccordionPresentation(
  kagamiEmbeddedAccordionPresentationModel: KagamiEmbeddedAccordionPresentationModel
) {
  return buildEmbeddedAccordionComponent(kagamiEmbeddedAccordionPresentationModel);
}

export function releaseEmbedForm(embedFormPresentationModel: KagamiEmbeddedFormPresentationModel) {
  embedFormPresentationModel.formDataIdentifier = '';
  embedFormPresentationModel.hasChanges = false;
}

export function resetEmbedFormPresentation(embeddedFormPresentationModel : KagamiEmbeddedFormPresentationModel){
  embeddedFormPresentationModel.isNewRecord = false;
  embeddedFormPresentationModel.formDataIdentifier = '';
  embeddedFormPresentationModel.isActiveForm = false;
  embeddedFormPresentationModel.oldEditRecord = null;
  let gridModel : KagamiGridModel | undefined = embeddedFormPresentationModel.kagamiGridModels.get(embeddedFormPresentationModel.formEntityId)
  if(gridModel){
    gridModel.activeRowNode = null;
    let actions = gridModel.actions;
    if(actions.has('edit')){

    }
  }
  
}

export async function addRecord(embeddedFormPresentationModel: KagamiExpandedEmbeddedPresentationModel) {
  console.log('Clicked on add record' + embeddedFormPresentationModel.actions.length);
  
  ///Note : pushing expanded embed model into the form controls to put it in the context
  let kagamiFormModel : KagamiFormModel = getFormModel();
  let listModel: KagamiGridModel | undefined = embeddedFormPresentationModel.kagamiGridModels.get(embeddedFormPresentationModel.formEntityId);
  if(CommonUtils.isNotEmpty(listModel?.activeRowNode)){
    delay(() =>{
      let validations = validate(kagamiFormModel, embeddedFormPresentationModel.formEntityId,true);
      validations.size === 0 ? addRecordToEmbed(kagamiFormModel,embeddedFormPresentationModel,listModel) : showValidationError(kagamiFormModel, validations);
    },100)
    
  }
  else if(
     kagamiFormModel.uiTemplate === 'pcEmbedForm' && CommonUtils.isNotEmpty(listModel?.detailedObjects)
     ){
      delay(() =>{
      let validations = validate(kagamiFormModel, embeddedFormPresentationModel.formEntityId,true);
      validations.size === 0 ? addRecordToEmbed(kagamiFormModel,embeddedFormPresentationModel,listModel) : showValidationError(kagamiFormModel, validations);
    },100)
  }
  else{
    addRecordToEmbed(kagamiFormModel,embeddedFormPresentationModel,listModel)
  }
  
}

function addRecordToEmbed(kagamiFormModel : KagamiFormModel,embeddedFormPresentationModel : KagamiExpandedEmbeddedPresentationModel,listModel : KagamiGridModel | undefined){
  /// Note : if nested opened in inline we have to fetch nested embed model with parent embed id 
  /// if nested is opened in embed pop up form we need fetch accodion embed model 
  /// we have handled those 2 cases here
  if(embeddedFormPresentationModel.isNested){
    let parentEmbedModel : KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[embeddedFormPresentationModel.parentEmbedEntityId]
    if(!parentEmbedModel.isActiveForm){
      kagamiFormModel.formControls[embeddedFormPresentationModel.formEntityId+'.'+embeddedFormPresentationModel.parentEmbedIdForNestedEmbed] = embeddedFormPresentationModel;
    }
    else{
      kagamiFormModel.formControls[embeddedFormPresentationModel.formEntityId] = embeddedFormPresentationModel;
    }
  }
  else{
    kagamiFormModel.formControls[embeddedFormPresentationModel.formEntityId] = embeddedFormPresentationModel;
  }
  embeddedFormPresentationModel.formDataIdentifier = '';
  embeddedFormPresentationModel.isNewRecord = true;
  embeddedFormPresentationModel.formDataIdentifier = getRecordId(kagamiFormModel, embeddedFormPresentationModel);
  embeddedFormPresentationModel.onLoadData = undefined;
  
  if (listModel) setKagamiListModel(listModel);
  if(listModel instanceof KagamiGridModel){
    if(listModel.defaultEmbedFormView === 'modal'){
      embeddedFormPresentationModel.isActiveForm = true;
      updateInFocusMap(listModel.mainEntityId,embeddedFormPresentationModel.formDataIdentifier,getFormModel())
      handleEmbeddedFormLoad(embeddedFormPresentationModel, embeddedFormPresentationModel.processName);
      let popupModel : KagamiModalModel = listModel.kagamiModalModel
      popupModel.isTriggerModal = true;
      popupModel.title = getEmbedHeaderForModal(embeddedFormPresentationModel, listModel,'Add');
      popupModel.content = getExpandedEmbedContentForModal(embeddedFormPresentationModel)
      popupModel.handleOnOk = onEmbedModalClose
      popupModel.handleOnClose = onEmbedModalCancel
      // popupModel.modalWidth = 'lg'
      //calculating the dynamic width of modal based on formComponents 
      popupModel.modalWidth =  getDynamicModalWidth(embeddedFormPresentationModel) ;
      popupModel.state.setOpen(true);
      // getFormModel().formControlStates[embeddedFormPresentationModel.formEntityId+'modal'].setModalState(true);
    }
    else{
      const newRow = getNewRow(listModel,embeddedFormPresentationModel.formDataIdentifier)
      let response = listModel.gridApi.applyTransaction({
        add : newRow,
        addIndex : 0
      })
      updateInFocusMap(listModel.mainEntityId,embeddedFormPresentationModel.formDataIdentifier,getFormModel())
      handleEmbeddedFormLoad(embeddedFormPresentationModel, embeddedFormPresentationModel.processName);
      if(CommonUtils.isEmpty(listModel.detailedObjects)){
        listModel.detailedObjects = [];
      }
      let newRowNode = response['add'][0];
      listModel.activeRowNode = newRowNode;
      let recordAlreadyExists : boolean = false;
      listModel.detailedObjects.forEach((record : any) => {
        if(record.id === newRowNode.data.id){
          recordAlreadyExists = true;
        }
      });
      /// Note : While creating record id because of add child object in formdata function data is already getting added in list model detail objects so we put a check here...
      if(!recordAlreadyExists){
        listModel.detailedObjects.push(newRowNode.data);
      }
      if(embeddedFormPresentationModel.isListPresentation && CommonUtils.isNotEmpty(embeddedFormPresentationModel.rowCountModel.formEntityId)){
        embeddedFormPresentationModel.rowCountModel.state.setDataCount(listModel.detailedObjects ? listModel.detailedObjects.length : 0)
      }

      setTimeout(()=>{
        // Enable edit by defulat on Add for Inline
        const colDefs = listModel.gridApi?.columnModel?.getAllDisplayedColumns();
        const firstEditableCol = getFirstEditableColumn(colDefs);
        if(firstEditableCol){
          listModel.gridApi.startEditingCell({
            rowIndex: 0,
            colKey: firstEditableCol
          });
        } 
      },500);
    }
  }
}

function getNewRow(gridModel : KagamiGridModel,formDataId : string){
  let newRow : any = {id : formDataId}
  let attributeName,attributePresentation : any;
  for([attributeName,attributePresentation] of Object.entries(gridModel.presentation.presentationRules)){
    if(attributePresentation['@type'] === 'FieldPresentation' && isVisibleAttribute(attributePresentation) && attributePresentation['defaultValue'] !== undefined){
      newRow[attributeName] = attributePresentation['defaultValue'];
    }
  }
  return [newRow];
}

export function editRecord(embedFormPresentationModel: KagamiExpandedEmbeddedPresentationModel,embeddedFormView : string,formDataId : string, callBackFn?: any) {
  console.log('Clicked on edit record');
  let kagamiFormModel : KagamiFormModel = getFormModel();
  ///Note : pushing expanded embed model into the form controls to put it in the context
  // formModel.formControls[embedFormPresentationModel.formEntityId] = embedFormPresentationModel;
  if(embedFormPresentationModel.isNested){
    let parentEmbedModel : KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[embedFormPresentationModel.parentEmbedEntityId]
    if(!parentEmbedModel.isActiveForm){
      kagamiFormModel.formControls[embedFormPresentationModel.formEntityId+'.'+embedFormPresentationModel.parentEmbedIdForNestedEmbed] = embedFormPresentationModel;
    }
    else{
      kagamiFormModel.formControls[embedFormPresentationModel.formEntityId] = embedFormPresentationModel;
    }
  }
  else{
    kagamiFormModel.formControls[embedFormPresentationModel.formEntityId] = embedFormPresentationModel;
  }

  let listModel: KagamiGridModel | undefined = embedFormPresentationModel.kagamiGridModels.get(
    embedFormPresentationModel.formEntityId
  );
  /// Note : setting active record id as form data identifier
  if(CommonUtils.isNotEmpty(formDataId)){
    embedFormPresentationModel.formDataIdentifier = formDataId;
    if(listModel){
      if(CommonUtils.isNotEmpty(listModel.gridApi)) {
      listModel.activeRowNode = listModel?.gridApi?.getRenderedNodes().find((node : any) => node.data.id === formDataId)
    }
  }
  }
  else if(CommonUtils.isNotEmpty(listModel?.activeRowNode)){
    embedFormPresentationModel.formDataIdentifier = listModel?.activeRowNode.data.id;
  }
/// Note : updating parent infocus id in case of nested record is getting edited so that parent and child will be in sync against the entity id's
  if (embedFormPresentationModel.isNested && CommonUtils.isNotEmpty(listModel?.parentEmbedIdForNestedEmbed)) {
    let entityHierarchy = kagamiFormModel.formData.entityHierarchies.get(embedFormPresentationModel.presentationId)
    let parentEntityId : string = entityHierarchy.parentEntity
    updateInFocusMap(parentEntityId,listModel?.parentEmbedIdForNestedEmbed || '',kagamiFormModel)
    embedFormPresentationModel = kagamiFormModel.formControls[listModel?.mainEntityId + '.' + listModel?.parentEmbedIdForNestedEmbed]
  }
  handleEmbeddedFormLoad(embedFormPresentationModel, embedFormPresentationModel.processName);
  if (listModel) setKagamiListModel(listModel);
  if(listModel instanceof KagamiGridModel){
    if(embeddedFormView === 'modal'){
      embedFormPresentationModel.isActiveForm = true;
      embedFormPresentationModel.isNewRecord = false;
      embedFormPresentationModel.oldEditRecord = cloneEditRecord(listModel.detailedObjects.find((record : any) => record.id === embedFormPresentationModel.formDataIdentifier));
      let popupModel : KagamiModalModel = listModel.kagamiModalModel
      popupModel.isTriggerModal = true;
      popupModel.title = getEmbedHeaderForModal(embedFormPresentationModel, listModel,'Edit');
      popupModel.content = getExpandedEmbedContentForModal(embedFormPresentationModel)
      popupModel.handleOnOk = onEmbedModalClose
      popupModel.handleOnClose = onEmbedModalCancel;

      //dynamicModalWidth
      let dynamicModalWidth:Breakpoint = getDynamicModalWidth(embedFormPresentationModel);
      popupModel.modalWidth = dynamicModalWidth
      // popupModel.modalWidth = 'md'
      popupModel.state.setOpen(true);
      // formModel.formControlStates[embedFormPresentationModel.formDataIdentifier + 'modal'].setModalState(true);
    }
  }
  if(callBackFn){
    callBackFn();
  }
}

function cloneEditRecord(record : any){
  let clonedRecord : any = cloneDeep(record)
  return clonedRecord;
}

export function removeRecord(embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel) {
  console.log('Clicked on delete record');
  let listModel: KagamiGridModel | undefined = embeddedFormPresentationModel.kagamiGridModels.get(
    embeddedFormPresentationModel.formEntityId
  );
  if (listModel) setKagamiListModel(listModel);
  clearEmbeddedRecord(embeddedFormPresentationModel);
  if(CommonUtils.isNotEmpty(listModel?.activeRowNode) && listModel?.activeRowNode !== undefined) {
    /// Note : commented this because on delete of multiple records continuously getting grid error, Temporary fix need to check permanent solution
    // setTimeout(function() { 
    //   listModel?.gridApi.applyTransaction({remove : [listModel.activeRowNode.data]})      
    // }, 30)
    listModel.activeRowNode = null;
  }
  resetEmbedFormPresentation(embeddedFormPresentationModel);
  fireOnCancelEventIfRequired(getFormModel(), embeddedFormPresentationModel);
  updateEmbeddedList(embeddedFormPresentationModel);
  delay(()=>{
    if(listModel){
      listModel.skipValidate = false;
    }
  },150)
}

export function handleEmbeddedModalClose(expandedEmbeddedFormPresentationModel: KagamiExpandedEmbeddedPresentationModel) {
  let kagamiFormModel: KagamiFormModel = getFormModel();
  expandedEmbeddedFormPresentationModel.isActiveForm = false;
  let initialEmbedFormPresentationModel = expandedEmbeddedFormPresentationModel.initialEmbedFormPresentationModel;
  kagamiFormModel.formControls[expandedEmbeddedFormPresentationModel.formEntityId] = initialEmbedFormPresentationModel;
  let validations = validate(kagamiFormModel, expandedEmbeddedFormPresentationModel.formEntityId,false);
  if (validations.size === 0) {
   let dataRecords: any = getFormRecords(initialEmbedFormPresentationModel);
    initialEmbedFormPresentationModel.onLoadData = dataRecords;   
    updateEmbeddedList(expandedEmbeddedFormPresentationModel);
    getFormModel().formControlStates[expandedEmbeddedFormPresentationModel.formEntityId + 'modal'].setModalState(false);
  } else {
    showValidationError(kagamiFormModel, validations);
  }
}

export function onEmbedModalClose(expandedEmbeddedFormPresentationModel: KagamiExpandedEmbeddedPresentationModel){
  let kagamiFormModel: KagamiFormModel = getFormModel();
  let validations = validate(kagamiFormModel, expandedEmbeddedFormPresentationModel.formEntityId,false);
  if (validations.size === 0) {
    expandedEmbeddedFormPresentationModel.isActiveForm = false;
    let initialEmbedFormPresentationModel = expandedEmbeddedFormPresentationModel.initialEmbedFormPresentationModel;
    if(expandedEmbeddedFormPresentationModel.isNested){
      let parentEmbedModel : KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[expandedEmbeddedFormPresentationModel.parentEmbedEntityId]
      if(!parentEmbedModel.isActiveForm){
        kagamiFormModel.formControls[expandedEmbeddedFormPresentationModel.formEntityId+'.'+expandedEmbeddedFormPresentationModel.parentEmbedIdForNestedEmbed] = initialEmbedFormPresentationModel;
      }
      else{
        kagamiFormModel.formControls[expandedEmbeddedFormPresentationModel.formEntityId] = initialEmbedFormPresentationModel;
      }
    }
    else{
      kagamiFormModel.formControls[expandedEmbeddedFormPresentationModel.formEntityId] = initialEmbedFormPresentationModel;
    }
    let kagamiGridModel : KagamiGridModel | undefined = initialEmbedFormPresentationModel.kagamiGridModels.get(initialEmbedFormPresentationModel.formEntityId);
    if(kagamiGridModel !== undefined){
      kagamiGridModel.isAutoAdjust = true;
      updateEmbedGridControls(kagamiGridModel,getFormRecords(expandedEmbeddedFormPresentationModel),expandedEmbeddedFormPresentationModel.isNested,initialEmbedFormPresentationModel)
    }
    // updateEmbeddedList(expandedEmbeddedFormPresentationModel);
   if(kagamiGridModel){
     let popupModel : KagamiModalModel = kagamiGridModel.kagamiModalModel;
     resetEmbedFormPresentation(expandedEmbeddedFormPresentationModel);
     popupModel.isTriggerModal = false;
     popupModel.state.setOpen(false);
     delete kagamiFormModel.formData.data[expandedEmbeddedFormPresentationModel.formEntityId]
     delay(()=>{
         if(expandedEmbeddedFormPresentationModel.isNested){
           delete kagamiFormModel.inFocusFormDataIdentifiers[expandedEmbeddedFormPresentationModel.formEntityId];
         }
     },100)
   }
    // getFormModel().formControlStates[expandedEmbeddedFormPresentationModel.formEntityId + 'modal'].setModalState(false);
  } else {
    showValidationError(kagamiFormModel, validations);
  }
}

export function onEmbedModalCancel(expandedEmbeddedFormPresentationModel: KagamiExpandedEmbeddedPresentationModel){
  let kagamiFormModel : KagamiFormModel = getFormModel();
  let isNewRecord : boolean = expandedEmbeddedFormPresentationModel.isNewRecord
  let formDataId : string = expandedEmbeddedFormPresentationModel.formDataIdentifier
  kagamiFormModel.formControls[expandedEmbeddedFormPresentationModel.formEntityId] = expandedEmbeddedFormPresentationModel.initialEmbedFormPresentationModel;
  let kagamiGridModel : KagamiGridModel | undefined= expandedEmbeddedFormPresentationModel.initialEmbedFormPresentationModel.kagamiGridModels.get(expandedEmbeddedFormPresentationModel.formEntityId)
  cancelEmbedChanges(expandedEmbeddedFormPresentationModel);
  delete kagamiFormModel.formData.data[expandedEmbeddedFormPresentationModel.formEntityId]
  if(isNewRecord){
    let renderedNodes : any[]
    renderedNodes = kagamiGridModel?.gridApi?.getRenderedNodes();
    if(CommonUtils.isNotEmpty(renderedNodes)){
      let cancelledNode : any = renderedNodes.find((node : any) => node.data.id === formDataId)
      if(cancelledNode){
        kagamiGridModel?.gridApi.applyTransaction({remove : [cancelledNode.data]})
      }
    }
  }
  if(kagamiGridModel){
    kagamiGridModel.kagamiModalModel.isTriggerModal = false;
    kagamiGridModel.kagamiModalModel.state.setOpen(false);
  }
}

export function handleEmbeddedModalCancel(expandedEmbeddedFormPresentationModel: KagamiExpandedEmbeddedPresentationModel) {
  getFormModel().formControls[expandedEmbeddedFormPresentationModel.formEntityId] = expandedEmbeddedFormPresentationModel.initialEmbedFormPresentationModel;
  getFormModel().formControlStates[expandedEmbeddedFormPresentationModel.formEntityId + 'modal'].setModalState(false);
  cancelEmbedChanges(expandedEmbeddedFormPresentationModel);
}

export function updateEmbeddedList(embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel) {
  let formModel = getFormModel();
  let paginationModel = new KagamiGridPaginationModel(
    false,
    false,
    getEmbedTotalRecords(formModel?.embeddedInfo, embeddedFormPresentationModel?.formEntityId),
    getEmbedStartRecord(formModel?.embeddedInfo, embeddedFormPresentationModel?.formEntityId),
    getEmbedEndRecord(formModel?.embeddedInfo, embeddedFormPresentationModel?.formEntityId),
    embeddedFormPresentationModel?.mainEntityId,
    embeddedFormPresentationModel?.processName,
    retrievePresentation(embeddedFormPresentationModel?.onStartData)
  );
  let records =getFormRecords(embeddedFormPresentationModel)
  let dataRecords: any = CommonUtils.isNotEmpty(records) ? records : [];
  let listModel: KagamiGridModel | undefined = embeddedFormPresentationModel.kagamiGridModels.get(
    embeddedFormPresentationModel.formEntityId
  );
  if(embeddedFormPresentationModel.isListPresentation && CommonUtils.isNotEmpty(embeddedFormPresentationModel.rowCountModel.formEntityId) && Array.isArray(dataRecords)){
    embeddedFormPresentationModel.rowCountModel.state.setDataCount(dataRecords.length)
  };
  if (listModel && listModel !== undefined ) {
    if (CommonUtils.isNotEmpty(appContext) && CommonUtils.isNotEmpty(appContext?.AppSetting[0])) {
      listModel.embeddedPageSize = appContext.AppSetting[0]?.embeddedPageSize;
    }  
    listModel.isAutoAdjust = true;
    listModel?.paginationModel?.state?.setStartRecord(paginationModel?.startRecord);
    listModel?.paginationModel?.state?.setEndRecord(paginationModel?.endRecord);  
    listModel.paginationModel = paginationModel;
    let totalPages = Number(paginationModel?.totalRecords) / Number(listModel?.embeddedPageSize);
    listModel.totalPages = Math.ceil(totalPages);  
    reBuildGridView(listModel, records ?? [], listModel.mainEntityId, true, false);
  }
}
 