import { useEffect } from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { FormControl, FormHelperText, FormLabel, IconButton, MenuItem, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SystemConstants } from '../../../../../../../../../../constants/SystemConstants';
import { getDmsViewUrl } from '../../../../../../../../../../service/URLService';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { extractDropdownOption, isLoadRequiredForPresentation } from '../../../../service/PresentationService';
import { getFormModel } from '../../../form/controller/KagamiFormController';
import { KagamiFormModel } from '../../../form/model/KagamiFormModel';
import { isSingleLovOnchangeRequired } from '../../../form/service/FormService';
import { KagamiTriggerController } from '../../../Triggers/builder/trigger/controller/KagamiTriggerController';
import { validateSelectTextBox } from '../controller/kagamiSelectController';
import { SelectFieldProps, useKagamiSelectModelState } from '../model/kagamiSelectModel';
import { KagamiSelectFieldStyle } from '../styles/KagamiSelectFieldStyle';

const useStyles = makeStyles(KagamiSelectFieldStyle);

function getImageFieldforSelectFields(value: any) {
  if (value && value !== '' && value != undefined) {
    return <img src={getDmsViewUrl(value.includes(':') ? value.split(':')[0] : value)} height="20" width="20" />;
  }
}

function SelectField(props: SelectFieldProps) {
  const classes = useStyles();
  const fontProperties = props.kagamiSelectModel.getFontStyling(props.kagamiSelectModel.uiSettings);
  const uiSettingsClassName = props.kagamiSelectModel.getControlWidth(props.kagamiSelectModel.uiSettings);

  const fieldState:any = useKagamiSelectModelState(props.kagamiSelectModel.createState);
  props.kagamiSelectModel.state = fieldState;

  getFormModel().formControlStates[props.kagamiSelectModel.controlIdentifier] = fieldState;

  useEffect(() => {
    const formModel: KagamiFormModel = getFormModel();

    /// Note : Avoiding single lov on change in case the form has onload call to avoid conflicts
    if (
      !isLoadRequiredForPresentation(formModel.onStartData) &&
      !props.kagamiSelectModel.readonly &&
      props.kagamiSelectModel.entityId === formModel.mainEntityId &&
      isSingleLovOnchangeRequired(props.kagamiSelectModel)
    ) {
      let associatedObject: any = props.kagamiSelectModel.lovRecords[0];
      props.kagamiSelectModel.associatedObject = associatedObject;
      fieldState.setControlValue(associatedObject['id']);
      validateSelectTextBox(props.kagamiSelectModel, associatedObject['id']);
    }
  }, []);

  const handleClearClick = () => {
    props.kagamiSelectModel.associatedObject = null;
    fieldState.setControlValue(null);
    validateSelectTextBox(props.kagamiSelectModel, null);
  };

const handleFocus = (value:boolean) =>{
  fieldState.setcancelVisible(value)
}
  let lovRecords = CommonUtils.isNotEmpty(fieldState.lovRecords)
    ? fieldState.lovRecords
    : CommonUtils.isNotEmpty(fieldState.associatedObject)
    ? [fieldState.associatedObject]
    : [];
  let formModel: KagamiFormModel = getFormModel();
  formModel.triggerAlertModalModel.alertName = 'Warning';
  return (
    <div className={`${uiSettingsClassName} ${classes.root}`}>
      {fieldState.isReadOnly === false ? (
        <FormControl variant="outlined" fullWidth size="small">
          <FormLabel
            component="span"
            required={fieldState.isMandatory}
            style={{
              color: fontProperties.labelFontColor,
              fontWeight: fontProperties.labelFontBold,
              fontStyle: fontProperties.labelFontItalic,
              textDecoration: fontProperties.labelFontUnderline
            }}
          >
            {props.kagamiSelectModel.controlDisplayName}
          </FormLabel>
          <Select
            id={props.kagamiSelectModel.controlDisplayName}
            className={classes.selectRoot}
            MenuProps={{ classes: { paper: classes.selectPaper } }}
            error={fieldState.errorMessage ? true : false}
            disabled={fieldState.isReadOnly}
                onMouseOver={() => handleFocus(true)}
                onMouseLeave={() => handleFocus(false)}
                onFocus={() => handleFocus(true)}
            value={fieldState.controlValue || SystemConstants.EMPTY}
            inputProps={{ 'aria-label': 'Without label' }}
            color="primary"
            style={{ color: fontProperties.controlFontColor }}
            notched={true}
            onChange={(e: any) => {
              let associatedObject: any = props.kagamiSelectModel.lovRecords.find(
                (record) => record.id === e.target.value.id
              );
              props.kagamiSelectModel.associatedObject = associatedObject;
              fieldState.setControlValue(e.target.value);
              validateSelectTextBox(props.kagamiSelectModel, e.target.value);
            }}
            endAdornment={
              fieldState.cancelVisible && (
                <IconButton
                  id='clearIconIdForSelect'
                  size="small"
                  // className='visible'
                  sx={{
                    visibility: CommonUtils.isNotEmpty(fieldState.controlValue) ? 'visible' : 'hidden',
                    mr: '12px'
                  }}
                  onClick={handleClearClick}
                >
                  <ClearOutlinedIcon sx={{ color: (theme: any) => theme.palette.error.main }} fontSize="small" />
                </IconButton>
              )
            }
            renderValue={(e) => {
              return extractDropdownOption(
                props.kagamiSelectModel.attributePresentation,
                props.kagamiSelectModel.associatedObject
              );
            }}
          >
            {lovRecords.map((lovRecord: any) =>
              lovRecord.fileUrl ? (
                <MenuItem
                  key={lovRecord.id}
                  value={lovRecord.id}
                >
                  {getImageFieldforSelectFields(lovRecord.fileUrl)}
                  {extractDropdownOption(props.kagamiSelectModel.attributePresentation, lovRecord, true)}
                </MenuItem>
              ) : (
                <MenuItem 
                  key={lovRecord.id} 
                  value={lovRecord.id}
                >
                  {extractDropdownOption(props.kagamiSelectModel.attributePresentation, lovRecord, true)}
                </MenuItem>
              )
            )}
          </Select>
          <FormHelperText error={fieldState.errorMessage ? true : false}>{fieldState.errorMessage}</FormHelperText>
        </FormControl>
      ) : (
        <div className={classes.readOnlyField_cont} onClick={KagamiTriggerController.handleHyperLinkClick}>
          <FormLabel
            component="span"
            className={classes.controlLabel}
            style={{ color: fontProperties.labelFontColor }}
            required={fieldState.isMandatory}
          >
            {props.kagamiSelectModel.controlDisplayName}
          </FormLabel>
          {fieldState.controlValue === undefined ? (
            <p className={classes.readOnlyField}> ----- </p>
          ) : (
            <p className={classes.readOnlyField} style={{ color: fontProperties.controlFontColor }}>
              {extractDropdownOption(props.kagamiSelectModel.attributePresentation, fieldState.associatedObject)}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default SelectField;