import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const KagamiFilterStyle = (theme: Theme) =>
  createStyles({
    root: {
      '& .MuiGrid-root': {
        'paddingBottom': '9px',
        '& .MuiGrid-item': {
          color: 'rgb(113 113 113)'
        },
        '& .css-1g1yugt-MuiButtonBase-root-MuiButton-root': {
          marginTop: '7px'
        }
      },
      '& .MuiFormControl-root': {
        '& .MuiTextField-root': {
          '& .MuiSvgIcon-root': {
            color: 'rgb(139 139 139)',
            paddingRight: '5px'
          },
          '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
            padding: '0px !important'
          },
          '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover': {
            backgroundColor: 'transparent !important'
          },
          '& .MuiOutlinedInput-input': {
            color: 'hsl(0deg 0% 71.76%)'
          }
        },

        '& .MuiFormGroup-root': {
          'backgroundColor': 'hsl(0deg 0% 94.9%)',
          'marginTop': '10px',
          'paddingLeft': '10px',
          'borderRadius': '5px',
          'fontWeight': 'normal',
          '& .css-11zohuh-MuiSvgIcon-root': {
            color: 'rgb(11 177 151) !important'
          }
        },
        '& .MuiOutlinedInput-input': {
          width: '70%'
        },
        '& .MuiFormControlLabel-root': {
          color: 'rgb(132 132 132)'
        },
        '& .MuiRadio-root': {
          color: 'rgb(132 132 132)'
        }
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: '1px solid rgb(196 196 196) !important'
        },
        '&:hover fieldset': {
          border: '1px solid gray !important'
        },
        '&.Mui-focused fieldset': {
          border: '1px solid gray !important'
        }
      }
    },
    labelName: {
      textAlign: 'right'
    },
    datePicker: {
      position: 'relative'
    },
    closeButton: {
      position: 'absolute',
      color: '#f05252',
      top: '8px',
      right: '30px',
      fontSize: '1.25rem',
      cursor: 'pointer'
    },
    closeButtonForEvent: {
      position: 'absolute',
      color: '#f05252',
      top: '1px',
      right: '-56px',
      fontSize: '1.25rem',
      cursor: 'pointer'
    },
    closeButtonForUser: {
      position: 'absolute',
      color: '#f05252',
      top: '8px',
      right: '-56px',
      fontSize: '1.25rem',
      cursor: 'pointer'
    },
    rowCounter: {
      color: '#fff',
      background: '#006e7f',
      borderRadius: '50%',
      padding: '2px 8px',
      margin: '5px',
      cursor: 'pointer',
      position: 'relative',
      fontSize: '13px',
      fontWeight: '500',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    totalRecords: {
      marginTop: '-9px',
      height: '40px',
      borderRadius: '50%'
    },
    header: {
      color: '#006e7f',
      fontSize: '1.25rem'
    }
  });
