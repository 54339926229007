import { Typography } from '@mui/material'; 
import { applicationURL } from '../../../../../../../service/URLService';
import { updateActiveModel } from '../controller/KagamiProcessController';
import { KagamiProcessResponseModel } from '../model/KagamiProcessResponseModel';
import { getDefaultListView, getHeaderForprocess, getMainEntityIdForPresentation, isLoadRequiredForPresentation, retrievePresentations } from '../service/PresentationService';
import { getPageOnloadContent } from '../service/ProcessPageService';
import { getSupportedTemplateTypes } from '../service/ProcessService'; 
import { buildDms } from './dms/controller/kagamiDMSController';
import { KagamiDMSModel } from './dms/model/kagamiDMSModel';
import { buildFormPage, setPickItemFormModel } from './form/controller/KagamiFormController';
import { KagamiFormModel } from './form/model/KagamiFormModel' 
import { buildGroup } from './group/controller/KagamiGroupController';
import { KagamiGroupModel } from './group/model/KagamiGroupModel';
import { buildList, getKagamiListModel } from './list/controller/KagamiListController';
import { KagamiListModel } from './list/model/KagamiListModel';
import { setPickItemListModel } from './form/builder/NonFieldPresentationBuilder';
import { buildListEdit } from './listEdit/controller/KagamiListEditController';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { getGroupModel } from './group/service/GroupService';
import { getBreadcrumbsModel } from './breadcrumbs/controller/kagamiBreadcrumbsController';
import { KagamiMasterDetailModel } from './master-detail/model/KagamiMasterDetailModel';
import { buildMasterDetail } from './master-detail/controller/KagamiMasterDetailController';
import { kagamiListHeaderModel } from './list/builder/listHeader/model/KagamiListHeaderModel';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { getListTotalRecords } from './list/service/kagamiListService';

export function processPageResponse(processResponseModel: KagamiProcessResponseModel) {
  if (getSupportedTemplateTypes().includes(processResponseModel.templateType)) {
    return buildProcessPageContent(processResponseModel);
  } else {
    return <div> This template has not been implemented yet </div>;
  }
}

export function buildProcessPageContent(processResponseModel: KagamiProcessResponseModel) {
  var mainEntityId: string = getMainEntityIdForPresentation(processResponseModel.onStartData);
  let viewType: string = processResponseModel.templateType === 'list' ? getDefaultListView(processResponseModel.onStartData) : '';

  if (processResponseModel.templateType !== 'DMS' && viewType !== SystemConstants.CALENDAR_VIEW_TYPE)  getPageOnloadContent(processResponseModel);
  switch (processResponseModel.templateType) {
    case 'list':
      const kagamiListModel: KagamiListModel = new KagamiListModel(
        processResponseModel.processName,
        processResponseModel.processStepName,
        processResponseModel.onStartData,
        mainEntityId
      );
      kagamiListModel.detailedObjects = processResponseModel.detailedObjects[mainEntityId];
      kagamiListModel.isListLoaded = !isLoadRequiredForPresentation(kagamiListModel.onStartData);
      kagamiListModel.kagamiListHeaderModel.headerloaded =  !isLoadRequiredForPresentation(kagamiListModel.onStartData);
      
      /// Note : To make sure on trigger event should call load group content we are passing isGroupListModel prop
      if (processResponseModel.isGroupTemplateNetworkCall){ 
        kagamiListModel.isGroupListModel = true;
        let groupModel : KagamiGroupModel = getGroupModel();
        groupModel.activeGroupModel = kagamiListModel;
      }
      kagamiListModel.isPickItemListModel = processResponseModel.isPickItemCall;
      kagamiListModel.kagamiListFilterModel.isPickItem = processResponseModel.isPickItemCall;
      kagamiListModel.kagamiListHeaderModel.kagamiListModel = kagamiListModel;
      kagamiListModel.kagamiListHeaderModel.totalRecords = getListTotalRecords(kagamiListModel.onStartData);
      kagamiListModel.presentation = retrievePresentations(processResponseModel.onStartData);
      if(processResponseModel.isPickItemCall){
          setPickItemListModel(kagamiListModel)
      }
      else{
        updateActiveModel(kagamiListModel);
      }
     
         return buildList(kagamiListModel);
      case 'list_edit':
          const kagamiListEditModel: KagamiListModel = new KagamiListModel(
            processResponseModel.processName,
            processResponseModel.processStepName,
            processResponseModel.onStartData,
            mainEntityId
          );
          kagamiListEditModel.detailedObjects = processResponseModel.detailedObjects[mainEntityId];
          if (processResponseModel.isGroupTemplateNetworkCall) kagamiListEditModel.isGroupListModel = true;
          kagamiListEditModel.isPickItemListModel = processResponseModel.isPickItemCall;
          kagamiListEditModel.kagamiListFilterModel.isPickItem = processResponseModel.isPickItemCall;
          
          kagamiListEditModel.presentation = retrievePresentations(processResponseModel.onStartData);
          if(processResponseModel.isPickItemCall){
              setPickItemListModel(kagamiListEditModel)
          }
          else{
            updateActiveModel(kagamiListEditModel);
          }
         
             return buildListEdit(kagamiListEditModel);

    case 'pChild':
    case 'treeView':
      const kagamiviewFormModel: KagamiFormModel = new KagamiFormModel(
        processResponseModel.onStartData,
        mainEntityId,
        processResponseModel.processName,
        processResponseModel.processStepName,
        processResponseModel.templateType
      );
      kagamiviewFormModel.readOnly = true;
      kagamiviewFormModel.triggersModel.isDetailView = kagamiviewFormModel.readOnly;
      if (processResponseModel.isGroupTemplateNetworkCall){
         kagamiviewFormModel.isGroupForm = true;
         let groupModel : KagamiGroupModel = getGroupModel();
         groupModel.activeGroupModel = kagamiviewFormModel;
        }
        if(processResponseModel.isPickItemCall){
          setPickItemFormModel(kagamiviewFormModel)
        }
        else{
          updateActiveModel(kagamiviewFormModel);
        }
      kagamiviewFormModel.inFocusFormDataIdentifiers[mainEntityId] = 'tempId';
      updateActiveModel(kagamiviewFormModel);
      return buildFormPage(kagamiviewFormModel);
    case 'form':
    case 'edit':
    case 'pcEmbedForm':
    case 'pcEmbedEdit':
    case 'treeForm':
    case 'treeEdit':
    case 'modalDialog':
      const kagamiFormModel: KagamiFormModel = new KagamiFormModel(
        processResponseModel.onStartData,
        mainEntityId,
        processResponseModel.processName,
        processResponseModel.processStepName,
        processResponseModel.templateType
      );
      // if(CommonUtils.isNotEmpty(processResponseModel.onKanbannClose)){
        kagamiFormModel.onKanbannClose = processResponseModel.onKanbannClose
      // }
      kagamiFormModel.inFocusFormDataIdentifiers[mainEntityId] = 'tempId';
      if (processResponseModel.isGroupTemplateNetworkCall){ 
        kagamiFormModel.isGroupForm = true;
        let groupModel : KagamiGroupModel = getGroupModel();
        groupModel.activeGroupModel = kagamiFormModel;
      }
      if(processResponseModel.isPickItemCall){
        kagamiFormModel.pickItemDetail = true;
        kagamiFormModel.pickItemModalClose = processResponseModel.modalCloseForPickItem;
        setPickItemFormModel(kagamiFormModel)
    }
    else{
      updateActiveModel(kagamiFormModel);
    }
      // updateActiveModel(kagamiFormModel);
      return buildFormPage(kagamiFormModel);

    case 'tabbed':
      const kagamiGroupModel: KagamiGroupModel = new KagamiGroupModel(
        mainEntityId,
        processResponseModel.processName,
        processResponseModel.processStepName,
        processResponseModel.onStartData,
        processResponseModel.templateType
      );
      updateActiveModel(kagamiGroupModel);
      return buildGroup(kagamiGroupModel);

    case 'DMS':
      const kagamiDmsModel: KagamiDMSModel = new KagamiDMSModel();
      const breadcrumb = getBreadcrumbsModel()
      breadcrumb.showBreadCrumb = false
      kagamiDmsModel.dmsResponseUrl = applicationURL + processResponseModel.detailedObjects['dmsUrl'][0];
      return buildDms(kagamiDmsModel);

    default:
      return <div> This template has not been implemented yet </div>;
  }
}

export const ListHeaderWithTotal = (props : any)=>{
  let listHeader = buildHeaderForList(props.kagamiListHeaderModel.kagamiListModel,props.kagamiListHeaderModel)
  let totalRecords = buildtotalRecodrs(props.kagamiListHeaderModel )
  return <div style={{display : 'flex',padding:'5px'}}> 
  {listHeader}
  {CommonUtils.isNotEmpty(totalRecords) && CommonUtils.isNotEmpty(totalRecords.props) && CommonUtils.isNotEmpty(totalRecords.props.children) ? 
  <div style={{marginTop:"-9px", height:'40px',borderRadius:'50%'}}>
  <Typography 
      sx={{color : '#fff' , 
      //  background:'#6bb5c1',
       background: '#006e7f',
      borderRadius:'50%',
      padding:'2px 8px',
      margin: '5px',
      cursor: 'pointer',
      position:'relative',
      fontSize:'13px',
      fontWeight:'500',display:'flex',alignItems:'center',justifyContent:'center'}}
      >{totalRecords.props.children}</Typography >
 </div> : null }
 </div>
}


export function buildtotalRecodrs(props:any ){
  // let totalRecords = props.state.totalRecords;
  let totalRecords: any
  if(props.state.totalRecords != 0) {
    totalRecords = props.state.totalRecords   
  }
return <div>{totalRecords}</div>
  }
  

export function buildHeaderForList(listModel : any,listHeaderModel : kagamiListHeaderModel){
  // if(CommonUtils.isEmpty(listHeaderModel.processHeader)){
    listHeaderModel.processHeader = buildProcessPageHeaderName(listModel);
  // }
  return listHeaderModel.processHeader;
}

export function buildProcessPageHeaderName(activeModel: any) {
  // const classes = makeStyles(KagamiProcessPageStyle)();
  if(activeModel instanceof KagamiListModel){
    activeModel = getKagamiListModel()
  }
  let processName:any = getHeaderForprocess(activeModel?.onStartData?.constructOutputData,activeModel?.onLoadData)
  return (
    <div style={{fontSize:'18px' ,fontWeight:600,marginLeft:'4px',display:'flex',gap:'8px' , alignItems:'center'}}
   > 
      <Typography 
       sx={{
        color: (theme:any) => theme.palette.secondary.main
      }}
      variant="h6" display="block" gutterBottom style={{marginBottom: '0'}}>
        {processName}
      </Typography>
    </div>
  );
}

export function getConfirmDialogContent(message: string) {
  return (
    <div>
      <p>{message}</p>
    </div>
  );
}

export function getConfirmDialogTile() {
  return (
    
      <>Process End Message</>
    
  );
}
