import { makeStyles, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const KagamiTextBoxStyle = (theme: Theme) =>
  createStyles({
    root: {

    //   '& .MuiFormControl-root': { 
    //        height: '28px',  
    //   },




    //   '& .MuiInputBase-root': { 
    //     height:  '28px',  
    // },

      '& .react-tel-input .form-control': {
        width: '100%',
        background:' #f5f8fa',
      },
      '& input': {
        fontSize:'0.9rem',
      },
      '& textarea':{
        fontSize:'14px',
      },
      '& .MuiOutlinedInput-root': {
        // background: theme.palette.common.white,
        // fontSize: '14px',
        // '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
        //   height: '0.8em',  // lesser than default small
        // },
        // '& .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input': {
        //   height: '0.8em', // lesser than default small
        // },
      },
      // '& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root': {
      //   fontSize: theme.typography.caption.fontSize,
      //   lineHeight: '0.7rem'
      // },
      // '& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
      //   fontSize:'0.9rem',
      //   lineHeight: '1.3rem'
      // }
    },
    visuallyHidden: {
      position: 'absolute',
      width: theme.spacing(0),
      height: theme.spacing(0),
      margin: `${theme.spacing(0)} !important`,
      border: theme.spacing(0),
      padding: theme.spacing(0),
      clipPath: 'inset(100%)',
      clip: 'rect(0 0 0 0)',
      overflow: 'hidden'
    },
    PhNoErrMessage: {
      position: 'relative',
      "& .invalid-number-message":{
        fontSize: theme.spacing(1.375),
        left:0,
        bottom: '-15px',
        background:'none',
        top:'auto',
        color:theme.palette.error.main
      }
    },
    PhNoErrMessageLabel: {
      // position: 'absolute',
      // top: '-9px',
      // zIndex: '1',
      // padding: '0 4px',
      // left: '46px',
      // borderRadius:' 50%',
      // background:theme.palette.background.default,
      //  '&.MuiFormLabel-root ': {
      //   fontSize: '12px', 
      //   fontWeight : 'normal',
      //   // color : '#000', 
      //  }, 
    },
    controlLabel : {
      '&.MuiFormLabel-root ': {
        fontSize: '13px',
        // color: theme.palette.info.dark,
      }, 
    } ,
    readOnlyEmpty:{
      color:'#ccc !important',
  },
    readOnlyField: {
      margin: '0',
      fontSize: '14px',
      color: theme.palette.primary.dark,
    },
    readOnlyField_cont: {
      // paddingLeft: theme.spacing(1.6),git stasesh 
      // marginTop: theme.spacing(-1.375)
    },
    readOnlyFieldError: {
      margin: '0',
      fontSize: '14px',
      color: theme.palette.error.main
    },
    phoneNumberInput: {
     
        fontFamily: 'Lexend deca'
   
    }
  });

 