import { KagamiStateManager } from '../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../constants/SystemConstants';
import { CommonUtils } from '../../../utils/CommonUtils';
import { DashboardType, KagamiDashboardModel } from '../builder/body/builder/dashboard/model/KagamiDashboardModel';
import { getNotification } from '../builder/body/builder/dashboard/service/DashboardService';
import { getDashboardModel } from '../builder/body/builder/dashboard/ui/KagamiDashboard';
import { renderBody } from '../builder/body/controller/KagamiBodyController';
import { BodyContent, KagamiHomeModel } from '../model/KagamiHomeModel';
import { buildHomeComponent } from '../ui/KagamiHome';

let kagamiHomeModel: KagamiHomeModel;

export function buildHome(linkProcessId: any) {
  const homeModel: KagamiHomeModel = new KagamiHomeModel();
  if (CommonUtils.isNotEmpty(linkProcessId)) {
    homeModel.linkProcessId = linkProcessId;
    homeModel.executeLinkProcess = true;
  }
  // kagamiHomeModel = homeModel;
  return buildHomeComponent(homeModel);
}

export function showBody() {
  // let dashboardModel: KagamiDashboardModel = getDashboardModel();
  if(CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))){
    KagamiStateManager.removeStateToHolder(SystemConstants.PROCESS_STATE)
  }
  kagamiHomeModel?.state.setCurrentBodyContent(BodyContent.DASHBOARD);
  renderBody(kagamiHomeModel.bodyModel);
  getNotification(kagamiHomeModel.bodyModel.dashboardModel);
  //  KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setNotificationIconVisible(dashboardModel.dashboardType == DashboardType.NOTIFICATIONS)
  //   KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setReportIconVisible(dashboardModel.dashboardType == DashboardType.REPORTS);

}

export function getHomeModel() {
  return kagamiHomeModel;
}

export function setHomeModel(model: KagamiHomeModel) {
  kagamiHomeModel = model;
}
