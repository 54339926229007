import { executeReportWithOrWithoutFilter } from "../../view/report/builder/KagamiReportBuilder";
import { getStaticFilterData, getStaticFilterInfo } from "../../view/report/service/KagamiReportService";
import { getReportType } from "../../view/report/service/ReportUtilService";
import { buildStaticReportFilters } from "../controller/StaticReportFiltersController";
import _ from 'lodash';

export const handleStaticReportFilters = (reportLandingPageModel: any,  reportModel: any) => {
    reportLandingPageModel.modalModel.title = "Filters"
    reportLandingPageModel.modalModel.isReportModel = true;
    // reportLandingPageModel.modalModel.content = buildReportFilter(reportFilterModel, false, reportFilterModel.translations);
    reportLandingPageModel.modalModel.content = buildStaticReportFilters(reportModel)
    reportLandingPageModel.modalModel.state.setOpen(true);
    reportLandingPageModel.modalModel.handleOnOk = async () => {
        reportModel.reportFilterModel = reportModel;
        reportLandingPageModel.content = await executeReportWithOrWithoutFilter(reportModel);
        reportLandingPageModel.isContentLoaded = true;
        reportLandingPageModel.state.setDataLoaded(true);
    };
    reportLandingPageModel.modalModel.handleOnClose = () => { };
}

export const onChangeAccount = async (account: any, ledgerAccountReportController: any) => {
    let reportType = getReportType(ledgerAccountReportController.reportConfig);
    if (account && account.length > 1) {
        account = account.split("-")[1];
    }
    if (reportType && account && account.length > 0 && reportType == 'Vendor Ledger') {
        let QueryParam = '';
        QueryParam = QueryParam.concat("ledgerType=" + reportType + '&');
        QueryParam = QueryParam.concat("dependant=" + account);
        let staticData = await getStaticFilterInfo(QueryParam);
        if (staticData?.data) {
            return _.cloneDeep(staticData.data.gst || []);
        }
    }
    return [];
}

export const onChangeState = async (state: any, ledgerAccountReportController: any) => {
    let reportType = getReportType(ledgerAccountReportController.reportConfig);
    if (reportType && state && state.length > 0) {
        let QueryParam = '';
        QueryParam = QueryParam.concat("ledgerType=" + reportType + '&');
        QueryParam = QueryParam.concat("dependant=" + "state|" + state.join(","));
        let staticData = await getStaticFilterInfo(QueryParam);
        if (staticData?.data) {
            return _.cloneDeep(staticData.data.projectPlan || []);
        }
    }
    return [];
}


export async function getDBStaticFilterData(reportType: any) {
    // let reportType =  getReportType(ageingReportController.reportConfig);
    return await getStaticFilterData(reportType);
}
