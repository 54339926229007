import { KagamiSlowApiLogModel } from '../model/KagamiSlowApiLogModel';
import { slowApiLogBuilder } from '../ui/KagamiSlowApiLog';

export function buildSlowApiLog(kagamiSlowApiLogModel: KagamiSlowApiLogModel) {
  return slowApiLogBuilder(kagamiSlowApiLogModel);
}

export function addRowChildren(children: Array<any>, eventId: string, props: any) {
  let array = [...props?.kagamiSlowApiLogModel.rowData];
  let index = array?.findIndex((item: any) => item?.event === eventId);
  if (index !== -1) {
    array[index].children = children;
    props?.kagamiSlowApiLogModel?.state?.setRowData(array);
  }
}

export function addRow(item: any, props: any) {
  props?.kagamiSlowApiLogModel?.state?.setRowData(item.content);
}

export function openCloseToDatePickerForFilter(props: any, value: any) {
  props?.kagamiSlowApiLogModel?.state?.setIsOpen(value);
}

export function openCloseFromDatePickerForFilter(props: any, value: any) {
  props?.kagamiSlowApiLogModel?.state?.setFromIsOpen(value);
}

export function paginationSettings(props: any, response: any) {
  props?.kagamiSlowApiLogModel?.state?.setPageNumber(response?.data?.pageable?.pageNumber);
  if (props?.kagamiSlowApiLogModel?.isPaginationPageSelector) {
    props?.kagamiSlowApiLogModel?.state?.setPageSize(props?.kagamiSlowApiLogModel?.state?.paginationPageSizeSelector);
  } else {
    props?.kagamiSlowApiLogModel?.state?.setPageSize(response?.data?.pageable?.pageSize);
  }
  props?.kagamiSlowApiLogModel?.state?.setTotalPages(response?.data?.totalPages);
  props?.kagamiSlowApiLogModel?.state?.setTotalElements(response?.data?.totalElements);
}
