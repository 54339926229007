import { createState } from '@hookstate/core';
import {
  ColumnReportModel,
  ColumnReportModelProps,
  useColumnReportModelState
} from '../model/ColumnReportModel';
import { downloadColumnPDF, getTotalOfReportColumn, makeAdditionalReport, makeColumn } from '../service/ColumnReportService';
import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React, {  useRef } from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts)



import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { buildReportsPageController } from '../../../../../../landingPage/builder/ReportsPage/controller/ReportsPageController';



export const BuildColumnReportView = (columnReportModel: ColumnReportModel) => {
  return (
    <KagamiColumnChartReportView key={'column-chart-report'} columnReportModel={columnReportModel}></KagamiColumnChartReportView>
  );
};


const KagamiColumnChartReportView = (props: ColumnReportModelProps) => {
  const chartRef:any = useRef();
  let columnReportModel = props.columnReportModel;
  let columnChartState = useColumnReportModelState(createState(columnReportModel));
  columnReportModel.state = columnChartState;
  let _config :any ={};
  let totalValue :any = 0;
  if(columnReportModel.contentReport)console.log(columnReportModel?.contentReport[0]??'getting undefined');
  React.useEffect(() => {
    getReportDataForDynamicReports(props.columnReportModel);
    if(props.columnReportModel?.reportConfig?.reportSettings?.additionalReportId) {
        makeAdditionalReport(props.columnReportModel.reportConfig.reportSettings.additionalReportId,props.columnReportModel);
    }

  }, [])

  const containerRef = React.useCallback((node:any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => { 
      chartRef.current?.chart?.reflow();
    });
    resizeObserver.observe(node);
  }, []);

  
  const downLoad = ()=> {
    downloadColumnPDF(chartRef,_config,columnReportModel);
  }

  if(!columnChartState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" /> 
  }else{
    if (columnReportModel.reportData){
        
  _config  = makeColumn(columnReportModel);
  totalValue = getTotalOfReportColumn(_config);
  return (
    <Grid container spacing={{ xs: 2, md: 3 }} style={{ marginTop:'5px'}} className='dashboard-column-container'>
      <Grid item xs={_config.tableConfig?.isBesideTableDetails && !columnChartState.isAdditionalReportLoaded ? 6:12} className='dashboard-column-chart-container'>
        <div ref ={containerRef} className="ag-theme-alpine dashboard-highchart" style={{ width: '100%' }}>
          <Chart ref ={chartRef} highcharts={Highcharts} options={_config.columnConfig}/>
        </div>
      </Grid>
        {(_config?.tableConfig?.showTableDetails && !columnChartState.isAdditionalReportLoaded) &&
        <Grid item xs={6} className='dashboard-table-container'>
          <Grid item xs={12} className='dashboard-download-button' style={{ float:'right',marginBottom:'10px'}}>
          {!columnChartState.isAdditionalReportLoaded &&  
            <Button
              id="print-button"
              variant="contained"
              onClick={downLoad}
            >
              Download
            </Button>
         }
          </Grid>
          <Grid item xs={12} className='dasboard-column-table-container'>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {_config?.tableConfig?.detailedTableconfig?.columns?.map((col: any) => (
                    <TableCell sx={{background:'#f2f2f2'}} component="th" scope="row">
                      {col.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {_config?.tableConfig?.detailedTableconfig?.data?.map((rec:any) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                   {_config?.tableConfig?.detailedTableconfig?.columns?.map((col: any) => (
                    <TableCell component="th" scope="row">
                      {rec[col.field]}
                    </TableCell>
                  ))}
                  </TableRow>
                ))}
                  <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {_config?.tableConfig?.detailedTableconfig?.columns?.map((col: any) => (
                      <TableCell component="th" scope="row">
                        {col.field =='y' ? ("Total : "+totalValue) :''}
                      </TableCell>
                    ))}
                  </TableRow>
              </TableBody>
            </Table>
          </Grid>
        
        </Grid>
        }
        <Grid item xs={12} style={{ marginBottom:'25px'}} className='dashboard-no-margin'>
          {columnChartState.isAdditionalReportLoaded && 
            <div>
              {buildReportsPageController(columnReportModel?.additionalReportContent, true)}
            </div>
          }
        </Grid>

        <Grid item xs={12} style={{ marginBottom:'25px'}} className='dashboard-no-margin'>
          {
            columnChartState.isContentReportLoaded && <div>
              {buildReportsPageController(columnReportModel?.contentReport, true)}
            </div>
          }
        </Grid>
    </Grid>
  );
}else{
  return <p className='NoDataFound'>No Data Found</p>
}
}
};
