import { State, useState } from '@hookstate/core';
import { KagamiLoaderModel } from '../../../../../../../../components/loader/model/KagamiLoaderModel';
import { KagamiPopoverModel } from '../../../../../../../../components/popover/model/KagamiPopoverModel';
import { KagamiStateManager } from '../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { KagamiHomeHeaderModel } from '../../../../header/model/KagamiPageHeaderModel';
import { KagamiBreadcrumbsModel } from '../builder/breadcrumbs/model/KagamiBreadcrumbsModel';

export class KagamiProcessPageModel {
  processName: string;
  fromDashboard: boolean;
  activeModel: any;
  detailsObjects?: any;
  processContent: JSX.Element | null;
  showPopover: boolean;
  popoverModel: KagamiPopoverModel;
  breadcrumbModel: KagamiBreadcrumbsModel;
  isGroupProcess: boolean;
  kagamiHomeHeaderModel: KagamiHomeHeaderModel;
  loaderModel: KagamiLoaderModel;
  linkId: string;
  isLinkProcess: boolean;
  constructor(processName: string) {
    this.processName = processName;
    this.fromDashboard = false;
    this.processContent = null;
    this.showPopover = false;
    this.popoverModel = new KagamiPopoverModel();
    this.breadcrumbModel = new KagamiBreadcrumbsModel();
    this.isGroupProcess = false;
    this.kagamiHomeHeaderModel = new KagamiHomeHeaderModel();
    this.loaderModel = new KagamiLoaderModel();
    this.linkId = '';
    this.isLinkProcess = false;
  }
}
export interface ProcessModelProps {
  kagamiProcessModel: KagamiProcessPageModel;
  isTodo?: boolean | undefined;
}
export const wrapProcessState = (state: State<KagamiProcessPageModel>) => {
  const processState = state;
  return {
    get processContent() {
      return processState.processContent.get();
    },
    loadProcessContent(processPageContent: JSX.Element) {
      processState.processContent.set(processPageContent);
    }
  };
};
export const useProcessState = (state: State<KagamiProcessPageModel>) => {
  const processState = wrapProcessState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.PROCESS_STATE, processState);
  return processState;
};
