import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';

export class KagamiDashboardModel {
  // dashboardType: DashboardType;
  notificationsLoaded: boolean;
  reportsLoaded: boolean;
  isDataLoaded: boolean;
  reportData: any;
  reportDataUx:any[];
  notificationData: any;
  favouritesData: any;
  containerData: any;
  layoutContainer: any[];
  notificationLayouts:any;
  reportLayouts:any;
  currentScreenSize:any;
  state: any;
  isSummaryDashBoard:boolean;
  constructor() {
    // this.dashboardType = DashboardType.NOTIFICATIONS;
    this.isDataLoaded = false;
    this.notificationsLoaded = false;
    this.reportsLoaded = false;
    this.reportData = null;
    this.reportDataUx = [];
    this.notificationData = null;
    this.favouritesData = null;
    this.containerData = null;
    this.state = null;
    this.isSummaryDashBoard = false;
    this.notificationLayouts = {};
    this.reportLayouts = {};
    this.layoutContainer = [];
  }
}

export enum DashboardType {
  NOTIFICATIONS,
  REPORTS
}
export interface DashboardModelProps {
  dashboardModel: KagamiDashboardModel;
}

export interface DashboardNotificationSectionProps {
  header: string;
  notifications: any[];
  statusModel?: any;
  dashboardModel: KagamiDashboardModel;
}

export interface DashboardGridProps {
  component:any[];
  dashboardModel: KagamiDashboardModel;
}

export interface DashboardNotificationProps {
  notification: any;
  statusModel?: any;
  dashboardModel?: KagamiDashboardModel;
  header: string;
}

export const wrapKagamiDashboardState = (state: State<KagamiDashboardModel>) => {
  let dashboardstate = state;

  return {
    // get dashboardType() {
    //   return dashboardstate.dashboardType.get();
    // },

    // setDashboardType(type: DashboardType) {
    //   dashboardstate.dashboardType.set(type);
    // },

    get notificationsLoaded() {
      return dashboardstate.notificationsLoaded.get();
    },

    setNotificationsLoaded(notificationsLoaded: boolean) {
      dashboardstate.notificationsLoaded.set(notificationsLoaded);
    },
    get getfavouritesData() {
      return dashboardstate.favouritesData.get();
    },

    setFavouritesData(favData: any) {
      dashboardstate.favouritesData.set(favData);
    },
    get getcontainerData() {
      return dashboardstate.containerData.get();
    },

    setcontainerData(leftPanelData: any) {
      dashboardstate.containerData.set(leftPanelData);
    },
    
    // get dashBoardType(){
    //   return dashboardstate.isSummaryDashBoard.get()
    // },

    // setDashBoardType(isSummaryDashBoard:boolean){
    //   dashboardstate.isSummaryDashBoard.set(isSummaryDashBoard)
    // },

    get isDataLoaded() {
      return dashboardstate.isDataLoaded.get();
    },

    setDataLoaded(isDataLoaded: boolean) {
      dashboardstate.isDataLoaded.set(isDataLoaded);
    },
  };
};

export const useKagamiDashboardState = (state: State<KagamiDashboardModel>) => {
  const kagamiDashboardState = wrapKagamiDashboardState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.DASHBOARD_STATE, kagamiDashboardState);
  return kagamiDashboardState;
};

export interface KagamiMenuComponentProps {
  triggerModels: any;
  notification: any,
  popupMenuModel: any,
  notificationId: any,
  userId: any,
  notifcationData: any,
  setNotificationData:any
};
