import { APIService } from '../../../../../../../service/ApiService';
import { getAsyncBpmURL, getbpmURL, linkProcessURL } from '../../../../../../../service/URLService';
import { RequestEvent } from '../model/RequestEvent';
import { RuntimeInput } from '../model/RuntimeInput';
import {
  createEmbedColumnSearchEvent,
  createEmbedPageChangeEvent,
  createExplicitAssignEvent,
  createExplicitEvent,
  createLinkProcessRequestEvent,
  createOnCancelEvent,
  createOnChangeEvent,
  createOnClickEvent,
  createOnloadEvent,
  createOnSearchFilterEvent,
  createOnSearchFiltersListEvent,
  createOnSearchListEvent,
  createOnStartEvent,
  createOnSubmitEvent
} from './ServerEventService';
import { KagamiProcessRequestModel } from '../model/KagamiProcessRequestModel';
import { NavigationService } from './NavigationService';
import { KagamiProcessResponseModel } from '../model/KagamiProcessResponseModel';
import { processPageResponse } from '../builder/ProcessPageBuildler';
import { getActiveModel, loadProcessContent, onSubmitCall } from '../controller/KagamiProcessController';
import { showServerError } from '../builder/form/controller/KagamiFormController';
import { hideLoader, showLoader } from '../../../../../../../../components/loader/controller/KagamiLoaderController';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import {
  buildModalDialogContent,
  setModalDialog
} from '../builder/modal-dialog/builder/KagamiModalDialogContentBuilder';
import { loadGroupContent } from '../builder/group/controller/KagamiGroupController';
import each from 'lodash/each';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import {
  getEntityIdForPresentation,
  getMainEntityIdForPresentation,
  getMainEntityPresentationsFromUIResource,
  getMappedEntityIdForPresentation,
  getPresenationIdFromPresentation,
  getUiResourceFromPresentation,
  isEnableTabs,
  isModalListView
} from './PresentationService';
import { KagamiSubProcessRequestModel } from '../model/KagamiSubProcessRequestModel';
import { KagamiStateManager } from '../../../../../../../../state/KagamiStateManager';
import { getTabNavigationModel } from '../../../../header/Tabs/controller/KagamiTabNavigationController';
import {
  eopTrueAndNoDetailedObjectsForTabs,
  storingContentIntoStateForTabs
} from '../../../../header/Tabs/ui/KagamiTabNavigation';
import _ from 'lodash';
import { getHomeModel } from '../../../../../controller/KagamiHomeController';
import { getBreadcrumbsModel } from '../builder/breadcrumbs/controller/kagamiBreadcrumbsController';
import { KagamiBreadcrumbsModel } from '../builder/breadcrumbs/model/KagamiBreadcrumbsModel';
import { appContext } from './PolicyExecutionService';

export function onOutBoundCall(currentProcessModel: KagamiProcessRequestModel, isTodo?: boolean) {
  showLoader(false, currentProcessModel.fromDashboard);
  NavigationService.navigateTo(currentProcessModel)
    .then((processResponseModel: any) => {
      hideLoader();
      if (processResponseModel instanceof KagamiProcessResponseModel) {
        let modalListView: boolean = processResponseModel.templateType == 'noUI' ? false : isModalListView(processResponseModel);
        currentProcessModel.templateType = processResponseModel.templateType;
        if (processResponseModel.onErrorData != null) {
          currentProcessModel.onError(processResponseModel.onErrorData);
        } else if (processResponseModel.templateType == 'noUI') {
          const processRequestModel: KagamiProcessRequestModel = new KagamiProcessRequestModel(
            processResponseModel.processName,
            processResponseModel.isGroupTemplateNetworkCall ? loadGroupContent : loadProcessContent,
            showServerError
          );
          processRequestModel.fromDashboard = false;
          processRequestModel.data = null;
          processRequestModel.submitCall = true;
          processRequestModel.processStepName = processResponseModel.processStepName;
          processRequestModel.isToastMessageHide = false;
          if (processResponseModel.isGroupTemplateNetworkCall) processRequestModel.isGroupTemplateNetworkCall = true;
          onSubmitCall(processRequestModel);
        } else if (processResponseModel.templateType == 'modalDialog' || modalListView) {
          let activeContextModel = getActiveModel();
          buildModalDialogContent(
            processResponseModel,
            activeContextModel,
            currentProcessModel.fromDashboard,
            modalListView
          );
          if(isEnableTabs(appContext)) {
            setModalDialog(processResponseModel);
            let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
            let tabNavigationModel = getTabNavigationModel();
            let breadcrumbModel: KagamiBreadcrumbsModel = getBreadcrumbsModel();
            let referenceBreadCrumbModel: KagamiBreadcrumbsModel = _.clone(breadcrumbModel);
            let activeTab = JSON.parse(JSON.stringify(tabNavigationState.getActiveTab));
            let homeModel = getHomeModel();
            storingContentIntoStateForTabs(
              currentProcessModel,
              homeModel.modalDialogModel.content,
              tabNavigationModel,
              processResponseModel,
              isTodo,
              activeTab,
              referenceBreadCrumbModel
            );
          }
          } else {
          currentProcessModel.templateType = processResponseModel.templateType;
          if (currentProcessModel.isGroupTemplateNetworkCall) processResponseModel.isGroupTemplateNetworkCall = true;
          const content: JSX.Element | null = processPageResponse(processResponseModel);
          if(isEnableTabs(appContext)) {
            let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
            let tabNavigationModel = getTabNavigationModel();
            if (!currentProcessModel.isModeldialogForattKeys) {
              let breadcrumbModel: KagamiBreadcrumbsModel = getBreadcrumbsModel();
              let referenceBreadCrumbModel: KagamiBreadcrumbsModel = _.clone(breadcrumbModel);
              let activeTab = JSON.parse(JSON.stringify(tabNavigationState.getActiveTab));
              storingContentIntoStateForTabs(
                currentProcessModel,
                content,
                tabNavigationModel,
                processResponseModel,
                isTodo,
                activeTab,
                referenceBreadCrumbModel
              );
              currentProcessModel.onSuccess(content);
            }
          } else {
            currentProcessModel.onSuccess(content);  
          }
        }
      } else {
        console.log('error while creating page model');
        if(isEnableTabs(appContext)) {
          let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
          let tabNavigationModel = getTabNavigationModel();
          eopTrueAndNoDetailedObjectsForTabs(currentProcessModel.processName, tabNavigationState, tabNavigationModel);  
        }      }
    })
    .catch((err) => {
      hideLoader();
      console.log(err);
    });
}

export async function makeOnStartCall(
  processName: string,
  data: any,
  fromDashboard: boolean,
  previousProcessName: string,
  processModel?: KagamiProcessRequestModel
) {
  let requestEvent = createOnStartEvent(processName, data, previousProcessName, fromDashboard);
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeOnLoadCall(
  processName: string,
  entityPresentation: any,
  changedData: any,
  data: any,
  runtimeInput: RuntimeInput | null,
  verbProperties?: any,
  isHierarchy: boolean = false,
  embeddedInfo: any = {}
) {
  let requestEvent = createOnloadEvent(
    processName,
    entityPresentation,
    changedData,
    data,
    runtimeInput,
    verbProperties,
    isHierarchy,
    embeddedInfo
  );
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeOnChangeCall(
  processName: string,
  changedAttribute: string,
  entityPresentation: any,
  changedData: any,
  data: any,
  formModel?: any,
  control?: any
) {
  let requestEvent: any = createOnChangeEvent(
    processName,
    changedAttribute,
    entityPresentation,
    changedData,
    data,
    control
  );
  let attrObj = {
    attrName: requestEvent.inputData.properties.attrSequence.attrName,
    timeStampKey: requestEvent.inputData.properties.attrSequence.timeStampKey
  };
  let clientsAttribute = [];
  if (!localStorage.getItem(SystemConstants.ATTRIBUTESEQUENCE)) {
    clientsAttribute.push(attrObj);
    localStorage.setItem(SystemConstants.ATTRIBUTESEQUENCE, JSON.stringify(clientsAttribute));
  } else {
    let attributeKeys = localStorage.getItem(SystemConstants.ATTRIBUTESEQUENCE);
    if (attributeKeys !== null) {
      clientsAttribute = JSON.parse(attributeKeys);
    }
    clientsAttribute.push(attrObj);
    localStorage.setItem(SystemConstants.ATTRIBUTESEQUENCE, JSON.stringify(clientsAttribute));
  }
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeOnCancelCall(processName: string, entityPresentation: any, changedData: any, data: any) {
  let requestEvent: RequestEvent = createOnCancelEvent(processName, entityPresentation, changedData, data);
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeOnSubmitCall(
  processName: string,
  data: any,
  bulkUploadProperties?: any,
  changeFor?: any,
  isPickItem: boolean = false,
  isModalResponse: boolean = false,
  bulkEventType?: boolean
) {
  let requestEvent = createOnSubmitEvent(
    processName,
    data,
    bulkUploadProperties,
    changeFor,
    isPickItem,
    isModalResponse,
    bulkEventType
  );
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeOnSearchCall(
  processName: string,
  presentationId: any,
  runtimeInput: any,
  attributeName: any,
  pageNumber: Number,
  searchRecord?: any,
  changeFor?: any,
  detailedObjects?: any
) {
  if (CommonUtils.isNotEmpty(searchRecord)) {
    let requestEvent = createOnSearchFiltersListEvent(
      processName,
      presentationId,
      runtimeInput,
      attributeName,
      searchRecord,
      pageNumber
    );
    return APIService.getData('POST', getbpmURL(), requestEvent);
  } else {
    let requestEvent = createOnSearchListEvent(
      processName,
      presentationId,
      runtimeInput,
      attributeName,
      pageNumber,
      changeFor,
      detailedObjects
    );
    return APIService.getData('POST', getbpmURL(), requestEvent);
  }
}

export async function makeOnClickCall(
  processName: any,
  mainEntityId: any,
  embeddedEntity: any,
  detailedObject: any,
  mainPresenationId: any
) {
  let requestEvent = createOnClickEvent(processName, mainEntityId, embeddedEntity, detailedObject, mainPresenationId);
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeOnFilterCall(
  processName: string,
  presentationId: any,
  filterAttrName: any,
  runtimeInput: any,
  searchObj: any
) {
  let requestEvent = createOnSearchFilterEvent(processName, presentationId, filterAttrName, runtimeInput, searchObj);
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeExplixitCallForNonFieldButton(
  processName: string,
  entityPresentation: any,
  detailedObjects: any,
  fieldName: any,
  presenationId: any,
  changeFor: any
) {
  let requestEvent = createExplicitEvent(
    processName,
    entityPresentation,
    detailedObjects,
    fieldName,
    presenationId,
    changeFor
  );
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeExplicitAssignCallForNonFieldButton(
  processName: string,
  parentProcessName: string,
  data: any
) {
  let requestEvent = createExplicitAssignEvent(processName, parentProcessName, data);
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export function getSupportedTemplateTypes() {
  return [
    'list',
    'DMS',
    'form',
    'edit',
    'pcEmbedForm',
    'pcEmbedEdit',
    'treeForm',
    'treeEdit',
    'pChild',
    'treeView',
    'tabbed',
    'modalDialog',
    'ConformDialog',
    'upload'
  ];
}

export function getEmbedInfo(onStartData : any){
  let baseFormEntity=  getMainEntityIdForPresentation(onStartData);
  let uiResource = getUiResourceFromPresentation(onStartData);
  let embeddedParticipatingEntities= getPageEmbeddedParticipatingEntitiesWithoutNested(uiResource);
  let embeddedInfo : any ={
          "entityPageMap":{},
          "entitySearchMap":{},
          "entityInput":{}
      }
     embeddedInfo.entityInput={
      "entityId":baseFormEntity,
      "participatingEntities":embeddedParticipatingEntities
  }

  if(onStartData?.constructOutputData?.embeddedInfo?.entityPageMap) {
       embeddedInfo.entityPageMap = onStartData?.constructOutputData?.embeddedInfo?.entityPageMap;
  }
  else if(embeddedParticipatingEntities){
         embeddedInfo.entityPageMap[embeddedParticipatingEntities] = {
        "pageNumber" : 1,
        "pageSize" : 10
      }
  }
  if(!embeddedParticipatingEntities.length){
    embeddedInfo = {};
  }
  return embeddedInfo;
}

export function getInGridEmbedInfo(kagamiGridModel: any, paginationModel?: any, embeddedPageSize?: any) {
  let baseFormEntity = getMainEntityIdForPresentation(kagamiGridModel.onStartData);
  let uiResource = getUiResourceFromPresentation(kagamiGridModel.onStartData);
  let embeddedParticipatingEntities = getPageEmbeddedParticipatingEntitiesWithoutNested(uiResource);

  const removeDuplicatesEmbeddedParticipatingEntities = (data: any) => {
    return data.filter((value: any, index: number) => data.indexOf(value) === index);
  };

  let embeddedParticipatingEntitiesFilteredData =
  removeDuplicatesEmbeddedParticipatingEntities(embeddedParticipatingEntities);
  embeddedParticipatingEntities = embeddedParticipatingEntitiesFilteredData;

  let embeddedInfo: any = {
    entityPageMap: {},
    entitySearchMap: {},
    entityInput: {}
  };

  embeddedInfo.entityInput = {
    entityId: baseFormEntity,
    participatingEntities: embeddedParticipatingEntities
  };

  if (kagamiGridModel.onStartData?.constructOutputData?.embeddedInfo?.entityPageMap) {
    embeddedInfo.entityPageMap = kagamiGridModel.onStartData?.constructOutputData?.embeddedInfo?.entityPageMap;

    if(uiResource.uiTemplate === 'treeForm' ||
      uiResource.uiTemplate === 'treeEdit' ||
      uiResource.uiTemplate === 'treeView') {
      embeddedInfo.entityPageMap[kagamiGridModel.mainEntityId] = {
        pageNumber: paginationModel.state ? Number(paginationModel.state.getActivePageIndex()) : 1,
        pageSize: embeddedPageSize ? Number(embeddedPageSize) : 10
      }  
    } else {
      embeddedInfo.entityPageMap[embeddedParticipatingEntities] = {
        pageNumber: paginationModel.state ? Number(paginationModel.state.getActivePageIndex()) : 1,
        pageSize: embeddedPageSize ? Number(embeddedPageSize) : 10
      };
    }   
  } else if (embeddedParticipatingEntities) {
      embeddedInfo.entityPageMap[embeddedParticipatingEntities] = {
        pageNumber: paginationModel.state ? Number(paginationModel.state.getActivePageIndex()) : 1,
        pageSize: embeddedPageSize ? Number(embeddedPageSize) : 10
      };
  }
  if (!embeddedParticipatingEntities.length) {
    embeddedInfo = {};
  }
  return embeddedInfo;
}

export function setEmbeddedInfo_scroll(
  request: any,
  formModel: any,
  displayAttribute?: string,
  searchValue?: string,
  entityId: string = '',
  pageNumber: Number = 1
) {
  let baseFormEntity = getMainEntityIdForPresentation(formModel.onStartData);
  let uiResource = getUiResourceFromPresentation(formModel.onStartData);
  let embeddedParticipatingEntities = getPageEmbeddedParticipatingEntitiesWithoutNested_scroll(
    uiResource,
    formModel.onStartData
  );
  let formulaPresentInPRule = getFormulaPresentInPRule(uiResource);
  if (request.inputData?.embeddedInfo === undefined) {
    if (request.inputData === undefined) {
      request['inputData'] = {};
    }
    request.inputData['embeddedInfo'] = {
      entityPageMap: {},
      entitySearchMap: {},
      entityInput: {}
    };
  }
  request.inputData.embeddedInfo.entityInput = {
    entityId: baseFormEntity,
    participatingEntities: embeddedParticipatingEntities
  };

  if (formModel?.onStartData?.constructOutputData?.embeddedInfo?.entityPageMap) {
    request.inputData.embeddedInfo.entityPageMap =
      formModel?.onStartData?.constructOutputData?.embeddedInfo?.entityPageMap;
    request.inputData.embeddedInfo.entityPageMap[entityId].pageNumber = pageNumber;
  } else if (embeddedParticipatingEntities) {
    request.inputData.embeddedInfo.entityPageMap = {
      embeddedParticipatingEntities: {
        pageNumber: 1,
        pageSize: 10
      }
    };
  }

  if (CommonUtils.isNotEmpty(displayAttribute) && CommonUtils.isNotEmpty(searchValue) && entityId) {
    request.inputData.embeddedInfo.entitySearchMap = {};
    request.inputData.embeddedInfo.entitySearchMap[entityId] = {
      attributeName: displayAttribute,
      attributeValue: searchValue
    };
  }
  return request;
}

export function getPageEmbeddedParticipatingEntitiesWithoutNested_scroll(uiResource: any, startData: any) {
  let entityLevelMap = uiResource.presentations.entityLevelMap[0];
  let firstPRuleMap = uiResource.presentations.presentationRuleMap[entityLevelMap];
  let arr: any = [];
  let entityPageMap: any = startData.constructOutputData.embeddedInfo.entityPageMap;
  Object.keys(entityPageMap).map((item, i) => arr.push(item));

  return arr;
}

export function setEmbeddedInfo(
  request: any,
  formModel: any,
  displayAttribute?: string,
  searchValue?: string,
  entityId?: string,
  pageNumber?: any,
  pageSize?: any
) {
  let baseFormEntity = getMainEntityIdForPresentation(formModel.onStartData);
  let uiResource = getUiResourceFromPresentation(formModel.onStartData);
  let embeddedParticipatingEntities = getPageEmbeddedParticipatingEntitiesWithoutNested(uiResource);

  const removeDuplicatesEmbeddedParticipatingEntities = (data: any) => {
    return data.filter((value: any, index: number) => data.indexOf(value) === index);
  };

  let embeddedParticipatingEntitiesFilteredData =
    removeDuplicatesEmbeddedParticipatingEntities(embeddedParticipatingEntities);
  embeddedParticipatingEntities = embeddedParticipatingEntitiesFilteredData;

  if (embeddedParticipatingEntities.length === 0 && CommonUtils.isNotEmpty(entityId)) {
    embeddedParticipatingEntities.push(entityId);
  }
  let formulaPresentInPRule = getFormulaPresentInPRule(uiResource);
  if (!request.inputData.embeddedInfo) {
    request.inputData.embeddedInfo = {
      entityPageMap: {},
      entitySearchMap: {},
      entityInput: {}
    };
  }
  request.inputData.embeddedInfo.entityInput = {
    entityId: baseFormEntity,
    participatingEntities: embeddedParticipatingEntities
  };

  if (formModel?.onStartData?.constructOutputData?.embeddedInfo?.entityPageMap) {
    request.inputData.embeddedInfo.entityPageMap =
      formModel?.onStartData?.constructOutputData?.embeddedInfo?.entityPageMap;
  } else if (embeddedParticipatingEntities) {
    request.inputData.embeddedInfo.entityPageMap = {
      embeddedParticipatingEntities: {
        pageNumber: pageNumber ? Number(pageNumber) : 1,
        pageSize: pageSize ? Number(pageSize) : 10
      }
    };
  }
  if (!embeddedParticipatingEntities.length) {
    request.inputData.embeddedInfo = {};
  }
  if (CommonUtils.isNotEmpty(displayAttribute) && CommonUtils.isNotEmpty(searchValue) && entityId) {
    request.inputData.embeddedInfo.entitySearchMap = {};
    request.inputData.embeddedInfo.entitySearchMap[entityId] = {
      attributeName: displayAttribute,
      attributeValue: searchValue
    };
  }
  return request;
}

export function getPageEmbeddedParticipatingEntitiesWithoutNested(uiResource: any) {
  let entityLevelMap = uiResource.presentations.entityLevelMap[0];
  let firstPRuleMap = uiResource.presentations.presentationRuleMap[entityLevelMap];
  let treePresenations = firstPRuleMap[0]?.presentationRules;
  let arr: any = [];
  if (uiResource.uiTemplate === 'pcEmbedForm' || uiResource.uiTemplate === 'pcEmbedEdit') {
    each(firstPRuleMap, function (val, key, ref) {
      let entityId: string = getMappedEntityIdForPresentation(val);
      if (!val.matrixView && entityId && entityId !== entityLevelMap) {
        arr.push(entityId);
      }
    });
  } else if (
    uiResource.uiTemplate === 'treeForm' ||
    uiResource.uiTemplate === 'treeEdit' ||
    uiResource.uiTemplate === 'treeView'
  ) {
    each(treePresenations, function (val, key, ref) {
      each(ref, function (val, key, ref) {
        if (val['@type'] === 'FormPresentation' && val.presentationRule) {
          let entityId: string = getEntityIdForPresentation(val.presentationRule);
          CommonUtils.isNotEmpty(val.presentationRule['entityPrefix'])
            ? val.presentationRule['entityPrefix'].concat(entityId)
            : entityId;
          if (entityId && entityId != entityLevelMap) {
            arr.push(entityId);
          }
        }
      });
    });
  }
  return arr;
}

export function getFormulaPresentInPRule(uiResource: any) {
  let formulaPresent: any = [];
  forEach(uiResource.presentations.presentationRuleMap, function (presentationRuleMap, key) {
    forEach(presentationRuleMap, function (value, key) {
      forEach(value, function (pMetaData, index, ref) {
        if (index == 'presentationRules') {
          var entity = ref.entityId;
          forEach(pMetaData, function (pRule) {
            if (pRule && pRule.uiSettings && pRule.uiSettings.attributeVariable && pRule.uiSettings.resolveVariable) {
              formulaPresent.push(entity);
            }
          });
        }
      });
    });
  });
  return uniq(formulaPresent);
}

export async function makeAsyncOnStartCall(
  processName: string,
  data: any,
  fromDashboard: boolean,
  previousProcessName: string,
  processModel?: KagamiProcessRequestModel
) {
  let requestEvent = createOnStartEvent(processName, data, previousProcessName, fromDashboard);
  return APIService.getData('POST', getAsyncBpmURL(), requestEvent);
}

export async function makeLinkProcessOnStartCall(linkProcessId: string) {
  let requestData = createLinkProcessRequestEvent(linkProcessId);
  return APIService.getData('POST', linkProcessURL(), requestData);
}

export async function makeEmbedColumnSearchCall(
  requestModel: KagamiSubProcessRequestModel,
  searchValue: any,
  embeddedId?: string,
  pageNumber: Number = 1,
  embedPageChange?: boolean,
  pageSize?: any
) {
  let requestEvent: any;
  if (embedPageChange) {
    requestEvent = createEmbedPageChangeEvent(requestModel, pageNumber, pageSize);
  } else {
    let entityId = getMappedEntityIdForPresentation(requestModel.entityPresentation);
    requestEvent = createEmbedColumnSearchEvent(
      requestModel.processName,
      requestModel.data,
      requestModel.runtimeInput,
      requestModel.changedAttribute,
      searchValue,
      entityId,
      embeddedId,
      pageNumber
    );
  }
  return APIService.getData(SystemConstants.POST, getbpmURL(), requestEvent);
}
