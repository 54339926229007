import { SystemConstants } from '../../../../constants/SystemConstants';
import { APIService } from '../../../../service/ApiService';
import {
  applicationURL,
  getDownloadSampleForBulkUpload,
  getBulkUploadUrl,
  getExcelSheetHeaders
} from '../../../../service/URLService';
import { createOnStartEvent } from '../body/builder/process/service/ServerEventService';

export function downloadSample(data:any) { 
	let requestEvent = createOnStartEvent(data.processName, null, data.processName, false);
  	if (requestEvent.event) requestEvent.event.stepName = data.eventName;
	delete requestEvent.uiEvent; 
	
    APIService.getData(SystemConstants.POST_WITH_BLOB_RESPONSE, getDownloadSampleForBulkUpload(), requestEvent)
      .then((response:any) => {
            const blob = new Blob([response?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Sample.xlsx';
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
      }).catch((error:any) => {
        if (error?.response?.status === 404) {
			// NOTE:: done only support backward compatiblity with older BE versions
      const eventName = data?.stepName || data?.eventName
            let oldSampleUrl = applicationURL + 'verb/bulk/ExcelMetaData?eventName=' +
            eventName + '&processName=' + data.processName;
          	window.open(oldSampleUrl, '_blank');
        } else {
          alert('Failed to fetch BPM bulk metadata.');
        }
      });
}

export function getBulkUploadList(data: any) {
  return APIService.getData(SystemConstants.POST, getBulkUploadUrl(), data);
}

export function getExcelHeadersList(docId: string, eventName: string, processName: string) {
  return APIService.getData(SystemConstants.GET, getExcelSheetHeaders(docId, eventName, processName), {});
}
