import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const ReportsPageStyle = (theme: Theme) =>
createStyles({
    main_page_reports: {
      display: 'flex',
      flexDirection: 'column',
      background: '#fff'
    },

    header_row: {
      display: 'flex',
      padding: '0px 14px',
      justifyContent: 'space-between',
      verticalAlign: 'middle',
      alignItems: 'center'
    },

    header_row_reportName: {
      color: theme.palette.primary.dark,
      fontSize: '20px',
      fontWeight: '500',
    },

    header_report_back_button: {
      'backgroundColor': '#ffff',
      'color': '#6ebe44',
      'border': '2px solid #dbdbdb',
      'transitionDuration': '0.4s',
      'width': '50px',
      'height': '30px',
      'borderRadius': '5px',
      'cursor':'pointer',
      '&:hover': {
        backgroundColor: '#ffff',
        color: '#2d72b9',
        border: '2px solid #6ebe44'
      }
    },

    report_header_link: {
      'cursor': 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      }
    },

    report_filter: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: '14px',
    },

    asondate: {
      textDecoration:'none',
      '&:hover': {
        textDecoration: 'underline',
      }
    },
    
    report_content: {
      margin: '0px 14px',
      // padding: '4px',
      '& .NoDataFound':{
        color:'red',
        fontSize:'12px',
        margin:'0px',
        padding:'0px'
      },
      '& .MuiInputBase-root' : {
        minHeight: '37px'
      }
    },

    report_header_link_main: {
      'fontSize': '14px',
      'cursor': 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },

    report_header_link_main_title: {
      color: '#2d72b9'
    },

    report_header_link_main_relationName: {
      color: '#6ebe44'
    },

    report_as_on_date: {
      'display': "flex",
      'flex-direction': "row-reverse",
    },

    hidden: {
      display:'none'
    },

    languages_container: {
      position :'relative'
    },

    languages_box : {
      position: 'absolute',
      borderRadius: '50px',
      background: '#eaf0ea',
      color: 'green',
      // width: '20px',
      // height: '20px',
      width: '21px',
      height: '21px',
      margin: '-2px 2px',
      // padding: '0px 2px'
      padding: '0px 3px'
      
   
    },

    languages_radio : {
      padding: '1px 5px 12px 10px',
      background: 'whitesmoke',
      borderRadius: '8px',
      border: '1px solid whitesmoke',
      height: '125px',
      boxShadow: '2px 3px 3px 1px #cfd8db',
      margin: '16px'
    },

  });