import { flexibleCompare } from '@fullcalendar/react';
import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles/createStyles';

export const KagamiTabNavigationStyles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      'width': '100%',
      // boxShadow:' -5px 0px 2px 0px #ccc inset' ,
      '& .MuiTabs-scroller': {
        height: '42px',
        display: 'flex',
        alignItems: 'center'
      },
      '& .MuiTabs-root': {
        alignItems: 'center',
        minHeight: '43px'
      }
    },
    tabs: {},
    tabContainer: {
      'backgroundColor': '#ccc',
      'marginRight': '10px',
      'padding': '5px 10px',
      'borderTopLeftRadius': '5px',
      'borderTopRightRadius': '5px',
      'borderBottomLeftRadius': '0',
      'borderBottomRightRadius': '0',
      'position': 'relative',
      '&:nth-child(10)': {
        marginRight: '0px'
      }
      // '&:nth-child(1):hover':{
      //   background:'#00856B'
      // }
    },

    closeIcon: {
      'position': 'absolute',
      'top': '-12px',
      'right': '-12px',
      'width': '25px',
      'height': '25px',
      'padding': '1px',
      'backgroundColor': theme.palette.background.paper,
      'borderRadius': '100%',
      'boxShadow': '0 3px 10px 2px rgb(0 0 0 / 0.2);',
      'cursor': 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.error.main
      }
    },
    MainDialogPaper: {
      'padding': '1px',
      '& .MuiDialog-container': {
        '& .MuiDialog-paper': {
          overflowY: 'inherit !important'
        }
      }
    },
    tabMain: {
      padding: '5px 5px',
      textAlign: 'left',
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '90%'
    },
    tabAline: {
      padding: '2px 8px'
    },
    tabActiveMain: {
      padding: '5px 5px',
      display: 'inline-block'
    },
    tab: {
      backgroundColor: 'none !important',
      minHeight: '0px !important',
      minWidth: '0px !important',
      margin: '0 !important',
      padding: '0 !important',
      textTransform: 'none',
      width: '90%',
      alignItems: 'flex-start'
    },
    tabActive: {
      backgroundColor: '#11344D!important',
      color: '#fff!important',
      opacity: '1'
    },
    tabInActive: {
      // color: '#33475b !important',
      // color: '#000 !important',
      // opacity: ".4 !important",
      opacity: '1'
      // '&:hover':{
      //   background:'#ccc'
      // }
    },
    icon: {
      'fontSize': '0.875rem',
      // width:'10%',
      'marginLeft': 'auto',
      'display': 'inline-block',
      'verticalAlign': 'middle',
      'top': '-2px',
      'position': 'absolute',
      'right': '-3px',
      'color': '#252222',
      'background': '#fff',
      'borderRadius': '50%',
      '&:hover': {
        color: theme.palette.error.main,
        fontSize: '20px',
        transition: '0.5s'
      }
    },
    dashBoardClass: {
      'background': ' #006e7f',
      'color': '#fff !important',
      '&:hover': {
        background: '#006e7f'
      }
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
    // tooltip:{
    //   visibility: 'hidden',
    //   width: '130px',
    //   backgroundColor: 'red',
    //   color: '#fff',
    //   position:'absolute',
    //   bottom: '-2px',
    //   left: '8px', // },
  });
