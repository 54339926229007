import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';


export const KagamiDateFieldStyle = (theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-root': {
        // height: '28px',
        // background: '#fff' ,
        // '& .MuiFormHelperText-root': {
        //   marginTop: '-3px'
        // }
      },
      '& .MuiInputBase-root': {
        // height:  '28px',
        fontSize: '16px'
      },

      '& input': {
        fontSize: '0.9rem',
        paddingLeft: theme.spacing(0.5)
      }
      // '& .MuiOutlinedInput-root ': {
      //   background: theme.palette.common.white,
      //   fontSize: '14px'
      // },

      // '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
      //   height: '0.8em',

      // },
      // '& .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input':{
      //   height :'0.8em'
      // }
    },
    controlLabel: {
      '&.MuiFormLabel-root ': {
        fontSize: '13px'
        // fontSize: theme.typography.caption.fontSize,
        // color: theme.palette.info.dark,
      }
    },
    readOnlyEmpty:{
      color:'#ccc !important',
  },
    readOnlyField: {
      margin: '0',
      // marginLeft: theme.spacing(-0.5)
      fontSize: '14px',
      color: theme.palette.primary.dark
    },
    readOnlyField_cont: {
      // paddingLeft: theme.spacing(1.6),
      // marginTop: theme.spacing(-1.75)
    },
    popper: {
      // zIndex:'5',
      '&.disabledHoliday': {
        color: theme.palette.error.main
      }
    },

    Ibutton: {
      position: 'absolute',
      top: theme.spacing(0.25),
      right: theme.spacing(0.5)
    },
 
  });
