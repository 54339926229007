export class KagamiDashboardCardModel {
    icon: any;
    title: any;
    content: any;
    transactionType: string = "";
    constructor (title: any, content: any){
        this.title = title;
        this.content = content;
    }
}

export interface KagamiDashboardCardModelProps {
    cardModel: KagamiDashboardCardModel;
}