import {
  EmbeddedTabPresentationProps,
  KagamiEmbeddedTabsPresentationModel,
  useKagamiEmbedTabsModelState
} from '../model/KagamiEmbeddedTabsPresentationModel';
import makeStyles from '@mui/styles/makeStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { createState } from '@hookstate/core';
import { EmbedTabsContent } from '../builder/KagamiEmbedTabsBuilder';
import { KagamiEmbeddedTabsStyle } from '../styles/KagamiEmbeddedTabsStyle';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import { KagamiEmbeddedFormPresentationModel } from '../../../../../model/KagamiEmbeddedFormPresentationModel';
import { getFormRecords, handleSingleEmbedFormLoad, isViewTemplate } from '../../../../../service/FormService';
import FormLabel from '@mui/material/FormLabel';
import { useEffect } from 'react';
import { getEmbeddedSectionContent } from '../../embedded-accordion-presentation/builder/EmbeddedFormBuilder';
import { EmbeddedRowCountModel } from '../../embedded-accordion-presentation/embedded-rowcount/model/EmbeddedRowCountModel';
import { embeddedRowCountController } from '../../embedded-accordion-presentation/embedded-rowcount/controller/EmbeddedRowCountController';
import { translateToLocalistaion } from '../../../../../../../service/PresentationService';
import { getActiveModel } from '../../../../../../../controller/KagamiProcessController';

const useStyles = makeStyles(KagamiEmbeddedTabsStyle);
export const BuildEmbeddedTabsPresentation = (embeddedTabsPresentationModel: KagamiEmbeddedTabsPresentationModel) => {
  return (
    <KagamiEmbeddedTabsPresentation
      key={embeddedTabsPresentationModel.key}
      embeddedTabsPresentationModel={embeddedTabsPresentationModel}   />
  );
};

const KagamiEmbeddedTabsPresentation = (props: EmbeddedTabPresentationProps) => {
  const classes = useStyles();
const isNested = props.embeddedTabsPresentationModel.isNestedEmbedModel
  let embedTabsState: any = useKagamiEmbedTabsModelState(createState(props.embeddedTabsPresentationModel), isNested);
  props.embeddedTabsPresentationModel.state = embedTabsState;

  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;

  useEffect(() => {
    let embedModels: any[] = props.embeddedTabsPresentationModel.embeddedPresentationsList;
    if (embedModels.length > 0) {
      let activeEmbedModel: KagamiEmbeddedFormPresentationModel = embedModels[0];
      if (
        activeEmbedModel.onloadRequired &&
        !activeEmbedModel.isListPresentation &&
        activeEmbedModel.isFirstOnloadCall &&
        !activeEmbedModel.isNested &&
        !isViewTemplate()
      ) {
        handleSingleEmbedFormLoad(activeEmbedModel, true);
        activeEmbedModel.isFirstOnloadCall = false;
      }
    }
  }, []);
        
    function TabPanel(props: any) {
        const { embedFormModel, value, index, ...other } = props;
      
        return (
          <div key={CommonUtils.isNotEmpty(embedFormModel) ? embedFormModel.formEntityId : ''}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <div className='d1'>
              {getEmbeddedSectionContent(embedFormModel, embedFormModel.readonly )}
              </div>
            )}
          </div>
        );
      }
    
  return CommonUtils.isNotEmpty(props.embeddedTabsPresentationModel.embeddedPresentationsList) ? (
    <div className={`${classes.mainRoot} fullWidthAccordSection`}>
      <Tabs
        classes={{
          root: classes.root,
          indicator: classes.indicator
        }}
        scrollButtons
        variant="scrollable"
        allowScrollButtonsMobile 
        aria-label="scrollable auto tabs example"
        onChange={(event, index) => {        
          props.embeddedTabsPresentationModel.state.setActiveTabIndex(index);          
          let emebdFormModel: KagamiEmbeddedFormPresentationModel =
            props.embeddedTabsPresentationModel.embeddedPresentationsList[index];
          /// Note : avoiding making multiple onload calls for single embed form , we are going to make onload only for the first time
          if (
            emebdFormModel.onloadRequired &&
            !emebdFormModel.isListPresentation &&
            emebdFormModel.isFirstOnloadCall &&
            !emebdFormModel.isNested &&
            !isViewTemplate()
          ) {
            handleSingleEmbedFormLoad(emebdFormModel, true);
            emebdFormModel.isFirstOnloadCall = false;
          }
        }}
        value={embedTabsState.getActiveTabIndex()}
      >
        {props.embeddedTabsPresentationModel.embeddedPresentationsList
          .filter((model) => model.visible === true)
          .map((embedFormModel, index) => {
            let records : any[] = getFormRecords(embedFormModel)
            embedFormModel.rowCountModel.dataCount = CommonUtils.isNotEmpty(records) ?  records.length : 0
            embedFormModel.rowCountModel.formEntityId = embedFormModel.formEntityId
            embedFormModel.rowCountModel.isNested = embedFormModel.isNested
            embedFormModel.rowCountModel.presentationType = 'tab'
            return (
              <Tab
                key={'KagamiEmbeddedTabsPresentation' + index}
                value={index}
                label={
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormLabel style={{paddingLeft: "10px"}} className={classes.tabLabel} required={embedFormModel.mandatory}>
                      {/* {embedFormModel.formHeader} */}
                      {translateToLocalistaion(translations, embedFormModel.formHeader)}
                    </FormLabel>
                  <div   style={{ position :'relative' , right : '-10px',
                  color: embedTabsState.getActiveTabIndex() === index ? "#29947F" : "#bbb6b6",
                  border: embedTabsState.getActiveTabIndex() === index ? "1px solid #29947F" : "1px solid #bbb6b6",
                  borderRadius: "50%", marginTop: '-13px'
                }}>{embeddedRowCountController(embedFormModel.rowCountModel)} </div> 
                </div>                
                }
                hidden={!embedFormModel.visible}               
              />
            );
          })}
      </Tabs>
      {props.embeddedTabsPresentationModel.embeddedPresentationsList
          
          .map((embedFormModel, index) => {
            return (
              <TabPanel value={embedTabsState.getActiveTabIndex()} index={index} embedFormModel={embedFormModel} />    
 
            );
          })}
  <>

</>

    </div>
  ) : (
    <div></div>
  );
};
