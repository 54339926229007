import extend from 'lodash/extend';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { getReportData } from '../../../../service/ReportUtilService';

export const categoryRank: any = {
  'C': 1,
  'B': 2,
  'B+': 3,
  'A': 4,
  'A+': 5
}

const bellCurveDefaultConfig = () => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'bellCurve',
      height: 450,
    },
    exporting: {
      enabled: true
    },
    title: {
      text: '',
      align: 'center',
    },
    tooltip: {
      shared: true,
      valueSuffix: ' '
    },
    plotOptions: {
      bellCurve: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: true
      }
    },
    credits: {
      enabled: false,
    },
    series: [{
      name: '',
      colorByPoint: true,
      data: [],
    }]
  }
}

export const makeBellCurve = (bellCurveReportController: any) => {
  let defaultConfig = bellCurveDefaultConfig();
  let dbChartData = JSON.parse(bellCurveReportController.reportData);
  let tablerecords = getReportData(dbChartData, bellCurveReportController.reportConfig);
  let userConfig = prapareUserConfigForChart(tablerecords, bellCurveReportController.reportConfig, false);
  let dispchartOptions = extend(true, {}, defaultConfig, userConfig);
  return dispchartOptions;
}

const prapareUserConfigForChart = (tablerecords: any, reportConfig: any, isDashboard: any) => {
  let userConfig: any = {
    yAxis: {
      title: {
        text: getYAxisLabels(reportConfig.horizontalAxis)
      },
    },
    xAxis: {
      title: {
        text: reportConfig.verticalAxis
      },
      categories: bellCurve_getColumn(reportConfig.verticalAxis, tablerecords)
    },
    series: [
      {
        data: []
      }
    ]
  };
  let seriesData: any = [];
  let xaxis = reportConfig.horizontalAxis[0];
  let yaxis = reportConfig.verticalAxis[0];

  const configData = new Map<string, any>();
  if (xaxis && yaxis) {
    forEach(tablerecords, function (rec, index) {
      seriesData.push({
        category: rec[yaxis],
        name: rec[xaxis],
        rating: categoryRank[rec[yaxis]]
      });
      configData.set(rec["acronym"], {
        minPercent: +rec["minpercentage"],
        maxPercent: +rec["maxpercentage"],
      })
    });
  }
  userConfig.series.data = seriesData;
  const _configData: any = [];
  configData.forEach((val, key) => {
    _configData.push({ key: key, val: val })
  })
  userConfig.series.configData = _configData;
  return userConfig;

}


const getYAxisLabels = (arr: any) => {
  return (arr && arr.length > 0) ? arr.join(",") : ''
}
const bellCurve_getColumn = (colId: any, chartData: any) => {
  return map(chartData, function (row) {
    return row[colId];
  });
}
