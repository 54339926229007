
const STATIC_REPORT_FIELDS: any = [
    { header: 'Sub Organization', field: 'subOrganization', dataField: 'suborganization', type: 'SELECT', isMandatory: true },
    { header: 'Ledger Account', field: 'selectedValue', dataField: 'accountResult', type: 'SELECT', isMandatory: true ,required : true},
    { header: 'Currency', field: 'selectedCurrency', dataField: 'currency', type: 'SELECT', isMandatory: false },
    { header: 'GST', field: 'gst', dataField: 'gst', type: 'SELECT', isMandatory: false },
    { header: 'State', field: 'state', dataField: 'state', type: 'MULTISELECT', isMandatory: false },
    { header: 'Project', field: 'projectPlan', dataField: 'projectPlan', type: 'MULTISELECT', isMandatory: false },
    { header: 'From Date', field: 'startDate', dataField: 'fromDate', type: 'DATE', isMandatory: true },
    { header: 'To Date', field: 'endDate', dataField: 'toDate', type: 'DATE', isMandatory: true },
]

const SUB_LEDGER_DEPENDENT_OPTIONS = [
    { label: 'Employee', value: 'employee' },
    { label: 'Customer', value: 'customer' },
    { label: 'Vendor', value: 'vendor' },
    { label: 'Bank', value: 'bank' },
    { label: 'Project', value: 'project' }
]

export const SUB_LEDGER_AGEING_OPTIONS = [
    { label: 'FROM', value: 'From' },
    { label: 'TO', value: 'To' }
]

export const getStaticReportFields = (type: string) => {
    let fields = [...STATIC_REPORT_FIELDS];
    switch (type) {
        case 'Sub Ledger':
            let dependentValue = { header: 'Dependent Value', field: 'dependant', dataField: 'dependant', type: 'RADIO', isMandatory: true, options: SUB_LEDGER_DEPENDENT_OPTIONS };
            fields.splice(1, 0, dependentValue);
            let controlAccount = { header: 'Control Account', field: 'classifier2', dataField: 'controlAccounts', type: 'MULTISELECT', isMandatory: true };
            fields.splice(2, 0, controlAccount);
            fields[3]['header'] = 'Account Value';
            return fields;
        case 'BLReport':
        case 'PLReport':
        case 'TrialBLReport':
            return fields.filter(ele => ele.field != 'selectedValue')
        case 'Customer Ageing':
        case 'Vendor Ageing':
            fields.splice(-2);//Remove From &  toDate
            let asOnDate = { header: 'As On Date', field: 'asonDate', dataField: 'asonDate', type: 'DATE', isMandatory: true };
            fields.splice(0, 0, asOnDate);
            fields.push({ header: 'Ageing Periods', field: 'agingPeriod', dataField: 'agingPeriod', type: 'NUMBER', isMandatory: true, options: SUB_LEDGER_AGEING_OPTIONS });
            fields[2]['header'] = 'Ageing List';
            return fields;
        case 'Vendor Payment Due List':
        case 'Customer Payment Due List':
            fields.splice(6, 1);
            fields[1]['header'] = "Payment Due List";
            fields[6]['header'] = "Date";
            return fields;
        case 'DayBook':
            fields.splice(1, 1);
            return fields;
        case 'Stock Ledger':
            fields[1]['header'] = "Item Data";
            fields.splice(2, 1);
            return fields;
        default:
            return fields;
    }
}

export const getStaticFilterValue = (reportConfig: any, field: string) => {
    switch (field) {
        case 'subOrganization':
            return reportConfig?.subOrganization?.[0] || '';
        case 'selectedValue':
            return reportConfig?.selectedValue || '';
        case 'currency':
            return reportConfig?.currency || '';
        case 'state':
            return reportConfig?.state || '';
        case 'gst':
            return reportConfig?.gst || '';
        case 'projectPlan':
            return reportConfig?.projectPlan || '';
        case 'startDate':
            return reportConfig?.leftPanel?.startDate || '';
        case 'endDate':
            return reportConfig?.leftPanel?.endDate || '';
        default:
            return '';
    }
}

export const getStaticReportActions = (reportType: any) => {
    let buttonsArr: any = [];
    switch (reportType) {
        case 'Bank Account':
        case 'Cash Account':
        case 'Bank Ledger':
        case 'Cash Ledger':
        case 'Customer Ledger':
        case 'General Ledger':
        case 'Vendor Ledger':
        case 'Sub Ledger':
            buttonsArr = [
                {
                    label: 'Excel', type: 'BUTTONS_LIST', actions: [
                        { label: 'With Narration', eventType: 'onDownloadExcel', params: { reportType: 'LEDGER', index: 0 } },
                        { label: 'Without Narration', eventType: 'onDownloadExcel', params: { reportType: 'LEDGER', index: 1 } },
                        { label: 'With Narration & Closing Balance', eventType: 'onDownloadExcel', params: { reportType: 'LEDGER', index: 2 } },
                        { label: 'Without Narration & Closing Balance', eventType: 'onDownloadExcel', params: { reportType: 'LEDGER', index: 3 } }
                    ]
                },
                { label: 'Detailed Excel', eventType: 'onDetailedExcel' },
                {
                    label: 'Print Potrait', type: 'BUTTONS_LIST', actions: [
                        { label: 'With Narration', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 0, printType: 'potrait' } },
                        { label: 'Without Narration', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 1, printType: 'potrait' } },
                        { label: 'With Narration & Closing Balance', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 2, printType: 'potrait' } },
                        { label: 'Without Narration & Closing Balance', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 3, printType: 'potrait' } }
                    ]
                },
                {
                    label: 'Print Landscape', type: 'BUTTONS_LIST', eventType: 'onDownloadPdf', actions: [
                        { label: 'With Narration', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 0, printType: 'landscape' } },
                        { label: 'Without Narration', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 1, printType: 'landscape' } },
                        { label: 'With Narration & Closing Balance', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 2, printType: 'landscape' } },
                        { label: 'Without Narration & Closing Balance', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 3, printType: 'landscape' } }
                    ]
                },
            ]
            break;
        // case 'Sub Ledger':
        // break;

        case 'Customer Ageing':
        case 'Vendor Ageing':
            buttonsArr = [
                { label: 'Download Excel', eventType: 'onDownloadExcel', params: { reportType: 'AGEING' } },
                { label: 'Download PDF', eventType: 'onDownloadPdf', params: { reportType: 'AGEING' } }
            ]
            break;
        case 'BLReport':
        case 'PLReport':
        case 'TrialBLReport':
            buttonsArr = [{ label: 'Expand All', eventType: 'EXPAND', params: { reportType: reportType } },
            { label: 'Download Excel', eventType: 'onDownloadExcel', params: { reportType: reportType } }
            ]
            break;

        case 'Vendor Payment Due List':
        case 'Customer Payment Due List':
            buttonsArr = [
                { label: 'Download Excel', eventType: 'onDownloadExcel', params: { reportType: 'PAYMENTDUELIST' } },
                { label: 'Download PDF', eventType: 'onDownloadPdf', params: { reportType: 'PAYMENTDUELIST' } }
            ]

            break;
        case 'DayBook':
            buttonsArr = [
                {
                    label: 'Excel', type: 'BUTTONS_LIST', actions: [
                        { label: 'With Narration', eventType: 'onDownloadExcel', params: { reportType: 'LEDGER', index: 0 } },
                        { label: 'Without Narration', eventType: 'onDownloadExcel', params: { reportType: 'LEDGER', index: 1 } },
                    ]
                },
                {
                    label: 'Print Potrait', type: 'BUTTONS_LIST', actions: [
                        { label: 'With Narration', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 0, printType: 'potrait' } },
                        { label: 'Without Narration', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 1, printType: 'potrait' } },
                    ]
                },
                {
                    label: 'Print Landscape', type: 'BUTTONS_LIST', eventType: 'onDownloadPdf', actions: [
                        { label: 'With Narration', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 0, printType: 'landscape' } },
                        { label: 'Without Narration', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 1, printType: 'landscape' } },
                    ]
                },
            ]
            break;
        case 'Stock Ledger':
            buttonsArr = [
                { label: 'Download Excel', eventType: 'onDownloadExcel', params: { reportType: 'LEDGER', index: 0 } },
                {
                    label: 'Print', type: 'BUTTONS_LIST', actions: [
                        { label: 'Potrait', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 0, printType: 'potrait' } },
                        { label: 'Landscape', eventType: 'onDownloadPdf', params: { reportType: 'LEDGER', index: 0, printType: 'landscape' } }
                    ]
                },
            ]
            break;

        default:
            return buttonsArr;
    }
    return buttonsArr;
}

const getGridOptions = (keys: any, gridInfo: any) => {
    let gridOptions: any = {};
    if (keys?.length) {
        keys.forEach((key: any) => {
            gridOptions[key] = gridInfo[key];
        });
    }
    return gridOptions;
}

export const getStaticReportGridOptions = (config: any) => {
    let keys = [];
    let gridOptions: any = {};
    let gridReportInfo = config?.gridReportInfo;
    let reportType = config?.inputReportConfig?.reportConfigSummary?.type;
    switch (reportType) {
        case 'Bank Account':
        case 'Cash Account':
        case 'Bank Ledger':
        case 'Cash Ledger':
        case 'Customer Ledger':
        case 'General Ledger':
        case 'Vendor Ledger':
        case 'Sub Ledger':
            keys = ['columnDefs', 'defaultColDef', 'detailCellRendererParams', 'detailRowHeight', 'groupIncludeTotalFooter', 'icons', 'masterDetail', 'rowHeight', 'rowData', 'pinnedBottomRowData', 'suppressAggFuncInHeader'];
            gridOptions = getGridOptions(keys, gridReportInfo);
            gridOptions['onRowClicked'] = config?.onLedgerDetails;
            break;
        case 'BLReport':
        case 'PLReport':
        case 'TrialBLReport':
            keys = ['autoGroupColumnDef', 'columnDefs', 'defaultColDef', 'getDataPath', 'groupIncludeTotalFooter', 'icons', 'rowData', 'treeData'];
            gridOptions = getGridOptions(keys, gridReportInfo);
            break;
        case 'Customer Ageing':
        case 'Vendor Ageing':
            keys = ['columnDefs', 'defaultColDef', 'getRowHeight', 'groupIncludeTotalFooter', 'icons', 'pagination', 'paginationPageSize', 'rowHeight', 'rowData', 'suppressAggFuncInHeader'];
            gridOptions = getGridOptions(keys, gridReportInfo);
            break;
        case 'Vendor Payment Due List':
        case 'Customer Payment Due List':
            keys = ['columnDefs', 'defaultColDef', 'getRowHeight', 'groupIncludeTotalFooter', 'icons', 'pagination', 'paginationPageSize', 'rowHeight', 'rowData', 'suppressAggFuncInHeader'];
            gridOptions = getGridOptions(keys, gridReportInfo);
            break;
        case 'DayBook':
            keys = ['columnDefs', 'defaultColDef', 'detailCellRendererParams', 'detailRowHeight', 'onRowClicked', 'groupIncludeTotalFooter', 'icons', 'masterDetail', 'rowHeight', 'rowData', 'pinnedBottomRowData', 'suppressAggFuncInHeader'];
            gridOptions = getGridOptions(keys, gridReportInfo);
            gridOptions['onRowClicked'] = config?.onLedgerDetails;
            break;
        case 'Stock Ledger':
            keys = ['columnDefs', 'defaultColDef', 'detailCellRendererParams', 'detailRowHeight', 'groupIncludeTotalFooter', 'icons', 'masterDetail', 'rowHeight', 'rowData', 'pinnedBottomRowData', 'suppressAggFuncInHeader'];
            gridOptions = getGridOptions(keys, gridReportInfo);
            break;
        default:
            gridOptions = {
                columnDefs: gridReportInfo.columnDefs,
                rowHeight: gridReportInfo.rowHeight,
                rowData: gridReportInfo.rowData,
            }
            break;
    }
    return gridOptions;
}