import { KagamiTabNavigationModel } from '../../../../header/Tabs/model/KagamiTabNavigationModel';
import { KagamiFolderModel } from '../model/KagamiFolderModel';
import { buildMainMenu } from '../ui/KagamiMenuFolder';

export function buildMenuFolder(
  menuItems: KagamiFolderModel[],
  favouriteData: any,
  navigationData: any,
  tabNavigtaionModel: KagamiTabNavigationModel
) {
  return buildMainMenu(menuItems, favouriteData, navigationData, tabNavigtaionModel);
}
