import _ from 'lodash';

import { getLedgerReportData, getStaticFilterData, getStaticFilterInfo } from '../../../../service/KagamiReportService';
import { getReportType, getSelectedCurrency, handleStaticReportDates, numberCellDisplayFormatter, getValueInDateTimeFormatAsAppSetting } from '../../../../service/ReportUtilService';

export async function getDBStaticFilterData(ledgerAccountReportController: any) {
    let reportType =  getReportType(ledgerAccountReportController.reportConfig);
    return await getStaticFilterData(reportType);
}
export const makeReport = (ledgerAccountReportController: any,inputReportConfig:any) => {
        let defaultConfig:any ={
          autoGroupColumnDef: {  cellRendererParams: {suppressCount: true,checkbox: false}},    
          columnDefs: [],
          defaultColDef: { flex :1,sortable: true,filter: true,resizable: true,editable: false },
          detailCellRendererParams: {
            detailGridOptions: {
              columnDefs: [  ],
              defaultColDef: {
                flex: 1,
              },
            },
            getDetailRowData: (params:any) => {
              params.successCallback(params.data.nestedFinanceRecord);
            },
          },
          detailRowHeight: 190,
          groupDefaultExpanded: -1,//expand everything by default
          groupHideOpenParents:true,
          groupIncludeTotalFooter: false,
          getRowStyle: (params:any) => {
            if (params?.node?.rowPinned) {
                return {'font-weight': 'bold'}
            }
          },
          getRowHeight :(params: any) => 30,
          rowHeight:34,
          icons: {
            // groupExpanded:'<i class="fa fa-arrow-down"/>',
            // groupContracted:'<i class="fa fa-user"/>'
            groupContracted:'<div class="kgm-plus-icon radius"></div>',
            groupExpanded:'<div class="kgm-minus-icon radius"></div>'
          },
          masterDetail: true,
          onFirstDataRendered: (params:any) => {
            setTimeout(function () {
              params.api.getDisplayedRowAtIndex(1).setExpanded(true);
            }, 0);
          },
          pivotMode : false,
          pagination: true,
          paginationPageSize: 50,
          pinnedBottomRowData: [],
          rowData:[],
          suppressAggFuncInHeader: true, //hide aggregate name in a row 
        };
        let userConfig = prapareUserConfigForReport(ledgerAccountReportController,defaultConfig);  
        let reportConfig = _.extend(true, {}, defaultConfig, userConfig);
        return reportConfig;

} 


function prapareUserConfigForReport (reportController: any,defaultConfig:any) {
    let translation = reportController.languageTranslation || {}; 
    let userConfig:any = {
      columnDefs: [],
      rowData: [],
    };
    let columnDefs: any = [];
    columnDefs =[
       {headerName: translation['Date'] || 'Date', field: 'date',cellRenderer: 'agGroupCellRenderer' },
       {headerName: translation['Particulars'] || 'Particulars', field: 'type'},
       {headerName: translation['Project Name'] || 'Project Name', field: 'projectName'},
       {headerName: translation['Reference No'] || 'Reference No', field: 'referenceNo'},
       {headerName: translation['Voucher No'] || 'Voucher No', field: 'docno',cellStyle:{'font-weight':'bold','text-decoration':'underline'}},
       {headerName: translation['Voucher Type'] || 'Voucher Type', field: 'transtype'},
       {headerName: translation['Debit'] || 'Debit', field: 'debit',type: 'rightAligned'},
       {headerName: translation['Credit'] || 'Credit', field: 'credit',type: 'rightAligned'},
       {headerName: translation['Closing Balance'] || 'Closing Balance', field: 'balance',type: 'rightAligned',cellStyle:{'padding-right':'10px'}},
      ]
    userConfig['columnDefs'] = columnDefs;
    defaultConfig['detailCellRendererParams']['detailGridOptions']['columnDefs'] = [
      {headerName: '', field: 'accountName'},
      {headerName: '', field: 'accountValue'},
    ]
    return userConfig;
  }

    export const  handledColumnsBasedOnProjects = (reportInfo:any,ledgerData:any) => {
      if(ledgerData) {
        // let projectNames =_.map(ledgerData || [],'projectName');
        // let isProjectNameExists = _.filter(projectNames,function (o) { return  o }).length > 0 ? true : false;
        // if(!isProjectNameExists && reportInfo.columnDefs){
        //   reportInfo.columnDefs = _.filter(reportInfo.columnDefs,function(o) { return o.field !='projectName' })
        // }
        let isExistProjectName = true;
        let isExistReferenceNumber = true;
        var projectNames = _.filter(ledgerData,function(o) { return _.has(o,'projectName')})
        if(projectNames && projectNames.length == 0 && reportInfo.columnDefs){
          isExistProjectName = false;
          reportInfo.columnDefs = _.filter(reportInfo.columnDefs,function(o) { return o.field !='projectName' })
        }
        var referenceNos = _.filter(ledgerData,function(o) { return _.has(o,'referenceNo')})
        if(referenceNos && referenceNos.length == 0 && reportInfo.columnDefs){
          isExistReferenceNumber = false;
          reportInfo.columnDefs = _.filter(reportInfo.columnDefs,function(o) { return o.field !='referenceNo' })
        }

        if(reportInfo?.defaultColDef &&  (isExistProjectName || isExistReferenceNumber )) {
            reportInfo.columnDefs.forEach((col: any) => {
              if(col.field == 'docno' || col.field == 'balance')     {
                col ['minWidth'] = '200';
              }
            });
          
          reportInfo.defaultColDef['minWidth'] = '170';
        }

      }
    }
  

    export function makeLedgerData( reportModel:any,dbReportData: any) {
      let currency = getSelectedCurrency(reportModel.reportConfig);
      return getReportData(_.cloneDeep(dbReportData), currency) || [];
    }
    export const getData = async (reportModel :any,inputReportConfig: any) => {

      if((!inputReportConfig.state || inputReportConfig.state.length == 0 ) && (reportModel?.reportData?.staticFilterData?.data?.state)){
        inputReportConfig['state'] = "NOPOP";
      }
      if((!inputReportConfig.projectPlan || inputReportConfig.projectPlan.length == 0) && (reportModel?.reportData?.staticFilterData?.data?.projectPlan)){
        inputReportConfig['projectPlan'] = "NOPOP";
      }

    inputReportConfig['templateType'] = (inputReportConfig?.ktreeReport?.ktreeReport?.templateType) ? (inputReportConfig?.ktreeReport?.ktreeReport?.templateType).trim() : null;
    handleStaticReportDates(inputReportConfig);
    let res = await getLedgerReportData(inputReportConfig);
    if (res?.data) {
        return res.data['financeReportRow'];
    }
    return [];
    };

  const customCreditOrDebitCellFormatter = (cellvalue:any, currency:any) => {
    return numberCellDisplayFormatter(cellvalue, currency)
  }

  const getReportData = (financeReportRow:any,currency:any) => {
    _.forEach(financeReportRow, function (ledgerRow,index) {
      if (ledgerRow.credit) {
        ledgerRow.creditAmt = ledgerRow.credit;
        ledgerRow.credit = customCreditOrDebitCellFormatter(ledgerRow.credit,currency);
      }
      if (ledgerRow.debit) {
        ledgerRow.debitAmt = ledgerRow.debit;
        ledgerRow.debit = customCreditOrDebitCellFormatter(ledgerRow.debit,currency);
      }
      if (ledgerRow.balance) {
          let withCommas = customCreditOrDebitCellFormatter(ledgerRow.balance,currency);
          // ledgerRow.balance = currency + " " + withCommas;
          ledgerRow.balance = withCommas;
          ledgerRow.balanceAmt = ledgerRow.balance;

          if(ledgerRow.balanceType){
          // ledgerRow.balance = currency + " " + withCommas +" "+ledgerRow.balanceType;
            ledgerRow.balance = withCommas +" "+ledgerRow.balanceType;
          }
      }
      if (ledgerRow.date) {
        if(index == "0" && ledgerRow.type == "Balance B/F") {   
            ledgerRow.type = "Opening Balance";
        }
        let [date, time] = ledgerRow.date?.split(" ");
        ledgerRow.date = (time === undefined || time === "00:00:00") ? getValueInDateTimeFormatAsAppSetting(date, 'date') : getValueInDateTimeFormatAsAppSetting(ledgerRow.date, 'datetime')
      }
    })
    return financeReportRow || [];
  }
  
  export function makeFooterRowData (reportModel:any,dbReportData: any) {
    let currency = getSelectedCurrency(reportModel?.reportConfig);
    let translation = reportModel.languageTranslation || {}; 
    let footerRows = [
      { transtype : (translation['Opening Balance'] ||  'Opening Balance')+" :",'debit':displayOpeningBalance('DEBIT',dbReportData,currency),'credit':displayOpeningBalance('CREDIT',dbReportData,currency)},
      { transtype : (translation['Current Total'] ||  'Current Total')+" :",'debit':getAggregationValue('DEBIT',dbReportData,currency),'credit':getAggregationValue('CREDIT',dbReportData,currency)}, 
      { transtype : (translation['Closing Balance'] ||  'Closing Balance')+" :",'debit':displayClosingBalance("DEBIT",dbReportData,currency),'credit':displayClosingBalance("CREDIT",dbReportData,currency)}
    ];
    return footerRows;
  }

  
   
  export const onChangeAccount = async (account:any,ledgerAccountReportController:any) => {
    let reportType =  getReportType(ledgerAccountReportController.reportConfig);
    if(account && account.length >1 ){
      account =  account.split("-")[1];
    }   
    if(reportType && account && account.length >0 && reportType == 'Vendor Ledger') {
        let QueryParam='';
        QueryParam = QueryParam.concat("ledgerType=" + reportType +'&');
        QueryParam = QueryParam.concat("dependant="  + account);
        let staticData = await getStaticFilterInfo(QueryParam);
        if (staticData?.data) {
          return _.cloneDeep(staticData.data.gst || []);
        }
    }
    return [];
} 

  export const onChangeState = async (state:any,ledgerAccountReportController:any) => {
    let reportType =  getReportType(ledgerAccountReportController.reportConfig);
    if(reportType && state && state.length >0) {
        let QueryParam='';
        QueryParam = QueryParam.concat("ledgerType=" + reportType +'&');
        QueryParam = QueryParam.concat("dependant=" + "state|" + state.join(","));
        let staticData = await getStaticFilterInfo(QueryParam);
        if (staticData?.data) {
          return _.cloneDeep(staticData.data.projectPlan || []);
        }
    }
    return [];
}


/* FOR FOOTER */
const displayOpeningBalance = (type:any,financeReportRow:any,currency:any) => {
  if(financeReportRow && financeReportRow.length > 0){
      let value= financeReportRow[0];
      return type =='CREDIT'  ? (customCreditOrDebitCellFormatter(value.credit,currency || '')): (customCreditOrDebitCellFormatter(value.debit, currency || ''));
  }   
  return "";
}

 const displayClosingBalance = (type:any,financeReportRow:any ,currency:any) => {
  if(financeReportRow && financeReportRow.length > 0){
      let value= financeReportRow[financeReportRow.length-1];
     if(type=='CREDIT') {
          if(value.balanceType=="Cr" && value.balance){
            // return value.balance +" Cr";
            return customCreditOrDebitCellFormatter(value.balance ,currency || "")+" Cr";
          }else { return ""};
     }else if(type=='DEBIT'){
        if(value.balanceType=="Dr" && value.balance){
          // return value.balance +" Dr";
          return customCreditOrDebitCellFormatter(value.balance , currency || "")+" Dr";
         }else { return ""};
    }
  }   
  return "";
}
const getAggregationValue = (type: any, financeReportRow: any,currency:any) => {
  if (financeReportRow && financeReportRow.length > 0) {
    let totalVal = 0;
    _.forEach(financeReportRow, function (rec,index:any) {
      if(index !=0  ) {
        if (type == 'CREDIT' && rec.credit) {
          totalVal += parseFloat(rec.credit);
        } else if (type == 'DEBIT' && rec.debit) {
          totalVal += parseFloat(rec.debit);
        }
      }
    });
    if (totalVal) {
      return customCreditOrDebitCellFormatter(""+totalVal, currency || '');
    }
  }
  return '';
};



/* END  FOR FOOTER */
