import { hideLoader } from '../../../../../../../../../../../../../../components/loader/controller/KagamiLoaderController';
import { EventType, KagamiSubProcessRequestModel } from '../../../../../../../model/KagamiSubProcessRequestModel';
import { updateErrorContent } from '../../../../../../../service/ProcessPageService';
import { makeEmbedColumnSearchCall, makeOnLoadCall } from '../../../../../../../service/ProcessService';
import { onSearchSuccess } from '../../../../../../../service/SubProcessService';
import { KagamiFormModel } from '../../../../../../form/model/KagamiFormModel';
import { _getUnSyncedFormRecord } from '../../../../../../form/service/FormService';
import { getKagamiListModel } from '../../../../../controller/KagamiListController';
import { KagamiListModel } from '../../../../../model/KagamiListModel';
import {
  getEmbedStartRecord,
  getEmbedEndRecord,
  getStartRecord,
  getEndRecord
} from '../../../../../service/kagamiListService';
import { reBuildGridViewForPagination } from '../../../controller/KagamiGridController';

export function paginationApiCall(
  props: any,
  paginationModel: any,
  activeModel: any,
  embeddedInfo: any,
  verbProperties: any,
  transverseDirection: string,
) {
  let isForwardPagination: boolean = transverseDirection === 'forward' ? true : false;
  if (props?.kagamiGridModel?.isEmbedModel) {
    let requestModel: KagamiSubProcessRequestModel = new KagamiSubProcessRequestModel(
      paginationModel?.processName,
      '',
      null,
      null,
      EventType.PAGECHANGE,
      onSearchSuccess,
      updateErrorContent
    );
    let id = _getUnSyncedFormRecord(activeModel, props?.kagamiGridModel?.mainEntityId, true);
    requestModel.data = activeModel?.formData?.changedData;
    requestModel.changeFor = {};
    requestModel.embeddedInfo = embeddedInfo;
    makeEmbedColumnSearchCall(
      requestModel,
      '',
      '',
      paginationModel?.state?.getActivePageIndex(),
      true,
      props?.kagamiGridModel?.embeddedPageSize
    ).then((response: any) => {
      if (response?.data?.constructOutputData) {
        hideLoader();
        let dataRecords =
          response?.data?.constructOutputData?.detailedObjects[paginationModel?.mainEntityId][0][
            props?.kagamiGridModel?.mainEntityId
          ];
        paginationModel.startRecord = getEmbedStartRecord(response?.data, props?.kagamiGridModel?.mainEntityId);
        paginationModel.endRecord = getEmbedEndRecord(response?.data, props?.kagamiGridModel?.mainEntityId);
        paginationModel.state.setStartRecord(paginationModel?.startRecord);
        paginationModel.state.setEndRecord(paginationModel?.endRecord);
        if (activeModel instanceof KagamiListModel || activeModel instanceof KagamiFormModel) {
          activeModel = getKagamiListModel();
          reBuildGridViewForPagination(activeModel, dataRecords, paginationModel?.mainEntityId, true, false, isForwardPagination);
        }
      }
    });
  } else {
    makeOnLoadCall(
      paginationModel?.processName,
      paginationModel?.entityPresentation,
      {},
      {},
      null,
      verbProperties
    ).then((response) => {
      if (response?.data?.constructOutputData) {
        hideLoader();
        let dataRecords = response?.data?.constructOutputData?.detailedObjects[paginationModel?.mainEntityId];
        paginationModel.startRecord = getStartRecord(response?.data);
        paginationModel.endRecord = getEndRecord(response?.data);
        paginationModel.state.setStartRecord(paginationModel?.startRecord);
        paginationModel.state.setEndRecord(paginationModel?.endRecord);
        if (activeModel instanceof KagamiListModel || activeModel instanceof KagamiFormModel) {
          activeModel = getKagamiListModel();
          reBuildGridViewForPagination(activeModel, dataRecords, paginationModel?.mainEntityId, false, true, isForwardPagination);
        }
      }
    });
  }
}
