import { CloseTwoTone } from "@mui/icons-material";
import { Box, Dialog, DialogTitle, IconButton } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { CommonUtils } from "../../../application/utils/CommonUtils";
import { Loader } from "../../loader/ui/KagamiLoader";
import { getAuditLogData } from "../service/AuditLogService";
import "../styles/AuditLog.scss";
import "ag-grid-enterprise";

type AuditLogProps = {
  entityId: string;
  transId: string;
};

export interface IAuditLog {
  id: string;
  entityId: string;
  transId: string;
  details: string;
  updatedts: string;
  updatedby: string;
}

function AuditLog({ entityId, transId }: AuditLogProps) {
  import("ag-grid-enterprise");
  const [auditLogs, setAuditLogs] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([] as any);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");
  const [compareAuditLogs, setCompareAuditLogs] = useState([]);
  let gridApi: any = null;
  const ReactDiffViewer = lazy(() => import("react-diff-viewer"));
  let auditColDefs: any = [];

  useEffect(() => {
    getAuditLogData(entityId, transId, setAuditLogs);
  }, []);

  const jsonCellRenderer = (params: any): any => {
    const val = JSON.parse(params.data.details);
    console.log(val);
    const ReactJson = lazy(() => import("react-json-view"));
    return (
      <Suspense fallback={<Loader />}>
        <ReactJson
          src={val}
          name={false}
          theme="flat"
          iconStyle="circle"
          style={{ width: "100%" }}
        />
      </Suspense>
    );
  };

  const colDefs: any = [
    { field:  "id", hide: true },
    { field: "entityId", headerName: CommonUtils.makeFirstLetterCapital('Entity Id'), headerCheckboxSelection: true, checkboxSelection: true, },
    { field: "transId", headerName: CommonUtils.makeFirstLetterCapital('Transaction Id')},
    { field: "updatedTimeStamp",  headerName: CommonUtils.makeFirstLetterCapital('Time stamp')},
    { field: "updatedBy", headerName: CommonUtils.makeFirstLetterCapital('Updated By')},
  ];

  const getRowDataForAuditLogs = (firstValue: any, secondValue: any) => {
    if (CommonUtils.isNotEmpty(firstValue._header)) {
      firstValue._header["index"] = "1";
    }
    if (CommonUtils.isNotEmpty(secondValue._header)) {
      secondValue._header["index"] = "2";
    }
    let getRowDataFirstValues = [firstValue._header];
    let getRowDataSecondValues = [secondValue._header];
    let mergeData = getRowDataFirstValues.concat(getRowDataSecondValues);
    return mergeData;
  };
  const getColoumDefData = (firstvaluedata: any) => {
    if (CommonUtils.isNotEmpty(firstvaluedata)) {
      let getHeadersData = Object.keys(firstvaluedata._header);
      getHeadersData.forEach((ele: any) => {
        if (ele !== "index") {
          auditColDefs.push({ field: CommonUtils.makeFirstLetterCapital(`${ele}`) });
        }
      });
      return auditColDefs;
    } else {
      return auditColDefs;
    }
  };
  const onGridReady = (params: any) => {
    gridApi = params.api;
    autoSizeColumns(params);
  };

  const autoSizeColumns = (params: any) => {
    params.api.sizeColumnsToFit();
  };

  const getActionButtons = () => {
    let retVal = <div className="actionbuttons"></div>;
    if (selectedRecords.length === 2) {
      retVal = (
        <div className="actionbuttons">
          <button onClick={handleCompare}>Compare</button>
        </div>
      );
    }
    return retVal;
  };

  const handleCompare = () => {
    setCompareAuditLogs(selectedRecords);
    setFirstValue(JSON.parse(selectedRecords[0].details));
    setSecondValue(JSON.parse(selectedRecords[1].details));
    setIsModalOpen(true);
    console.log(selectedRecords);
  };

  const handleRowSelection = (params: any) => {
    const selected = params.api.getSelectedNodes().map((node: any) => {
      return node.data;
    });
    setSelectedRecords(selected);
  };

  const rowClassRules = useMemo(() => {
    return {
      "audit-index1": (params: any) => params.data.index === "1",
      "audit-index2": (params: any) => params.data.index === "2",
    };
  }, []);

  return (
    <div className="auditlog-container">
      <div className="log_header">Audit Logs</div>
      {getActionButtons()}
      <div
        style={{ height: "100%", width: "100%" }}
        className={"ag-theme-alpine kgm-grid"}
      >
        <AgGridReact
          components={{
            jsonCellRenderer: jsonCellRenderer,
          }}
          suppressRowClickSelection
          suppressDragLeaveHidesColumns={true}
          rowData={auditLogs}
          animateRows={true}
          domLayout={"autoHeight"}
          preventDefaultOnContextMenu={true}
          onGridReady={onGridReady}
          pagination={true}
          paginationPageSize={100}
          columnDefs={colDefs}
          rowSelection="multiple"
          rowHeight={200}
          onSelectionChanged={handleRowSelection}
          rowClass="auditlog-row"
        ></AgGridReact>
      </div>
      <div className="diff-modal">
        <Dialog
          fullWidth={true}
          style={{ width: "95%", margin: "auto" }}
          fullScreen={true}
          scroll={"paper"}
          open={isModalOpen}
          onClose={(reason: any) => {
            if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
              setIsModalOpen(false);
            }
          }}
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>Audit Log Diff</Box>
              <Box>
                <IconButton
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  <CloseTwoTone />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <div className="ag-theme-alpine" style={{ height: "100%" }}>
            <AgGridReact
              rowClassRules={rowClassRules}
              suppressDragLeaveHidesColumns={true}
              rowData={getRowDataForAuditLogs(firstValue, secondValue)}
              columnDefs={getColoumDefData(firstValue)}
            ></AgGridReact>
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default AuditLog;
