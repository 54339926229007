import React, { useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import makeStyles from '@mui/styles/makeStyles';
import dayjs, { Dayjs } from 'dayjs';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { NumericFormat } from 'react-number-format';
import CloseIcon from '@mui/icons-material/Close';
import { KagamiFilterStyle } from '../style/KagamiSlowApiLog';
import {
  filterSearch,
  filterSearchForEvent,
  dateAndTimeValidations,
  getMinDate
} from '../service/KagamiSlowApiLogService';
import { CommonUtils } from '../../../../../../utils/CommonUtils';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  openCloseFromDatePickerForFilter,
  openCloseToDatePickerForFilter
} from '../controller/KagamiSlowApiLogController';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles(KagamiFilterStyle);

export const KagamiSlowApiLogFilters = (props: any) => {
  let currentDate = new Date();
  const prev = new Date();
  prev.setDate(currentDate.getDate() - 30);
  const currentTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const currentTimeFormatted = dayjs(currentTime, 'HH:mm');
  const [fromDate, setFromDate] = React.useState<Date | null>(new Date(prev));
  const [fromTime, setFromTime] = React.useState<Dayjs | null>(currentTimeFormatted);
  const [toDate, setToDate] = React.useState<Date | null>(new Date(currentDate));
  const [toTime, setToTime] = React.useState<Dayjs | null>(currentTimeFormatted);
  const classes = useStyles();

  const handleEventWiseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === false) {
      props?.kagamiSlowApiLogModel?.state?.setEventWiseChange(event?.target?.checked);
      dateAndTimeValidations(fromDate, fromTime, toDate, toTime, props);
      filterSearch(props, fromDate, fromTime, toDate, toTime);
    } else {
      props?.kagamiSlowApiLogModel?.state?.setEventWiseChange(event?.target?.checked);
      dateAndTimeValidations(fromDate, fromTime, toDate, toTime, props);
      filterSearchForEvent(props, fromDate, fromTime, toDate, toTime);
    }
  };

  useEffect(() => {
    if (props?.kagamiSlowApiLogModel?.state?.eventWiseChange) {
      dateAndTimeValidations(fromDate, fromTime, toDate, toTime, props);
      filterSearchForEvent(props, fromDate, fromTime, toDate, toTime);
    }
  }, []);

  const handleSearchFilter = () => {
    if (props?.kagamiSlowApiLogModel?.state?.eventWiseChange === false) {
      dateAndTimeValidations(fromDate, fromTime, toDate, toTime, props);
      filterSearch(props, fromDate, fromTime, toDate, toTime);
    } else {
      dateAndTimeValidations(fromDate, fromTime, toDate, toTime, props);
      filterSearchForEvent(props, fromDate, fromTime, toDate, toTime);
    }
  };

  return (
    <Box sx={{ width: '100%', display: 'flex' }} className={classes.root}>
      <Grid style={{ display: 'flex' }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={3.5}>
          <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={1.2}>
              <Typography className={classes.labelName} style={{ marginLeft: '-10px' }}>
                From
              </Typography>
            </Grid>
            <Grid item xs={5.4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl fullWidth>
                  <div className={classes.datePicker}>
                    <DatePicker
                      PopperProps={{
                        className: 'customPopover'
                      }}
                      views={['year', 'month', 'day']}
                      value={fromDate}
                      inputFormat="dd/MM/yyyy"
                      minDate={getMinDate()}
                      maxDate={new Date()}
                      open={props?.kagamiSlowApiLogModel?.state?.isfromOpen}
                      onClose={() => openCloseFromDatePickerForFilter(props, false)}
                      renderInput={(params: any) => (
                        <div style={{ position: 'relative' }}>
                          <TextField
                            fullWidth
                            style={{ width: '90%' }}
                            size="small"
                            {...params}
                            onKeyDown={(event: any) => {
                              if (event.code !== 'Tab') {
                                event.preventDefault();
                              }
                            }}
                            color="primary"
                            onClick={() => openCloseFromDatePickerForFilter(props, true)}
                          />
                        </div>
                      )}
                      onChange={(newValue) => {
                        if (newValue) {
                          setFromDate(newValue);
                          props?.kagamiSlowApiLogModel?.state?.setErrors('');
                        }
                      }}
                    />
                  </div>
                </FormControl>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={5.4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl fullWidth>
                  <TimePicker
                    PopperProps={{
                      className: 'customPopover'
                    }}
                    open={props?.kagamiSlowApiLogModel?.state?.fromTimeOpen}
                    onOpen={() => props?.kagamiSlowApiLogModel?.state?.setFromTimeOpen(true)}
                    onClose={() => props?.kagamiSlowApiLogModel?.state?.setFromTimeOpen(false)}
                    value={fromTime}
                    views={['hours', 'minutes', 'seconds']}
                    ampmInClock
                    onChange={(newValue: any) => {
                      if (newValue) {
                        setFromTime(newValue);
                        props?.kagamiSlowApiLogModel?.state?.setErrors('');
                      }
                    }}
                    renderInput={(params: any) => (
                      <div style={{ position: 'relative' }}>
                        <TextField
                          fullWidth
                          style={{ width: '90%' }}
                          size="small"
                          {...params}
                          color="primary"
                          sx={{}}
                          InputProps={{
                            endAdornment: (
                              <AccessTimeIcon
                                sx={{
                                  color: (theme: any) => theme.palette.secondary.main
                                }}
                                onKeyDown={(event: any) => {
                                  if (event.code !== 'Tab') {
                                    event.preventDefault();
                                  }
                                }}
                                fontSize="medium"
                                color="action"
                              />
                            ),
                            readOnly: true
                          }}
                          onKeyDown={(event: any) => {
                            if (event.code !== 'Tab') {
                              event.preventDefault();
                            }
                          }}
                          onClick={() => props?.kagamiSlowApiLogModel?.state?.setFromTimeOpen(true)}
                        />
                        {CommonUtils.isNotEmpty(props?.kagamiSlowApiLogModel?.fromTimeOpen) ? (
                          <IconButton
                            size="small"
                            style={{
                              position: 'absolute',
                              top: '2px',
                              right: '10px'
                            }}
                            onClick={() => {
                              setFromTime(null);
                            }}
                          ></IconButton>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={1.2}>
              <Typography className={classes.labelName}>To</Typography>
            </Grid>
            <Grid item xs={5.4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl fullWidth>
                  <div className={classes.datePicker}>
                    <DatePicker
                      PopperProps={{
                        className: 'customPopover'
                      }}
                      open={props?.kagamiSlowApiLogModel?.state?.isOpen}
                      onClose={() => openCloseToDatePickerForFilter(props, false)}
                      views={['year', 'month', 'day']}
                      value={toDate}
                      inputFormat="dd/MM/yyyy"
                      minDate={getMinDate()}
                      maxDate={new Date()}
                      renderInput={(params: any) => (
                        <div style={{ position: 'relative' }}>
                          <TextField
                            sx={{}}
                            style={{ width: '90%' }}
                            fullWidth
                            size="small"
                            {...params}
                            onKeyDown={(event: any) => {
                              if (event.code !== 'Tab') {
                                event.preventDefault();
                              }
                            }}
                            color="primary"
                            onClick={() => openCloseToDatePickerForFilter(props, true)}
                          />
                        </div>
                      )}
                      onChange={(newValue) => {
                        if (newValue) {
                          setToDate(newValue);
                          props?.kagamiSlowApiLogModel?.state?.setErrors('');
                        }
                      }}
                    />
                  </div>
                </FormControl>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={5.4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl fullWidth>
                  <TimePicker
                    PopperProps={{
                      className: 'customPopover'
                    }}
                    open={props?.kagamiSlowApiLogModel?.state?.toTimeOpen}
                    onOpen={() => props?.kagamiSlowApiLogModel?.state?.setToTimeOpen(true)}
                    onClose={() => props?.kagamiSlowApiLogModel?.state?.setToTimeOpen(false)}
                    value={toTime}
                    ampmInClock
                    views={['hours', 'minutes', 'seconds']}
                    onChange={(newValue) => {
                      if (newValue) {
                        setToTime(newValue);
                        props?.kagamiSlowApiLogModel?.state?.setErrors('');
                      }
                    }}
                    renderInput={(params: any) => (
                      <div style={{ position: 'relative' }}>
                        <TextField
                          fullWidth
                          style={{ width: '90%' }}
                          size="small"
                          {...params}
                          color="primary"
                          sx={{}}
                          InputProps={{
                            endAdornment: (
                              <AccessTimeIcon
                                sx={{
                                  color: (theme: any) => theme.palette.secondary.main
                                }}
                                onKeyDown={(event: any) => {
                                  if (event.code !== 'Tab') {
                                    event.preventDefault();
                                  }
                                }}
                                fontSize="medium"
                                color="action"
                              />
                            ),
                            readOnly: true
                          }}
                          onKeyDown={(event: any) => {
                            if (event.code !== 'Tab') {
                              event.preventDefault();
                            }
                          }}
                          onClick={() => props?.kagamiSlowApiLogModel?.state?.setToTimeOpen(true)}
                        />
                        {CommonUtils.isNotEmpty(props?.kagamiSlowApiLogModel?.toTimeOpen) ? (
                          <IconButton
                            size="small"
                            style={{
                              position: 'absolute',
                              top: '2px',
                              right: '10px'
                            }}
                            onClick={() => {
                              setToTime(null);
                            }}
                          ></IconButton>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Typography fontSize={'12px'} color={'#f05252'}>
            {props?.kagamiSlowApiLogModel?.state?.errors ? props?.kagamiSlowApiLogModel?.state?.errors : ''}
          </Typography>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem style={{ height: '87px' }} />
        <Grid item xs={2.7}>
          <FormControl fullWidth>
            <FormLabel component="span" style={{ color: 'rgb(56 73 93)', marginTop: '-23px' }}>
              Turn Around Time
            </FormLabel>
            {props?.kagamiSlowApiLogModel?.state?.radioValue != 'notCompleted' ? (
              <NumericFormat
                fullWidth
                sx={{}}
                customInput={TextField}
                allowNegative={false}
                autoSave="off"
                autoComplete="off"
                value={props?.kagamiSlowApiLogModel?.state?.turnAroundTime}
                onChange={(event: any) => {
                  props?.kagamiSlowApiLogModel?.setTurnAroundTime(event.target.value);
                }}
                variant="outlined"
              />
            ) : (
              <NumericFormat
                disabled
                fullWidth
                sx={{}}
                customInput={TextField}
                allowNegative={false}
                autoSave="off"
                autoComplete="off"
                value={props?.kagamiSlowApiLogModel?.turnAroundTime}
                variant="outlined"
              />
            )}
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              value={props?.kagamiSlowApiLogModel?.state?.radioValue}
              onChange={(e, value) => props?.kagamiSlowApiLogModel?.state?.setRadioValue(value)}
            >
              <FormControlLabel value="milliSec" control={<Radio />} label="MSec" />
              <FormControlLabel value="sec" control={<Radio />} label="Sec" />
              <FormControlLabel value="notCompleted" control={<Radio />} label="NA" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem style={{ height: '87px', paddingLeft: '20px' }} />
        <Grid item xs={3} style={{ width: '125%', marginLeft: '-16px' }}>
          <FormControl fullWidth style={{ paddingBottom: '15px' }}>
            <div style={{ display: 'flex', width: '125%', gap: '8px' }}>
              <p style={{ width: '15%', textAlign: 'right', color: 'hsl(0deg 0% 43.92%)' }}>User</p>
              <TextField
                style={{ width: '120%' }}
                sx={{}}
                fullWidth
                size="small"
                autoSave="off"
                value={props?.kagamiSlowApiLogModel?.state?.user}
                onChange={(e) => {
                  props?.kagamiSlowApiLogModel?.state?.setUser(e.target.value);
                }}
              />
              {CommonUtils.isNotEmpty(props?.kagamiSlowApiLogModel?.state?.user) ? (
                <CloseIcon
                  className={classes.closeButtonForUser}
                  onClick={() => props?.kagamiSlowApiLogModel?.state?.setUser('')}
                />
              ) : (
                ''
              )}
            </div>
          </FormControl>
          <FormControl fullWidth>
            <div style={{ display: 'flex', marginTop: '-8px', width: '125%', gap: '8px' }}>
              <p style={{ width: '15%', textAlign: 'right', color: 'hsl(0deg 0% 43.92%)' }}>Event </p>
              <TextField
                style={{ width: '125%' }}
                sx={{}}
                fullWidth
                size="small"
                autoSave="off"
                value={props?.kagamiSlowApiLogModel?.state?.activeEvent}
                onChange={(e) => {
                  props?.kagamiSlowApiLogModel?.state?.setActiveEvent(e.target.value);
                }}
              />
              {CommonUtils.isNotEmpty(props?.kagamiSlowApiLogModel?.state?.activeEvent) ? (
                <CloseIcon
                  className={classes.closeButtonForEvent}
                  onClick={() => props?.kagamiSlowApiLogModel?.state?.setActiveEvent('')}
                />
              ) : (
                ''
              )}
            </div>
          </FormControl>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem style={{ height: '87px', paddingLeft: '90px' }} />
        <FormGroup style={{ marginLeft: '15px' }}>
          <FormControlLabel
            label="Group By Event"
            control={
              <Checkbox
                checked={props?.kagamiSlowApiLogModel?.state?.eventWiseChange}
                onChange={handleEventWiseChange}
              />
            }
          />
        </FormGroup>
        <Button
          variant="contained"
          onClick={() => handleSearchFilter()}
          style={{ marginLeft: '-120px', marginTop: '55px', marginBottom: '33px' }}
        >
          Search
        </Button>
      </Grid>
    </Box>
  );
};
