import React, { useCallback, useState } from 'react';
import { SelectFieldProps, useKagamiSelectModelState } from '../model/kagamiSelectModel';
import { Autocomplete, FormLabel, Stack, TextField, FormControl } from '@mui/material';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiMultiSelectFieldStyle } from '../styles/KagamiSelectFieldStyle';
import { getFormModel } from '../../../form/controller/KagamiFormController';
import { extractDropdownOption } from '../../../../service/PresentationService';
import { 
  buildMultiSelectMoreContent,
  getMultiSelectReadOnlyTitle,
  onSearchCall,
  validateSelectTextBox
} from '../controller/kagamiSelectController';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import { KagamiModalModel } from '../../../../../../../../../../../components/modal/model/KagamiModalModel';
import { buildModal } from '../../../../../../../../../../../components/modal/controller/KagamiModalController';
import { fetchEmbedRecordsForAgGrid } from '../../../list/service/kagamiListService';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

const useStyles = makeStyles(KagamiMultiSelectFieldStyle);

function MultiselectField(props: SelectFieldProps) {
  const kagamiSelectModel = props.kagamiSelectModel;
  const textInputRef: any = React.useRef(null);
  const classes = useStyles();
  // const forceUpdate = useCallback(() => useKagamiSelectModelState(props.kagamiSelectModel.createState), []);
  let fieldState = useKagamiSelectModelState(props.kagamiSelectModel.createState);
  let popupModel: KagamiModalModel = new KagamiModalModel();
  popupModel.title = getMultiSelectReadOnlyTitle(props?.kagamiSelectModel);
  popupModel.content = buildMultiSelectMoreContent(fieldState, props.kagamiSelectModel, popupModel);
  popupModel.modalWidth = 'sm';
  
  popupModel.isVendorListButtonDisable = true;
  const uiSettingsClassName = props.kagamiSelectModel.getControlWidth(props.kagamiSelectModel.uiSettings);
  const fontProperties = props.kagamiSelectModel.getFontStyling(props.kagamiSelectModel.uiSettings);
  
  // const CssSelectField = styled(TextField)({
  //   '& .MuiInputLabel-root ': {
  //     color:fontProperties.labelFontColor,
  //     fontWeight: fontProperties.labelFontBold ,
  //     fontStyle: fontProperties.labelFontItalic,
  //     textDecoration: fontProperties.labelFontUnderline,
  //   },
  //   '& .MuiInputLabel-root.Mui-focused ': {
  //     color:fontProperties?.labelFontColor,  
  //     fontWeight: fontProperties?.labelFontBold ,
  //     fontStyle: fontProperties?.labelFontItalic ,
  //     textDecoration: fontProperties?.labelFontUnderline 
  //   },
  // });
  
  const debounceCallForOnSearch = React.useCallback(CommonUtils._debounceFtn(onChange, 500), []);
  const debounceOnSelection = React.useCallback(CommonUtils._debounceFtn(selectionOnChange,400),[]);
  function onChange(searchString: string) {
      onSearchCall(props.kagamiSelectModel, searchString);
  }

  function selectionOnChange(records : any){
    validateSelectTextBox(props.kagamiSelectModel, records);
  }

  props.kagamiSelectModel.state = fieldState;
  getFormModel().formControlStates[props.kagamiSelectModel.controlIdentifier] = fieldState;
  let lovRecords = CommonUtils.isNotEmpty(props.kagamiSelectModel.lovRecords)
    ? props.kagamiSelectModel.lovRecords
    : CommonUtils.isNotEmpty(props.kagamiSelectModel.associatedObject)
    ? [props.kagamiSelectModel.associatedObject]
    : [];

  const moreChipHandler = () => {
    let proxyToNormalArray = JSON.parse(JSON.stringify(props.kagamiSelectModel.selectValues))
    fieldState.setMultiSelectSelectedValues(proxyToNormalArray)
    // popupModel.content = buildVendorChip(fieldState, props.kagamiSelectModel, popupModel);
    // popupModel.modalWidth = 'sm';
    popupModel.state.setIsNonEditableList(false);
    popupModel.state.setOpen(true);
  };

  const readmoreHandler = () => {
    if(getFormModel().isSingleListView) {  
      popupModel.modalWidth = 'lg';
      let formModel  = getFormModel();
      fetchEmbedRecordsForAgGrid(props.kagamiSelectModel.attributePresentation,formModel.inFocusFormDataIdentifiers[props.kagamiSelectModel.entityId], popupModel,formModel.mainEntityId,null,formModel.formData.formData);
       
    } else {
      fieldState.setMultiSelectSelectedValues(props.kagamiSelectModel.selectValues)
      popupModel.modalWidth = 'sm';
      popupModel.content = buildMultiSelectMoreContent(fieldState, props.kagamiSelectModel, popupModel);
      popupModel.state.setIsNonEditableList(true);
      popupModel.state.setOpen(true);

    }
   

  };

  const MyChip = (props: any) => {
    return (<Chip 
    {...props}  
    deleteIcon={<CloseIcon 
      sx={{
      color:(theme) => theme.palette.error.main
    }} />}
      />);
  };

  return (
    <div className={ CommonUtils.isEmpty(fieldState.selectValues) ? `${uiSettingsClassName} ${classes.root1}` : `${uiSettingsClassName} ${classes.root}` }>
      {/* {{CommonUtils.isNotEmpty(fieldState.inputValue)} ? <div className={`${uiSettingsClassName} ${classes.root}`} ></div>: null} */}
      {buildModal(popupModel)}
      {fieldState.isReadOnly === false ? (
        <>
        <FormControl fullWidth>
        <FormLabel component="span"  
            required={fieldState.isMandatory}  
            style={{
              color:fontProperties.labelFontColor,
              fontWeight: fontProperties.labelFontBold ,
              fontStyle: fontProperties.labelFontItalic,
              textDecoration: fontProperties.labelFontUnderline,
             }}>{props.kagamiSelectModel.controlDisplayName}</FormLabel> 
        <Autocomplete
        /// Note : Commented free solo because in case of no lov records we are not getting no options message
        // freeSolo={CommonUtils.isNotEmpty(props.kagamiSelectModel.lovRecords)}
        forcePopupIcon= {true}
        noOptionsText = 'No data found'
        inputValue={fieldState.inputValue}
        classes={{
          popper: `customPopover ${classes.kgmSearchOptions}`,
          
        }}
        fullWidth
          size="small"
          multiple 
          options={props.kagamiSelectModel.lovRecords || []}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => {
            return value === '' ? true : (option && value && option.id === value.id) || false;
          }}
          value={
            fieldState.selectValues == null || fieldState.selectValues.length === 0
              ? []
              : props.kagamiSelectModel.selectValues
          }
          getOptionLabel={(lovRecord: any) =>
            extractDropdownOption(props.kagamiSelectModel.attributePresentation, lovRecord)
          }
          renderTags={(tagValue, getTagProps) => {
            tagValue = fieldState.selectValues?.filter((item:any) => !item?.id?.includes("remove"))
            return tagValue.map((option, index) => {
              if (index < 2) {
                return (
                  <MyChip
                    {...getTagProps({ index })}
                    label={extractDropdownOption(props.kagamiSelectModel.attributePresentation, option)}
                  />
                );
              }
              if (tagValue.length > 2 && tagValue.length - 1 === index) {
                return (
                  <Chip
                    label={`+${tagValue.length - 2} more`}
                    style={{ marginLeft: '10px', cursor: 'pointer' , color: fontProperties.controlFontColor}}
                    onClick={moreChipHandler}
                  />
                );
              }
            });
          }}
          disabled={fieldState.isReadOnly}
          disableCloseOnSelect
          renderInput={(params) => (
            <TextField
            sx= {{
              '& .MuiInputLabel-root ': {
                color:fontProperties.labelFontColor,
                fontWeight: fontProperties.labelFontBold ,
                fontStyle: fontProperties.labelFontItalic,
                textDecoration: fontProperties.labelFontUnderline,
              },
              '& .MuiInputLabel-root.Mui-focused ': {
                color:fontProperties?.labelFontColor,  
                fontWeight: fontProperties?.labelFontBold ,
                fontStyle: fontProperties?.labelFontItalic ,
                textDecoration: fontProperties?.labelFontUnderline 
          
              },
              '& .MuiOutlinedInput-root': { 
                backgroundColor: fontProperties.controlBackgroundColor ,
                color: fontProperties.controlFontColor +' ' + '!important',
                fontWeight: fontProperties.controlFontBold,
                fontStyle: fontProperties.controlFontItalic,
                textDecoration: fontProperties.controlFontUnderline,
              }
            }}
              key={props.kagamiSelectModel.controlIdentifier}
              {...params} color= "primary"
              variant="outlined"
              inputRef={textInputRef}
              required ={fieldState.isMandatory}
              // label={props.kagamiSelectModel.controlDisplayName}
              onChange={(e) => {
                fieldState.setInputValue(e.target.value)
                debounceCallForOnSearch(e.target.value)}}
              error = {fieldState.errorMessage ? true : false}
              helperText={fieldState.errorMessage ? fieldState.errorMessage : null}
            />
          )}
         
          onChange={(event, changedValue, reason) => {
            let currentSelectedValues: any[] = [];
            for (var selectedValue of changedValue) {
              currentSelectedValues.push(selectedValue);
            }
            props.kagamiSelectModel.associatedObject = currentSelectedValues;
            fieldState.setSelectValues(currentSelectedValues);
            let values: any = changedValue;
            // let values:any[] = changedValue;
            if (props.kagamiSelectModel.controlValue?.length !== 0 && reason === 'removeOption') {
              let previousValues = props.kagamiSelectModel.controlValue.map((item: any) => item.id);
              let selectedValues = values.map((item: any) => item.id);
              for (var itemId of previousValues) {
                if (!selectedValues.includes(itemId)) {
                  values.push({ id: 'removed_' + itemId });
                }
              }
            } else if (reason === 'clear') {
              let previousValues = props.kagamiSelectModel.controlValue.map((item: any) => item.id);
              for (var itemId of previousValues) {
                values.push({ id: 'removed_' + itemId });
              }
            }
            fieldState.setControlValue(values);
            debounceOnSelection(values);
            // validateSelectTextBox(props.kagamiSelectModel, values);
            // if(CommonUtils.isNotEmpty(props.kagamiSelectModel.inputValue)) {
            //   onChange('')
            //   fieldState.setInputValue('')
            // }
          }}

          renderOption={(props, option, { inputValue }) => {
            const optionTitle= extractDropdownOption(kagamiSelectModel.attributePresentation, option, true);
            const matches = match(optionTitle, inputValue, { findAllOccurrences: true });
            const parts = parse(optionTitle, matches);

            return (
              <li {...props}>
                <div>
                  {parts.map((part:any, index:any) => (
                    <span
                      key={index}
                      style={{
                        fontSize: '13px',
                        fontWeight: part.highlight ? 700 : 400,
                        color: part.highlight ? '#29947f' : '',
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
        </FormControl>
        </>
      ) : (
        <div className={classes.readOnlyField_cont}>
          <FormLabel component="span" 
          className={classes.controlLabel}  
          required={fieldState.isMandatory}
          style={{ color:fontProperties.labelFontColor }} 
          >
            {' '}
            {props.kagamiSelectModel.controlDisplayName}
          </FormLabel>
          {CommonUtils.isEmpty(fieldState.selectValues) ? (
            <p className={classes.readOnlyEmpty}>----</p>
          ) : (
            <>
              <Stack spacing={1}>
                {fieldState.selectValues.map((item: any, index: any) => {
                  if (index < 2) {
                    return (
                      <>
                        <Chip
                          size="small"
                          style={{ color: fontProperties.controlFontColor  }}
                          className={classes.chipStyle}
                          label={extractDropdownOption(props.kagamiSelectModel.attributePresentation, item)}
                          onClick={readmoreHandler}
                        />
                      </>
                    );
                  }
                })}
                {fieldState.selectValues.length > 2 ? (
                  <div style={{ color: '#007ecc' , cursor: 'pointer'}} onClick={readmoreHandler}>
                    + {props.kagamiSelectModel.selectValues.length - 2} more
                  </div>
                ) : null}
              </Stack>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default MultiselectField;
