import { title } from './../../../../../../../../../../application1/style/KagamiApplicationStyles';

import forEach from 'lodash/forEach';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { ReportFilterModel } from '../../../filter/model/KagamiReportFilterModel';
import { executeReportWithOrWithoutFilter } from '../builder/KagamiReportBuilder';
import { setDefaultSelectedValue } from '../controller/KagamiReportController';
import { KagamiReportModel } from '../model/KagamiReportModel';
import { getReportConfig } from './KagamiReportService';
import _ from 'lodash';
import { getLandingPageModel } from '../../../landingPage/controller/ReportsLandingPageController';
import { buildReportsPageComponent } from '../../../landingPage/builder/ReportsPage/ui/ReportsPage';

export const handleDrillDownReport = (event:any,pieReportController: any,reportConfig:any,selectedColValue:any,selectedColName:any) => {
    let drillDownAppliedOnColumn = '';
    let drillDOwnReportName = '';
    let drillDownReportId = '';
    let selectedCol:any ={}
    forEach(reportConfig?.criteria?.columns, function (col) {
        if (col.columnReportName != null &&  col.name == selectedColName) {          
            drillDownAppliedOnColumn = col.name;
            drillDOwnReportName = col.columnReportName;
            drillDownReportId = col.columnReport;
            selectedCol = col;
        }
    });
    let tempObj:any = {}
    tempObj[drillDownAppliedOnColumn] = selectedColValue;

    // let tempArr = [{drillDownAppliedOnColumn : selectedColValue}];
    // let queryParam = JSON.stringify(tempArr);
    if(reportConfig?.criteria?.columns){
      forEach(reportConfig?.criteria?.columns, function (filter) {
        if(filter.name != drillDownAppliedOnColumn && pieReportController?.reportFilterModel?.selectedValue?.get(filter.name)  ) {
          tempObj[filter.name] =  pieReportController?.reportFilterModel?.selectedValue?.get(filter.name);
        }
      });
    }
    //handle link with row data (mulutple columns data neeed to send to backend)
    if (selectedCol?.otherProperties?.linkedReportMapping?.length) {
      let columnMappingInfo = selectedCol.otherProperties.linkedReportMapping;
      let hadChildMapping = _.filter(columnMappingInfo, function (col) {
        return col.childCol != '';
      })
      if (hadChildMapping && hadChildMapping.length) {
        let pickFilterData = event.data ?? _.cloneDeep(event.data);
        if(pickFilterData) {
          for(let $item of hadChildMapping){
            if(!pickFilterData[$item['parentCol']] && pieReportController?.reportFilterModel?.selectedValue?.get($item['parentCol'])){
              // hiden col handled here
              pickFilterData[$item['parentCol']] = pieReportController?.reportFilterModel?.selectedValue?.get($item['parentCol']);
            }
          }
        }
        let queryParamObj = handleLinkedReportQueryParamsByColumn(columnMappingInfo,pickFilterData || selectedColValue || ''); //selectedColValue for graphial reports like pie row data is not fetched so used selected value
        tempObj = queryParamObj;
      }
    }//end 

    
    getDrillDownReportData(drillDownReportId,pieReportController,tempObj);
}

export async function getDrillDownReportData(reportId: any, pieReportController: any, selectedColValue: any) {
  let response: any = await getReportConfig(reportId);
  let reportConfig = response?.data?.respObject;
  if (reportConfig != undefined && reportConfig['reportConfigSummary'] != undefined) {
    let reportModel = new KagamiReportModel();
    reportModel.reportConfig = reportConfig;
    reportModel.reportConfigSummary = reportConfig['reportConfigSummary'];
    if(!selectedColValue?.isAddionalReport){
      let filters = setSelectedFilterValue(reportConfig.dynamicParameterList, selectedColValue);
      reportModel.filter = filters;
      let reportFilterModel = new ReportFilterModel(filters, {}, reportId);
      reportFilterModel.filterSetting = reportConfig?.reportSettings?.filterSettings ?? {};
      setDefaultSelectedValue(reportFilterModel);
      // reportFilterModel.filters = filters;
      reportModel.reportFilterModel = reportFilterModel;
      await executeReportWithOrWithoutFilter(reportModel, true).then((content: any) => {
        // when modal need to give for all type of report then use common ReporModalModel
        if (
          !pieReportController.fromDashboard &&
          pieReportController.reportConfig?.reportSettings?.drilldown?.shownInModal
        ) {
          pieReportController.reportModalModel.title = "";
          // pieReportController.reportModalModel.content = content;
          pieReportController.reportModalModel.content = buildReportsPageComponent(content,false);
          pieReportController.reportModalModel.modalWidth = 'xl';
          pieReportController.reportModalModel.state.setOpen(true);


          // const landingPageModel = getLandingPageModel();
          // landingPageModel.modalModel.content = content;
          // landingPageModel.modalModel.modalWidth = 'xl';
          // landingPageModel.modalModel.handleOnOk = landingPageModel.modalModel.handleOnClose;
          // landingPageModel.modalModel.state.setOpen(true);
        } else {
          pieReportController.contentReport = content;
          pieReportController.state?.setContentReport(true);
        }
      });
    }else{
      let filters = setMultipleSelectedFilter(reportConfig.dynamicParameterList, pieReportController.reportFilterModel.selectedValue); // change required here
      reportModel.filter = filters;
      let reportFilterModel = new ReportFilterModel(filters, {}, reportId);
      reportFilterModel.filterSetting = reportConfig?.reportSettings?.filterSettings ?? {};
      setDefaultSelectedValue(reportFilterModel);
      reportModel.reportFilterModel = reportFilterModel;
      await executeReportWithOrWithoutFilter(reportModel, true).then((content: any) => {
        // when modal need to give for all type of report then use common ReporModalModel
        if (
          !pieReportController.fromDashboard &&
          pieReportController.reportConfig?.reportSettings?.drilldown?.shownInModal
        ) {
          // pieReportController.reportModalModel.content = content;
          // pieReportController.reportModalModel.maxWidth = 'xl';
          // pieReportController.reportModalModel.state.setOpen(true);

          // const landingPageModel = getLandingPageModel();
          // landingPageModel.modalModel.content = content;
          // landingPageModel.modalModel.modalWidth = 'xl';
          // landingPageModel.modalModel.state.setOpen(true);
        } else {
          pieReportController.contentReport = content;
          pieReportController.state?.setContentReport(true);
        }
      });
    }
  }
}

function setSelectedFilterValue(filters:any, selectedColValue:any) {
    return filters?.map((filterConfig:any)=>{   
      filterConfig['value'] = CommonUtils.isNotEmpty(selectedColValue[filterConfig['name']])
          ? selectedColValue[filterConfig['name']]
          : filterConfig['value'];
        return filterConfig;
    })
}

function setMultipleSelectedFilter(filters:any, selectedValue:any) {
  return filters?.map((filterConfig:any)=>{
    filterConfig['value'] = (selectedValue && CommonUtils.isNotEmpty(selectedValue.get(filterConfig['name'])))
      ? selectedValue.get(filterConfig['name'])
      : filterConfig['value'];
    return filterConfig;
})
}

export const handleLinkedReportQueryParamsByColumn = function (columnMappingInfo: any, parentCurrentRow: any) {
  let queryParamObj: any = {}
  _.forEach(columnMappingInfo, function (mapObj) {
    if (mapObj.childCol && typeof(parentCurrentRow) != "string") {
      queryParamObj[mapObj.childCol] = parentCurrentRow ? (parentCurrentRow[mapObj.parentCol] || 'NOPOP') : 'NOPOP';
    }else if (mapObj.childCol && typeof(parentCurrentRow) == "string") {
      queryParamObj[mapObj.childCol] = parentCurrentRow  || 'NOPOP';
    }
  })
  return queryParamObj;
}