import { createState } from '@hookstate/core';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { useCallback } from 'react';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { APIService } from '../../../../../../../service/ApiService';
import { saveUserFavourites } from '../../../../../../../service/URLService';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { KagamiDashboardModel } from '../../../../body/builder/dashboard/model/KagamiDashboardModel';
import { getDashboardModel } from '../../../../body/builder/dashboard/ui/KagamiDashboard';
import { isEnableTabs, translateToLocalistaion } from '../../../../body/builder/process/service/PresentationService';
import { getUserProfileData } from '../../../../header/controller/KagamiPageHeaderController';
import { executeProcess, setCurrentProcessName } from '../controller/KagamiMenuController';
import { KagamiFolderModel, KagamiFolderModelProps, useFolderState } from '../model/KagamiFolderModel';
import { KagamiMenuModel, KagamiMenuModelProps, useMenuState } from '../model/KagamiMenuModel';
import { usePanelState } from '../model/KagamiPanelState';
import { isFolder, isMenu } from '../service/KagamiMenuService';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiNavigatorStyle } from '../../../styles/KagamiNavigator';
import { KagamiStateManager } from '../../../../../../../../state/KagamiStateManager';
import { tabExistAndContentFromState } from '../../../../header/Tabs/ui/KagamiTabNavigation';
import { KagamiTabNavigationModel } from '../../../../header/Tabs/model/KagamiTabNavigationModel';
import { appContext } from '../../../../body/builder/process/service/PolicyExecutionService';
import { getBreadcrumbsModel, resetBreadcrumbs } from '../../../../body/builder/process/builder/breadcrumbs/controller/kagamiBreadcrumbsController';

const useStyles = makeStyles(KagamiNavigatorStyle);

export function buildMainMenu(
  mainMenus: KagamiFolderModel[],
  favouriteData: any,
  navigationData: any,
  tabNavigtaionModel: KagamiTabNavigationModel
) {
  return (
    <KagamiNavigatorMenu
      items={mainMenus}
      favouriteData={favouriteData}
      navigationData={navigationData}
      tabNavigtaionModel={tabNavigtaionModel}
    />
  );
}

function KagamiNavigatorMenu(props: any) {
  const { items, favouriteData, navigationData, tabNavigationModel } = props;
  let translations = navigationData?.otherProperties?.translation;

  return (
    <>
      {items.map((item: any, index: any) =>
        isFolder(item) ? (
          <KagamiFolderMenu
            key={index}
            item={item}
            favouriteData={favouriteData}
            tabNavigtaionModel={tabNavigationModel}
            translations={translations}
          />
        ) : (
          buildMenu(item, favouriteData, [], tabNavigationModel, translations)
        )
      )}
    </>
  );
}

const KagamiFolderMenu = (props: any) => {
  const { item, favouriteData, tabNavigationModel, translations } = props;
  const parents = [item.name];
  const panelState = usePanelState();
  const activeItemParents = panelState.activeItemParents;
  const isActiveMenu = activeItemParents.includes(item.name);

  const kagamiFolderModel: KagamiFolderModel = new KagamiFolderModel(
    item.build,
    item.favourite,
    item.img,
    item.name,
    item.subMenus,
    item.type,
    isActiveMenu,
    []
  );
  kagamiFolderModel.state = useFolderState(createState(kagamiFolderModel));

  let searchProcessName = kagamiFolderModel.state.searchProcessName;
  const currentUser = getUserProfileData();
  kagamiFolderModel.activeSubFolderLevel = 0;

  const regExpression = (string: any) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const searchingString = (e:any) => {
    const value = e.target.value;
    return value;
  };

  const handleSearchChange = useCallback((e: any) => {
    const searchValue = searchingString(e);
    kagamiFolderModel.state.SetSearchProcessName(searchValue);
  }, [kagamiFolderModel.state]);

  const handleOnOpenChange = useCallback((open: any, kagamiFolderModel: any) => {
    if (
      panelState.activeFolder &&
      panelState.activeFolder?.state &&
      panelState.activeFolder instanceof KagamiFolderModel
    ) {
      if (panelState.activeSubFolder && panelState.activeSubFolder?.state) {
        panelState.activeSubFolder?.state.SetIsActiveFolder(false);
      }
      panelState.activeFolder?.state.SetIsActiveFolder(false);
    }
    panelState.activeFolder !== null && panelState.activeFolder.name !== kagamiFolderModel.name
      ? kagamiFolderModel.state.SetIsActiveFolder(true)
      : kagamiFolderModel.state.SetIsActiveFolder(open);
    panelState.SetActiveFolder(kagamiFolderModel);
  }, []);

  return (
    <>
      <Menu iconShape="square" key={kagamiFolderModel.name.replace(/\s+/g, '') + '_1'}>
        <SubMenu
          placeholder=""
          open={kagamiFolderModel.state.isActiveFolder}
          className={isActiveMenu ? 'activeSubMenu' : ''}
          onOpenChange={(open: boolean) => {
            handleOnOpenChange(open, kagamiFolderModel);
          }}
          key={item.name.replace(/\s+/g, '') + '_2'}
          icon={
            <CustomImageComponent
              isMainFolder={true}
              image={kagamiFolderModel.img}
              className={isActiveMenu ? 'activeMainMenu' : ''}
            />
          }
          title={translateToLocalistaion(translations, kagamiFolderModel.name)}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <>
            {kagamiFolderModel.name === 'Master Data' && CommonUtils.isNotEmpty(kagamiFolderModel.subMenus) ? (
              <>
                <div key={'masterData_textfilter'} className="textBox_masterData">
                  <TextField
                    placeholder="Search..."
                    size="small"
                    value={searchProcessName}
                    variant="outlined"
                    style={{ background: '#fff', marginRight: '6px' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon></SearchIcon>
                        </InputAdornment>
                      )
                    }}
                    onChange={handleSearchChange}
                  />
                </div>
                {
                  kagamiFolderModel.subMenus
                    .filter((item) => item.name.match(new RegExp(regExpression(searchProcessName), 'i')))
                    .map((item) => isMenu(item) && buildMenu(item, favouriteData, parents, tabNavigationModel, translations))}
                </>
            ) : (
              <>
                {
                  kagamiFolderModel.subMenus &&
                    kagamiFolderModel.subMenus.map((item: KagamiFolderModel) =>
                      isFolder(item)
                        ? buildSubMenu(
                            item,
                            favouriteData,
                            [...parents, item.name],
                            kagamiFolderModel,
                            tabNavigationModel,
                            translations
                          )
                        : buildMenu(item, favouriteData, parents, tabNavigationModel, translations)
                    )
                  // getMenuItems(kagamiFolderModel.subMenus)
                }
              </>
            )}
          </>
        </SubMenu>
      </Menu>
    </>
  );
};

// KagamiFolderMenu.whyDidYouRender = true;

const buildSubMenu = (
  item: KagamiFolderModel,
  favouriteData: any,
  parents: string[],
  parentFolder: KagamiFolderModel,
  tabNavigationModel: KagamiTabNavigationModel,
  translations: any
) => {
  const panelState = usePanelState();
  const activeItemParents = panelState.activeItemParents;
  const isActiveMenu = activeItemParents.includes(item.name);
  const parentLevel = parentFolder.activeSubFolderLevel;
  const kagamiFolderModelSubMenu: KagamiFolderModel = new KagamiFolderModel(
    item.build,
    item.favourite,
    item.img,
    item.name,
    item.subMenus,
    item.type,
    isActiveMenu,
    parents
  );
  // console.log(kagamiFolderModelSubMenu);
  kagamiFolderModelSubMenu.activeSubFolderLevel = parentLevel + 1;
  return (
    <KagamiSubMenuFolder
      key={'submenu_' + item.name}
      tabNavigationModel={tabNavigationModel}
      kagamiFolderModel={kagamiFolderModelSubMenu}
      favouriteData={favouriteData}
      isActiveMenu={isActiveMenu}
      translations={translations}
    />
  );
};

const KagamiSubMenuFolder = React.memo((props: KagamiFolderModelProps) => {
  const { kagamiFolderModel, favouriteData, isActiveMenu, tabNavigationModel, translations } = props;
  const { parents } = kagamiFolderModel;
  const panelState = usePanelState();
  kagamiFolderModel.state = useFolderState(createState(kagamiFolderModel));

  // function handleOpenClose(value: boolean) {
  //   kagamiFolderModel.state.SetIsActiveFolder(value);
  // }
  const handleOpenClose = useCallback((open: any, kagamiFolderModel: any) => {
    if (panelState.activeSubFolder && panelState.activeSubFolder?.state) {
      kagamiFolderModel.activeSubFolderLevel === panelState.activeSubFolder.activeSubFolderLevel &&
        panelState.activeSubFolder?.state.SetIsActiveFolder(false);
    }
    panelState.activeSubFolder !== null && panelState.activeSubFolder.name !== kagamiFolderModel.name
      ? kagamiFolderModel.state.SetIsActiveFolder(true)
      : kagamiFolderModel.state.SetIsActiveFolder(open);
    panelState.SetActiveSubFolder(kagamiFolderModel);
  }, []);

  return (
    <>
      <SubMenu
        placeholder=""
        open={kagamiFolderModel.state.isActiveFolder}
        className={isActiveMenu ? 'activeSubMenu' : ''}
        onOpenChange={(open: boolean) => {
          handleOpenClose(open, kagamiFolderModel);
        }}
        icon={<CustomImageComponent image={kagamiFolderModel.img} className={isActiveMenu ? 'activeMainMenu' : ''} />}
        title={translateToLocalistaion(translations, kagamiFolderModel.name)}
        key={kagamiFolderModel.name.replace(/\s+/g, '')}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        {CommonUtils.isNotEmpty(kagamiFolderModel.subMenus) &&
          kagamiFolderModel.subMenus.map((item: any) =>
            isFolder(item)
              ? buildSubMenu(
                  item,
                  favouriteData,
                  [...parents, item.name],
                  kagamiFolderModel,
                  tabNavigationModel,
                  translations
                )
              : buildMenu(item, favouriteData, parents, tabNavigationModel, translations)
          )}
      </SubMenu>
    </>
  );
});

// KagamiSubMenuFolder.whyDidYouRender = true;

function buildMenu(
  item: any,
  favouriteData: any,
  parents: string[],
  tabNavigationModel: KagamiTabNavigationModel,
  translations: any
) {
  let isFavItem = false;

  favouriteData.forEach((favItem: any) => {
    if (favItem.processId === item.event.process) {
      isFavItem = true;
    }
  });
  const kagamiMenu: KagamiMenuModel = new KagamiMenuModel(
    item.build,
    isFavItem || item.favourite,
    item.event,
    item.groupName,
    item.name,
    item.type,
    parents
  );

  return (
    // <Menu>
    //     <MenuItem title={translateToLocalistaion(translations, item.name)}>
    <KagamiMenu key={'menuitem_' + item.event.process} kagamiMenuModel={kagamiMenu} translations={translations} />
    //     </MenuItem>
    // </Menu>
  );
}

const KagamiMenu = React.memo((props: KagamiMenuModelProps) => {
  const classes = useStyles();

  const panelState = usePanelState();
  const { kagamiMenuModel, translations } = props;
  const { parents } = kagamiMenuModel;
  let activeItem = panelState.activeItem;

  const setActiveItem = (processName: string) => {
    panelState.SetActiveItem(processName);
    panelState.SetActiveItemParents(parents);
  };

  let dashboardModel: KagamiDashboardModel = getDashboardModel();
  kagamiMenuModel.state = useMenuState(createState(kagamiMenuModel));

  const addTab = (process: any) => {
    let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
    let tabs = JSON.parse(JSON.stringify(tabNavigationState?.getTabs));
    tabNavigationState?.setTabs(tabs);
    if (process.name === 'Reports') {
      tabNavigationState?.setActiveTab(process?.name);
    } else {
      tabNavigationState?.setActiveTab(process?.name);
    }
  };

  const handleTab = (processName: string, name: string) => {
    let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
    let tabs = JSON.parse(JSON.stringify(tabNavigationState.getTabs));
    let isTabAlreadyExist = tabs?.findIndex((item: any) => item?.processName === processName);
    if (isTabAlreadyExist !== -1) {
      tabExistAndContentFromState(processName, name);
    } else {
      tabs?.push({ processName, name });
      tabNavigationState.setTabs(tabs);
      if (tabs?.length <= 10) {
        tabNavigationState.setActiveTab(processName);
        executeProcess(processName);
      }
    }
  };

  const handleMenuItemClick = React.useCallback((processName: string, name: string) => {
    let newName = translateToLocalistaion(translations, name);
    if(isEnableTabs(appContext)) {
      handleTab(processName, newName);
    } else {
      executeProcess(processName);
    }
    
    const breadcrumbsModel = getBreadcrumbsModel();
    resetBreadcrumbs(breadcrumbsModel);
    setActiveItem(processName);
    kagamiMenuModel.state.SetIsActiveMenu(true);
    setCurrentProcessName(processName);
  }, []);

  function handleChecked() {
    let favPayloadData: any;
    let value = kagamiMenuModel.state.isFavourite;
    kagamiMenuModel.state.SetIsFavourite(!value);
    let userData = getUserProfileData();
    const obj = {
      groupName: kagamiMenuModel.groupName,
      processId: kagamiMenuModel.event.process,
      processName: kagamiMenuModel.name,
      userId: userData.userId
    };
    if (!value) {
      favPayloadData = dashboardModel.favouritesData.map((ele: any) => {
        return { groupName: ele.groupName, processId: ele.processId, processName: ele.processName, userId: ele.userId };
      });
      favPayloadData.push(obj);
    } else {
      let checkFavata = dashboardModel.favouritesData.map((ele: any) => {
        return { groupName: ele.groupName, processId: ele.processId, processName: ele.processName, userId: ele.userId };
      });
      favPayloadData = checkFavata.filter((arratData: any) => arratData.processId != obj.processId);
    }
    APIService.getData(SystemConstants.POST, saveUserFavourites(), favPayloadData)
      .then((response: any) => {
        dashboardModel.state.setFavouritesData(response.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {renderFavoriteCheckBox(kagamiMenuModel.state.isFavourite, handleChecked)}
      <div
        className={classes.activeItem1}
        style={{
          // display: 'flex',
          // alignItems: 'center',
          // width: 'calc(100% - 5px)',
          borderRadius: '5px',
          // background: activeItem === kagamiMenuModel.event.process ? 'rgba(255,255,255,0.2)' : '',
          // color: activeItem === kagamiMenuModel.event.process ? 'yellow' : '',
          width:"100%",
          // paddingLeft: '10px',
          cursor: 'pointer',
          listStyleType: 'none'
        }}
      >
        {/* {renderFavoriteCheckBox(kagamiMenuModel.state.isFavourite, handleChecked)} */}
        <MenuItem active={activeItem === kagamiMenuModel.event.process} key={kagamiMenuModel.event.process}
          onClick={() => handleMenuItemClick(kagamiMenuModel.event.process, kagamiMenuModel.name)}>
          {/* {kagamiMenuModel.name} */}
          {translateToLocalistaion(translations, kagamiMenuModel.name)}
        </MenuItem>
      </div>
    </div>
  );
});

// KagamiMenu.whyDidYouRender = true;

const renderFavoriteCheckBox = (isFavourite: boolean, handleChecked: any) => {
  const icon = isFavourite ? (
    <StarIcon sx={{ color: '#fff' }} onClick={handleChecked} />
  ) : (
    <StarBorderIcon fontSize="small" sx={{ color: '#fff' }} onClick={handleChecked} />
  );
  return <>{icon}</>;
};

export const CustomImageComponent = (props: any) =>
  props.image ? (
    <img
      className={props.className}
      alt="Folder"
      src={props.image}
      title={props.title}
      style={
        {
          //  width: '30px', height: '30px',
          // filter: 'invert(100%) sepia(48%) saturate(2%) hue-rotate(94deg) brightness(109%) contrast(100%)'
        }
      }
    ></img>
  ) : (
    <FolderOpenIcon fontSize={props.isMainFolder ? 'large' : 'small'} />
  );
