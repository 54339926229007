import { KagamiGroupModel } from '../model/KagamiGroupModel';
import { buildGroupComponent } from '../ui/KagamiGroup';
import { getActiveTabContent, getGroupModel, lookForDefualtTab } from '../service/GroupService';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import { getMainEntityPresentationsFromUIResource } from '../../../service/PresentationService';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { KagamiListModel } from '../../list/model/KagamiListModel';

export function buildGroup(groupModel: KagamiGroupModel) {
  groupModel.triggers = getMainEntityPresentationsFromUIResource(groupModel.onStartData)[0].actions;
  lookForDefualtTab(groupModel);

  return buildGroupComponent(groupModel);
}

export function onTabChange(ativeTabNumber: number, groupModel: KagamiGroupModel) {
  groupModel.groupContent = null;
  groupModel.state.setActiveIndex(ativeTabNumber);
  groupModel.state.setDefaultProcessList();
  getActiveTabContent(ativeTabNumber, groupModel);
}

export function loadGroupContent(content: JSX.Element) {
  getGroupModel().groupContent = content;
  KagamiStateManager.getStateToHolder(SystemConstants.GROUP_STATE).setGroupContent(content);
  setSearchBar();
}

function setSearchBar(){
  let groupModel : KagamiGroupModel = getGroupModel();
  if(CommonUtils.isNotEmpty(groupModel.activeGroupModel)){
    if(groupModel.activeGroupModel instanceof KagamiListModel){
      groupModel.state.setSearchBar(true);
    }
    else{
      groupModel.state.setSearchBar(false);
    }
  }
 
}

export function handleServerError(error: any) {}
