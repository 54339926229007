import * as React from 'react';
import { createState, useState } from '@hookstate/core';
import {
  PaymentDueListReportModel,
  PaymentDueListReportModelProps,
  usePaymentDueListReportModelState
} from '../model/PaymentDueListReportModel';
import { makeReport, makeLedgerData, getData } from '../service/PaymentDueListReportService';
import { StaticReportActions } from '../../../../../../staticReportFilters/ui/StaticReportActions';
import { StaticReportGrid } from '../../../../../../staticReportFilters/ui/StaticReportGrid';

export const BuildPaymentDueListReportView = (paymentDueListReportModel: PaymentDueListReportModel) => {
  return (
    <PaymentDueListReportView key={'paymentDueList-report-view'} paymentDueListReportModel={paymentDueListReportModel}></PaymentDueListReportView>
  );
};


const PaymentDueListReportView = (props: PaymentDueListReportModelProps) => {
  let runReportConfig: any = useState({
    loadReport: false,
    loadFilterData: true,
    showLoader: false,
    reportInfo: {}
  });
  let paymentDueListReportModel = props.paymentDueListReportModel;

  let paymentDueListChartState = usePaymentDueListReportModelState(createState(paymentDueListReportModel));
  paymentDueListReportModel.state = paymentDueListChartState;
  let staticFilterData: any;
  if (paymentDueListReportModel?.reportData?.staticFilterData) {
    staticFilterData = paymentDueListReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo: any = {}
  if (paymentDueListReportModel?.reportData?.gridReportInfo) {
    gridReportInfo = paymentDueListReportModel.reportData['gridReportInfo'];
  }
  let inputReportConfig = (props.paymentDueListReportModel.reportConfig);
  React.useEffect(() => {
    runReport();
  }, [])

  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo = makeReport(paymentDueListReportModel, inputReportConfig);
    let paymentDueListData = await getData(inputReportConfig);
    let rowData = await makeLedgerData(paymentDueListData, inputReportConfig);
    reportInfo['rowData'] = rowData;
    const obj = Object.assign({}, reportInfo);
    props.paymentDueListReportModel.reportData['gridReportInfo'] = obj;
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }
  let config = { reportModel: paymentDueListReportModel, inputReportConfig, gridReportInfo, runReportConfig };
  return (
    <div className="ag-theme-alpine static-report-container static-report-wbp static-report-grid" >
      <StaticReportActions config={config} ></StaticReportActions>
      <StaticReportGrid config={config}></StaticReportGrid>
    </div>
  );
};
