import _ from 'lodash';
import { ReportConstant } from '../../ReportConstant/ReportConstant';
import { KagamiReportModel } from '../model/KagamiReportModel';
import dayjs from 'dayjs';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const getStaticReportTypes = () => {
    return ['Bank Ledger','Bank Account','Cash Ledger','Cash Account','Customer Ledger','General Ledger','Sub Ledger','Vendor Ledger'	,'Customer Ageing','Vendor Ageing','BLReport','PLReport','TrialBLReport','Vendor Payment Due List','Customer Payment Due List','DayBook','Stock Ledger'];
}
export const getVisibleColumns = (columns:any)=> {
    return _.filter(columns,function (col) {
      return col.hidden != true;
    }) || [];
}

export const getReportType = (reportConfig:any) => {
    if(reportConfig?.reportConfigSummary) {
        return (reportConfig.reportConfigSummary.type) 
    }
    return '';
}
export const getReportId = (reportConfig:any) => {
    let reportId:any = '';
    if(reportConfig.id){
        reportId = reportConfig.id;
    }else if(reportConfig?.reportConfigSummary) {
        reportId = reportConfig.reportConfigSummary.id;
    }
    return reportId;
}
export const getReportName = (reportModel:any) => {
    return reportModel?.reportConfig?.reportConfigSummary?.name || reportModel?.reportConfigSummary?.name || '';
}
export const getReportNameByConfig = (reportConfig:any) => {
    return reportConfig?.reportConfigSummary?.name ||  '';
}


export const getReportData = (dbChartData:any, reportConfig:any) => {
  let chartRecords:any = [];
  if( dbChartData.table){
      let keys=_.map( dbChartData.table.cols,"label");
      dbChartData.table.rows.map(function (row:any) {
          let tempData :any = {};
          _.map(keys, function (key, i) {
            let column: any = reportConfig?.criteria?.columns?.find((col:any) => col["id"] === dbChartData.table.cols[i]["id"]);
            if (column && isDateOrTimeType(column)) {
              tempData[key] = getValueInDateTimeFormatAsAppSetting(row.c[i].v, column.type);
            } else {
              tempData[key] = row.c[i].v;
            }
          });
          chartRecords.push(tempData);
      });
 }
  return chartRecords;
}

// Get The Value Type From Criteria Column
export function getValueInDateTimeFormatAsAppSetting(value: any, type: string) {

  if ((type?.toLowerCase() === 'date' && dayjs(value, 'DD-MM-YYYY').isValid()) || (type.toLowerCase() === 'datetime' && dayjs(value, 'DD-MM-YYYY HH:mm:ss').isValid())) {
    value = (type.toLowerCase() === 'date' ? dayjs(value, 'DD-MM-YYYY') : dayjs(value, 'DD-MM-YYYY HH:mm:ss'));
  }
  if (!dayjs(value).isValid()) {
    return value;
  }

  let _appsetting = CommonUtils.getAppSetting();
  
  switch(type.toLowerCase()) {
    case 'date': {
        let dateFormat = _appsetting?.dateFormat;
        let format = convert_to_dayjs_date_format(dateFormat);
        return dayjs(value).format(format).toString();
    }
    case 'datetime': {
        let dateTimeFormat = _appsetting?.dateTimeFormat;
        let format = convert_to_dayjs_datetime_format(dateTimeFormat);
        return dayjs(value).format(format).toString();
    }
    case 'time': {
        let timeFormat = _appsetting?.timeFormat;
        let format = convert_to_dayjs_time_format(timeFormat);
        return dayjs(value).format(format).toString();
    }
  }
  return value;
}

// Convert Kagami Format to DayJs Date Format
export function convert_to_dayjs_date_format(format: string | undefined) {
    const dateFormats: { [key: string]: string } = {
        "dd/mm/yyyy": "DD/MM/YYYY",
        "dd/Mth/yyyy": "DD/MMM/YYYY",
        "Month dd,yyyy": "MMMM DD,YYYY",
        "mm/dd/yyyy": "MM/DD/YYYY"
    };

    if (format && dateFormats[format]) {
        return dateFormats[format];
    }
    return 'DD/MM/YYYY';
}

// Convert Kagami Format to DayJs DateTime Format
export function convert_to_dayjs_datetime_format(format: string | undefined) {

    const dateFormats: { [key: string]: string } = {
        "dd/mm/yyyy": "DD/MM/YYYY",
        "dd/Mth/yyyy": "DD/MMM/YYYY",
        "Month dd,yyyy": "MMMM DD,YYYY",
        "mm/dd/yyyy": "MM/DD/YYYY"
    };

    const timeFormats: { [key: string]: string } = {
        "hh:mm": "hh:mm a",
        "hh:mm:ss": "hh:mm:ss a",
        "HH:mm": "HH:mm",
        "HH:mm:ss": "HH:mm:ss"
    };

    if (format) {
        const [date, time] = format.split(" ");
        if(dateFormats[date] && timeFormats[time]) {
            return `${dateFormats[date]} ${timeFormats[time]}`;
        }
    }
    return 'DD/MM/YYYY HH:mm:ss';
}

// Convert Kagami Format to DayJs Time Format
export function convert_to_dayjs_time_format(format: string | undefined) {
    const timeFormats: { [key: string]: string } = {
        "hh:mm": "hh:mm a",
        "hh:mm:ss": "hh:mm:ss a",
        "HH:mm": "HH:mm",
        "HH:mm:ss": "HH:mm:ss"
    };

    if (format && timeFormats[format]) {
        return timeFormats[format];
    }
    return 'HH:mm:ss';
}


export function isDateOrTimeType(column: any) {
    return (
      column['type'].toLowerCase() === 'date' ||
      column['type'].toLowerCase() === 'datetime' ||
      column['type'].toLowerCase() === 'time'
    );
  }

export function getAppSettingDateFormat(reportConfig?: any) {
    let _appsetting = CommonUtils.getAppSetting();
    let dateFormat = reportConfig?.reportSettings?.date ?? _appsetting?.dateFormat;
    return convert_to_dayjs_date_format(dateFormat);
}

export function getAppSettingDateTimeFormat(reportConfig?: any) {
    let _appsetting = CommonUtils.getAppSetting();
    let dateTimeFormat = reportConfig?.reportSettings?.datetime ?? _appsetting?.dateTimeFormat;
    return convert_to_dayjs_datetime_format(dateTimeFormat);
}

export function getAppSettingTimeFormat(reportConfig?: any) {
    let _appsetting = CommonUtils.getAppSetting();
    let timeFormat = reportConfig?.reportSettings?.time ?? _appsetting?.timeFormat;
    return convert_to_dayjs_time_format(timeFormat);
}

export const getColumnName = (field:any,columnsList:any) => {
  if(columnsList){
    let column = _.filter(columnsList,{key:field});
    if(column && column.length){
      return column[0]['name'];
    }
  }
  return field || '';    
}

export const reportColumnDisplayFormatter = (reportConfig:any,cellvalue:any,colname:any,col:any,isFromPrint?:any) => {
  if(reportConfig && !reportConfig.criteria){
      return cellvalue;
  }
  let currConfigCol :any = {};
  if(colname){
      currConfigCol = _.find(reportConfig.criteria.columns, { 'name': colname});
  }else if(col) {
      currConfigCol = _.find(reportConfig.criteria.columns, { 'id':  col.field });
  }
  let $colkpresentationFunction = currConfigCol && currConfigCol['kpresentationFunction'];
  if ($colkpresentationFunction != undefined && $colkpresentationFunction.length > 0) {
      let $cellText ='';
      if(cellvalue && (""+cellvalue).indexOf(":")!==-1){
        $cellText = cellvalue.split(":")[0];
        cellvalue = parseFloat(cellvalue.split(":")[1]);
      }
      let $cellvalue :any = _.cloneDeep(cellvalue);  
      if (cellvalue != null || cellvalue != undefined) {
          for (let i = 0; i < $colkpresentationFunction.length; i++) { //each kpresentationFunction
              let $tkp = $colkpresentationFunction[i];
              let $kcurrencySymbol = $tkp.currencySymbol;
              let decimalSeparator = $tkp.decimalSeparator;
              let thousandSeparator = $tkp.thousandSeparator;
              let indianCurrency = false;
              if($tkp.currencySymbol && ($tkp.currencySymbol.indexOf('Indian') !=-1 || $tkp.currencySymbol.indexOf('INR') !=-1 ) ) {
                indianCurrency = true;
              }
              if(isFromPrint) {
                $kcurrencySymbol = "";
              }
              if ($kcurrencySymbol != undefined) {
                  let findIndex = $kcurrencySymbol.indexOf(' ');
                  $kcurrencySymbol = $kcurrencySymbol.substring(0, findIndex);
                  if ($kcurrencySymbol) {
                      if (decimalSeparator != 0 && decimalSeparator != null && thousandSeparator) {
                          $cellvalue = parseFloat($cellvalue);
                          $cellvalue = $kcurrencySymbol + " " + $cellvalue.toFixed(decimalSeparator).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
                      } else if (decimalSeparator != 0 && decimalSeparator != null) {
                          $cellvalue = parseFloat($cellvalue);  
                          $cellvalue = $kcurrencySymbol + " " + $cellvalue.toFixed(decimalSeparator).replace(/(\d)(?=(\d{})+\.)/g, '$1,');
                      } else if (thousandSeparator) {
                          $cellvalue = $cellvalue.toString();
                          let index = $cellvalue.indexOf('.');
                          let afterPoint ='';
                          if (index != -1) {
                              afterPoint = $cellvalue.substring(index, $cellvalue.length);
                              $cellvalue = $cellvalue.substring(0, index);
                          }
                          if (afterPoint) {
                              $cellvalue = $kcurrencySymbol + " " + $cellvalue.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + afterPoint;
                          } else {
                              $cellvalue = $kcurrencySymbol + " " + $cellvalue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          }
                      } else {
                          $cellvalue = $kcurrencySymbol + " " + $cellvalue;
                      }
                  } else {
                      if (decimalSeparator != 0 && decimalSeparator != null && thousandSeparator) {
                         $cellvalue = parseFloat($cellvalue);
                          $cellvalue = $cellvalue.toFixed(decimalSeparator).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
                      } else if (decimalSeparator != 0 && decimalSeparator != null) {
                          $cellvalue = parseFloat($cellvalue);
                          $cellvalue = $cellvalue.toFixed(decimalSeparator).replace(/(\d)(?=(\d{})+\.)/g, '$1,');
                      } else if (thousandSeparator) {
                        //   $cellvalue = $cellvalue.toString();
                        //   $cellvalue = $cellvalue.replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
                          $cellvalue = $cellvalue.toString();
                          let index = $cellvalue.indexOf('.');
                          let afterPoint = '';
                          if (index != -1) {
                              afterPoint = $cellvalue.substring(index, $cellvalue.length);
                              $cellvalue = $cellvalue.substring(0, index);
                          }
                          if(indianCurrency) {
                            //   $cellvalue =  $cellvalue.replace(/\B(?=(\d{2})+(?!\d))/g, ",")
                            $cellvalue = getLocaleValue($cellvalue, 'INR');
                          } else if ($tkp?.currencySymbol?.startsWith('XOF')) {
                            $cellvalue = getLocaleValue($cellvalue, $tkp?.currencySymbol);
                          } else{
                            //   $cellvalue =  $cellvalue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              $cellvalue = getLocaleValue($cellvalue,'');
                          }
                          if(afterPoint && !$tkp?.currencySymbol?.endsWith('_nd')) {
                              $cellvalue =  $cellvalue + afterPoint;
                          }
                      }
                  }
              }
              return $cellText ? ($cellText+": "+$cellvalue) : $cellvalue;
          }
      }
  }
  return cellvalue;
}


export const reportFilterHeader = (reportConfig:any) => {
  let dynamicParameterList:any = []  
  let reportFilterHeader:any = [];
  if(reportConfig?.dynamicParameterList && reportConfig.dynamicParameterList.length>0){
        dynamicParameterList = reportConfig.dynamicParameterList;
  }
  if (dynamicParameterList && dynamicParameterList.length > 0) {
      _.forEach(dynamicParameterList, function (value, key) {
          let filterObj:any = {
              'name': value.name,
              'relation': value.relationName
          }
          if(value.value != ''){
              if (value.relationName != ReportConstant.Is_In_The_Range && value.relationName != ReportConstant.Is_Not_In_The_Range) {
                  filterObj.value = value.value;
              } else {
                  filterObj.value = "From" + " " + value.value.fromDate + " " + "To" + " " + value.value.toDate
              }
              reportFilterHeader.push(filterObj);
          }else{
              if (value.relationName != ReportConstant.Is_In_The_Range && value.relationName != ReportConstant.Is_Not_In_The_Range) {
                  if(reportConfig.subtitle)
                  filterObj.value = reportConfig.subtitle;
              } else {
                  filterObj.value = "From" + " " + value.value.fromDate + " " + "To" + " " + value.value.toDate
              }
              reportFilterHeader.push(filterObj);
          }
      })
  }
  return reportFilterHeader;
}

export const getDisplayRulesForColumn = (column:any)=> {
    let displayRules: any = {
        style:[]
    };
    if (column.kpresentationFunction) {
      _.each(column.kpresentationFunction, function (kpFunction) {
        if (kpFunction.negativeNumFormat) {
          displayRules['presentationFunction'] = column.kpresentationFunction;
        }
        if (kpFunction.presentationFuncName == 'Hilight') {
          displayRules['presentationFunction'] = column.kpresentationFunction;
                let style = getStyleForKPresentation(kpFunction);
                if(style && _.keys(style).length) {
                    displayRules['style'].push(style);
                }
        } else if (kpFunction.presentationFuncName == 'Aggregate') {
            displayRules['presentationFunction'] = column.kpresentationFunction;
            if (kpFunction.funcProperties['style'][0] == 'Sum') {
                displayRules['aggregate'] = 'Sum';
            } else if (kpFunction.funcProperties['style'][0] == 'Count') {
                displayRules['aggregate'] = 'Count';
            } else if (kpFunction.funcProperties['style'][0] == 'Avg') {
                displayRules['aggregate'] = 'Avg';
            } else if (kpFunction.funcProperties['style'][0] == 'Min') {
                displayRules['aggregate'] = 'Min';
            } else if (kpFunction.funcProperties['style'][0] == 'Max') {
                displayRules['aggregate'] = 'Max';
            }
        }
        if (!displayRules['thousandSeparator'] && kpFunction.thousandSeparator) {
          displayRules['thousandSeparator'] = kpFunction.thousandSeparator;
        }
        if(kpFunction.currencySymbol) {
            displayRules['currencySymbol'] = true;
            displayRules['$$currencySymbol'] = kpFunction.currencySymbol;
        }
        if (kpFunction.currencySymbol && (kpFunction.currencySymbol.indexOf('Indian') != -1 ||  kpFunction.currencySymbol.indexOf('INR') != -1)) {
          displayRules['indianCurrency'] = true;
        }
      });
    }
    //for rtl column
    if (column.rtl == true) {
      displayRules['rtl'] = true;
    }
    if (column.multiReportMapping) {
      displayRules['ismultiReportMapping'] = true;
    }
    return displayRules;
}

const getStyleForKPresentation = (kpFunction:any) => {
    let $style:any = {};
    if (kpFunction?.funcProperties) {
    let $lstyle = kpFunction.funcProperties.style[0];
    let $rstyle = kpFunction.funcProperties.style[1];
    if ($lstyle == 'fontSize') {
        $lstyle = 'font-size';
        $rstyle = $rstyle + 'px';
    } else if ($lstyle == 'Italic') {
        $lstyle = 'font-style';
        $rstyle = 'italic';
    } else if ($lstyle == 'Bold') {
        $lstyle = 'font-weight';
        $rstyle = 'bold';
    } else if ($lstyle == 'Bold') {
        $lstyle = 'font-weight';
        $rstyle = 'bold';
    } else if ($lstyle == 'Color') {
        $lstyle = 'color';
    } else if ($lstyle == 'Background-Color') {
        $lstyle = 'background-color';
    } else if ($lstyle == '0 num coloring') {
        $lstyle = 'color';
        $rstyle = 'inherit';
    } else if ($lstyle == '+ve num coloring') {
        $lstyle = 'color';
    } else if ($lstyle == '-ve num coloring') {
        $lstyle = 'color';
        $rstyle = 'inherit';
    }
    // $style += '' + $lstyle + ':' + $rstyle + ';';
     $style['key']=$lstyle;
     $style['value']=$rstyle;

    if (kpFunction.negativeNumFormat == '1234-red' ||kpFunction.negativeNumFormat == '(1234)-red') {
        // $style += 'color' + ':red' + ';';
        $style['key']='color';
        $style['value']= 'red';
    }
    }
    return $style;
}

export const getObjectForReportData = (kagamiReportModel: KagamiReportModel) => {
    let param: any = {};
  
    Array.from(kagamiReportModel?.reportFilterModel.selectedValue.keys()).forEach((key: any) => {
      param[key] = (!kagamiReportModel.reportFilterModel.selectedValue.get(key)  || kagamiReportModel.reportFilterModel.selectedValue.get(key) =='undefined') ? 'NOPOP' : kagamiReportModel.reportFilterModel.selectedValue.get(key);
    });

    let mainParam: any = {};
    let filtersList = kagamiReportModel.reportConfig.dynamicParameterList;
    filtersList.forEach((ele: any) => {
      if (Array.isArray(param[ele.name])) {
        mainParam[ele.name] = param[ele.name].join(',');
      } else {
        if (ele.type === ReportConstant.Date) {
          if (
            ele.relationName === ReportConstant.Is_In_The_Range ||
            ele.relationName === ReportConstant.Is_Not_In_The_Range
          ) {
            mainParam[ele.name] = param[ele.name] === "NOPOP"? "1970-01-01 00:00:00,1970-01-01 00:00:00": param[ele.name];
            //   .split(',')
            //   .map((date: any, index: any) => {
            //     if (index === 0) {
            //     //   return date + ' 00:00:00';
            //         return date;
            //     } else if (index === 1) {
            //     //   return date + ' 23:59:59';
            //     // return date + ` ${dayjs().format('HH:mm:ss')}`
            //     return date;
            //     }
            //   })
            //   .join(',');
          } else if (
            ele.relationName === ReportConstant.Is ||
            ele.relationName === ReportConstant.Is_Not ||
            ele.relationName === ReportConstant.Is_After ||
            ele.relationName === ReportConstant.Is_Before ||
            ele.relationName === ReportConstant.Is_After_Or_Equal ||
            ele.relationName === ReportConstant.Is_Before_Or_Equal ||
            ele.relationName === ReportConstant.Today
          ) {
            mainParam[ele.name] = param[ele.name].split(',')[0];
          }
        } else if(ele.type === ReportConstant.DateTime){
            mainParam[ele.name] = param[ele.name]
        }else {
          mainParam[ele.name] = param[ele.name];
        }
      }
    });
  
    return {
      reportId: kagamiReportModel.reportConfig.reportConfigSummary.id,
      queryParam: JSON.stringify([mainParam]),
      offset: 330
    };
  };
  
  export const handleStaticReportDates = (inputReportConfig:any ) =>{
    let startDateYear=new Date();
    startDateYear.setDate(1);
    startDateYear.setMonth(0);
    let yearStartDate = new Date(startDateYear).toISOString().substring(0, 10);
    let todayDate = new Date().toISOString().substring(0, 10);
    if(!inputReportConfig['leftPanel']) {
        inputReportConfig['leftPanel'] = {
            startDate:yearStartDate +" 00:00:00",
            endDate:todayDate +" 23:59:59"
        };
    }else if(!inputReportConfig['leftPanel']['startDate'])  {
        inputReportConfig['leftPanel']['startDate']= yearStartDate +" 00:00:00";
    }else if(!inputReportConfig['leftPanel']['endDate'])  {
        inputReportConfig['leftPanel']['endDate']= todayDate +" 23:59:59";
    }
    if(inputReportConfig?.['leftPanel']?.['startDate']?.indexOf("00:00:00") == -1) {
        inputReportConfig['leftPanel']['startDate'] += " 00:00:00";
    }
    if(inputReportConfig?.['leftPanel']?.['endDate']?.indexOf("23:59:59") == -1) {
        inputReportConfig['leftPanel']['endDate'] += " 23:59:59";
    }

  }
export const getSelectedCurrency =  (reportConfig:any) => {
    if(reportConfig?.ktreeReport?.selectedCurrency) {
        return reportConfig.ktreeReport.selectedCurrency || "";
    }
    return "";
}

 export const getLocaleValue = (cellvalue:any, currency:any) => {
    if (cellvalue) {
        if(cellvalue.indexOf(",") != -1) {
            cellvalue = cellvalue.replaceAll(",","");
        }
        let valueAndUnit = cellvalue.split(' ');
        let changedVal = numberCellDisplayFormatter(valueAndUnit[0], currency);
        let unit = valueAndUnit[1];
        return unit ? changedVal + ' ' + unit : changedVal;
    }
    return cellvalue;
  }

  export const numberCellDisplayFormatter = (cellvalue:any, currency:any) => {
    if (cellvalue) {
     let locale;
     switch(currency){
      case 'INR':
        locale = 'en-IN';
        break;
      case 'XOF':
      case 'XOF_nd':
        locale = 'fr-ML';
        break;
      default:
        locale = 'en-US';
     }
     let floatValue = parseFloat(cellvalue);
     if (currency.includes('_nd')) {
      // _nd is ment for no-decimal
      return floatValue.toLocaleString(locale, {minimumFractionDigits: 0, maximumFractionDigits: 0}).replace(/[^ 0-9,.-]+/g," ");
     }
      return floatValue.toLocaleString(locale, {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/[^ 0-9,.-]+/g," ");
    }
    return "";
  }


  export const prepareReportAggregateData =  (rows:any,tablecolDefs:any,runReportConfig:any,requireAggregateKey:any) => {
    let aggregationsInfo:any = {};
    let aggregatecoldata:any =[];
    let columnsList = _.cloneDeep(tablecolDefs);
    _.forEach(columnsList, function (colDef:any ,index:any) {
        let aggregatedisp:any = '';
        let aggegatedValue:any = 0;
        // let removeSpaces = colDef.id;
        let exportableRows=rows;
        getAggregateFunctionForColumn (runReportConfig,colDef);
        if (colDef.functionName == "Sum") {
            aggregatedisp = '';
            _.forEach(exportableRows, function (row) {
                if(row){
                    aggegatedValue = aggegatedValue + convertStringValtoNumber(row[colDef.label]);
                }
            })
            if(aggegatedValue == 0){
                aggregatedisp = 0;
            }
        } else if (colDef.functionName == "Count") {
            aggregatedisp = 'Count:';
            aggegatedValue = exportableRows.length;
        } else if (colDef.functionName == "Avg") {
            aggregatedisp = 'Avg:';
            let sum = 0;
            _.forEach(exportableRows, function (row) {
                sum = sum + convertStringValtoNumber(row[colDef.label]);
            })
            aggegatedValue = sum / exportableRows.length;
        } else if (colDef.functionName == "Min") {
            let minArray:any = [];
            aggregatedisp = 'Min:';
            _.forEach(exportableRows ,function (row) {
                minArray.push(convertStringValtoNumber(row[colDef.label]));
            })
            aggegatedValue = _.min(minArray);
        } else if (colDef.functionName == "Max") {
            aggregatedisp = 'Max:';
            let maxArray:any = [];
            _.forEach(exportableRows, function (row) {
                maxArray.push(convertStringValtoNumber(row[colDef.label]));
            })
            aggegatedValue = Math.max.apply(null, maxArray)
        }
        if (aggegatedValue != undefined && aggegatedValue != '') {
             aggregatedisp = aggregatedisp + '' + (((""+aggegatedValue)?.indexOf(".") !=-1) ?  parseFloat(aggegatedValue).toFixed(2) : parseFloat(aggegatedValue).toFixed(0));
        }
        // aggregationsInfo[colDef.label] =parseFloat(aggegatedValue).toFixed(2);
        aggregationsInfo[colDef.label] = aggregatedisp;
        aggregatecoldata.push({
            value: aggregatedisp
        });
    });
    if(requireAggregateKey){
        aggregationsInfo['aggregatecoldata'] = aggregatecoldata;
    }
    return aggregationsInfo;
};

const convertStringValtoNumber = (val:any) => {
    if(val){
        if( !(_.isNumber(val)) && val.indexOf(",") !=-1){
            val = val.replaceAll(",","");
            return parseFloat(val);
        }else {
            return parseFloat(val);
        }
    }return 0;
}

export const getAggregateFunctionForColumn = (runReportConfig:any,currentColumn:any) => {
    if (runReportConfig.criteria) {
        _.forEach(runReportConfig.criteria.columns, function (column) {
            if (currentColumn.id == column.id && column.kpresentationFunction) {
                _.each(column.kpresentationFunction, function (kpFunction) {
                    if (kpFunction.negativeNumFormat) {
                        currentColumn.presentationFunction = column.kpresentationFunction;
                    }
                    if (kpFunction.presentationFuncName == "Hilight") {
                        currentColumn.presentationFunction = column.kpresentationFunction;
                    }
                    else if (kpFunction.presentationFuncName == "Aggregate") {
                        if (kpFunction.funcProperties["style"][0] == "Sum") {
                            currentColumn.functionName = "Sum";
                        } else if (kpFunction.funcProperties["style"][0] == "Count") {
                            currentColumn.functionName = "Count";
                        } else if (kpFunction.funcProperties["style"][0] == "Avg") {
                            currentColumn.functionName = "Avg";
                        } else if (kpFunction.funcProperties["style"][0] == "Min") {
                            currentColumn.functionName = "Min";
                        } else if (kpFunction.funcProperties["style"][0] == "Max") {
                            currentColumn.functionName = "Max";
                        }
                    }
                });
            }

        })
    }
}

export const getReportUOM = (reportConfig:any) => {
 return reportConfig.reportSettings.uom || '';
}
export const getFilterCanvasContent = (reportConfig:any) => {
    if(reportConfig?.reportSettings?.filterCanvasContent){
        return reportConfig.reportSettings.filterCanvasContent;
    }
    return "";
}

export const getDateFormatsList =  function (){
    return ["DD MMM","DD MM YYYY","DD MMM YYYY","MMM YYYY","DD/MM/YYYY"]
}
//  export const getDateFormatData=(reportConfig:any)=>{
//     let format=reportConfig?.reportSettings?.date||"yyyy-MM-dd";
//     switch(format){
//       case "DD-MM-YYYY":
//       case "dd-MM-yyyy":
//         format="dd/MM/yyyy"
//         break;
//       case "DD/MM/YYYY":
//         format="dd/MM/yyyy"
//         break;
//       default:
//         format="dd/MM/yyyy"
//         break;
//     }
//     return format;
//   }
export const handleFilterCanvas = function(reportConfig:any,reportModel:any) {
    let filterImage  = "";
    if(reportConfig?.reportSettings?.filterCanvasContent ){
         filterImage = window.decodeURIComponent(reportConfig.reportSettings.filterCanvasContent); 
         if(reportConfig?.dynamicParameterList?.length) {
            _.forEach(reportConfig.dynamicParameterList,function(filter) {
                var filterVal = "";
                if(reportModel?.reportFilterModel?.selectedValue) {
                    filterVal =  reportModel?.reportFilterModel?.selectedValue.get(filter.name);
                }
                if(filter.type == ReportConstant.Date || filter.type == ReportConstant.DateTime) {
                    _.forEach(getDateFormatsList(),function(dateFormat) {
                            if(dateFormat && filterImage.indexOf('#F{' + filter.name +','+dateFormat+'}') !=-1) {
                                filterImage = filterImage.replace('#F{' + filter.name +','+dateFormat+'}',filterVal)
                            }
                    });
                }else {
                    filterImage = filterImage.replace('#F{' + filter.name +'}',filterVal)
                }
            })
         }
    }
    return filterImage;
}

export const handleExcelFilterConfig = function (reportConfig: any, reportModel: any, excelHeaderRows: any) {
    if (excelHeaderRows?.length) {
        _.forEach(excelHeaderRows, function (excelRow) {
            if (excelRow?.filterSymbol) {
                let filterVal = "";
                if (reportConfig?.dynamicParameterList?.length) {
                    _.forEach(reportConfig.dynamicParameterList, function (filter) {
                        if (reportModel?.reportFilterModel?.selectedValue) {
                            filterVal = reportModel?.reportFilterModel?.selectedValue.get(filter.name);
                            if(filterVal =='NOPOP'){
                                filterVal ='All';
                            }
                            if (excelRow?.filterSymbol?.indexOf('#F{' + filter.name + '}') != -1) {
                                excelRow.filterValue = filterVal;
                                excelRow.title += " "+filterVal;
                            } else if (filter.type == "Date" || filter.type == "DateTime") {
                                _.forEach(getDateFormatsList(), function (dateFormat) {
                                    if (dateFormat && excelRow?.filterSymbol?.indexOf('#F{' + filter.name + ',' + dateFormat + '}') != -1) {
                                        excelRow.filterValue = filterVal;
                                        excelRow.title += " "+filterVal;
                                    }
                                });
                            }
                        }
                    })
                }
            }
        });
    }
 
}

export const getReportPageSize = (reportConfig:any) => {
    if(reportConfig?.reportSettings?.printSettings?.pageSize){
        return reportConfig.reportSettings.printSettings.pageSize || "A4";
    }
    return "A4";
}
export const getColumnWidth = (listReportController: any, col: any) => {
    if (listReportController?.reportConfiguration?.columnConfig && col.label) {
        let colDef = _.find(listReportController.reportConfiguration.columnConfig, { 'columnName': col.label });
        if (colDef?.columnWidth) {
            return colDef.columnWidth
        }
    }
}


export const getRTLFields = (reportConfig:any)=> {
    let rtlColumns = _.filter(reportConfig.columnDefs,function(item) {
        return item.cellStyle['text-align'] == 'right' || item?.headerComponentParams?.column?.type == 'number';
    })   
    return rtlColumns?.length ? _.map(rtlColumns,'field') : []
}

export const getReportColumnByName = (reportConfig: any, column: any) => {
    let originalCol = _.cloneDeep(column);
    if (reportConfig.criteria.columns) {
        _.forEach(reportConfig?.criteria?.columns, function (item) {
            if (item.name == column.field) {
                originalCol = item;
            }
        })
    }
    return originalCol
}

export const getExcelNumberFields = (reportConfig: any, selectedCurrency: any) => {
    let numberFields: any[] = [];
    if (reportConfig?.criteria?.columns) {
        for (let index = 0; index < reportConfig.criteria.columns.length; index++) {
            let kPresentationFunction = reportConfig?.criteria?.columns[index]?.kpresentationFunction;
            if (kPresentationFunction?.length) {
                _.forEach(kPresentationFunction, function (kpf) {
                    if (kpf['thousandSeparator'] == true) {
                        numberFields.push(
                            {
                                name: reportConfig?.criteria?.columns[index]['name'],
                                numFmt: getNumberFormatForExcel(selectedCurrency || kpf.currencySymbol, kpf.decimalSeparator)
                            });
                    }
                })
            }
        }
    }
    return numberFields;
}

export const getNumberFormatForExcel = (currency:string, decimalSeparator: any) => {
    let format;
    switch(currency) {
      case 'INR':
        format = "#,#0";
        break;
      case 'XOF':
      case 'XOF_nd':  
        format = "### ### ### ### ### ### ##0";
        break;
      default:
        format = "#,##0";
     }
     if (decimalSeparator && !currency?.endsWith('_nd')) {
        format += "."+("0".repeat(decimalSeparator))
    }
     return format;
  }

export const getReportAdditionalSettings = (config:any) => {
    return config?.customTileReportModel?.reportConfig?.reportSettings || {}
}
export const getReportAdditionalSettingsByProperty = (reportSettings:any,property:any) => {
    return reportSettings[property] || '';
}

export const getValueColumnForCustomTile = (reportConfig: any) => {
    if (reportConfig.criteria.columns?.length) {
        let columnArr = _.filter(reportConfig.criteria.columns, { name: 'Value' })
        if (columnArr?.length) {
            return columnArr[0];
        }
    }
    return '';
}


export const getReportColorBySeriesIndex = (reportConfig:any , index : any) => {
    let colorsArr = reportConfig?.reportSettings?.colors || [];
    if( colorsArr?.length > 1 ) {
        return colorsArr[index] ? colorsArr[index] : colorsArr[index % colorsArr.length];
    }
    return  colorsArr?.length == 1 ? colorsArr[0] :  CommonUtils.getRandomColor();
}

export const isStaticReport = (reportType: string) => {
    return getStaticReportTypes()?.indexOf(reportType) != -1 
}


