import { createState } from '@hookstate/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from "react";

import calender_view from '../../../../../../../../../../assets/images/custom-icons/calender_view.png';
import card_view from '../../../../../../../../../../assets/images/custom-icons/card_view.png';
import hierarchy_view from '../../../../../../../../../../assets/images/custom-icons/hierarchy_view.png';
import kanban_view from '../../../../../../../../../../assets/images/custom-icons/list_view_1.png';
import list_view from '../../../../../../../../../../assets/images/custom-icons/list_view.png';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { getActiveModel } from '../../../controller/KagamiProcessController';
import { getAttrForCustomFiled, isCustomField, isVisibleViewsPresent, translateToLocalistaion } from '../../../service/PresentationService';
import { showGroupBackButton } from '../../form/service/FormService';
import { handleServerError, loadGroupContent } from '../../group/controller/KagamiGroupController';
import { getGroupModel } from '../../group/service/GroupService';
import { onTriggerClick } from '../../Triggers/service/KagamiTriggersService';
import { BuildListFilterComponent, BuildListFilterWidget, BuildListPresentation, BuildSelectedFilter, BuildTriggers } from '../builder/ListBuilder';
import { buildListHeader } from '../builder/listHeader/controller/KagamiListHeaderController';
import { KagamiPresentationFilterModel, usePresentationFiltersState } from '../builder/presentationFilters/buliders/kagamiPresentationFilter/model/KagamiPresentationFilterModel';
import { kagamiPresentationFilterstyle } from '../builder/presentationFilters/buliders/kagamiPresentationFilter/styles/KagamiPresentationFilterStyle';
import { buildListSearchBar } from '../builder/searchbar/controller/KagamiListSearchController';
import { KagamiListSearchModel } from '../builder/searchbar/model/KagamiListSearchModel';
import { handleCalendarOnLoadData, handleCardOnLoadData, handleGridOnLoadData, handleHierarchicalOnLoadData, handleKanbanOnLoadData } from '../controller/KagamiListController';
import { KagamiListModel, ListModelProps, useListState } from '../model/KagamiListModel';
import { retrieveMainObject } from '../service/kagamiListService';
import { KagamiListStyle } from '../style/ListStyle';

export const buildListView = (kagamiListModel: KagamiListModel) => {
  return <KagamiList key={Math.random().toString()} kagamiListModel={kagamiListModel} />;
};

const useStyles = makeStyles(KagamiListStyle);

const KagamiList = (props: ListModelProps) => {
  let classes = useStyles();
  const listState = useListState(createState(props.kagamiListModel));
  props.kagamiListModel.state = listState
  const searchModel = new KagamiListSearchModel(props.kagamiListModel);
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  props.kagamiListModel.kagamiListFilterModel.filterData = props.kagamiListModel.onStartData.constructOutputData.detailedObjects;
  props.kagamiListModel.isVisibleview = isVisibleViewsPresent(props.kagamiListModel.presentation);
  props.kagamiListModel.isCustomField = props.kagamiListModel.listTemplate === 'calendar' ? isCustomField(props.kagamiListModel.presentation) : props.kagamiListModel.isCustomField;
  props.kagamiListModel.customFieldAttrName = props.kagamiListModel.listTemplate === 'calendar' ? getAttrForCustomFiled(props.kagamiListModel.presentation) : props.kagamiListModel.customFieldAttrName;
  let getDetailsObjectsLength = retrieveMainObject(props.kagamiListModel);
  //props.kagamiListModel.kagamiListFilterModel.isWidget  = true
  const handleBackButton = () => {
    let triggerModel = getGroupModel().kagamiTriggerModel;
    if (triggerModel !== null) {
      onTriggerClick([], triggerModel, handleServerError, loadGroupContent, true, true);
    }
  };

  useEffect(() => {
    if(props.kagamiListModel.listTemplate === 'hierarchical'){
      const bodyNode = document.querySelector("body");
      bodyNode?.classList.add("hierarchy");
      return () => {
        bodyNode?.classList.remove("hierarchy");
      }
    }
  }, [ ])
  
  return (
    <>
      <div className={`${classes.root} isPickItem list_container`}>
        <div className={`${classes.process_headername_and_searchfilter} listPageHeader`}>
          {/* {!props.kagamiListModel.isGroupListModel ? (props.kagamiListModel.listTemplate === 'calendar' ? null : buildProcessPageHeaderName(props.kagamiListModel)) : <></>} */}
          {/* {(!props.kagamiListModel.isGroupListModel || props.kagamiListModel.onLoadData) ? buildListHeader(props.kagamiListModel.kagamiListHeaderModel) : <></>} */}
          {(!props.kagamiListModel.isGroupListModel || props.kagamiListModel.onLoadData) ? buildListHeader(props.kagamiListModel.kagamiListHeaderModel) : <></>}
          <div className={classes.process_header_Icons}>
            
             {/* hiding searchcomponent for calendar view */}
            {/* {!props.kagamiListModel.isGroupListModel
             ? (props.kagamiListModel.listTemplate === 'hierarchical' || props.kagamiListModel.listTemplate === 'calendar')
               ? null : buildListSearchBar(searchModel)
             : null} */}

            {!props.kagamiListModel.isGroupListModel
              ? (props.kagamiListModel.listTemplate === 'hierarchical')
                ? null : buildListSearchBar(searchModel)
              : null}
              <div className={classes.visibleViewsContainer}>
            {props.kagamiListModel.isVisibleview.length > 1 ? props.kagamiListModel.isVisibleview.map((visibleview: any) => {
             if(props.kagamiListModel.viewType === visibleview.id) return <></>
              return showVisbleview(visibleview, props.kagamiListModel)
            }) : null}
              </div>
          </div>
        </div>

        {showGroupBackButton(props.kagamiListModel) ? props.kagamiListModel.listTemplate !== 'calendar' && (
          <div className={classes.backButtonH} >
            <Button variant="contained" size="small" onClick={handleBackButton}
              startIcon={<ArrowBackIcon fontSize='small' />}>
              {translateToLocalistaion(translations, 'Back')}
            </Button>
          </div>
        ) : null}
        <div className={classes.triggers_and_representation}>
          <div className={classes.presentation_body}>
            <div className={classes.subPresentation}>
              <BuildTriggers key="build-triggers" kagamiListModel={props.kagamiListModel} templateType={listState.getViewType()}></BuildTriggers>
              <div className={`${classes.filterPresentation} grid_filters_list`}>
               {props.kagamiListModel.kagamiListFilterModel.isWidget ? <BuildListFilterWidget kagamiListFilterModel={props.kagamiListModel.kagamiListFilterModel}/>
               : <BuildListFilterComponent kagamiListFilterModel={props.kagamiListModel.kagamiListFilterModel} />
               } 
               {/* {getDetailsObjectsLength.length !==1 ? <BuildListFilterComponent kagamiListFilterModel={props.kagamiListModel.kagamiListFilterModel} />:null} */}
              </div>
            </div>
            <div style={{display:"flex", justifyContent:'end', flexDirection:"row-reverse", marginRight: '5px', marginBottom: '0.19rem'}}>
             <BuildSelectedFilter key="list-selectedfilter"   kagamiListModel={props.kagamiListModel} selectedFilterModel={props.kagamiListModel.selectedFilterModel} />
             </div>
            <BuildListPresentation key="list-presenatation" kagamiListModel={props.kagamiListModel} templateType={listState.getViewType()} />
          </div>
        </div>
      </div>
    </>
  );
};

export const showVisbleview = (visibilityviews: any, listmodel: KagamiListModel) => {  
  if(visibilityviews.selected===true) {
    switch (visibilityviews.name) {
      case 'List':
      case 'Grid':
        if(listmodel.viewType === 'list' || listmodel.viewType === 'grid') return <></>
        return  <IconButton size = 'small' title="List View" style={{border: listmodel.state.getViewType() === 'grid' ? '2px solid  rgb(152 210 168)' : 'none'}} onClick={() => handleGridOnLoadData(listmodel)}>
                <Avatar sx={{ width: 24, height: 24 }} alt="ListView" src={list_view}  />
                </IconButton> 
      case 'Card':
        return <IconButton size = 'small' title="Card View" style={{marginBottom:'15x', marginTop:'4px' ,marginRight:'5x' ,border: listmodel.state.getViewType() === 'Card' ? '2px solid  rgb(152 210 168)' : 'none'}} onClick={() => handleCardOnLoadData(listmodel)}>
               <Avatar sx={{ width: 24, height: 24 }} alt="CardView" src={card_view}  />
             </IconButton>
      case 'Calendar':
        return <IconButton size = 'small' title="Calender View" style={{marginBottom:'15x', marginTop:'4px' ,marginRight:'5x' ,border: listmodel.state.getViewType() === 'Calendar' ? '2px solid  rgb(152 210 168)' : 'none'}} onClick={() => handleCalendarOnLoadData(listmodel)} > 
                <Avatar sx={{ width: 24, height: 24 }} alt="CardView" src={calender_view} />
                </IconButton>
      case 'hierarchical':
        return  <IconButton size = 'small' title="Hierarchy View" style={{marginBottom:'15x', marginTop:'4px' ,marginRight:'5x' ,border: listmodel.state.getViewType() === 'hierarchical' ? '2px solid  rgb(152 210 168)' : 'none'}} onClick={() => handleHierarchicalOnLoadData(listmodel)}> 
              <Avatar sx={{ width: 24, height: 24 }} alt="CardView" src={hierarchy_view}  />
              </IconButton>
      case 'Kanban':
      case 'kanban':
        return <IconButton size = 'small' title="Kanban View" style={{marginBottom:'15x', marginTop:'4px' ,marginRight:'5x' ,border: listmodel.state.getViewType() === 'Kanban' ? '2px solid  rgb(152 210 168)' : 'none'}} onClick={() => handleKanbanOnLoadData(listmodel)}> 
        <Avatar sx={{ width: 24, height: 24 }} alt="CardView" src={kanban_view}  />
        </IconButton>
      default:
        return null
      }    
  }
}

export const listpopupHeaderwithTotal = (attributePresentation: any, recordsLength: any) => {
  return (
    <div style={{display : 'flex',justifyContent : 'left' , alignItems: 'center' , padding:'5px'}}>
    <p>{attributePresentation.label}</p>
    <div>
    <Typography sx={{color : '#fff' , 
       //  background:'#6bb5c1',
        background: '#006e7f',
       borderRadius:'50%',
       border: 'none',
       padding:'1px 7px',
       margin: '5px',
       cursor: 'pointer',
       position:'relative',
       fontSize:'13px',
       marginTop: "-20px",
       fontWeight:'500',}}>{recordsLength}</Typography >
    </div>
    
    </div>);
}
