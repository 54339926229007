export const STATIC_REPORT_TRANSALATIONS_KEYS: any = {
    "COMMON": [],
    "LEDGER_COMMON": ["As On Date", "As On", "Date", "Account" , "Particulars", "Project Name", "Reference No", "Voucher No", "Voucher Type", "Debit", "Credit", "Closing Balance"
        , "Opening Balance", "Current Total", "Sub Organization", "Select Sub Organization", "Account List", "Select Account List", "Currency List", "Select Currency", "GST", "Select GST", "State", "Select state", "Project", "Select Project", "From Date", "To Date", "Run", "Excel"
        , "With Narration", "Without Narration", "With Narration & Closing Balance", "Without Narration & Closing Balance", "Detailed Excel", "Print Potrait", "Print Land Scape",
        "Download Excel","Print","Document No","Total","Account Details"
    ],
    "General Ledger": ["General Ledger"],
    "Bank Ledger": ["Bank Ledger"],
    "Bank Account": ["Bank Account"],
    "Cash Account": ["Cash Account"],
    "Cash Ledger": ["Cash Ledger"],
    "Customer Ledger": ["Customer Ledger"],
    "Vendor Ledger": ["Vendor Ledger"],
    "Sub Ledger": ["Sub Ledger", "Control Account", "Select Control Account", "Employee", "Customer", "Vendor", "Bank", "Project", "Select Value"],
    "PLReport": ["PLReport","Profit-Loss Report", "Account Name", "Value", "Total"],
    "TrialBLReport": ["TrialBLReport","Account Name","Trial-Balance Report", "Download Excel", "Expand All"],
    "BLReport": ["BLReport","Account Name","Balance Sheet Report"],
    "Customer Ageing": ["Customer Ageing", "Ageing List", "Select Ageing List", "Ageing Periods", "From", "To", "Reptype", "No of Invoices","Amount"],
    "Vendor Ageing": ["Vendor Ageing", "Ageing List", "Select Ageing List", "Ageing Periods", "From", "To", "Reptype", "No of Invoices","Amount"],
    "Vendor Payment Due List": ["Payment Due List", "Select Payment Due List", "Invoice LabelName", "Amount", "Payment Terms", "Amount Paid", "Due Amount", "Due Date", "Days Overdue"],
    "Customer Payment Due List": ["Payment Due List", "Select Payment Due List", "Invoice LabelName", "Amount", "Payment Terms", "Amount Paid", "Due Amount", "Due Date", "Days Overdue"],
    "Stock Ledger": ["Item Data", "Select Item","Stock Ledger"],
    "DayBook": ["Day Book","DayBook"]
}





