import extend from 'lodash/extend';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import {
  getReportData,
} from '../../../../service/ReportUtilService';


export const makeCustomTile = (customTileReportController: any) => {
  let defaultConfig = {
    tableRecords: []
  }
  let dbChartData = JSON.parse(customTileReportController.reportData);
  defaultConfig['tableRecords'] = getReportData(dbChartData, undefined);
  let dispchartOptions = extend(true, {}, defaultConfig);
  return dispchartOptions;
}

