import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../constants/SystemConstants';
import { KagamiTabNavigationModel } from '../../header/Tabs/model/KagamiTabNavigationModel';

export class KagamiNavigatorModel {
  navigatorLoaded: boolean;
  navigationData: any[];
  userData: any;
  failedMessage: string;
  state: any;
  collapseSidebar: boolean;
  menuMap: Map<string, any>;
  navComponents: any[];
  tabNavigationModel: KagamiTabNavigationModel;
  constructor() {
    this.navigatorLoaded = false;
    this.navigationData = [];
    this.failedMessage = '';
    this.userData = '';
    this.state = null;
    this.collapseSidebar = true;
    this.menuMap = new Map<string, any>();
    this.navComponents = [];
    this.tabNavigationModel = new KagamiTabNavigationModel();
  }
}

export const wrapNavigatorState = (state: State<KagamiNavigatorModel>) => {
  const navigatorState = state;

  return {
    get collapseSidebar() {
      return navigatorState.collapseSidebar.get();
    },

    drawerNeeded(drawerNeeded: boolean) {
      navigatorState.collapseSidebar.set(drawerNeeded);
    },
    get navigatorDataLoaded() {
      return navigatorState.navigatorLoaded.get();
    },

    setNavigatorDataLoaded(dashboardLoaded: boolean) {
      navigatorState.navigatorLoaded.set(dashboardLoaded);
    }
  };
};

// export const useNavigatorState = (state: State<KagamiNavigatorModel>) => {
//   return wrapNavigatorState(useState(state));
// };

export const useNavigatorState = (state: State<KagamiNavigatorModel>) => {
  const navState = wrapNavigatorState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.NAV_STATE, navState);
  return navState;
};

export interface NavigatorProps {
  kagamiNavigatorModel: KagamiNavigatorModel;
  tabNavigationModel?: KagamiTabNavigationModel;
}
