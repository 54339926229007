import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { getHeaderForprocess } from '../../../service/PresentationService';
import { getKagamiListModel } from '../../list/controller/KagamiListController';
import { CrumbType, KagamiBreadcrumbsModel } from '../model/KagamiBreadcrumbsModel';
import { buildBreadcrumbComponent } from '../ui/KagamiBreadcrumbs';

let breadcrumbsModel: KagamiBreadcrumbsModel;

export function buildBreadcrumbs(kagamiBreadcrumbsModel: KagamiBreadcrumbsModel) {
  breadcrumbsModel = kagamiBreadcrumbsModel;
  createBreadcrumb(kagamiBreadcrumbsModel);
  return buildBreadcrumbComponent(kagamiBreadcrumbsModel);
}

export function getBreadcrumbsModel() {
  return breadcrumbsModel;
}

export function setBreadcrumbsModel(kagamiBreadcrumbsModel: KagamiBreadcrumbsModel) {
  breadcrumbsModel = kagamiBreadcrumbsModel;
}

export function reBuildBreadcrumb() {
  breadcrumbsModel?.state?.setOnLoadRequired(true);
}

export function resetBreadcrumbs(kagamiBreadcrumbsModel: KagamiBreadcrumbsModel) {
  kagamiBreadcrumbsModel?.resetBreadcrumbs();
}

export function createBreadcrumb(kagamiBreadcrumbsModel: KagamiBreadcrumbsModel) {
  let $activeModel = kagamiBreadcrumbsModel.activeModel;
  let $breadcrumbs = kagamiBreadcrumbsModel.breadcrumbs;
  if (CommonUtils.isNotEmpty($activeModel)) {
    if (
      !$breadcrumbs.some((crumb:CrumbType) => {
        return getPresentationIdFromActiveModel(crumb.activeModel) === getPresentationIdFromActiveModel($activeModel);
      })
    ) {
      $breadcrumbs.push({
        headerName: getHeaderNameFromActiveModel($activeModel),
        activeModel: $activeModel,
        detailedObjects: getDetailedObjectFromActiveModel($activeModel)
      });
    } else {
      const currentBreadcrumb = getCurrentBreadcrumb($breadcrumbs, $activeModel);
      $breadcrumbs.length = 0;
      $breadcrumbs.push(...currentBreadcrumb);
    }
    if (kagamiBreadcrumbsModel.backwardCall) {
      const currentBreadcrumb = getCurrentBreadcrumb($breadcrumbs, $activeModel);
      $breadcrumbs.length = 0;
      $breadcrumbs.push(...currentBreadcrumb);
    }
  }
}

function getCurrentBreadcrumb(breadcrumbs: any, activeModel: any) {
  let breadcrumb:any = [];
  let presentationId = getPresentationIdFromActiveModel(activeModel);
  for (let index = 0; index < breadcrumbs.length; index++) {
    let b_presentationId = getPresentationIdFromActiveModel(breadcrumbs[index].activeModel);
    if(presentationId !== b_presentationId){
      breadcrumb.push(breadcrumbs[index]);
    }else{
      breadcrumb.push(breadcrumbs[index]);
      break;
    }
  }
  return breadcrumb;
}

export function getPresentationIdFromActiveModel(activeModel: any) {
  let presentation = activeModel['onStartData']['constructOutputData']['uiResource']['presentations'];
  let presentationId = presentation.presentationRuleMap[presentation.entityLevelMap[0]][0]['presentationId'];
  return presentationId;
}

export function getHeaderNameFromActiveModel(activeModel: any) {
  let headerName: any;
  const kagamiListModel = getKagamiListModel();
  if (kagamiListModel?.listTemplate?.toLowerCase() === 'calendar' && CommonUtils.isEmpty(kagamiListModel?.onLoadData)) {
    headerName = SystemConstants.EMPTY;
  } else if(activeModel?.onStartData?.constructOutputData) {
    headerName = getHeaderForprocess(activeModel['onStartData']['constructOutputData'], activeModel?.onLoadData) ?? '';
  }
  return headerName ?? SystemConstants.EMPTY;
}

function getDetailedObjectFromActiveModel(activeModel: any) {
  let $mainEntityId = activeModel['mainEntityId'];
  let $detailedObject: any = {};
  let $selectedDetailedObjects =
    activeModel['onStartData']['constructOutputData']['detailedObjects'][$mainEntityId];

  if ($selectedDetailedObjects) {
    let selectedObject: any = [];
    if ($selectedDetailedObjects.length > 0) {
      $selectedDetailedObjects.forEach((record: any) => selectedObject.push({ id: record.id }));
    }

    if (selectedObject != null && selectedObject.length > 0) {
      $detailedObject[$mainEntityId] = selectedObject;
    }
  } else {
    $detailedObject[$mainEntityId] = activeModel['onStartData']['constructOutputData']['detailedObjects'][$mainEntityId]?[
      activeModel['onStartData']['constructOutputData']['detailedObjects'][$mainEntityId]
    ]:[];
  }
  return $detailedObject;
}

export const checkForFlowerBraces = (constructOutputDataForStartCall: any) => {
  let entityPresentation = constructOutputDataForStartCall.uiResource.presentations;
  if (entityPresentation.entityLevelMap) {
    let entityId: string = entityPresentation.entityLevelMap[0];
    let presentationRuleMap: any = entityPresentation['presentationRuleMap'];
    if (presentationRuleMap[entityId] != null) {
      let list: any = presentationRuleMap[entityId];
      if (list[0].headerName.includes('{{')) {
        return true;
      }
      else{
        return false;
      }
    }
  }
  return false;
};
