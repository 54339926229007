import _ from 'lodash';
import AgGridGeneralToolPanel from '../ui/AgGridGeneralToolPanel';
import { getAggregateFunctionForColumn, getDisplayRulesForColumn, numberCellDisplayFormatter } from '../../../../service/ReportUtilService';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { currencyFormats } from '../../../../../ReportConstant/ReportConstant';

export function  makeHierarchyReportData(listReportController: any) {

  interface DynamicObject {
    [key: string]: any; // Allow any keys with any values
  }

  let cellClassRules: DynamicObject = {
    "hierarchy-nested-row": function (params: any) {
      return params.node.level > 0;
    },
    "right-alignment": function (params: any) {
      return params.colDef.cellDataType === 'number';
    },
    "header-and-footer-style": function (params: any) {
      return params.node.rowPinned === 'bottom';
    }
  };

  const headerClass = ((params: any) => {
    return params.colDef.cellDataType==="number"?["header-and-footer-style", "right-alignment"]: "header-and-footer-style";
  })

  const getCurrencySymbol = (currencySymbol: string) => {
    return currencyFormats.find((currency: any) => currency['symbol'] === currencySymbol)?.symbol ?? "DEFAULT";
  }

  currencyFormats.forEach((currency: any) => {
    let key = "currencySymbol_" + currency.symbol;
    cellClassRules[key] = function (params: any) {
      return CommonUtils.isNotEmpty(params.colDef?.currencySymbol) && getCurrencySymbol(params.colDef?.currencySymbol) === currency.symbol ? true : false;
    }
  })

  let defaultConfig ={
    autoGroupColumnDef: {  width: 200,cellRendererParams: {suppressCount: true,checkbox: false}},    
    columnDefs: [],
    defaultColDef: {
      flex: 1, sortable: true, minWidth: 125, filter: 'agMultiColumnFilter', resizable: true, editable: false, menuTabs: ['filterMenuTab'],
      cellClassRules: cellClassRules, headerClass: headerClass
    },
    groupDefaultExpanded: -1,
    // groupHideOpenParents:true,
    groupIncludeTotalFooter: false,
    suppressAggFuncInHeader: true,
    treeColumn: listReportController['treeColumn'],
    getRowHeight :(params: any) => 30,
    icons: {
      'menu': '<span class="ag-icon ag-icon-filter"/>',
      'general-stats': '<span class="ag-icon ag-icon-custom-stats"></span>',
      'icon-menu': '<span class="ag-icon ag-icon-menu"></span>'
    },
    pinnedBottomRowData:[],
    hideTotals: false,
    rowData:[],
    sideBar : {
      toolPanels: [
        {
          id: 'general',
          labelDefault: 'General',
          labelKey: 'general',
          iconKey: 'icon-menu',
          toolPanel: AgGridGeneralToolPanel,
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 225,
          width: 225,
          maxWidth: 225,
        },
      ],
      position: 'right',
      defaultToolPanel: ''
    },
  };
  
  
  let userConfig = prapareUserConfigForReport(listReportController,defaultConfig);  
  let dbChartData = JSON.parse(listReportController.reportData);
  let disptabelData = getReportData(dbChartData,listReportController);
  userConfig['rowData'] = disptabelData;

  let footerRowData = getFooterRowData(disptabelData,dbChartData,listReportController.reportConfig);
  if(footerRowData && footerRowData.length ){
    let footerValues = _.values(footerRowData[0]);
    if(footerValues && footerValues.length) {
      let footerData =  _.filter(footerValues,function(val) {
        return val !="";
      })
      if(footerData?.length){
        userConfig['pinnedBottomRowData'] = footerRowData;
      }
    }
  }
  let otherSettings = listReportController?.reportConfig.reportSettings;
  if (otherSettings) {
    if (otherSettings.showGrandTotals) {
      if (otherSettings.showGrandTotals == 'off') {
        userConfig['hideTotals'] = true;
      }
    }
  }

  let reportConfig = _.extend(true, {}, defaultConfig, userConfig);
  return reportConfig;
}

  function prapareUserConfigForReport (listReportController: any,defaultConfig:any) {
    let userConfig:any = {
      columnDefs: [],
      rowData: [],
    };
    userConfig['columnDefs'] = getReportColumnDefs(listReportController,defaultConfig);
    return userConfig;
  }
  const  getReportColumnDefs = (listReportController:any,defaultConfig:any) => {
    let columnDefs: any = [];
    let dbChartData  = JSON.parse(listReportController.reportData);
    if(dbChartData?.table?.cols) {
      dbChartData.table.cols.forEach((field: any) => {
        if(field.label!==listReportController['treeColumn']){
            let temp:any = {
              field: field.label,
              headerName: CommonUtils.makeFirstLetterCapital(field.label) ,
              valueFormatter: field.type == "number"? (params: any) => {
                if(params.colDef?.currencySymbol){
                  return numberCellDisplayFormatter(params.value, params.colDef?.currencySymbol);
                }
                let value = +params.value;
                return value?.toLocaleString(undefined, {maximumFractionDigits: params.colDef?.precision ?? 2, useGrouping: false});
              } : ""
            }
            // ---if extra thing needed then keep it in a function----
            let definedField = _.cloneDeep(field);
            if (listReportController?.reportConfig?.criteria?.columns) {
              let definedColArr = _.filter(listReportController.reportConfig.criteria.columns, { name: field.label });
              if (definedColArr && definedColArr.length > 0) {
                definedField = definedColArr[0];
              }
            }
            let dispRules = getDisplayRulesForColumn(definedField);
            if (dispRules['aggregate']) {
              temp['aggFunc'] = dispRules['aggregate'].toLowerCase();
            }
            if(dispRules['thousandSeparator'] && dispRules['currencySymbol']) {
              temp['currencySymbol'] = dispRules['$$currencySymbol'];
            }
            let cellStyles:any = {};
            cellStyles['text-align'] = definedField['type']?.toLowerCase() === 'number' ? 'right' : 'left';
            
            // ---------------rtl need to implement-------------------
            if(definedField['type']?.toLowerCase() === 'text') {
              temp['cellClass'] = 'stringType';
            }
            
            temp['cellStyle'] = cellStyles;
            // -------------up to here--------------------------------
            columnDefs.push(temp);
        }
      });
    }
    return columnDefs;
  }

   const getReportData = (dbChartData:any,listReportController:any) => {
    let reportJsonData:any = [];
    if( dbChartData.table){
        let keys=_.map( dbChartData.table.cols,"label");
        dbChartData.table.rows.map(function (row:any, index:number) {
            let tempData :any = {};
            _.map(keys, function (key, i) {
                if(key==listReportController['treeColumn']){
                    tempData[key] = row.c[i].v.split(' -> ')
                }else{
                    tempData[key] = row.c[i].v;
                }
            });
            reportJsonData.push(tempData);
        });
   }
    return reportJsonData;
}       

const getFooterRowData = (disptabelData: any, dbChartData: any, reportConfig: any) => {
  let footerRowData: any = [];
  if (dbChartData?.table?.cols && disptabelData) {
    footerRowData = [getTotalFooterDataForAggregate(disptabelData, dbChartData, reportConfig)];
  }
  return footerRowData;
}

const getTotalFooterDataForAggregate = (rows: any, dbChartData: any, runReportConfig: any) => {
  let aggregateColumnsData: any = {};
  let columnsList = dbChartData.table.cols;
  let treeColumn = runReportConfig.reportSettings.treeColumn;
  _.forEach(columnsList, function (colDef: any) {
      let aggregatedValue: number = 0;
      let groupHeaderRows: any[] = rows.filter((row: any) => row[treeColumn].length == 1);

      getAggregateFunctionForColumn(runReportConfig, colDef);
      
      if (colDef.functionName == "Sum") {
        aggregatedValue = _.sum(groupHeaderRows.map((row:any)=> +row[colDef.label]));
      } else if (colDef.functionName == "Count") {
        aggregatedValue = groupHeaderRows.length;
      } else if (colDef.functionName == "Avg") {
        aggregatedValue = _.sum(groupHeaderRows.map((row:any)=> +row[colDef.label])) / groupHeaderRows.length;
      } else if (colDef.functionName == "Min") {
        aggregatedValue = _.min(groupHeaderRows.map((row:any)=> +row[colDef.label])) ?? 0;
      } else if (colDef.functionName == "Max") {
        aggregatedValue = _.max(groupHeaderRows.map((row:any)=> +row[colDef.label])) ?? 0;
      }
      aggregatedValue = ((("" + aggregatedValue)?.indexOf(".") != -1) ? parseFloat(aggregatedValue.toFixed(2)) : parseFloat(aggregatedValue.toFixed(0)));
      aggregateColumnsData[colDef.label] = CommonUtils.isNotEmpty(colDef.functionName) ? aggregatedValue: "";
  });
  return aggregateColumnsData;
}