import _ from 'lodash';
import { handleStaticReportLinkClick } from '../../landingPage/controller/ReportsLandingPageController';
import { getStaticFilterValue, getStaticReportFields } from '../service/StaticReportUtil';
import { getReportType } from '../../view/report/service/ReportUtilService';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export const StaticReportFilterLink = (props: any) => {
    let content = props.content;
    let classes = props.classes;
    return (
        <div>
            <div
                key={`report-header-link-1`}
                className={classes.report_header_link}
                onClick={() => handleStaticReportLinkClick(content.reportConfig, content.reportFilterModel)}
            >
                <CreateLinkForStaticReports key={`create-link`} classes={classes} content={content} />
            </div>
            {/* <div key={`report-header-link-2`} className={classes?.asondate}>{`As On Date : ${dayjs().format(props?.dateFormatAsOnDate)}`}</div> */}
        </div>
    )
}
const CreateLinkForStaticReports = (props: any) => {
    let classes = props.classes;
    let staticFields: any = [];
    let translation = props?.content?.languageTranslation || {};
    let staticReportFields = getStaticReportFields(getReportType(props?.content?.reportConfig));
    staticReportFields.forEach((attr: any) => {
        let attrVal = getStaticFilterValue(props?.content?.reportConfig, attr.field);
        if (attrVal && attrVal !="NOPOP") {
            attr['value'] = attrVal;
            staticFields.push(attr);
        }
    })


    return (
        <span key={`header-link`} className={classes.report_header_link_main}>
            <span key={`start-parenthesis`} style={{ color: '#2d72b9' }}>
                {'( '}
            </span>
            <span key={`filter-icon`}>
                <FilterAltIcon sx={{ color: '#2d72b9', fontSize: '1.2rem' }} />
            </span>
            {staticFields?.map((rec: any, index: number) => (
                <>
                    <span key={`filterName-${index}`} className={classes?.report_header_link_main_title}>
                        { (translation[rec.header] || rec.header) + " "}
                    </span>
                    <span key={`filterRelationName-${index}`} className={classes?.report_header_link_main_relationName}>
                        {rec.value + " "}
                    </span>
                </>

            ))}
            <span key={`end-parenthesis`} style={{ color: '#2d72b9' }}>
                {')'}
            </span>
        </span>

    )
};

