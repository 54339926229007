import { getReportData } from '../../../../service/ReportUtilService';

export const makeTile = (tileViewReportModel: any) => {
  let eachGrid: any = {};
  let dbChartData = JSON.parse(tileViewReportModel.reportData);
  let tablerecords = getReportData(dbChartData, tileViewReportModel.reportConfig);
  let xaxis:any=tileViewReportModel?.reportConfig['horizontalAxis']??'';
  let yaxis:any=tileViewReportModel?.reportConfig['verticalAxis'][0]??'';
  let groupBy:any = tileViewReportModel?.reportConfig['groupingColumns'][0];
  tablerecords.forEach((record:any)=>{
    
    if(eachGrid[record[yaxis]]!==undefined && record[yaxis] in eachGrid){
      eachGrid[record[yaxis]]['count'] = eachGrid[record[yaxis]]['count']+1;
    }else{
      let obj:any = {};
      obj[yaxis] = record[yaxis];
      obj['count'] = 1;
      eachGrid[record[yaxis]] = obj;
    }
  })
  return eachGrid;
};

// tablerecords.forEach((record:any)=>{
//   let obj:any={};
//   if(!eachGrid.find((grid:any)=>{
//     if(grid['groupBy']===record[groupBy]){
//       grid['count'] = grid['count']+1;
//       return true;
//     }else{
//       return false;
//     }
//   })){
//     obj[yaxis] = record[yaxis];
//     obj['groupBy'] = record[groupBy]
//     obj['count'] = 1;
//     eachGrid.push(obj)
//   }
// })