import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class CustomTileReportModel extends KagamiReportModel {
  reportData: any;
  isDataLoaded:boolean;
  state: any;
  constructor(reportContent: any) {
    super()
    this.isDataLoaded = false;
    this.reportData = reportContent;
  }
}
export interface CustomTileReportModelProps {
  customTileReportModel: CustomTileReportModel;
}
export const useCustomTileReportModelState = (state: State<CustomTileReportModel>) => {
  let customTileReportState = useState(state);
  return {
    reportConfig() {
      customTileReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      customTileReportState.reportData.set(reportContent);
    },

    get isDataLoaded(){
      return customTileReportState.isDataLoaded.get()
    },

    setDataLoaded(isDataLoaded:boolean){
      customTileReportState.isDataLoaded.set(isDataLoaded);
    }
  };
};
