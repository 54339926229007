import { useCallback, useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import makeStyles from '@mui/styles/makeStyles';
import { createState } from '@hookstate/core';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import 'ag-grid-community/styles/ag-grid.css';
import { IGroupCellRendererParams, ITooltipParams,  ModuleRegistry, ValueFormatterParams } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  KagamiSlowApiLogModel,
  KagamiSlowApiLogModelProps,
  useKagamiSlowApiLogModelState
} from '../model/KagamiSlowApiLogModel';
import { SystemConstants } from '../../../../../../constants/SystemConstants';
import { KagamiSlowApiLogFilters } from './KagamiSlowLogApiFilters';
import {
  nestedChildClick,
  paginationPreviousPageHandler,
  paginationNextPageHandler,
  slowApiLogPagination
} from '../service/KagamiSlowApiLogService';
import { buildtotalRecodrs } from '../../../body/builder/process/builder/ProcessPageBuildler';
import { Typography } from '@mui/material';
import { KagamiFilterStyle } from '../style/KagamiSlowApiLog';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { CommonUtils } from '../../../../../../utils/CommonUtils';

const useStyles = makeStyles(KagamiFilterStyle);

ModuleRegistry.registerModules([ClientSideRowModelModule]);

export const slowApiLogBuilder = (kagamiSlowApiLogModel: KagamiSlowApiLogModel) => {
  return <KagamiSlowApiLog key={Math.random()} kagamiSlowApiLogModel={kagamiSlowApiLogModel} />;
};

export const KagamiSlowApiLog = (props: KagamiSlowApiLogModelProps) => {
  let slowLogApiState = useKagamiSlowApiLogModelState(createState(props?.kagamiSlowApiLogModel));
  props.kagamiSlowApiLogModel.state = slowLogApiState;
  const classes = useStyles();

  const handleCellClick = (data: any) => {
    nestedChildClick(data, props);
  };

  const gridOption: any = {
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressRowGroups: true
          }
        }
      ],
      defaultToolPanel: ''
    }
  };

  const columnDefs: any[] = [
    {
      field: '#',
      value: props?.kagamiSlowApiLogModel?.rowData.forEach((row: any, i: number) => (row['#'] = i + 1)),
      minWidth: 30,
      width: 40,
      pinned: 'left',
      maxWidth: 50,
      resizable: false,
      editable: false,
      sortable: false,
      cellStyle: { 'border-right': '1px solid rgb(215 223 241)' }
    },
    { field: 'logId', tooltipValueGetter: (p: ITooltipParams) => p.value },
    {
      field: 'startTime',
      valueFormatter: useCallback((params: any) => {
        return moment(params.value).format(SystemConstants.DD_MM_yyyy_HH_mm_ss);
      }, []),
      tooltipValueGetter: (p: ITooltipParams) => p.value
    },
    {
      field: 'endTime',
      valueFormatter: useCallback((params: any) => {
        return moment(params.value).format(SystemConstants.DD_MM_yyyy_HH_mm_ss);
      }, []),
      tooltipValueGetter: (p: ITooltipParams) => p.value
    },
    { field: 'event', tooltipValueGetter: (p: ITooltipParams) => p.value },
    { field: 'tmeDiff', tooltipValueGetter: (p: ITooltipParams) => p.value },
    {
      field: 'guid',
      tooltipValueGetter: (p: ITooltipParams) => p.value,
      hide: true
    },
    {
      field: 'ipAddress',
      tooltipValueGetter: (p: ITooltipParams) => p.value,
      hide: true
    },
    {
      field: 'threadId',
      tooltipValueGetter: (p: ITooltipParams) => p.value,
      hide: true
    },
    { field: 'userId', tooltipValueGetter: (p: ITooltipParams) => p.value },
    { field: 'windowId', tooltipValueGetter: (p: ITooltipParams) => p.value }
  ];

  const defaultColDef = {
    flex: 1,
    sortable: true,
    minWidth: 125,
    filter: 'agMultiColumnFilter',
    resizable: true,
    editable: false,
    cellStyle: { 'background-color': 'hsl(0deg 0% 100%)', 'color': 'hsl(0deg 0% 43.92%)' }
  };

  const detailCellRendererParams = useMemo<any>(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          {
            field: '#',
            value: props?.kagamiSlowApiLogModel?.rowData.forEach((row: any, i: number) => (row['#'] = i + 1)),
            minWidth: 30,
            width: 40,
            pinned: 'left',
            maxWidth: 50,
            resizable: false,
            editable: false,
            sortable: false,
            cellStyle: { 'border-right': '1px solid rgb(215 223 241)' }
          },
          { field: 'logId' },
          { field: 'startTime' },
          { field: 'endTime' },
          {
            field: 'startTime',
            valueFormatter: (params: ValueFormatterParams) => {
              return moment(params.value).format(SystemConstants.DD_MM_yyyy_HH_mm_ss);
            },
            tooltipValueGetter: (p: ITooltipParams) => p.value
          },
          {
            field: 'endTime',
            valueFormatter: (params: ValueFormatterParams) => {
              return moment(params.value).format(SystemConstants.DD_MM_yyyy_HH_mm_ss);
            },
            tooltipValueGetter: (p: ITooltipParams) => p.value
          },
          { field: 'event' },
          { field: 'guid' },
          { field: 'ipAddress' },
          { field: 'threadId' },
          { field: 'timeDiff' },
          { field: 'userId' },
          { field: 'windowId' }
        ],
        defaultColDef: {
          flex: 1
        },
        groupDefaultExpanded: 1,
        masterDetail: true,
        detailRowHeight: 40,
        suppressDoubleClickExpand: true
      },
      getDetailRowData: (params: any) => {
        if (params?.data?.children?.length > 0) {
          params.successCallback(params?.data?.children);
        } else {
          params.successCallback([]);
        }
      }
    } as IGroupCellRendererParams;
  }, []);

  const groupByEventcolumnDefs: any[] = [
    {
      field: '#',
      value: props?.kagamiSlowApiLogModel?.rowData.forEach((row: any, i: number) => (row['#'] = i + 1)),
      minWidth: 30,
      width: 40,
      maxWidth: 50,
      pinned: 'left',
      resizable: false,
      editable: false,
      sortable: false,
      cellStyle: { 'marginLeft': 0, 'border-right': '1px solid rgb(215 223 241)' }
    },
    {
      field: 'event',
      tooltipValueGetter: (p: ITooltipParams) => p.value,
      cellRendererSelector: (param: any) => {
        return {
          component: 'agGroupCellRenderer'
        };
      },
      onCellClicked: (param: any) => {
        handleCellClick(param?.data);
      }
    },
    {
      field: 'avgTime',
      tooltipValueGetter: (p: ITooltipParams) => p.value
    }
  ];

  return (
    <div style={{ height: '58%', width: '98%', margin: 'auto' }} className={`ag-theme-alpine kgm-grid `}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2 className={classes.header}>Slow Api(S)</h2>
        {CommonUtils.isNotEmpty(props?.kagamiSlowApiLogModel?.state?.totalRecords) ? (
          <div className={classes.totalRecords}>
            <Typography className={classes.rowCounter}>{buildtotalRecodrs(props?.kagamiSlowApiLogModel)}</Typography>
          </div>
        ) : (
          ''
        )}
      </div>
      <KagamiSlowApiLogFilters kagamiSlowApiLogModel={props?.kagamiSlowApiLogModel} />
      <p>Double Click On The Grid Cell, To Expand/Collapse Nested Grids</p>
      <AgGridReact
        suppressContextMenu={true}
        enableCellTextSelection={true}
        // paginationPageSizeSelector={props?.kagamiSlowApiLogModel?.state?.pageSize}
        rowData={props?.kagamiSlowApiLogModel?.state?.rowData}
        masterDetail={props?.kagamiSlowApiLogModel?.state?.groupByEvent ? true : false}
        columnDefs={props?.kagamiSlowApiLogModel?.state?.groupByEvent ? groupByEventcolumnDefs : columnDefs}
        detailCellRendererParams={props?.kagamiSlowApiLogModel?.state?.nestedEvent ? detailCellRendererParams : null}
        defaultColDef={defaultColDef}
        gridOptions={gridOption}
      />
      <div style={{ display: 'flex', position: 'relative' }}>
        {paginationPageSizeSelector(props)}
        {paginationStatusBar(props)}
      </div>
    </div>
  );
};

export function paginationPageSizeSelector(props: any) {
  let paginationPageSizeSelector = [10, 20, 50, 100];

  const pageSizeChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e?.target?.value;
    props?.kagamiSlowApiLogModel?.state?.setPaginationPageSizeSelector(value);
    props.kagamiSlowApiLogModel.isPaginationPageSelector = true;
    let params = {
      pageNumber: 0,
      pageSize: value
    };
    slowApiLogPagination(props, params);
  };

  return (
    <div style={{ display: 'flex', position: 'absolute', right: '290px', top: '14px' }}>
      <select value={props?.kagamiSlowApiLogModel?.state?.paginationPageSizeSelector} onChange={pageSizeChangeHandler}>
        {paginationPageSizeSelector?.map((item, i) => {
          return <option value={item}>{item}</option>;
        })}
      </select>
    </div>
  );
}

export function paginationStatusBar(props: any) {
  return (
    <div style={{ display: 'flex', position: 'absolute', right: '0px' }}>
      <div>
        <p>
          Records [
          {Number(props?.kagamiSlowApiLogModel?.state?.pageNumber) *
            Number(props?.kagamiSlowApiLogModel?.state?.pageSize) +
            1}
          ] to [
          {(Number(props?.kagamiSlowApiLogModel?.state?.pageNumber) + 1) *
            Number(props?.kagamiSlowApiLogModel?.state?.pageSize) >
          Number(props?.kagamiSlowApiLogModel?.state?.totalElements)
            ? props?.kagamiSlowApiLogModel?.state?.totalElements
            : (Number(props?.kagamiSlowApiLogModel?.state?.pageNumber) + 1) *
              Number(props?.kagamiSlowApiLogModel?.state?.pageSize)}
          ] of [
          {props?.kagamiSlowApiLogModel?.state?.totalElements ? props?.kagamiSlowApiLogModel?.state?.totalElements : 0}]
        </p>
      </div>
      <div style={{ display: 'flex' }}>
        <button
          style={{ marginTop: '16px', height: '15px', width: '20px' }}
          disabled={Number(props?.kagamiSlowApiLogModel?.state?.pageNumber) === 0}
          onClick={(e) => paginationPreviousPageHandler(props)}
        >
          <KeyboardArrowLeftIcon style={{ marginTop: '-7px', marginLeft: '-10px' }} />
        </button>
        <p>
          Page [{Number(props?.kagamiSlowApiLogModel?.state?.pageNumber) + 1} ] of [
          {props?.kagamiSlowApiLogModel?.state?.totalPages
            ? Number(props?.kagamiSlowApiLogModel?.state?.totalPages)
            : 0}
          ]
        </p>
        <button
          style={{ marginTop: '16px', height: '15px', width: '20px' }}
          disabled={
            Number(props?.kagamiSlowApiLogModel?.state?.pageNumber) + 1 ===
            Number(props?.kagamiSlowApiLogModel?.state?.totalPages)
          }
          onClick={(e) => paginationNextPageHandler(props)}
        >
          <KeyboardArrowRightIcon style={{ marginTop: '-7px', marginLeft: '-10px' }} />
        </button>
      </div>
    </div>
  );
}
