import _ from 'lodash';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { exportShilpaPerformanceReport, getFilterSelectedHeader } from '../../../../service/KagamiReportService';
import { getObjectForReportData, getReportData, getReportName, getValueInDateTimeFormatAsAppSetting, handleExcelFilterConfig, reportColumnDisplayFormatter } from '../../../../service/ReportUtilService';
import { saveAs } from "file-saver";
import { handleDrillDownReport } from '../../../../service/ReportDrillDownService';
import { SystemConstants } from '../../../../../../../../../../../../constants/SystemConstants';

export function  makePivot(pivotReportController: any, gridRef: any) {
  let defaultConfig ={
    autoGroupColumnDef: {  minWidth: 250,
      cellRendererParams: {
        suppressCount: true,
        checkbox: false,
        footerValueGetter: (params:any) => {
          const isRootLevel = params.node.level === -1;
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      }
    },   
    columnDefs: [],
    defaultColDef: { flex :1,sortable: false,minWidth:150,filter: true,resizable: true,editable: false },
    groupIncludeFooter: false,
    groupIncludeTotalFooter: true,
    groupDefaultExpanded: -1,//expand everything by default
    groupHideOpenParents:true,
    getRowHeight :(params: any) => 30,
    pivotColumnGroupTotals :'after',//before
    pivotRowTotals:'after',//before ,after
    pivotMode : true,
    rowGroupPanelShow :'always',
    rowData:[],
    sideBar : {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 225,
          width: 225,
          maxWidth: 225,
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
      // position: 'left',
      defaultToolPanel: ''
    },
    suppressExpandablePivotGroups :true,
    suppressAggFuncInHeader: true, //hide aggregate name in a row 
    ExcelStyles: [
      {
        id: "default",
        alignment: {
          horizontal: 'Center', vertical: 'Center'
        },
        font: { color: "#161313", size: 11 },
        interior: {
          color: "#f3f7fb", pattern: 'Solid'
        }
      },
      {
        id: 'fontBold',
        font: { bold: true },
      }
    ]
  };
  
  
  let userConfig = prapareUserConfigForPivot(pivotReportController,defaultConfig ,gridRef);  
  let dbChartData = JSON.parse(pivotReportController.reportData);
  // let disptabelData = getPivotData(dbChartData);
  let reportConfig = pivotReportController.reportConfig;
  let disptabelData = getReportData(dbChartData, reportConfig);
  if(reportConfig.reportSettings) {
      let otherSettings:any = reportConfig.reportSettings;
      if(otherSettings.showGrandTotals){
        if (otherSettings.showGrandTotals == 'off') {
          defaultConfig.pivotRowTotals = '';
          defaultConfig.groupIncludeTotalFooter = false;
        } else if (otherSettings.showGrandTotals == 'rows') {
          defaultConfig.groupIncludeTotalFooter = true;
          defaultConfig.pivotRowTotals = '';
        } else if (otherSettings.showGrandTotals == 'columns') {
            defaultConfig.pivotRowTotals = 'after';
            defaultConfig.groupIncludeTotalFooter = false;
        }

        // if(otherSettings.showGrandTotals=="rows" || otherSettings.showGrandTotals == "on"){
        //   defaultConfig.groupIncludeTotalFooter = true;
        // }
    }
    if(otherSettings.showTotals){
         if(otherSettings.showTotals=="rows" || otherSettings.showTotals == "on" ){
          defaultConfig.groupIncludeFooter = true;
        }
    }
    if(otherSettings.customColConfig) {
        disptabelData =  manipulatePivotData(reportConfig, disptabelData,otherSettings.customColConfigList);
    }
    updatePivotExcelStyles(defaultConfig,otherSettings);
  }
  userConfig['rowData'] = disptabelData;
  let pivotConfig = _.extend(true, {}, defaultConfig, userConfig);
  return pivotConfig;
}
  function updatePivotExcelStyles(defaultConfig: any, otherSettings: any) {
    if (otherSettings?.excelHeaderRows?.length) {
      _.forEach(otherSettings.excelHeaderRows, function (headerRow, index) {
        defaultConfig.ExcelStyles.push({
          id: 'filterID' + index,
          font: { bold: headerRow?.styles?.font?.bold || false, size: headerRow?.styles?.font?.size || 14 },
        })
      })
    }
  }

  function prapareUserConfigForPivot (pivotReportController: any,defaultConfig:any ,gridRef:any) {
    let userConfig = {
      columnDefs: [],
      rowData: [],
    };

    let xAxis = pivotReportController.reportConfig.verticalAxis;
    let yAxis = pivotReportController.reportConfig.horizontalAxis;
    let cell = pivotReportController.reportConfig.matrixAttributes;
    let columnDefs: any = [];
    let orderColumns = pivotReportController?.reportConfig?.criteria.ordersTypes|| [];
    _.each(xAxis, function (field) {
      let temp:any  = {
        field: field, headerName: getColumnName(field, pivotReportController),
        pivot: true,
        sortable: false,
        // pivotComparator: (a: any, b: any) => {
        //   return a - b;
        // },
        //  enablePivot: true,
      };
      if (_.filter(orderColumns, { name: field })?.length <= 0) {
        temp['pivotComparator'] = (a: any, b: any) => {
          return gridPivotComparator(a, b, field, gridRef);
        }
      }
      columnDefs.push(temp);
      makeDrillDownLink(field, pivotReportController, defaultConfig)
    });
    _.each(yAxis, function (field) {
      columnDefs.push({
        field: field,
        headerName: getColumnName(field, pivotReportController),
        rowGroup: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: false,
      });
      makeDrillDownLink(field, pivotReportController, defaultConfig)
    });
    _.each(cell, function (field) {
      columnDefs.push({ field: field,sortable: false, headerName: getColumnName(field,pivotReportController), 
      // aggFunc:'sum',
      aggFunc: (params:any) => {
        let total: any = 0;
        var columnType = getColumnType(params?.colDef?.field, pivotReportController);
        if (columnType === 'Number') {
          params?.values?.forEach((value: any) => total += value);
        } else {
          if (params?.values && params?.values?.length > 0) {
            params.values.forEach((value: any, paramsIndex :any) => {
              if (!isNaN(parseFloat(value))) {
                total += parseFloat(value);
              }
            });
            if (!total && params.rowNode?.level != 0) {
              total = params.values[0];
            }
          }

          // if column type is not number, default value should be Empty
          if(total === 0) total = SystemConstants.EMPTY;
        }
		
        if(total && (total + "").indexOf(".") != -1){
        	total = parseFloat(Number.parseFloat(total).toFixed(2));
        	return reportColumnDisplayFormatter(pivotReportController?.reportConfig, total, params?.colDef?.field, '');
        }
        return reportColumnDisplayFormatter(pivotReportController?.reportConfig, total, params?.colDef?.field, '');
      },
      cellRenderer: (params:any) => {
        return (params.value || params.value == 0) ? params.value : '-';
      },
      });
    });
    userConfig['columnDefs'] = columnDefs;
    return userConfig;
  }

  function getColumnName(field:any,pivotReportController:any){
    if(pivotReportController?.reportConfig?.criteria?.columns){
      let column = _.filter(pivotReportController.reportConfig.criteria.columns,{key:field});
      if(column && column.length){
        return CommonUtils.makeFirstLetterCapital(column[0]['name']) 
      }
    }
    return field || '';    
  }

  function getColumnClass(field:any,pivotReportController:any){
    if(pivotReportController?.reportConfig?.criteria?.columns){
      let column = _.filter(pivotReportController.reportConfig.criteria.columns,{name:field});
      if(column?.length){
        return column[0]['columnReport'] ? ['drilldown-report-link'] : [''];
      }
    }
    return  '';    
  }
  function makeDrillDownLink (field:any, pivotReportController:any,defaultConfig:any) {
    if (isDrillDownReportColumn(field, pivotReportController) && defaultConfig.autoGroupColumnDef) {
      defaultConfig.autoGroupColumnDef['cellStyle'] = function (params: any) {
        if(params?.column?.colDef?.headerName == field){
          return { color: 'blue' };
        }
      }
    }
  }

  function isDrillDownReportColumn(field:any,pivotReportController:any){
    if(pivotReportController?.reportConfig?.criteria?.columns){
      let column = _.filter(pivotReportController.reportConfig.criteria.columns,{name:field});
      if(column?.length && column[0].name == field && column[0].columnReport ){
        return true;
      }
    }
    return  false;  
  }


  function getColumnType(field:any,pivotReportController:any){
    if(pivotReportController?.reportConfig?.criteria?.columns){
      let column = _.filter(pivotReportController.reportConfig.criteria.columns,{key:field});
      if(column?.length == 0){
        column = _.filter(pivotReportController.reportConfig.criteria.columns,{name:field});
      }
      if(column && column.length){
        return column[0]['type']
      }
    }
    return '';    
  }

//   function getPivotData(dbChartData:any) {
//     let pivotJsonData:any = [];
//     if( dbChartData.table){
//         let keys=_.map( dbChartData.table.cols,"label");
//         dbChartData.table.rows.map(function (row:any) {
//             let tempData :any = {};
//             _.map(keys, function (key, i) {
//             tempData[key] = row.c[i].v;
//             });
//             pivotJsonData.push(tempData);
//         });
//    }
//     return pivotJsonData;
// }        

function manipulatePivotData(reportConfig:any,disptabelData:any,customColConfigList:any) {
  let additionalRec:any = [];
  if(customColConfigList && customColConfigList.length > 0){
      let horizontalAxis =reportConfig.horizontalAxis; 
      let verticalAxis =reportConfig.verticalAxis; 
      let matrixAttributes =  reportConfig.matrixAttributes; 
      let horizontalAxisObj = _.groupBy(disptabelData,horizontalAxis[0]);
      let horizontalAxisValArrays = _.values(horizontalAxisObj); 

      _.each(customColConfigList, function(col){
          for(let i=0;i<horizontalAxisValArrays.length;i++){
              //add additional columns as grandtotals for right menu 
              let  eachRec:any = Object.assign({},horizontalAxisValArrays[i][0]); 
              //eachRec[verticalAxis[0]] ="Totals";
              //eachRec[verticalAxis[1]] =customColConfigList[0]
              eachRec[verticalAxis[0]] = col;
              eachRec[verticalAxis[1]] ="";
              eachRec[matrixAttributes[0]] =eachRec[col];
              additionalRec.push(eachRec);
              //end additional columns
          }
      });
  }
  return disptabelData.concat(additionalRec);
}
export const downloadPivotExcel = (gridRef: any, pivotReportModel: any) => {
  let configInfo: any = {
    excelHeaderRows: _.cloneDeep(pivotReportModel?.reportConfig?.reportSettings?.excelHeaderRows),//custom headers with styles
  }
  if (configInfo?.excelHeaderRows?.length) {
    handleExcelFilterConfig(pivotReportModel?.reportConfig, pivotReportModel, configInfo?.excelHeaderRows);
    _.remove(configInfo.excelHeaderRows, function (item: any) { return item?.filterValue == 'All' && item?.filterHideNoneSelection == true })
  }
  let excelHeaderConent = [];
  if (!(configInfo.excelHeaderRows?.length)) {
    excelHeaderConent.push(getExcelRow(getReportName(pivotReportModel), { excelStyles: ['fontBold'] }));
  }
  if (configInfo.excelHeaderRows?.length) {
    _.filter(configInfo.excelHeaderRows, function (headerRow, index) {
      if (headerRow?.title) {
        excelHeaderConent.push(getExcelRow(headerRow.title, { excelStyles: ['filterID' + headerRow.index] }));
      }
    })
  }
  excelHeaderConent.push(getExcelRow("As On Date : " + getValueInDateTimeFormatAsAppSetting(new Date(), 'datetime'), { excelStyles: ['fontBold'] }));
  let fileName = getReportName(pivotReportModel) || 'File';
  gridRef?.current?.api?.exportDataAsExcel(
    {
      prependContent: excelHeaderConent || [],
      fileName: fileName,
      sheetName: fileName,
      processCellCallback(params: any) {
        const value = params.value;
        return value === undefined ? '' : `${value}`;
      },
      processRowGroupCallback(params: any) {
        const { node } = params;

        if (!node.footer) {
          return `${node.key}`;
        }
        const isRootLevel = node.level === -1;

        if (isRootLevel) {
          return 'Grand Total';
        }
        return `Sub Total (${node.key})`;
      },
    }
  );
}
const getExcelRow = (val: any,excelConfig?:any) => {
  return {
    "cells": [
      {
        mergeAcross: 8,
        "data": {
          "value": val,
          "type": "String",
        },
        styleId: ['default'].concat(excelConfig?.excelStyles || []),
      }
    ]
  }
}

export const onDetailedExcel = async (gridRef: any, pivotReportModel: any) => {
  let reportData: any = {
    reportId: pivotReportModel.reportConfig.reportConfigSummary.id,
    queryParam: JSON.stringify([{}]),
    offset: 330
  };
  if (CommonUtils.isNotEmpty(pivotReportModel.reportFilterModel)) {
    reportData = getObjectForReportData(pivotReportModel)
  }
  let response = await exportShilpaPerformanceReport(reportData);

  let fileName = pivotReportModel.reportConfig.reportConfigSummary.name + ".xlsx";
  const byteCharacters = atob(response.data.data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  let blob = new Blob([byteArray], { type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, fileName);
}

export const onPivotReportRowClick = function (event:any,pivotReportModel:any) {
  let headerName = event.api.getFocusedCell().column.colDef.headerName;
	let colId	= event.api.getFocusedCell().column.colId;
	let colValue	= event.node.groupData[colId];

  let colNames = _.map(pivotReportModel.reportConfig.criteria.columns,'name');
   let record:any = {}
  _.forEach(colNames,function(col) {
    record[col] =  event.node.groupData["ag-Grid-AutoColumn-"+col];
  })
  let currentColArr = _.filter(pivotReportModel.reportConfig.criteria.columns,{name:headerName});
  if(currentColArr?.length && currentColArr[0].columnReport) {
    handleDrillDownReport({data:record},pivotReportModel,pivotReportModel.reportConfig,colValue,headerName);
  }
}
export const gridPivotComparator = (start: string, end: string, field: string, gridRef: any) => {
  let colData = gridRef?.current?.props?.rowData ? gridRef.current.props.rowData : [];
  let requiredOrder = _.map(colData, field);
  return requiredOrder.indexOf(start) - requiredOrder.indexOf(end);
}
