import { createState, useState } from '@hookstate/core';
import * as React from 'react';
import { buildLedgerDetailsModal } from '../../modal/controller/ledgerDetailsModalController';
import { handleOnLedgerClick } from '../controller/SubLedgerReportController';
import { SubLedgerReportModel, SubLedgerReportModelProps, useSubLedgerReportModelState } from '../model/SubLedgerReportModel';
import { getData, handledColumnsBasedOnProjects, makeFooterRowData, makeLedgerData, makeReport, onChangeAccount, onChangeControlAccount, onChangeDependedValue, onChangeState } from '../service/SubLedgerReportService';
import { StaticReportActions } from '../../../../../../staticReportFilters/ui/StaticReportActions';
import { StaticReportGrid } from '../../../../../../staticReportFilters/ui/StaticReportGrid';

export const BuildSubLedgerReportView = (subLedgerReportModel: SubLedgerReportModel) => {
  return (
    <SubLedgerReportView key={'sub-ledger-report'} subLedgerReportModel={subLedgerReportModel}></SubLedgerReportView>
  );
};


const SubLedgerReportView = (props: SubLedgerReportModelProps) => {
  const onLedgerDetails = (row: any) => {
    let ledgerInputs: any = {
      invoiceNo: row.data.docno,
      transType: row.data.transtype,
      reportType: inputReportConfig.reportConfigSummary.type,
      currency: undefined,
      translation: props?.subLedgerReportModel?.languageTranslation || {}
    };

    handleOnLedgerClick(ledgerInputs, row);
  }
  let runReportConfig: any = useState({
    loadReport: false,
    loadFilterData: true,
    showLoader: false,
    reportInfo: {}
  });
  let subLedgerReportModel = props.subLedgerReportModel;

  let subLedgerChartState = useSubLedgerReportModelState(createState(subLedgerReportModel));
  subLedgerReportModel.state = subLedgerChartState;
  let staticFilterData: any;
  if (subLedgerReportModel?.reportData?.staticFilterData) {
    staticFilterData = subLedgerReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo: any = {}
  if (subLedgerReportModel?.reportData?.gridReportInfo) {
    gridReportInfo = subLedgerReportModel.reportData['gridReportInfo'];
  }

  let inputReportConfig = (props.subLedgerReportModel.reportConfig);

  React.useEffect(() => {
    runReport();
  }, [])


  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo = makeReport(subLedgerReportModel, inputReportConfig);
    let ledgerData = await getData(subLedgerReportModel, inputReportConfig);
    let rowData = await makeLedgerData(subLedgerReportModel, ledgerData);
    let footerRowData = await makeFooterRowData(subLedgerReportModel, ledgerData);
    handledColumnsBasedOnProjects(reportInfo, ledgerData);
    reportInfo['pinnedBottomRowData'] = footerRowData;
    reportInfo['rowData'] = rowData;
    reportInfo['dbLedgerData'] = ledgerData;
    const obj = Object.assign({}, reportInfo);
    props.subLedgerReportModel.reportData['gridReportInfo'] = obj;
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }
  let config = { reportModel: subLedgerReportModel, inputReportConfig, gridReportInfo, runReportConfig, onLedgerDetails };

  return (
    <div className="Report-MuiFormControl-root">
      {buildLedgerDetailsModal(props.subLedgerReportModel.modelPopup)}
      <StaticReportActions config={config} ></StaticReportActions>
      <StaticReportGrid config={config}></StaticReportGrid>
    </div>
  );
};
