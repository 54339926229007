import React from 'react';
import { getFavoritesPanel, getLeftPanel } from '../service/NavigatorService';
import { Menu, MenuItem } from 'react-pro-sidebar';

import dashboard from '../../../../../../assets/images/navbaricons/dashboardIcon.svg';
import reports from '../../../../../../assets/images/navbaricons/reportsIcon.svg';
import masterdata from '../../../../../../assets/images/navbaricons/masterData.svg';
import { showBody } from '../../../controller/KagamiHomeController';
import { renderReportPage } from '../../body/controller/KagamiBodyController';
import { buildMenuFolder } from './Menu/controller/KagamiMenuFolderController';

import { KagamiFolderModel } from './Menu/model/KagamiFolderModel';
import { getUserProfileData } from '../../header/controller/KagamiPageHeaderController';
import { CustomImageComponent } from './Menu/ui/KagamiMenuFolder';
import { isEnableTabs, translateToLocalistaion } from '../../body/builder/process/service/PresentationService';
import { KagamiStateManager } from '../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../constants/SystemConstants';
import { KagamiTabNavigationModel } from '../../header/Tabs/model/KagamiTabNavigationModel';
import { tabExistAndContentFromState } from '../../header/Tabs/ui/KagamiTabNavigation';
import { appContext } from '../../body/builder/process/service/PolicyExecutionService';
import { executeProcess } from './Menu/controller/KagamiMenuController';

export const buildNavigatorSection = (
  navigationData: any,
  MasterDatapanel: any,
  tabNavigationModel: KagamiTabNavigationModel
) => {
  const handleTab = (processName: string, name: string) => {
    let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
    let tabs = JSON.parse(JSON.stringify(tabNavigationState.getTabs));
    let isTabAlreadyExist = tabs?.findIndex((item: any) => item?.processName === processName);
    let translations = navigationData?.otherProperties?.translation;
    let newName = translateToLocalistaion(translations, name);
    name = newName;
    if (isTabAlreadyExist !== -1) {
      tabExistAndContentFromState(processName, name);
    } else if (processName === 'Reports') {
      tabs?.push({ processName, name });
      tabNavigationState.setTabs(tabs);
      if (tabs?.length <= 10) {
        tabNavigationState.setActiveTab(processName);
        renderReportPage();
      }
    }
  };

  const addTab = (process: any) => {
    if(isEnableTabs(appContext)) {
      handleTab(process?.processName, process?.name);
    } else {
      executeProcess(process?.processName);
    }
  };

  const components: any[] = [];

  let dashboard = <DashboardMenu key={'way-to-dashboard'} addTab={addTab} navigationData={navigationData} />;
  let report = <ReportMenu key={'way-to-reports'} addTab={addTab} navigationData={navigationData} />;
  let mainMenus: KagamiFolderModel[] = getLeftPanel(navigationData);
  let masterData: KagamiFolderModel[] = [MasterDataMenu(MasterDatapanel)];
  let favourites = getFavoritesPanel(navigationData);
  let individualMenuFolders = (
    <BuildMenuFolder
      key="way-to-mainmenus"
      mainmenus={mainMenus}
      masterdata={masterData}
      favouriteData={favourites}
      tabNavigationModel={tabNavigationModel}
      navigationData={navigationData}
    />
  );

  components.push(dashboard);
  components.push(individualMenuFolders);
  // components.push(dataMigration)
  components.push(report);
  return components;
};

const BuildMenuFolder = React.memo((props: any) => {
  const { mainmenus, masterdata, favouriteData, navigationData, tabNavigationModel } = props;
  let consolidatedMenuItems: any;
  const currentUser = getUserProfileData();
  if (currentUser && currentUser.userId != 'admin') {
    consolidatedMenuItems = mainmenus;
  } else {
    consolidatedMenuItems = [...mainmenus, ...masterdata];
  }
  return buildMenuFolder(consolidatedMenuItems, favouriteData, navigationData, tabNavigationModel);
});
const MasterDataMenu = (MasterDatapanel: any) => {
  const masterDatamenu = new KagamiFolderModel(
    true,
    false,
    masterdata,
    'Master Data',
    MasterDatapanel,
    'group',
    undefined
  );
  return masterDatamenu;
};

const DashboardMenu = (props: any) => {
  let translations = props.navigationData?.navigationData?.otherProperties?.translation;
  return (
    <Menu>
      <MenuItem
        icon={<CustomImageComponent image={dashboard} title={translateToLocalistaion(translations, 'Dashboard')} />}
        onClick={() => isEnableTabs(appContext) ? props?.addTab({ processName: 'Dashboard', name: 'Dashboard' }) : showBody() }
      >
        {translateToLocalistaion(translations, 'Dashboard')}
      </MenuItem>
    </Menu>
  );
};

const ReportMenu = (props: any) => {
  let translations = props.navigationData?.navigationData?.otherProperties?.translation;
  return (
    <Menu>
      <MenuItem
        icon={<CustomImageComponent image={reports} title={translateToLocalistaion(translations, 'Reports')} />}
        onClick={() =>  isEnableTabs(appContext) ? props?.addTab({ processName: 'Reports', name: 'Reports' }) : renderReportPage()}
      >
        {translateToLocalistaion(translations, 'Reports')}
      </MenuItem>
    </Menu>
  );
};
