import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class ColumnReportModel extends KagamiReportModel {
  reportData: any;
  state: any;
  isDataLoaded: boolean;
  contentReport: any;
  isContentReportLoaded: boolean;
  additionalReport: any;
  additionalReportContent: any;
  isAdditionalReportLoaded: boolean;
  constructor(reportContent: any) {
    super()
    this.reportData = reportContent;
    this.isDataLoaded = false;
    this.isContentReportLoaded = false;
    this.isAdditionalReportLoaded = false;
  }
}
export interface ColumnReportModelProps {
  columnReportModel: ColumnReportModel;
}
export const useColumnReportModelState = (state: State<ColumnReportModel>) => {
  let columnReportState = useState(state);
  return {
    reportConfig() {
      columnReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      columnReportState.reportData.set(reportContent);
    },

    get isContentReportLoaded() {
      return columnReportState.isContentReportLoaded.get()
    },
    setContentReport(isContentReportLoaded: boolean) {
      columnReportState.isContentReportLoaded.set(isContentReportLoaded);
    },
    get isAdditionalReportLoaded() {
      return columnReportState.isAdditionalReportLoaded.get()
    },
    setAdditionalReportContent(isAdditionalReportLoaded: boolean) {
      columnReportState.isAdditionalReportLoaded.set(isAdditionalReportLoaded);
    },

    get isDataLoaded() {
      return columnReportState.isDataLoaded.get()
    },

    setDataLoaded(isDataLoaded: boolean) {
      columnReportState.isDataLoaded.set(isDataLoaded);
    }
  };
};
