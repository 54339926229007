import { createState } from '@hookstate/core';
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
} from '@mui/material';
import {
  ExpandedEmbeddedPresentationModelProps,
  KagamiExpandedEmbeddedPresentationModel
} from '../../expanded-embedded-presentation/model/KagamiExpandedEmbeddedPresentationModel';
import { buildFormPresentation } from '../../../../form-presenation/controller/KagamiFormPresentationController';
import { buildEmbeddedModel } from '../../../../FormBuilder';
import {
  addRecord,
  editRecord,
  handleEmbeddedModalCancel,
  handleEmbeddedModalClose,
  removeRecord
} from '../controller/KagamiEmbeddedAccordionPresentationController';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { KagamiEmbedAccordionProps } from '../model/KagamiEmbeddedAccordionPresentationModel';
import { buildEmbedTrigger } from './Embed-Triggers/controller/KagamiEmbedTriggerController';
import { buildEmbeddedTriggerModel } from './Embed-Triggers/service/EmbeddedTriggersService';
import { EmbeddedModalModelProps, KagamiEmbeddedModalModel } from './embedded-modal/model/KagamiEmbeddedModalModel';
import CloseIcon from '@mui/icons-material/Close';
import { buildExpandedEmbeddedPresentation } from '../../expanded-embedded-presentation/controller/KagamiExpandedEmbeddedPresentationController';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { callPoliciesForEmbedTriggers, getFormModel, toggleEmbedAccordion } from '../../../../../controller/KagamiFormController';
import { buildGrid } from '../../../../../../list/builder/grid/controller/KagamiGridController';
import { VisibleConstants } from '../../../../../../../../../../../../../constants/VisibeConstants';
import {
  getBusinessPolicy,
  getDefaultEmbeddedFormView,
  getFormPresentationFormAttribute,
  getHeaderForPresentation,
  getMappedEntityIdForPresentation,
  getPresenationIdFromPresentation,
  getPresentationPolicy,
  isListPresentation,
  isMandatoryPresentation,
  isOnCancelRequiredForPresentation,
  isOnLoadRequiredForPresentation,
  isVisiblePresentation,
  retrievePresentation,
  retrievePresentationsForEntity,
  retrievePresentationsFromUIResource,
  translateToLocalistaion
} from '../../../../../../../service/PresentationService';
import { KagamiGridModel } from '../../../../../../list/builder/grid/model/KagamiGridModel';
import { getEmbedEndRecord, getEmbedStartRecord, getEmbedTotalRecords, getEndRecord, getListTotalRecords, getStartRecord, hasReadOnlyColumns } from '../../../../../../list/service/kagamiListService';
import { KagamiGridPaginationModel } from '../../../../../../list/builder/grid/builder/pagination/model/KagamiGridPaginationModel';
import { KagamiEmbeddedFormPresentationModel } from '../../../../../model/KagamiEmbeddedFormPresentationModel';
import { buildEmbeddedPresentation } from '../../../controller/KagamiEmbeddedPresentationController';
import { KagamiEmbeddedPresentationModel } from '../../../model/KagamiEmbeddedPresentationModel';
import { KagamiEmbeddedModalStyle } from './embedded-modal/style/KagamiEmbeddedModalStyle';
import makeStyles from '@mui/styles/makeStyles';
import { getActiveContext, getFormRecords, getRecordId, isAllowableTemplate, updateInFocusMap } from '../../../../../service/FormService';
import { KagamiFormModel } from '../../../../../model/KagamiFormModel';
import { buildGridControlModels } from '../../../../../../list/builder/grid/service/GridService';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import { KagamiTriggersModel } from '../../../../../../Triggers/model/KagamiTriggersModel';
import { handleTriggerClick } from '../../../../../../list/controller/KagamiListController';
import { BuildNonFieldPresentations } from '../../../../NonFieldPresentationBuilder';
import { isMatrixFormTrue } from '../../../service/MatrixPresentationService';
import { embeddedRowCountController } from '../embedded-rowcount/controller/EmbeddedRowCountController';
import { getActiveModel } from '../../../../../../../controller/KagamiProcessController';
import cloneDeep from 'lodash/cloneDeep';
import { appContext } from '../../../../../../../service/PolicyExecutionService';

export function getEmbeddedSectionContent(embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel, readOnly: boolean) {
  let isMatrixForm: boolean = false;
  isMatrixForm = isMatrixFormTrue(embeddedFormPresentationModel);
  if (isMatrixForm) {
   return null;
  }
  else {
    return embeddedFormPresentationModel.isListPresentation
      ? buildEmbeddedListPresentation(embeddedFormPresentationModel, readOnly)
      : buildEmbeddedFormPresentation(embeddedFormPresentationModel);
  }
}

export function buildEmbeddedListPresentation(embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel, readOnly: boolean) {
  let expandedEmbeddedModel = buildExpandedEmbedModel(embeddedFormPresentationModel);
  expandedEmbeddedModel.nestedEmbeddedModels = buildNestedEmbedModels(embeddedFormPresentationModel);
  expandedEmbeddedModel.initialEmbedFormPresentationModel = embeddedFormPresentationModel
  let formModel = getFormModel();
  // getFormModel().formControls[expandedEmbeddedModel.formEntityId] = expandedEmbeddedModel;
  let paginationModel = new KagamiGridPaginationModel(
    false,
    false,
    getEmbedTotalRecords(embeddedFormPresentationModel?.onStartData, embeddedFormPresentationModel?.formEntityId),
    getEmbedStartRecord(embeddedFormPresentationModel?.onStartData, embeddedFormPresentationModel?.formEntityId),
    getEmbedEndRecord(embeddedFormPresentationModel?.onStartData, embeddedFormPresentationModel?.formEntityId),
    embeddedFormPresentationModel?.mainEntityId,
    embeddedFormPresentationModel?.processName,
    retrievePresentation(embeddedFormPresentationModel?.onStartData)
  )
    let kagamiGridModel: KagamiGridModel = new KagamiGridModel(
    embeddedFormPresentationModel?.processName,
    embeddedFormPresentationModel?.processStepName,
    embeddedFormPresentationModel?.formEntityId,
    embeddedFormPresentationModel?.presentation,
    paginationModel
  );
  if (CommonUtils.isNotEmpty(appContext) && CommonUtils.isNotEmpty(appContext.AppSetting[0])) {
    kagamiGridModel.embeddedPageSize = appContext.AppSetting[0]?.embeddedPageSize;
  }
  let totalPages = Number(paginationModel?.totalRecords) / Number(kagamiGridModel?.embeddedPageSize);
  kagamiGridModel.totalPages = Math.ceil(totalPages);
  buildGridControlModels(kagamiGridModel,undefined,formModel);
  if (embeddedFormPresentationModel.isNested) kagamiGridModel.parentEmbedIdForNestedEmbed = embeddedFormPresentationModel.parentEmbedIdForNestedEmbed
  kagamiGridModel.defaultEmbedFormView = getDefaultEmbeddedFormView(formModel);
  kagamiGridModel.kagamiModalModel.embeddedFormPresentationModel = expandedEmbeddedModel;
  kagamiGridModel.onLoadData = cloneDeep(embeddedFormPresentationModel.onLoadData);
  // kagamiGridModel.presentation = embeddedFormPresentationModel.presentation;
  kagamiGridModel.onStartData = embeddedFormPresentationModel.onStartData;
  kagamiGridModel.totalRecords = getEmbedTotalRecords(embeddedFormPresentationModel.onStartData,embeddedFormPresentationModel.formEntityId)
  kagamiGridModel.detailedObjects = getDetailedObjectsForGridModel(embeddedFormPresentationModel, embeddedFormPresentationModel.formEntityId)//embeddedFormPresentationModel.onLoadData
  kagamiGridModel.nestedEmbedModels = expandedEmbeddedModel.nestedEmbeddedModels;
  kagamiGridModel.isMasterDetailEnabled = isMasterDetailEnabled(kagamiGridModel.nestedEmbedModels)//kagamiGridModel.nestedEmbedModels.length > 0;
  kagamiGridModel.isListLoaded = true;
  kagamiGridModel.isReadOnly = readOnly;
  embeddedFormPresentationModel.kagamiGridModels.set(embeddedFormPresentationModel.formEntityId, kagamiGridModel);
  expandedEmbeddedModel.kagamiGridModels.set(embeddedFormPresentationModel.formEntityId, kagamiGridModel);
  kagamiGridModel.triggersModel = new KagamiTriggersModel(
    kagamiGridModel.mainEntityId,
    kagamiGridModel.presentation.actions,
    handleTriggerClick
  );
  // Commented to enable triggers in embeded if readonly
  if (isAllowableTemplate(expandedEmbeddedModel.uiTemplate)) {
    let triggers: any = buildEmbeddedTriggers(expandedEmbeddedModel,kagamiGridModel);
    kagamiGridModel.actions = triggers;
    embeddedFormPresentationModel.embedTriggers = expandedEmbeddedModel.embedTriggers;
    callPoliciesForEmbedTriggers(formModel)
  }
  kagamiGridModel.isEditableEmbedList = !embeddedFormPresentationModel.readonly;
  kagamiGridModel.isEmbedModel = true;
  if(formModel.isPivot){
    kagamiGridModel.isPivot = true;
    formModel.pivotEntityId = kagamiGridModel.mainEntityId;
    kagamiGridModel.defaultEmbedFormView = 'inline';
  }
  return buildGrid(kagamiGridModel);
}

export function isMasterDetailEnabled(nestedEmbedModels: any[]) {
  let emebdFormModel: any;
  let masterDetailEnabled: boolean = false;
  for (emebdFormModel of nestedEmbedModels) {
    if (emebdFormModel.visible) {
      masterDetailEnabled = true;
    }
  }
  return masterDetailEnabled
}

export function buildEmbeddedFormPresentation(embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel) {
  let kagamiFormModel : KagamiFormModel = getFormModel()
  let recordId = getRecordId(kagamiFormModel,kagamiFormModel.formControls[embeddedFormPresentationModel.formEntityId])
  updateInFocusMap(embeddedFormPresentationModel.formEntityId,recordId,kagamiFormModel)
  return buildFormPresentation(embeddedFormPresentationModel);
}

function getDetailedObjectsForGridModel(embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel, entityId: string) {
  /// Note : fetching grid records from active form data
  let detailedObjects : any[] = getFormRecords(embeddedFormPresentationModel)
  return detailedObjects;
}

export function buildEmbeddedTriggers(expandedEmbedModel: KagamiExpandedEmbeddedPresentationModel,kagamiGridModel : KagamiGridModel) {
  let triggerModels: Map<string, any> = new Map<string, any>();
  let allReadOnlyColumns : boolean =  hasReadOnlyColumns(kagamiGridModel)
  let embeddedModalModel: KagamiEmbeddedModalModel = new KagamiEmbeddedModalModel(expandedEmbedModel);
  embeddedModalModel.createState = createState(embeddedModalModel);
  // let modal = buildEmbeddedModal(embeddedModalModel);
  expandedEmbedModel.actions.push(embeddedModalModel);
  let triggers: any[] = expandedEmbedModel.presentation.actions;
  triggers.forEach((triggerAction: any) => {
    let triggerModel;
    let component: any = null;
    switch (triggerAction['actionName']) {
      case 'add':
        triggerModel = buildEmbeddedTriggerModel(expandedEmbedModel, triggerAction, addRecord, true);
        triggerModel.embeddedModal = embeddedModalModel;
        if(allReadOnlyColumns && CommonUtils.isEmpty(triggerModel.policy)){
             triggerModel.enableTrigger = false;
        }
        component = buildEmbedTrigger(triggerModel);
        expandedEmbedModel.embedTriggers['add'] = triggerModel;
        triggerModels.set(triggerAction['actionName'], component);
        return triggerModels;
      case 'edit':
        triggerModel = buildEmbeddedTriggerModel(expandedEmbedModel, triggerAction, editRecord, true);
        triggerModel.embeddedModal = embeddedModalModel;
        expandedEmbedModel.embedTriggers['edit'] = triggerModel;
        triggerModels.set(triggerAction['actionName'], triggerModel);
        return triggerModels;

      case 'remove':
        triggerModel = buildEmbeddedTriggerModel(expandedEmbedModel, triggerAction, removeRecord, true);
        component = buildEmbedTrigger(triggerModel);
        expandedEmbedModel.embedTriggers['remove'] = triggerModel;
        triggerModels.set(triggerAction['actionName'], triggerModel);
        return triggerModels;

      case 'reset':
        triggerModel = buildEmbeddedTriggerModel(expandedEmbedModel, triggerAction, removeRecord, true);
        component = buildEmbedTrigger(triggerModel);
        triggerModels.set(triggerAction['actionName'], triggerModel);
        return triggerModels;

      default:
        return null;
    }
  });

  return triggerModels;
}

export function buildExpandedEmbedModel(embeddedPresentationModel: KagamiEmbeddedFormPresentationModel) {
  let expandedEmbeddedModel: KagamiExpandedEmbeddedPresentationModel = new KagamiExpandedEmbeddedPresentationModel(
    embeddedPresentationModel.onStartData,
    embeddedPresentationModel.mainEntityId,
    embeddedPresentationModel.formEntityId,
    embeddedPresentationModel.formHeader,
    embeddedPresentationModel.processName,
    embeddedPresentationModel.processStepName,
    embeddedPresentationModel.uiTemplate,
    embeddedPresentationModel.visible,
    embeddedPresentationModel.readonly,
    embeddedPresentationModel,
    embeddedPresentationModel.isDynamicForm
  );
  expandedEmbeddedModel.formControls = embeddedPresentationModel.formControls;
  expandedEmbeddedModel.presentationId = embeddedPresentationModel.presentationId;
  expandedEmbeddedModel.embeddedView = embeddedPresentationModel.embeddedView;
  expandedEmbeddedModel.presentation = embeddedPresentationModel.presentation;
  expandedEmbeddedModel.presentationPolicy = embeddedPresentationModel.presentationPolicy;
  expandedEmbeddedModel.businessPolicy = embeddedPresentationModel.businessPolicy;
  expandedEmbeddedModel.presentationHireachy = embeddedPresentationModel.presentationHireachy;
  // expandedEmbeddedModel.onLoadData = embeddedPresentationModel.onLoadData;
  expandedEmbeddedModel.mandatory = embeddedPresentationModel.mandatory;
  expandedEmbeddedModel.visible = embeddedPresentationModel.visible;
  expandedEmbeddedModel.isListPresentation = embeddedPresentationModel.isListPresentation;
  expandedEmbeddedModel.onloadRequired = embeddedPresentationModel.onloadRequired;
  expandedEmbeddedModel.onCancelRequired = embeddedPresentationModel.onCancelRequired;
  expandedEmbeddedModel.actions = embeddedPresentationModel.actions;
  expandedEmbeddedModel.state = embeddedPresentationModel.state;
  expandedEmbeddedModel.createState = createState(expandedEmbeddedModel);
  expandedEmbeddedModel.formControls = embeddedPresentationModel.formControls;
  expandedEmbeddedModel.formComponents = embeddedPresentationModel.formComponents;
  expandedEmbeddedModel.formControlStates = embeddedPresentationModel.formControlStates;
  expandedEmbeddedModel.rowCountModel = embeddedPresentationModel.rowCountModel;
  if(embeddedPresentationModel.isNested){
    expandedEmbeddedModel.isNested = embeddedPresentationModel.isNested;
    expandedEmbeddedModel.parentEmbedIdForNestedEmbed = embeddedPresentationModel.parentEmbedIdForNestedEmbed;
    expandedEmbeddedModel.parentEmbedEntityId = embeddedPresentationModel.parentEmbedEntityId;
  }
 
  return expandedEmbeddedModel;
}

export function buildNestedEmbedModels(embeddedPresentationModel: KagamiEmbeddedFormPresentationModel) {
  let nestedEmbeddedModels: KagamiEmbeddedFormPresentationModel[] = [];
  if (embeddedPresentationModel.uiTemplate === 'treeForm' || embeddedPresentationModel.uiTemplate === 'treeEdit'
       || embeddedPresentationModel.uiTemplate === 'treeView') {
    console.log('fetching nested embed form presentations');
    let attributePresentations: any[] = embeddedPresentationModel.presentation['presentationRules'];
    for (let [attrName, attributePresentation] of Object.entries(attributePresentations)) {
      if (attributePresentation['@type'] === 'FormPresentation') {
        let entityPresentation: any = getFormPresentationFormAttribute(attributePresentation);
        let entityId: string = getMappedEntityIdForPresentation(entityPresentation);
        let formHeader: string = getHeaderForPresentation(entityPresentation);
        let isMandatory: boolean = isMandatoryPresentation(entityPresentation);
        let isVisible: boolean = isVisiblePresentation(entityPresentation);
        let isList: boolean = isListPresentation(entityPresentation);
        let isOnloadRequired: boolean = isOnLoadRequiredForPresentation(entityPresentation);
        let presentationPolicy: any = getPresentationPolicy(entityPresentation);
        let businessPolicy: any = getBusinessPolicy(entityPresentation);
        let presentationId : any = getPresenationIdFromPresentation(entityPresentation)
        let nestedEmbeddedModel = buildEmbeddedModel(
          entityPresentation,
          embeddedPresentationModel.onStartData,
          embeddedPresentationModel.mainEntityId,
          entityId,
          formHeader,
          embeddedPresentationModel.processName,
          embeddedPresentationModel.processStepName,
          embeddedPresentationModel.uiTemplate,
          isMandatory,
          isVisible,
          embeddedPresentationModel.readonly,
          isList,
          isOnloadRequired,
          embeddedPresentationModel.onLoadData !== undefined ? embeddedPresentationModel.onLoadData[entityId] : embeddedPresentationModel.onLoadData,
          presentationPolicy,
          businessPolicy,
          embeddedPresentationModel.isDynamicForm,
          presentationId
        );
        nestedEmbeddedModel.isNested = true;
        nestedEmbeddedModel.parentEmbedEntityId = embeddedPresentationModel.formEntityId;
        nestedEmbeddedModel.onCancelRequired = isOnCancelRequiredForPresentation(entityPresentation);
        getFormModel().formControls[entityId] = nestedEmbeddedModel;
        if (nestedEmbeddedModel.presentation['presentationRules']) {
          nestedEmbeddedModels.push(nestedEmbeddedModel);
        }
      }
    }
  }
  else {
    const presentationMap: any = retrievePresentationsFromUIResource(embeddedPresentationModel.onStartData);
    const presentations: any[] = retrievePresentationsForEntity(presentationMap, embeddedPresentationModel.formEntityId);
    let nestedEmbeddedModel: KagamiEmbeddedFormPresentationModel;
    presentations.forEach((presentation: any) => {
      let entityId: string = getMappedEntityIdForPresentation(presentation);
      let formHeader: string = getHeaderForPresentation(presentation);
      let isMandatory: boolean = isMandatoryPresentation(presentation);
      let isVisible: boolean = isVisiblePresentation(presentation);
      let isList: boolean = isListPresentation(presentation);
      let isOnloadRequired: boolean = isOnLoadRequiredForPresentation(presentation);
      let presentationPolicy: any = getPresentationPolicy(presentation);
      let businessPolicy: any = getBusinessPolicy(presentation);
      let presentationId : any = getPresenationIdFromPresentation(presentation)
      nestedEmbeddedModel = buildEmbeddedModel(
        presentation,
        embeddedPresentationModel.onStartData,
        embeddedPresentationModel.mainEntityId,
        entityId,
        formHeader,
        embeddedPresentationModel.processName,
        embeddedPresentationModel.processStepName,
        embeddedPresentationModel.uiTemplate,
        isMandatory,
        isVisible,
        embeddedPresentationModel.readonly,
        isList,
        isOnloadRequired,
        embeddedPresentationModel.onLoadData !== undefined ? embeddedPresentationModel.onLoadData[entityId] : embeddedPresentationModel.onLoadData,
        presentationPolicy,
        businessPolicy,
        embeddedPresentationModel.isDynamicForm,
        presentationId
      );
      nestedEmbeddedModel.onCancelRequired = isOnCancelRequiredForPresentation(presentation);
      nestedEmbeddedModel.isNested = true;
      nestedEmbeddedModel.parentEmbedEntityId = embeddedPresentationModel.formEntityId
      getFormModel().formControls[entityId] = nestedEmbeddedModel;
      if (nestedEmbeddedModel.presentation['presentationRules']) {
        nestedEmbeddedModels.push(nestedEmbeddedModel);
      }
    });
  }
  let formModel: KagamiFormModel = getFormModel();
  let activeContext = getActiveContext(formModel, formModel.mainEntityId);
  // executePoliciesForNestedOnMounting(activeContext,nestedEmbeddedModels)
  return nestedEmbeddedModels;
}

export const NestedEmbedPresentation = (props: ExpandedEmbeddedPresentationModelProps) => {
  let nestedEmbedModels: any[] = [];
  // props.expandedEmbeddedPresentationModel.nestedEmbeddedModels.map(
  //   (embedModel: KagamiEmbeddedFormPresentationModel) => {
  //     nestedEmbedModels.push(embedModel);
  //     // nestedEmbedComponents.push(buildEmbeddedPresentation(embedModel));
  //   }
  // );
  //// adding embed view for checking purpose, need to add it by main form presentation
  let embeddedPresentationModel: KagamiEmbeddedPresentationModel = new KagamiEmbeddedPresentationModel(
    props.expandedEmbeddedPresentationModel.nestedEmbeddedModels,
    'accordion'
  );
  return <div>{buildEmbeddedPresentation(embeddedPresentationModel)}</div>;
};

export const ExpandedEmbeddedHeader = (props: EmbeddedModalModelProps) => {
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  return (
    <DialogTitle
      id={props.embeddedModalModel.embeddedExpandedPresentationModel.formHeader}
      className={props.classes.modalTitle}
    >
      <div className={props.classes.modalHeader}>
        <FormLabel className={props.classes.formlabel}>
          {/* {props.embeddedModalModel.embeddedExpandedPresentationModel.formHeader} */}
          {translateToLocalistaion(translations, props.embeddedModalModel.embeddedExpandedPresentationModel.formHeader)}
        </FormLabel>
        <Button
          color="secondary"
          onClick={() => {
            handleEmbeddedModalCancel(props.embeddedModalModel.embeddedExpandedPresentationModel);
          }}
        >
          <CloseIcon />
        </Button>
      </div>
    </DialogTitle>
  );
};

export function getExpandedEmbedContentForModal(embeddedExpandedPresentationModel: KagamiExpandedEmbeddedPresentationModel) {
  return buildExpandedEmbeddedPresentation(embeddedExpandedPresentationModel)
}

export function getEmbedHeaderForModal(embeddedExpandedPresentationModel: KagamiExpandedEmbeddedPresentationModel, listModel: KagamiGridModel | undefined,action : string) {
  return <ExpandedEmbeddedHeaderForModal embeddedExpandedPresentationModel={embeddedExpandedPresentationModel} kagamiGridModel={listModel} action={action} />
}

export const ExpandedEmbeddedHeaderForModal = (props: any) => {
  const classes = makeStyles(KagamiEmbeddedModalStyle)()
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  return (<div  className= {classes.embedModalHeader}>
    {/* <div>{props.action + ' '+ props.embeddedExpandedPresentationModel.formHeader}</div> */}
    <div>{translateToLocalistaion(translations, props.action) + " " + translateToLocalistaion(translations, props.embeddedExpandedPresentationModel.formHeader)}</div>
    <div className={classes.nonfields_container}>
      <BuildNonFieldPresentations key="nonFieldPresentation" kagamiGridModel={props.kagamiGridModel} isPickAttributeButtonVisible={true} />
    </div>

  </div>)
  // return  <div className={classes.modalHeader}>
  // <FormLabel className={classes.formlabel}>
  //   {props.embeddedExpandedPresentationModel.formHeader}
  // </FormLabel>
  // </div>
}

export const ExpandedEmbedContent = (props: EmbeddedModalModelProps) => {
  return (
    <DialogContent
      key={props.embeddedModalModel.embeddedExpandedPresentationModel.formEntityId}
      id={props.embeddedModalModel.embeddedExpandedPresentationModel.formHeader}
      dividers
    >
      {buildExpandedEmbeddedPresentation(props.embeddedModalModel.embeddedExpandedPresentationModel)}
    </DialogContent>
  );
};

export const EmbedFormButtons = (props: EmbeddedModalModelProps) => {
  return (
    <DialogActions className={props.classes.modalFooter}>
      <Button
        variant="contained"
        onClick={() => handleEmbeddedModalClose(props.embeddedModalModel.embeddedExpandedPresentationModel)}
        color="primary"
      >
        {VisibleConstants.saveLabel}
      </Button>
    </DialogActions>
  );
};

export const InitialEmbedHeader = (props: KagamiEmbedAccordionProps) => {
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  props.embeddedAccordionPresentationModel.rowCountModel.dataCount = CommonUtils.isNotEmpty(props.embeddedAccordionPresentationModel.onLoadData) ? getEmbedTotalRecords(activeModel.onStartData,props.embeddedAccordionPresentationModel.formEntityId) : 0
  props.embeddedAccordionPresentationModel.rowCountModel.formEntityId = props.embeddedAccordionPresentationModel.formEntityId
  props.embeddedAccordionPresentationModel.rowCountModel.presentationType = 'accordion'
  return (
    <AccordionSummary
      className={props.classes.accordionSummary}
      expandIcon={props.embeddedAccordionPresentationModel.active ? <KeyboardArrowDownIcon className="expand-more-icon" /> : <KeyboardArrowRightIcon className="expand-more-icon" />}
      aria-controls="panel1a-content"
      id={props.embeddedAccordionPresentationModel.formEntityId}
      onClick={() => toggleEmbedAccordion(props.embeddedAccordionPresentationModel)}
    >
      <FormLabel className={props.classes.formLabel} required={props.embeddedAccordionPresentationModel.mandatory}>
        {/* {props.embeddedAccordionPresentationModel.formHeader} */}
        {translateToLocalistaion(translations, props.embeddedAccordionPresentationModel.formHeader)}
      </FormLabel>
      {embeddedRowCountController(props.embeddedAccordionPresentationModel.rowCountModel)}
      {/* <Typography className = {props.classes.hint} sx={{color: 'text.secondary', display:'flex'}} variant='caption'>
        <KeyboardArrowDownIcon fontSize='small' sx={{ color: 'text.secondary' }} /> 
        &nbsp;Click here to open 
        </Typography> */}
      <div></div>
      {/* <Badge color="secondary" badgeContent={props.embeddedAccordionPresentationModel.state.getDataCount(props.embeddedAccordionPresentationModel)}></Badge> */}
    </AccordionSummary>
  );
};

export const KagamiEmbedAccordionContent = (props: KagamiEmbedAccordionProps) => {
  return (
    <AccordionDetails>
      <div className={props.classes.accordionContent}>
        {getEmbeddedSectionContent(props.embeddedAccordionPresentationModel, props.readOnly)}
      </div>
    </AccordionDetails>
  );
};

export const ExpandedEmbedFormPresentation = (props: ExpandedEmbeddedPresentationModelProps) => {
  return <div>{buildFormPresentation(props.expandedEmbeddedPresentationModel)}</div>;
};
