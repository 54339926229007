import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import { openCloseDatePicker, validateDateOnChange } from '../controller/kagamiDateController';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { KagamiDateFieldStyle } from '../styles/KagamidateFieldStyle';
import { FormControl, FormLabel, IconButton, TextField } from '@mui/material';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { getFormModel } from '../../../form/controller/KagamiFormController';
import { useKagamiControlModelState } from '../../model/kagamiControlModel';
import { getDateStringToPopulateInForm } from '../../../../../../../../../../utils/DateUtils';
import { getMinTime } from '../service/DateService';

function TimeField(props: any) {
  let fieldState = useKagamiControlModelState(props.kagamiDateModel.createState);
  props.kagamiDateModel.state = fieldState;
  props.kagamiDateModel.displayValue = getDateStringToPopulateInForm(fieldState.controlValue, fieldState.controlType);
  getFormModel().formControlStates[props.kagamiDateModel.controlIdentifier] = fieldState;
  const classes = makeStyles(KagamiDateFieldStyle)();
  const uiSettingsClassName = props.kagamiDateModel.getControlWidth(props.kagamiDateModel.uiSettings);
  const fontProperties = props.kagamiDateModel.getFontStyling(props.kagamiDateModel.uiSettings);
  const onTimeChange = (dateValue: any) => {
    if (dateValue.toString() !== 'Invalid Date') {
      validateDateOnChange(props.kagamiDateModel, dateValue);
    }
  };
  const debounceTimeChange = React.useCallback(CommonUtils._debounceFtn(onTimeChange, 900), []);

  if (fieldState.isVisible === false) {
    return <></>;
  } else {
    return (
      <div className={`${uiSettingsClassName} ${classes.root}`}>
        {!fieldState.isReadOnly ? (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl fullWidth>
              <FormLabel
                component="span"
                required={fieldState.isMandatory}
                style={{
                  color: fontProperties.labelFontColor,
                  fontWeight: fontProperties.labelFontBold,
                  fontStyle: fontProperties.labelFontItalic,
                  textDecoration: fontProperties.labelFontUnderline
                }}
              >
                {props.kagamiDateModel.controlDisplayName}
              </FormLabel>
              <TimePicker
                open={fieldState.isOpen}
                PopperProps={{
                  className: 'customPopover'
                }}
                onOpen={() => openCloseDatePicker(props.kagamiDateModel, true)}
                onClose={() => openCloseDatePicker(props.kagamiDateModel, false)}
                value={fieldState.displayValue}
                ampmInClock
                disabled={fieldState.isReadOnly}
                minTime={props.kagamiDateModel.uiSettings
                  ? getMinTime(props.kagamiDateModel.uiSettings, props.kagamiDateModel)
                  : null}
                onChange={(newValue: any) => {
                  if (!props.kagamiDateModel.isOpen) {
                    debounceTimeChange(newValue);
                  }
                }}
                onAccept={(newValue: any) => {
                  validateDateOnChange(props.kagamiDateModel, newValue);
                }}
                renderInput={(params) => (
                  <div style={{ position: 'relative' }}>
                    <TextField
                      {...params}
                      color="primary"
                      sx={{
                        '& .MuiInputLabel-root ': {
                          color: fontProperties.labelFontColor,
                          fontWeight: fontProperties.labelFontBold,
                          fontStyle: fontProperties.labelFontItalic,
                          textDecoration: fontProperties.labelFontUnderline
                        },
                        '& .MuiInputLabel-root.Mui-focused ': {
                          color: fontProperties?.labelFontColor,
                          fontWeight: fontProperties?.labelFontBold,
                          fontStyle: fontProperties?.labelFontItalic,
                          textDecoration: fontProperties?.labelFontUnderline
                        },
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: fontProperties.controlBackgroundColor,
                          color: fontProperties.controlFontColor + ' ' + '!important',
                          fontWeight: fontProperties.controlFontBold,
                          fontStyle: fontProperties.controlFontItalic,
                          textDecoration: fontProperties.controlFontUnderline
                        }
                      }}
                      onKeyPress={(event: any) => {
                          /// Note : event added to prevent user from entering date through keyboard
                          if(event.code !== "Tab") {
                            event.preventDefault();
                            }
                      }}
                      onKeyDown={(event: any) => {
                           /// Note : event added to prevent user from entering date through keyboard
                        if(event.code !== "Tab") {
                        event.preventDefault();
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <ScheduleIcon
                            sx={{
                              color: (theme) => theme.palette.secondary.main
                            }}
                            fontSize="small"
                            color="action"
                          />
                        ),
                        readOnly: true
                      }}
                      fullWidth
                      size="small"
                      required={fieldState.isMandatory}
                      error={fieldState.errorMessage ? true : false}
                      helperText={fieldState.errorMessage}
                      onClick={() => openCloseDatePicker(props.kagamiDateModel, true)}
                    />
                    {CommonUtils.isNotEmpty(fieldState.displayValue) ? (
                      <IconButton
                        size="small"
                        style={{ position: 'absolute', top: '2px', right: '10px' }}
                        onClick={() => {
                          validateDateOnChange(props.kagamiDateModel, null);
                        }}
                      >
                        <ClearOutlinedIcon
                          sx={{
                            color: (theme) => theme.palette.error.main
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              />
            </FormControl>
          </LocalizationProvider>
        ) : (
          <div className={classes.readOnlyField_cont}>
            <FormLabel
              component="span"
              className={classes.controlLabel}
              required={fieldState.isMandatory}
              style={{ color: fontProperties.labelFontColor }}
            >
              {' '}
              {props.kagamiDateModel.controlDisplayName}
            </FormLabel>
            {fieldState.controlValue ? (
              <p className={classes.readOnlyField} style={{ color: fontProperties.controlFontColor }}>
                {moment(fieldState.displayValue).format('LT')}
              </p>
            ) : (
              <p className={classes.readOnlyEmpty} style={{ color: fontProperties.controlFontColor }}>
                ----
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default TimeField;
