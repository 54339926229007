import { KagamiStateManager } from '../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { useAsyncProcess } from '../../../../../../../service/Settings';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { getHomeModel } from '../../../../../controller/KagamiHomeController';
import { KagamiHomeModel } from '../../../../../model/KagamiHomeModel';
import { getContentForAppSettingModel, getTitleForAppSettingModel } from '../../../../HomeBuilder';
import { executeProcess, getProcessName } from '../../../../navigator/builder/Menu/controller/KagamiMenuController';
import { buildtoastNotification } from '../../../../ToastNotification/controller/ToastNotificationController';
import { GetUploadDialogContent } from '../builder/bulkUpload/controller/BulkUploadController';
import { KagamiBulkUploadModel } from '../builder/bulkUpload/model/BulkUploadModel';
import { buildPresentation } from '../builder/form/builder/FormBuilder';
import {
  buildFormPage,
  handleFormDataChange,
  setFormModel,
  showServerError
} from '../builder/form/controller/KagamiFormController';
import { KagamiFormModel } from '../builder/form/model/KagamiFormModel';
import {
  BackButtonToshowOrNot,
  updateFormHierarchyAndOnloadData,
  _getUnSyncedFormRecord
} from '../builder/form/service/FormService';
import { getGroupModel } from '../builder/group/service/GroupService';
import { KagamiGridModel } from '../builder/list/builder/grid/model/KagamiGridModel';
import { getKagamiListModel } from '../builder/list/controller/KagamiListController';
import { KagamiListModel } from '../builder/list/model/KagamiListModel';
import { getConfirmDialogContent, getConfirmDialogTile } from '../builder/ProcessPageBuildler';
import {
  getActiveModel,
  loadProcessContent,
  onSubmitCall,
  updateActiveModel
} from '../controller/KagamiProcessController';
import { KagamiProcessRequestModel } from '../model/KagamiProcessRequestModel';
import { KagamiProcessResponseModel } from '../model/KagamiProcessResponseModel';
import { getHeaderForprocess, getMainEntityIdForPresentation, isUiInputReadOnly } from './PresentationService';
import { makeAsyncOnStartCall, makeLinkProcessOnStartCall, makeOnStartCall, makeOnSubmitCall } from './ProcessService';
import { prepareForLoadCall } from './SubProcessService';

export class NavigationService {
  static async navigateTo(processModel: KagamiProcessRequestModel) {
    let response: any = null;
    if (CommonUtils.isNotEmpty(processModel.linkId)) {
      response = await makeLinkProcessOnStartCall(processModel.linkId);
      return this.processResponse(response, processModel, false);
    } else if (processModel.submitCall) {
      response = await makeOnSubmitCall(
        processModel.processName,
        processModel.data,
        processModel.bulkUploadProperties,
        processModel.changeFor,
        processModel.isPickItemCall,
        processModel.isModalResponse,
        processModel.bulkEventType
      );
      return this.processResponse(response, processModel, false);
    } else if (useAsyncProcess) {
      const asyncResponse = await makeAsyncOnStartCall(
        processModel.processName,
        processModel.data,
        processModel.fromDashboard,
        processModel.processName,
        processModel
      );
      // const asyncProcessState = useAsyncProcessState();
      // const asyncProcesses = asyncProcessState.asyncProcesses;
      // const newAsyncProcesses = [...asyncProcesses,{
      //   requestId : asyncResponse.requestId,
      //   responses: [asyncResponse],
      //   combinedResponse: asyncResponse,
      //   isCompleted: false

      // }];
      // asyncProcessState.SetAsyncProcesses(newAsyncProcesses);
      return this.processResponse(asyncResponse, processModel, false);
    } else {
      response = await makeOnStartCall(
        processModel.processName,
        processModel.data,
        processModel.fromDashboard,
        // processModel.processName,
        CommonUtils.isNotEmpty(processModel.previousProcessName)
          ? processModel.previousProcessName
          : processModel.processName,
        processModel
      );
      return this.processResponse(response, processModel, false);
    }
  }

  static processResponse(
    result: any,
    processModel: KagamiProcessRequestModel | null,
    isSubProcess: boolean,
    isExplicit?: boolean,
    entityIdOfExplicitEvent ?: string
  ): KagamiProcessResponseModel | undefined {
    var processResponseModel: KagamiProcessResponseModel = new KagamiProcessResponseModel();
    let response: any = result['data'];
    if (response && response['constructError'] != null) {
      processResponseModel.onErrorData = response['constructError'];
      return processResponseModel;
    } else if (response && response['constructOutputData'] != null) {
      let constructOutputData: any = response['constructOutputData'];
      let uiResource: any = constructOutputData['uiResource'];
      let detailedObjects: any = constructOutputData['detailedObjects'];
      let isDmsUrl: boolean = CommonUtils.isNotEmpty(detailedObjects['dmsUrl']);
      if ((uiResource != null || uiResource != undefined) && !isDmsUrl) {
        if (
          constructOutputData.uiResource.stepInfo.verb === 'UIINPUT' &&
          constructOutputData.uiResource.uiTemplate === 'form'
        ) {
          processResponseModel.templateType = 'form';
          processResponseModel.onStartData = response;
          processResponseModel.onErrorData = null;
          if (processModel) {
            processResponseModel.onKanbannClose = processModel.onKanbannClose;
          }
          processResponseModel.detailedObjects = detailedObjects;
          processResponseModel.processName = NavigationService.getStepAndProcessName(
            uiResource,
            constructOutputData,
            'processName'
          );
          console.log('Stepinfo verb type uiEvent implement here');
          let activeModel = getActiveModel();
          var mainEntityId: string = getMainEntityIdForPresentation(processResponseModel.onStartData);
          const kagamiFormModel: KagamiFormModel = new KagamiFormModel(
            processResponseModel.onStartData,
            mainEntityId,
            processResponseModel.processName,
            processResponseModel.processStepName,
            processResponseModel.templateType
          );
          kagamiFormModel.readOnly = isUiInputReadOnly(processResponseModel.onStartData);
          updateActiveModel(kagamiFormModel);
          if (activeModel instanceof KagamiListModel) {
            activeModel = getKagamiListModel();
            const processRequestModel = new KagamiProcessRequestModel(
              processResponseModel.processName,
              loadProcessContent,
              showServerError
            );
            processRequestModel.submitCall = true;
            processRequestModel.isUiInput = true;
            const onClickCall = () => {
              _getUnSyncedFormRecord(kagamiFormModel, kagamiFormModel.mainEntityId);
              processRequestModel.data = kagamiFormModel.formData.changedData;
              onSubmitCall(processRequestModel);
            };
            const onClickCancel = () => {
              updateActiveModel(activeModel);
            };

            let popupModel = activeModel.kagamiModalModel;
            popupModel.title = getHeaderForprocess(constructOutputData);
            popupModel.content = buildFormPage(kagamiFormModel)
            popupModel.modalWidth = 'sm';
            popupModel.isForm = true;
            popupModel.handleOnOk = kagamiFormModel.readOnly ? onClickCancel : onClickCall;
            popupModel.handleOnClose = onClickCancel;
            popupModel.state.setOpen(true);
          } else if (activeModel instanceof KagamiFormModel) {
            const onOkCall = () => {
              updateActiveModel(activeModel);
            };

            let popupModel = activeModel.errorModal;
            popupModel.title = getHeaderForprocess(constructOutputData);
            popupModel.content = buildFormPage(kagamiFormModel);
            popupModel.modalWidth = 'sm';
            popupModel.handleOnOk = onOkCall;
            popupModel.handleOnClose = onOkCall;
            popupModel.state.setOpen(true);
          }
        } else if (
          constructOutputData.uiResource.stepInfo.verb != 'upload' ||
          uiResource['uiTemplate'] === 'modalDialog'
        ) {
          if (
            (constructOutputData.uiResource.stepInfo.verb === 'create' && constructOutputData.responseMap) ||
            processModel?.isToastMessageHide == false
          ) {
            //************************** */
          } else if (processModel?.submitCall == true) {
            //show toast "record saved"
            // showToastAlert(SystemConstants.RECORD_SAVED_TOAST, 2000);
            buildtoastNotification('success', SystemConstants.RECORD_SAVED_TOAST, 2000);
          } else {
            null;
          }
          let processName = this.getStepAndProcessName(uiResource, constructOutputData, 'processName');
          let stepName = this.getStepAndProcessName(uiResource, constructOutputData, 'stepName');
          let presentationType = uiResource['uiTemplate'];

          if (processModel != null) {
            processResponseModel.isGroupTemplateNetworkCall = processModel.isGroupTemplateNetworkCall;
            processResponseModel.templateType = presentationType;
            if (processResponseModel.isGroupTemplateNetworkCall) {
              let groupModel = getGroupModel();
              if (BackButtonToshowOrNot(processResponseModel.templateType)) {
                groupModel.state.setProcessList(groupModel.processList.slice(0, -1));
              } else {
                groupModel.processList.push(processModel.processName);
              }
            }
          }

          if (processModel != null) {
            processModel.processStepName = stepName;
          }

          processResponseModel.processName = processName;
          processResponseModel.processStepName = stepName;
          processResponseModel.templateType = presentationType;
          processResponseModel.onStartData = response;
          processResponseModel.onErrorData = null;
          processResponseModel.detailedObjects = detailedObjects;
          if (processModel) {
            processResponseModel.onKanbannClose = processModel.onKanbannClose;
          }
          if (processModel !== null) {
            processResponseModel.isGroupTemplateNetworkCall = processModel.isGroupTemplateNetworkCall;
          }

          if (presentationType === 'modalDialog' || isSubProcess) {
            processResponseModel.isModelDialog = true;
          }

          return processResponseModel;
        } else if (constructOutputData.uiResource.stepInfo.verb === 'upload') {
          let listModel = getKagamiListModel();
          let bulkUploadModel: KagamiBulkUploadModel = new KagamiBulkUploadModel();
          let popupModel = listModel.kagamiModalModel;
          popupModel.title = 'Bulk Upload';
          popupModel.content = GetUploadDialogContent(
            response.constructOutputData.uiResource.stepInfo,
            bulkUploadModel,
            popupModel
          );
          popupModel.isBulkUploadSave = true;
          popupModel.state?.setOpen(true);
        }
      } else {
        if (constructOutputData.responseMap.EOP === true && detailedObjects.dmsUrl) {
          if (processModel?.isToastMessageHide == false && constructOutputData.responseMap.EOP === true) {
            null;
          } else if (processModel?.submitCall == true) {
            //show toast "record saved"
            // showToastAlert(SystemConstants.RECORD_SAVED_TOAST, 3000);
            buildtoastNotification('success', SystemConstants.RECORD_SAVED_TOAST, 3000);
          } else {
            null;
          }
          processResponseModel.templateType = 'DMS';
          processResponseModel.onStartData = response;
          processResponseModel.onErrorData = null;
          processResponseModel.detailedObjects = detailedObjects;
          return processResponseModel;
        } else if (constructOutputData.responseMap.EOP === true && constructOutputData.responseMap.confirmMessage) {
          console.log('constructOutputData', constructOutputData);
          let activeModel = getActiveModel();
          const onModalOkClickCall = () => {
            /// Note : commented this because in one scenario it is taking first process name in the request not the eop process name .
            /// NEED TO DISCUSS
            // let processName = CommonUtils.isNotEmpty(constructOutputData.processName ) ? constructOutputData.processName: getProcessName();
            let processName = getProcessName();
            const processRequestModel: KagamiProcessRequestModel = new KagamiProcessRequestModel(
              processName,
              loadProcessContent,
              showServerError
            );
            processRequestModel.fromDashboard = false;
            processRequestModel.data = null;
            processRequestModel.bulkUploadProperties = null;
            processRequestModel.submitCall = false;
            if (processRequestModel.isGroupTemplateNetworkCall) processRequestModel.isGroupTemplateNetworkCall = true;
            onSubmitCall(processRequestModel);
            activeModel.embedModal.state.setOpen(false);
          };
          if (activeModel instanceof KagamiListModel) {
            activeModel = getKagamiListModel();
            let popupModel = activeModel.embedModal;
            popupModel.title = getConfirmDialogTile();
            popupModel.content = getConfirmDialogContent(constructOutputData.responseMap.confirmMessage);
            popupModel.handleOnOk = onModalOkClickCall;
            popupModel.state.setOpen(true);
          } else if (activeModel instanceof KagamiFormModel) {
            if (isExplicit) {
              let homeModel: KagamiHomeModel = getHomeModel();
              const onModalAlertClickCall = () => {
                homeModel.modalDialogModel.state.setOpen(false);
                let entityId : string = CommonUtils.isNotEmpty(entityIdOfExplicitEvent) ? entityIdOfExplicitEvent : activeModel.mainEntityId
                handleFormDataChange(entityId, response);
              };
              homeModel.modalDialogModel.title = 'Confirm';
              homeModel.modalDialogModel.content = constructOutputData.responseMap.confirmMessage;
              homeModel.modalDialogModel.state.setOpen(true);
              homeModel.modalDialogModel.handleOnOk = onModalAlertClickCall;
            } else if (
              CommonUtils.isEmpty(isExplicit) &&
              constructOutputData.responseMap.EOP === true &&
              constructOutputData.responseMap.confirmMessage
            ) {
              let homeModel: KagamiHomeModel = getHomeModel();
              const onModalClickCall = () => {
                // let processName = CommonUtils.isNotEmpty(constructOutputData.processName ) ? constructOutputData.processName: getProcessName();
                // Note: We have commented processName because It is not redirecting to List View when on click of Ok in a Modal
                let processName = getProcessName();
                const processRequestModel: KagamiProcessRequestModel = new KagamiProcessRequestModel(
                  processName,
                  loadProcessContent,
                  showServerError
                );
                processRequestModel.fromDashboard = false;
                processRequestModel.data = null;
                processRequestModel.bulkUploadProperties = null;
                processRequestModel.submitCall = false;
                if (processRequestModel.isGroupTemplateNetworkCall)
                  processRequestModel.isGroupTemplateNetworkCall = true;
                onSubmitCall(processRequestModel);
                homeModel.modalDialogModel.state.setOpen(false);
              };

              homeModel.modalDialogModel.title = 'Confirm';
              homeModel.modalDialogModel.content = constructOutputData.responseMap.confirmMessage;
              homeModel.modalDialogModel.state.setOpen(true);
              homeModel.modalDialogModel.handleOnOk = onModalClickCall;

              // showToastAlert(constructOutputData.responseMap.confirmMessage, 1000);
              buildtoastNotification('info', constructOutputData.responseMap.confirmMessage, 2000);
            } else {
              // showToastAlert(constructOutputData.responseMap.confirmMessage, 1000);
              buildtoastNotification('info', constructOutputData.responseMap.confirmMessage, 1000);
            }
          }
        } else if (constructOutputData.responseMap.EOP === true) {
          let homeModel = getHomeModel();
          homeModel.errorModal.title = getTitleForAppSettingModel();
          homeModel.errorModal.content = getContentForAppSettingModel();
          homeModel.errorModal.modalWidth = 'sm';
          if (processModel?.submitCall == true && constructOutputData?.detailedObjects?.UserSetting?.length > 0) {
            //show toast "record saved"
            // showToastAlert(SystemConstants.RECORD_SAVED_TOAST, 1000);
            buildtoastNotification('success', SystemConstants.RECORD_SAVED_TOAST, 1000);

            setTimeout(() => {
              homeModel.errorModal.state.setOpen(true);
            }, 500);
            //need window to reload
            CommonUtils._needWindowReload(6000);
          } else if (processModel?.submitCall == true && constructOutputData?.detailedObjects?.AppSetting?.length > 0) {
            //show toast "record saved"
            // showToastAlert(SystemConstants.RECORD_SAVED_TOAST, 1000);
            buildtoastNotification('success', SystemConstants.RECORD_SAVED_TOAST, 1000);

            setTimeout(() => {
              homeModel.errorModal.state.setOpen(true);
            }, 1000);

            //need window to reload
            CommonUtils._needWindowReload(5000);
          // } else if (processModel?.submitCall == true && !processModel.isPickItemCall) {
          }
          else if(processModel?.bulkEventType) {
            let activeModel: any = getActiveModel();
            if (activeModel instanceof KagamiFormModel) {
              let pickItemModalState = KagamiStateManager.getStateToHolder('PickItemModal');
              handleFormDataChange(activeModel.mainEntityId, response);
              pickItemModalState.setOpen(false);
            }
          }
          else if (processModel?.submitCall == true && !processModel.isPickItemCall) {
            //show toast "record saved"
            // showToastAlert(SystemConstants.RECORD_SAVED_TOAST, 1000);
            executeProcess(getProcessName());
            buildtoastNotification('success', SystemConstants.RECORD_SAVED_TOAST, 1000);

            if (processModel.isPickItemCall) {
              let activeModel: any = getActiveModel();
              if (activeModel instanceof KagamiFormModel) {
                let pickItemModalState = KagamiStateManager.getStateToHolder('PickItemModal');
                handleFormDataChange(activeModel.mainEntityId, response);
                pickItemModalState.setOpen(false);
              }
            } else if (!processModel.isUiInput && constructOutputData.responseMap.hasOwnProperty('UIINPUT')) {
              //NOTE : IF verb UIINPUT 'FALSE' AND EOP 'TRUE' THEN ONLY CALL EXECUTE PROCESS
              executeProcess(getProcessName());
            }
          } 
          else {
            let activeModel: any = getActiveModel();
            console.log('fetching active model');
            if (activeModel && activeModel instanceof KagamiListModel) {
              let activeListModel: any = getKagamiListModel();
              if (activeListModel instanceof KagamiGridModel) {
                activeModel = activeListModel;
                let selectedObjects: any[] = activeModel.selectedObjects;
                let detailedObject: any = {};
                let changeFor: any = {};
                if (CommonUtils.isNotEmpty(selectedObjects)) {
                  let selectedObject: any[] = [];
                  selectedObjects.forEach((record: any) => selectedObject.push({ id: record?.id }));
                  detailedObject[activeModel.mainEntityId] = selectedObject;
                  activeModel.selectedObjects = [];
                  prepareForLoadCall(
                    activeModel.processName,
                    activeModel.presentation,
                    changeFor,
                    detailedObjects,
                    '',
                    activeModel.isPickItemListModel,
                    true
                  );
                }
              }
            }
            // processResponseModel.templateType = '';
            // processResponseModel.onStartData = response;
            // processResponseModel.onErrorData = null;
            // processResponseModel.detailedObjects = detailedObjects;
            // return processResponseModel;
          }
        } else if (isDmsUrl) {
          processResponseModel.templateType = 'DMS';
          processResponseModel.onStartData = response;
          processResponseModel.onErrorData = null;
          processResponseModel.detailedObjects = detailedObjects;
          return processResponseModel;
        }
      }
    } else if (response && response['constructError'] != null) {
      processResponseModel.onErrorData = response['constructError'];
      return processResponseModel;
    } else if (useAsyncProcess) {
      processResponseModel.templateType = 'form';
      processResponseModel.onStartData = response;
      processResponseModel.onErrorData = null;
      processResponseModel.detailedObjects = [];
      return processResponseModel;
    } else {
      processResponseModel.onErrorData = { 'Unexpected Error': 'Empty response by server' };
      return processResponseModel;
    }
  }

  static getStepAndProcessName(uiResource: any, constructOutputData: any, type: string) {
    if (uiResource['stepInfo'] != null) {
      return uiResource['stepInfo'][type] ? uiResource['stepInfo'][type] : constructOutputData['processName'];
    } else {
      return constructOutputData['processName'];
    }
  }
}

function buildContent(kagamiFormModel: KagamiFormModel) {
  setFormModel(kagamiFormModel);
  updateFormHierarchyAndOnloadData(kagamiFormModel);
  return buildPresentation(kagamiFormModel);
}
