import { State, useState } from '@hookstate/core';
import { KagamiModalModel } from '../../../../../../../../../../components/modal/model/KagamiModalModel';
import { KagamiPopoverModel } from '../../../../../../../../../../components/popover/model/KagamiPopoverModel';
import { RuntimeInput } from '../../../../../../../../../../model/form/runtime_input';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { kagamiListHeaderModel } from '../builder/listHeader/model/KagamiListHeaderModel';
import { KagamiPresentationFilterModel } from '../builder/presentationFilters/buliders/kagamiPresentationFilter/model/KagamiPresentationFilterModel';
import { SelectedFilterModel } from '../builder/presentationFilters/buliders/selectedFilter/selectedFilterModel/selectedFIlterModel';
import { KagamiListFilterModel } from '../builder/presentationFilters/model/KagamiListFilterModel';

export class KagamiListModel {
  processName: string;
  processStepName: string;
  onStartData: any;
  listTemplate: string;
  mainEntityId: string;
  onLoadData: any;
  state: any;
  event: string;
  detailedObjects: any;
  selectedObjects: any[];
  presentation: any;
  isEditableEmbedList: boolean;
  actions: Map<string, any>;
  runtimeInput: RuntimeInput;
  embedModal: KagamiModalModel;
  isEventsOnLoad: boolean;
  triggersModel: any;
  isListLoaded: boolean;
  popoverModel: KagamiPopoverModel;
  kagamiModalModel: KagamiModalModel;
  selectedFilterModel:SelectedFilterModel;
  statusInfo: any;
  listChatInfo:any;
  isGroupListModel : boolean;
  kagamiListFilterModel: KagamiListFilterModel; 
  kagamiListHeaderModel: kagamiListHeaderModel;
  isPickItemListModel : boolean;
  docInfo:any;
  isVisibleview:any;
  viewType:string;
  isCustomField:boolean;
  customFieldAttrName:string;
  isHeaderLoadedFromOnStartData: boolean;
  isVisibleViewHierarchy: boolean;
  isPivot: boolean;
  textWidth: any[];
  pageNumber : number;
  totalRecords : number;
  filterList?:any;
  childListModel : any;
  constructor(processName: string, processStepName: string, onStartData: any, mainEntityId: string) {
    this.processName = processName;
    this.processStepName = processStepName;
    this.onStartData = onStartData;
    this.mainEntityId = mainEntityId;
    this.listTemplate = '';
    this.event = 'OnStart';
    this.onLoadData = null;
    this.state = null;
    this.selectedObjects = [];
    this.isEditableEmbedList = false;
    this.actions = new Map();
    this.runtimeInput = new RuntimeInput('', '', '');
    this.embedModal = new KagamiModalModel();
    this.isEventsOnLoad = false;
    this.isListLoaded = false;
    this.popoverModel = new KagamiPopoverModel();
    this.statusInfo = '';
    this.listChatInfo = [];
    this.isGroupListModel = false;
    this.kagamiListFilterModel = new KagamiListFilterModel(); 
    this.kagamiListHeaderModel = new kagamiListHeaderModel();
    this.isPickItemListModel = false;
    this.kagamiModalModel = new KagamiModalModel();
    this.docInfo = {};
    this.isVisibleview = [];
    this.viewType = '';
    this.isCustomField = false;
    this.customFieldAttrName = '';
    this.isHeaderLoadedFromOnStartData = false;
    this.isVisibleViewHierarchy = false;
    this.isPivot = false;
    this.textWidth = [];
    this.pageNumber = 1;
    this.totalRecords = 0;
    this.filterList = {};
    this.childListModel = null;
    this.selectedFilterModel=new SelectedFilterModel();
  }
}

export interface ListModelProps {
  kagamiListModel: KagamiListModel;
}

export const wrapListState = (state: State<KagamiListModel>) => {
  let listState = state;

  return {
    getListLoaded() {
      return listState.isListLoaded.get();
    },
    setListLoaded(value: boolean) {
      listState.isListLoaded.set(value);
    },
    getViewType() {
      return listState.viewType.get();
    },
    setViewType(value: string) {
      listState.viewType.set(value);
    },
    gettotalRecods() {
      return listState.totalRecords.get();
    },
    settotalRecords(value: number) {
      listState.totalRecords.set(value);
    },
  };
};

export const useListState = (state: State<KagamiListModel>) => {
  const listState = wrapListState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.LIST_STATE, listState);
  return listState;
};
