import { Chip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { computeDisplayString } from "../../service/FieldHelper";
import { getPresentationRuleImagePath } from "../../service/GridUtilService";
import { cellRendererCustomComponentsStyle } from "../../style/CellRendererCustomComponents";
import get from 'lodash/get';
import { executeDisplayCondition } from "../../../../../../service/PolicyExecutionService";
import { SystemConstants } from "../../../../../../../../../../../../constants/SystemConstants";
import { CommonUtils } from "../../../../../../../../../../../../utils/CommonUtils";
import {  getCurrencyIcon } from "../../../../../controls/numberTextbox/builder/KagamiNumberBulider";
import { NoBackpackSharp } from "@mui/icons-material";
import { ToWords } from "to-words";
import { setActiveRowNode } from "../../service/GridService";
import { getCammaSeperatedValue, getLocaleCodeCurrency } from "../../../../../../service/PresentationService";
import React, { useEffect } from "react";
import { KagamiGridModel } from "../../model/KagamiGridModel";
import { KagamiFormModel } from "../../../../../form/model/KagamiFormModel";
import { getFormModel } from "../../../../../form/controller/KagamiFormController";

const useStyles = makeStyles(cellRendererCustomComponentsStyle);
const KagamiNumberCellRenderer =React.memo((props: any) => {
    let dispConditions: any = {};
    let cellVal:any = 0;
    let value = props.data[props.column.colId]
    let kagamiGridModel : KagamiGridModel = props.gridModel;

    const handleOnClick = () =>{
        console.log('clicked on text cell renderer')
          if(kagamiGridModel && kagamiGridModel.isEditableEmbedList){
            setActiveRowNode(props,kagamiGridModel)
        }
        }
        
    if (props?.attributePresentation?.hasOwnProperty('uiSettings') && props?.attributePresentation?.uiSettings.hasOwnProperty('commaSeparated') && props?.attributePresentation.uiSettings.commaSeparated) {
        cellVal = (value === SystemConstants.REMOVED_ || CommonUtils.isEmpty(value) ? 0 : value.toString().includes('.') ?value.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + value.toString().split('.')[1] : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    } else {
        cellVal = (value === SystemConstants.REMOVED_ || CommonUtils.isEmpty(value) ? 0 : value)
    }

    if (props.attributePresentation?.policyMap?.presentation) {
        if(kagamiGridModel && kagamiGridModel.isEditableEmbedList && (CommonUtils.isNotEmpty(kagamiGridModel.activeRowNode) && kagamiGridModel.activeRowNode.data.id !== props.data.id)){
            let formModel : KagamiFormModel = getFormModel();
            delete formModel.formData.data[kagamiGridModel.mainEntityId];
        }
        dispConditions = executeDisplayCondition(props.data, props.attributePresentation);
    }
    let dispCurrency = dispConditions['currency-attribute'];

    if(props?.attributePresentation?.hasOwnProperty('uiSettings') && props?.attributePresentation?.uiSettings.hasOwnProperty('inWords') && props?.attributePresentation.uiSettings.inWords){
        let newCurrencyLocalCode = getLocaleCodeCurrency(dispCurrency);
        const toWords = new ToWords({
          localeCode: newCurrencyLocalCode,
          converterOptions: {
            currency: false,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: true,
          },
        });
        cellVal =
          typeof cellVal === "number"
            ? cellVal + " " + "(" + toWords.convert(cellVal).replaceAll("Dollars ", "") + ")"
            : cellVal;
      }

    let dispCellVal: any = cellVal;
    if (props?.attributePresentation?.uiSettings?.prefixType?.selected && !(dispCurrency && props?.attributePresentation?.uiSettings?.prefixType.value =='Currency')) {
        // dispCellVal = _.get(props.attributePresentation.uiSettings, 'prefixType.selected') + " " + dispCellVal;
        dispCurrency = get(props.attributePresentation.uiSettings, 'prefixType.selected');
    }
    
    let currencySymbolArr = ['Indian Rupee','Rupee','rupee','Rupees','rupees','INR'];
    let isCurrencySymbol = false;
    if(dispCurrency ){
        // isCurrencySymbol = (_.indexOf(currencySymbolArr,dispCurrency )!=-1) ? true:false;
        isCurrencySymbol  = true;
    }
    if (dispConditions.color && dispCurrency) {
        let bgColor = dispConditions.color;
        return (
            <div onClick={handleOnClick}>
                {dispCurrency &&  isCurrencySymbol ?
                    (<div className="cell-number-currency" style={{ backgroundColor: bgColor ? bgColor : 'default', 'width': '100%' }}>   {getCurrencyIcon(dispCurrency)}  <span>{dispCellVal} </span></div>)
                    : (<div style={{ backgroundColor: bgColor ? bgColor : 'default', 'width': '100%' }}>  <span dangerouslySetInnerHTML={{ __html: dispCurrency }} /> {dispCellVal} </div>)
                }
            </div>
        )
    } else if (dispCurrency) {
        return(
         <div onClick={handleOnClick}>{
            dispCurrency && isCurrencySymbol ? 
            props.attributePresentation.uiSettings.commaSeparated && CommonUtils.isNotEmpty(props.attributePresentation.uiSettings.prefixType)   ? <div className="cell-number-currency">  {getCurrencyIcon(props.attributePresentation.uiSettings.prefixType.code)}   <span>{getCammaSeperatedValue(dispCellVal , props.attributePresentation.uiSettings.prefixType.code)}</span></div>  : 
             (<div className="cell-number-currency">  {getCurrencyIcon(dispCurrency)}   <span>{dispCellVal} </span></div>)
            : (<div>  <span dangerouslySetInnerHTML={{ __html: dispCurrency }} />  {
                }</div>)
        }</div>
        )
    }
    return (<div onClick={(event) => {
        console.log('clicked on number cell renderer')
        handleOnClick();
    }}> {dispCellVal}</div>)

})

export default  KagamiNumberCellRenderer;
