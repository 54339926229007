import { CommonUtils } from "../../../../../../../../../../../../utils/CommonUtils"
export const CUSTOM_TILE_HEADER = "Header";
export const CUSTOM_TILE_VALUE = "Value";
export const CUSTOM_TILE_VALUE1 = "Value1";
export const CUSTOM_TILE_INNER_VALUE = "Inner Value";
export const getDoughnutWidgetData = (chartObj: any,color:any) => {
    let data = [{ name: 'Percenge', y: chartObj[CUSTOM_TILE_INNER_VALUE], color: color || CommonUtils.getRandomColor() }];
    if (chartObj[CUSTOM_TILE_INNER_VALUE] != 100) {
        data.push({ name: 'Normal', y: 100 - chartObj[CUSTOM_TILE_INNER_VALUE], color: '#f9f9f9' });
    }
    return data;
}
export const getGaugeWidgetData = (chartObj: any,color:any) => {
    let data = [{ name: 'Percenge', y: chartObj[CUSTOM_TILE_INNER_VALUE], color: color || CommonUtils.getRandomColor() }];
    if (chartObj[CUSTOM_TILE_INNER_VALUE] != 100) {
        data.push({ name: 'Normal', y: 100 - chartObj[CUSTOM_TILE_INNER_VALUE], color: '#f9f9f9' });
    }
    return data;
}