import { NumberValidator } from '../numberTextbox/validations/NumberValidator';
import { TextValidator } from '../textbox/validations/TextValidator';
import { DateValidator } from '../date/validations/DateValidator';
import { SelectFieldValidator } from '../select/validations/SelectFieldValidator';
import { FileValidator } from '../file/validations/FileValidations';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { RadioValidator } from '../radio/validations/RadioValidator';
export default class KagamiControlValidator {
  static _validateEmail(value: any) {
    let emailRegExp = RegExp('(^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$)');
    return emailRegExp.test(value);
  }

  static _validatePhoneNumber(value: any) {
    let telRegExp = RegExp('(^(1s?)?((d{3})|d{3})[s-]?d{3}[s-]?d{4}$)');
    return telRegExp.test(value);
  }

  static validate(type: string, value: any, validations: any, controlModel?: any) {
    if(CommonUtils.isNotEmpty(validations)) {
    switch (type) {
      case 'text':
      case 'textarea':
      case 'contact':
      case 'barcode':
        return TextValidator.validate(validations, value);

        case 'search':
        case 'multiselect':
        case 'select':
          return SelectFieldValidator.validate(validations, value);

        case 'number':
          return NumberValidator.validate(validations, value);

        case 'radio':
          return RadioValidator.validate(validations, value);

        case 'file':
          return FileValidator.validate(
            validations,
            value,
            CommonUtils.isNotEmpty(validations['fileFormat']) ? validations['fileFormat'] : null,
            controlModel
          );

        case 'dateTime':
        case 'date':
        case 'time':
          return DateValidator.validate(validations, value);
      }
    }
    return null;
  }
}
