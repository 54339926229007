import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React from 'react';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CUSTOM_TILE_HEADER, CUSTOM_TILE_INNER_VALUE, CUSTOM_TILE_VALUE, getDoughnutWidgetData } from '../graphic/customTile/service/CustomTileReportUtil';
import { getReportAdditionalSettingsByProperty } from '../../service/ReportUtilService';


export const CircleWidget = (props: any) => {
    let chartObj = props.chartObj;
    let reportSettings = props.reportSettings;
    let chartColors = getReportAdditionalSettingsByProperty(reportSettings,'colors') ||  ['#659C45', '#E27C16', '#00A5FD', '#1E3148', '#1E3148', '#8F7849'];

    const chartComponent: any = React.useRef(null);
    const containerRef = React.useCallback((node: any) => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            chartComponent?.current?.chart.reflow();
        });
        resizeObserver?.observe(node);
    }, []);

    const circleConfig = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            maxHeight: 250,
            minHeight: 100,
            // spacingTop: 0,    // Remove top spacing
            // spacingRight: 0,  // Remove right spacing
            // spacingBottom: 0, // Remove bottom spacing
            // spacingLeft: 0,   // Remove left spacing
            // height: 120,
            // width: null // This ensures the chart takes the full width of the container
            events: {
                load: function () {
                    adjustTitlePosition(this);
                },
                redraw: function () {
                    adjustTitlePosition(this);
                }
            }
        },
        tooltip: {
            enabled: false // Disable tooltips
        },
        title: {
            text: chartObj[CUSTOM_TILE_INNER_VALUE] || '', // Text to display in the center
            align: 'center',
            verticalAlign: 'middle',
            y: 10,
            style: {
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#fff'
            }
        },
        subtitle: {
            text: chartObj[CUSTOM_TILE_HEADER],
            verticalAlign: 'bottom',  // This keeps the subtitle at the bottom
            y: -90                     // Adjusts the vertical position
        },
        plotOptions: {
            pie: {
                innerSize: '0%',
                dataLabels: {
                    enabled: false,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        exporting: {
            enabled: false // Disable the export button
        },
        colors: chartColors,
        series: [{
            name: '',
            data: [{ name: 'Data', y: 100, color: chartColors[props.index] || CommonUtils.getRandomColor() }],
            marker: {
                enabled: false // Hide markers to reduce clutter
            },
        }],
    }

        // Function to adjust title and subtitle positions
        function adjustTitlePosition(chart: any) {
            const title = chart.title;
            const subtitle = chart.subtitle;
    
            // Calculate dynamic positions based on chart height
            const chartHeight = chart.chartHeight;
    
            title.update({ y: chartHeight / -30 });  // Set dynamic y position for title
            subtitle.update({ y: chartHeight / -20 });  // Set dynamic y position for subtitle
        }
    

    return (
        <div className="circle-container">
            <div className='chart-box' ref={containerRef}>
                <Chart ref={chartComponent} highcharts={Highcharts} options={circleConfig} />
                {/* <Chart highcharts={Highcharts} options={circleConfig} /> */}
            </div>
            {
            !props.hideArrow && 
                <div className='icon-box'>
                    <ArrowForwardIcon></ArrowForwardIcon>
                </div>
            }
        </div>
    )
}
            // <div className="content">
            //     {/* <div className="title">{chartObj[CUSTOM_TILE_VALUE]}</div> */}
            //     <div className="sub-title">{chartObj[CUSTOM_TILE_HEADER]}</div>
            // </div>