import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { APIService } from '../../../../../../../service/ApiService';
import {
  getDashboardLayout,
  getDelInfoNotificationURL,
  getNotificationURL,
  getReportListURL,
  setDashboardLayout,
  getNotificationDataURL
} from '../../../../../../../service/URLService';
import { DashboardType, KagamiDashboardModel } from '../model/KagamiDashboardModel';
import { rebuildDashboard } from '../controller/KagamiDashboardController';
import { hideLoader, showLoader } from '../../../../../../../../components/loader/controller/KagamiLoaderController';
import { buildDashboardReportSections } from '../builder/DashboardReportBuilder';
import { getHomeModel } from '../../../../../controller/KagamiHomeController';

export async function getDashboardData(dashboardModel: KagamiDashboardModel) {
  showLoader();
  await getPrevDashboardLayout(dashboardModel);
  await getNotification(dashboardModel);
  await buildDashboardReportSections(dashboardModel);
  hideLoader();
  dashboardModel.state.setDataLoaded(true)
}

export async function getPrevDashboardLayout(dashboardModel: KagamiDashboardModel) {
  await getDashboardLayoutData().then((resp:any)=>{
    dashboardModel.layoutContainer = resp.data;
    // dashboardModel.notificationLayouts = JSON.parse(dashboardModel.layoutContainer.find(
    //   (dd: any) => dd.pageName === 'NotificationLayout'
    // )['reqData']);
    dashboardModel.reportLayouts = JSON.parse(dashboardModel.layoutContainer.find(
      (dd: any) => dd.pageName === 'ReportLayout'
    )['reqData']);
  }).catch((err:any)=>{
    console.log('layout error :: ',err);
  })
}

export async function getNotification(dashboardModel: KagamiDashboardModel) {
  const loginData = localStorage.getItem(SystemConstants.LOGIN_DATA);
  if (loginData !== null) {
    const loginObject = JSON.parse(loginData);
    await getNotificationData(loginObject.userId)
      .then((notificationResponse: any) => {
        // hideLoader();
        dashboardModel.notificationData = notificationResponse.data;
      })
      .catch((err: any) => {
        console.log('DashboardSrvice.ts - Failed To Load Dashboard Notification Data', err);
      });
  }
}

function removeDuplicateObjectFromArray(array: any[], key: string | number) {
  return array.filter(
    (obj: { [x: string]: any }, index: any, self: any[]) =>
      index === self.findIndex((el: { [x: string]: any }) => el[key] === obj[key])
  );
}

export async function getAllFavouriteReportsData(dashboardModel: KagamiDashboardModel) {
  await getReportsByRoleData()
    .then((reportResponse: any) => {
      const favouriteReports: any[] =
        reportResponse.data?.respObject !== undefined
          ? removeDuplicateObjectFromArray(
              reportResponse.data?.respObject.filter((ele: any) => ele.favourite),
              'id'
            )
          : [];
      dashboardModel.reportData = favouriteReports;
      const homeModel = getHomeModel();
      if(!favouriteReports.length) homeModel.currentDashboardScreen = DashboardType.NOTIFICATIONS;
    })
    .catch((err: any) => {
      console.error('Failed To Load Dashboard Report Data : ', err);
    });
}

// need to replace this with current one
function getDashboardDataAndLayout(dashboardModel: KagamiDashboardModel){
  const loginData = localStorage.getItem(SystemConstants.LOGIN_DATA);
  if (loginData !== null) {
    const loginObject = JSON.parse(loginData);
    showLoader();
    getDashboardNotiReportDataAndLayout(loginObject.userId).then((response:any)=>{

    }).catch((error:any)=>{
      console.log('something went wrong..!');
    })
  }else{
    console.log('login data can not be null. please logged in first..!')
  }
}

function getDashboardNotiReportDataAndLayout(userId:string){
  return Promise.all([getNotificationData(userId), getReportsByRoleData(), getDashboardLayoutData()]);
}

function getNotificationData(userId:string){
  return APIService.getData(SystemConstants.GET, getNotificationURL(userId), {})
}

function getReportsByRoleData() {
  return APIService.getData(SystemConstants.GET, getReportListURL(), {});
};

async function getDashboardLayoutData(){
  return await APIService.getData(SystemConstants.GET, getDashboardLayout(), {})
}

export async function setDashboardLayoutData(layout:any, dashboardModel: KagamiDashboardModel){
  return await APIService.getData(SystemConstants.POST, setDashboardLayout(), layout).then((response:any)=>{
    console.log(response);
    if(!dashboardModel.layoutContainer.find((layout:any)=>layout.id==response.data.id)){
      dashboardModel.layoutContainer.push(response.data)
    }
  }).catch((error:any)=>{
    console.log(error);
  })
}

export function groupByWidgetType(notificationData: any) {
  const widgetGroups: Map<string, any[]> = new Map();
  widgetGroups.set(SystemConstants.TODO, []);
  widgetGroups.set(SystemConstants.INFO, []);
  for (let notification of notificationData) {
    widgetGroups.get(notification['widgetName'])?.push(notification);
  }
  groupByNotificationType(widgetGroups);
  //TODO better to sort the map  display name
  return widgetGroups;
}

export function groupByNotificationType(widgetGroups: Map<string, any[]>) {
  const notifications: any[] | undefined = widgetGroups.get(SystemConstants.TODO);

  if (notifications !== undefined) {
    for (let notification of notifications) {
      if (widgetGroups.get(notification['displayName']) == null) {
        const section: any[] = [];
        section.push(notification);
        widgetGroups.set(notification['displayName'], section);
      } else {
        widgetGroups.get(notification['displayName'])?.push(notification);
      }
    }
  }
  widgetGroups.delete(SystemConstants.TODO);
}

export async function deleteNotificationById(notificationId:string, dashboardModel: KagamiDashboardModel ){
  console.log(notificationId)
  const apiResonse = await APIService.getData(SystemConstants.GET, getDelInfoNotificationURL(notificationId),null)
  .then(async (response:any) => {
    if(response.data.status === 'success' && dashboardModel){
       await getNotification(dashboardModel)  
        rebuildDashboard(dashboardModel)
      };
  }
  )
  .catch((err:any)=> console.log('notification deletion failed'));
  return apiResonse; 
} 


export async function getNotificationDataById(notificationId:string) {
  const apiResonse = await APIService.getData(SystemConstants.GET, getNotificationDataURL(notificationId), null)
  .then((response:any) => {
    if (response.status === 200) {
      return response.data;
    }
  })
  .catch((err:any) => console.log('notification data failed'));
  return apiResonse;
} 

