import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-enterprise';
import { useMemo } from 'react';
import { retrievePresentation } from '../../../service/PresentationService';
import { KagamiGridPaginationModel } from '../../list/builder/grid/builder/pagination/model/KagamiGridPaginationModel';
import { KagamiGridModel, useKagamiGridState } from '../../list/builder/grid/model/KagamiGridModel';
import { buildGridControlModels } from '../../list/builder/grid/service/GridService';
import { getKagamiListModel, setKagamiListModel } from '../../list/controller/KagamiListController';
import { KagamiListModel } from '../../list/model/KagamiListModel';
import {
  getEndRecord,
  getListTotalRecords,
  getPaginationForEmbedForm,
  getPaginationForList,
  getStartRecord,
  haveHyperlink,
  retrieveListRows
} from '../../list/service/kagamiListService';
import { KagamiGridStyle } from '../../list/builder/grid/style/KagamiGridStyle';
import { createState } from '@hookstate/core';
import makeStyles from '@mui/styles/makeStyles';
import { disableAllTheTriggers } from '../../Triggers/service/KagamiTriggersService';
import { KagamiActivityLogModel } from '../../../../../../../../../../components/activityLog/model/KagamiActivityLogModel';
import KagamiStatusCellRenderer from '../../list/builder/grid/builder/cell_renderers/KagamiStatusCellRenderer';
import { getListEditColumns } from './ColumnBuilder';
import { appContext } from '../../../service/PolicyExecutionService';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';

export const buildListEditView = (kagamiListEditModel: KagamiListModel) => {
  return <KagamiListEdit kagamiListEditModel={kagamiListEditModel} />;
};

const useStyles = makeStyles(KagamiGridStyle);

function KagamiListEdit(props: any) {
  import('ag-grid-enterprise');
  const { kagamiListEditModel } = props;
  let paginationModel = new KagamiGridPaginationModel(
    false,
    false,
    getListTotalRecords(kagamiListEditModel.onStartData),
    getStartRecord(kagamiListEditModel.onStartData),
    getEndRecord(kagamiListEditModel.onStartData),
    kagamiListEditModel.mainEntityId,
    kagamiListEditModel.processName,
    retrievePresentation(kagamiListEditModel.onStartData)
  );

  let activityLogModel: KagamiActivityLogModel = new KagamiActivityLogModel();

  let kagamiGridModel: KagamiGridModel = new KagamiGridModel(
    kagamiListEditModel.processName,
    kagamiListEditModel.processStepName,
    kagamiListEditModel.mainEntityId,
    kagamiListEditModel.presentation,
    paginationModel
  );
  if (CommonUtils.isNotEmpty(appContext) && CommonUtils.isNotEmpty(appContext.AppSetting[0])) {
    kagamiGridModel.pageSize = appContext.AppSetting[0]?.pageSize;
    kagamiGridModel.paginationForList = getPaginationForList(appContext);
    kagamiGridModel.paginationForEmbedForm = getPaginationForEmbedForm(appContext);
  }
  let totalPages = Number(getListTotalRecords(kagamiListEditModel.onStartData)) / Number(kagamiGridModel.pageSize);
  kagamiGridModel.totalPages = Math.ceil(totalPages);
  kagamiGridModel.paginationModel.totalRecords = kagamiGridModel.totalRecords;
  kagamiGridModel.isGroupListModel = kagamiListEditModel.isGroupListModel;
  kagamiGridModel.detailedObjects = kagamiListEditModel.detailedObjects;
  kagamiGridModel.isListLoaded = kagamiListEditModel.isListLoaded;
  kagamiGridModel.triggersModel = kagamiListEditModel.triggersModel;
  kagamiGridModel.docInfo = kagamiListEditModel.docInfo;
  kagamiGridModel.isPickItemListModel = kagamiListEditModel.isPickItemListModel;
  kagamiGridModel.showGridDataCount = false;
  kagamiGridModel.isEmbedModel = true;

  setKagamiListModel(kagamiGridModel);
  getKagamiListModel().onStartData = kagamiListEditModel.onStartData;
  kagamiGridModel.hasHyperlink = haveHyperlink(kagamiGridModel.presentation);
  buildGridControlModels(kagamiGridModel);

  let classes = useStyles();
  let listState = useKagamiGridState(createState(kagamiGridModel), kagamiGridModel.mainEntityId);
  kagamiGridModel.state = listState;
  if (!kagamiGridModel.isListEmbedPopModel) setKagamiListModel(kagamiGridModel);
  let rows: any = [];

  if (listState.getListLoaded()) {
    rows = retrieveListRows(kagamiGridModel);
    if (kagamiGridModel.isPickItemListModel && rows.length === 0) {
      disableAllTheTriggers(kagamiGridModel.triggersModel);
    }
  }

  console.log(kagamiListEditModel, kagamiGridModel, rows);

  const defaultColDef = useMemo<any>(() => {
    return {
      flex: 1,
      sortable: true,
      minWidth: 125,
      filter: true,
      resizable: true,
      suppressMenuHide: true
    };
  }, []);

  return (
    <div
      style={{ height: '100%', width: '100%' }}
      className={'ag-theme-alpine kgm-grid ' + kagamiGridModel.mainEntityId}
    >
      <AgGridReact
        stopEditingWhenCellsLoseFocus={false}
        suppressDragLeaveHidesColumns={true}
        preventDefaultOnContextMenu={true}
        suppressColumnVirtualisation={true}
        editType={'fullRow'}
        singleClickEdit={true}
        rowData={rows}
        domLayout={'autoHeight'}
        components={{
          KagamiStatusCellRenderer: KagamiStatusCellRenderer
        }}
        defaultColDef={defaultColDef}
        suppressCopyRowsToClipboard={true}
      >
        {getListEditColumns(kagamiGridModel, kagamiGridModel.kagamiModalModel, activityLogModel)}
      </AgGridReact>
    </div>
  );
}

export default KagamiListEdit;
