import { State, useState } from '@hookstate/core';
import { ReportModalModel } from '../../ReportModal/model/ReportModalModel';

export class KagamiReportModel {
  filter: any;
  reportFilterModel:any;
  reportModalModel = new ReportModalModel();
  reportConfig:any;
  reportConfigSummary: any;
  fromDashboard:Boolean = false;
  fromDrillDown:Boolean = false;
  reportSettings:any; 
  translationLanguage: any; 
  languageTranslation: any;
}

export interface KagamiReportModelProps {
 kagamiReportModel: KagamiReportModel;
}