import { InputAdornment } from '@mui/material'; 
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CallIcon from '@mui/icons-material/Call';


export class KagamiTextBoxBuilder {}

export const buildIconsForReadOnly = (validation : any) => 
{
  if(validation.email) {
  return ( 
        <EmailOutlinedIcon  fontSize='small' color='secondary'/>
  );
  } else if(validation.phone || validation.cell) {
    return (  
          <CallIcon fontSize='small' color='secondary'  />
    );
  } else  {
    return <></>
  }
}

export const buildIconsForTextBox = (validations: any) => {
  if (null != validations && validations) {
    let validation: any;
    for ([validation] of Object.entries(validations)) {
      switch (validation) {
        case 'email':
          return (
            <div>
              <InputAdornment position="start">
                <EmailOutlinedIcon
                  sx={{
                    color:(theme) =>theme.palette.secondary.main
                  }}
                    style={{fontSize: '15px'}} />
              </InputAdornment>
            </div>
          );

        case 'cell':
          return (
            <div>
              <InputAdornment position="start">
                <LocalPhoneOutlinedIcon 
                  sx={{
                    color:(theme) =>theme.palette.secondary.main
                  }}
                 style={{fontSize: '15px'}} />
              </InputAdornment>
            </div>
          );

        case 'fax':
        case 'website':
          return (
            <div>
              <InputAdornment position="start">
                <LanguageOutlinedIcon
                  sx={{
                    color:(theme) =>theme.palette.secondary.main
                  }}
                 style={{fontSize: '15px'}}/>
              </InputAdornment>
            </div>
          );

           
          default :
            return (
              <></>
            )
        // case 'fax':
        //   return (
        //     <div>
        //       <InputAdornment position="start">
        //         <LanguageOutlinedIcon  style={{fontSize: '15px'}}/>
        //       </InputAdornment>
        //     </div>
        //   );
      }
    }
  }
};