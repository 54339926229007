import extend from "lodash/extend";
import { getReportData } from "../../../../service/ReportUtilService";
import { FunnelReportModel } from "../model/funnelReportModel";

const funnelDefaultConfig = () => {
    return {
      chart: {
          type: 'funnel'
      },
      title: {
          text: ''
      },
      plotOptions: {
          series: {
              dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b> ({point.y:,.0f})',
                  softConnector: true
              },
              center: ['50%', '50%'],
              neckWidth: '10%',
              neckHeight: '10%',
              width: '30%'
          }
      },
      legend: {
          enabled: false
      },
      credits: {
        enabled: false,
      },
      series: [{
          name: 'ffff',
          data: []
      }],
  
      responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                  plotOptions: {
                      series: {
                          dataLabels: {
                              inside: true
                          },
                          center: ['50%', '50%'],
                          width: '100%'
                      }
                  }
              }
          }]
      }
  }
}

export const makeFunnel = (funnelReportModel: FunnelReportModel) => {
    let defaultConfig  = funnelDefaultConfig();
    let dbChartData = JSON.parse(funnelReportModel.reportData);
    let tablerecords = getReportData(dbChartData, funnelReportModel.reportConfig);
    let userConfig = prapareUserConfigForChart(tablerecords,funnelReportModel.reportConfig,false);
    let dispchartOptions = extend(true, {}, defaultConfig, userConfig);

    return dispchartOptions;
}

const prapareUserConfigForChart = (tablerecords:any,reportConfig:any,isDashboard:any)  => {
  let userConfig: any = {
    series: [
      {
        data: [],
        innerSize: '70%'
      }
    ]
  };
  
  let seriesData:any[]=[];
  let xaxis = reportConfig['verticalAxis'];
  let yaxis = reportConfig['horizontalAxis'];
//   let key:string='';
//   yaxis.some((yxis:any)=>{
//     if(tablerecords.length>0 && typeof(tablerecords[0][yxis])==='number'){
//         // console.log(typeof(tablerecords[0][yxis]));
//         key=yxis;
//         return true;
//     }
//   })
//   if(tablerecords.length>0 && typeof(tablerecords[0][yaxis[0]])==='number'){
//     key = yaxis[0];
//   }
//   tablerecords.sort((a:any,b:any)=>{return b[key]-a[key]})
tablerecords.sort((a:any,b:any)=>{return b[yaxis[0]]-a[yaxis[0]]})
  tablerecords.forEach((record:any)=>{
    // let y = (typeof(+record[key])!=='number')? (+record[key]):1;
      seriesData.push([record[xaxis[0]],+record[yaxis[0]]]);
  })

  userConfig.series[0].data = seriesData;
  return userConfig;
}