import { State, useState } from '@hookstate/core';

export class ReportFilterModel {
  [x: string]: any;
  filters: any;
  filterValues: any;
  filterSetting: any = {};
  selectedValue: Map<string, any> = new Map();;
  displayFilterValue:any[] = [];
  modalModel: any;
  state: any;
  filterModels: any;
  fromDashboard:boolean = false;
  reportId:any;
  translations:any;
  constructor(filters: any, filterValues: any={},reportId: any,translations?:any) {
    this.filters = filters;
    this.filterValues = filterValues;
    this.filterModels = new Map();
    this.reportId = reportId;
    this.translations=translations;
  }
}
export interface ReportFilterModelProps {
  reportFilterModel: ReportFilterModel;
  translations?: any;
}

export const useFilterState = (state: State<ReportFilterModel>) => {
  let filterstate = useState(state);

  return {
    getFilterValues() {
      return filterstate.reportFilterValues.get();
    },

    SetFilterValues(value: any) {
      filterstate.reportFilterValues.set(value);
    }
  };
};