import { KagamiReportModel } from '../model/KagamiReportModel';

import { renderReport } from '../../../../../controller/KagamiBodyController';
import { executeReportWithOrWithoutFilter } from '../builder/KagamiReportBuilder';
import { getFilterData, getReportConfig, getReportTranslationData } from '../service/KagamiReportService';
import { ReportFilterModel } from '../../../filter/model/KagamiReportFilterModel';
import { getLandingPageModel } from '../../../landingPage/controller/ReportsLandingPageController';
import { haveMandatoryField } from '../../../filter/service/ReportFilterUtilService';
import { buildReportFilter } from '../../../filter/controller/KagamiReportFilterController';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { ReportConstant } from '../../ReportConstant/ReportConstant';
import { translateToLocalistaion } from '../../../../process/service/PresentationService';
import { isStaticReport } from '../service/ReportUtilService';
import { handleStaticReportFilters } from '../../../staticReportFilters/service/StaticReportFiltersService';
import { getSystemLangugeFromAppSettings } from '../../../../../../../../../utils/CommonUtils';

let kagamiReportModel: KagamiReportModel;

export async function executeReportOnClick(reportId: string, translations?: any) {
  return await executeReport(reportId, translations);
}

export function renderReportContent(reportContent: any) {
  return renderReport(reportContent);
}

export function setReportModel(reportModel: KagamiReportModel) {
  kagamiReportModel = reportModel;
}

export function getReportModel() {
  return kagamiReportModel;
}
export function reBuildReports(reportModel: KagamiReportModel) {
  kagamiReportModel = reportModel;
}

export async function executeReport(reportId: any, translation: any) {
  let reportLandingPageModel = getLandingPageModel();
  let response: any = await getReportConfig(reportId);
  let translations = translation;
  let reportConfig = response?.data?.respObject;
  let staticReport = isStaticReport(reportConfig?.reportConfigSummary.type) || false;
  if(staticReport) {
    delete reportConfig.subOrganization; // for static reports we are getting filter data in other api
  }
  let systemLanguage = getSystemLangugeFromAppSettings()
  let languageTranslation = await getReportTranslationData(reportConfig, systemLanguage);


  if (reportConfig != undefined && reportConfig['reportConfigSummary']) {
    let reportModel: KagamiReportModel = new KagamiReportModel();
    reportModel.reportConfig = reportConfig;
    reportModel.reportConfigSummary = reportConfig.reportConfigSummary;
    reportModel.languageTranslation = languageTranslation;
    setReportModel(reportModel);
    let filters = reportConfig.dynamicParameterList;
    if(staticReport) {
      const loginData = localStorage.getItem(SystemConstants.LOGIN_DATA);
      let userId = null;
      if (loginData !== null) {
        let loginObject = JSON.parse(loginData);
        userId = loginObject.userId;
      }
      let requestData = { reportId: reportId, pageNo: 0, userId: userId };
      handleStaticReportFilters(reportLandingPageModel, reportModel );
    }else if (filters.length > 0) {
      reportModel.filter = filters;
      const loginData = localStorage.getItem(SystemConstants.LOGIN_DATA);
      let userId = null;
      if (loginData !== null) {
        let loginObject = JSON.parse(loginData);
        userId = loginObject.userId;
      }

      let requestData = { reportId: reportId, pageNo: 0, userId: userId };
      let filterDataResponse: any = await getFilterData(reportId, requestData);
      let reportFilterModel = new ReportFilterModel(filters, filterDataResponse.data.respObject, reportId,translations);
      reportFilterModel.modalModel = reportLandingPageModel.modalModel;
      reportFilterModel.filterSetting = reportConfig?.reportSettings?.filterSettings ?? {};
      setDefaultSelectedValue(reportFilterModel);

      if (reportFilterModel.filters.length > 0) {
        if (haveMandatoryField(reportFilterModel.filters)) {
          reportLandingPageModel.modalModel.disabledButton = true;
        } else {
          reportLandingPageModel.modalModel.disabledButton = false;
        }
        reportLandingPageModel.modalModel.title = translateToLocalistaion(reportFilterModel.translations, 'Filters');
        reportLandingPageModel.modalModel.isReportModel = true;
        reportLandingPageModel.modalModel.content = buildReportFilter(reportFilterModel, false, reportFilterModel.translations);
        reportLandingPageModel.modalModel.state.setOpen(true);
        reportLandingPageModel.modalModel.handleOnOk = async () => {
          reportModel.reportFilterModel = reportFilterModel;
          reportLandingPageModel.content = await executeReportWithOrWithoutFilter(reportModel);
          reportLandingPageModel.isContentLoaded = true;
          reportLandingPageModel.state.setDataLoaded(true);
        };
        reportLandingPageModel.modalModel.handleOnClose = () => {};
      } else {
        reportModel.reportFilterModel = reportFilterModel;
        reportModel.filter = filters;
        reportLandingPageModel.content = await executeReportWithOrWithoutFilter(reportModel);
        reportLandingPageModel.isContentLoaded = true;
        reportLandingPageModel.state.setDataLoaded(true);
      }
    } else {
      reportLandingPageModel.content = await executeReportWithOrWithoutFilter(reportModel);
      reportLandingPageModel.isContentLoaded = true;
      reportLandingPageModel.state.setDataLoaded(true);
    }
  }
}

export const setDefaultSelectedValue = (reportFilterModel: ReportFilterModel) => {
  let filters: any[] = [];
  reportFilterModel.filters.forEach((filterConfig: any) => {
    if(!filterConfig.isuser && filterConfig['defaultDisplayAttributes'] === undefined){
      if(filterConfig.value === ''){
        filters.push(filterConfig)
      }else if(filterConfig.defaultValueVisibility){
        filters.push(filterConfig)
      }
    }
    // else {
    //   filters.push(filterConfig)
    // }

    if (filterConfig['defaultDisplayAttributes'] !== undefined) {
      if (filterConfig['relationName'] === ReportConstant.In || filterConfig['relationName'] === ReportConstant.Not_In) {
        reportFilterModel.selectedValue.set(
          filterConfig['name'],
          filterConfig['defaultDisplayAttributes'].join()
        );
      } else if (
        filterConfig['relationName'] === ReportConstant.Is_In_The_Range ||
        filterConfig['relationName'] === ReportConstant.Is_Not_In_The_Range
        ) {
        reportFilterModel.selectedValue.set(
          filterConfig['name'],
          filterConfig['defaultDisplayAttributes'].join()
        );
      } else {
        reportFilterModel.selectedValue.set(filterConfig['name'], filterConfig['defaultDisplayAttributes']);
      }
    } else if (filterConfig['value'] !== '') {
      reportFilterModel.selectedValue.set(filterConfig['name'], filterConfig['value']);
    } else {
      if (filterConfig['relationName'] === ReportConstant.In || filterConfig['relationName'] === ReportConstant.Not_In) {
        reportFilterModel.selectedValue.set(filterConfig['name'], 'undefined');
      } else {
        reportFilterModel.selectedValue.set(filterConfig['name'], 'NOPOP');
      }
    }
  });
      
  reportFilterModel.filters = filters;
};

export const setDefaultFilterValuesForEach = (reportFilterModel: ReportFilterModel) => {
  reportFilterModel.filters.forEach((filterConfig: any) => {
    if (filterConfig.value === '') {
      if (filterConfig.relationName === ReportConstant.In) {
        reportFilterModel.selectedValue.set(filterConfig.name, 'undefined');
      } else {
        reportFilterModel.selectedValue.set(filterConfig.name, 'NOPOP');
      }
    } else {
      reportFilterModel.selectedValue.set(filterConfig.name, filterConfig.value);
    }
  });
};
