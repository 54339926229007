import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class ProfitLossReportModel extends KagamiReportModel {
  reportData: any;
  state: any;
  collapseAll:Boolean;
  constructor(reportContent: any) {
    super()
    this.reportData = reportContent;
    this.collapseAll=false;
  }
}
export interface ProfitLossReportModelProps {
  profitLossReportModel: ProfitLossReportModel;
}
export const useProfitLossReportModelState = (state: State<ProfitLossReportModel>) => {
  let profitLossReportState = useState(state);
  return {
    reportConfig() {
      profitLossReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      profitLossReportState.reportData.set(reportContent);
    }
  };
};
