import {createState } from "@hookstate/core"
import makeStyles from '@mui/styles/makeStyles';
import { KagamiModalDialogModel, KagamiModalDialogModelProps, useKagamiModalDialogModelState } from '../model/KagamiModalDialogModel'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { KagamiModalDialogStyle } from '../style/KagamiModelDialogStyle';
import { VisibleConstants } from '../../../../../../../../../constants/VisibeConstants';
import { handleModalOnClose, handleModalOnOk } from '../controller/KagamiModalDialogController';
import { translateToLocalistaion } from "../../../service/PresentationService";
import { getActiveModel } from "../../../controller/KagamiProcessController";
import { CommonUtils } from "../../../../../../../../../utils/CommonUtils";


export function builModalDialogComponent (kagamiModalDialogModel: KagamiModalDialogModel) {
    // let formComponents = buildPresentation(kagamiModalDialogModel);
    // kagamiModalDialogModel.formComponents = formComponents;

    return <KagamiModalDialog kagamiModalDialogModel={kagamiModalDialogModel} />
}
const useStyles = makeStyles(KagamiModalDialogStyle);

const KagamiModalDialog = (props: KagamiModalDialogModelProps) => {
  let classes = useStyles();
  let modalState = useKagamiModalDialogModelState(createState(props.kagamiModalDialogModel) );
  let translations: any;
  let activeModel = getActiveModel();
  // if (CommonUtils.isNotEmpty(activeModel)) {
    translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  // } 
  // else {
  //   let data: any = {ok: "D'accord"};
  //   translations = data;
  // }
  const handleClose =(event:any,reason:any)=>{
      if (reason && reason=='backdropClick')
      return;
      handleModalOnClose(props.kagamiModalDialogModel);
 }
  props.kagamiModalDialogModel.state = modalState;
  return (
    <Dialog
      fullWidth={true}
      scroll={'paper'}
      maxWidth={props.kagamiModalDialogModel.modalWidth}
      open={modalState.isOpen}
      // onClose = {handleClose}
      // onClose={() => handleModalOnClose(props.kagamiModalDialogModel)}
      classes={{
        root: classes.modalDialogTemplateRoot,
        paper: props.kagamiModalDialogModel.numberofListRecords > 12 ?  classes.modalPaperMaxHeight : classes.modalPaper,
        paperFullWidth: classes.paperFullWidth,
      }}
      aria-labelledby={props.kagamiModalDialogModel.title}
      aria-describedby="classic-modal-slide-description"
    >
       <IconButton className={classes.closeModalBtn} size='small' onClick={() => handleModalOnClose(props.kagamiModalDialogModel)}>
          <ClearIcon fontSize='medium'></ClearIcon>
        </IconButton>

      <DialogTitle className={classes.modalHeader} color="secondary" id={props.kagamiModalDialogModel.title}>
        {/* {props.kagamiModalDialogModel.title === 'Error' ? (
          <div className={classes.model_icon_error}> 
              {props.kagamiModalDialogModel.title === 'Error' ? 'Validations Failed...' : props.kagamiModalDialogModel.title}
            <ClearIcon onClick={() => handleModalOnClose(props.kagamiModalDialogModel)}></ClearIcon>
          </div>
        ) : (
          <>
          {props.kagamiModalDialogModel.title} 
          </>
        )} */}
        {/* <div className={classes.model_icon_error}>  */}
              {props.kagamiModalDialogModel.title === 'Error' ? 'Validations Failed...' : translateToLocalistaion(translations, props.kagamiModalDialogModel.title)}
            {/* <IconButton onClick={() => handleModalOnClose(props.kagamiModalDialogModel)}>
            <ClearIcon ></ClearIcon>
            </IconButton> */}
          {/* </div> */}
      </DialogTitle>
      <DialogContent
      classes={{
        root: classes.dialogContentRoot
      }}
        // style={{ textAlign: props.kagamiModalDialogModel.error == true ? 'start' : 'center' }}
        className={classes.modalBody}
      >
        {/* <br /> */}
        {props.kagamiModalDialogModel.content}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
       
        {props.kagamiModalDialogModel.title === 'Error' && props.kagamiModalDialogModel.isModalDialogTemplate 
        ? <></>
        : (
          <div className={classes.model_buttons}>
            <Button
              variant="outlined"
              
              onClick={() => handleModalOnOk(props.kagamiModalDialogModel)}
              className={classes.model_button}
            >
              {/* { VisibleConstants.okLabel} */}
              {translateToLocalistaion(translations, VisibleConstants.okLabel)}
            </Button>
             
          </div>
        )

        }
      </DialogActions>
    </Dialog>
  );
};
