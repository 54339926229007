import moment from "moment";
import { SystemConstants } from "../../../../../../../../../../../constants/SystemConstants";
import { CommonUtils } from "../../../../../../../../../../../utils/CommonUtils";
import { getAttributeLabel, getAttributeName, getAttributePresentationType, getAttributeValidations, getBusinessPolicy, getEntityAssociatedAttribute, getEntityConsumed, getEntityConsumedInAttribute, getEntityPrefix, getHtmlControl, getPolicyMap, getPresentationPolicy, isOnChangeRequiredForAttribute, isReadOnlyAttribute, isRequiredAttribute, isVisibleAttribute, retrievePresentation } from "../../../../../service/PresentationService";
import { prepareForLoadCall } from "../../../../../service/SubProcessService";
import { KagamiControlModel } from "../../../../controls/model/kagamiControlModel";
import { editRecord } from "../../../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/controller/KagamiEmbeddedAccordionPresentationController";
import { getFormModel, showValidationError } from "../../../../form/controller/KagamiFormController";
import { KagamiFormModel } from "../../../../form/model/KagamiFormModel";
import { getActiveFormRecord, getAssociatedObject, updateInFocusMap } from "../../../../form/service/FormService";
import { validate } from "../../../../form/service/FormValidationService";
import { getKagamiListModel, setKagamiListModel } from "../../../controller/KagamiListController";
import { onCellValueChanged } from "../../ListBuilder";
import { KagamiGridModel } from "../model/KagamiGridModel";
import { searchRecord } from "../../../service/kagamiListService";
import _, { delay } from "lodash";

export function getLovValuesForEmbedControl(kagamiGridModel : KagamiGridModel,keyValuesObject : any){
    
}

export function getValues(selectObject : any,gridApi : any){
    console.log('sdfjjd');
    return Object.keys(selectObject);
}

export function getValueForGridSearch(gridModel : KagamiGridModel,attributePresentation : any,rowData:any = null){
    let associatedObject : any = ''
    const attrName = getAttributeName(attributePresentation);
    if(CommonUtils.isNotEmpty(gridModel.activeRowNode) && gridModel.activeRowNode.data[attrName]){
        let entityPrefix = getEntityPrefix(attributePresentation);
        let entityConsumed = getEntityConsumed(attributePresentation)
        entityConsumed = CommonUtils.isNotEmpty(entityPrefix) ? entityPrefix+entityConsumed : entityConsumed;
        const entityConsumedData = rowData?rowData[attrName]:gridModel.activeRowNode.data[attrName];
        associatedObject = getAssociatedObject(getActiveFormRecord(getFormModel(),gridModel.mainEntityId,rowData?.id),entityConsumed,entityConsumedData,getEntityAssociatedAttribute(attributePresentation))
    }
    return associatedObject;
}

export function getValueForGridSelect(gridModel : KagamiGridModel,attributePresentation : any){
    let associatedObject : any = ''
    if(CommonUtils.isNotEmpty(gridModel.activeRowNode) && gridModel.activeRowNode.data[getAttributeName(attributePresentation)]){
    let entityPrefix = getEntityPrefix(attributePresentation);
    let entityConsumed = getEntityConsumed(attributePresentation)
    entityConsumed = CommonUtils.isNotEmpty(entityPrefix) ? entityPrefix+entityConsumed : entityConsumed
     associatedObject = getAssociatedObject(getActiveFormRecord(getFormModel(),gridModel.mainEntityId),entityConsumed,gridModel.activeRowNode.data[getAttributeName(attributePresentation)],getEntityAssociatedAttribute(attributePresentation))
    }
    return CommonUtils.isNotEmpty(associatedObject) ? associatedObject.id : SystemConstants.EMPTY
}

export function getValueForGridMultiSelect(gridModel : KagamiGridModel,attributePresentation : any){
    let selectedValues : any[] = [];
    if(CommonUtils.isNotEmpty(gridModel.activeRowNode) && gridModel.activeRowNode.data[getAttributeName(attributePresentation)]){
      selectedValues =  gridModel.activeRowNode.data[getAttributeName(attributePresentation)]
    }
    return selectedValues; 
}

export function getValueForGridRadio(rowNode : any,attributePresentation : any,gridModel : KagamiGridModel){
    let associatedObject : any = {};
    if(CommonUtils.isNotEmpty(rowNode)){
    let entityPrefix = getEntityPrefix(attributePresentation);
    let entityConsumed = getEntityConsumed(attributePresentation)
    entityConsumed = CommonUtils.isNotEmpty(entityPrefix) ? entityPrefix+entityConsumed : entityConsumed
     associatedObject = getAssociatedObject(getActiveFormRecord(getFormModel(),gridModel.mainEntityId),entityConsumed,rowNode.data[getAttributeName(attributePresentation)],getEntityAssociatedAttribute(attributePresentation))
    }
    return associatedObject;
}
export function getValueForGridDate(rowNode : any,attributePresentation : any){
    let dateValue : any;
    if(CommonUtils.isNotEmpty(rowNode) && CommonUtils.isNotEmpty(rowNode.data)){
       let dateval =  rowNode.data[attributePresentation.attrName]
            // if(attributePresentation.defaultValue === 'today' || dateval === 'today') {
            //     dateValue = moment(new Date())
            // } else if(dateval){
            //      dateValue = moment(dateval).format(SystemConstants.DD_MM_YYYY);
            // }    else {
            //     dateValue = null
            // }




            if(dateval) {
                if(dateval === 'today' ) dateValue = moment(new Date())
                else dateValue = moment(new Date(dateval)) 
            } else  if(attributePresentation.defaultValue === 'today' ){
                dateValue = moment(new Date()) 
            } else {
                dateValue = null
            }
    }
    return dateValue
}

export function getValueForGridCheckBox(gridModel:KagamiGridModel,attributePresentation : any){
    let checkboxValue :any;
    if(CommonUtils.isNotEmpty(gridModel.activeRowNode) && CommonUtils.isNotEmpty(gridModel.activeRowNode.data)){
        checkboxValue = gridModel.activeRowNode.data
    }
    return checkboxValue;
}

export function getLovRecodsIfAny(entityConsumed : string){
    let lovRecords : any[] = [];
    let formModel : KagamiFormModel = getFormModel();
    if(CommonUtils.isNotEmpty(formModel.formData.data[entityConsumed])){
        lovRecords = formModel.formData.data[entityConsumed];
    }
    return lovRecords;
}

export function getActiveRowNode(gridParams : any){
    let activeRowNode : any;
    let nodeList : any[] = gridParams?.api?.getRenderedNodes().filter((node : any) => node.rowIndex === gridParams.rowIndex)
    if(CommonUtils.isNotEmpty(nodeList)){
       activeRowNode = nodeList[0]
    }
    return activeRowNode;
}

export function buildGridControlModels(kagamiGridModel : KagamiGridModel,presentation?: any,formModel ?: KagamiFormModel | undefined){
  let presentationRules : any = CommonUtils.isNotEmpty(presentation) ? presentation.presentationRules : kagamiGridModel.presentation.presentationRules;
  let attributeName : string, attributePresentation : any;
  let dynamicColumn : any = Object.values(presentationRules).find((e:any) => e.matrixAxis === 'cell')
  for([attributeName,attributePresentation] of Object.entries(presentationRules)){
      let attrPresentationType : string = getAttributePresentationType(attributePresentation) 
      if(attrPresentationType === 'FieldPresentation'){
        if(dynamicColumn && attributePresentation.matrixAxis === 'x'){
          attributePresentation.onChangeRequired = dynamicColumn.onChangeRequired;
          attributePresentation['pivotOnChangeAttr'] = dynamicColumn.attrName
        }
        kagamiGridModel.gridControls[attributeName] = buildGridControlModel(attributePresentation,kagamiGridModel.mainEntityId,onCellValueChanged,)
        if(CommonUtils.isNotEmpty(formModel) && formModel?.isPivot && CommonUtils.isNotEmpty(attributePresentation['matrixAxis']) && attributePresentation.matrixAxis === 'x'){
             formModel.pivotConsumedEntity = getEntityConsumedInAttribute(attributePresentation)
        }
      }
      else if(attrPresentationType === 'SectionPresentation'){
        buildGridControlModels(kagamiGridModel,attributePresentation)
      }
  }
}

function buildGridControlModel(attributePresentation: any,entityId: string,onCellValueChanged: any) {
    let attributeName = getAttributeName(attributePresentation);
    let controlId = entityId + '.' + attributeName;
    const pickAttribute = attributeName.includes('.') ? true : false;
    let isReadOnly = isReadOnlyAttribute(attributePresentation);
    let isVisible = isVisibleAttribute(attributePresentation);
    let controlModel: KagamiControlModel = new KagamiControlModel(
      controlId,
      attributeName,
      isRequiredAttribute(attributePresentation),
      isVisible,
      entityId,
      isReadOnly,
      getHtmlControl(attributePresentation),
      null,
      getAttributeLabel(attributePresentation),
      true,
      isOnChangeRequiredForAttribute(attributePresentation),
      onCellValueChanged,
      null,
      pickAttribute,
      getPolicyMap(attributePresentation),
      getPresentationPolicy(attributePresentation),
      getBusinessPolicy(attributePresentation),
      isReadOnly,
      isVisible,
      '',
      true,
      true
    );
    controlModel.attributePresentation = attributePresentation;
    controlModel.validation = getAttributeValidations(attributePresentation);

    /// Note : making all the columns visible which have policies and visible as false to avoid grid shrink
    // if(!controlModel.visible && CommonUtils.isNotEmpty(controlModel.presentationPolicy)){
    //     controlModel.visible = true;
    // }
    return controlModel;
  }

  export function setActiveRowNode(params : any,kagamiGridModel : KagamiGridModel, isEmbedCheckBox?:boolean){
    if(kagamiGridModel.isPivot && params.node.footer){}
    else{
      let activeListModel: any = getKagamiListModel()
      if (activeListModel && activeListModel.mainEntityId !== kagamiGridModel.mainEntityId) 
      setKagamiListModel(kagamiGridModel);
      if (kagamiGridModel.isEditableEmbedList) {
        if (CommonUtils.isEmpty(kagamiGridModel.activeRowNode) || (CommonUtils.isNotEmpty(kagamiGridModel.activeRowNode) && kagamiGridModel.activeRowNode.rowIndex !== params.rowIndex && (isEmbedCheckBox || validatePreviousRecord(kagamiGridModel)))) {
          let formModel: KagamiFormModel = getFormModel();
          var colVal:any = params.node.data[params.column.colId];
          formModel.embedCheckBox={isEmbedCheckBox : isEmbedCheckBox,colId : params.column.colId, colVal : colVal};
          kagamiGridModel.activeRowNode = getActiveRowNode(params);
          if (kagamiGridModel.activeRowNode) {
            updateInFocusMap(kagamiGridModel.mainEntityId, kagamiGridModel.activeRowNode.data.id, formModel);
            let embeddedFormPresentationModel: any =  kagamiGridModel?.actions?.get("edit")?.embeddedFormPresentationModel
            if(embeddedFormPresentationModel === undefined){
              embeddedFormPresentationModel = formModel.formControls[kagamiGridModel.mainEntityId]
            }
              if (embeddedFormPresentationModel.isNested && CommonUtils.isNotEmpty(kagamiGridModel.parentEmbedIdForNestedEmbed)) {
                let entityHierarchy = formModel.formData.entityHierarchies.get(embeddedFormPresentationModel.presentationId)
                let parentEntityId : string = entityHierarchy.parentEntity
                updateInFocusMap(parentEntityId,kagamiGridModel.parentEmbedIdForNestedEmbed,formModel)
                embeddedFormPresentationModel = formModel.formControls[kagamiGridModel.mainEntityId + '.' + kagamiGridModel.parentEmbedIdForNestedEmbed]
              }
              editRecord(embeddedFormPresentationModel, 'inline', kagamiGridModel.activeRowNode.data.id);
          }
        }
      }
      delay(() =>{
        if(isEmbedCheckBox){
          kagamiGridModel.activeRowNode = null
        }
      },1000)
    }
}

export function validatePreviousRecord(kagamiGridModel : KagamiGridModel){
    let formModel : KagamiFormModel = getFormModel()
    let validations = validate(formModel,kagamiGridModel.mainEntityId,true);
    return validations.size === 0
}

export function fetchDataOnScroll(kagamiGridModel : KagamiGridModel,event :any){
      if(CommonUtils.isNotEmpty(kagamiGridModel.detailedObjects) && kagamiGridModel.detailedObjects.length < kagamiGridModel.totalRecords){
        var bottomPixel = event.api.getVerticalPixelRange().bottom;
        var gridHeight = event.api.getDisplayedRowCount() * 30
        if(gridHeight - bottomPixel < 70){
          kagamiGridModel.scrollEnd = true;
        }
        if(gridHeight - bottomPixel <2000){
          let index = kagamiGridModel.gridApi.getLastDisplayedRow();
          kagamiGridModel.lastRenderedRowNode = kagamiGridModel.gridApi.getRowNode(index)
          kagamiGridModel.pageNumber = kagamiGridModel.pageNumber+1
          let verbProperties : any = {
            pageNumber : kagamiGridModel.pageNumber,
            pageSize : kagamiGridModel.isEmbedModel
            ? kagamiGridModel?.embeddedPageSize
            : kagamiGridModel?.pageSize,
            byMeForMe : "DEFAULT"
          }
          if(CommonUtils.isNotEmpty(kagamiGridModel.filterList) || CommonUtils.isNotEmpty(kagamiGridModel.searchValue)){
            searchRecord(
              kagamiGridModel,
              kagamiGridModel.presentation.presentationId,
              null,
              kagamiGridModel.searchValue,
              kagamiGridModel.filterList,
              true
            );
          }
          else{
            prepareForLoadCall(
              kagamiGridModel.processName,
              retrievePresentation(kagamiGridModel.onStartData),
              {},
              {},
              null,
              kagamiGridModel.isPickItemListModel,
              false,
             'list',
              verbProperties,
             true
           );
          }  
       }
    }
}