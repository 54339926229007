import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React from 'react';
import { CUSTOM_TILE_HEADER, CUSTOM_TILE_INNER_VALUE, CUSTOM_TILE_VALUE, getGaugeWidgetData } from '../graphic/customTile/service/CustomTileReportUtil';
import { getReportAdditionalSettingsByProperty, getValueColumnForCustomTile, reportColumnDisplayFormatter } from '../../service/ReportUtilService';


export const GaugeWidget = (props: any) => {
    let chartObj = props.chartObj;
    let reportSettings = props.reportSettings;
    let reportConfig = props.customTileReportModel?.reportConfig;
    const chartComponent: any = React.useRef(null);
    const containerRef = React.useCallback((node: any) => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            chartComponent?.current?.chart.reflow();
        });
        resizeObserver?.observe(node);
    }, []);

    let chartColors = getReportAdditionalSettingsByProperty(reportSettings, 'colors') || ['#659C45', '#E27C16', '#00A5FD', '#1E3148', '#1E3148', '#8F7849'];
    const gaugeConfig = {

        chart: {
            type: 'gauge',
            maxHeight: 250,
            minHeight: 100,
            // height: 200,
            // spacingTop: 0,    // Remove top spacing
            // spacingRight: 0,  // Remove right spacing
            // spacingBottom: 0, // Remove bottom spacing
            // spacingLeft: 0,   // Remove left spacing
        },

        title: {
            text: null
        },
        exporting: {
            enabled: false // Disable the export button
        },
        pane: {
            startAngle: -100,
            endAngle: 100,
            background: {
                backgroundColor: '#fff',
                borderWidth: 0
            }
        },

        // The value axis
        yAxis: {
            min: 0,
            max: 100,
            minorTickInterval: 0,
            tickColor: '#ffffff',
            tickLength: 40,
            tickPixelInterval: 125,// 40,
            tickWidth: 0,//2,
            lineWidth: 0,
            title: {
                text: null
            },
            labels: {
                distance: 15,
                formatter :  (props:any) :any  => {
                    return props.value +"%";
                } 
            },
            plotBands: [{
                from: 1,
                to: 25,
                color: '#FF4E00',// '#55BF3B',
                innerRadius: '60%',//'82%',
                // borderRadius: '50%'
            }, {
                from: 25,
                to: 75,
                color: '#FF9E1B',// '#DDDF0D',
                innerRadius: '60%',// '82%',
                zIndex: 1
            }, {
                from: 75,
                to: 100,
                color: '#8AC81E',// '#DF5353',
                innerRadius: '60%',//'82%',
                // borderRadius: '50%'
            }]
        },

        series: [{
            name: 'Data',
            data: [chartObj[CUSTOM_TILE_VALUE] || 0],
            dataLabels: {
                borderWidth: 0,
                style: {
                    fontSize: '1.5em'
                },
                format: '{y}%',
            },
            tooltip: {
                valueSuffix: ' %'
            }
        }],
    }
    let column = getValueColumnForCustomTile(reportConfig);
    let formattedValue = column && reportColumnDisplayFormatter(reportConfig, chartObj[CUSTOM_TILE_VALUE] || '', 'Value', column);
    return (
        <div className={`gauge-container`}  style={{height: "inherit"}}>
            <div className={`chart-box`}  style={{height: "inherit"}} ref={containerRef}>
                <Chart ref={chartComponent} highcharts={Highcharts} options={gaugeConfig}  style={{height: "inherit"}}/>
            </div>
            <div className="content">
                {/* <div className="title">{formattedValue}</div> */}
                {/* <div className="sub-title">{chartObj[CUSTOM_TILE_HEADER]}</div> */}
            </div>
        </div>
    )
}


