import { makeStyles } from '@mui/styles';
// Core CSS
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import { APIService } from '../../../../../../../../service/ApiService';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { getSchedulerList } from '../../../../../../../service/URLService';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { KagamiGridStyle } from '../../../../body/builder/process/builder/list/builder/grid/style/KagamiGridStyle';

import 'ag-grid-community/styles/ag-grid.css';

export const KagamiScheduler = () => {
    const [rowData,setRowData] = useState([]);

    useEffect(()=>{
     APIService.getData(SystemConstants.GET,getSchedulerList(),null).then((response:any) => {
         console.log(response)
         if(response.status === 200 && CommonUtils.isNotEmpty(response.data)){
             setRowData(response.data)
         }
     })
    },[])
    const colDefs: any[] = [{ field: CommonUtils.makeFirstLetterCapital('triggerName')  ,minWidth: 500}, { field:  CommonUtils.makeFirstLetterCapital('cronExpression') }, {
        field:  CommonUtils.makeFirstLetterCapital('nextFireTime') ,
        valueFormatter: useCallback((params: any) => {
            return moment(params.value).format(SystemConstants.dd_MM_yyyy_h_mm_a)
        }, [])
    }, {
        field:  CommonUtils.makeFirstLetterCapital('prevFireTime') , valueFormatter: useCallback((params: any) => {
            return moment(params.value).format(SystemConstants.dd_MM_yyyy_h_mm_a)
        }, [])
    }, {
        field:  CommonUtils.makeFirstLetterCapital('startTime') , valueFormatter: useCallback((params: any) => {
            return moment(params.value).format(SystemConstants.dd_MM_yyyy_h_mm_a)
        }, [])
    }];
    const defaultColDef =  { flex :1,sortable: true ,minWidth: 125,filter: 'agMultiColumnFilter',resizable: true,editable: false };

    return (

        <div
            style={{ width: "auto", height: '100%'}}
            className={`ag-theme-alpine kgm-grid `}

        >
                <h2 style={{color : '#006e7f' , display : 'flex' , justifyContent : 'center' }}>SCHEDULERS</h2>
            <AgGridReact columnDefs={colDefs} defaultColDef={defaultColDef} rowData={rowData} />
            
        </div>
    );
}