import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { executeBusinessPolicyForControl } from '../../../service/PolicyExecutionService';
import { getEntityIdForPresentation, getHeaderForPresentation } from '../../../service/PresentationService';
import { KagamiControlModel } from '../../controls/model/kagamiControlModel';
import { KagamiSelectModel } from '../../controls/select/model/kagamiSelectModel';
import { KagamiTextModel } from '../../controls/textbox/model/kagamiTextModel';
import KagamiControlValidator from '../../controls/validations/KagamiControlValidator';
import { KagamiGridModel } from '../../list/builder/grid/model/KagamiGridModel';
import { buildExpandedEmbedModel } from '../builder/embedded-presentation/builder/embedded-accordion-presentation/builder/EmbeddedFormBuilder';
import { buildFormComponents } from '../builder/FormBuilder';
import { KagamiEmbeddedFormPresentationModel } from '../model/KagamiEmbeddedFormPresentationModel';
import { KagamiFormModel } from '../model/KagamiFormModel';
import { getActiveContext, getActiveFormRecord } from './FormService';

export function needtoShowErrorPopup(validations: any) {
  for (var key of validations.keys()) {
    let value = validations.get(key);
    if (value instanceof Map) {
      return true;
    }
  }
  return false;
}
export function validate(formModel: KagamiFormModel, entityId: string, isGrid: boolean) {
  let errorDetails: any = new Map()
  let object = getActiveFormRecord(formModel, entityId);
  if (object) {
    let activeContext = getActiveContext(formModel, entityId);
    errorDetails = validateRecord(formModel, entityId, object, activeContext, isGrid);
  }
  return errorDetails;
}

function validateRecord(formModel: KagamiFormModel, entityId: string, record: any, activeContext: any, isGrid: boolean) {
  let errorDetails = new Map();
  let presentationModel = formModel.formControls[entityId];
  if(formModel.mainEntityId === entityId){
    activeContext[entityId] = [record];
  }
  else{
    let contextEntityId : string = CommonUtils.isNotEmpty(presentationModel.presentation) ? getEntityIdForPresentation(presentationModel.presentation)
    : entityId
    activeContext[contextEntityId] = [record]
  }
  let hasFormControls: boolean = false;
  if (isGrid) {
    validateEmbedGridRecord(formModel, entityId, activeContext, record, errorDetails)
  }
  else {
    if (CommonUtils.isEmpty(presentationModel.formControls)) {
      buildFormComponents(presentationModel, null);
    }

    for (var value in presentationModel.formControls) {
      let controlModel = presentationModel.formControls[value];
      if (controlModel instanceof KagamiControlModel) {
        if (!hasFormControls) hasFormControls = true;
        if (controlModel.visible) {
          let validations = [];
          let defaultValidation = makeDefaultValidation(controlModel, record);
          let businessValidation = executeBusinessPolicyForControl(activeContext, controlModel);
          if (defaultValidation && defaultValidation != null) {
            if (controlModel.state) {
              if (presentationModel.presentation.entityId === formModel.formData.entityHierarchies.get(presentationModel.presentationId).currentEntity) {
                if (presentationModel.uiTemplate !== "pcEmbedEdit" || presentationModel.uiTemplate === "pcEmbedForm") {
                  if (!presentationModel.presentation.mandatory && CommonUtils.isEmpty(presentationModel.presentation.policyMap)) {
                    controlModel.state.setErrorMessage(null);
                  } else {
                    controlModel.state.setErrorMessage(defaultValidation);
                  }
                } else {
                  controlModel.state.setErrorMessage(defaultValidation);
                }
              }
            } else {
              controlModel.errorMessage = defaultValidation;
            }
            if (CommonUtils.isNotEmpty(formModel.formData.entityHierarchies.get(presentationModel.presentationId)) && presentationModel.presentation.entityId === formModel.formData.entityHierarchies.get(presentationModel.presentationId).currentEntity) {
              if (presentationModel.uiTemplate === "pcEmbedEdit" || presentationModel.uiTemplate === "pcEmbedForm") {
                if (!presentationModel.presentation.mandatory && CommonUtils.isNotEmpty(presentationModel.presentation.policyMap)) {
                  validations.push(defaultValidation);
                } else if (presentationModel.presentation.mandatory && CommonUtils.isNotEmpty(presentationModel.presentation.policyMap)) {
                  validations.push(defaultValidation);
                } else if (presentationModel.presentation.mandatory && CommonUtils.isEmpty(presentationModel.presentation.policyMap)) {
                  validations.push(defaultValidation);
                }
                else if (!presentationModel.presentation.mandatory && controlModel.mandatory && CommonUtils.isEmpty(presentationModel.presentation.policyMap)) {
                  validations.push(defaultValidation);
                }
                else if (!presentationModel.presentation.mandatory && presentationModel.hasChanges && CommonUtils.isEmpty(presentationModel.presentation.policyMap)) {
                  validations.push(defaultValidation);
                }
              } else {
                validations.push(defaultValidation);
              }
            }
          }
          if (CommonUtils.isNotEmpty(businessValidation)) {
            if (controlModel.state) {
              controlModel.state.setErrorMessage(businessValidation);
            }
            else {
              controlModel.errorMessage = businessValidation;
            }
            validations.push(businessValidation);
          }
          if (CommonUtils.isEmpty(defaultValidation) && CommonUtils.isEmpty(businessValidation)) {
            if (CommonUtils.isNotEmpty(controlModel.errorMessage)) {
              if (controlModel.state) {
                controlModel.state.setErrorMessage(undefined)
              }
              else {
                controlModel.errorMessage = undefined
              }
            }
            /// Note : checking the data value from record instead of control model because it is a grid record , we may or may have not updated 
            /// control model value in embed form
            else if (controlModel.mandatory && CommonUtils.isEmpty(record[controlModel.attributeName])) {
              if (record.hasOwnProperty(controlModel.attributeName)) {
                if (controlModel.state) {
                  let errorMessage: string = 'Please enter ' + controlModel.controlDisplayName
                  validations.push(errorMessage);
                  controlModel.state.setErrorMessage(errorMessage)
                }
                else {
                  controlModel.errorMessage = undefined
                }
              } else {
                if (controlModel.state) {
                  let errorMessage: string = 'Please enter' + controlModel.controlDisplayName;
                  validations.push(errorMessage);
                  controlModel.state.setErrorMessage(errorMessage);
                } else {
                  controlModel.errorMessage = undefined
                }
              }
            }
          }
          if (validations && validations.length > 0) {
            if (entityId === formModel.mainEntityId) {
              let entityHierarchy = formModel.formData.entityHierarchies.get(presentationModel.presentationId);
              if (entityHierarchy && entityHierarchy.currentPruleChildren.length >= 1) {
                errorDetails.set(controlModel.controlDisplayName, validations);
              }
              else {
                errorDetails.set(controlModel.controlDisplayName, validations);
              }
            } else {
              errorDetails.set(controlModel.controlDisplayName, validations);
            }
          }
        }
      }
    }
    /// Note : In case of embed records are not added or edited in popup the presentation model
    /// will not have embed form controls so we are validating using embed grid recortd
    if (!hasFormControls && presentationModel instanceof KagamiEmbeddedFormPresentationModel) {
      validateEmbedGridRecord(formModel, entityId, activeContext, record, errorDetails)
    }
  }

  validateEmbeddedData(formModel, entityId, record, activeContext, errorDetails, isGrid);
  return errorDetails;
}

function validateEmbedGridRecord(kagamiFormModel: KagamiFormModel, entityId: string, activeContext: any, record: any, errorDetails: any) {
  let embedFormPresentationModel: KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[entityId]
  let gridModel: KagamiGridModel | undefined = embedFormPresentationModel.kagamiGridModels.get(entityId);
  if (gridModel) {
    let attributeName: any, controlModel: any;
    for ([attributeName, controlModel] of Object.entries(gridModel.gridControls)) {
      if (controlModel.visible) {
        let validations = [];
        let defaultValidation = makeDefaultValidation(controlModel, record);
        let businessValidation = executeBusinessPolicyForControl(activeContext, controlModel);
        if (defaultValidation && defaultValidation != null) {
          controlModel.errorMessage = defaultValidation;
          validations.push(defaultValidation);
        }
        if (CommonUtils.isNotEmpty(businessValidation)) {
          validations.push(businessValidation);
        }
        if (validations && validations.length > 0) {
          if (entityId === kagamiFormModel.mainEntityId) {
            let entityHierarchy = kagamiFormModel.formData.entityHierarchies.get(embedFormPresentationModel.presentationId);
            if (entityHierarchy.currentPruleChildren.length >= 1) {
              errorDetails.set(controlModel.controlDisplayName, validations);
            }
          } else {
            errorDetails.set(controlModel.controlDisplayName, validations);
          }
        }
      }
    }
  }
}

function validateEmbeddedData(
  formModel: KagamiFormModel,
  entityId: string,
  record: any,
  activeContext: any,
  errorDetails: any,
  isGrid: boolean
) {
  let formData = formModel.formData;
  let embedFormPresentationModel: KagamiEmbeddedFormPresentationModel = formModel.formControls[entityId]
  let entityHierarchy = formData.entityHierarchies.get(embedFormPresentationModel.presentationId); 
  if (entityHierarchy && embedFormPresentationModel.visible) {
    for (let key in entityHierarchy.currentPruleChildren) {
      let childEntityId = entityHierarchy.currentPruleChildren[key];
      let childErrorDetails: any = new Map();
      let childObjects = record[childEntityId];
      let childPresentationModel = formModel.formControls[childEntityId];
      if (CommonUtils.isEmpty(childPresentationModel)) {
        childPresentationModel = buildExpandedEmbedModel(formModel.formControls[entityId]);
      }
      let embeddedErrorDetails: Map<string, any> = new Map();
      if (CommonUtils.isEmpty(childObjects)) {
        if (childPresentationModel.mandatory) {
          embeddedErrorDetails.set(childPresentationModel.formHeader, 'Details are mandatory');
        }
      } else {
        if (childPresentationModel.isListPresentation) {
          for (let childObjectsKey in childObjects) {
            let childObject = childObjects[childObjectsKey];
            let ced: Map<any, any> = validateRecord(formModel, childEntityId, childObject, activeContext, isGrid);
            if (ced.size > 0) {
              ced.forEach((value, key) => {
                childErrorDetails.set(key, value);
              })
            }
          }
        } else {
          childErrorDetails = validateRecord(formModel, childEntityId, childObjects, activeContext, isGrid);
        }
      }
      let embeddedFormHeader = getHeaderForPresentation(childPresentationModel.presentation);
      if (embeddedErrorDetails && embeddedErrorDetails.size !== 0) {
        errorDetails.set(embeddedFormHeader, embeddedErrorDetails)
      }
      if (childErrorDetails && childErrorDetails.size !== 0) {
        errorDetails.set(embeddedFormHeader, childErrorDetails);
      }

    }
  }
}

export function makeDefaultValidation(controlModel: KagamiControlModel, record: any) {
  let value = record?.[controlModel.attributeName]
  value = CommonUtils.isNotEmpty(value) ? value : null;
  if (controlModel instanceof KagamiSelectModel) {
    if (CommonUtils.isEmpty(value) && CommonUtils.isNotEmpty(record[controlModel.entityConsumed])) {
      value = record[controlModel.entityConsumed].id
    }
  }
  return KagamiControlValidator.validate(controlModel.controlType, controlModel instanceof KagamiTextModel && controlModel.isContact ? value?.toString().slice(1) : value, controlModel.validation, controlModel);
}
