// import { AgGridReact } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";

import { KagamiActivityLogModel } from "../../../../../../../../../../components/activityLog/model/KagamiActivityLogModel";
import { KagamiModalModel } from "../../../../../../../../../../components/modal/model/KagamiModalModel";
import { isTypeDate } from "../../../../../../../../../utils/DateUtils";
import { getAttributePresentationType, getDisplayAttributes, getEmbeddedPresentationId, getEntityConsumedInAttribute, getGridAttributeType, isTypeText } from "../../../service/PresentationService";
import { KagamiControlModel } from "../../controls/model/kagamiControlModel";
import { KagamiGridModel } from "../../list/builder/grid/model/KagamiGridModel";
import { getDispPresentationRuleConfig } from "../../list/builder/grid/service/ProcessHelperService";
import { handleActivityLogOnClick } from "../../list/builder/ListBuilder";
import { CommonUtils } from "../../../../../../../../../utils/CommonUtils";

export const getIndexColumn = (kagamiGridModel: any) => {
  let gridColumns: any = [];
  return (
    gridColumns.push(
      {
      key:kagamiGridModel.mainEntityId + '_add',
      field:"Sl no.",
      checkboxSelection:true,
      headerCheckboxSelection:true,
      cellStyle:{ marginLeft: 0 },
      minWidth:100,
      width:100,
      maxWidth:130,
      editable:false,
      cellRenderer:"agGroupCellRenderer",
      suppressNavigable:true,
      valueGetter:(params: any) => {
        return parseInt(params.node.id) + 1;
      },
      cellClass:(params: any) => {
        return ['kgm-sno-cell'];
      }
    }
  ))
}

const mandatoryIcon = <div className="ag-cell-label-container" role="presentation">
  <span ref="eMenu" className="ag-header-icon ag-header-cell-menu-button"></span>
  <div ref="eLabel" className="ag-header-cell-label" role="presentation">
    <span ref="eSortOrder" className="ag-header-icon ag-sort-order" ></span>
    <span ref="eSortAsc" className="ag-header-icon ag-sort-ascending-icon" ></span>
    <span ref="eSortDesc" className="ag-header-icon ag-sort-descending-icon" ></span>
    <span ref="eSortNone" className="ag-header-icon ag-sort-none-icon" ></span>
    <span ref="eText" className="ag-header-cell-text" role="columnheader"></span>
    <span ref="eFilter" className="ag-header-icon ag-filter-icon"></span>
    <span style={{ "color": "#f05252" }}> *</span> </div>
</div>;


export const getListEditColumns = (kagamiGridModel: KagamiGridModel, popupModel: KagamiModalModel, activityLogModel: KagamiActivityLogModel) => {
  let gridColumns: any = [];
  const { gridControls } = kagamiGridModel;
  for (let attrName of Object.keys(gridControls)) {
    let gridControlModel = gridControls[attrName]
    let attributeName: string = gridControlModel.attributeName
    let isMandatory: boolean = gridControlModel.mandatory;
    let attributePresentation = gridControlModel.attributePresentation;
    let displayAttributeToconsume = getDisplayAttributes(attributePresentation);
    let dispPresentationRuleConfig = getDispPresentationRuleConfig(attributePresentation);
    if (attributePresentation && gridControlModel instanceof KagamiControlModel && getAttributePresentationType(attributePresentation) === 'FieldPresentation') {
      let entityConsumed = getEntityConsumedInAttribute(attributePresentation);
      const embedPresentationId = getEmbeddedPresentationId(attributePresentation);

      let controlType = getGridAttributeType(gridControlModel) || gridControlModel.controlType;
      if (attributeName.includes('.') && !isTypeDate(gridControlModel.controlType) && !isTypeText(gridControlModel.controlType)) {
        let properties = attributeName.split('.');
        if (properties.length === 2) {
          let pickAttributeProperty: string = properties[0];
          let pickAttributePropertyValue: string = properties[1];
          gridColumns.push(
            {
              key:kagamiGridModel.mainEntityId + '_' + pickAttributeProperty,
              field:pickAttributeProperty,
              headerComponentParams:{ displayName: CommonUtils.makeFirstLetterCapital( gridControlModel.controlDisplayName)
               , template: isMandatory ? mandatoryIcon : null },
              cellRenderer:"KagamiStatusCellRenderer",
              cellRendererParams:{
                attributeToConsume: pickAttributeProperty,
                attributeValueToConsume: pickAttributePropertyValue
              },
              hide:!gridControlModel.visible,
              editable:!gridControlModel.readonly,
            }
          )
        }
      }
      else if (gridControlModel.controlType === 'customactivitylog' || gridControlModel.controlType === 'comments') {
        gridColumns.push(
          {
            key:kagamiGridModel.mainEntityId + '_' + attributeName,
            field:attributeName,
            headerComponentParams:{
              displayName: CommonUtils.makeFirstLetterCapital( gridControlModel.controlDisplayName), template: isMandatory ?
                mandatoryIcon : null
            },
            cellRenderer:"KagamiActivityLogCellRenderer",
            cellRendererParams:{
              activityLogModel: activityLogModel,
              handleActivityLogOnClick: handleActivityLogOnClick,
              attributePresentation: attributePresentation
            },

            hide:!gridControlModel.visible
          }
        );
      } else {
        switch (controlType) {
          case 'multiselect':

            break;
          case 'search':
            break;
          case 'customSearch':
            break;
          case 'select':
            break;
          case 'radio':
            break;
          case 'file':
          case 'fileViewer':
            break;
          case 'image':
            break;
          case 'date':
          case 'dateTime':
          case 'time':
            break;
          case 'checkbox':
          case 'boolean':
            break;
          case 'number':
            break;
          case 'customNumber':
            break;
          case 'customPhoneNumber':
            break;
          default:
            break;
        }
      }
    }
  }
  return gridColumns;
}