import React, { useEffect, useRef } from 'react';
import { KagamiLoginModel, KagamiLoginProps, useLoginState } from '../model/KagamiLoginModel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import logo from '../images/logo.png';
import intro from "../../../../assets/images/loginpagepics/intro.png"
import { convertToSha256, forgotpassword, getLogoData, loginRequest } from '../service/LoginService';
import { Link, Link as RouterLink} from 'react-router-dom';
import { createState } from '@hookstate/core';
import { KagamiLoginThreeStyle } from '../style/KagamILoginThree';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DOMAIN } from '../../../service/URLService';
import {
  FormControl,
  FormLabel,
  InputAdornment,
  Button,
  TextField,
} from '@mui/material';
import { CommonUtils } from '../../../utils/CommonUtils';
import PersonIcon from '@mui/icons-material/Person';
import { ENV_URL } from '../../../service/AppURL';
import { KagamiStateManager } from '../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../constants/SystemConstants';
import { getCopyrightYear } from '../../../controller/KagamiApplicationController';

export const buildLogin = (kagamiLoginModel: KagamiLoginModel): JSX.Element => {
  return <KagamiLoginTwo key={'login'} kagamiLoginModel={kagamiLoginModel} />;
};
const KagamiLoginTwo = (props: KagamiLoginProps): JSX.Element => {
  const userIdRef = useRef<HTMLInputElement>(null);
  const classes = makeStyles(KagamiLoginThreeStyle)();
  const loginState = useLoginState(createState(props.kagamiLoginModel));
  var uri = window.location;
  let url : string = ENV_URL;
  if (uri && uri.host && uri.pathname) {
       url = uri.protocol + '//' + uri.host + '/' + uri.pathname.split('/')[1]+'/';
  }
  const  currentReactPath:string = url+'resetPassword/';
 // const prevLocation = useLocation();
  // const navigate = useHistory(); 
  // navigate.push(`/login`);  
  
  const [passwordSetValues, setValues] = React.useState({
    password: '',
    visualizePasswordFlag: false
  });
  let noDeviceFoundError = KagamiStateManager.getStateToHolder(SystemConstants.LOGIN_STATE).getNoDeviceFoundMsg();

  const toggleClickIconShowPassword = () => {
    setValues({ ...passwordSetValues, visualizePasswordFlag: !passwordSetValues.visualizePasswordFlag });
  };
  const handleKeyPress = (onKeyPress: any) => {
    props.kagamiLoginModel.userName = props.kagamiLoginModel.userName.trim();
    if (onKeyPress.key === 'Enter') {
      if (props.kagamiLoginModel.userName !== '' && props.kagamiLoginModel.password !== '') {
        loginRequest(props.kagamiLoginModel.userName, convertToSha256(props.kagamiLoginModel.password));
      } else if (onKeyPress.target.name === 'username' && props.kagamiLoginModel.userName === '') {
        loginState.setIsUserNameHasError(true);
        if (props.kagamiLoginModel.password === '') {
          loginState.setIsPasswordHasError(true)
        }
      } else if (onKeyPress.target.name === 'password' && props.kagamiLoginModel.password === '') {
        loginState.setIsPasswordHasError(true);
        if (props.kagamiLoginModel.userName === '') {
          loginState.setIsUserNameHasError(true);
        }
      }
      else {
        if (onKeyPress.target.name !== 'username') {
          loginState.setIsUserNameHasError(true);
        } else {
          loginState.setIsPasswordHasError(true);
        }
      }
    }
    else {
      if (onKeyPress.target.name === 'username' && loginState.getIsUserNameHasError()) {
        loginState.setIsUserNameHasError(false);
      } else if (onKeyPress.target.name === 'password' && loginState.getIsPasswordHasError()) {
        loginState.setIsPasswordHasError(false);
      }
    }
  };

  useEffect(() => {
    getLogoData().then((respObject: any) => {
      props.kagamiLoginModel.logodata = respObject.data.logodata;
      loginState.setLogoVisible(true);

    })
      .catch((err:any) => {
        props.kagamiLoginModel.logodata = logo;
        loginState.setLogoVisible(true);

      });  
  }, []);

  const currentYear = getCopyrightYear();


  useEffect(() => {
      userIdRef?.current?.focus();
  }, [loginState]);

  return (
    <div className={classes.root}>
      <div className={classes.content_container}>
        <div className={classes.image_part}>



          <img className={classes.intro_img} src={intro}></img>

        </div>
        <div className={classes.form_part}>
          {/* <div className={classes.logo_img}>
            <img src={logo} alt="<kagami>"></img>
          </div> */}

          <div className={classes.login_card_cont}>
            <div className={classes.login_card}>
              {loginState.getForgotPassword() ? (
                //forgotpasswrd form
                <form className={classes.login_form}>
                  <div className={classes.company_logo}>
                    <img
                      className={classes.company_logo_size}
                      src={props.kagamiLoginModel.logodata ? props.kagamiLoginModel.logodata : logo}
                      alt="company_logo"
                    ></img>
                  </div>

                  <div className={classes.formPart}>

                    <Typography align={'center'} variant="caption" className={classes.errMsg}>
                      {/* {loginState.getLoginErrorMessage()} */}
                      {CommonUtils.isEmpty(loginState.getforgotPasswordErrorMessage)
                      && CommonUtils.isEmpty(loginState.getforgotPasswordSucessMessage) ? <span style={{color:"#006e7f"}}>Enter user id and check your email for the link to reset your password</span>
                       :  CommonUtils.isNotEmpty(loginState.getforgotPasswordErrorMessage) ? loginState.getforgotPasswordErrorMessage
                       : CommonUtils.isNotEmpty(loginState.getforgotPasswordSucessMessage) ? <span style={{color:"#006e7f"}}>An email has been sent to you with a link to reset your password</span> : ''
                      //  { != null && loginState.getforgotPasswordSucessMessage}
                       }
                      
                      <br />
                    
                    </Typography>
                    <FormControl>
                      <FormLabel component="span">User Id</FormLabel>
                      <TextField
                        inputRef={userIdRef}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderWidth: '0.2px',
                            },
                          },
                        }}
                        fullWidth
                        size="small"
                        id="userid"
                        name="User Id"
                        placeholder="Enter User Id"
                        autoComplete="off"
                        autoSave="off"
                        value={props.kagamiLoginModel.userId}
                        variant="outlined"
                        onKeyPress={handleKeyPress}
                        type="text"
                        required={true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon style={{ opacity: '0.7' }} />
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => {
                          loginState.setUserId(e.target.value)
                          let forgotButton = CommonUtils.isNotEmpty(e.target.value) && CommonUtils.isNotEmpty(props.kagamiLoginModel.userId);
                          props.kagamiLoginModel.enableForgotPasswordButton = forgotButton;
                          loginState.setEnableForgotPasswordButton(forgotButton);
                          loginState.setUserId(e.target.value);
                        }}

                      />
                    </FormControl>
                    <Button
                      fullWidth
                      className={classes.loginBtn}
                      size="small"
                      variant="contained"
                      disabled={!loginState.getEnableForgotPasswordButton()}
                      // value={loginState.userId}  
                      onClick={() =>
                         
                        forgotpassword({ redirectUrl:currentReactPath, loginUser:{  userdomain: DOMAIN, userId: loginState.userId }}).then((res: any) => {
                          if (res.data.errorMessage) {
                            loginState.setforgotPasswordErrorMessage(res.data.errorMessage)
                          } else if (res.data.status) {
                            loginState.setEnableForgotPasswordButton(false);
                            if(CommonUtils.isNotEmpty(props.kagamiLoginModel.forgotPasswordErrorMessage)){
                              loginState.setforgotPasswordErrorMessage('');
                            }
                            loginState.setforgotPasswordSucessMessage('An email has been sent to you with a link to reset your password')
                          }
                        })}
  
                  
                    

                    >
                      Send Email
                    </Button>
                    <div className={classes.forgot}>
                      <Link style={{ padding : '0px 145px', textDecoration: 'none'}} to="/login" onClick={() => loginState.setForgotPassword(false)}>
                        {' '}
                        Login?{' '}
                      </Link>
                    </div>
                  </div>

                </form>
              ) : (
                //login form
                <form className={classes.login_form}>
                  <div className={classes.company_logo}>
                    {loginState.isLogoVisible && <img
                      className={classes.company_logo_size}
                      src={CommonUtils?.isNotEmpty(props?.kagamiLoginModel?.logodata) ? props.kagamiLoginModel.logodata : logo}
                      alt="company_logo"
                    ></img>}
                    {/* <img
                      className={classes.company_logo_size}
                      src={ kagamiLoginModel.logodata ?  kagamiLoginModel.logodata  : logo}
                       alt="company_logo"
                    ></img> */}
                  </div>

                  <div className={classes.formPart}>

                    <Typography align={'center'} variant="caption" className={classes.errMsg}>
                      {loginState.getLoginErrorMessage()}
                      {noDeviceFoundError}
                    </Typography>
                    {/* ----------------------------------username-------------------------------------- */}
                    <FormControl>
                      <FormLabel component="span"> Username *</FormLabel>
                      <TextField
                        fullWidth
                        size="small"
                        autoFocus={true}
                        autoComplete="off"
                        autoSave="off"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderWidth: '0.2px',
                            },
                          },
                        }}
                        // className={classes.textField}
                        id="username"
                        name="username"
                        placeholder="Enter User Name"
                        value={loginState.userName}
                        error={loginState.getIsUserNameHasError()}
                        variant="outlined"
                        onKeyPress={handleKeyPress}
                        type="text"
                        helperText={loginState.getIsUserNameHasError() ? 'Please enter username' : ''}
                        required={true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon style={{ opacity: '0.7' }} />
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => {
                          let enableLogin = CommonUtils.isNotEmpty(e.target.value) && CommonUtils.isNotEmpty(props.kagamiLoginModel.password);
                          props.kagamiLoginModel.enableButton = enableLogin;
                          loginState.setEnableButton(enableLogin)
                          loginState.setUserName(e.target.value);
                        }
                        }
                      />
                    </FormControl>
                    {/* ----------------------------------password-------------------------------------- */}
                    <FormControl>
                      <FormLabel component="span"> Password *</FormLabel>
                      <TextField
                        fullWidth
                        size="small"
                        id="password"
                        name="password"
                        placeholder="Enter Password"
                        value={loginState.passWord}
                        autoComplete="off"
                        autoSave="off"
                        helperText={loginState.getIsPasswordHasError() ? 'Please enter password' : ''}
                        error={loginState.getIsPasswordHasError()}
                        variant="outlined"
                        onKeyPress={handleKeyPress}
                        type={passwordSetValues.visualizePasswordFlag ? 'text' : 'password'}
                        required={true}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderWidth: '0.2px',
                            },
                          },
                        }}
                        InputProps={{

                          startAdornment: (
                            <InputAdornment position="start" onClick={toggleClickIconShowPassword}>
                              {passwordSetValues.visualizePasswordFlag ? (
                                <VisibilityIcon style={{ opacity: '0.7', cursor: 'pointer' }} color='primary' />
                              ) : (
                                <VisibilityOffIcon style={{ opacity: '0.7', cursor: 'pointer' }} />
                              )}
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => {
                          let enableLogin = CommonUtils.isNotEmpty(e.target.value) && CommonUtils.isNotEmpty(props.kagamiLoginModel.userName);
                          props.kagamiLoginModel.enableButton = enableLogin;
                          loginState.setEnableButton(enableLogin)
                          loginState.setPassword(e.target.value);
                        }}
                      />
                    </FormControl>

                    {/* <FormControlLabel control={<Checkbox />} label="Remember me" /> */}

                    <Button
                      fullWidth
                      size="small"
                      className={classes.loginBtn}
                      // disabled={enableButton?false:true}
                      disabled={!loginState.getEnableButton()}
                      component={RouterLink}
                      to="/"
                      onClick={() => {
                        loginRequest(props.kagamiLoginModel.userName, convertToSha256(props.kagamiLoginModel.password));
                      }}
                      variant="contained"
                    >
                      LOGIN
                    </Button>
                    <Button disableRipple
                    sx={{
                      "&:hover": {
                        background: 'none !important'
                      },
                      "&.Mui-focusVisible": {
                        border: '2px solid black',
                        padding: '0px !important'
                      },
                    }} className={classes.forgot}  onClick={() => {loginState.setForgotPassword(true)}}
                      >
                      <a>Forgot Password?</a>
                    </Button>
                  </div>





                </form>
              )}


            </div>

          </div>
          <div className={classes.footer}>
            <Typography variant="caption" className={classes.footer} align="center">
              &copy; Copyright {currentYear} Kagami India Pvt Ltd
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
export default KagamiLoginTwo;
