import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const selectedFilterStyle = (theme: Theme) => 
    createStyles({
        selectedTabs: {
            display: 'flex',
            minHeight:"1.875rem"
        },
        selectedTabItemBoxes: {
            display: 'flex', 
            alignItems: 'center',
            margin: '0 0.25rem', 
            padding: '0.5rem', 
            borderRadius: '0.25rem', 
            border: "0.0625rem solid #BABFC7", 
            backgroundColor: '#fff', 
            height: "1.875rem"
        },
        selectedTabsContent: {
            fontWeight:"400",
            fontSize:"small",
            display: 'flex', 
            alignItems: 'center',
            cursor: "pointer", 
            padding: "0.24rem 0.5rem",
            textTransform: "capitalize" 
        },
        selectedTabOpertorLabel: {
            fontWeight: 'bold', 
            marginRight: '0.3rem', 
            fontSize: "small" 
        },
        selectedTabOpertorFieldValue: {
            marginLeft: '0.5rem' 
        },
        selectedTabRangeFieldSign: {
            padding: '0 0.3125rem'
        },
        selectedTabCloseIcon: {
            fontSize: '0.75rem', 
            paddingTop: '0.125rem', 
            color: 'red', 
            marginLeft: '0.25rem', 
            cursor: 'pointer'
        }
    })