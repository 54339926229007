import { TextareaAutosize } from '@mui/base';
import { FormControl } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { getFormModel } from '../../../form/controller/KagamiFormController';
import { KagamiTriggerController } from '../../../Triggers/builder/trigger/controller/KagamiTriggerController';
import { showPlaceHolder } from '../../numberTextbox/builder/KagamiNumberBulider';
import { buildIconsForReadOnly, buildIconsForTextBox } from '../builder/KagamiTextBoxBuilder';
import { validateTextBox } from '../controller/KagamiTextController';
import { KagamiTextFieldProps, useKagamiTextModelState } from '../model/kagamiTextModel';
import { KagamiTextBoxStyle } from '../style/KagamiTextBoxStyle';
import 'react-phone-input-2/lib/style.css';

const useStyles = makeStyles(KagamiTextBoxStyle);
const useTextAreaStyle = (isTextArea: boolean) =>
  makeStyles((theme: any) => ({
    root: {}
  }));
const KagamiTextFieldCustom = (props: KagamiTextFieldProps) => {
  let getAppSettingDefaultContryCode: any = localStorage.getItem('userContextDataForKcountry');
  let getParsedValue: any =
    getAppSettingDefaultContryCode !== 'undefined' || getAppSettingDefaultContryCode !== 'null'
      ? JSON.parse(getAppSettingDefaultContryCode)
      : { countryCode: 'in' };
  let isTextArea = props.kagamiTextModel.controlType === 'textarea' ? true : false;
  if (props.kagamiTextModel.isContact) {
    props.kagamiTextModel.controlType = 'contact';
  }
  const classes = useStyles();
  const classes1 = useTextAreaStyle(isTextArea)();
  let fieldState: any = useKagamiTextModelState(props.kagamiTextModel.createState);
  const uiSettingsClassName = props.kagamiTextModel.getControlWidth(props.kagamiTextModel.uiSettings);
  const fontProperties = props.kagamiTextModel.getFontStyling(props.kagamiTextModel.uiSettings);
  props.kagamiTextModel.state = fieldState;
  getFormModel().formControlStates[props.kagamiTextModel.controlIdentifier] = fieldState;
  const debounceCallForOnSearch = React.useCallback(CommonUtils._debounceFtn(onSearchCallForSearchFeild, 1000), []);

  function onSearchCallForSearchFeild(controlvalueData: string) {
    validateTextBox(props.kagamiTextModel, controlvalueData);
  }

  const handleTextField = (e: any) => {
    let newData = e.target.value.trimStart();
    fieldState.setPasswordChanges(true);
    fieldState.setControlValue(newData);
    debounceCallForOnSearch(fieldState.controlValue);
  };
  if (fieldState.isVisible === false) {
    return <></>;
  } else if (fieldState.isReadOnly === false) {
    switch (props.kagamiTextModel.controlType) {
      case 'text':
      case 'password':
        return (
          <>
            <div className={`${uiSettingsClassName} ${classes.root} ${classes1.root}`}>
              <FormControl fullWidth>
                <FormLabel
                  component="span"
                  required={fieldState.isMandatory}
                  style={{
                    color: fontProperties.labelFontColor,
                    fontWeight: fontProperties.labelFontBold,
                    fontStyle: fontProperties.labelFontItalic,
                    textDecoration: fontProperties.labelFontUnderline
                  }}
                >
                  {props.kagamiTextModel.controlDisplayName}
                </FormLabel>
                <TextField
                  sx={{
                    '& .MuiInputLabel-root ': {
                      color: fontProperties.labelFontColor,
                      fontWeight: fontProperties.labelFontBold,
                      fontStyle: fontProperties.labelFontItalic,
                      textDecoration: fontProperties.labelFontUnderline
                    },
                    '& .MuiInputLabel-root.Mui-focused ': {
                      color: fontProperties?.labelFontColor,
                      fontWeight: fontProperties?.labelFontBold,
                      fontStyle: fontProperties?.labelFontItalic,
                      textDecoration: fontProperties?.labelFontUnderline
                    },
                    '& .MuiOutlinedInput-root': {
                      color: fontProperties.controlFontColor + ' ' + '!important',
                      fontWeight: fontProperties.controlFontBold,
                      fontStyle: fontProperties.controlFontItalic,
                      textDecoration: fontProperties.controlFontUnderline
                    }
                  }}
                  fullWidth
                  size="small"
                  autoSave="off"
                  disabled={fieldState.isReadOnly}
                  name={props.kagamiTextModel.controlIdentifier}
                  type={props.kagamiTextModel.controlType}
                  multiline={isTextArea ? true : false}
                  rows={2}
                  placeholder={showPlaceHolder(props.kagamiTextModel.validation)}
                  error={fieldState.errorMessage ? true : false}
                  required={fieldState.isMandatory}
                  id={props.kagamiTextModel.controlIdentifier}
                  value={
                    (props.kagamiTextModel.controlType === 'password' && !fieldState.hasPasswordChanges) ||
                    CommonUtils.isEmpty(fieldState.controlValue)
                      ? ''
                      : fieldState.controlValue
                  }
                  helperText={fieldState.errorMessage}
                  variant="outlined"
                  onChange={(e) => {
                    handleTextField(e);
                  }}
                  onBlur={(e: any) => {
                    let value = e.target.value;
                    if (props.kagamiTextModel.controlValue?.toString() !== value) {
                      fieldState.setControlValue(value);
                    }
                  }}
                  onFocus={(e: any) => {
                    let value: string = e.target.value;
                    if (value === '0') {
                      fieldState.setControlValue('');
                    }
                  }}
                  InputProps={{
                    readOnly: fieldState.isReadOnly,
                    startAdornment: buildIconsForTextBox(props.kagamiTextModel.validation)
                  }}
                />
              </FormControl>
            </div>
          </>
        );
      case 'textarea':
        return (
          <>
            <div className={`${uiSettingsClassName} ${classes.root} ${classes1.root}`}>
              <FormControl
                fullWidth
                sx={{
                  maxHeight: 'none'
                }}
              >
                <FormLabel
                  component="span"
                  required={fieldState.isMandatory}
                  style={{
                    color: fontProperties.labelFontColor,
                    fontWeight: fontProperties.labelFontBold,
                    fontStyle: fontProperties.labelFontItalic,
                    textDecoration: fontProperties.labelFontUnderline
                  }}
                >
                  {props.kagamiTextModel.controlDisplayName}
                </FormLabel>
                <TextareaAutosize
                  minRows={3}
                  maxRows={6}
                  value={CommonUtils.isEmpty(fieldState.controlValue) ? '' : fieldState.controlValue}
                  name={props.kagamiTextModel.controlIdentifier}
                  onChange={(e) => {
                    fieldState.setPasswordChanges(true);
                    fieldState.setControlValue(e.target.value);
                    debounceCallForOnSearch(fieldState.controlValue);
                  }}
                  onBlur={(e: any) => {
                    let value = e.target.value;
                    let controlValue = props.kagamiTextModel.controlValue.toString().trim();
                    if (controlValue !== value) {
                      fieldState.setControlValue(value);
                    }
                    props.kagamiTextModel.controlValue = controlValue;
                    validateTextBox(props.kagamiTextModel, controlValue);
                  }}
                  onFocus={(e: any) => {
                    let value: string = e.target.value;
                    if (value === '0') {
                      fieldState.setControlValue('');
                    }
                  }}
                  style={{ marginBottom: '15px' }}
                />
                {CommonUtils.isNotEmpty(fieldState.errorMessage) ? (
                  <span style={{ color: '#f05252', fontSize: '12px' }}>{fieldState.errorMessage}</span>
                ) : null}
              </FormControl>
            </div>
          </>
        );
      case 'contact':
        return (
          <>
            <div className={`${uiSettingsClassName} ${classes.root} ${classes1.root}`}>
              <div className={classes.PhNoErrMessage}>
                <FormControl fullWidth>
                  <FormLabel
                    component="span"
                    required={fieldState.isMandatory}
                    style={{ color: fontProperties.labelFontColor }}
                  >
                    {props.kagamiTextModel.controlDisplayName}
                  </FormLabel>
                  <PhoneInput
                    inputClass={classes.phoneNumberInput}
                    inputProps={{
                      name: 'contact number',
                      autoFocus: false
                    }}
                    inputStyle={{ color: fontProperties.controlFontColor }}
                    placeholder="8123456789"
                    disableCountryCode={false}
                    enableSearch={false}
                    disableSearchIcon={false}
                    autoFormat={false}
                    country={CommonUtils.isNotEmpty(getParsedValue) ? getParsedValue.toLowerCase() : 'in'}
                    value={fieldState.controlValue}
                    countryCodeEditable={false}
                    preferredCountries={['in', 'us', 'tg']}
                    onChange={(value: string, data: any) => {
                      if (props.kagamiTextModel.dialCode !== data.dialCode) {
                        props.kagamiTextModel.dialCode = data.dialCode;
                      }
                      let concatMobileNumberValue = '+' + value;
                      validateTextBox(props.kagamiTextModel, concatMobileNumberValue);
                    }}
                    isValid={(value: any, country: any) =>
                      CommonUtils.isNotEmpty(props.kagamiTextModel.state.errorMessage)
                        ? props.kagamiTextModel.state.errorMessage
                        : true
                    }
                  />
                </FormControl>
              </div>
            </div>
          </>
        );
      default:
        return <div>{props.kagamiTextModel.controlType}: this type not implemented </div>;
    }
  } else if (fieldState.isReadOnly === true) {
    return (
      <div className={`${uiSettingsClassName} ${classes.root} ${classes1.root}`}>
        <div className={classes.readOnlyField_cont} onClick={KagamiTriggerController.handleHyperLinkClick}>
          <FormLabel
            component="span"
            required={fieldState.isMandatory}
            className={classes.controlLabel}
            style={{color: fontProperties.labelFontColor}}>
              {' '}
              {props.kagamiTextModel.controlDisplayName}
            </FormLabel>
            {fieldState.controlValue === null ||
            fieldState.controlValue === undefined ||
            fieldState.controlValue === '' ? (
              <div>
                <p className={classes.readOnlyEmpty}>----</p>
                {/* <p className={classes.readOnlyFieldError}>{fieldState.errorMessage}</p> */}
              </div>
            ) : (
              <div>
                {props.kagamiTextModel.validation && props.kagamiTextModel.validation.email 
                ? 
                  <p className={classes.readOnlyField} style={{color: fontProperties.controlFontColor, display:'flex', alignItems: 'center' }}>
                
                  {buildIconsForReadOnly(props.kagamiTextModel.validation)}
                  {fieldState.controlValue}
                </p>
               : 
                <p
                  className={classes.readOnlyField}
                  style={{
                    color: fontProperties?.controlFontColor,
                    wordBreak: 'break-word',
                    whiteSpace: fieldState?.controlValue ? 'pre-line' : 'normal'
                  }}
                >
                  {fieldState?.controlValue}
                </p>
              }
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default KagamiTextFieldCustom;
