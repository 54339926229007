import { makeStyles } from '@mui/styles';
import {
  KagamiGridPaginationModel,
  KagamiGridPaginationProps,
  useKagamiGridPaginationState
} from '../model/KagamiGridPaginationModel';
import { ArrowBackIos } from '@mui/icons-material';
import { ArrowForwardIos } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { createState } from '@hookstate/core';
import { KagamiGridStyle } from '../../../style/KagamiGridStyle';
import { getActiveModel } from '../../../../../../../controller/KagamiProcessController';
import { KagamiGridModel } from '../../../model/KagamiGridModel';
import { getFormModel } from '../../../../../../form/controller/KagamiFormController';
import { KagamiFormModel } from '../../../../../../form/model/KagamiFormModel';
import { _getChangeForDetail, _getUnSyncedFormRecord } from '../../../../../../form/service/FormService';
import { showLoader } from '../../../../../../../../../../../../../../components/loader/controller/KagamiLoaderController';
import { paginationApiCall } from '../service/paginationService';
import { getInGridEmbedInfo } from '../../../../../../../service/ProcessService';

export const buildPaginationComponent = (
  paginationModel: KagamiGridPaginationModel,
  kagamiGridModel: KagamiGridModel
) => {
  return (
    <KagamiGridPagination key="grid-pagination" paginationModel={paginationModel} kagamiGridModel={kagamiGridModel} />
  );
};

const useStyles = makeStyles(KagamiGridStyle);

const KagamiGridPagination = (props: KagamiGridPaginationProps) => {
  const classes = useStyles();
  let paginationModel = props?.paginationModel;
  let paginationState = useKagamiGridPaginationState(createState(paginationModel), 'grid-pagination');
  paginationModel.state = paginationState;
  let activeModel = getActiveModel();
  paginationModel.pageSize = props?.kagamiGridModel?.embeddedPageSize || props?.kagamiGridModel?.pageSize;

  const handleArrowClick = (transverseDirection: string) => {
    showLoader();
    paginationModel.activePageIndex =
      transverseDirection === 'forward' ? paginationModel?.activePageIndex + 1 : paginationModel?.activePageIndex - 1;
    paginationModel?.state?.setActivePageIndex(paginationModel?.activePageIndex);
    let verbProperties: any = {};
    verbProperties['byMeForMe'] = 'DEFAULT';
    verbProperties['pageSize'] = props.kagamiGridModel.isEmbedModel
      ? props?.kagamiGridModel?.embeddedPageSize
      : props?.kagamiGridModel?.pageSize;
    verbProperties['pageNumber'] = paginationModel?.activePageIndex;
    if (activeModel instanceof KagamiFormModel) {
      activeModel = getFormModel();
    }
    let embeddedInfo: any = {};
    embeddedInfo = getInGridEmbedInfo(props.kagamiGridModel, paginationModel, props?.kagamiGridModel?.embeddedPageSize);
    {
      paginationApiCall(props, paginationModel, activeModel, embeddedInfo, verbProperties, transverseDirection);
    }
  };

  if (paginationModel?.totalRecords > 0 && paginationModel?.totalRecords > paginationModel?.pageSize) {
    if (props?.kagamiGridModel?.paginationForList === true && props.kagamiGridModel.isEmbedModel === false) {
      return (
        <div className={classes.grid_pagination_comp}>
          <div className={classes.pagination}>
            Records &nbsp; {paginationModel?.state?.getStartRecord()}&nbsp; to &nbsp;
            {paginationModel?.state?.getEndRecord()} of {paginationModel?.totalRecords}
            <IconButton
              disabled={paginationModel?.startRecord === 1}
              onClick={() => handleArrowClick('backward')}
              size="large"
            >
              <ArrowBackIos style={{ fontSize: '12px' }} />
            </IconButton>
            Page &nbsp; {paginationModel?.state?.getActivePageIndex()}&nbsp; of &nbsp;
            {props?.kagamiGridModel?.totalPages}
            <IconButton
              disabled={paginationModel?.endRecord === paginationModel?.totalRecords}
              onClick={() => handleArrowClick('forward')}
              size="large"
            >
              <ArrowForwardIos style={{ fontSize: '12px' }} />
            </IconButton>
          </div>
        </div>
      );
    } else if (props?.kagamiGridModel?.paginationForEmbedForm === true && props.kagamiGridModel.isEmbedModel === true) {
      return (
        <div className={classes.grid_pagination_comp}>
          <div className={classes.pagination}>
            Records &nbsp; {paginationModel?.state?.getStartRecord()}&nbsp; to &nbsp;
            {paginationModel?.state?.getEndRecord()} of {paginationModel?.totalRecords}
            <IconButton
              disabled={paginationModel?.startRecord === 1}
              onClick={() => handleArrowClick('backward')}
              size="large"
            >
              <ArrowBackIos style={{ fontSize: '12px' }} />
            </IconButton>
            Page &nbsp; {paginationModel?.state?.getActivePageIndex()}&nbsp; of &nbsp;
            {props?.kagamiGridModel?.totalPages}
            <IconButton
              disabled={paginationModel?.endRecord === paginationModel?.totalRecords}
              onClick={() => handleArrowClick('forward')}
              size="large"
            >
              <ArrowForwardIos style={{ fontSize: '12px' }} />
            </IconButton>
          </div>
        </div>
      );
    } else {
      return null
    }
  }
  return null;
};
