import { KagamiStateManager } from '../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { showServerErrorPopUp } from '../../../../../../../controller/KagamiApplicationController';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { renderProcessPage } from '../../../controller/KagamiBodyController';
import { KagamiProcessPageModel } from '../model/KagamiProcessModel';
import { KagamiProcessRequestModel } from '../model/KagamiProcessRequestModel';
import { KagamiProcessResponseModel } from '../model/KagamiProcessResponseModel';
import { onOutBoundCall } from '../service/ProcessService';
import { buildProcessPageComponent } from '../ui/KagamiProcessPage';

let kagamiProcesPageModel: KagamiProcessPageModel;

export function buildProcessPage(processPageModel: KagamiProcessPageModel, isTodo?: boolean) {
  kagamiProcesPageModel = processPageModel;
  return buildProcessPageComponent(processPageModel, isTodo);
}

export function onStartCall(processRequestModel: KagamiProcessRequestModel, isTodo?: boolean | undefined) {
  onOutBoundCall(processRequestModel, isTodo);
}

export function updateActiveModel(activeModel: any) {
  if (CommonUtils.isEmpty(kagamiProcesPageModel)) {
    const processPageModel: KagamiProcessPageModel = new KagamiProcessPageModel(activeModel.processName);
    kagamiProcesPageModel = processPageModel;
  }
  kagamiProcesPageModel.activeModel = activeModel;
}

export function getActiveModel() {
  return kagamiProcesPageModel ? kagamiProcesPageModel.activeModel : undefined;
}

export function onSubmitCall(processRequestModel: KagamiProcessRequestModel) {
  onOutBoundCall(processRequestModel);
}

export function loadProcessContent(content: JSX.Element) {
  KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE).loadProcessContent(content);
}

export function executeLinkProcess(processId: string) {
  const processModel: KagamiProcessPageModel = new KagamiProcessPageModel('');
  processModel.isLinkProcess = true;
  processModel.breadcrumbModel.breadcrumbs = [];
  renderProcessPage(buildProcessPage(processModel));
  const processRequestModel: KagamiProcessRequestModel = new KagamiProcessRequestModel(
    '',
    loadProcessContent,
    showServerErrorPopUp
  );
  processRequestModel.linkId = processId;
  onOutBoundCall(processRequestModel);
}

export function setProcessPageModel(processPageModel: KagamiProcessPageModel){
  kagamiProcesPageModel = processPageModel;
}

export function getProcessPageModel(){
  return kagamiProcesPageModel;
}

