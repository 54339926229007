import makeStyles from '@mui/styles/makeStyles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { KagamiDateFieldStyle } from '../styles/KagamidateFieldStyle';
import { openCloseDatePicker, validateDateOnChange } from '../controller/kagamiDateController';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import moment from 'moment';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { FormLabel, IconButton, TextField, FormControl } from '@mui/material';
import { getFormModel } from '../../../form/controller/KagamiFormController';
import { useKagamiControlModelState } from '../../model/kagamiControlModel';
import { getDateStringToPopulateInForm } from '../../../../../../../../../../utils/DateUtils';
import { KagamiBeanUtils } from '../../../../../../../../../../utils/KagamiBeanUtils';
import { KagamiFormModel } from '../../../form/model/KagamiFormModel';
import { PickersDay } from '@mui/x-date-pickers';
import { KagamiTriggerController } from '../../../Triggers/builder/trigger/controller/KagamiTriggerController';
import { getVariableValue } from '../../../../service/PolicyExecutionService';
import { getMaxDate, getMinDate } from '../service/DateService';

function DateField(props: any) {
  const classes = makeStyles(KagamiDateFieldStyle)();
  let fieldState = useKagamiControlModelState(props.kagamiDateModel.createState);
  props.kagamiDateModel.state = fieldState;
  const uiSettingsClassName = props.kagamiDateModel.getControlWidth(props.kagamiDateModel.uiSettings);
  const fontProperties = props.kagamiDateModel.getFontStyling(props.kagamiDateModel.uiSettings);
  props.kagamiDateModel.displayValue = getDateStringToPopulateInForm(fieldState.controlValue, fieldState.controlType);
  getFormModel().formControlStates[props.kagamiDateModel.controlIdentifier] = fieldState;
   //disable dates till today in case dateToValue === 'today' 
  let maxDate: any = props.kagamiDateModel.uiSettings
    ? getMaxDate(props.kagamiDateModel.uiSettings, props.kagamiDateModel)
    : null;
  let kagamiFormModel: KagamiFormModel = getFormModel();
    //disable dates from today in case dateFromValue === 'today'
  let minDate: any = props.kagamiDateModel.uiSettings
    ? getMinDate(props.kagamiDateModel.uiSettings, props.kagamiDateModel)
    : null;
  let dateValid: any;
  if (CommonUtils.isNotEmpty(props.kagamiDateModel.uiSettings)) {
    if (props.kagamiDateModel?.policyMap?.businessValidations && props.kagamiDateModel.uiSettings.dateFromValue) {
      dateValid = getVariableValue(
        props.kagamiDateModel.uiSettings.dateFromValue.name,
        kagamiFormModel.formData.formData
      );
    }
  }

  const returnDisabledDays = () => {
    let disabledDates: any[] = [];
    if (
      CommonUtils.isNotEmpty(props.kagamiDateModel.uiSettings) &&
      CommonUtils.isNotEmpty(props.kagamiDateModel.uiSettings.dateExclusion)
    ) {
      let dateExclusion: string = props.kagamiDateModel.uiSettings.dateExclusion.toString();
      let disableData: any = kagamiFormModel.formData.data;
      let disabledDayDates: any[] = [];
      if (dateExclusion.includes('.')) {
        disableData = KagamiBeanUtils.getProperty(dateExclusion.split('.')[0], kagamiFormModel.formData.data);
        if (CommonUtils.isNotEmpty(disableData)) {
          disableData.map((disableDays: any) => {
            let property1 = props.kagamiDateModel.uiSettings.dateExclusion.toString().split('.');
            property1.shift();
            let property3 = property1.join('.');
            let day: any = KagamiBeanUtils.getProperty(property3, disableDays);
            if (CommonUtils.isNotEmpty(day)) {
              disabledDates.push(moment(day).format('YYYY-MM-DD'));
            }
          });
        } else {
          disableData = [];
        }
      } else {
        disabledDayDates.push(
          KagamiBeanUtils.getProperty(props.kagamiDateModel.uiSettings.dateExclusion, kagamiFormModel.formData.data)
        );
        if (CommonUtils.isNotEmpty(disabledDayDates)) {
          disabledDayDates.map((disableDays: any) => {
            disabledDates.push(moment(disableDays.date).format('YYYY-MM-DD'));
          });
        }
      }
    } else {
      disabledDates = [];
    }
    return disabledDates;
  };

  const disableSpecificDays = (date: any) => {
    let disabledDatesForSpecificDates: any[] = returnDisabledDays();
    if (
      CommonUtils.isNotEmpty(props.kagamiDateModel.weekendsSelected) ||
      CommonUtils.isNotEmpty(disabledDatesForSpecificDates)
    ) {
      return (
        disabledDatesForSpecificDates.includes(moment(date).format('YYYY-MM-DD')) ||
        props.kagamiDateModel.weekendsSelected.includes(moment(date).format('dddd'))
      );
    } else {
      return false;
    }
  };

  if (fieldState.isVisible === false) {
    return <></>;
  } else {
    return (
      <div className={`${uiSettingsClassName} ${classes.root}`}>
        {!fieldState.isReadOnly ? (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl fullWidth>
              <FormLabel
                component="span"
                required={fieldState.isMandatory}
                style={{
                  color: fontProperties.labelFontColor,
                  fontWeight: fontProperties.labelFontBold,
                  fontStyle: fontProperties.labelFontItalic,
                  textDecoration: fontProperties.labelFontUnderline
                }}
              >
                {props.kagamiDateModel.controlDisplayName}
              </FormLabel>
              <DatePicker
                PopperProps={{
                  className: 'customPopover'
                }}
                views={['year', 'month', 'day']}
                open={fieldState.isOpen}
                inputFormat="dd/MM/yyyy"
                onOpen={() => openCloseDatePicker(props.kagamiDateModel, true)}
                onClose={() => openCloseDatePicker(props.kagamiDateModel, false)}
                key={props.kagamiDateModel.controlIdentifier}
                value={fieldState.displayValue}
                maxDate={maxDate}
                minDate={minDate}
                renderDay={(day: any, _value: any, DayComponentProps: any) => {
                  return (
                    <PickersDay
                      classes={
                        {
                        }
                      }
                      className={
                        returnDisabledDays().includes(moment(day).format('YYYY-MM-DD')) ? 'disabledHoliday' : ''
                      }
                      {...DayComponentProps}
                    />
                  );
                }}
                shouldDisableDate={disableSpecificDays}
                onChange={(newvalue: any) => {}}
                disabled={fieldState.isReadOnly}
                onAccept={(newvalue: any) => {
                  let newDate = moment(newvalue).format('DD/MM/yyyy');
                  validateDateOnChange(props.kagamiDateModel, newvalue);
                }}
                renderInput={(params: any) => (
                  <div style={{ position: 'relative' }}>
                    <TextField
                      sx={{
                        '& .MuiInputLabel-root ': {
                          color: fontProperties.labelFontColor,
                          fontWeight: fontProperties.labelFontBold,
                          fontStyle: fontProperties.labelFontItalic,
                          textDecoration: fontProperties.labelFontUnderline
                        },
                        '& .MuiInputLabel-root.Mui-focused ': {
                          color: fontProperties?.labelFontColor,
                          fontWeight: fontProperties?.labelFontBold,
                          fontStyle: fontProperties?.labelFontItalic,
                          textDecoration: fontProperties?.labelFontUnderline
                        },
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: fontProperties.controlBackgroundColor,
                          color: fontProperties.controlFontColor + ' ' + '!important',
                          fontWeight: fontProperties.controlFontBold,
                          fontStyle: fontProperties.controlFontItalic,
                          textDecoration: fontProperties.controlFontUnderline
                        }
                      }}
                      fullWidth
                      size="small"
                      {...params}
                      onKeyPress={(event: any) => {
                        /// Note : event added to prevent user from entering date through keyboard
                        if(event.code !== "Tab") {
                          event.preventDefault();
                        }
                      }}
                      onKeyDown={(event: any) => {
                          /// Note : event added to prevent user from entering date through keyboard
                        if(event.code !== "Tab") {
                        event.preventDefault();
                        }
                      }}
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <CalendarMonthOutlinedIcon
                            sx={{
                              color: (theme) => theme.palette.secondary.main
                            }}
                            fontSize="small"
                            color="action"
                          />
                        ),
                        readOnly: true
                      }}
                      required={fieldState.isMandatory}
                      error={fieldState.errorMessage ? true : false}
                      helperText={fieldState.errorMessage}
                      onClick={() => openCloseDatePicker(props.kagamiDateModel, true)}
                    />
                    {CommonUtils.isNotEmpty(fieldState.displayValue) ? (
                      <IconButton
                        size="small"
                        className={classes.Ibutton}
                        onClick={() => {
                          validateDateOnChange(props.kagamiDateModel, null);
                        }}
                      >
                        <ClearOutlinedIcon
                          sx={{
                            color: (theme) => theme.palette.error.main
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              />
            </FormControl>
          </LocalizationProvider>
        ) : (
          <div className={classes.readOnlyField_cont} onClick={KagamiTriggerController.handleHyperLinkClick}>
            <FormLabel
              component="span"
              className={classes.controlLabel}
              required={fieldState.isMandatory}
              style={{ color: fontProperties.labelFontColor }}
            >
              {' '}
              {props.kagamiDateModel.controlDisplayName}
            </FormLabel>
            {fieldState.controlValue ? (
              <p className={classes.readOnlyField} style={{ color: fontProperties.controlFontColor }}>
                {moment(fieldState.displayValue).format('DD/MM/yyyy')}
              </p>
            ) : (
              <p className={classes.readOnlyEmpty} style={{ color: fontProperties.controlFontColor }}>
                ----
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default DateField;
