import { ENV_URL, ENV_DOMAIN } from './AppURL';
// export const applicationURL = ENV_URL;
export const applicationURL = ENV_URL + 'kgm/';
export const DOMAIN = ENV_DOMAIN;
export const NETWORKCHECK_URL = applicationURL + 'actuator/health';
export const LOGIN_URL = 'api/auth/login';
export const LOGOUT_URL = 'api/auth/logout';
export const DASHBOARD_URL = 'api/presentation/getDashboard';
export const MOBILE_LOGIN_URL = 'mobile/login';
export const IMPERSONATE = 'mobile/impersonate';
export const CLOSE_IMPERSONATE = 'mobile/closeImpersonation';
export const USER_URL = 'role/role/getUser';
export const MOBILE_LOGOUT_URL = 'mobile/logout';
export const AUTH_LOGIN_URL = 'api/auth/loginPage';
export const BPM_URL = 'bpm/bpm/process';
export const ASYNC_BPM_URL = 'bpm/bpm/processasync';
export const BPM_METADATA_URL_GETENTITY = 'bpm/metadata/getEntity';
export const BPM_DOWNLOAD_SAMPLE_FOR_BULK_UPLOAD = 'verb/bulk/downloadSample';
export const REPORT_DATA_URL = 'report/getReportData';
export const REPORT_CONFIG_URL = 'report/getReportConfigDetails';
export const REPORT_LIST_URL = 'report/getReportsByRole';
export const REPORT_LEDGER_URL = 'report/getGeneralLedger';
export const REPORT_STOCK_LEDGER_URL = 'report/itemStockLedger';
export const REPORT_PLREPORT_URL = 'report/pLReport';
export const REPORT_FOLDER_URL = 'report/getReportFolderStructure';
export const REPORT_TB_REPORT_URL = 'report/trialbalanceReport';
export const REPORT_BALANCESHEET_REPORT_URL = 'report/balanceReport';
export const REPORT_EXPORT_DETAILED_LEDGER_URL = 'report/exportGeneralLedger';
export const REPORT_EXPORT_SHILPA_PERFORMANCE_REPORT_URL = 'report/exportShilpaPerformanceReport';
export const REPORT_TRANSLATION_URL = 'report/translation/forKeys';
export const DMS_VIEW_URL = 'dms/viewDocument?docId=';
export const DMS_DOWNLOAD_URL = 'dms/downloadDocument?docId=';
export const DMS_CREATE_URL = 'dms/newUploadDocument';
export const BULK_UPLOAD = 'dms/uploadDocument';
export const GET_DOCNAME_URL = 'dms/getDocumentName?docId=';
export const DYNAMIC_TREE_URL = 'api/role/getDynamicTree';
export const NOTIFICATION_URL = 'user/widget/todo';
export const SIGN_UP_URL = 'api/auth/signUp';
export const APP_AND_USER_CONTEXT_RUL = 'bpm/cache/getAppAndUserContext';
export const GUAGE_DATA_URL = 'report/gaugeData';
export const CALENDAR_DATA_URL = 'report/calendarData';
export const FILTER_DATA_URL = 'report/getFilterData';
export const STATIC_FILTER_DATA_URL = 'report/getStaticFilterData';
export const FORGOT_PASSWORD_URL = 'api/auth/forgotPasswordMobile';
export const VALIDATE_TOKEN_FOR_RESET_PASSWORD = 'api/auth/validateTokenMobile?token=';
export const UPDATE_PASSWORD = 'api/auth/resetPassword';
export const DMS_DELETE_URL = 'dms/deleteDocument?docId=';
export const GET_EXCELSHEET_HEADERS = 'verb/bulk/getExcelSheetHeaders?docId=';
export const GET_PRESENTATION_URL = 'api/presentation/getPresentationRule?presentationRuleId=';
export const SAVE_PAGE_FOR_USER_URL = 'settings/savepageForUser';
export const REPORT_ROLE_MAPPING_URL = 'report/getRoleReportMapping';
export const LOGO_URL = 'api/auth/getLogo';
export const ACTIVITY_LOG = 'api/customcontroller/processActivityLog';
export const STATUS_INFO = 'api/customcontroller/getStatusInfo?entityId=';
export const LIST_CHAT = 'chat/listChat';
export const DEL_INFO_NOTIFICATION = 'user/widget/deleteEmailNotifcation?notificationId=';
export const TALLY_VOUCHER_DISPLAY = 'report/getTallyVocherDisplay';
export const SAVEUSERFAVOURITES = 'api/presentation/saveUserFavourites';
export const ALL_ROLES_URL = 'report/getAllRoles';
export const UPDATE_REPORT_ROLE_MAPPING_URL = 'report/updateRoleReportMapping';
export const ALL_STUDIO_REPORTS = 'report/getAllStudioReport';
export const REPORT_AUDITLOG_URL = 'report/getAuditLogDetails';
export const REPORT_DELETE_URL = 'report/deleteReport';
export const WEBSOCKET_URL = `${applicationURL}ws`;
export const FETCH_ALL_SCHEDULES = 'report/fetchAllSchedules';
export const GET_USER_LIST = 'report/getUserList';
export const SCHEDULE_REPORT = 'report/scheduleReport';
export const DELETE_SCHEDULE = 'report/deleteSchedule';
export const CREATE_FOLDER_URL = 'report/createFolder';
export const UPDATE_FOLDER_URL = 'report/updateFolderDetailsForReport';
export const DELETE_FOLDER_URL = 'report/deleteFolder';
export const CALL = 'bpm/bpm/call';
export const RESET_PASSWORD = 'api/auth/resetPasswordMobile?token=';
export const LOGGER_CONFIG = 'logger?configurationLevel=';
export const UNLOAD_REPORT_CONFIG_DATA = 'report/unloadReportConfigData?reportId=';
export const RELOAD_REPORT_CONFIG_DATA = 'report/reloadReportConfigData?reportId=';
export const RELOAD_ALL_REPORT_CONFIG_DATA = 'report/reloadAllReportConfigData';
export const ALL_USER_SETTINGS = 'settings/updateUserSettingForAllUsers';
export const FETCH_DASHBOARD_LAYOUT = 'Dashboard/fetchSettings';
export const COMPILE_ALL_POLICY = 'util/compileAllPolicy';
export const SET_DASHBOARD_LAYOUT = 'Dashboard/save';
export const REPORT_CONFIGURATION = 'report/getReportConfiguration';
export const UPDATE_REPORT_PRINT_CONFIGURATION = 'report/updatedReportPrintConfiguration';
export const SCHEDULER_LIST = 'schedulerProcess/schedulerList';
export const DEVICE_INFO = "http://127.0.0.1:59001/hwid"
export const NOTIFICATION_DATA_URL = 'user/widget/fetchNotificationById';
export const SLOW_API_LOG_FILTER = 'api/ual/fetchByFilter';
export const SLOW_API_LOG_EVENT_FILTER = 'api/ual/getAvgTimeByEvent';
export const SLOW_API_LOG_LOG_PAGINATION = 'api/ual/fetchByFilter?sortBy=';
export const SLOW_API_LOG_AVGEVENT_PAGINATION = 'api/ual/getAvgTimeByEvent?sortBy=';

export function updateReportPrintConfigurationURL(): string {
  return applicationURL + UPDATE_REPORT_PRINT_CONFIGURATION;
}

export function getReportConfigurationURL(reportId: string): string {
  return applicationURL + REPORT_CONFIGURATION + '?reportId=' + reportId;
}

export function getDashboardLayout(): string {
  return applicationURL + FETCH_DASHBOARD_LAYOUT;
}

export function setDashboardLayout(): string {
  return applicationURL + SET_DASHBOARD_LAYOUT;
}

export function unloadReportConfigData(reportId: string): string {
  return applicationURL + UNLOAD_REPORT_CONFIG_DATA + reportId;
}

export function reloadReportConfigData(reportId: string): string {
  return applicationURL + RELOAD_REPORT_CONFIG_DATA + reportId;
}

export function reloadAllReportConfigData(): string {
  return applicationURL + RELOAD_ALL_REPORT_CONFIG_DATA;
}

export function scheduleReportURL(): string {
  return applicationURL + SCHEDULE_REPORT;
}

export function deleteSchedulerURL(): string {
  return applicationURL + DELETE_SCHEDULE;
}

export function getUserListURL(): string {
  return applicationURL + GET_USER_LIST;
}

export function fetchAllSchedulerURL(): string {
  return applicationURL + FETCH_ALL_SCHEDULES;
}

export function getAllRolesURL(): string {
  return applicationURL + ALL_ROLES_URL;
}

export function updateRoleReportMappingURL(): string {
  return applicationURL + UPDATE_REPORT_ROLE_MAPPING_URL;
}

export function getAllStudioReportURL(): string {
  return applicationURL + ALL_STUDIO_REPORTS;
}

export function getRoleReportMappingURL(): string {
  return applicationURL + REPORT_ROLE_MAPPING_URL;
}

export function getDashBoardURL(): string {
  return applicationURL + DASHBOARD_URL;
}

export function getLoginURL(): string {
  return applicationURL + MOBILE_LOGIN_URL;
}

export function getImpersonateURL(): string {
  return applicationURL + IMPERSONATE;
}

export function getCloseImpersonateURL(): string {
  return applicationURL + CLOSE_IMPERSONATE;
}

export function getNetworkConnection(): string {
  return NETWORKCHECK_URL;
}
export function getLogoutURL(): string {
  return applicationURL + LOGOUT_URL;
}

export function getLogoURL(): string {
  return applicationURL + LOGO_URL;
}

export function getDynamicTreeURL(): string {
  return applicationURL + DYNAMIC_TREE_URL;
}

export function getNotificationURL(username: string): string {
  return applicationURL + NOTIFICATION_URL + `?userId=${username}`;
}

export function getNotificationDataURL(notificationId : string): string {
    return applicationURL + NOTIFICATION_DATA_URL + `?notificationId=${notificationId}`;
}

export function getbpmURL(): string {
  return applicationURL + BPM_URL;
}

export function getAsyncBpmURL(): string {
  return applicationURL + ASYNC_BPM_URL;
}

export function getEntity(): string {
  return applicationURL + BPM_METADATA_URL_GETENTITY;
}

export function getDownloadSampleForBulkUpload(): string {
  return applicationURL + BPM_DOWNLOAD_SAMPLE_FOR_BULK_UPLOAD;
}

export function getUpdatePassword(): string {
  return applicationURL + UPDATE_PASSWORD;
}

export function getReportDataURL(): string {
  return applicationURL + REPORT_DATA_URL;
}

export function getReportConfigURL(reportId: string): string {
  return applicationURL + REPORT_CONFIG_URL + '?reportId=' + reportId + '&userId=admin';
}

export function getAuditLogURL(): string {
  return applicationURL + REPORT_AUDITLOG_URL;
}

export function getTallyVoucher(tallyDetail: any): string {
  return (
    applicationURL +
    TALLY_VOUCHER_DISPLAY +
    '?invoiceNo=' +
    tallyDetail.invoiceNo +
    '&transType=' +
    tallyDetail.transType +
    '&reportType=' +
    tallyDetail.reportType +
    '&currency=' +
    tallyDetail.currency
  );
}

export function getFilterDataURL(): string {
  return applicationURL + FILTER_DATA_URL;
}

export function getReportListURL(): string {
  return applicationURL + REPORT_LIST_URL;
}

export function getReportFolderURL(): string {
  return applicationURL + REPORT_FOLDER_URL;
}

export function getGuageDataUrl(): string {
  return applicationURL + GUAGE_DATA_URL;
}

export function getCalendarDatUrl(): string {
  return applicationURL + CALENDAR_DATA_URL;
}

export function getStaticFilterDataURL(ledgerType: string): string {
  return applicationURL + STATIC_FILTER_DATA_URL + '?ledgerType=' + ledgerType;
}
export function getStaticFilterURL(): string {
  return applicationURL + STATIC_FILTER_DATA_URL;
}

export function getDmsViewUrl(fileId: string): string {
  if (fileId != null) {
    return applicationURL + DMS_VIEW_URL + fileId;
  }
  return '';
}

export function getDownloadFileUrl(fileId: string): string {
  if (fileId != null) {
    return applicationURL + DMS_DOWNLOAD_URL + fileId;
  }
  return '';
}

export function getDownloadFiles(fileId: string): string {
  if (fileId != null) {
    return applicationURL + DMS_VIEW_URL + fileId;
  }
  return '';
}

export function getDmsCreateUrl(): string {
  return applicationURL + DMS_CREATE_URL;
}

export function getBulkUploadUrl(): string {
  return applicationURL + BULK_UPLOAD;
}

export function getSignUpUrl(): string {
  return applicationURL + SIGN_UP_URL;
}

export function getAppAndUserContextUrl(): string {
  return applicationURL + APP_AND_USER_CONTEXT_RUL;
}

export function getDocNameUrl(docId: string): string {
  if (docId != null) {
    return applicationURL + GET_DOCNAME_URL + docId;
  }
  return '';
}

export function getForgotPasswordUrl(): string {
  // let encodeUrl = encodeURIComponent(applicationURL + AUTH_LOGIN_URL);
  return applicationURL + FORGOT_PASSWORD_URL;
}
export function getValidateTokenForPasswordReset(tokenString: string): string {
  return applicationURL + VALIDATE_TOKEN_FOR_RESET_PASSWORD + tokenString;
}
export function getDMSDeleteURL(docId: string): string {
  return applicationURL + DMS_DELETE_URL + docId;
}

export function getUrl() {
  return 'https://192.168.1.215:9099/SwissGarnier/api/auth/validateTokenMobile?token=c5a9a165-ffc7-49f8-8882-5d9c9830ffa0';
}

export function getExcelSheetHeaders(docId: string, eventName: string, processName: string): string {
  return applicationURL + GET_EXCELSHEET_HEADERS + docId + '&eventName=' + eventName + '&processName=' + processName;
}

export function getSlowLogApiPagination(startTime: string, sortDir: string, pageNo: number, pageSize: number): string {
  return (
    applicationURL +
    SLOW_API_LOG_LOG_PAGINATION +
    startTime +
    '&sortDir=' +
    sortDir +
    '&pageNo=' +
    pageNo +
    '&pageSize=' +
    pageSize
  );
}

export function getSlowLogApiEventPagination(
  startTime: string,
  sortDir: string,
  pageNo: number,
  pageSize: number
): string {
  return (
    applicationURL +
    SLOW_API_LOG_AVGEVENT_PAGINATION +
    startTime +
    '&sortDir=' +
    sortDir +
    '&pageNo=' +
    pageNo +
    '&pageSize=' +
    pageSize
  );
}

export function getPresentationRuleForId(pruleId: string): string {
  return applicationURL + GET_PRESENTATION_URL + pruleId;
}

export function getUserUrl(): string {
  return applicationURL + USER_URL;
}

export function getsavepageForUser(): string {
  return applicationURL + SAVE_PAGE_FOR_USER_URL;
}

export function getActivityLogUrl(): string {
  return applicationURL + ACTIVITY_LOG;
}

export function saveUserFavourites(): string {
  return applicationURL + SAVEUSERFAVOURITES;
}
export function getStatusInfoUrl(entityId: string): string {
  return applicationURL + STATUS_INFO + entityId;
}
export function getListChatUrl() {
  return applicationURL + LIST_CHAT;
}
export function getLedgerURL(): string {
  return applicationURL + REPORT_LEDGER_URL;
}
export function getStockLedgerURL(): string {
  return applicationURL + REPORT_STOCK_LEDGER_URL;
}

export function getPLReportURL(): string {
  return applicationURL + REPORT_PLREPORT_URL;
}
export function getTBReportURL(): string {
  return applicationURL + REPORT_TB_REPORT_URL;
}
export function getBalanceSheetReportURL(): string {
  return applicationURL + REPORT_BALANCESHEET_REPORT_URL;
}
export function exportDetailedLedgerURL(): string {
  return applicationURL + REPORT_EXPORT_DETAILED_LEDGER_URL;
}

export function exportShilpaPerformanceReportURL(): string {
  return applicationURL + REPORT_EXPORT_SHILPA_PERFORMANCE_REPORT_URL;
}

export function getDelInfoNotificationURL(id: string): string {
  return applicationURL + DEL_INFO_NOTIFICATION + id;
}

export function getAuditLogHeaders(entityId: string, transId: string): string {
  return getAuditLogURL() + '?entityId=' + entityId + '&transId=' + transId;
}

export function deleteReportURL(reportId: string): string {
  return applicationURL + REPORT_DELETE_URL + '?reportId=' + reportId;
}

export function getWSSubsctriptionURL(userId: string): string {
  return `/user/${userId}/queue/notify`;
}

export function getUpdateFolderDetailsForReportsURL(): string {
  return applicationURL + UPDATE_FOLDER_URL;
}

export function getCreateFolderURL(): string {
  return applicationURL + CREATE_FOLDER_URL;
}

export function deleteFolderURL(folderId: string): string {
  return applicationURL + DELETE_FOLDER_URL + '?folderId=' + folderId;
}

export function linkProcessURL(): string {
  return applicationURL + CALL;
}

export function resetPasswordURL(token: string) {
  return applicationURL + RESET_PASSWORD + token;
}

export function loggerConfigURL(configType: string) {
  return applicationURL + LOGGER_CONFIG + configType;
}

export function updateUserSettingForAllUsers() {
  return applicationURL + ALL_USER_SETTINGS;
}

export function compileAllPolicy() {
  return applicationURL + COMPILE_ALL_POLICY;
}

export function getSchedulerList() {
  return applicationURL + SCHEDULER_LIST;
}

export function slowApiLogFilter() {
  return applicationURL + SLOW_API_LOG_FILTER;
}

export function slowApiLogEventFilter() {
  return applicationURL + SLOW_API_LOG_EVENT_FILTER;
}

export function getDeviceInfo() {
  return DEVICE_INFO;
}

export function getReportTranslationURL() {
  return applicationURL + REPORT_TRANSLATION_URL;
}

