import { State, useState } from '@hookstate/core';
import { KagamiControlModel } from '../../model/kagamiControlModel';

export class KagamiBarCodeModel extends KagamiControlModel {
  createState: any;
  uiSettings: any;
  attributePresentation: any;
  constructor(
    controlIdentifier: string,
    attributeName: string,
    mandatory: boolean,
    visible: boolean,
    entityId: string,
    readonly: boolean,
    controlType: string,
    controlDisplayName: string,
    showDisplayName: boolean,
    controlValue: any,
    onChangeRequired: boolean,
    validation: any,
    pickAttribute: boolean,
    formDataChange: (control: KagamiControlModel, value: any) => void,
    state: any,
    createState: State<KagamiBarCodeModel> | null,
    policyMap: any,
    presentationPolicy: any,
    businessPolicy: any,
    initiallyReadOnly: boolean,
    initiallyVisible: boolean,
    uiSettings: any,
    formDataIdentifier: string,
    isGridField: boolean,
    isEmbddedField: boolean
  ) {
    super(
      controlIdentifier,
      attributeName,
      mandatory,
      visible,
      entityId,
      readonly,
      controlType,
      controlValue,
      controlDisplayName,
      showDisplayName,
      onChangeRequired,
      formDataChange,
      validation,
      pickAttribute,
      policyMap,
      presentationPolicy,
      businessPolicy,
      initiallyReadOnly,
      initiallyVisible,
      formDataIdentifier,
      isGridField,
      isEmbddedField
    );
    this.uiSettings = uiSettings;
    this.previouseControlValue = controlValue || '';
  }
}

export interface KagamiBarCodeModelProps {
  kagamiBarCodeModel: KagamiBarCodeModel;
}

export const wrapKagamiBarCodeModelState = (state: State<KagamiBarCodeModel>) => {
  let controlState = state;

  return {
    get isMandatory() {
      return controlState.mandatory.get();
    },

    setMandatory(isMandatory: boolean) {
      controlState.mandatory.set(isMandatory);
    },

    get isVisible() {
      return controlState.visible.get();
    },

    setVisible(isVisible: boolean) {
      controlState.visible.set(isVisible);
    },

    get isReadOnly() {
      return controlState.readonly.get();
    },

    setReadonly(isReadonly: boolean) {
      controlState.readonly.set(isReadonly);
    },

    get errorMessage() {
      return controlState.errorMessage.get();
    },

    setErrorMessage(value: string) {
      controlState.errorMessage.set(value);
    },

    get controlValue() {
      return controlState.controlValue.get();
    },

    setControlValue(value: any) {
      controlState.controlValue.set(value);
    }
  };
};

export const useKagamiBarCodeModelState = (state: State<KagamiBarCodeModel>) =>
  wrapKagamiBarCodeModelState(useState(state));

export const accessKagamiBarCodeModelState = (state: State<KagamiBarCodeModel>) => wrapKagamiBarCodeModelState(state);
