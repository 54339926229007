import { KagamiErrorModalModel } from '../../../../../../../../../../components/errorModal/model/KagamiErrorModalModel';
import { KagamiBeanUtils } from '../../../../../../../../../utils/KagamiBeanUtils';
import { getFormButtonLabel, getMainEntityPresentationsFromUIResource } from '../../../service/PresentationService';
import { TriggerAlertModalModel } from '../../Triggers/builder/TriggerAlertModal/model/TriggerAlertModalModel';
import { KagamiTriggersModel } from '../../Triggers/model/KagamiTriggersModel';
import { kagamiProcessHeaderModel } from '../../processHeader/model/KagamiProcessHeaderModel';
import { handleTriggerClick } from '../controller/KagamiFormController';
import { KagamiFormData } from './formData';

export class KagamiFormModel {
  processName: string;
  processStepName: string;
  onStartData: any;
  uiTemplate: string;
  mainEntityId: string;
  embeddedInfo: any;
  errorModal: KagamiErrorModalModel;
  readOnly: boolean;
  formData: KagamiFormData;
  inFocusFormDataIdentifiers: any;
  formControls: any = {};
  formControlStates: any = {};
  formComponents: any[];
  onLoadData: any;
  isModelDialog: boolean;
  triggersModel: KagamiTriggersModel;
  isGroupForm : boolean; 
  footerElements : any[]; 
  isSingleListView : boolean;
  saveLabel : string;
  triggerAlertModalModel:TriggerAlertModalModel;
  resetLabel : string;
  pickItemDetail : boolean;
  onKanbannClose : any;
  isKanbannDetailView : boolean;
  isPivot : boolean;
  pivotEntityId : string;
  pivotConsumedEntity : string;
  pivotLOV : any[];
  embedCheckBox:any={};
  docInfo: any;
  mainAccordionFormName : any;
  formComponentObj: any = {};
  presentation: any;
  embeddedTransaction: any;
  kagamiFormModel: any;
  isStepForm : boolean;
  kagamiProcessHeaderModel: kagamiProcessHeaderModel;
  pickItemModalClose :any;
  getControlWidth(uiSettings: any){
    const isVerticalView = uiSettings?.formView === 'vertical' ? true : false;
    const fieldWidth = uiSettings ? uiSettings.fieldWidth?.value : null;

    // const labelWidth =  isVerticalView ? uiSettings.fieldWidth?.label : ''
    if (isVerticalView) {
      let uiSettingsClassName = `col_${fieldWidth} verticalFormView`;
      return uiSettingsClassName;
    } else if (fieldWidth) {
      if (
        fieldWidth === 1 ||
        // fieldWidth === 5 ||
        fieldWidth === 7 ||
        // fieldWidth === 8 ||
        fieldWidth === 9 ||
        fieldWidth === 10 ||
        fieldWidth === 11
      ) {
        let uiSettingsClassName = `col`;
        return uiSettingsClassName;
      } else {
        let uiSettingsClassName = `col_${fieldWidth}`;
        return uiSettingsClassName;
      }
    } else {
      let uiSettingsClassName = `col_4`;
      return uiSettingsClassName;
    }
  };
  constructor(onStartData: any, mainEntityId: any, processName: string, processStepName: string, uiTemplate: string) {
    this.processName = processName;
    this.processStepName = processStepName;
    this.uiTemplate = uiTemplate;
    this.onStartData = onStartData;
    this.mainEntityId = mainEntityId;
    this.errorModal = new KagamiErrorModalModel();
    this.readOnly = false;
    this.formData = new KagamiFormData(
      KagamiBeanUtils.getProperty('id', onStartData.constructOutputData.detailedObjects[mainEntityId]),
      mainEntityId
    );
    this.inFocusFormDataIdentifiers = {};
    this.formComponents = [];
    this.isModelDialog = false;
    this.footerElements = [];
    this.isKanbannDetailView = false;
    this.triggersModel = new KagamiTriggersModel(
      mainEntityId,
      getMainEntityPresentationsFromUIResource(onStartData)[0].actions,
      handleTriggerClick
    );
    this.mainAccordionFormName = getMainEntityPresentationsFromUIResource(onStartData)[0].formName
    this.isGroupForm = false;
    this.isSingleListView = false;
    this.saveLabel = getFormButtonLabel(getMainEntityPresentationsFromUIResource(onStartData)[0],'saveLabel')
    this.resetLabel = getFormButtonLabel(getMainEntityPresentationsFromUIResource(onStartData)[0],'resetLabel')
    this.triggerAlertModalModel= new TriggerAlertModalModel(processName);
    this.pickItemDetail = false;
    this.isPivot = false;
    this.pivotEntityId = '';
    this.pivotConsumedEntity = '';
    this.pivotLOV = [];
    this.embedCheckBox = {};
    this.docInfo = {};
    this.isStepForm = false;
    this.kagamiProcessHeaderModel = new kagamiProcessHeaderModel();
    this.embeddedInfo = {};
  }

  pushToFormControls(formControl: string, value: any) {
    this.formControls[formControl] = value;
  }

  clearFormData(kagamiFormModel : KagamiFormModel){
     kagamiFormModel.formData.clearFormData();
  }
}

export interface FormModelProps {
  kagamiFormModel: KagamiFormModel;
}

export interface FormButtonProps {
  buttonLabel: string;
  onButtonClick: Function;
  kagamiFormModel: KagamiFormModel;
  isSubmitButton: boolean;
}
