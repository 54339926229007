import * as React from 'react';
import { createState, useState } from '@hookstate/core';
import {
  ProfitLossReportModel,
  ProfitLossReportModelProps,
  useProfitLossReportModelState
} from '../model/ProfitLossReportModel';
import { makeReport, makeReportData, getData } from '../service/ProfitLossReportService';
import { StaticReportActions } from '../../../../../../staticReportFilters/ui/StaticReportActions';
import { StaticReportGrid } from '../../../../../../staticReportFilters/ui/StaticReportGrid';

export const BuildProfitLossReportView = (profitLossReportModel: ProfitLossReportModel) => {
  return (
    <ProfitLossReportView key={'profit-loss-report'} profitLossReportModel={profitLossReportModel} />
  );
};
const ProfitLossReportView = (props: ProfitLossReportModelProps) => {
  let runReportConfig: any = useState({
    loadReport: false,
    loadFilterData: true,
    showLoader: false,
    reportInfo: {}
  });
  let profitLossReportModel = props.profitLossReportModel;
  let profitLossChartState = useProfitLossReportModelState(createState(profitLossReportModel));
  profitLossReportModel.state = profitLossChartState;
  let staticFilterData: any;
  if (profitLossReportModel?.reportData?.staticFilterData) {
    staticFilterData = profitLossReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo: any = {}
  if (profitLossReportModel?.reportData?.gridReportInfo) {
    gridReportInfo = profitLossReportModel.reportData['gridReportInfo'];
  }

  let inputReportConfig = (props.profitLossReportModel.reportConfig);
  React.useEffect(() => {
    runReport();
  }, [])

  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo = makeReport(profitLossReportModel);
    let plReportData = await getData(profitLossReportModel, inputReportConfig)
    let rowData = await makeReportData(profitLossReportModel, plReportData)
    reportInfo['rowData'] = rowData;
    reportInfo['reportDBData'] = plReportData;
    const obj = Object.assign({}, reportInfo);
    props.profitLossReportModel.reportData['gridReportInfo'] = obj;
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }
  const gridRef: any = React.useRef();
  const expandAll = React.useCallback(() => {
    if (!profitLossReportModel.collapseAll) {
      gridRef.current!.api.expandAll();
      profitLossReportModel.collapseAll = true;
    } else {
      gridRef.current!.api.collapseAll();
      profitLossReportModel.collapseAll = false;
    }

  }, []);

  let config = { reportModel: profitLossReportModel, inputReportConfig, gridReportInfo, runReportConfig, expandAll, gridRef };
  return (
    <div className="Report-MuiFormControl-root " style={{ width: '100%', background: '#fff', padding: '10px' }}>
      <StaticReportActions config={config} ></StaticReportActions>
      <StaticReportGrid config={config}></StaticReportGrid>
    </div>
  );
};
