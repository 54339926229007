import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from '@mui/icons-material/Info';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import {
  DashboardNotificationProps,
  DashboardNotificationSectionProps,
  KagamiDashboardModel
} from '../model/KagamiDashboardModel';
import { deleteNotificationById, groupByWidgetType, getNotificationDataById } from '../service/DashboardService';
import { KagamiDashboardStyle } from '../style/KagamiDashboard';
import { KagamiAccordionModel } from '../../../../../../../../components/accordion/model/KagamiAccordionModel';
import { buildAccordion } from '../../../../../../../../components/accordion/controller/KagamiAccordionController';
import { KagamiPopoverModel } from '../../../../../../../../components/popover/model/KagamiPopoverModel';
import { buildPopover } from '../../../../../../../../components/popover/controller/KagamiPopoverController';
import { executeProcess } from '../../../../navigator/builder/Menu/controller/KagamiMenuController';
import { KagamiModalModel } from '../../../../../../../../components/modal/model/KagamiModalModel';
import { buildModal } from '../../../../../../../../components/modal/controller/KagamiModalController';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import { buildDashboardFavouriteSection } from './FavouriteBuilder';
import Badge from '@mui/material/Badge';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Fragment } from 'react';
import { buildTriggerModel, enableTrigger } from '../../process/builder/Triggers/service/KagamiTriggersService';
import { ContextType } from '../../process/builder/Triggers/builder/trigger/model/KagamiTriggerModel';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { DashboardGrid, highPriorityWidget } from '../ui/KagamiDashboard';
import { isEnableTabs, translateToLocalistaion } from '../../process/service/PresentationService';
import { KagamiDashboardCardModel } from '../../../../../../../../components/cards/model/KagamiDashboardCardModel';
import { buildDashboardCard } from '../../../../../../../../components/cards/controller/KagamiDashboardCardController';
import highpriority from '../../../../../../../../assets/images/highpriority.svg';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { buildMenuComponent } from './MenuComponent';
import no_info from '../../../../../../../../assets/images/no_info.svg';
import { KagamiStateManager } from '../../../../../../../../state/KagamiStateManager';
import { getTabNavigationModel } from '../../../../header/Tabs/controller/KagamiTabNavigationController';
import { getActiveModel } from '../../process/controller/KagamiProcessController';
import { appContext } from '../../process/service/PolicyExecutionService';

const useStyles = makeStyles(KagamiDashboardStyle);
export function buildDashboardNotificationSections(dashboardModel: any) {
  const notificationData = dashboardModel.notificationData;
  const favouritesData = dashboardModel.state.getfavouritesData;
  const notificationComponents: any[] = [];
  const notifcationGroups: Map<string, any[]> = groupByWidgetType(notificationData);
  let statusModel: KagamiModalModel = new KagamiModalModel();
  notifcationGroups.forEach((value: any[], key: string) => {
    notificationComponents.push(
      <DashboardNotificationSection
        key={`${key}_WIDGET_KEY`}
        header={key}
        notifications={value}
        statusModel={statusModel}
        dashboardModel={dashboardModel}
      />
    );
  });
  let favData = favouritesData? JSON.parse(JSON.stringify(favouritesData)):[];
  if(favData.length){
    notificationComponents.unshift(buildDashboardFavouriteSection(favData));
  }
  return (
    <Fragment key={'dashboard_notification'}>
      <DashboardGrid
        key={'dashboard-notification-layout'}
        component={notificationComponents}
        dashboardModel={dashboardModel}
      />{' '}
      {buildModal(statusModel)}
    </Fragment>
  );
}

export const DashboardNotificationSection = (props: DashboardNotificationSectionProps) => {
  const classes = useStyles();
  let groupAccordions = Array.from(new Set(props.notifications.map((notification) => notification.displayName)));

  let toiterate = (item: any, list: any) => {
    let resArr: any[] = [];

    list.map((i: any) => {
      if (item == i.displayName) {
        resArr.push(i);
      }
    });
    return resArr;
  };
  let notificationsToiterate = Array.from(
    groupAccordions.map((item: any) => [item, toiterate(item, props.notifications)])
  );

  const title: JSX.Element = (
    <div>
      <div className={classes.dashboard_icon_header}>
        {props.header != 'Favourites' ? (
          props.header.toLowerCase() === highPriorityWidget.toLowerCase() ? (
            <img
              src={highpriority}
              alt="no image"
              style={{ height: 27, width: 30, marginLeft: '8px' }}
              className="highpriority-icon-color"
            />
          ) : (
            <InfoIcon className={classes.dashboard_icon}></InfoIcon>
          )
        ) : (
          <StarBorderOutlinedIcon className={classes.dashboard_icon}></StarBorderOutlinedIcon>
        )}
        {props.header != 'INFO' && props.header != 'Favourites' ? (
          <>
            {' '}
            <span
              className={`${classes.dashboard_icon_header} ${
                props.header.toLowerCase() == highPriorityWidget?.toLowerCase() ? 'highpriority-widget-title' : ''
              }`}
            >
              {props.header}
            </span>
            {props.header.toLowerCase() !== highPriorityWidget.toLowerCase() ? (
              <Badge badgeContent={props.notifications.length} color="secondary">
                <MailOutlineIcon color="secondary" />
              </Badge>
            ) : (
              <div className="highpriority-badge-body">
                <p className="highpriority-badge-count">{props.notifications.length}</p>
              </div>
            )}
          </>
        ) : (
          <span className={classes.dashboard_header}>{props.header}</span>
        )}
      </div>
    </div>
  );

  const content: JSX.Element = (
    <div className={classes.notificationContent}>
      {groupAccordions.length > 1 || props.header === 'INFO' ? (
        <BuildSubAccords
          list={notificationsToiterate}
          statusModel={props.statusModel}
          dashboardModel={props.dashboardModel}
          header={props.header}
        />
      ) : (
        props.notifications.map((notification) => {
          return <DashboardNotification key={Math.random()} notification={notification} header={props.header} />;
        })
      )}
    </div>
  );
  const dashboardCardModel = new KagamiDashboardCardModel(title, content);
  return <div className={`col_4 ${classes.dashboardCard}`}>{buildDashboardCard(dashboardCardModel)}</div>;
};

export function BuildSubAccords(props: any) {
  const { list, statusModel, dashboardModel, header } = props;
  const classes = useStyles();
  let translations = dashboardModel?.containerData?.otherProperties?.translation;

  return (
    <>
      {list.length === 0 ? (<>
        <div className={classes.dashboardInfoBackgroundIcon}>
          <img src={no_info} alt="NO NOTIFICATIONS" height={'100%'} width={'100%'}/>
        </div>
        <p className={classes.noInfoText}>{translateToLocalistaion(translations, "Hey, You have no info to show")}</p>
        </>
        ) : (
        list.map((item: any, index: number) => {
          let subTitle: JSX.Element = (
            <div className={classes.dashboard_icon_header}>
              <Badge badgeContent={item.length > 1 ? (Array.isArray(item[1]) ? item[1].length : 0) : 0} color="secondary">
                <MailOutlineIcon color="secondary" />
              </Badge>
              <span className={classes.dashboard_header}>{item.length > 0 ? item[0] : ''}</span>
            </div>
          );
          let active = list.length-1 === index;
          let subcont: JSX.Element = item[1].map((notification: any) => {
            return (
              <DashboardNotification
                header={header}
                key={Math.random()}
                notification={notification}
                statusModel={statusModel}
                dashboardModel={dashboardModel}
              />
            );
          });
          const accordionModel: KagamiAccordionModel = new KagamiAccordionModel(subTitle, subcont, active);
          return buildAccordion(accordionModel);
        })
      )}
    </>
  );
}

export const DashboardNotification = (props: DashboardNotificationProps) => {
  const { notification, statusModel, dashboardModel, header } = props;
  const classes = useStyles();
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  const loginData: any = localStorage.getItem(SystemConstants.LOGIN_DATA);

  let notificationData: any;
  let notificationId = notification['notificationId'];
  let popupModel: KagamiPopoverModel = new KagamiPopoverModel();
  let statusModelPopup: KagamiModalModel = new KagamiModalModel();
  let userId: any = null;

  const setNotificationData = (data: any) => {
    notificationData = data;
  };

  const handleDeleteNotification = () => {
    deleteNotificationById(notificationId, dashboardModel!);
  };

  const handleClick = (processName: string, popoverModel: KagamiPopoverModel, action: any) => {
    if (action.confirmation) {
      statusModelPopup.state.setOpen(true);
      statusModelPopup.content = action.confirmationMsg;
      statusModelPopup.title = action.actionName;
      statusModelPopup.handleOnOk = _handleOnOkAction;
    } else {
      _handleOnOkAction();
    }
    function _handleOnOkAction() {
      executeProcess(processName, notificationData);
    }
    popoverModel.state.setPopoverTarget(null);
  };

  if (CommonUtils.isNotEmpty(loginData)) {
    let loginObject = JSON.parse(loginData);
    userId = loginObject.userId;
  }

  let triggerModels: any[] =
    notification.hasOwnProperty('actions') && notification['actions'].length > 0
      ? getEnabledTriggerModels(notification, notificationData, handleClick)
      : [];

  const handleIconColor = (notification: any, theme: any) => {
    let color: any = CommonUtils.isNotEmpty(notification.colorName) ? notification.colorName : theme.palette.info.dark;
    return color;
  };

  const tabsFunction = (processName: any, transactionEntity: any, name: any, notification: any) => {
    let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
    let tabs = JSON.parse(JSON.stringify(tabNavigationState.getTabs));
    let newName = translateToLocalistaion(translations, name);
    name = newName;
    let isTabAlreadyExist = tabs?.findIndex((item: any) => item?.processName === processName);
    let tabNavigationModel = getTabNavigationModel();
    if (isTabAlreadyExist !== -1) {
      // tabNavigationState.setTabNameInModalPopup({ processName, name });
      // tabNavigationState.setAlreadyExistTabPopupModal(true);
      tabNavigationState?.setActiveTab(processName);
      executeProcess(processName, transactionEntity, [], false, true);
    } else {
      tabs?.push({ processName, name });
      tabNavigationState.setTabs(tabs);
      if (tabs?.length <= 10) {
        tabNavigationState.setActiveTab(processName);
        executeProcess(processName, transactionEntity);
      }
    }
  };

  return (
    <>
      {header !== 'INFO' ? (
        <div
          className={`${classes.dashboardNotificationRoot} ${
            props.header.toLowerCase() === highPriorityWidget.toLowerCase() ? 'highpriority-row-content' : ''
          }`}
        >
          <div className={`${classes.dashboard_notification_header} highpriority-row`}>
            <FiberManualRecordIcon
              sx={{
                color: (theme: any) => handleIconColor(notification, theme)
              }}
              fontSize="small"
              className={classes.dashboard_notofication_circleicon}
            ></FiberManualRecordIcon>
            <span
              className={`${classes.dashboard_notification_message} ${
                props.header.toLowerCase() == highPriorityWidget?.toLowerCase() ? 'highpriority-widget-text' : ''
              }`}
              onClick={() => {
                if (notificationData) {
                  // executeProcess(notification.detail, notificationData);
                  if(isEnableTabs(appContext)) {
                    tabsFunction(
                  notification.detail,
                  notification.transactionEntity,
                  notification.displayName,
                  notification
                )
                } else {
                executeProcess(notification.detail, notificationData);
                }
                } else {
                  getNotificationDataById(notificationId).then((response: any) => {
                    notificationData = response?.transactionEntity;
                    // executeProcess(notification.detail, notificationData);
                    if(isEnableTabs(appContext)) {
                      tabsFunction(
                        notification.detail,
                        notification.transactionEntity,
                        notification.displayName,
                        notification
                      );
                  } else {
                  executeProcess(notification.detail, notificationData);
                  }                
                  });
                }
              }}
            >
              {notification['message']} -{' '}
              <span
                className={`${classes.dashboard_notification_timedifference} ${
                  props.header.toLowerCase() == highPriorityWidget?.toLowerCase() ? 'highpriority-widget-timediff' : ''
                }`}
              >
                {notification['timeDiffMessage']}
              </span>
            </span>
          </div>

          {triggerModels.length > 0 ? (
            <>
              <IconButton
                size="small"
                id="demo-positioned-menu"
                style={{ padding: '2px' }}
                onClick={(event: any) => {
                  popupModel.transactionId = notification['transactionEntity'];
                  popupModel.child = buildMenuComponent(
                    triggerModels,
                    notification,
                    popupModel,
                    notificationId,
                    userId,
                    notificationData,
                    setNotificationData
                  );
                  popupModel.state.setPopoverTarget(event.currentTarget);
                }}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
              {buildPopover(popupModel)}
              {buildModal(statusModelPopup)}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <>
          <div className={classes.dashboardNotificationRoot}>
            <div>
              <span className={classes.dashboard_notification_header}>
                <FiberManualRecordIcon
                  sx={{
                    color: (theme: any) => handleIconColor(notification, theme)
                  }}
                  fontSize="small"
                  className={classes.dashboard_notofication_circleicon}
                ></FiberManualRecordIcon>
                <p
                  className={classes.dashboard_notification_message}
                  onClick={() => handleDetailModal(notification, statusModel)}
                >
                  {notification['message']}
                  <span className={classes.dashboard_notification_timedifference}>
                    {'-' + ' ' + notification['timeDiffMessage']}
                  </span>
                </p>
              </span>
            </div>
            <>
              <IconButton size="small" style={{ padding: '2px' }} onClick={handleDeleteNotification}>
                <CancelPresentationOutlinedIcon fontSize="small" color="error" />
              </IconButton>
            </>
          </div>
        </>
      )}
    </>
  );
};

const handleDetailModal = (notification: any, statusModal: KagamiModalModel) => {
  statusModal.content = (
    <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: notification.detail }}></div>
  );
  statusModal.title = notification.message;
  statusModal.state.setOpen(true);
};

export const getEnabledTriggerModels = (notification: any, notificationData: any, handleClick: any) => {
  let enabledTriggers: any = [];
  {
    notification.actions.map((item: any) => {
      let triggerModel = buildTriggerModel(
        CommonUtils.isNotEmpty(notificationData?.entityType) ? notificationData.entityType : '',
        item,
        handleClick,
        false
      );
      triggerModel.enableTrigger = triggerModel.contextType === ContextType.None;
      triggerModel.detailedObject = notificationData;
      triggerModel.policy = item.policy;
      if (triggerModel.detailedObject) {
        triggerModel.enableTrigger = enableTrigger(
          triggerModel,
          triggerModel.detailedObject instanceof Array ? triggerModel.detailedObject : [triggerModel.detailedObject],
          triggerModel.enableTrigger
        );
      }
      enabledTriggers.push(triggerModel);
    });
  }
  return enabledTriggers;
};
