import _ from 'lodash';
import * as htmlToImage from 'html-to-image';
// import { toPng } from 'html-to-image';


import {
    getReportData,
    reportColumnDisplayFormatter,
    reportFilterHeader,
    getReportId,
    getReportName
} from '../../../../service/ReportUtilService';
import { getFilterSelectedHeader } from '../../../../service/KagamiReportService';
import { getDrillDownReportData, handleDrillDownReport } from '../../../../service/ReportDrillDownService';
const pieDefaultConfig = () => {
    return {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 450,
        },
        exporting: {
            enabled: false
        },
        title: {
            text: '',
            align: 'center',
        },
        caption: {
            text: '',
            align: 'center',
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>',
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.y}',
                },
                showInLegend: false
            }
        },
        credits: {
            enabled: false,
        },
        series: [{
            name: '',
            colorByPoint: true,
            data: [],
            innerSize: '100%',
        }]
    }
}

export const makePie = (pieReportController: any) => {
    let defaultConfig = pieDefaultConfig();
    let dbChartData = JSON.parse(pieReportController.reportData);
    let tablerecords = getReportData(dbChartData, pieReportController.reportConfig);
    let userConfig = prapareUserConfigForChart(tablerecords, pieReportController, false);
    let dispchartOptions = _.extend(true, {}, defaultConfig, userConfig);
    return {
        pieConfig: dispchartOptions,
        tableConfig: makeTableDetails(pieReportController.reportConfig, userConfig)
    }
}

export const makeAdditionalReport = (additionalReportId:any,pieReportController:any) => {
    getDrillDownReportData(additionalReportId,pieReportController,{isAddionalReport:true});
}

const prapareUserConfigForChart = (tablerecords: any, pieReportController: any, isDashboard: any) => {
    let reportConfig = pieReportController.reportConfig;
    let userConfig: any = {
        series: [{
            data: [],
            point: {
                events: {
                    click: function (event:any) {
                        // return;
                        let $$this:any = this;
                        let value =  $$this?.['options']?.['name'] || '';
                        handleDrillDownReport(event,pieReportController,reportConfig,value,reportConfig.verticalAxis[0]);
                    }
                }
            }
            //   innerSize: '70%', 
        }],
    };
    let otherSettings: any;
    let seriesData: any = [];
    let customLegendData = [];
    //series data
    let xaxis = reportConfig.horizontalAxis[0];
    let yaxis = reportConfig.verticalAxis[0];
    if (xaxis && yaxis) {
        _.forEach(tablerecords, function (rec, index) {
            seriesData.push({
                name: rec[yaxis],
                y: parseFloat(rec[xaxis])
            });
            let legendVal = parseFloat(rec[xaxis]);
            let legendFormattedVal = legendVal;
            legendFormattedVal = legendVal && reportColumnDisplayFormatter(reportConfig, legendFormattedVal, rec[xaxis], '');
            customLegendData.push({
                name: rec[yaxis],
                value: legendVal,
                color: otherSettings && otherSettings.colors?.length > index ? otherSettings.colors[index] : ''
            });
        });
    }
    userConfig.series[0].data = seriesData;
    return userConfig;
}
const makeTableDetails = (reportConfig: any, pieReportConfigDetails: any) => {
    let isBesideTableDetails = false;
    let showTableDetails = false;
    let detailedTableconfig;
    let additionalReportId = false;
    if (reportConfig?.reportSettings) {
        let otherSettings = reportConfig.reportSettings;
        if (otherSettings && otherSettings.showTableDetails) {
            showTableDetails = true;
            isBesideTableDetails = otherSettings.isBesideTableDetails || false;
            detailedTableconfig = {
                columns: [
                    { field: "name", headerName: reportConfig?.verticalAxis?.[0] || 'Title' },
                    { field: "y", headerName: reportConfig.horizontalAxis[0] || 'Count' },
                ],
                data: pieReportConfigDetails?.series?.[0].data || []
            }
        }
        if(otherSettings && otherSettings.additionalReportId) {
            additionalReportId = otherSettings.additionalReportId;
            isBesideTableDetails = true;
       }
    }
    return {
        isBesideTableDetails,
        detailedTableconfig,
        showTableDetails,
        additionalReportId
    }
}

export const downloadPiePDF = (chartRef: any, downloadConfig: any,pieReportModel:any) => {
    let html = chartRef.current.container.current.getInnerHTML()
    if(htmlToImage){
        htmlToImage.toPng(chartRef.current.container.current)
        .then(function (dataUrl: any) {
            var img = new Image();
            img.src = dataUrl;
            downloadPdfTable(img, '', downloadConfig,pieReportModel);
        })
        .catch(function (error: any) {
            console.error('oops, something went wrong!', error);
        });
    }
}
const downloadPdfTable = function (pdfImage: any, mode: any, downloadConfig: any,pieReportModel:any) {
    let reportName = getReportName(pieReportModel);
    let doc: any = {
        content: [
            {
                text: reportName,
                alignment: 'center',
                fontSize: 13,
            },
            {
                text: getFilterSelectedHeader(pieReportModel),
                alignment: 'center',
                fontSize: 9,
            },
            {
                image: pdfImage.src,
                fit: [490,490]
            },
            {
                table: {
                    body: getPdfTableData(downloadConfig?.tableConfig?.detailedTableconfig) || [],
                    alignment: "center"
                }
            },
        ],
    }
    let headers = [];
    if (doc.content && doc.content[1] && doc.content[1].table && doc.content[1].table.body) {
        headers = _.cloneDeep(doc.content[1].table.body[0]);
        doc.content[1].table.body[0] = [];
    }
    _.each(headers, function (val) {
        var obj = { text: val, bold: true, fontSize: 14 };
        doc.content[1].table.body[0].push(obj);
    })

    import('pdfmake/build/pdfmake').then(({default : pdfMake}) => {
        import('pdfmake/build/vfs_fonts').then(({default : pdfFonts}) => {
          (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
          if (mode == 'print') {
            pdfMake.createPdf(doc).print();
           } else {
               pdfMake.createPdf(doc).download(reportName || 'Report');
            }
        })
    })
    
}

const getPdfTableData = (detailedTableconfig: any) => {
    let tableData = [_.map(detailedTableconfig.columns, 'headerName')];
    if (detailedTableconfig?.data?.length) {
        _.forEach(detailedTableconfig.data, function (rec) {
            tableData.push(_.values(rec));
        })
        let totalRow:any = []
        _.forEach(detailedTableconfig.columns, function (col:any,idx:any,array) {
            if (idx === array.length - 1){ 
                totalRow.push("Total : "+( _.sum(_.map(detailedTableconfig.data,'y'))))
            }else {
                totalRow.push("");
            }
        })
        tableData.push(totalRow)
    }
    return tableData;
}

export const getTotalOfReportColumn = (_config:any) => {
    if(_config?.tableConfig?.detailedTableconfig?.data){
        return  _.sum(_.map(_config.tableConfig.detailedTableconfig.data,'y'))
      }
      return 0;
}
