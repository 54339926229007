import { KagamiListModel} from '../model/KagamiListModel';
import { buildListView } from '../ui/KagamiList';
import { KagamiBeanUtils } from '../../../../../../../../../utils/KagamiBeanUtils';
import { getKagamiGridModel, reBuildGridView, updateTotalRecords } from '../builder/grid/controller/KagamiGridController';
import { reBuildCalendarView } from '../builder/calendar/controller/KagamiCalendarViewController';
import { reBuildCardView } from '../builder/card_view/controller/KagamiCardViewController';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { KagamiTriggersModel } from '../../Triggers/model/KagamiTriggersModel';
import { KagamiTriggerModel } from '../../Triggers/builder/trigger/model/KagamiTriggerModel';
import { KagamiTriggersController } from '../../Triggers/controller/KagamiTriggersController';
import { handleAssignItemClick, onTriggerClick } from '../../Triggers/service/KagamiTriggersService';
import { getActiveModel, loadProcessContent } from '../../../controller/KagamiProcessController';
import {
  getDefaultListView,
  getMainEntityPresentationsFromUIResource,
  retrievePresentation
} from '../../../service/PresentationService';
import { prepareForLoadCall } from '../../../service/SubProcessService';
import { showServerErrorPopUp } from '../../../../../../../../../controller/KagamiApplicationController';
import { reBulidHierarchyView } from '../builder/hierarchy_view/controller/KagamiHierarchyViewController';
import { reBulidKanbannView } from '../builder/kanbann/controller/KagamiKanbanSectionsController';
import { reBuildCrossTabView } from '../builder/crosstab/controller/KagamiCrossTabViewController';
import { loadGroupContent } from '../../group/controller/KagamiGroupController';
import { KagamiFormModel } from '../../form/model/KagamiFormModel';
import { getFormModel, setFormModel,getPreviousFormModel } from '../../form/controller/KagamiFormController';
import { _getUnSyncedFormRecord } from '../../form/service/FormService';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import {reBuildListFilterComponent } from '../builder/presentationFilters/controller/KagamiListFilterController';
import { getBreadcrumbsModel, reBuildBreadcrumb, setBreadcrumbsModel } from '../../breadcrumbs/controller/kagamiBreadcrumbsController';
import { KagamiGridModel } from '../builder/grid/model/KagamiGridModel';
import { makeOnLoadCall } from '../../../service/ProcessService';
import { hideLoader, showLoader } from '../../../../../../../../../../components/loader/controller/KagamiLoaderController';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { handleClearFilters } from '../builder/presentationFilters/buliders/clearFilterButton/controller/KagamiClearFiltersController';


let kagamiListModel: KagamiListModel;

export function getKagamiListModel() {
  return kagamiListModel;
}

export function setKagamiListModel(kagamiList: KagamiListModel) {
  kagamiListModel = kagamiList;
}

export function buildList(kagamiListModel: KagamiListModel): any {
  kagamiListModel.listTemplate = getDefaultListView(kagamiListModel.onStartData);
  kagamiListModel.triggersModel = new KagamiTriggersModel(
    kagamiListModel.mainEntityId,
    getMainEntityPresentationsFromUIResource(kagamiListModel.onStartData)[0].actions,
    kagamiListModel.isPickItemListModel ? handlePickItemTriggerClick : handleTriggerClick
  );
  kagamiListModel.triggersModel.viewType = kagamiListModel.listTemplate;
  setKagamiListModel(kagamiListModel);
  kagamiListModel.viewType =  getDefaultListView(kagamiListModel.onStartData);

  return buildListView(kagamiListModel);
}

export function reBuildList(response: any,updateRecord: boolean = false , templateType?:string,mergeOnloadData? : boolean) {
  let listModel : KagamiListModel = getKagamiListModel();
  let mainEntityId = listModel.mainEntityId;
  var detailedObjects = KagamiBeanUtils.getProperty('constructOutputData.detailedObjects.'+mainEntityId, response) ?? {};
  listModel.onLoadData = detailedObjects;
  listModel.totalRecords = response?.constructOutputData?.verbProperties?.totalRecords;
  let totalRecords = listModel.totalRecords;
  let viewType: string = CommonUtils.isNotEmpty(listModel.triggersModel.viewType) ? listModel.triggersModel.viewType : getDefaultListView(listModel.onStartData);
  if (viewType === SystemConstants.CARD_VIEW_TYPE) {
    reBuildCardView(detailedObjects);
  } else if (viewType === SystemConstants.CALENDAR_VIEW_TYPE) {
    listModel.onLoadData = response.constructOutputData
    let breadcrumbsModel = getBreadcrumbsModel();
    if (CommonUtils.isNotEmpty(breadcrumbsModel.activeModel)) { 
      breadcrumbsModel.activeModel['onLoadData'] = detailedObjects; 
      setBreadcrumbsModel(breadcrumbsModel);
      reBuildBreadcrumb();
    }
    reBuildCalendarView(response.constructOutputData.detailedObjects[mainEntityId]);
    updateTotalRecords(listModel,totalRecords)
  } else if (viewType === SystemConstants.LIST_VIEW_TYPE || viewType === SystemConstants.GRID_VIEW_TYPE) {
    // rebuildListFilters(response.constructOutputData.detailedObjects, mainEntityId)
     if(listModel instanceof KagamiGridModel){
      reBuildListFilterComponent(response);
      //updating docInfo from onload response in listmodel
      let docInfo = response.constructOutputData.hasOwnProperty('docInfo') ? response.constructOutputData.docInfo : {}
      listModel.docInfo = {...listModel.docInfo, ...docInfo}     
      reBuildTriggers() 
      reBuildBreadcrumb()
      reBuildGridView(listModel,detailedObjects,mainEntityId,updateRecord,mergeOnloadData);  
     } 
     if(listModel instanceof KagamiGridModel){
      let gridModel = getKagamiGridModel();
      if(!gridModel.isEmbedModel) {
        updateTotalRecords(gridModel,totalRecords)
     }}
  }else if (viewType === SystemConstants.KANBAN_VIEW_TYPE){
    reBulidKanbannView(response.constructOutputData.detailedObjects);
  }else if (viewType === SystemConstants.HIERARCHICAL_VIEW_TYPE){
    reBulidHierarchyView(listModel,response?.constructOutputData?.detailedObjects[mainEntityId],mainEntityId);
  }else if (viewType ===SystemConstants.CROSS_TAB){
    reBuildCrossTabView(response.constructOutputData.detailedObjects,mainEntityId);
  }
  listModel.kagamiListHeaderModel?.state?.setheaderVisible(true)
}

export function handleOnload(listModel: KagamiListModel) {
  let changeFor = {};
  let entityPresentation: any = retrievePresentation(listModel.onStartData);
  prepareForLoadCall(
    listModel.processName,
    entityPresentation,
    changeFor,
    listModel.detailedObjects,
    listModel.runtimeInput,
  )
}

export function handleTriggerClick(triggerModel: KagamiTriggerModel) {
  // triggerModel.triggerCallback = loadProcessContent;
  let listModel = getKagamiListModel();
  onTriggerClick(listModel.selectedObjects, triggerModel, handleServerError,listModel.isGroupListModel ? loadGroupContent : loadProcessContent,listModel.isGroupListModel);
}

export function handlePickItemTriggerClick(triggerModel : KagamiTriggerModel ){
  let listModel = getKagamiListModel();
  let activeModel = getActiveModel()
  let selectedObjects : any[]= [];
  let processName = triggerModel.processName;
  let modalState = KagamiStateManager.getStateToHolder('PickItemModal');
  if(modalState){
    let parentEntityId = modalState.parentEntityId;
    let premodel:KagamiFormModel |undefined = getPreviousFormModel() ;
    premodel && setFormModel(premodel );
    let formModel : KagamiFormModel = getFormModel() ;
    _getUnSyncedFormRecord(formModel,parentEntityId)
    listModel.selectedObjects.map((record: any) => {
      let detailedObjects: any[] = Array.isArray(listModel.detailedObjects)
        ? listModel.detailedObjects
        : listModel.detailedObjects[listModel.mainEntityId] ?? [];
     let detailRecord : any =  detailedObjects.find((r:any) => r.id === record.id)
     if(detailRecord){
      selectedObjects.push(detailRecord);
     }
    })

    let changedData = formModel.formData.changedData;
    changedData[listModel.mainEntityId] = selectedObjects;
    handleAssignItemClick(triggerModel,listModel.processName,formModel,parentEntityId );
  }
}

export function reBuildTriggers() {
  let activeModel :any =getActiveModel();
  KagamiTriggersController.reBuildTriggers(getKagamiListModel().triggersModel ,getKagamiListModel().selectedObjects);
  }

export function handleGridOnLoadData(listModel:KagamiListModel){
  let data: any = {};
  showLoader();
  makeOnLoadCall(listModel.processName , listModel.presentation ,{},data , null, {}, false).then((response:any) =>{
    hideLoader();
    listModel.detailedObjects = response.data['constructOutputData']['detailedObjects'][listModel.mainEntityId] 
    listModel.isListLoaded = true; 
    listModel.triggersModel.viewType = SystemConstants.GRID_VIEW_TYPE;
    listModel.state.setViewType(SystemConstants.GRID_VIEW_TYPE);
    handleClearFilters(listModel.kagamiListFilterModel,false);

  }).catch((error:any) =>{
    console.log("error" , error)

  })
}

export function handleCardOnLoadData(listModel:KagamiListModel){
  let data: any = {};
  showLoader();
  makeOnLoadCall(listModel.processName , listModel.presentation ,{},data , null, {}, false).then((response:any) =>{
    hideLoader();
    listModel.detailedObjects = response.data['constructOutputData']['detailedObjects'][listModel.mainEntityId]
    listModel.isListLoaded = true; 
    listModel.triggersModel.viewType = SystemConstants.CARD_VIEW_TYPE;
    listModel.state.setViewType(SystemConstants.CARD_VIEW_TYPE)
     handleClearFilters(listModel.kagamiListFilterModel,false);

  }).catch((error:any) =>{
    console.log("error" , error)
  })
}

export function handleCalendarOnLoadData(listModel:KagamiListModel){
  let data: any = {};
  showLoader();
  makeOnLoadCall(listModel.processName , listModel.presentation ,{},data , null, {}, false).then((response:any) =>{
    hideLoader();
    listModel.detailedObjects = response.data['constructOutputData']['detailedObjects'][listModel.mainEntityId]
    listModel.isListLoaded = true; 
    listModel.triggersModel.viewType = SystemConstants.CALENDAR_VIEW_TYPE
    listModel.state.setViewType(SystemConstants.CALENDAR_VIEW_TYPE);
    handleClearFilters(listModel.kagamiListFilterModel,false);
  }).catch((error:any) =>{
    console.log("error" , error)
  })
}

export function handleHierarchicalOnLoadData(listModel:KagamiListModel){
  let data: any = {};
  showLoader();
  makeOnLoadCall(listModel.processName , listModel.presentation ,{},data , null, {}, false).then((response:any) =>{
    hideLoader();
    listModel.detailedObjects = response.data['constructOutputData']['detailedObjects'][listModel.mainEntityId]
    listModel.isListLoaded = true 
    listModel.isVisibleViewHierarchy = true;
    listModel.triggersModel.viewType = SystemConstants.HIERARCHICAL_VIEW_TYPE
    listModel.state.setViewType(SystemConstants.HIERARCHICAL_VIEW_TYPE);
    handleClearFilters(listModel.kagamiListFilterModel,false);
  }).catch((error:any) =>{
    console.log("error" , error)
  })
}


export function handleKanbanOnLoadData(listModel:KagamiListModel){
  let data: any = {};
  showLoader();
  makeOnLoadCall(listModel.processName , listModel.presentation ,{},data , null, {}, false).then((response:any) =>{
    hideLoader();
    listModel.detailedObjects = response.data['constructOutputData']['detailedObjects'][listModel.mainEntityId]
    listModel.isListLoaded = true 
    listModel.isVisibleViewHierarchy = true;
    listModel.triggersModel.viewType = SystemConstants.KANBAN_VIEW_TYPE
    listModel.state.setViewType(SystemConstants.KANBAN_VIEW_TYPE)
    handleClearFilters(listModel.kagamiListFilterModel,false);
  }).catch((error:any) =>{
    console.log("error" , error)
  })
}
export function handleServerError(error: any) {
  showServerErrorPopUp(error);
}

