import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import { TextValidator } from '../../../../../../controls/textbox/validations/TextValidator';

export const KagamiEmbeddedTabsStyle = (theme: Theme) =>
  createStyles({
    mainRoot :{
      '&.fullWidthAccordSection':{
        width: '100%',
        '& .MuiTabs-root': {
          minHeight:'0',
        },
        '& .MuiButtonBase-root' : {
          overflow:'inherit',
          height: '25px',
        },
        '& .MuiTabs-scroller.MuiTabs-fixed': {
          overflow:'auto !important',
          // border:'1px solid #006e7f',
           borderRadius:'4px',
           backgroundColor: "#ffff",
           marginBottom: '4px!important',
           maxWidth: 'fit-content'
        },
      '& > div:last-child': {
        // borderLeft: '1px solid #ebebeb',
        // borderBottom: '1px solid #ebebeb',
        // // borderTop: '1px solid #ebebeb', 
        // borderRight: '1px solid #ebebeb',
      }
      },
      '& .MuiTabs-flexContainer':{
        // backgroungColor: theme.palette.background.default,
        padding: '5px',
      },
    },
    root: { 
      // background: theme.palette.background.default,
      // //  background: '#EEF4FB',
      //  width: 'fit-content',
      // //  borderTop: '1px solid #ebebeb',
      // //  borderRight: '1px solid #ebebeb',
      //  height: theme.spacing(4.5),
      //  minHeight: theme.spacing(4.5), 
       marginTop:'10px',
       marginLeft:'7px',
       overflow: 'inherit',
       '& .MuiTab-root': {
         minHeight: theme.spacing(1.62), 
        //  margin: `0 ${theme.spacing(1)}`,
        //  borderLeft: '1px solid #ebebeb',
        //  borderRight: '1px solid #ebebeb',
        color: '#bbb6b6',
        fontWeight: '500',
        padding:'10px',
        margin: '0px 0px',
        '&> div': {
          alignItems:'center',
        },

        '&.Mui-selected': {
          // background: theme.palette.common.white,
          // background: theme.palette.background.default,
          // background: theme.palette.background.defaultBlue,
          // borderBottom: theme.palette.common.white,
          // color: '#29947f',
          // color: '#0059b3',
          // fontWeight: '600',
          // color: '#fff',
          // background: '#006e7f',
          color: '#29947f',
          borderBottom: '2px solid #29947f',
          margin: '0px 0px',
        },
       '& .MuiTab-wrapper': {
        //  color: theme.palette.common.black,
         textTransform: 'capitalize',
         fontSize: theme.typography.subtitle1.fontSize,
         paddingRight:'6px',
       }
      }
    }, 
    tabLabel: { 
      fontSize: "15px !important",
      color: 'inherit', 
      fontWeight:  'inherit',
      textTransform: "none",
      // paddingRight:'10px',
      paddingRight: '0px',
      margin: '0px -10px 0px -10px'
    },
    indicator: {
      bottom: '0',
      height: '0',
      backgroundColor: '#156282',
    },
    rowCounter: {
      // border: '1px solid #bbb6b6',
      display:'flex', 
      alignItems:'center', 
      justifyContent:'center',
      // top:"-5px",
      height: 'auto',
      width: 'auto', 
      borderRadius:'50%',
      transition: 'border-color 0.3s',
      padding:'1px 4px',
      fontSize: '0.65rem',
      // marginTop: '-20px'
    },
  
     
  });

 



