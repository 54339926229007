import { transition } from './../../../../../../../../../style/KagamiApplicationStyles';
import { hideLoader } from '../../../../../../../../../../components/loader/controller/KagamiLoaderController';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { APIService } from '../../../../../../../../../service/ApiService'
import { deleteFolderURL, deleteReportURL, exportDetailedLedgerURL, exportShilpaPerformanceReportURL, getAllRolesURL, getAllStudioReportURL, getBalanceSheetReportURL, getCalendarDatUrl, getFilterDataURL, getGuageDataUrl, getLedgerURL, getPLReportURL, getReportConfigurationURL, getReportConfigURL, getReportDataURL, getReportListURL, getReportTranslationURL, getRoleReportMappingURL, getStaticFilterDataURL, getStaticFilterURL, getStockLedgerURL, getTBReportURL, updateRoleReportMappingURL } from '../../../../../../../../../service/URLService'
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { ReportConstant } from '../../ReportConstant/ReportConstant';
import { KagamiReportModel } from '../model/KagamiReportModel';
import { STATIC_REPORT_TRANSALATIONS_KEYS } from './ReportTranslationUtil';
import { getObjectForReportData, getReportType, getValueInDateTimeFormatAsAppSetting, isStaticReport } from './ReportUtilService';

export async function getReportList() {
    let apiResponse: string = await APIService.getData('GET', getReportListURL(), null);
    return apiResponse;
    
  }

function getReportConfiguration(reportData: any) {
  let _rid = reportData['reportId'];
  return APIService.getData(SystemConstants.GET, getReportConfigurationURL(_rid), '');
}

async function getReportData(reportData: any) {
  return await APIService.getData(SystemConstants.POST, getReportDataURL(), reportData);
}

  export async function getReportConfig(reportId: any) {
    let apiResponse = await APIService.getData('GET', getReportConfigURL(reportId),"");
    return apiResponse;
    
  }

  export async function getFilterData(reportId: string , requestData: any) {
    let apiResponse = await APIService.getData('POST', getFilterDataURL(), requestData);
    return apiResponse;
    
  }

  export async function getGuageChartData() {
    let apiResponse = await APIService.getData('GET', getGuageDataUrl(), null);
    return apiResponse;
    
  }

  export async function getCalendarData() {
    let apiResponse = await APIService.getData('GET', getCalendarDatUrl(), null);
    return apiResponse;
    
  }

  export async function getStaticFilterData(ledgerType:any) {
    let apiResponse = await APIService.getData('GET', getStaticFilterDataURL(ledgerType), null);
    let response = apiResponse;
    return response;
  }
  export async function getStaticFilterInfo(requestData: any) {
    return  await APIService.getData('GET', getStaticFilterURL()+'?'+requestData, '');
  }
  export async function getLedgerReportData(requestData: any) {
    return  await APIService.getData('POST', getLedgerURL(), requestData);
  }
  export async function getStockLedgerReportData(requestData: any) {
    return await APIService.getData('GET', getStockLedgerURL()+'?'+requestData, '');
  }
  export async function getPLReportData(requestData: any) {
    return  await APIService.getData('POST', getPLReportURL(), requestData);
  }
  export async function getTBReportData(requestData: any) {
    return  await APIService.getData('POST', getTBReportURL(), requestData);
  }
  export async function getBalanceSheetReportData(requestData: any) {
    return  await APIService.getData('POST', getBalanceSheetReportURL(), requestData);
  }
  export async function exportDetailedLedger(requestData: any) {
    return  await APIService.getData('POST', exportDetailedLedgerURL(), requestData);
  }
  
  export const getReportDataForDynamicReports = async (reportModel: any) => {
    let reportType = reportModel.reportConfig.reportConfigSummary.type;
    let reportData: any = {
      reportId: reportModel.reportConfig.reportConfigSummary.id,
      queryParam: JSON.stringify([{}]),
      offset: 330
    };

    if (CommonUtils.isNotEmpty(reportModel.reportFilterModel)) {
      reportData = getObjectForReportData(reportModel);
    }

    await getReportData(reportData).then((response: any)=>{
      let data: any;
      data = response?.data ?? null; 
      setReportData(reportModel, data);
    }).catch((err:any)=>{
      console.log('getting error while accessing report data')
    })
    if (reportType == 'List') {
      await getReportConfiguration(reportData).then((response: any)=>{
      reportModel.reportConfiguration = response?.data;
    }).catch((err:any)=>{
      console.log('getting error while accessing list reportconfig')
    })
    }
    if (reportModel) reportModel.state?.setDataLoaded(true);
  };

function setReportData (reportModel:any, data:any) {
  let reportData = JSON.parse(data?.respObject);
  if (reportData['table'] !== undefined && reportData['table']['rows'].length) {
    reportModel.reportData = data.respObject;
  } else {
    reportModel.reportData = 0;
  }
  if (reportModel.hasOwnProperty('reportHeaderObject') && data.reportHeaderObject) {
    reportModel['reportHeaderObject'] = data.reportHeaderObject;
  }
}

export const getFilterSelectedData = (kagamiReportModel: KagamiReportModel) => {
  if (kagamiReportModel?.reportFilterModel?.displayFilterValue) {
    kagamiReportModel.reportFilterModel.displayFilterValue = [];
  }
  kagamiReportModel.reportConfig?.criteria?.filters?.rules.map((filterConfig: any) => {
    if (!filterConfig.isuser || CommonUtils.isNotEmpty(filterConfig['value'])) {
      // let value = kagamiReportModel.reportFilterModel.selectedValue.get(filterConfig.field);
      let value:any;
      if(CommonUtils.isNotEmpty(filterConfig['value'])){
        value = CommonUtils.isNotEmpty(kagamiReportModel?.reportFilterModel?.selectedValue.get(filterConfig.field))
          ? kagamiReportModel?.reportFilterModel?.selectedValue.get(filterConfig.field)
          : filterConfig['value'];
      }else{
        value = kagamiReportModel?.reportFilterModel?.selectedValue.get(filterConfig.field)??'';
      }
      let $$displayFilterData: any = {};
      $$displayFilterData['name'] = filterConfig.field;
      $$displayFilterData['type'] = filterConfig.type;
      if (value !== 'NOPOP' && value !== 'undefined' && CommonUtils.isNotEmpty(value)) {
        $$displayFilterData['relationName'] = filterConfig.relationName;
        $$displayFilterData['value'] =
          filterConfig.type === 'DateTime' || filterConfig.type === 'Date'
            ? getDateValue(value, filterConfig.relationName)
            : value;
        kagamiReportModel.reportFilterModel.displayFilterValue.push($$displayFilterData);
      } else {
        $$displayFilterData['relationName'] = ' = ';
        $$displayFilterData['value'] = 'ALL';
        kagamiReportModel.reportFilterModel.displayFilterValue.push($$displayFilterData);
      }
    }
  });
};

const getDateValue = (value: string, relationName: string) => {
  if (
    relationName === ReportConstant.Is ||
    relationName === ReportConstant.Is_Not ||
    relationName === ReportConstant.Is_After ||
    relationName === ReportConstant.Is_Before ||
    relationName === ReportConstant.Is_After_Or_Equal ||
    relationName === ReportConstant.Is_Before_Or_Equal ||
    relationName === ReportConstant.Today
  ){
    return eachDateFixToActualDate(value);
  } else {
    return value;
  }
};

const eachDateFixToActualDate = (date: string) => {
  return date
    .split(' ')
    .map((val: string, index: number) => {
      if (index === 1) {
        return val.split(':').some((time: string) => {
          return +time > 0;
        })
          ? val
          : '';
      } else {
        return val;
      }
    })
    .join(' ');
};

export const getFilterSelectedHeader = (kagamiReportModel: KagamiReportModel) => {
  let value: string = '';
  kagamiReportModel?.reportFilterModel?.displayFilterValue.map((selectedData: any, index: number) => {
    if (
      (selectedData.type === 'Date' || selectedData.type === 'DateTime') &&
      (selectedData.relationName === ReportConstant.Is_In_The_Range ||
        selectedData.relationName === ReportConstant.Is_Not_In_The_Range)
    ) {
      let data = selectedData.value.split(',');
      let fromDate = getValueInDateTimeFormatAsAppSetting(data[0], selectedData.type);
      let toDate = getValueInDateTimeFormatAsAppSetting(data[1], selectedData.type);
      value += `${selectedData.name} ${selectedData.relationName} from ${fromDate} To ${toDate}`;
      value += kagamiReportModel.reportFilterModel.displayFilterValue.length !== index + 1 ? ', ' : '';
    } else if (
      selectedData.type === 'Number' &&
      (selectedData.relationName === ReportConstant.Is_In_The_Range ||
        selectedData.relationName === ReportConstant.Is_Not_In_The_Range)
    ) {
      let data = selectedData.value.split(',');
      value += `${selectedData.name} ${selectedData.relationName} Min ${data[0]} Max ${data[1]}`;
      value += kagamiReportModel.reportFilterModel.displayFilterValue.length !== index + 1 ? ', ' : '';
    } else {
      if(selectedData.type === 'Date' || selectedData.type === 'DateTime'){
        let date = getValueInDateTimeFormatAsAppSetting(selectedData.value, selectedData.type);
        value += `${selectedData.name} ${selectedData.relationName} ${date}`;
      }else {
        value += `${selectedData.name} ${selectedData.relationName} ${selectedData.value}`;
      }
      value += kagamiReportModel.reportFilterModel.displayFilterValue.length !== index + 1 ? ', ' : '';
    }
  });
  return value;
};

  export async function getAllRoles() {
    return await APIService.getData('GET', getAllRolesURL(), '');
  }
  export async function getReportsByRole() {
    return await APIService.getData('GET', getReportListURL(), '');
  }
  export async function getRoleReportMapping() {
    return await APIService.getData('GET', getRoleReportMappingURL(), '');
  }
  export async function updateRoleReportMapping(respObject: any) {
    return await APIService.getData('POST', updateRoleReportMappingURL(), respObject);
  }
  export async function getAllStudioReport() {
    return await APIService.getData('GET', getAllStudioReportURL(), '');
  }

  export const deleteReport = async (reportId: any) => {
    return await APIService.getData(SystemConstants.GET, deleteReportURL(reportId), {});
  };

  export const deleteReportFolder = async (folderId: any) => {
    return await APIService.getData(SystemConstants.POST, deleteFolderURL(folderId), {});
  };
  export const exportShilpaPerformanceReport = async (reportDetails: any) => {
    return await APIService.getData('POST', exportShilpaPerformanceReportURL(), reportDetails);
  };

  export const getReportTranslationData = async (reportConfig: any,language: any) => {
    let reportType = getReportType(reportConfig);
    let translationValues: any = {};
    if (isStaticReport(reportType)) {
     let tranlationKeys = STATIC_REPORT_TRANSALATIONS_KEYS.LEDGER_COMMON.concat(STATIC_REPORT_TRANSALATIONS_KEYS[reportType] || []);
      translationValues = await APIService.getData('POST_SINGLE_VALUE_WITH_LANGUAGE', getReportTranslationURL(), tranlationKeys,language);
    }
    return translationValues?.data;
  }