import { createState } from "@hookstate/core";
import { IconButton, TextField, TextFieldProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useRef } from "react";
import { CommonUtils } from "../../../../../../../../../../../utils/CommonUtils";
import { KagamiListModel } from "../../../model/KagamiListModel";
import { searchRecord } from "../../../service/kagamiListService";
import { KagamiListStyle } from "../../../style/ListStyle";
import {
  KagamiListSearchModel,
  ListSearchBarProps,
  useSearchState,
} from "../model/KagamiListSearchModel";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { getKagamiListFilterModel } from "../../../../../../../../../../../builder/home/builder/body/builder/process/builder/list/builder/presentationFilters/controller/KagamiListFilterController";
import { handleClearFilters } from "../../../../../../../../../../../builder/home/builder/body/builder/process/builder/list/builder/presentationFilters/buliders/clearFilterButton/controller/KagamiClearFiltersController";
import { KagamiGridModel } from "../../grid/model/KagamiGridModel";
import { getKagamiGridModel } from "../../grid/controller/KagamiGridController";
import { getActiveModel } from "../../../../../controller/KagamiProcessController";
import { translateToLocalistaion } from "../../../../../service/PresentationService";
import { getKagamiListModel } from "../../../controller/KagamiListController";
import useKeyboardShortcut from "use-keyboard-shortcut";

export function buildListSearchBarComponent(
  listSearchModel: KagamiListSearchModel
) {
  return (
    <KagamiListSearchBar
      key={listSearchModel.kagamiListModel.processName}
      listSearchbarModel={listSearchModel}
    />
  );
}

const useStyles = makeStyles(KagamiListStyle);

const KagamiListSearchBar = (props: ListSearchBarProps) => {
  const inputRef = useRef<any>();

  const { flushHeldKeys } = useKeyboardShortcut(
    ["Control", "M"],
    (shortcutKeys: any) => {
      console.log("triggered");
      props?.listSearchbarModel?.inputRef?.current?.focus();
    },
    {
      overrideSystem: false,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  );

  let classes = useStyles();
  props.listSearchbarModel.state = useSearchState(
    createState(props.listSearchbarModel)
  );
  let activeModel = getActiveModel();
  let translations =
    activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties
      ?.translation;
  const debounceOnChange = React.useCallback(
    CommonUtils._debounceFtn(onChange, 900),
    []
  );
  function onChange(searchString: string, listModel: KagamiListModel) {
    const kagamiListFilterModel = getKagamiListFilterModel();
    var listModel: KagamiListModel = getKagamiListModel();
    if (listModel instanceof KagamiGridModel) {
      listModel.searchValue = searchString;
      listModel.pageNumber = 1;
      listModel.isAutoAdjust = true;
    }
    handleClearFilters(kagamiListFilterModel, false);
    searchRecord(
      listModel,
      listModel.presentation.presentationId,
      null,
      searchString
    );
  }

  const handleChange = (searchKey: string) => {
    debounceOnChange(searchKey, props.listSearchbarModel.kagamiListModel);
  };

  return (
    <div
      className={classes.process_searchfilter + " " + "list_search"}
      style={{ position: "relative" }}
    >
      <TextField
        size="small"
        color="secondary"
        variant="outlined"
        className={classes.process_searchfilter_Textfilter}
        placeholder={translateToLocalistaion(translations, "Search")}
        onChange={(event: any) => {
          props.listSearchbarModel.state.setSearchValue(event.target.value);
          if (event.nativeEvent.data !== " ") {
            handleChange(event.target.value);
          }
        }}
        inputRef={props.listSearchbarModel.inputRef}
        value={props.listSearchbarModel.state.searchValue}
      />
      {props.listSearchbarModel.state.searchValue ? (
        <>
          <IconButton
            size="small"
            style={{ position: "absolute", top: "6px", right: "4px" }}
            onClick={() => {
              props.listSearchbarModel.state.setSearchValue("");
              handleChange("");
            }}
          >
            <ClearOutlinedIcon
              sx={{
                color: (theme) => theme.palette.error.main,
                stroke: (theme) => theme.palette.error.main,
                strokeWidth: 1,
              }}
              fontSize="small"
            />
          </IconButton>
        </>
      ) : (
        <div style={{ position: "absolute", top: "10px", right: "10px" }}>
          <SearchOutlinedIcon
            sx={{
              // color: (theme) => theme.palette.secondary.main,
              // stroke: (theme) => theme.palette.secondary.main,
              strokeWidth: 1,
              color: "gray",
              stroke:"gray",
            }}
            fontSize="small"
            style={{ opacity: "0.6", fontSize: "15px" }}
          />
        </div>
      )}
    </div>
  );
};
