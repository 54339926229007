import { State, useState } from "@hookstate/core";
import { KagamiModalModel } from "../../../../../../../../../components/modal/model/KagamiModalModel";
import { uniqueId } from "lodash";

export class DashboardReportsModel {
    id: string;
    name: string;
    content: any;
    modal: KagamiModalModel = new KagamiModalModel();
    reportModel: any;
    drawerOpenDialog: boolean = false;
    lengthOfReport: number = 0;
    isContentLoaded: boolean = true;
    key: string = uniqueId();
    state: any;

    constructor(id: string, name: string, content: any) {
        this.id = id;
        this.name = name;
        this.content = content;
    }
}

export interface DashboardReportsProps {
    dashboardReportsModel: DashboardReportsModel;
}

export const useDashboardReportState = (state: State<DashboardReportsModel>) => {
    const dashboardReportState = useState(state);  // This is how you set up a state hook with Hookstate

    return {
        get drawerOpenDialog(){
            return dashboardReportState.drawerOpenDialog.get();
        },

        setDrawerOpenDialog(open: boolean) {
            dashboardReportState.drawerOpenDialog.set(open);  // Set drawerOpenDialog via `.set()`
        },
    }
}
