import {
  Toolbar,
  IconButton,
  Popper,
  Paper,
  CardMedia,
  Avatar,
  Tooltip,
  Chip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  KagamiHomeHeaderModel,
  KagamiPageHeaderProps,
  useKagamiPageHeaderState,
} from "../model/KagamiPageHeaderModel";
import { KagamiHeaderStyle } from "../style/KagamiHeader";
import { logout } from "../../../../login/controller/KagamiLoginController";
import logo from "../images/logo.png";
import {
  onFavoriteClick,
  onNotificationClick,
  onProfileClick,
} from "../controller/KagamiPageHeaderController";
// import { useKagamiHeaderNotificationModelState } from "../model/KagamiHeaderNotificationModel";
// import { useKagamiHeaderFavoriteState } from "../model/kagamiHeaderFavorites";
import { createState } from "@hookstate/core";
import { buildProfile } from "../builder/profile/controller/kagamiProfileController";
import { useKagamiHeaderProfileState } from "../builder/profile/model/KagamiProfileModel";
import { KagamiStateManager } from "../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../constants/SystemConstants";
import reports_hover from "../../../../../../assets/images/custom-icons/report.svg";
import closeImpersonate from "../../../../../../assets/images/custom-icons/ImpersonateClose.svg";
import user from "../../../../../../assets/images/user.png";
import notification from "../../../../../../assets/images/custom-icons/notify.svg";
import { getReportsData } from "../service/KagamiPageHeaderService";
import React, { useEffect } from "react";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { usePanelState } from "../../navigator/builder/Menu/model/KagamiPanelState";
import { buildInfo } from "../AboutUs/controller/InfoIconController";
import { getDmsViewUrl, getUserUrl } from "../../../../../service/URLService";
import { CommonUtils } from "../../../../../utils/CommonUtils";
import { APIService } from "../../../../../service/ApiService";
import { getHomeModel, showBody } from "../../../controller/KagamiHomeController";
import { keyBoardShortCutsInfo } from "../AboutUs/controller/KagamiKeyBoardShortCutsController";
import { translateToLocalistaion } from "../../body/builder/process/service/PresentationService";
import { closeImpersonateHandler } from "../../../../inpasinate/controller/impersonateController";
import { DashboardType } from "../../body/builder/dashboard/model/KagamiDashboardModel";
import { BodyContent } from "../../../model/KagamiHomeModel";
import { buildNavigationTabs } from "../Tabs/controller/KagamiTabNavigationController";

const useStyles = makeStyles(KagamiHeaderStyle);

export const buildPageHeaderComponent = (kagamiPageHeaderModel: KagamiHomeHeaderModel) => {
  return <KagamiPageHeader kagamiPageHeaderModel={kagamiPageHeaderModel} />;
};

export default function KagamiPageHeader(props: KagamiPageHeaderProps) {
  const classes = useStyles();
  const panelState = usePanelState();
  let homeModel = getHomeModel();
  let translations = props?.kagamiPageHeaderModel?.translations;
  // let headerNotificationState = useKagamiHeaderNotificationModelState(
  //   createState(props.kagamiPageHeaderModel.notificationModel)
  // );
  let pageHeaderState = useKagamiPageHeaderState(
    createState(props.kagamiPageHeaderModel)
  );
  props.kagamiPageHeaderModel.state = pageHeaderState;
  KagamiStateManager.addStateToHolder(
    SystemConstants.HEADER_STATE,
    pageHeaderState
  );
  // let headerFavoritesState = useKagamiHeaderFavoriteState(
  //   createState(props.kagamiPageHeaderModel.favoritesModel)
  // );
  let headerUserProfileState = useKagamiHeaderProfileState(
    createState(props.kagamiPageHeaderModel.profileModel)
  );

  props.kagamiPageHeaderModel.profileModel.state = headerUserProfileState;
  // props.kagamiPageHeaderModel.notificationModel.state = headerNotificationState;
  // props.kagamiPageHeaderModel.favoritesModel.state = headerFavoritesState;
  let isImpersonation = localStorage.getItem('isImpersonation')
  const home_state = KagamiStateManager.getStateToHolder(
    SystemConstants.HOME_STATE
  );
  const profileState = KagamiStateManager.getStateToHolder(
    SystemConstants.PROFILE_STATE
  );
  // const profileState = KagamiStateManager.getStateToHolder(
  //   SystemConstants.PROFILE_STATE
  // );
  // let impersonate = JSON.parse(JSON.stringify(profileState.isImpersonate));
  useEffect(() => {
    getReportsData(props.kagamiPageHeaderModel.favoritesModel);
    // APIService.getData(SystemConstants.GET, getUserUrl(), {}).then(
    //   (response: any) => ( props.kagamiPageHeaderModel.profileModel.userDataForProfile = response.data)
    // ).catch((error:any) =>console.log(`${error.message}`));
  }, []);

  const toggleSideBar = () => {
    panelState.SetSideBarToggled(!panelState.sideBarToggled);
  };

  const logoutApp = () => {
    panelState.SetClearPanelState();
    logout();
  };
  const errorHandler = (event: any) => {
    event?.target?.src ? (event.target.src = user) : '';
  };
  let userImage = CommonUtils.isNotEmpty(props.kagamiPageHeaderModel.profileModel.userDataForProfile?.profile)
    ? getDmsViewUrl(props.kagamiPageHeaderModel.profileModel.userDataForProfile?.profile)
    : user;

  let impersonateUserId = localStorage.getItem('impersonateUserId');

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar_navigation}>
        <div className={classes.icons_on_headers}>
          <div className={classes.toggleIcon}>
            <IconButton onClick={() => toggleSideBar()} title="Collapse Sidebar" size="medium">
              {!panelState.sideBarToggled && <MenuOutlinedIcon sx={{ fontSize: 24 }} />}
              {/* : (
                 <CloseOutlinedIcon className={classes.toggleIcon} sx={{ fontSize: 24 }} />
               ) */}
            </IconButton>
          </div>
          <CardMedia
            className={classes.kagami_image}
            component="img"
            alt="<Kagami>"
            image={home_state.logodata ? home_state.logodata : logo}
            title={translateToLocalistaion(translations, 'Powered by KagamiERP')}
            // onClick={() => showBody()}
          />
          {buildNavigationTabs(homeModel.tabNavigationModel)}
        </div>

        <div className={classes.headerRight}>
        {profileState.isImpersonate === "true" ? (
             <Tooltip
               title={translateToLocalistaion(translations, "Impersonated User")}
               arrow
             >
               <Chip
                label={`${impersonateUserId}`}
                style={{ marginRight: "12px", cursor: "pointer" }}
               />
             </Tooltip>
           ) : (
            "")}
{/* <> */}
      {/* {headerFavoritesState.isOpen && props.kagamiPageHeaderModel.favoritesModel.content?.length ? ( */}
      {homeModel.state.currentBodyContent === BodyContent.DASHBOARD?(  
        <>
          {homeModel.state.currentDashboardScreen === DashboardType.REPORTS ? (
            // pageHeaderState.notificationIconVisible() && (
              <Tooltip
                title={translateToLocalistaion(translations, "Notifications")}
                arrow
              >
                <IconButton onClick={onNotificationClick} size="large">
                  <Avatar
                    sx={{ height: 32, width: 32 }}
                    src={notification}
                    className={classes.userImage}
                  />
                </IconButton>
              </Tooltip>
            // )
          ) : (
            // pageHeaderState.getReportIconVisible() && (
              <Tooltip
                title={translateToLocalistaion(translations, "Reports")}
                arrow
              >
                <IconButton onClick={onFavoriteClick} size="large">
                  <Avatar
                    sx={{ height: 22, width: 22 }}
                    src={reports_hover}
                    className={classes.userImage}
                  />
                </IconButton>
              </Tooltip>
            // )
          )}
        </>
      ) : null}
    {/* </> */}
          {/* about us icon(info icon) */}
          {keyBoardShortCutsInfo(
            props.kagamiPageHeaderModel.keyBoardShortCutsModel,
            props?.kagamiPageHeaderModel?.translations
          )}
          {buildInfo(props.kagamiPageHeaderModel.infoIconModel, props?.kagamiPageHeaderModel?.translations)}
          <Tooltip title={translateToLocalistaion(translations, 'User Profile')} arrow>
            <IconButton onClick={(e: any) => onProfileClick(props.kagamiPageHeaderModel)} size="large">
              {/* <Avatar sx={{ height: 32, width: 32 }} 
            alt={props.kagamiPageHeaderModel.profileModel.userDataForProfile?.firstName} 
            src={CommonUtils.isNotEmpty(props.kagamiPageHeaderModel.profileModel.userDataForProfile?.profile) ? getDmsViewUrl(props.kagamiPageHeaderModel.profileModel.userDataForProfile?.profile) : user} 
            className={ CommonUtils.isNotEmpty(props.kagamiPageHeaderModel.profileModel.userDataForProfile?.profile) ? classes.userImage2: classes.userImage} /> */}
              <CardMedia
                sx={{ height: 32, width: 32 }}
                className={
                  CommonUtils.isNotEmpty(props.kagamiPageHeaderModel.profileModel.userDataForProfile?.profile)
                    ? classes.userImage2
                    : classes.userImage
                }
                image={userImage}
                component="img"
                onError={errorHandler}
              />
            </IconButton>
          </Tooltip>
          <Popper open={headerUserProfileState.isOpen} className={classes.popup_position} placement="bottom-end">
            <Paper className={classes.backgroundPaper}>
              {headerUserProfileState.isOpen ? (
                buildProfile(props.kagamiPageHeaderModel.profileModel, props?.kagamiPageHeaderModel?.translations)
              ) : (
                <></>
              )}
            </Paper>
          </Popper>
          {profileState.isImpersonate === 'true' ? (
            <Tooltip title={translateToLocalistaion(translations, 'End Impersonate')} arrow>
              <IconButton onClick={() => closeImpersonateHandler(profileState)} size="small">
                <Avatar alt="End Impersonate" src={closeImpersonate} className={classes.closeImpersonateIcon} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={translateToLocalistaion(translations, 'Logout')} arrow>
              <IconButton onClick={() => logoutApp()} size="large">
                <LogoutIcon className={classes.logoutIcon} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    </div>
  );
}
