import { blackColor } from './../../../../../../../../../style/KagamiApplicationStyles';

import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiListStyle = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height:'100%',
      maxHeight: `calc(100vh - ${theme.spacing(0.5)})`, 
      '& .kanbanPresentation': {
        display: 'flex',
        gap: '10px',
        width: '100%',
        overflowX: 'auto',
        height: 'calc(100vh - 160px)',
        overflowY:'hidden'
      },
      '& .listPageHeader': {
        // marginLeft: '-10px',
        // marginRight: '-10px',
      },
    },
    badgeRoot: {
      height:'100%',
      width:'100%',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5)
    },
    badge: {
      minWidth: theme.spacing(1.25),
      height: theme.spacing(1.25),
      borderRadius: '100%', 
    },
    embedListStyle: {
      marginLeft: 2,
      backgroundColor:theme.palette.primary.light,
      borderRadius: theme.spacing(3),
      color: theme.palette.primary.main,
      paddingTop: theme.spacing(0.25),
      paddingRight: theme.spacing(0.6),
      paddingBottom: theme.spacing(0.25),
      paddingLeft: theme.spacing(0.6),
      marginBottom: theme.spacing(0.25),
      width:'auto'
    },

    imageWidth: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5)
    },
    subPresentation:{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center'
    },
    backButtonH :{
      display: "flex", 
      justifyContent: "flex-start",
      marginBottom : '8px' ,
      paddingLeft: '4px' 
    },
    cancelTrigger:{
      fontSize: 'small'
        },
    process_headername_and_searchfilter: {
      display: 'flex', 
        justifyContent: 'space-between',
      alignItems: 'center', 
      paddingLeft: theme.spacing(1),
      // marginBottom: theme.spacing(0.5),
      // padding:`${theme.spacing(0.5)} ${theme.spacing(1)}`,
      background: theme.palette.background.defaultBlue,
      // boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 10%)',
       maxHeight: theme.spacing(7.5),
      width: 'auto',
      
      // margin: '-10px -10px 8px -10px',

       
    },

    process_header_Icons: {
      display: 'flex', 
      justifyContent: 'space-between',
      alignItems: 'center', 
      paddingLeft: theme.spacing(1),
      marginLeft:'auto',
      // marginBottom: theme.spacing(0.5),
      // padding:`${theme.spacing(0.5)} ${theme.spacing(1)}`,
      background: theme.palette.background.defaultBlue,
       maxHeight: theme.spacing(7.5),
      width: 'auto',
      
      // margin: '-10px -10px 8px -10px',

       
    },
    process_searchfilter_Textfilter:{
      width:'100%',
      '& .MuiOutlinedInput-root': {
        backgroundColor:"#fff !important",
        // borderRadius: '100%',
        // overflow: 'hidden',
        '& .MuiOutlinedInput-input': {
          position: 'absolute',
          padding: '0 !important',
          marginLeft: '8px',
        },
        '& .MuiInputBase-input':{
           width:'80%',
           whiteSpace: 'nowrap !important',
           overflow: 'hidden !important',
           textOverflow: 'ellipsis !important',
           fontSize:'0.9rem !important',
        },
        '& fieldset': {
          // borderColor: 'red !important',
          transitionDuration: '0.5s',
          marginTop: '6px',
          width:'200px',
          height: '40px',
          position: 'relative',
        },
        '&:hover fieldset': {
          width: '200px'
        },
        '&.Mui-focused fieldset': {
          right: '100%',
          width: '50rem',
          // borderRadius: 'none',
        },
      },
    },
    process_searchfilter:{
      display:'flex',
      // marginTop: theme.spacing(0.5),
      // marginBottom: theme.spacing(0.5),
      marginLeft:'auto',
      marginRight: '4px',
      marginBottom: '15px',
      marginTop:'4px',
      alignItems:'center',
      '& .MuiOutlinedInput-root': {
        backgroundColor:theme.palette.background.default,
        // '& fieldset': {
        //   borderColor: 'red',
        // },
        '&:hover fieldset': {
          //borderColor: 'yellow',
          border: ' 1px solid'
        },
        '&.Mui-focused fieldset': {
          border: '2px solid',
        },
      },
      '& .MuiInputBase-root': {
        height: theme.spacing(3.75),
        fontSize: theme.typography.subtitle1.fontSize,
        marginTop: '3px'
      } 
    },
    processContent: {
      width: '100%',
      height: '100%',
      // backgroundColor: theme.palette.background.default
      background: theme.palette.background.defaultBlue,

    },
    triggers_and_representation: {
     
      flex: 1,
      
    },
    presentation: {
    
      padding: `0 ${theme.spacing(1)}`,
      flex: 1, 
      display:'flex',
      flexDirection: 'column', 
      backgroundColor: theme.palette.background.default,
      maxHeight: 'calc(100vh - 150px)',
    },
  
    presentation_body: {
      display: 'flex', 
      flexDirection: 'column',  
      height: '100%',
      
    },
    filterPresentation:{
      display:'flex',
      maxHeight: theme.spacing(4.375),
      paddingLeft:'5px',
      paddingRight:'5px', 
    },
    cellError:{
      backgroundColor: '#FEE3E4',
      border : '1px solid ',
      borderBottom : '2px solid ',
      borderColor : '#FF8089'
    },
    visibleViewsContainer: {
      marginBottom:'15px', marginTop:'4px' ,marginRight:'5px' 
    }
  });
