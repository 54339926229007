import * as React from 'react';
import { createState, useState } from '@hookstate/core';
import {
  LedgerAccountReportModel,
  LedgerAccountReportModelProps,
  useLedgerAccountReportModelState
} from '../model/LedgerAccountReportModel';
import { makeReport, makeLedgerData, getData, makeFooterRowData, handledColumnsBasedOnProjects } from '../service/LedgerAccountReportService';
import { buildLedgerDetailsModal } from '../../modal/controller/ledgerDetailsModalController';
import { handleOnLedgerClick } from '../controller/LedgerAccountReportController';
import { StaticReportActions } from '../../../../../../staticReportFilters/ui/StaticReportActions';
import { StaticReportGrid } from '../../../../../../staticReportFilters/ui/StaticReportGrid';


export const BuildLedgerAccountReportView = (ledgerAccountReportModel: LedgerAccountReportModel) => {
  return (
    <LedgerAccountReportView key="{'LedgerAccountReportView'}" ledgerAccountReportModel={ledgerAccountReportModel} />
  );
};


const LedgerAccountReportView = (props: LedgerAccountReportModelProps) => {

  let ledgerAccountReportModel = props.ledgerAccountReportModel;
  const onLedgerDetails = (row: any) => {
    let ledgerInputs: any = {
      invoiceNo: row.data.docno,
      transType: row.data.transtype,
      reportType: inputReportConfig.reportConfigSummary.type,
      currency: undefined,
      translation: props?.ledgerAccountReportModel?.languageTranslation || {}
    };

    handleOnLedgerClick(ledgerInputs, row);
  }
  let runReportConfig: any = useState({
    loadReport: false,
    loadFilterData: true,
    showLoader: false,
    reportInfo: {}
  });
  let ledgerAccountState = useLedgerAccountReportModelState(createState(ledgerAccountReportModel));
  ledgerAccountReportModel.state = ledgerAccountState;
  let staticFilterData: any;
  if (ledgerAccountReportModel?.reportData?.staticFilterData) {
    staticFilterData = ledgerAccountReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo: any = {}
  if (ledgerAccountReportModel?.reportData?.gridReportInfo) {
    gridReportInfo = ledgerAccountReportModel.reportData['gridReportInfo'];
  }

  let inputReportConfig = (props.ledgerAccountReportModel.reportConfig);

  React.useEffect(() => {
    runReport();
  }, [])

  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo = makeReport(ledgerAccountReportModel, inputReportConfig);
    let ledgerData = await getData(ledgerAccountReportModel, inputReportConfig);
    let rowData = await makeLedgerData(ledgerAccountReportModel, ledgerData);
    let footerRowData = await makeFooterRowData(ledgerAccountReportModel, ledgerData);
    handledColumnsBasedOnProjects(reportInfo, ledgerData);
    reportInfo['pinnedBottomRowData'] = footerRowData;
    reportInfo['rowData'] = rowData;
    reportInfo['dbLedgerData'] = ledgerData;
    const obj = Object.assign({}, reportInfo);
    props.ledgerAccountReportModel.reportData['gridReportInfo'] = obj;
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }
  let config = { reportModel: ledgerAccountReportModel, inputReportConfig, gridReportInfo, runReportConfig ,onLedgerDetails};

  return (
    <div className="Report-MuiFormControl-root" style={{ width: '100%', background: '#fff', padding: '10px' }}>
      {buildLedgerDetailsModal(props.ledgerAccountReportModel.modelPopup)}
      <StaticReportActions config={config} ></StaticReportActions>
      <StaticReportGrid config={config}></StaticReportGrid>
    </div>
  );
};
