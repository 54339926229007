import Barcode from 'react-barcode';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { KagamiBarCodeModel, KagamiBarCodeModelProps, useKagamiBarCodeModelState } from '../model/BarCodeModel';
import { getFormModel } from '../../../form/controller/KagamiFormController';
import { FormControl, FormLabel, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiTextBoxStyle } from '../../textbox/style/KagamiTextBoxStyle';
import React from 'react';
import { validateBarCodeBox } from '../controller/BarCodeController';

const useStyles = makeStyles(KagamiTextBoxStyle);

const useTextAreaStyle = (isTextArea: boolean) =>
  makeStyles((theme: any) => ({
    root: {}
  }));

export function KagamiBarCode(kagamiBarCodeModel: KagamiBarCodeModel) {
  return <BarCodeInForm key={kagamiBarCodeModel?.controlIdentifier} kagamiBarCodeModel={kagamiBarCodeModel} />;
}

export function BarCodeInForm(props: KagamiBarCodeModelProps) {
  const classes = useStyles();
  let isTextArea = props?.kagamiBarCodeModel?.controlType === 'barCode';
  const classes1 = useTextAreaStyle(isTextArea)();
  let fieldState: any = useKagamiBarCodeModelState(props?.kagamiBarCodeModel?.createState);
  const uiSettingsClassName = props?.kagamiBarCodeModel?.getControlWidth(props?.kagamiBarCodeModel?.uiSettings);
  const fontProperties = props?.kagamiBarCodeModel?.getFontStyling(props?.kagamiBarCodeModel?.uiSettings);
  props.kagamiBarCodeModel.state = fieldState;
  getFormModel().formControlStates[props?.kagamiBarCodeModel?.controlIdentifier] = fieldState;

  const debounceCallForBarCodeOnSearch = React.useCallback(
    CommonUtils._debounceFtn(onSearchCallForBarCodeSearchFeild, 1000),
    []
  );

  function onSearchCallForBarCodeSearchFeild(controlvalueData: string) {
    validateBarCodeBox(props?.kagamiBarCodeModel, controlvalueData);
  }

  const handleBarCodeField = (e: any) => {
    let trimmedData = e.target.value.trimStart();
    fieldState?.setControlValue(trimmedData);
    debounceCallForBarCodeOnSearch(fieldState?.controlValue);
  };

  if (fieldState?.isVisible === false) {
    return null;
  } 
  else if (fieldState?.isReadOnly === false) {
    return (
      <>
        <div className={`${uiSettingsClassName} ${classes.root} ${classes1.root}`}>
          <FormControl fullWidth>
            <FormLabel
              component="span"
              required={fieldState?.isMandatory}
              style={{
                color: fontProperties?.labelFontColor,
                fontWeight: fontProperties?.labelFontBold,
                fontStyle: fontProperties?.labelFontItalic,
                textDecoration: fontProperties?.labelFontUnderline
              }}
            >
              {props?.kagamiBarCodeModel?.controlDisplayName}
            </FormLabel>
            <TextField
              sx={{
                '& .MuiInputLabel-root ': {
                  color: fontProperties?.labelFontColor,
                  fontWeight: fontProperties?.labelFontBold,
                  fontStyle: fontProperties?.labelFontItalic,
                  textDecoration: fontProperties?.labelFontUnderline
                },
                '& .MuiInputLabel-root.Mui-focused ': {
                  color: fontProperties?.labelFontColor,
                  fontWeight: fontProperties?.labelFontBold,
                  fontStyle: fontProperties?.labelFontItalic,
                  textDecoration: fontProperties?.labelFontUnderline
                },
                '& .MuiOutlinedInput-root': {
                  color: fontProperties?.controlFontColor + ' ' + '!important',
                  fontWeight: fontProperties?.controlFontBold,
                  fontStyle: fontProperties?.controlFontItalic,
                  textDecoration: fontProperties?.controlFontUnderline
                }
              }}
              fullWidth
              size="small"
              autoSave="off"
              disabled={fieldState?.isReadOnly}
              name={props?.kagamiBarCodeModel?.controlIdentifier}
              type={props?.kagamiBarCodeModel?.controlType}
              error={fieldState?.errorMessage ? true : false}
              required={fieldState?.isMandatory}
              id={props?.kagamiBarCodeModel?.controlIdentifier}
              value={fieldState?.controlValue}
              helperText={fieldState?.errorMessage}
              variant="outlined"
              onChange={(e) => {
                handleBarCodeField(e);
              }}
              onBlur={(e: any) => {
                let value = e.target.value;
                if (props?.kagamiBarCodeModel?.controlValue?.toString() !== value) {
                  fieldState?.setControlValue(value);
                }
              }}
              onFocus={(e: any) => {
                let value: string = e.target.value;
                if (value === '0') {
                  fieldState?.setControlValue('');
                }
              }}
            />
          </FormControl>
          <p style={{ marginTop: '0px', marginLeft: '-6px' }}>{barCode(fieldState)}</p>
        </div>
      </>
    );
  } 
  else if (fieldState?.isReadOnly === true) {
    return (
      <>
        <div className={`${uiSettingsClassName} ${classes.root} ${classes1.root}`}>
          <div className={classes.readOnlyField_cont}>
            <FormLabel
              component="span"
              required={fieldState?.isMandatory}
              className={classes.controlLabel}
              style={{ color: fontProperties?.labelFontColor }}
            >
              {props?.kagamiBarCodeModel?.controlDisplayName}
            </FormLabel>
            {fieldState?.controlValue === null ||
            fieldState?.controlValue === undefined ||
            fieldState?.controlValue === '' ? (
              <div>
                <p className={classes.readOnlyField}>----</p>
              </div>
            ) : (
              <div>
                <p style={{ marginTop: '-6px', marginLeft: '-10px' }}>{barCode(fieldState)}</p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
  return null;
}

export const barCode = (props: any) => {
  if (CommonUtils.isNotEmpty(props) && CommonUtils.isNotEmpty(props?.controlValue)) {
    return <Barcode format="CODE128" height={7} width={0.5} fontSize={10} marginLeft={0} value={props?.controlValue} />;
  } else {
    return null;
  }
};
