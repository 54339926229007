import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../../../../../../../../../state/KagamiStateManager';
import { KagamiGridModel } from '../../../model/KagamiGridModel';

export class KagamiGridPaginationModel {
  isForwardEnable: boolean;
  isBackwardEnable: boolean;
  totalRecords: any;
  startRecord: any;
  endRecord: any;
  state: any;
  activePageIndex: any;
  mainEntityId: string;
  processName: string;
  entityPresentation: any;
  pageSize: number;

  constructor(
    isForwardEnable: boolean,
    isBackwardEnable: boolean,
    totalRecords: any,
    startRecord: any,
    endRecord: any,
    mainEntityId: string,
    processName: string,
    entityPresentation: any
  ) {
    this.isForwardEnable = isForwardEnable;
    this.isBackwardEnable = isBackwardEnable;
    this.totalRecords = totalRecords;
    this.startRecord = startRecord;
    this.endRecord = endRecord;
    this.state = null;
    this.activePageIndex = 1;
    this.mainEntityId = mainEntityId;
    this.processName = processName;
    this.entityPresentation = entityPresentation;
    this.pageSize = 0;
  }
}

export interface KagamiGridPaginationProps {
  paginationModel: KagamiGridPaginationModel;
  kagamiGridModel: KagamiGridModel;
}

export const wrapKagamiGridPaginationState = (state: State<KagamiGridPaginationModel>) => {
  let paginationState = state;

  return {
    getForwardEnabled() {
      return paginationState.isForwardEnable.get();
    },
    setForwardEnabled(value: boolean) {
      paginationState.isForwardEnable.set(value);
    },
    getActivePageIndex() {
      return paginationState.activePageIndex.get();
    },
    setActivePageIndex(value: number) {
      paginationState.activePageIndex.set(value);
    },
    getStartRecord() {
      return paginationState.startRecord.get();
    },
    setStartRecord(value: number) {
      paginationState.startRecord.set(value);
    },
    getEndRecord() {
      return paginationState.endRecord.get();
    },
    setEndRecord(value: number) {
      paginationState.endRecord.set(value);
    },
    getBackwardEnabled() {
      return paginationState.isBackwardEnable.get();
    },
    setBackwardEnabled(value: boolean) {
      paginationState.isBackwardEnable.set(value);
    }
  };
};

export const useKagamiGridPaginationState = (
  state: State<KagamiGridPaginationModel>,
  kagamiPaginationIdentifier: string
) => {
  const kagamiGridPaginationState = wrapKagamiGridPaginationState(useState(state));
  KagamiStateManager.addStateToHolder(kagamiPaginationIdentifier, kagamiGridPaginationState);
  return kagamiGridPaginationState;
};
