import { State, useState } from "@hookstate/core";

export class StaticReportFiltersModel {
    headerDeatils: any;
    nestedFinanceRecord: any;
    otherDeatils: any;
    isDataLoaded: boolean;
    state: any;
    ledgerInputs: any;
    reportData: any;
    reportConfig: any;
    constructor() {
        this.headerDeatils = {};
        this.nestedFinanceRecord = [];
        this.otherDeatils = {};
        this.isDataLoaded = false;
        this.ledgerInputs = {}
    }
}

export interface StaticReportFiltersModelProps {
    staticReportFiltersModel: StaticReportFiltersModel
}

const wrapStaticReportFiltersModel = (state: State<StaticReportFiltersModel>) => {
    const ExcelState = state;
    return {
        get isDataLoaded() {
            return ExcelState.isDataLoaded.get();
        },

        setDataLoaded(isDataLoaded: boolean) {
            ExcelState.isDataLoaded.set(isDataLoaded)
        }
    }
}

export const useStaticReportFiltersModelState = (state: State<StaticReportFiltersModel>) => {
    return wrapStaticReportFiltersModel(useState(state));
}