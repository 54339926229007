import makeStyles from '@mui/styles/makeStyles';
import { getActiveModel } from '../../process/controller/KagamiProcessController';
import { KagamiModalModel } from '../../../../../../../../components/modal/model/KagamiModalModel';
import { KagamiDashboardModel, KagamiMenuComponentProps } from '../model/KagamiDashboardModel';
import { useEffect, useState  , useRef} from 'react';
import { KagamiPopoverModel } from '../../../../../../../../components/popover/model/KagamiPopoverModel';
import { executeProcess } from '../../../../navigator/builder/Menu/controller/KagamiMenuController';
import { MenuList } from '@mui/material';
import { MenuItem } from 'react-pro-sidebar';
import { translateToLocalistaion } from '../../process/service/PresentationService';
import { getNotificationDataById } from '../service/DashboardService';
import { KagamiDashboardStyle } from '../style/KagamiDashboard';
import { enableTrigger } from '../../process/builder/Triggers/service/KagamiTriggersService';
import CircularProgress from '@mui/material/CircularProgress';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import {  Typography } from '@mui/material';  

const useStyles = makeStyles(KagamiDashboardStyle);

export function buildMenuComponent(triggerModels:any, notification:any, popupMenuModel:any, notificationId:any, userId:any, notifcationData:any, setNotifcationData:any) {
  return <KagamiMenuComponent
            key={'menu-component'}
            triggerModels={triggerModels}
            notification={notification}
            setNotificationData={setNotifcationData}
            popupMenuModel={popupMenuModel}
            notificationId={notificationId}
            userId={userId}
            notifcationData={notifcationData} />;
};

export const KagamiMenuComponent = (props: KagamiMenuComponentProps) => {
    const classes = useStyles();
    const [enabledTriggers, setEnabledTriggers] = useState<any[]>([]);
    const [notifactionDataRecord, setNotifactionDataRecord] = useState<any>(props.notifcationData);
    const [dataLoaded, setDataLoaded] = useState(false)

    let activeModel = getActiveModel();
    let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
    let statusModelPopup: KagamiModalModel = new KagamiModalModel();
 
   useEffect(() => {
    if (CommonUtils.isEmpty(notifactionDataRecord)) {
      getNotificationDataById(props.notificationId).then((response: any) => {
          let transactionEntity = response?.transactionEntity;
          props.setNotificationData(transactionEntity);
          setNotifactionDataRecord(transactionEntity);
          enableTriggers(transactionEntity);
      });
    } else {
      enableTriggers(notifactionDataRecord);
    }
  }, []);

  const enableTriggers = (data:any[])=>{
    let activeTriggers:any[] = [];
    props.triggerModels.map((triggerModel:any) => {
     triggerModel.detailedObject = data ;
     if (triggerModel.detailedObject) {  
       triggerModel.enableTrigger = enableTrigger(
         triggerModel,
         triggerModel.detailedObject instanceof Array ? triggerModel.detailedObject : [triggerModel.detailedObject],
         triggerModel.enableTrigger,
       );
     };
     triggerModel.enableTrigger && activeTriggers.push(triggerModel);
    })
    setEnabledTriggers(activeTriggers);
    setDataLoaded(true);
  }

  const handleClick = (
    processName:string,
    popoverModel:KagamiPopoverModel,
    action:any
  ) => {
    if (action.confirmation) {
      statusModelPopup.state.setOpen(true);
      statusModelPopup.content = action.confirmationMsg;
      statusModelPopup.title = action.triggerName;
      statusModelPopup.handleOnOk = _handleOnOkAction;  
    } else {
      _handleOnOkAction();
    }
    popoverModel.state.setPopoverTarget(null);

    function _handleOnOkAction() {
      executeProcess(processName, notifactionDataRecord);
    }
  };

 return (
  <>
  {dataLoaded? 
  <div style={{minWidth: '105px', border:'1px solid #d4d4d4'}} className={classes.notificationMenu}>
       <MenuList style={{ padding: '0', textTransform:'capitalize'}}>
         {enabledTriggers.length > 0 ? enabledTriggers.map((item: any) => {
           return (
             <>
            <MenuItem 
                 style={{
                  fontSize:'12px', 
                  color: 'theme.palette.secondary.main',
                  borderBottom:'1px solid #ebebeb',
                 }}
                 className={classes.PopoverMenuItem}
                 onClick={() =>
                   handleClick(
                     item.processName,
                     props.popupMenuModel,
                     item
                   )
                 }
               >
                 {translateToLocalistaion(translations, item.triggerName)}
               </MenuItem>
             </>
           );
         }) : <>
               <div style={{backgroundColor:"rgb(248 248 248)"}}> 
               <Typography  fontSize="14px" fontFamily="Lexend deca, sans-serif" padding="4px 15px" fontWeight="500">
               No action
                </Typography> 
               </div>
               </>
         }
      </MenuList>
    </div>
    :<>
    <div style={{}}>
      <span style={{display:"block" , padding:"0px 31px"}}>
      <CircularProgress sx={{fontSize:"12px"}}/>
      </span>
    </div> 
  </>} 
  </>
  );

};