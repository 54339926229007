import { createState } from "@hookstate/core";
import { EmbeddedDataRecordModelProps, EmbeddedRowCountModel, useEmbeddedDataRecordModelState } from "./model/EmbeddedRowCountModel"
import makeStyles from '@mui/styles/makeStyles';
import { KagamiEmbeddedTabsStyle } from "../../embedded-tabs-presentation/styles/KagamiEmbeddedTabsStyle";


const useStyles = makeStyles(KagamiEmbeddedTabsStyle);
export function buildEmbeddedRowCount(embeddedRowCountModel: EmbeddedRowCountModel) {
  return <EmbeddedRecordCount key={embeddedRowCountModel.formEntityId + Math.random().toString()} embeddedDataRecordModel={embeddedRowCountModel}/>
}


export const EmbeddedRecordCount = (props: EmbeddedDataRecordModelProps) => {
  const classes = useStyles();

  props.embeddedDataRecordModel.state=useEmbeddedDataRecordModelState(createState(props.embeddedDataRecordModel), props.embeddedDataRecordModel.formEntityId);
  let dataRecordsLength = props.embeddedDataRecordModel.state.getDataCount()

  const counter = dataRecordsLength?.toString();  

  const adjustFontSize = () => {
    const numDigits = counter.length; 
    if(numDigits > 3 ){
      return '10px';
    } else if (numDigits === 3) {
      return '12px';
    } else if (numDigits === 2) {
      return '14px';
    } else {
      return '16px';
    }
  };
  if(dataRecordsLength > 0) {
    return (
    <>
     {/* <span  style={{ color:"#006e7f", border:'1px solid',padding:'1px',borderRadius:'50px',width:'24px', height:'24px', margin:'0px',marginLeft: '5px', lineHeight:'14px',position:'relative',top:'-8px'}}>
        <p style={{ color:"#006e7f",textAlign:'center',width:'16px', height:'16px', margin:'0px',marginLeft: '5px', lineHeight:'14px',fontWeight: '500',fontSize:'12px',position:'relative',top:'4px',left :'-3px'}}>
          {dataRecordsLength}            
        </p>     
      </span> */}
      <div className={classes.rowCounter}
        style={{
      top:"-5px", 
       right: props.embeddedDataRecordModel.presentationType ==='accordion' ? '35px' :'0px',
       border: props.embeddedDataRecordModel.presentationType ==='accordion' ? '1px solid #006e7f' :'',
       color: props.embeddedDataRecordModel.presentationType ==='accordion' ? '#006e7f' :'',
       marginTop: props.embeddedDataRecordModel.presentationType ==='accordion' ? '-20px' :'',
       borderRadius:'50%',
       }}>
       {counter} 
         </div>
    </>
    )
 }else {
    return <></>
  }   
}