import React from 'react';
import { KagamiDashboardCardModel, KagamiDashboardCardModelProps } from '../model/KagamiDashboardCardModel';
import { makeStyles } from '@mui/styles';
import { KagamiCardStyle } from '../style/KagamiDashboardCardStyle';

export const buildDashboardCardComponent = (cardModel: KagamiDashboardCardModel) => {
  return <KagamiDashboardCard cardModel={cardModel} />;
};

const useStyles = makeStyles(KagamiCardStyle);

const KagamiDashboardCard = (props: KagamiDashboardCardModelProps) => {
  let classes = useStyles();
  let cardModel = props.cardModel;
  const isMainForm = cardModel.transactionType === "mainForm";

  return (
    <div className={classes.container}>
      <div className="box-title drag-handle">
        {cardModel.title}
      </div>
      <div className="box-content" style={ isMainForm ? { minHeight: "fit-content"}: {}}>
        {cardModel.content}
      </div>
    </div>
  );
};
