import { CardMedia, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { KagamiProfileModel } from "../model/KagamiProfileModel";
import { KagamiProfilePopoverStyles } from "../styles/kagamiProfile";
import Avatar from "@mui/material/Avatar";
import usermanagement from "../../../../../../../../assets/images/custom-icons/user_management.png";
import updatePaassword from "../../../../../../../../assets/images/custom-icons/update_pwd.png";
import impersonate from "../../../../../../../../assets/images/custom-icons/Inpasinate.svg";
import errorlog from "../../../../../../../../assets/images/custom-icons/error_log.png";
import announcement from "../../../../../../../../assets/images/custom-icons/announcement.png";
import role_management from "../../../../../../../../assets/images/custom-icons/role_management.png";
import user_settings from "../../../../../../../../assets/images/custom-icons/user_settings.png";
import application_settings from "../../../../../../../../assets/images/custom-icons/application_settings.png";
import supportlink from "../../../../../../../../assets/images/custom-icons/support_link.png";
import organization from "../../../../../../../../assets/images/custom-icons/hierarchy_view.png";
import user from "../../../../../../../../assets/images/user.png";
import { executeProcess } from "../../../../navigator/builder/Menu/controller/KagamiMenuController";
import EditIcon from "@mui/icons-material/Edit";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import { KagamiModalModel } from "../../../../../../../../components/modal/model/KagamiModalModel";
import { buildModal } from "../../../../../../../../components/modal/controller/KagamiModalController";
import { KagamiUpdatePasswordModel } from "../../../../body/builder/process/builder/updatePassword/model/updatePasswordModel";
import { buildUpdatePasswordBuilder } from "../../../../body/builder/process/builder/updatePassword/controller/UpdatePasswordController";
import { CommonUtils } from "../../../../../../../utils/CommonUtils";
import { getDmsViewUrl } from "../../../../../../../service/URLService";
import { handleUpdateButton } from "../../../../body/builder/process/builder/updatePassword/ui/UpdatePassword";
import { isEnableTabs, translateToLocalistaion } from "../../../../body/builder/process/service/PresentationService";
import { KagamiScheduler } from "./KagamiScheduler";
import { KagamiStateManager } from "../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../constants/SystemConstants";
import { buildImpasinateBuilder } from "../../../../../../inpasinate/controller/impersonateController";
import { KagamiImpersonateModel } from "../../../../../../inpasinate/model/impersonateModel";
import { buildSlowApiLog } from "../../../SlowLogApi/controller/KagamiSlowApiLogController";
import { tabExistAndContentFromState } from "../../../Tabs/ui/KagamiTabNavigation";
import { appContext } from "../../../../body/builder/process/service/PolicyExecutionService";

const useStyles = makeStyles(KagamiProfilePopoverStyles);

const editProfileOnclick = (kagamiProfileModel: KagamiProfileModel, userRolesData: any) => {
  let filterUserRoleData = userRolesData.filter((userrole: any) => userrole.panelType === 'userRole');
  let getProfileProcessName = filterUserRoleData[0]['menuPanel']['menuItems'].filter(
    (profile: any) => profile.name === 'Profile'
  );
  // executeProcess(getProfileProcessName[0]?.event.process);
  if(isEnableTabs(appContext)) {
    handleTab(getProfileProcessName[0]?.event.process, getProfileProcessName[0]?.name);
  } else {
  executeProcess(getProfileProcessName[0]?.event.process);
  }
  kagamiProfileModel.state.setOpen(false);
};

const addTab = (process: any) => {
  let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
  let tabs = JSON.parse(JSON.stringify(tabNavigationState?.getTabs));
  tabNavigationState?.setTabs(tabs);
  if (process.name === 'Reports') {
    tabNavigationState?.setActiveTab(process?.name);
  } else {
    tabNavigationState?.setActiveTab(process?.name);
  }
};

const handleTab = (processName: string, name: string) => {
  let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
  let tabs = JSON.parse(JSON.stringify(tabNavigationState.getTabs));
  let isTabAlreadyExist = tabs?.findIndex((item: any) => item?.processName === processName);
  if (isTabAlreadyExist !== -1) {
    tabExistAndContentFromState(processName, name);
  } else {
    tabs?.push({ processName, name });
    tabNavigationState.setTabs(tabs);
    if (tabs?.length <= 10) {
      tabNavigationState.setActiveTab(processName);
      executeProcess(processName);
    }
  }
};

export const buildProfileMenu = (kagamiProfileModel: KagamiProfileModel, translations?: any) => {
  return <ProfileMenuWidget kagamiProfileModel={kagamiProfileModel} translations={translations} />;
};

export const ProfileMenuWidget = (props: any) => {
  const { kagamiProfileModel } = props;
  const classes = useStyles();
  let scheduler: any = {
    name: 'Schedulers',
    build: true,
    favourite: false,
    event: { process: 'random' },
    type: 'process'
  };
  let slowApiLog: any = {
    name: "Slow Api(S)",
    build: true,
    favourite: false,
    event: { process: "random" },
    type: "process",
  };
  let updatePasswordModel: KagamiUpdatePasswordModel = new KagamiUpdatePasswordModel();
  let popupModel: KagamiModalModel = updatePasswordModel.kagamiModalModel;
  let impersonateModel: KagamiImpersonateModel = new KagamiImpersonateModel();
  let impersonateModelPopupModel: KagamiModalModel = impersonateModel.kagamiModalModel;
  let translations = props?.translations;
  const OpenDialogForUpdatePassWord = () => {
    popupModel.title = translateToLocalistaion(translations, 'Update Password');
    popupModel.content = buildUpdatePasswordBuilder(updatePasswordModel, popupModel);
    popupModel.state.setOpen(true);
    popupModel.modalWidth = 'sm';
    popupModel.handleOnOk = handleUpdateButton;
  };

  const OpenDialogForImpasinate = () => {
    impersonateModelPopupModel.title = translateToLocalistaion(translations, 'Impersonate');
    impersonateModelPopupModel.content = buildImpasinateBuilder(impersonateModel, impersonateModelPopupModel);
    impersonateModelPopupModel.state.setOpen(true);
    impersonateModelPopupModel.modalWidth = 'sm';
    impersonateModelPopupModel.state.setIsImpersonation(true);
    impersonateModelPopupModel.disabledButton = true;
  };

  let profileMenu: any;
  kagamiProfileModel?.content?.container?.filter((ele: any) => {
    if (ele?.panelType === 'userRole') {
      profileMenu = ele?.menuPanel?.menuItems;
    }
  });
  let userImage = CommonUtils.isNotEmpty(kagamiProfileModel.userDataForProfile?.profile)
    ? getDmsViewUrl(kagamiProfileModel.userDataForProfile?.profile)
    : user;
  const errorHandler = (event: any) => {
    event?.target?.src ? (event.target.src = user) : '';
  };

  return (
    <>
      <div className={classes.userInfo_banner}>
        <div className={classes.profile_pic}>
          <CardMedia
            sx={{ height: 45, width: 45, borderRadius: '50%' }}
            image={userImage}
            component="img"
            onError={errorHandler}
          />
          <div className={classes.editIcon}>
            <EditIcon
              onClick={() => editProfileOnclick(kagamiProfileModel, kagamiProfileModel?.content?.container)}
              fontSize="small"
            />
          </div>
        </div>
        <div>
          <Typography variant="caption" display="block">
            {kagamiProfileModel.userDataForProfile?.firstName === 'null'
              ? ''
              : kagamiProfileModel.userDataForProfile?.firstName}
            (
            {kagamiProfileModel.userDataForProfile?.userId === 'null'
              ? ''
              : kagamiProfileModel.userDataForProfile?.userId}
            )
          </Typography>
          <Typography variant="caption" display="block">
            {kagamiProfileModel.userDataForProfile?.userEmail === 'null'
              ? ''
              : kagamiProfileModel.userDataForProfile?.userEmail}
          </Typography>
          <Typography variant="caption" display="block">
            {kagamiProfileModel.userDataForProfile?.phone === 'null'
              ? ''
              : kagamiProfileModel.userDataForProfile?.phone}
          </Typography>
        </div>
      </div>
      <Divider />
      <MenuList style={{ backgroundColor: "#F6FCFA" }}>
        {profileMenu?.map((process: any, index: any) =>
          BuildMenuItems(process, index, kagamiProfileModel)
        )}
        <SchedulerComponent
          key={"scheduler"}
          scheduler={scheduler}
          kagamiProfileModel={kagamiProfileModel}
        />
        <SlowApiLogComponent
          key={"slowApiLog"}
          slowApiLog={slowApiLog}
          kagamiProfileModel={kagamiProfileModel}
        />
        <MenuItem
          onClick={OpenDialogForUpdatePassWord}
          className={classes.profileMenuItems}
        >
          <Avatar
            alt="Update Password"
            src={updatePaassword}
            className={classes.icon}
          />
          <ListItemText
            primary={
              <Typography variant="caption" display="block">
                {translateToLocalistaion(translations, 'Update Password')}
              </Typography>
            }
          ></ListItemText>
        </MenuItem>
        {kagamiProfileModel.userDataForProfile?.userId === "admin" ? (
          <MenuItem
            onClick={OpenDialogForImpasinate}
            className={classes.profileMenuItems}
          >
            <Avatar
              alt="impersonate"
              src={impersonate}
              className={classes.icon}
            />
            <ListItemText
              primary={
                <Typography variant="caption" display="block">
                  {translateToLocalistaion(translations, "Impersonate")}
                </Typography>
              }
            ></ListItemText>
          </MenuItem>
        ) : (
          ""
        )}
      </MenuList>
      {buildModal(popupModel)}
      {buildModal(impersonateModelPopupModel)}
    </>
  );
};

export const SchedulerComponent = (props: any) => {
  return props.kagamiProfileModel?.userDataForProfile?.userId === 'admin'
    ? BuildMenuItems(props.scheduler, 15, props.kagamiProfileModel)
    : null;
};

export const SlowApiLogComponent = (props: any) => {
  return props.kagamiProfileModel?.userDataForProfile?.userId
    ? BuildMenuItems(props?.slowApiLog, 15, props?.kagamiProfileModel)
    : null;
};

export const BuildMenuItemContent = (props: any) => {
  const classes = useStyles();
  const handleProcessOnclick = () => {
    if (props.props.name === "Schedulers") {
      let content = <KagamiScheduler />;
      KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(content);
    } else if (props?.props?.name === "Slow Api(S)") {
      let content: JSX.Element | null = buildSlowApiLog(props?.KagamiProfileModel?.kagamiSlowApiLogModel);
      KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(content);
    } else {
      executeProcess(props.props.event.process);
    }
    props.KagamiProfileModel.state.setOpen(false);
  };

  let translations = props?.KagamiProfileModel?.content?.otherProperties?.translation;

  const Processnameconditioncase = () => {
    switch (props.props.name) {
      case 'Profile':
        return <Avatar alt="User Management" src={usermanagement} className={classes.icon} />;
      case 'User Management':
        return <Avatar alt="User Management" src={usermanagement} className={classes.icon} />;
      case 'Support Link':
        return <Avatar alt="Support Link" src={supportlink} className={classes.icon} />;
      case 'ErrorLog':
        return <Avatar alt="errorlog" src={errorlog} className={classes.icon} />;
      case 'Announcement':
        return <Avatar alt="announcement" src={announcement} className={classes.icon} />;
      case 'fetchAnnouncement':
        return <Avatar alt="announcement" src={announcement} className={classes.icon} />;
      case 'Role Management':
        return <Avatar alt="role_management" src={role_management} className={classes.icon} />;
      case 'User Setting':
        return <Avatar alt="user_settings" src={user_settings} className={classes.icon} />;
      case 'Application Setting':
        return <Avatar alt="Application Setting" src={application_settings} className={classes.icon} />;
      case 'View_Settings':
        return <Avatar alt="application_settings" src={application_settings} className={classes.icon} />;
      case 'Organization':
        return <Avatar alt="organization" src={organization} className={classes.icon} />;
      default:
        return <Avatar alt="usermanagement" src={usermanagement} className={classes.icon} />;
    }
  };
  return (
    <MenuItem onClick={handleProcessOnclick} className={classes.profileMenuItems}>
      {props.props.name != 'Profile' && (
        <>
          <Processnameconditioncase />
          <ListItemText
            primary={
              <Typography variant="caption" display="block">
                {translateToLocalistaion(translations, props.props.name)}
              </Typography>
            }
          ></ListItemText>
        </>
      )}
    </MenuItem>
  );
};

export const BuildMenuItems: any = (
  item: any,
  index: any,
  kagamiProfileModel: KagamiProfileModel
) => {
  return (
    <>
      <BuildMenuItemContent key={index} props={item} KagamiProfileModel={kagamiProfileModel} />
    </>
  );
};

export const NoImageHandler = (classes: any, fieldState: any) => {
  return (
    <div onBlur={() => (fieldState.errorMessage = 'hello')}>
      <img src={process.env.PUBLIC_URL + 'images/no-image-found.png'} width="100" />
    </div>
  );
};
