import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import uniqueId from 'lodash/uniqueId';
import TranslateIcon from '@mui/icons-material/Translate';
import { ReportConstant } from '../../../../view/ReportConstant/ReportConstant';
import { handleReportLinkCLick, reloadReportOnLanguageChange } from '../../../controller/ReportsLandingPageController';
import { ReportsPageStyle } from '../style/ReportsPageStyle';
import { convert_to_dayjs_date_format, getValueInDateTimeFormatAsAppSetting, isStaticReport } from '../../../../view/report/service/ReportUtilService';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import React from 'react';
import { FormControl, FormControlLabel, Popover, Radio, RadioGroup } from '@mui/material';
import { getReportModel } from '../../../../view/report/controller/KagamiReportController';
import { StaticReportFilterLink } from '../../../../staticReportFilters/ui/StaticReportFilterLink';

const useStyles = makeStyles(ReportsPageStyle);

export const buildReportsPageComponent = (component: any, hideHeader: boolean,translations?:any) => {
  return <ReportsPage key={uniqueId()} component={component} hideHeader={hideHeader} translations={translations}/>;
};

const ReportsPage = (props: any) => {
  const classes = useStyles();
  let content: any;
  let component = props.component;
  let _appsetting = CommonUtils.getAppSetting();
  let dateFormat: string | undefined = _appsetting?.dateFormat;
  const dateFormatAsOnDate:string = convert_to_dayjs_date_format(dateFormat);
  let reportModel = getReportModel();
  let sysLanguages = CommonUtils.getSystemLanguages();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  function onChangeLanguageHandler(e: any, value: any) {
    reloadReportOnLanguageChange(value)
  }  

  if (Array.isArray(component)) {
    content = component.length > 0 ? Object.values(component[0].props)[0] : null;
  } else {
    content = Object.values(component.props)[0];
  }
  let translation :any =  content?.languageTranslation || {};
  let isStaticRepo = isStaticReport(content.reportConfig?.reportConfigSummary.type)

  if (content !== null) {
    if (content['filters'] !== undefined) {
      return component;
    } else {
      return (
        <div className={classes.main_page_reports}>
          <div>
            <div className={classes.header_row}>
              <div className={classes.header_row_reportName}>{translation[content.reportConfig?.reportConfigSummary?.name] || content?.reportConfig?.reportConfigSummary?.name }
                {_appsetting?.translationType && sysLanguages?.length && isStaticReport(content?.reportConfig?.reportConfigSummary?.type) && 
                  <span className={classes.languages_container}>
                    <span className={classes.languages_box}  onClick={handleClick}> <TranslateIcon fontSize="small"  sx={{width:'0.7em',height:'0.7em',position: 'absolute',top: '20%'}} ></TranslateIcon></span>
                    {
                      <span>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}

                          sx={{
                            '.MuiPaper-root': {
                              backgroundColor: '#fff', 
                              padding: '0px 10px',
                              borderRadius: '8px',         
                              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                              margin: '-8px 2px'
                            },
                          }}
                        >
                          <FormControl>
                            <RadioGroup
                              row
                              value={reportModel.translationLanguage}
                              onChange={onChangeLanguageHandler}
                            >
                              {sysLanguages?.map((item: any, index: any) => {
                                return (
                                  <>
                                    <FormControlLabel value={item.code} control={<Radio />} label={item.name} />
                                  </>
                                )
                              })}
                            </RadioGroup>
                          </FormControl>
                        </Popover>
                      </span>
                    }
                  </span>
                }
              </div>
              <div className={classes.report_as_on_date}>{translation['As On Date'] || 'As On Date'} {` : ${dayjs().format(dateFormatAsOnDate)}`}</div>
            </div>
            { isStaticRepo ? (
              <div className={classes.report_filter}>
                <div className={classes.report_header_link}>
                  <StaticReportFilterLink classes={classes} content={content} dateFormatAsOnDate={dateFormatAsOnDate} ></StaticReportFilterLink>
                </div>
              </div>
                ) : ( content.reportConfig.dynamicParameterList.length ? (
              <div className={classes.report_filter}>
                <div
                  key={`report-header-link-1`}
                  className={classes.report_header_link}
                  onClick={() => handleReportLinkCLick(content.reportConfig, content.reportFilterModel)}
                >
                  <CreateLinkForModel key={`create-link`} content={content} />
                </div>
              </div>
            ) : (
                null
            ))}
          </div>
          <div style={{ marginTop: isStaticReport(content?.reportConfig?.reportConfigSummary?.type) ? '20px':'0' }} className={classes.report_content}> {component} </div>
        </div>
      );
    }
  } else {
    return <BuildNotImplementedReportMessage key={uniqueId()} />;
  }
};

const CreateLinkForModel = (props: any) => {
  let content: any = props.content;
  const classes = useStyles();
  const filterLength = content?.reportFilterModel?.displayFilterValue.length ?? 0;
  return filterLength ? (
    <span key={`header-link`} className={classes.report_header_link_main}>
      <span key={`start-parenthesis`} style={{ color: '#2d72b9' }}>
        ({' '}
      </span>
      <span key={`filter-icon`}>
        <FilterAltIcon sx={{ color: '#2d72b9', fontSize: '1.2rem' }} />
      </span>
      {content.reportFilterModel.displayFilterValue.map((filterSelectedData: any, index: number) => {
        let filterName = filterSelectedData.name;
        let relationName = filterSelectedData.relationName;
        let filterValue = filterSelectedData.value;
        let filterType = filterSelectedData.type;

        return (
          <>
            <span key={`filterName-${index}`} className={classes.report_header_link_main_title}>
              {filterName}
              {'  '}
            </span>
            <span key={`filterRelationName-${index}`} className={classes.report_header_link_main_relationName}>
              {relationName}
              {'  '}
            </span>
            <span key={`filterSelectedValue-${index}`}>
              {((filterType === 'Date' || filterType === 'DateTime') &&
                relationName === ReportConstant.Is_In_The_Range) ||
              relationName === ReportConstant.Is_Not_In_The_Range ? (
                <>
                  {filterValue.split(',').map((value: any, index: number) => {
                    return (
                      <>
                        <span key={`Filter${filterType}Label-${index}`}>
                          {index === 0 ? (
                            <>From{'  '}</>
                          ) : (
                            <>
                              {'  '}To{'  '}
                            </>
                          )}{' '}
                        </span>
                        <span key={`Filter${filterType}Value-${index}`}>{getValueInDateTimeFormatAsAppSetting(value, filterType)}</span>
                      </>
                    );
                  })}
                </>
              ) : filterType === 'Number' &&
                (relationName === ReportConstant.Is_In_The_Range ||
                  relationName === ReportConstant.Is_Not_In_The_Range) ? (
                <>
                  {filterValue.split(',').map((value: any, index: number) => {
                    return (
                      <>
                        <span key={`FilterNumberLabel-${index}`}>
                          {index === 0 ? (
                            <>Min{'  '}</>
                          ) : (
                            <>
                              {'  '}Max{'  '}
                            </>
                          )}{' '}
                        </span>
                        <span key={`FilterNumberValue-${index}`}>{value}</span>
                      </>
                    );
                  })}
                </>
              ) : (
                <>{getValueInDateTimeFormatAsAppSetting(filterValue, filterType)}</>
              )}
            </span>
            <span key={`split-with-comma-${index}`}>{filterLength !== index + 1 ? <>, </> : <></>}</span>
          </>
        );
        // }
      })}
      <span key={`end-parenthesis`} style={{ color: '#2d72b9' }}>
        {'  '})
      </span>
    </span>
  ) : (
    <></>
  );
};

export const BuildNotImplementedReportMessage = () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '65vh'}}>
      {' '}
      <h2 style={{color: '#205e8b'}}>Work in progress update soon...!</h2>
      <p style={{color: '#205e8b'}}>Click to report icon in the navigation</p>
    </div>
  );
};
