import React, { useState } from 'react';
import { numericComparisonFilters, numericComparisonRangeFilters } from '../util/kagamiPresentationFilterUtil';
import { Button, FormControl, FormLabel, Stack, TextField } from '@mui/material';
import { KagamiStateManager } from '../../../../../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../../../../../constants/SystemConstants';
import { getKagamiListFilterModel } from '../../../controller/KagamiListFilterController';
import { searchRecord } from '../../../../../service/kagamiListService';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import makeStyles from '@mui/styles/makeStyles';
import { kagamiPresentationFilterstyle } from '../styles/KagamiPresentationFilterStyle';

export const KagamiPresentationNumberFilterBuilder = (props: any) => {
    const filterAttrName: any = props?.presentationNumberProps?.kagamiPresentationFilterModel?.attributeName;
    const listFiltersModel: any = getKagamiListFilterModel();
    const selectedFilterState: any = KagamiStateManager.getStateToHolder(SystemConstants.SELECTED_FILTER_STATE);
    const selectedValues: any[] = JSON.parse(JSON.stringify(selectedFilterState.getSelectedFilterValue()));
    const classes = makeStyles(kagamiPresentationFilterstyle)();
    let searchObj: any = listFiltersModel?.searchObj;
    let kagamiListModel: any = props?.presentationNumberProps?.kagamiListModel;
    let listModelPresentationId: any = props?.presentationNumberProps?.kagamiListModel?.presentation?.presentationId;

    let existingNumberFilterObj: any = {};
    if (selectedValues[filterAttrName]?.[0]) {
        existingNumberFilterObj = selectedValues[filterAttrName][0];
    }

    const currentNumberFilterObj: any = {
        id: existingNumberFilterObj?.id || '',
        value: existingNumberFilterObj?.value || '',
        attributeName: filterAttrName || '',
        symbol: existingNumberFilterObj?.symbol
    };

    const [numberFilterObj, setNumberFilterObj]: any = React.useState(currentNumberFilterObj);

    const [numberFilterRangeObj, setNumberFilterRangeObj] = React.useState({
        Min: existingNumberFilterObj?.value?.Min || '',
        Max: existingNumberFilterObj?.value?.Max || '',
    });

    const [isSearchEnabled, setIsSearchEnabled] = React.useState(false);

    const numberFilterInputOnChange = (input: any, event: React.ChangeEvent<HTMLInputElement>) => {
        const inputFieldValue = event.target.value;
        if (CommonUtils.isNotEmpty(numberFilterRangeObj)) {
            setNumberFilterRangeObj({ Min: '', Max: '' }); 
        }
        setNumberFilterObj({
            id: input.id,
            value: inputFieldValue,
            symbol: input.symbol,
        });
        setIsSearchEnabled(inputFieldValue !== '');
    };

    const numberFilterRangeInputOnChange = (range: 'Min' | 'Max', event: React.ChangeEvent<HTMLInputElement>) => {
        const rangeInputValue = event.target.value;
        if (CommonUtils.isNotEmpty(numberFilterObj)) {
            setNumberFilterObj({ id: '', value: '', symbol: '' });
        }
        setNumberFilterRangeObj((prevRange: any) => {
            const newRangeObj = { 
                ...prevRange, 
                [range]: rangeInputValue 
            };
            if (range === 'Min' && parseFloat(rangeInputValue) > parseFloat(prevRange.Max)) {
                newRangeObj.Max = '';
            }
            const isBothMinMaxFilled = newRangeObj.Min !== '' && newRangeObj.Max !== '';
            const isMinLessThanOrEqualMax = parseFloat(newRangeObj.Min) < parseFloat(newRangeObj.Max);
            setIsSearchEnabled(isBothMinMaxFilled && isMinLessThanOrEqualMax);
            return newRangeObj;
        });
        setIsSearchEnabled(rangeInputValue !== '');
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if(e.key === "e" || e.key === "E") {
            e.preventDefault();
            e.stopPropagation();
        }
    }; 

    const valueObjects: any = CommonUtils.isNotEmpty(numberFilterObj?.value) ? numberFilterObj : numberFilterRangeObj;
    selectedFilterState?.setSelectedFilterValue(selectedValues);

    if (listFiltersModel) {
        listFiltersModel?.presentationFiltersApplied?.push(props?.presentationNumberProps?.kagamiPresentationFilterModel);
    }

    return (
        <>
            <div className={classes.numberFilterInputBoxes}>
                {numericComparisonFilters?.map((input: any) => (
                    <FormControl   
                        key={input?.id}
                        className={classes.numbertFilterFormControl}
                    >
                        <FormLabel className={classes.numbertFilterFormLabel}>{input?.symbol}</FormLabel>
                        <TextField
                            id={input?.id}
                            type={input?.type}
                            placeholder={input?.label}
                            value={input?.id === numberFilterObj?.id ? numberFilterObj?.value : ''}
                            onKeyDown={(e: any) => handleKeyDown(e)}
                            onChange={(e: any) => numberFilterInputOnChange(input, e)}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    padding: '0 0.3125rem',
                                },
                            }}
                            className={classes.numberFilterTextField}
                        />
                    </FormControl>
                ))}
                <FormControl className={classes.numberFilterRangeFormControl}>
                    {numericComparisonRangeFilters?.map((input: any, index: number) => (
                        <React.Fragment key={input?.id}>
                            <TextField
                                id={input?.id}
                                type={input?.type}
                                placeholder={input?.range}
                                value={input?.range === "Min" ? numberFilterRangeObj?.Min : numberFilterRangeObj?.Max}
                                onKeyDown={(e: any) => handleKeyDown(e)}
                                onChange={(e: any) => numberFilterRangeInputOnChange(input.range, e)}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    style: {
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        padding: '0 0.3125rem',
                                    },
                                }}
                                className={classes.numberFilterRangeTextField}
                            />
                            {index === 0 && (
                                <span style={{ display: 'inline-block', minWidth: '1rem', fontWeight: 400 }}> to </span>
                            )}
                        </React.Fragment>
                    ))}
                </FormControl>
            </div>
            <Stack direction="row" spacing={1} style={{ justifyContent: 'end' }}>
                <Button
                    variant="contained"
                    color="success"
                    className={classes.numberFilterSearchButton}
                    disabled={!isSearchEnabled} 
                    onClick={() => handleOnNumberFilterSearch(searchObj, kagamiListModel, listModelPresentationId, valueObjects, filterAttrName)}
                >
                    SEARCH
                </Button>
            </Stack>
        </>
    );
};

export const handleOnNumberFilterSearch = (
    searchObj: any,
    kagamiListModel: any,
    listModelPresentationId: any,
    valueObjects: any,
    filterAttrName: any,
    isRemove: boolean = false,
) => {
    const selectedFilterState: any = KagamiStateManager.getStateToHolder(SystemConstants.SELECTED_FILTER_STATE);
    const selectedValues: any = JSON.parse(JSON.stringify(selectedFilterState.getSelectedFilterValue()));
    const listFilterAppliedState: any = KagamiStateManager.getStateToHolder(SystemConstants.CLEAR_LIST_FILTER_STATE);

    if (isRemove) {
        delete selectedValues[filterAttrName];
        delete searchObj[filterAttrName];
    } else {
        if (valueObjects?.value) {
            selectedValues[filterAttrName] = [{
                value: valueObjects?.value,
                id: valueObjects?.id,
                symbol: valueObjects?.symbol
            }];
            searchObj[filterAttrName] = {
                [valueObjects?.id]: [valueObjects?.value]    
            };
        } else if (valueObjects?.Min && valueObjects?.Max) {
            selectedValues[filterAttrName] = [{
                value: valueObjects,
                symbol: ":"
            }];
            searchObj[filterAttrName] = {
                between: [valueObjects?.Min, valueObjects?.Max]       
            };
        } else {
            delete selectedValues[filterAttrName];
            delete searchObj[filterAttrName];
        }
    }

    selectedFilterState.setSelectedFilterValue(selectedValues);

    const allKeys: any = Object.keys(searchObj);
    let falsyArray: any[] = [];

    if (allKeys.length > 0) {
        for (let item of allKeys) {    
            if (searchObj[item][valueObjects?.id] && searchObj[item][valueObjects?.id]?.length > 0) {
                falsyArray.push('true');
            } else if (searchObj[item]['between'] && searchObj[item]['between']?.length > 0) {
                falsyArray.push('true');
            }
            else if (searchObj[item]['like']?.length > 0) {
                falsyArray.push('true');
            }
             else {
                falsyArray.push('false');
            }
        }
    }

    falsyArray;

	kagamiListModel.pageNumber = 1;
    CommonUtils.isNotEmpty(falsyArray) && falsyArray.includes('true')
        ? listFilterAppliedState?.SetIsEnabled(true) : listFilterAppliedState?.SetIsEnabled(false);
    searchRecord(kagamiListModel, listModelPresentationId, null, null, searchObj, false);
};