import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import {
  buildPresentation,
  FormButtons,
  FormPageContent,
  FormFooterContent,
  FormTriggers,
  ModalPopUP,
  pushModalPopUpToFormControls
} from '../builder/FormBuilder';
import { FormModelProps, KagamiFormModel } from '../model/KagamiFormModel';
import { KagamiFormStyle } from '../style/KagamiFormStyle';
import { buildProcessPageHeaderName } from '../../ProcessPageBuildler';
import { buildActivityLogModal, getKagamiActivityLogModel } from '../../../../../../../../../../components/activityLog/controller/KagamiActivityLogModalController';
import { KagamiActivityLogModel } from '../../../../../../../../../../components/activityLog/model/KagamiActivityLogModel';
import { buildTriggerAlertModal } from '../../Triggers/builder/TriggerAlertModal/controller/TriggerAlertModalController';
import { buildProcessHeader } from '../../processHeader/controller/KagamiProcessHeaderController';
import { customErrorCheck } from '../service/FormService';

export const buildForm = (kagamiFormModel: KagamiFormModel) => {
  let formComponentObj = buildPresentation(kagamiFormModel);
  kagamiFormModel.formComponentObj = formComponentObj;
  return <KagamiForm key={kagamiFormModel.processStepName} kagamiFormModel={kagamiFormModel} />;
};

const useStyles = makeStyles(KagamiFormStyle);
const KagamiForm = (props: FormModelProps) => {
  let classes = useStyles();

  useEffect(() => {
    console.log('loaded Form ' + props.kagamiFormModel.processStepName);
    customErrorCheck(props?.kagamiFormModel?.formData?.formData)
  }, []);

  pushModalPopUpToFormControls(props.kagamiFormModel);
  // let activityLogModel: KagamiActivityLogModel = getKagamiActivityLogModel()

  return (
    <div  className={classes.root}> 
      <>
      {!props.kagamiFormModel.isSingleListView 
      && <>
      <div className={classes.formHeader + ' ' + 'form_header'}>
          {!props.kagamiFormModel.isGroupForm && buildProcessHeader(props.kagamiFormModel.kagamiProcessHeaderModel) }
          {/* {!props.kagamiFormModel.isGroupForm && buildProcessPageHeaderName(props.kagamiFormModel) } */}
          <FormTriggers key={props.kagamiFormModel.processStepName} kagamiFormModel={props.kagamiFormModel} />
      </div>
        </>
        
    }
      </>
    <form>
      <FormPageContent kagamiFormModel={props.kagamiFormModel} />
      <FormFooterContent kagamiFormModel={props.kagamiFormModel}/>
      <ModalPopUP kagamiFormModel={props.kagamiFormModel} />
      {/* {buildActivityLogModal(activityLogModel)} */}
      {buildTriggerAlertModal(props.kagamiFormModel.triggerAlertModalModel)}
      <footer className={classes.formFooter}>
        <FormButtons kagamiFormModel={props.kagamiFormModel} />
      </footer>
    </form>
    </div>
  );
};
