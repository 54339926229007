import { saveAs } from "file-saver";
import _ from 'lodash';
const ExcelTreeDataDownload = (configInfo: any) => {
    let initConfig = {
        fileName: 'File',
        worksheetName: 'WorkSheet',
        staticHeader: false,
        alternateRowColor: false,
        headerrow: 3,
        totalHeaderRows : 4,
        excelHeaderRow: 0, //for titles ||  column headers
        rowHeaderColor:  '000000',//'FFFFFF', 
        rowHeaderBGColor: 'd5d5d5',//'005ba9', 
        staticHeaderValue: "",
        colors: ["dff0d8","c4c5c4","e4dac9","ffe8e8","ffc5a9","dffff0","3ec450","ac909e", "f19605", "fdd29a",  "dbe2c3",  "e6d9ff", "f87a216b", "319bd0"],
        images: [],
        defaultRowHeight: 20,
        defaultHeaderRowHeight:30,
        maxHeaderCellWidth: 50,
        excelcolumns: [],
        excelData: [],
        levelsInfo: [],
        nestedKey:'nesteddata',
        nestedRecordRowColor:false,//for embed records enable this for alternate colors 
    }

    let config = _.assignIn(initConfig, configInfo);
    const downLoad = () => {
        makeExcelColumnsAndData();
        console.log('Download Start ...');
        import('exceljs').then(({default : Excel}) => {
            let workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet(config.worksheetName);
            initDownload(workbook, worksheet);
            worksheetStyles(workbook, worksheet);
            worksheet.getRow(1).height = config.defaultHeaderRowHeight; 
            downloadFile(workbook, worksheet);
        })    
    }
    const makeExcelColumnsAndData = () => {
        config.excelcolumns = getExcelColumns(configInfo);
        config.excelData = configInfo.excelTableRows || configInfo.reportDBData;
    }

    const getExcelColumns = function(configInfo:any) {
        var columns = _.cloneDeep(configInfo.reportColumns);
        var columnsList:any =[]
            if(columns.length > 0) {
                _.forEach(columns,function(ele) {
                    // var obj = { header: ele.headerName, key: ele.field ,width: configInfo.defaultColumnWidth || 33};
                    let colWidth = getColumnWidth(ele.field);
                    if(ele?.headerName?.length > colWidth) {
                        colWidth = ele?.headerName?.length;
                    }
                    if(config.maxHeaderCellWidth && colWidth > config.maxHeaderCellWidth ) {
                        colWidth = config.maxHeaderCellWidth;
                    }
                    var obj = { header: ele.headerName, key: ele.field ,width: colWidth + 2};
                    getExcelColumnSettings(configInfo,obj)
                    columnsList.push(obj);                 
                })
            }
        return columnsList;
    }
    const getColumnWidth = function (field: any) {
        if (config?.excelTableRows?.length && field) {
            let recObj = _.maxBy(config.excelTableRows, function (rec: any) { return rec[field]?.length });
            if (recObj && recObj[field]) {
                return ("" + recObj[field]).length
            }
        }
        return configInfo.defaultColumnWidth || 33;
    }

    const getExcelColumnSettings = function(configInfo:any,obj:any) {
        if( configInfo?.reportColumns && configInfo.reportColumns.length > 0) {
            var found = _.filter(configInfo.reportColumns,{id:obj.field });
            if(!found?.length) {
                found = _.filter(configInfo.reportColumns,{headerName:obj.header })
            }
            if(found && found.length > 0 ) {
                if(found[0]['type'] =='Number'){
                    obj['type']='number';
                }else {
                    obj['type']='string';
                }
            }
        }
    }

    const initDownload = (workbook: any, worksheet: any) => {
        let excelcolumns = _.cloneDeep(config.excelcolumns);
        worksheet.columns = _.cloneDeep(config.excelcolumns);
        if (!(config.images && config.images.length > 0)) {
            config.headerrow = 0;
            if (worksheet._rows && worksheet._rows.length > 0) {
                worksheet._rows.shift(0);
            }
        } else {
            for (let rec = 0; rec < config.headerrow - 1; rec++) {
                worksheet.addRow({})
            }
        }
        let filterstr = config.staticHeaderValue || '';
        let dataKeys = _.map(excelcolumns, 'key');
        let headerKeys = _.map(excelcolumns, 'header')
        if (filterstr && config.staticHeader) {
            let staticRowData:any = {};
            let keys = _.cloneDeep(dataKeys)
            if (keys && keys.length > 0) {
                keys.forEach((o) => {
                    staticRowData[o] = '';
                })
                staticRowData[keys[0]] = filterstr;
                worksheet.addRow(staticRowData);
                //filter row merger
                let excelHeaderCellRange = intToExcelCol(excelcolumns.length);//'A1:E4'
                let fromRange = `A${config.headerrow + 1}`;
                let toRange = excelHeaderCellRange + (config.headerrow + 1)
                worksheet.mergeCells(`${fromRange}:${toRange}`);
            }
        }
        makeLedgerHeader(dataKeys,worksheet);
        let headerRow = makeExcelHeader(dataKeys, headerKeys);
        let row = worksheet.addRow(headerRow);
        config.excelHeaderRow = row._number;
        applyColorToRow(row, config, true);
        handleWorksheetData(excelcolumns, worksheet, workbook);
    }

    const getRowForSingleCell = (dataKeys:any,value:any) => {
        let newRow:any = {}
        if (dataKeys && dataKeys.length > 0) {
            dataKeys.forEach((o:any, index:any) => {
                if(index ==0) {
                    newRow[o] = value;
                }else {
                    newRow[o] = '';
                }
            })
        }
        return newRow;
    }
    const makeLedgerHeader = (dataKeys:any,worksheet:any) => {
        let excelHeaderInfo = configInfo.excelHeaderInfo || {};
        let translation = configInfo.languageTranslation || {}; 
        let excelHeaderCellRange = intToExcelCol(dataKeys.length -1);//'A1:E4'
        let excelHeaders = [ { value: translation[excelHeaderInfo['reportType']] || excelHeaderInfo['reportType'],fromCell:'A1',toCell:'B1',style: {fontSize:20,alignment:'right',bold:true} },
                             { value: '',fromCell:'A2',toCell:excelHeaderCellRange+'2',style: {fontSize:14,alignment:'right',bold:true} },
                             { value:  translation['Date'] || 'Date' ,fromCell:'A3',toCell:'B3',style: {fontSize:14,alignment:'right',bold:true} },
                             { value: translation['Sub Organization'] || 'Sub Organization',fromCell:'A4',toCell:'B4',style: {fontSize:14,alignment:'right',bold:true} },
                             { value: (translation['As On'] || 'As On')+" :"+(config.asOnDate || new Date().toLocaleString('en-US')),fromCell:'C1',toCell:excelHeaderCellRange+'1',style: {fontSize:12,alignment:'right',bold:false} } ,
                             { value: excelHeaderInfo['date'],fromCell:'C3',toCell:excelHeaderCellRange+'3',style: {fontSize:12,alignment:'left',bold:false} } ,
                             { value: excelHeaderInfo['subOrganization'],fromCell:'C4',toCell:excelHeaderCellRange+'4',style: {fontSize:12,alignment:'left',bold:false} } ,
                           ]

        excelHeaders.forEach((item)=> {
            let cell =  worksheet.getCell(item.fromCell);
            cell.value = item.value;
            worksheet.mergeCells(`${item.fromCell}:${item.toCell}`);
            handleReportHeaderStyles(cell,item.style);
        })                           

    }
    const makeLedgerHeader1 = (dataKeys:any,worksheet:any) => {
        let excelHeaderInfo = configInfo.excelHeaderInfo || {};
        // worksheet.addRow(getRowForSingleCell(dataKeys,excelHeaderInfo['reportType'] ));
        // worksheet.addRow(getRowForSingleCell(dataKeys, "Sub Organization : "+excelHeaderInfo['subOrganization']));
        // worksheet.addRow(getRowForSingleCell(dataKeys,excelHeaderInfo['date'] ));
        // worksheet.addRow(getRowForSingleCell(dataKeys,''));
        let excelHeaderCellRange = intToExcelCol(dataKeys.length -1);//'A1:E4'
        let reportTypeRow = worksheet.addRow([excelHeaderInfo['reportType']]);
        worksheet.mergeCells(`A${reportTypeRow.number}:${excelHeaderCellRange}${reportTypeRow.number}`);
        applyStyleToExcelHeader(worksheet,reportTypeRow);
        let subOrgRow = worksheet.addRow(["Sub Organization : " + excelHeaderInfo['subOrganization']]);
        worksheet.mergeCells(`A${subOrgRow.number}:${excelHeaderCellRange}${subOrgRow.number}`);
        applyStyleToExcelHeader(worksheet,subOrgRow);
        let dateRow = worksheet.addRow([excelHeaderInfo['date']]);
        worksheet.mergeCells(`A${dateRow.number}:${excelHeaderCellRange}${dateRow.number}`);
        applyStyleToExcelHeader(worksheet,dateRow);
        worksheet.addRow(['']);

    }
    const makeExcelHeader = (dataKeys:any, headerKeys:any) => {
        let headerRow:any = {}
        if (dataKeys && dataKeys.length > 0) {
            dataKeys.forEach((o:any, index:any) => {
                headerRow[o] = headerKeys[index] || headerRow[o];
            })
            return headerRow;
        }
    }
    const applyColorToRow = (row:any, config:any, isHeaderRow:any) => {
        row.eachCell((cell:any, colNumber:any) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: config.rowHeaderColor || 'FF0000FF' },
                fgColor: { argb: config.rowHeaderBGColor || 'f5f5f5' }
            }
            if (isHeaderRow) {
                cell.font = {
                    name: 'Calibri',
                    bold: true,
                    size: 12,
                };
            }
        });
    }
    const makeNestedWorkSheetData = (worksheet:any, nestedExcelData:any, outlineLevel:any, rowColor:any) => {
        worksheet.lastRow.outlineLevel = outlineLevel;
        for (let _indx = 0; _indx < nestedExcelData.length; _indx++) {
            let row = worksheet.addRow(nestedExcelData[_indx]);
                row.eachCell((cell: any, colNumber: any) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    // fgColor: { argb: rowColor || 'f5f5f5' }
                    fgColor: { argb: 'ffffff' }
                };
                if(initConfig.nestedRecordRowColor) {
                    cell.fill['fgColor'] = { argb: rowColor || 'f5f5f5' };
                }
                });
            worksheet.lastRow.outlineLevel = outlineLevel + 1;
            if(configInfo?.defaultExpand == false) {
                worksheet.lastRow.hidden = true;
            }
            if (nestedExcelData[_indx][initConfig.nestedKey]&& nestedExcelData[_indx][initConfig.nestedKey].length > 0) {
                makeNestedWorkSheetData(worksheet, nestedExcelData[_indx][initConfig.nestedKey], outlineLevel + 1, getRandomColor(outlineLevel + 1));
            }
        }
    }

    const handleWorksheetData = (excelcolumns:any, worksheet:any, workbook:any) => {
        let excelData = (config.excelData && config.excelData.length > 0) ? config.excelData : [];
        for (let indx = 0; indx < excelData.length; indx++) {
            worksheet.addRow(excelData[indx]);
            if (excelData[indx][initConfig.nestedKey]) {
                makeNestedWorkSheetData(worksheet, excelData[indx][initConfig.nestedKey], 0, getRandomColor(0));
            }
        }
        let aggrgatecols = _.filter(excelcolumns, (o) => o.aggregates);
        if (aggrgatecols && aggrgatecols.length > 0) {
            let temp:any = {};
            for (let indx = 0; indx < excelcolumns.length; indx++) {
                temp[excelcolumns[indx].key] = "";
                let aggregatename = excelcolumns[indx].aggregates;
                if (aggregatename) {
                    temp[excelcolumns[indx].key] = aggregatename == 'count' ? excelData.length : aggregatename == 'sum' ? _.sumBy(excelData, excelcolumns[indx].key) : '';
                }
            }
            worksheet.addRow(temp);
        }

        //add images to work sheet 
        if (config.images && config.images.length > 0) {
            let excelHeaderCellRange = intToExcelCol(excelcolumns.length);//'A1:E4'
            if (config.staticHeader == true) {
                worksheet.mergeCells(`A${config.headerrow}:${excelHeaderCellRange}${config.headerrow}`);
            }
            config.images.forEach((item:any, index:any) => {
                worksheet.addImage(workbook.addImage({
                    base64: item.data,
                    extension: item.extension,
                }), item.range);
            })
        }
    }

    const worksheetStyles = (workbook:any, worksheet:any) => {
        worksheet.eachRow({ includeEmpty: true }, (row:any, rowNumber:any) => {
            row.height = config.defaultRowHeight || 15;
            if(rowNumber > config?.totalHeaderRows ) {
            row.eachCell((cell:any, colNumber:any) => {
                cell.font = {
                    name: 'Calibri',
                    bold: false,
                    size: 11,
                };

                if (rowNumber == config.excelHeaderRow) {
                    cell.alignment = {
                        vertical: 'middle', horizontal: 'center'
                    };
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: config.rowHeaderBGColor || 'f5f5f5' },
                    }
                    cell.font = {
                        bold: true,
                        size: 12,
                        color: { argb: config.rowHeaderColor || "FFFFFF" }
                    };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    
                }else {
                    cell.border = {
                        top: { style: 'thin', color: { argb: 'C7C7C7' } },
                        left: { style: 'thin', color: { argb: 'C7C7C7' } },
                        bottom: { style: 'thin', color: { argb: 'C7C7C7' } },
                        right: { style: 'thin', color: { argb: 'C7C7C7' } }
                    };
                }
                
                if (config.alternateRowColor && (rowNumber > config.headerrow + 1) && rowNumber % 2 == 0) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'f5f5f5' }
                    }
                }
                /** for outline records it space requrired for firt field */
                if(cell?._row?.outlineLevel  && colNumber == 1){
                    cell.alignment={indent:cell._row.outlineLevel*2}
                }
                //for rtl columns
                if(configInfo.excelRTLfields && cell?._column?._key  &&  configInfo.excelRTLfields.indexOf(cell._column._key) !=-1) {
                    if(!cell.alignment) {
                        cell.alignment = {};
                    }
                    cell.alignment['horizontal'] = 'right';
                }

            });
            }
        });

    }

    const downloadFile = (workbook:any, worksheet:any) => {
        worksheet.properties.outlineProperties = {
            summaryBelow: false,
            summaryRight: false,
        };
        workbook.views = [
            {
                x: 10, y: 10, width: 10000, height: 20000,
                firstSheet: 0, activeTab: 1, visibility: 'visible'
            }
        ]
        let fileName = config?.excelHeaderInfo?.reportType || config.fileName;
        workbook.xlsx.writeBuffer().then((data:any) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, `${fileName}.xlsx`);
        });
    }
    const intToExcelCol:any = (number:any) => {
        // let colName = '', dividend = Math.floor(Math.abs(number)), rest;
        // while (dividend > 0) {
        //     rest = (dividend - 1) % 26;
        //     colName = String.fromCharCode(65 + rest) + colName;
        //     dividend = ((dividend - rest) / 26);
        // }
        // return colName;
        const res = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[number % 26];
        return number >= 26 ? intToExcelCol(Math.floor(number/ 26) - 1) + res : res;
    };
    const getRandomColor = (colorIndex:any) => {
        // let color = config.colors[colorIndex];
        // if (!color) {
        //     const letters = '0123456789ABCDEF';
        //     for (let indx = 0; indx < 6; indx++) {
        //         color += letters[Math.floor(Math.random() * 16)]
        //     }
        // }
        let color = 'f5f5f5'
        return color;
    }
    const applyStyleToExcelHeader = (worksheet: any, row: any) => {
        const mergedCell = worksheet.getCell(`A${row.number}`);
        mergedCell.font = { bold: true };
        mergedCell.alignment = { vertical: 'middle', horizontal: 'center' };
    }
    const handleReportHeaderStyles = (cell :any,style:any) => {
        cell.alignment = {
            horizontal: style?.alignment || 'left',  // Horizontal alignment: left, center, right, fill, justify, centerContinuous, distributed
            vertical: 'middle',    // Vertical alignment: top, middle, bottom, distributed, justify
            wrapText: true,        // Text wrapping
            shrinkToFit: true,     // Shrink the text to fit inside the cell
            indent: 1              // Set indentation
          };
          cell.font = {
            bold: style?.bold || false,
            size: style?.fontSize || 12,
        };
    }

  
    return {
        downLoad
    }
};
export default ExcelTreeDataDownload;



