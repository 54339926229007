import makeStyles from "@mui/styles/makeStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { KagamiAccordionStyle } from "../style/KagamiAccordion";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  AccordionProps,
  KagamiAccordionModel,
  useKagamiAccordionState,
} from "../model/KagamiAccordionModel";
import { toggleAccordion } from "../controller/KagamiAccordionController";
import { createState } from "@hookstate/core";
import { getActiveModel } from "../../../application/builder/home/builder/body/builder/process/controller/KagamiProcessController";
import { translateToLocalistaion } from "../../../application/builder/home/builder/body/builder/process/service/PresentationService";

export default function buildAccordionComponent(
  accordionModel: KagamiAccordionModel
) {
  return (
    <KagamiAccordion key={Math.random()} accordionModel={accordionModel} />
  );
}

const useStyles = makeStyles(KagamiAccordionStyle);

const KagamiAccordion = (props: AccordionProps) => {
  const classes = useStyles();
  let activeModel = getActiveModel();
  let translations =
    activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties
      ?.translation;
  props.accordionModel.state = useKagamiAccordionState(
    createState(props.accordionModel)
  );
  const isMainForm = props.accordionModel.transactionType === "mainForm";
  return (
    <div className={` ${isMainForm ? classes.mainTransaction : classes.root}`}>
      <Accordion
        defaultExpanded={isMainForm ? true : props.accordionModel.active}
        disabled={props.accordionModel.disabled}
        className="highpriority-background"
        classes={{
          root: classes.accordion,
          expanded: classes.accordionExpanded,
        }}
      >
        <AccordionSummary
          expandIcon={props.accordionModel.active ? <KeyboardArrowDownIcon  className="expand-more-icon" /> : <KeyboardArrowRightIcon className="expand-more-icon" />}
          classes={{
            root: `${classes.accordionSummary} kgm-accordian-header`,
            expanded: `${classes.accordionSummaryExpaned} `,
            content: `${classes.accordionSummaryContent} drag-handle`,
          }}
          onClick={() => toggleAccordion(props.accordionModel)}
        >
          {translateToLocalistaion(translations, props.accordionModel.title)}
        </AccordionSummary>
        <div
          className={`${classes.accordion_details_content} kgm-accordian-content `}
        >
          <AccordionDetails
            style={{ minHeight: isMainForm ? "fit-content" : "360px" }}
            className={classes.accordionDetails}
          >
            {props.accordionModel.content}
          </AccordionDetails>
        </div>
      </Accordion>
    </div>
  );
};
