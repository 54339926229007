import { createState } from '@hookstate/core';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { StaticReportFiltersModel, StaticReportFiltersModelProps, useStaticReportFiltersModelState } from '../model/StaticReportFiltersModel';
import { hideLoader, showLoader } from '../../../../../../../../../components/loader/controller/KagamiLoaderController';
import { getAppSettingDateFormat, getReportType } from '../../view/report/service/ReportUtilService';
import { getDBStaticFilterData, onChangeAccount, onChangeState } from '../service/StaticReportFiltersService';
import { getStaticReportFields } from '../service/StaticReportUtil';
import { makeStaticReportFields } from './StaticReportFields';
import { onChangeControlAccount, onChangeDependedValue } from '../../view/report/builder/ledgers/subLedger/service/SubLedgerReportService';

export const buildStaticReportFiltersComponent = (staticReportFiltersModel: StaticReportFiltersModel) => {
  return <StaticReportFilters key={'ledger-details'} staticReportFiltersModel={staticReportFiltersModel} />;
};

export const StaticReportFilters = (props: StaticReportFiltersModelProps) => {
  const staticReportFiltersModel: StaticReportFiltersModel = props.staticReportFiltersModel;
  const dateformat = getAppSettingDateFormat(staticReportFiltersModel?.reportConfig);
  let inputReportConfig = (staticReportFiltersModel.reportConfig);
  const payloadDateFormat: string = 'YYYY-MM-DD';
  // Date
  let startDateYear = new Date();
  startDateYear.setDate(1);
  startDateYear.setMonth(0);
  let yearStartDate = new Date(startDateYear).toISOString().substring(0, 10);
  let todayDate = new Date().toISOString().substring(0, 10);
  const [staticFilterData, setStaticFilterData]: any = React.useState({ suborganization: [], gst: [], currency: [], accountResult: [], state: [], projectPlan: [], allProjects: [], controlAccounts: [] });
  
  const handleAgeingRanage = (value : any) => {
    let ageingPeriods: any = {};
    if(value?.length) {
      value.forEach((element :any,index :number) => {
        if(element) {
          ageingPeriods['From'+index] = element.split("-")[0] || 0;
          ageingPeriods['To'+index] = element.split("-")[1] || 0;
        }
      });
    }
    return ageingPeriods;
  }

  //remove any for state 
  const [reportsFields, setReportFields]: any = React.useState({
    subOrganization: staticReportFiltersModel?.reportConfig?.subOrganization?.[0] || null,
    selectedValue: staticReportFiltersModel?.reportConfig?.selectedValue || '',
    selectedCurrency: staticReportFiltersModel?.reportConfig?.selectedCurrency || '',
    state: (staticReportFiltersModel?.reportConfig?.state  && staticReportFiltersModel?.reportConfig?.state != "NOPOP" ) ? [staticReportFiltersModel?.reportConfig?.state] : [],
    gst: staticReportFiltersModel?.reportConfig?.gst || '',
    projectPlan: (staticReportFiltersModel?.reportConfig?.projectPlan  && staticReportFiltersModel?.reportConfig?.projectPlan != "NOPOP") ? [staticReportFiltersModel?.reportConfig?.projectPlan] : [],
    startDate: staticReportFiltersModel?.reportConfig?.leftPanel?.startDate || yearStartDate,
    endDate: staticReportFiltersModel?.reportConfig?.leftPanel?.endDate || todayDate,
    agingPeriod: staticReportFiltersModel?.reportConfig.agingPeriod,
    fromto: staticReportFiltersModel?.reportConfig.fromto,
    agingRange: handleAgeingRanage(staticReportFiltersModel?.reportConfig.fromto)
  });

  // let translation :any =  props?.staticReportFiltersModel?.languageTranslation || {}

  if (!inputReportConfig['leftPanel']) {
    inputReportConfig['leftPanel'] = {
      startDate: yearStartDate,
      endDate: todayDate
    };
  }
  props.staticReportFiltersModel.state = useStaticReportFiltersModelState(createState(staticReportFiltersModel));
  const handleAccountChange = async (value: any) => {
    let gstData = await onChangeAccount(value, staticReportFiltersModel);
    setStaticFilterData((prevState: any) => ({
      ...prevState,
      gst: gstData
    }));
  }

  const handleStateChange = async (value: any) => {
    let projectPlan: any = [];
    if (value && value.length > 0) {
      projectPlan = await onChangeState(value, staticReportFiltersModel);
    } else {
      projectPlan = staticFilterData['allProjects'];
    }
    setStaticFilterData((prevState: any) => ({
      ...prevState,
      projectPlan: projectPlan
    }));
  }

  

  const handleControlAccountChange = async (value: any) => {
    let staticData = await onChangeControlAccount(value, staticReportFiltersModel, inputReportConfig['classifier1']);
    let accountResult: any = [];
    if (staticData?.data) {
      accountResult = cloneDeep(staticData.data.accountResult);
    }
    setStaticFilterData((prevState: any) => ({
      ...prevState,
      accountResult: accountResult
    }));

  }

  const handleFilterChange = (item: any, newValue: any) => {
    if (item.field == 'subOrganization') {
      handleStaticFilters([newValue], item.field);
    } else if (item.field == 'selectedValue') {
      handleAccountChange(newValue);
      handleStaticFilters(newValue, item.field);
    } else if (item.field == 'state') {
      handleStateChange(newValue);
      handleStaticFilters(newValue, 'state');
    } else if (item.field == 'projectPlan') {
      handleStaticFilters(newValue, 'projectPlan');
    } else if (item.field == 'startDate' || item.field == 'endDate') {
      if (newValue !== null) {
        newValue = newValue.format(payloadDateFormat).toString();
        let val = newValue + (item.field == 'startDate' ? " 00:00:00" : ' 23:59:59')
        handleStaticFilters(val, item.field);
      }
    } else if (item.field == 'gst') {
      handleStaticFilters(newValue, 'gst');
    } else if (item.field == 'classifier2') {
      handleControlAccountChange(newValue);
      handleStaticFilters(newValue, item.field);
    } else if (item.field == 'agingPeriod') {
      handleStaticFilters(newValue ? parseInt(newValue) : 0, item.field);
    } else {
      handleStaticFilters(newValue, item.field);
    }
  }
  const handleStaticFilters = (value: any, type: any) => {
    if (type == 'startDate' || type == 'endDate') {
      if (!inputReportConfig['leftPanel']) {
        inputReportConfig['leftPanel'] = {};
      }
      inputReportConfig['leftPanel'][type] = value;
    } else if (type == 'state' || type == 'projectPlan') {
      inputReportConfig[type] = value && value.length > 0 ? value.join(",") : [];
    } else {
      inputReportConfig[type] = value;
    }

    setReportFields((prevState: any) => ({
      ...prevState,
      [type]: value
    }));
  }

  const handleDependedChange = async (value: any) => {
    let staticData = await onChangeDependedValue(value, staticReportFiltersModel);
    setStaticFilterData((prevState: any) => ({
      ...prevState,
      controlAccounts: staticData?.data?.controlAccount || []
    }));
    handleStaticFilters(value, 'classifier1');
  }
  const handleAgingPeriodRangeChange = async (value: any, type: any, index: any) => {
    if (!inputReportConfig['fromto']) {
      inputReportConfig['fromto'] = [];
    }
    if (type == 'From') {
      if (inputReportConfig['fromto'][index] && inputReportConfig['fromto'][index].split("-").length > 1) {
        inputReportConfig['fromto'][index] = value + "-" + inputReportConfig['fromto'][index]?.split("-")[1];
      } else {
        inputReportConfig['fromto'][index] = value;
      }
    } else if (type == 'To') {
      inputReportConfig['fromto'][index] = inputReportConfig['fromto'][index]?.split("-")[0] + "-" + value;
    }
    setReportFields((prevState: any) => ({
      ...prevState,
      agingRange: handleAgeingRanage(inputReportConfig['fromto'])
    }));
  }


  useEffect(() => {
    getDBStaticFilterData(staticReportFiltersModel?.reportConfig?.reportConfigSummary?.type).then((response: any) => {
      console.log(response.data);
      setStaticFilterData(response.data);
      props.staticReportFiltersModel.state.setDataLoaded(true);
    });
    props.staticReportFiltersModel.state.setDataLoaded(true);

  }, []);

  let staticReportFields = getStaticReportFields(getReportType(staticReportFiltersModel?.reportConfig));
  if (props.staticReportFiltersModel.state.isDataLoaded) {
    hideLoader();
    return (
      <div>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {staticReportFields.map((item: any, index) => (
            <> {(item.isMandatory || staticFilterData?.[item.dataField]?.length > 0) && makeStaticReportFields(item, index, { staticFilterData, reportsFields, handleFilterChange, dateformat, handleDependedChange ,handleAgingPeriodRangeChange,staticReportFiltersModel})} </>
          ))}
        </Grid>
      </div>
    );
  } else {
    showLoader()
    return null;
  }
};

