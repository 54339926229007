import { createState } from '@hookstate/core';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { KagamiProcessPageModel, ProcessModelProps, useProcessState } from '../model/KagamiProcessModel';
import { KagamiProcessPageStyle } from '../style/KagamiProcessPage';
import { buildBreadcrumbs } from '../builder/breadcrumbs/controller/kagamiBreadcrumbsController';
import { makeInitialProcessContentCall } from '../service/ProcessPageService';
import { getActiveModel, getProcessPageModel } from '../controller/KagamiProcessController';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';

export const buildProcessPageComponent = (processModel: KagamiProcessPageModel, isTodo?: boolean | undefined) => {
  return <KagamiProcessPage key={Math.random().toString()} kagamiProcessModel={processModel} isTodo={isTodo} />;
};
const useStyles = makeStyles(KagamiProcessPageStyle);

const KagamiProcessPage = (props: ProcessModelProps) => {
  const classes = useStyles();
  let processState = useProcessState(createState(props.kagamiProcessModel));
  const activeData =  getActiveModel();
  if(CommonUtils.isNotEmpty(props.kagamiProcessModel) && props.kagamiProcessModel.breadcrumbModel && activeData) {
  props.kagamiProcessModel.breadcrumbModel.activeModel = activeData
  }
  if (processState.processContent == null) {
    props.kagamiProcessModel.loaderModel.loading = true;
  }

  useEffect(() => {
    if (!props.kagamiProcessModel.isLinkProcess) {
      makeInitialProcessContentCall(props.kagamiProcessModel, props.isTodo);
    }
    return () => {
      //clean up
    };
  }, []);

  return processState.processContent == null ? (
    <></>
  ) : (
    <>
      {CommonUtils.isNotEmpty(props.kagamiProcessModel.breadcrumbModel) && props.kagamiProcessModel.breadcrumbModel.showBreadCrumb &&
        buildBreadcrumbs(props.kagamiProcessModel.breadcrumbModel)}

      <div className={classes.mainAppRoot}>{props.kagamiProcessModel.processContent}</div>
    </>
  );
};
