import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiNavigatorStyle = (theme: Theme) =>
  createStyles({
    proSidebar: {
      'willChange': 'width',
      'transition': 'width 0.1s',
      '& .pro-menu': {
        paddingTop: '0',
        paddingBottom: '0'
      },
      '& .MuiSvgIcon-root': {},
      '& .pro-menu .pro-menu-item': {
        'fontSize': '13px',
        '& > .pro-inner-item': {
          padding: '7px !important'
        }
      },
      '& .pro-sidebar-inner': {
        backgroundColor: theme.palette.primary.main
      },
      '& .pro-sidebar-layout::-webkit-scrollbar': {
        display: 'none'
      },
      '& .popper-inner::-webkit-scrollbar': {
        display: 'none'
      },
      '& .pro-inner-list-item > .popper-inner': {
        backgroundColor: theme.palette.primary.main
      },
      '& .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item': {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: 'linear-gradient(to right,#05263d, #13344d)',
        boxShadow: '0 0 0 1px rgba(0, 0, 0, .2)'
      },
      '& .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper': {
        background: 'transparent'
      },
      '& .pro-menu-item > .pro-inner-item > .pro-icon-wrapper': {
        width: '30px!important',
        minWidth: '30px!important',
        height: '30px!important'
      },
      '& .pro-menu-item > .pro-inner-item > .pro-arrow-wrapper': {
        right: '10px!important'
      },
      '& .masterData_ul .react-slidedown ul': {
        paddingTop: '0 !important'
      },
      '& .textBox_masterData': {
        '& > .pro-inner-item': {
          padding: '8px 8px 8px 20px !important'
        }
      },
      '& .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul': {
        padding: '6px 0'
      },
      '& .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item': {
        paddingLeft: '6px'
      },
      '& .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        color: '#bbdcfe',
        marginRight: '10px !important'
      },
      '&:not(.collapsed) .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content': {
        wordBreak: 'break-word'
      },
      '& .MuiCheckbox-root': {
        padding: '0'
      },
      // Update the active item styles
      '& .pro-menu .pro-menu-item.active .pro-inner-item': {
        // backgroundColor: 'rgba(0, 255, 0, 0.2) !important', // Light green background for active item
        color: 'green !important', // Green text color for active item
        fontWeight: '600 !important'
      },
      '& .pro-sub-menu.open .pro-icon': {},
      '& .activeMainMenu': {
        backgroundColor: 'rgba(255,255,255,0.16)',
        padding: '5px',
        borderRadius: '15%',
        boxSizing: 'content-box'
      },
      '& .activeSubMenu': {
        '& > .pro-inner-item:first-child': {
          '& .pro-item-content': {
            color: theme.palette.success.dark + ' !important',
            fontWeight: '600'
          }
        }
      },
      '& .popper-element': {
        display: 'none'
      },
      '& .pro-inner-list-item .pro-icon img': {
        height: '25px',
        width: '25px'
      },
      '& .pro-icon img': {
        height: '30px',
        width: '30px'
      },
      '&.collapsed': {
        'width': '65px',
        'minWidth': '65px',
        '& .popper-inner:has(> ul > .textBox_masterData)': {
          height: '100vh !important'
        },
        '& .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner': {
          backgroundColor: theme.palette.primary.main
        },
        '& .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content': {
          wordBreak: 'normal'
        },
        '& .pro-menu .pro-menu-item': {
          '& > .pro-inner-item': {
            paddingLeft: '15px !important'
          }
        },
        '& .textBox_masterData': {
          'marginLeft': '0',
          '& > .pro-inner-item': {
            padding: '8px 8px 8px 0 !important'
          }
        },
        '& .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item': {
          background: 'transparent'
        },
        '& .popper-element': {
          display: 'block'
        }
      }
    },
    activeItem1: {
      // Ensure this matches the active styles if used
    },
    hamburgerimg: {
      height: theme.spacing(5),
      padding: '0 8px',
      display: 'flex',
      alignItems: 'center'
    },
    hamburgerimgnrml: {
      transform: 'none',
      transition: '0.5s ease-in',
      cursor: 'pointer'
    },
    hamburgerimgrevers: {
      transform: 'scaleX(-1)',
      transition: '0.5s ease-out',
      cursor: 'pointer'
    }
  });
