import * as React from 'react';
import { createState ,useState } from '@hookstate/core';
import {
  AgeingReportModel,
  AgeingReportModelProps,
  useAgeingReportModelState
} from '../model/AgeingReportModel';
import { makeReport,makeLedgerData ,getData} from '../service/AgeingReportService';
import { StaticReportActions } from '../../../../../../staticReportFilters/ui/StaticReportActions';
import { StaticReportGrid } from '../../../../../../staticReportFilters/ui/StaticReportGrid';


export const BuildAgeingReportView = (ageingReportModel: AgeingReportModel) => {
  return (
    <AgeingReportView key={'ageing-report-view'} ageingReportModel={ageingReportModel}></AgeingReportView>
  );
};


const AgeingReportView = (props: AgeingReportModelProps) => {

  let runReportConfig:any = useState({
    loadReport : false,
    loadFilterData:true,
    showLoader:false,
    reportInfo:{}
  });
  let ageingReportModel = props.ageingReportModel;
  let ageingChartState = useAgeingReportModelState(createState(ageingReportModel));
  ageingReportModel.state = ageingChartState;
  let staticFilterData :any;
  if(ageingReportModel?.reportData?.staticFilterData) {
    staticFilterData  = ageingReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo:any ={}
  if(ageingReportModel?.reportData?.gridReportInfo) {
    gridReportInfo  = ageingReportModel.reportData['gridReportInfo'];
  }
  let inputReportConfig= (props.ageingReportModel.reportConfig);
  
  React.useEffect(() => {
    runReport();
  }, [])
  
  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo =  makeReport(ageingReportModel,inputReportConfig);
    let ageingData =  await getData(ageingReportModel,inputReportConfig);
    let rowData = await makeLedgerData(ageingData,inputReportConfig);
    reportInfo['rowData'] = rowData;
    const obj = Object.assign({}, reportInfo);
    props.ageingReportModel.reportData['gridReportInfo'] = obj;
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }
  let config = {reportModel : ageingReportModel, inputReportConfig ,gridReportInfo, runReportConfig};
  return (
    <div className="Report-MuiFormControl-root" style={{width:'100%' ,background: '#fff',padding: '10px'}}>
      <StaticReportActions config={config} ></StaticReportActions>
      <StaticReportGrid config={config}></StaticReportGrid>
      </div>
  );
};
