import {getStaticFilterData,getLedgerReportData,getStaticFilterInfo} from '../../../../service/KagamiReportService';
import {getReportType, getValueInDateTimeFormatAsAppSetting} from '../../../../service/ReportUtilService';
import PDFDownload  from '../../../../service/exports/PDFService';
import ExcelDownload from '../../../../service/exports/ExcelService';
import extend from 'lodash/extend';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import constant from 'lodash/constant';
import times from 'lodash/times';

export async function getDBStaticFilterData(ageingReportController: any) {
    let reportType =  getReportType(ageingReportController.reportConfig);
    return await getStaticFilterData(reportType);
}
export const makeReport = (ageingReportController: any,inputReportConfig:any) => {
        let defaultConfig ={
          autoGroupColumnDef: {  width: 200,cellRendererParams: {suppressCount: true,checkbox: false}},    
          columnDefs: [],
          defaultColDef: { flex :1,sortable: false,minWidth: 150,filter: true,resizable: true,editable: false },
          detailCellRendererParams: {
            detailGridOptions: {
              columnDefs: [  ],
              defaultColDef: {
                flex: 1,
              },
            },
            getDetailRowData: (params:any) => {
              params.successCallback(params.data.nestedFinanceRecord);
            },
          },
          detailRowHeight: 190,
          groupDefaultExpanded: -1,//expand everything by default
          groupHideOpenParents:true,
          groupIncludeTotalFooter: false,
          getRowStyle: (params:any) => {
            if (params?.node?.rowPinned) {
                return {'font-weight': 'bold'}
            }
          },
          getRowHeight :(params: any) => 30,
          rowHeight:30,
          icons: {
            // groupExpanded:'<i class="fa fa-arrow-down"/>',
            // groupContracted:'<i class="fa fa-user"/>'
            groupContracted:'<div class="kgm-plus-icon radius"></div>',
            groupExpanded:'<div class="kgm-minus-icon radius"></div>'
          },
          masterDetail: true,
          onFirstDataRendered: (params:any) => {
            setTimeout(function () {
              params.api.getDisplayedRowAtIndex(1).setExpanded(true);
            }, 0);
          },
          pivotMode : false,
          pagination: true,
          paginationPageSize: 50,
          pinnedBottomRowData: [],
          rowData:[],
          suppressAggFuncInHeader: true, //hide aggregate name in a row 
        };
        let userConfig = prapareUserConfigForReport(ageingReportController,defaultConfig,inputReportConfig);  
        let reportConfig =  extend(true, {}, defaultConfig, userConfig);
        return reportConfig;

} 


function prapareUserConfigForReport (reportController: any,defaultConfig:any,inputReportConfig:any) {
    let translation = reportController.languageTranslation || {}; 
    let userConfig:any = {
      columnDefs: [],
      rowData: [],
    };
    let columnDefs: any = [];
    columnDefs = [
      { headerName: translation['Reptype'] || 'Reptype', field: 'name' },
      { headerName: translation['No of Invoices'] || 'No of Invoices', field: 'code' },
      { headerName: translation['Amount'] ||  'Amount', field: 'balance' }
    ];
    let dynCols =  getDynamicColumns(inputReportConfig);
    columnDefs = columnDefs.concat(dynCols);
    userConfig['columnDefs'] = columnDefs;
    return userConfig;
  }
  
  const getDynamicColumns = (inputReportConfig: any) => {
    if (inputReportConfig?.fromto || inputReportConfig?.leftPanel?.fromto ) {
        let fromTo = inputReportConfig.fromto || inputReportConfig?.leftPanel?.fromto ;
        let fromToArr = [];
         forEach(fromTo,function(rec) {
            fromToArr.push({headerName: rec, field: rec.split("-").join("")+"val" });
        });
        fromToArr.push({headerName: fromTo[fromTo.length-1]+" Above", field:fromTo[fromTo.length-1].split("-").join("")+"above_val"});
        return fromToArr;
    }
    return [];
  };

    export function makeLedgerData(dbReportData: any,inputReportConfig:any) {
    return getReportData( cloneDeep(dbReportData), '',inputReportConfig) || [];
    }
    export const getData = async (ageingReportModel:any,inputReportConfig: any) => {
      if (inputReportConfig?.fromto) {
        inputReportConfig['leftPanel'] ={};
        inputReportConfig['leftPanel']['fromto'] = inputReportConfig.fromto;
        inputReportConfig['leftPanel']['endDate'] = inputReportConfig.asonDate ||  getEndDate();
        inputReportConfig['leftPanel']['startDate'] = null;
      }
      if(!inputReportConfig.selectedValue) {
        inputReportConfig['selectedValue'] = null;
      }
      /*
      inputReportConfig["currencyList"] = [];
      if(ageingReportModel?.reportData?.staticFilterData?.data?.accountResult) {
        delete inputReportConfig.agingPeriod;
        delete inputReportConfig.fromto;
        delete inputReportConfig.ktreeReport;
        delete inputReportConfig.template;
        delete inputReportConfig.dynamicParameterList;
        inputReportConfig.generalLedgerList =  ageingReportModel.reportData.staticFilterData.data.accountResult;
        
      }
      if(inputReportConfig?.reportConfigSummary) {
        inputReportConfig.reportConfigSummary.type =  inputReportConfig.reportConfigSummary.name;
        delete inputReportConfig.reportConfigSummary.template;
      }
      */
      if(ageingReportModel?.reportData?.staticFilterData?.data?.accountResult) {
        inputReportConfig.generalLedgerList =  ageingReportModel.reportData.staticFilterData.data.accountResult;
      }

      let res = await getLedgerReportData(inputReportConfig);
      if (res?.data) {
        return res.data['financeReportRow'];
      }
      return [];
    };

  const customCreditOrDebitCellFormatter =  ( cellvalue:any,currency:any) => {
    if(cellvalue){
     let locale  = (currency && currency =='INR') ? 'en-IN' :'en-US';
     let changedVal =parseFloat(cellvalue).toLocaleString(locale);
     if(cellvalue.split(".").length>0 && cellvalue.split(".")[1]=="00"){
        changedVal +=".00";
     }
     return changedVal;
    }
    return "";
  }

  const getReportData = (financeReportRow:any,currency:any,inputReportConfig:any) => {
     forEach(financeReportRow, function (reportRow) {
      if (reportRow.balance) {
          let withCommas = customCreditOrDebitCellFormatter(reportRow.balance,currency);
          reportRow.balance = currency + " " + withCommas;

          if(reportRow.balanceType){
          reportRow.balance = currency + " " + withCommas +" "+reportRow.balanceType;
          }
      }
      if (reportRow.date) {
            reportRow.date = reportRow.date.split(" ")[0];
      }
      makeAgingData(reportRow,inputReportConfig);
    })
    return financeReportRow || [];
  }
   
  const makeAgingData = (reportRow: any, inputReportConfig: any) => {
    if (reportRow?.fromto) {
      let dynCols = getDynamicColumns(inputReportConfig);
      if (dynCols && dynCols.length) {
         forEach(dynCols, function (rec, index) {
          reportRow[rec.field] = reportRow.fromto[index];
        });
      }
    }
  };

  export const onChangeAccount = async (account:any,ageingReportController:any) => {
    let reportType =  getReportType(ageingReportController.reportConfig);
    if(account && account.length >1 ){
      account =  account.split("-")[1];
    }   
    if(reportType && account && account.length >0 && reportType == 'Vendor Ledger') {
        let QueryParam='';
        QueryParam = QueryParam.concat("ledgerType=" + reportType +'&');
        QueryParam = QueryParam.concat("dependant="  + account);
        let staticData = await getStaticFilterInfo(QueryParam);
        if (staticData?.data) {
          return  cloneDeep(staticData.data.gst || []);
        }
    }
    return [];
} 

  export const onChangeState = async (state:any,ageingReportController:any) => {
    let reportType =  getReportType(ageingReportController.reportConfig);
    if(reportType && state && state.length >0) {
        let QueryParam='';
        QueryParam = QueryParam.concat("ledgerType=" + reportType +'&');
        QueryParam = QueryParam.concat("dependant=" + "state|" + state.join(","));
        let staticData = await getStaticFilterInfo(QueryParam);
        if (staticData?.data) {
          return  cloneDeep(staticData.data.projectPlan || []);
        }
    }
    return [];
}


/* FOR FOOTER */
const displayOpeningBalance = (type:any,financeReportRow:any) => {
  if(financeReportRow && financeReportRow.length > 0){
      let value= financeReportRow[0];
      return type =='CREDIT'  ? (customCreditOrDebitCellFormatter(value.credit,'')): (customCreditOrDebitCellFormatter(value.debit,''));
  }   
  return "";
}

 const displayClosingBalance = (type:any,financeReportRow:any ) => {
  if(financeReportRow && financeReportRow.length > 0){
      let value= financeReportRow[financeReportRow.length-1];
     if(type=='CREDIT') {
          if(value.balanceType=="Cr" && value.balance){
              return value.balance +" Cr";
          }else { return ""};
     }else if(type=='DEBIT'){
        if(value.balanceType=="Dr" && value.balance){
          return value.balance +" Dr";
         }else { return ""};
    }
  }   
  return "";
}
const getAggregationValue = (type: any, financeReportRow: any) => {
  if (financeReportRow && financeReportRow.length > 0) {
    let totalVal = 0;
     forEach(financeReportRow, function (rec) {
      if (type == 'CREDIT' && rec.credit) {
        totalVal += parseFloat(rec.credit);
      } else if (type == 'DEBIT' && rec.debit) {
        totalVal += parseFloat(rec.debit);
      }
    });
    if (totalVal) {
      return customCreditOrDebitCellFormatter(""+totalVal, '');
    }
  }
  return '';
};
const getEndDate = () => {
  let eD = new Date();
  let enddate = eD.getFullYear() + '-' + ('0' + (eD.getMonth() + 1)).slice(-2) + '-' + ('0' + eD.getDate()).slice(-2);
  return enddate + ' 23:59:59';
};

/* END  FOR FOOTER */

/** EXCEL DOWNLOAD **/
export const downloadExcel = (reportModel:any) => {
  let gridInfo:any = {};
  if(reportModel?.reportData?.gridReportInfo) {
    gridInfo =reportModel.reportData.gridReportInfo;
  }
  
  let configInfo:any = {
    alternateRowColor:true,
    defaultColumnWidth:20,
    fileName: 'Report',
    rowData:  cloneDeep(gridInfo['rowData']) || [],
    excelTableRows:  cloneDeep(gridInfo['rowData']) || [],
    reportColumns : gridInfo.columnDefs, 
    exportableColumnDefinitions : gridInfo.columnDefs, //grid exportable column definitions
  }
  const excelLib =  ExcelDownload(configInfo);
  excelLib.downLoad();
}

/** END EXCEL DOWNLOAD **/
/** PDF DOWNLOAD **/
export const downloadPDF = (reportModel:any) => {
  let gridInfo:any = {};
  if(reportModel?.reportData?.gridReportInfo) {
    gridInfo =reportModel.reportData.gridReportInfo;
  }
  let columnsInfo = getPdfTableColumns(gridInfo);
  let configInfo:any  = {
    pageOrientation:'potrait',
    fileName: 'Report',
    rowData:  cloneDeep(gridInfo['rowData']) || [],
    pdfHeaderInfo :getPdfHeaderInfo(reportModel.reportConfig),
    pdfTableColumns : columnsInfo.exportableColumns,
    exportableColumnDefinitions : columnsInfo.columnsList, //grid exportable column definitions
    pdfTableColumnWidths : columnsInfo.widths,

  }
  configInfo['pdfTableRows'] =  makePdfData(configInfo,gridInfo);
  const pdfLib =  PDFDownload(configInfo);
  pdfLib.downLoad();
}

const getPdfTableColumns = (reportConfig: any) => {
  let exportableColumns: any = [];
  let columnsList:any = [];
  if (reportConfig.columnDefs && reportConfig.columnDefs.length > 0) {
     forEach(reportConfig.columnDefs, (col: any) => {
      let headerCol: any = {
        text: col.headerName,
        style: 'tableHeader'
      };
        exportableColumns.push(headerCol);
        columnsList.push(col);
    });
  }
  return {
    exportableColumns: exportableColumns || [],
    columnsList: columnsList || [],
    widths:  times(columnsList.length,  constant(100/columnsList.length+"%")) || []
  };
};

const getPdfHeaderInfo = (reportConfig:any) => {
  let headerConfig:any = {};
  if(reportConfig) {
    headerConfig['reportType'] =  getReportType(reportConfig) || '';
    headerConfig['subOrganization'] =reportConfig.selectedValue || ''; 
    headerConfig['subTitle'] =   getFilterSelectedHeader(reportConfig) || '';
    headerConfig['exportedDate'] = getValueInDateTimeFormatAsAppSetting(new Date(), 'date') || ''; 
  }
  return headerConfig;
}

const makePdfData =(configInfo:any,reportConfig:any) => {
  let rowsData:any = [];
  if(configInfo.exportableColumnDefinitions && configInfo.exportableColumnDefinitions.length > 0 && reportConfig.rowData && reportConfig.rowData.length > 0) {
     forEach(reportConfig.rowData,(rec)=>{
      let _row:any = []
       forEach(configInfo.exportableColumnDefinitions,(col)=>{
        _row.push({text:rec[col.field] || ''});
      });
      rowsData.push(_row);
    })
  }
  if(rowsData)
  return rowsData;
}
 
const getFilterSelectedHeader = (reportConfig: any) => {
  let filterValue = '';
  if (reportConfig.subOrganization) {
    filterValue = filterValue.concat("SubOrganization :" + reportConfig.subOrganization[0]);
  }
  if (reportConfig.selectedValue) {
    filterValue = filterValue.concat(" Account :" + reportConfig.selectedValue);
  }
  if (reportConfig.selectedCurrency) {
    filterValue = filterValue.concat(" Currency :" + reportConfig.selectedCurrency);
  }
  return filterValue || '';
}


/** END PDF DOWNLOAD **/
