import { createState } from '@hookstate/core';
import makeStyles from '@mui/styles/makeStyles';
import { CellValueChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { delay } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { buildActivityLogModal } from '../../../../../../../../../../../../components/activityLog/controller/KagamiActivityLogModalController';
import { KagamiActivityLogModel } from '../../../../../../../../../../../../components/activityLog/model/KagamiActivityLogModel';
import { buildModal } from '../../../../../../../../../../../../components/modal/controller/KagamiModalController';
import { KagamiModalModel } from '../../../../../../../../../../../../components/modal/model/KagamiModalModel';
import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import { executeGridUiPolicy, executePoliciesForNestedOnMounting } from '../../../../../service/PolicyExecutionService';
import { editRecord } from '../../../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/controller/KagamiEmbeddedAccordionPresentationController';
import { BuildNonFieldPresentations } from '../../../../form/builder/NonFieldPresentationBuilder';
import { buildFormPage, getFormModel, showValidationError } from '../../../../form/controller/KagamiFormController';
import { KagamiEmbeddedFormPresentationModel } from '../../../../form/model/KagamiEmbeddedFormPresentationModel';
import { KagamiFormModel } from '../../../../form/model/KagamiFormModel';
import { getActiveContext, handleGridAutoSizeColumns, updateInFocusMap } from '../../../../form/service/FormService';
import { validate } from '../../../../form/service/FormValidationService';
import { disableAllTheTriggers, reBuildAllTriggers } from '../../../../Triggers/service/KagamiTriggersService';
import {
  getKagamiListModel,
  handleTriggerClick,
  reBuildTriggers,
  setKagamiListModel
} from '../../../controller/KagamiListController';
import { onNestedEmbedOpened, retrieveListRows } from '../../../service/kagamiListService';
import { agGridColumns, BuildEmbedModalForList } from '../../ListBuilder';
import ActionCellRenderer from '../builder/cell_renderers/ActionCellRenderer';
import CheckboxCellRenderer from '../builder/cell_renderers/CheckboxCellRenderer';
import DeleteCellRenderer from '../builder/cell_renderers/DeleteCellRenderer';
import EditRecordCellRenderer from '../builder/cell_renderers/EditRecordCellRenderer';
import FloatingActionCellRenderer from '../builder/cell_renderers/FloatingActionCellRenderer';
import HeaderSearchRenderer from '../builder/cell_renderers/HeaderSearchCellRenderer';
import InFormCellRenderer from '../builder/cell_renderers/InFormCellRenderer';
import KagamiActivityLogCellRenderer from '../builder/cell_renderers/KagamiActivityLogCellRenderer';
import KagamiImageCellRenderer from '../builder/cell_renderers/KagamiImageRenderer';
import KagamiNumberCellRenderer from '../builder/cell_renderers/KagamiNumberCellRenderer';
import KagamiStatusCellRenderer from '../builder/cell_renderers/KagamiStatusCellRenderer';
import KagamiTextCellRenderer from '../builder/cell_renderers/KagamiTextCellRenderer';
import ListEmbedFieldRenderer from '../builder/cell_renderers/ListEmbedFieldRenderer';
import NestedEmbedPresentationsRenderer, {
  cloneAndPushNestedModelsToFormControls
} from '../builder/cell_renderers/NestedEmbedPresentationsRenderer';
import KagamiThumbnailCellRenderer from '../builder/cell_renderers/ThumbnailCellRenderer';
import AddButtonRenderer from '../builder/custom/AddButtonRenderer';
import DateEditor from '../builder/editors/dateEditor';
import KagamiThumbnailCellEditor from '../builder/editors/KagamiThumbnailCellEditor';
import MultiSelectEditor from '../builder/editors/multiSelectEditor';
import PhoneNumberEditor from '../builder/editors/PhoneNumberEditor';
import RadioEditor from '../builder/editors/RadioEditor';
import SearchEditor from '../builder/editors/SearchEditor';
import SelectEditor from '../builder/editors/SelectEditor';
import { KagamiGridModel, KagamiGridProps, useKagamiGridState } from '../model/KagamiGridModel';
import { fetchDataOnScroll, getActiveRowNode } from '../service/GridService';
import { getFirstEditableColumn, handleGridCellData } from '../service/GridUtilService';
import { isPreviewColumn } from '../service/ProcessHelperService';
import { KagamiGridStyle } from '../style/KagamiGridStyle';
import { getHomeModel } from '../../../../../../../../../controller/KagamiHomeController';
import TotalRecordStatusBarComponent from '../builder/custom/TotalRecordStatus';
import KagamiBarCodeCellRenderer from '../builder/cell_renderers/KagamiBarCodeCellRenderer';
import { buildPagination } from '../builder/pagination/controller/KagamiGridPaginationController';
import { DomLayoutType } from 'ag-grid-community';
import { GridApi } from 'ag-grid-community';

export const BuildGrid = (kagamiGridModel: KagamiGridModel) => {
  return (
    <Grid key={kagamiGridModel.processName + '_' + kagamiGridModel.mainEntityId} kagamiGridModel={kagamiGridModel} />
  );
};

const useStyles = makeStyles(KagamiGridStyle);

export const Grid = (props: KagamiGridProps) => {
  let kagamiGridModel = props.kagamiGridModel;
  let classes = useStyles();
  let listState = useKagamiGridState(createState(kagamiGridModel), props.kagamiGridModel.mainEntityId);
  kagamiGridModel.state = listState;
  /// Note : not setting the list model if it is a list embed pop up grid
  if (!props.kagamiGridModel.isListEmbedPopModel) setKagamiListModel(kagamiGridModel);
  let rows: any = [];
  let activityLogModel: KagamiActivityLogModel = new KagamiActivityLogModel();

  if (listState.getListLoaded()) {
    rows = retrieveListRows(kagamiGridModel);
    // kagamiGridModel.totalRecords = kagamiGridModel.totalRecords === 0 ? rows.length : kagamiGridModel.totalRecords;
    /// Note : disbaling triggers for non field button list if there are no records
    if (kagamiGridModel.isPickItemListModel && rows.length === 0) {
      disableAllTheTriggers(kagamiGridModel.triggersModel);
    }
  }

  return (
    <>
      {buildListTableView(classes, kagamiGridModel, activityLogModel, rows)}

      {/* {(
        rows.length === 1
        && !props.kagamiGridModel.isEmbedModel
        && !props.kagamiGridModel.isListEmbedPopModel)
        ? buildListDetailView(classes, kagamiGridModel, activityLogModel, rows)
        : buildListTableView(classes, kagamiGridModel, activityLogModel, rows)
      } */}
    </>
  );
};

// Grid.whyDidYouRender = true;

function buildListDetailView(classes: any, kagamiGridModel: any, activityLogModel: any, rows: any[]) {
  // KagamiStateManager.getStateToHolder(SystemConstants.GRID_LIST_FILTER_STATE).setShowFilters(false);

  return (
    <>
      {/* random key for rerendering the component evrey time it is called */}

      <ListDetailViewComponent
        key={Math.random()}
        classes={classes}
        kagamiGridModel={kagamiGridModel}
        activityLogModel={activityLogModel}
        rows={rows}
      />
    </>
  );
}
function buildListTableView(classes: any, kagamiGridModel: any, activityLogModel: any, rows: any) {
  // if (!kagamiGridModel.isEmbedModel) {
  //   KagamiStateManager.getStateToHolder(SystemConstants.GRID_LIST_FILTER_STATE).setShowFilters(true);
  // }

  return (
    <>
      <ListTableViewComponent
        key={kagamiGridModel.isPivot ? Math.random().toString() : 'ListTableComponent_' + kagamiGridModel.mainEntityId}
        classes={classes}
        kagamiGridModel={kagamiGridModel}
        activityLogModel={activityLogModel}
        Nrows={rows}
      />
    </>
  );
}

function ListTableViewComponent(props: any) {
  const { classes, kagamiGridModel, activityLogModel, Nrows } = props;

  const recordsLength = () => {
    let lengthofRecords: any;
    if (kagamiGridModel?.onStartData?.constructOutputData?.uiResource?.uiTemplate === 'list') {
      lengthofRecords = kagamiGridModel?.onStartData?.constructOutputData?.verbProperties?.totalRecords;
      return lengthofRecords;
    }
  };

  return (
    <>
      <div className={classes.root}>
        <BuildEmbedModalForList key="embedModal" kagamiGridModel={kagamiGridModel} />
        <div className={classes.grid_with_nonfieldPresentaation}>
          <div style={{ display: 'flex' }}>
            {!kagamiGridModel.isEmbedModel ? (
              <BuildNonFieldPresentations
                key="nonFieldPresentation"
                kagamiGridModel={kagamiGridModel}
                isPickAttributeButtonVisible={false}
              />
            ) : null}
            {/* {kagamiGridModel.presentation.uiTemplate !== 'list' && buildSpreadSheet(kagamiGridModel.kagamiSpreadSheetModal)}
            {kagamiGridModel.presentation.uiTemplate !== 'list' &&
            <button 
            type="button"
              onClick={() =>{
                kagamiGridModel.kagamiSpreadSheetModal.kagamiModalModel.state.setOpen(true)
             }}>Spread sheet</button>
            } */}
          </div>

          {AgGrid(kagamiGridModel, Nrows, kagamiGridModel.kagamiModalModel, activityLogModel)}
          {/* <>{!props.kagamiGridModel.isEmbedModel ? 
            <p >Total Records: {recordsLength()} </p> : ''} 
            </> */}
        </div>
        {buildModal(kagamiGridModel.kagamiModalModel)}
        {buildActivityLogModal(activityLogModel)}
      </div>
    </>
  );
}

function ListDetailViewComponent(props: any) {
  const { classes, kagamiGridModel, activityLogModel, rows } = props;

  React.useEffect(() => {
    reBuildAllTriggers(kagamiGridModel.triggersModel, rows, true);

    return () => {
      kagamiGridModel.selectedObjects = [];
      reBuildAllTriggers(kagamiGridModel.triggersModel, []);
    };
  }, []);

  const kagamiFormModel: KagamiFormModel = new KagamiFormModel(
    CommonUtils.isNotEmpty(kagamiGridModel.onStartData) ? kagamiGridModel.onStartData : {},
    kagamiGridModel.mainEntityId,
    kagamiGridModel.processName,
    kagamiGridModel.processStepName,
    'pChild'
  );

  kagamiGridModel.detailedObjects instanceof Array
    ? kagamiGridModel.onStartData
    : (kagamiFormModel.onStartData['constructOutputData']['detailedObjects'][kagamiFormModel.mainEntityId] =
        kagamiGridModel.detailedObjects[kagamiGridModel.mainEntityId]);

  kagamiFormModel.isSingleListView = true;
  kagamiFormModel.pickItemDetail = kagamiGridModel.isPickItemListModel;
  kagamiFormModel.readOnly = true;
  kagamiFormModel.triggersModel.isDetailView = kagamiFormModel.readOnly;
  kagamiFormModel.inFocusFormDataIdentifiers[kagamiGridModel.mainEntityId] = 'tempId';
  kagamiGridModel.selectedObjects = rows;

  return (
    <>
      <div className={classes.root}>
        <BuildEmbedModalForList key="embedModal" kagamiGridModel={kagamiGridModel} />
        <div className={classes.grid_with_nonfieldPresentaation}>
          <BuildNonFieldPresentations
            key="nonFieldPresentation"
            kagamiGridModel={kagamiGridModel}
            isListDetail={true}
            isPickAttributeButtonVisible={false}
          />
          {/* {buildFormPage(kagamiFormModel)} */}
          <div style={{ cursor: kagamiGridModel.hasHyperlink ? 'pointer' : 'default' }}>
            {buildFormPage(kagamiFormModel)}
          </div>
        </div>
        {buildModal(kagamiGridModel.kagamiModalModel)}
        {buildActivityLogModal(activityLogModel)}
      </div>
    </>
  );
}

function AgGrid(
  kagamiGridModel: KagamiGridModel,
  rowData: any[],
  popupModel: KagamiModalModel,
  activityLogModel: KagamiActivityLogModel
) {
  import('ag-grid-enterprise');
  let classes = useStyles();

  const isRowMaster = useCallback((dataRecord: any) => {
    let nestedEmbedModels: any[] = kagamiGridModel.nestedEmbedModels;
    if (CommonUtils.isNotEmpty(nestedEmbedModels)) {
      let clonedNestedModels: any[] = cloneAndPushNestedModelsToFormControls(nestedEmbedModels, dataRecord);
      executePoliciesForNestedOnMounting(clonedNestedModels, dataRecord);
      let visibleNestedModels = clonedNestedModels.filter((model: any) => model.visible === true);
      return visibleNestedModels.length > 0;
    }
    return kagamiGridModel.isMasterDetailEnabled ? kagamiGridModel.isMasterDetailEnabled : false;
  }, []);

  const SelectionChanged = useCallback(() => {
    const selectedObjects: any[] = [];
    kagamiGridModel.gridApi.getSelectedNodes().forEach((node: any) => {
      console.log(node.data);
      selectedObjects.push(node.data);
    });
    kagamiGridModel.selectedObjects = selectedObjects;
    reBuildTriggers();
  }, []);

  const onGridReady = useCallback((params: any) => {
    kagamiGridModel.gridApi = params.api;
    kagamiGridModel.gridColumnApi = params.columnApi;
    kagamiGridModel.skipValidate = false;

    if (kagamiGridModel.isEditableEmbedList) {
      let formModel: KagamiFormModel = getFormModel();
      let activeContext = getActiveContext(formModel, kagamiGridModel.mainEntityId);

      // POV:
      // In Ved - salesOrder embed grid data was not displayed 
      // Because of KagamiGridModel Data for embeded is not updated properly In the formPresentationModel.kagamiGridModels.get(kagamiGridModel.mainEntityId)
      // But here in kagamiGridModel we are getting whole data
      // so i am assining it again here.
      let formPresentationModel = formModel.formControls[kagamiGridModel.mainEntityId];
      if (CommonUtils.isNotEmpty(formPresentationModel.kagamiGridModels.get(kagamiGridModel.mainEntityId).presentation) && 
          CommonUtils.isNotEmpty(kagamiGridModel.presentation) &&
          formPresentationModel.kagamiGridModels.get(kagamiGridModel.mainEntityId).presentation.presentationId === 
          kagamiGridModel.presentation.presentationId
        ) {
        formPresentationModel.kagamiGridModels.set(kagamiGridModel.mainEntityId, kagamiGridModel);
      }
      executeGridUiPolicy(activeContext, formPresentationModel);
    }
    // TODO FLOAT ACTIONS BY DEFAULT FOR EMBED
    if (kagamiGridModel.isEditableEmbedList) {
      // handleFloatingActions(params,kagamiGridModel,true);
    }
  }, []);

  const onRowDataUpdated = useCallback((params: any) => {
    if (kagamiGridModel.isAutoAdjust && kagamiGridModel.textWidth && kagamiGridModel.textWidth.length > 0) {
      /// Note : commented this because its causing the text overlap issue, need to look for a permanent solution
      /// to make it work in both overlap and auto size scenarios
      let columnSizeArr = handleGridAutoSizeColumns(kagamiGridModel);
      params.api.gridBodyCtrl.sizeColumnsToFit({
        defaultMinWidth: 100,
        // columnLimits: kagamiGridModel.textWidth
        columnLimits: columnSizeArr || kagamiGridModel.textWidth
      });

      delay(() => {
        kagamiGridModel.skipValidate = false;
      }, 150);
      kagamiGridModel.isAutoAdjust = false;
    }
    console.log('rows are auto adjusted');
  }, []);

  let newHeight: any;

  const getRowHeight = React.useCallback(
    (params: any) => {
      if (
        params.data &&
        params.data.type === 'multiselect' && // dummy condition should change
        newHeight != null
      ) {
        return newHeight;
      } else {
        return 30;
      }
    },
    [newHeight]
  );

  let sort: boolean = false;

  const defaultColDef = useMemo<any>(() => {
    return {
      sortable: true,
      suppressFilter: true,
      resizable: true,
      suppressMenuHide: true,
      suppressMenu: true,
      editable: kagamiGridModel.isEditableEmbedList,
      lockVisible: true
    };
  }, []);

  const setnewHeight = React.useCallback((height: any) => {
    // this is used next time resetRowHeights is called
    newHeight = height;
    kagamiGridModel.gridApi.forEachNode(function (rowNode: any) {
      rowNode?.setRowHeight(30);
      kagamiGridModel.activeRowNode?.setRowHeight(height);
    });
    kagamiGridModel.gridApi.onRowHeightChanged();
  }, []);

  const onCellClicked = useCallback((e: any) => {
    if (kagamiGridModel.presentation.uiTemplate == 'list') {
      if (isPreviewColumn('event', e)) {
        //logic for preview popup
        console.log('popUp');
      } else {
        //check hyper link exists
        console.log('detail');
        if (kagamiGridModel.hasHyperlink) {
          let triggerModel =
            kagamiGridModel.triggersModel.triggerControls['HYPERLINK'] ||
            kagamiGridModel.triggersModel.triggerControls['hyperlink'];
          kagamiGridModel.selectedObjects = [e.data];
          handleTriggerClick(triggerModel);
        }
      }
    }
  }, []);

  const onCellKeyDown = useCallback((params: any) => {
    handleGridCellData(params);
  }, []);

  const statusBar: any = useMemo(() => {
    const actionsMap = kagamiGridModel.actions;
    const addAction = actionsMap && actionsMap.get('add');  
    if (addAction) {
      return {
        statusPanels: [
          {
            statusPanel: 'AddButtonRenderer',
            align: 'left',
            statusPanelParams: {
              gridModel: kagamiGridModel
            }
          }
        ]
      };
    } else return undefined;
  }, []);

  let applyDom: 'normal' | 'autoHeight' | 'print' | undefined = 'normal';
  function gridBodyStyling() {
    let homeModel = getHomeModel();
    if (kagamiGridModel.isEmbedModel) {
      applyDom = 'autoHeight';
    } else if (kagamiGridModel.isGroupListModel) {
      applyDom = 'autoHeight';
      if (rowData.length > 23) {
        applyDom = 'normal';
      }
    } else if (kagamiGridModel.isPickItemListModel) {
      applyDom = 'autoHeight';
    }
    else if (kagamiGridModel.paginationForList) {
      applyDom = 'normal';
    } else if (kagamiGridModel.isListEmbedPopModel) {
      applyDom = 'autoHeight';
      if (rowData.length > 23) {
        applyDom = 'autoHeight';
      }
    } else {
      applyDom = 'autoHeight';
      if (
        homeModel?.modalDialogModel?.numberofListRecords > 15 &&
        homeModel?.modalDialogModel?.numberofListRecords < 23
      ) {
        applyDom = 'normal';
      } else if (rowData.length > 23) {
        applyDom = 'normal';
      }
    }

    return applyDom;
  }

  const rowClass = kagamiGridModel.hasHyperlink ? 'ag-blue' : 'ag-black';

  const NoRows = () => {
    return (
      <div
        style={{
          textAlign: 'center'
        }}
      >
        ---
      </div>
    );
  };

  const getParams: () => any = () => ({
    processCellCallback(params: any): string {
      const value = params.value;
      return value === undefined ? '' : `${value}`;
    }
  });

  const defaultExcelExportParams = useMemo<any>(() => {
    return getParams();
  }, []);

  gridBodyStyling();
  let columnDefs: any = [];
  columnDefs = agGridColumns(
    kagamiGridModel.gridControls,
    kagamiGridModel,
    popupModel,
    activityLogModel,
    setnewHeight,
    rowData,
    sort
  );
  return (
    <div
      style={{ height: '100%', width: '100%', overflow: "hidden" }}
      // **** Note:grid is strucking, so uncommented this.
      // className={`ag-theme-alpine kgm-grid ${kagamiGridModel.mainEntityId}`} // ${kagamiGridModel.isEmbedModel && rowData.length > 15 && classes.embedGrid}
      className={`ag-theme-alpine kgm-grid ${kagamiGridModel.paginationForList && 'kgm_pagination_list'} ${kagamiGridModel.mainEntityId} ${
        kagamiGridModel.isEmbedModel && classes.embedGrid
      }`}
    >
      <AgGridReact
        enableCellTextSelection={true}
        onDisplayedColumnsChanged={(event: any) => {
          /// Note : making skip vaidate to true if its been called from policies stopEditing or api AutoResize
          kagamiGridModel.skipValidate = true;
        }}
        suppressDragLeaveHidesColumns={true}
        suppressContextMenu={true}
        onSelectionChanged={SelectionChanged}
        suppressRowClickSelection
        rowData={rowData}
        animateRows={true}
        masterDetail={true}
        domLayout={applyDom}
        onCellKeyDown={onCellKeyDown}
        noRowsOverlayComponent={NoRows}
        tooltipShowDelay={0}
        singleClickEdit={false}
        suppressAutoSize
        stopEditingWhenCellsLoseFocus={false}
        detailCellRenderer="nestedEmbedsRenderer"
        detailCellRendererParams={{ nestedEmbedModels: kagamiGridModel.nestedEmbedModels }}
        enableRangeSelection={true}
        allowContextMenuWithControlKey={true}
        rowSelection={kagamiGridModel.isEditableEmbedList ? 'single' : 'multiple'}
        detailRowAutoHeight={true}
        preventDefaultOnContextMenu={true}
        onCellDoubleClicked={onCellClicked}
        onGridReady={onGridReady}
        onRowDataUpdated={onRowDataUpdated}
        groupIncludeTotalFooter={kagamiGridModel.isPivot ? true : false}
        defaultExcelExportParams={defaultExcelExportParams}
        isRowMaster={isRowMaster}
        suppressColumnVirtualisation={true}
        onRowGroupOpened={useCallback((params: any) => onNestedEmbedOpened(params, kagamiGridModel), [])}
        getRowHeight={getRowHeight}
        statusBar={statusBar}
        editType={'fullRow'}
        suppressScrollOnNewData
        onSortChanged={(e) => {
          sort = true;
          // e.api.forEachNode((rowNode: any, index: number) => (rowNode.rowIndex = index));
          e.api.refreshCells();
        }}
        onBodyScrollEnd = {useCallback((event: any) => {
          if (
            event.direction !== 'horizontal' && 
            kagamiGridModel?.paginationModel?.totalRecords > 0 && 
            kagamiGridModel?.paginationModel?.totalRecords > kagamiGridModel?.paginationModel?.pageSize
          ) {
            if (kagamiGridModel?.isEmbedModel === false && !kagamiGridModel?.paginationForList) {
              fetchDataOnScroll(kagamiGridModel, event);
            } else if (kagamiGridModel?.isEmbedModel === true && !kagamiGridModel?.paginationForEmbedForm) {
              fetchDataOnScroll(kagamiGridModel, event);
            } else {
              return null;
            }
          }
        }, [kagamiGridModel])
      }
        onRowEditingStopped={(event: any) => {
          console.log('row editing stopped');
          /// Note : using skipValidate flag to decide whether validations has to be executed or not, if row editing has been stopped for policy execution
          /// we should avoid executing validations
          if (!kagamiGridModel.skipValidate) {
            if (
              kagamiGridModel.isEditableEmbedList &&
              CommonUtils.isNotEmpty(kagamiGridModel.activeRowNode) &&
              event.node.data.id === kagamiGridModel.activeRowNode.data.id
            ) {
              let formModel: KagamiFormModel = getFormModel();
              let embedModel: KagamiEmbeddedFormPresentationModel =
                formModel.formControls[kagamiGridModel.mainEntityId];
              if (embedModel.isNested) {
                /// Note : Incase of inline we need the context nested model with id and in parent embed popup we need
                /// just the embed model. To cover this 2 scenarios we are doing the following
                let parentEmbedModel: KagamiEmbeddedFormPresentationModel =
                  formModel.formControls[embedModel.parentEmbedEntityId];
                if (!parentEmbedModel.isActiveForm) {
                  embedModel =
                    formModel.formControls[
                      kagamiGridModel.mainEntityId + '.' + kagamiGridModel.parentEmbedIdForNestedEmbed
                    ];
                }
              }
              let validations = validate(formModel, embedModel.formEntityId, true);
              if (validations.size === 0) {
                console.log('no validations');
                kagamiGridModel.activeRowNode = null;
              } else {
                const colDefs = kagamiGridModel.gridColumnApi?.columnModel.getAllDisplayedColumns();
                const firstEditableCol = getFirstEditableColumn(colDefs);
                if (firstEditableCol) {
                  event.api.startEditingCell({
                    rowIndex: event.rowIndex ?? 0,
                    colKey: firstEditableCol
                  });
                }
                showValidationError(formModel, validations);
              }
            }
          } else {
            kagamiGridModel.skipValidate = false;
          }
        }}
        onRowEditingStarted={(event: any) => {
          if (
            kagamiGridModel.isEditableEmbedList &&
            CommonUtils.isNotEmpty(kagamiGridModel?.activeRowNode) &&
            event.node.data.id !== kagamiGridModel.activeRowNode.data.id
          ) {
            let validations = validate(getFormModel(), kagamiGridModel.mainEntityId, true);
            if (validations.size !== 0) {
              event.api.stopEditing();
            }
          }
        }}
        rowBuffer={20}
        components={{
          listEmbedFieldRenderer: ListEmbedFieldRenderer,
          KagamiStatusCellRenderer: KagamiStatusCellRenderer,
          editCellRenderer: EditRecordCellRenderer,
          deleteCellRenderer: DeleteCellRenderer,
          kagamiImageCellRenderer: KagamiImageCellRenderer,
          inFormCellRenderer: InFormCellRenderer,
          KagamiActivityLogCellRenderer: KagamiActivityLogCellRenderer,
          nestedEmbedsRenderer: NestedEmbedPresentationsRenderer,
          checkboxCellRenderer: CheckboxCellRenderer,
          actionCellRenderer: ActionCellRenderer,
          KagamiTextCellRenderer: KagamiTextCellRenderer,
          KagamiBarCodeCellRenderer: KagamiBarCodeCellRenderer,
          floatingActionRenderer: FloatingActionCellRenderer,
          KagamiNumberCellRenderer: KagamiNumberCellRenderer,
          KagamiThumbnailCellRenderer: KagamiThumbnailCellRenderer,
          AddButtonRenderer: AddButtonRenderer,
          PhoneNumberEditor: PhoneNumberEditor,
          searchEditor: SearchEditor,
          radioEditor: RadioEditor,
          selectEditor: SelectEditor,
          dateEditor: DateEditor,
          multiSelectEditor: MultiSelectEditor,
          KagamiThumbnailCellEditor: KagamiThumbnailCellEditor,
          agColumnHeader: kagamiGridModel.isEmbedModel ? HeaderSearchRenderer : undefined
        }}
        defaultColDef={defaultColDef}
        suppressCopyRowsToClipboard={true}
        rowClass={rowClass}
        columnDefs={columnDefs}
      ></AgGridReact>
      {buildPagination(kagamiGridModel.paginationModel, kagamiGridModel)}
    </div>
  );
}
