import { KagamiStateManager } from "../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../constants/SystemConstants";
import { CommonUtils } from "../../../../../../../utils/CommonUtils";
import { getHomeModel } from "../../../../../controller/KagamiHomeController";
import { BodyContent } from "../../../../../model/KagamiHomeModel";
import { buildProcessPage, getProcessPageModel, setProcessPageModel } from "../../../../body/builder/process/controller/KagamiProcessController";
import { KagamiProcessPageModel } from "../../../../body/builder/process/model/KagamiProcessModel";
import { cloneProcessModel, makeInitialProcessContentCall } from "../../../../body/builder/process/service/ProcessPageService";
import { renderProcessPage } from "../../../../body/controller/KagamiBodyController";
 

export function executeProcess(
  processName: string,
  detailedObjects?: any,
  breadcrumb: any = [],
  back: boolean = false,
  isTodo: boolean = false
) {
  const processModel: KagamiProcessPageModel = new KagamiProcessPageModel(processName);
  if (CommonUtils.isNotEmpty(detailedObjects)) {
    processModel.detailsObjects = detailedObjects;
  }
  if (!back) {
    processModel.fromDashboard = true;
  }
  processModel.breadcrumbModel.breadcrumbs = breadcrumb;
  if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
    if (processModel.fromDashboard) {
      let previousPageModel: KagamiProcessPageModel = getProcessPageModel();
      cloneProcessModel(previousPageModel, processModel);
    }
    setProcessPageModel(processModel);
    makeInitialProcessContentCall(processModel);
  } else {
    renderProcessPage(buildProcessPage(processModel, isTodo));
    // KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setNotificationIconVisible(false);
    // KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setReportIconVisible(false);
    const homeModel = getHomeModel();
    homeModel.state.setCurrentBodyContent(BodyContent.PROCESS);
  }
  // KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setNotificationIconVisible(false);
  // KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setReportIconVisible(false);
}

let processProcessName: string = '';

export function getProcessName() {
  return processProcessName;
}

export function setCurrentProcessName(processName: string) {
  return (processProcessName = processName);
}
