import { createState, State } from '@hookstate/core';
import { KagamiBeanUtils } from '../../../../../../../../../utils/KagamiBeanUtils';
import {
  getAttributeLabel,
  getAttributeName,
  getAttributeValidations,
  getBusinessPolicy,
  getEntityConsumedInAttribute,
  getHtmlControl,
  getPolicyMap,
  getPresenationIdFromPresentation,
  getPresentationPolicy,
  isOnChangeRequiredForAttribute,
  isReadOnlyAttribute,
  isRequiredAttribute,
  isVisibleAttribute,
  getUiSettings,
  getPolicyMapFromAttributeEvent,
  isOnChangeRequiredFromAttributeEvent,
  getPresentationPolicyForDynamicForm,
  getBusinessPolicyForDynamicForm,
  isCommaSeparated,
  isConversion,
  currencySymbolValue,
  noValueMsgAtrribute,
  isContactNumber,
  isPrefixType,
  broadcastRequiredAttribute,
  hasExternalData,
  isDefaultValuesPresent
} from '../../../service/PresentationService';
import { buildCheckbox, buildMultiSelectCheckBox } from '../../controls/checkbox/controller/kagamiCheckboxController';
import { KagamiCheckboxModel } from '../../controls/checkbox/model/kagamiCheckboxModal';
import { buildDateField } from '../../controls/date/controller/kagamiDateController';
import { KagamiDateModel } from '../../controls/date/model/kagamiDateModel';
import { KagamiFileModel } from '../../controls/file/model/KagamiFileModel';
import { buildRadioButton } from '../../controls/radio/controller/kagamiRadioController';
import { KagamiRadioModel } from '../../controls/radio/model/kagamiRadioModel';
import { KagamiSelectModel } from '../../controls/select/model/kagamiSelectModel';
import { buildSelectBoxComponent } from '../../controls/select/ui/kagamiSelectField';
import { buildTextBox } from '../../controls/textbox/controller/KagamiTextController';
import { KagamiTextModel } from '../../controls/textbox/model/kagamiTextModel';
import { getFormModel, handleChange } from '../controller/KagamiFormController';
import { KagamiFormPresentationModel } from './form-presenation/model/KagamiFormPresentationModel';
import { getDateStringToPopulateInForm, getEnableDates } from '../../../../../../../../../utils/DateUtils';
import { KagamiFormModel } from '../model/KagamiFormModel';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { isStepFormTrue } from './FormBuilder';
import { buildNumberBox } from '../../controls/numberTextbox/controller/KagamiNumberController';
import { KagamiNumberModel } from '../../controls/numberTextbox/model/KagamiNumberModel';
import {
  buildActivityLogControl,
} from '../../../../../../../../../../components/activityLog/controller/KagamiActivityLogModalController';
import { KagamiImageModel } from '../../controls/image/model/kagami_ImageModel';
import { buildImageField } from '../../controls/image/controller/kagami_ImageController';
import { buildFile } from '../../controls/file/controller/KagamiFileController';
import { getBreadcrumbsModel } from '../../breadcrumbs/controller/kagamiBreadcrumbsController';
import {
  KagamiActivityLogControlModel,
} from '../../../../../../../../../../components/activityLog/model/KagamiActivityLogModel';
import { appContext } from '../../../service/PolicyExecutionService';
import { KagamiBarCodeModel } from '../../controls/barcode/model/BarCodeModel';
import { buildBarCode } from '../../controls/barcode/controller/BarCodeController';

export function createFieldComponents(
  attributePresentation: any,
  kagamiFormPresentationModel: KagamiFormPresentationModel,
  onLoadData: any,
  isGridField: boolean,
  isEmbddedField: boolean,
  isDynamicForm: boolean,
  isStepForm: boolean,
  attributeEvent?: any
) {
  let kagamiFormModel: KagamiFormModel = getFormModel();
  const controlType = getHtmlControl(attributePresentation);
  const attributeName: string = getAttributeName(attributePresentation);
  const attributeLabel: string = getAttributeLabel(attributePresentation, true);
  const isMandatory: boolean = isRequiredAttribute(attributePresentation);
  const isVisible: boolean = isVisibleAttribute(attributePresentation);
  const noValueMsg: string = noValueMsgAtrribute(attributePresentation);
  const broadcastRequired: boolean = broadcastRequiredAttribute(attributePresentation);
  const onChangeRequired = isDynamicForm ? isOnChangeRequiredFromAttributeEvent(attributeEvent) : isOnChangeRequiredForAttribute(attributePresentation);
  const isReadOnly = kagamiFormModel.isSingleListView ? true : isReadOnlyAttribute(attributePresentation);
  const isFileReadOnly = kagamiFormModel.readOnly; //considering readOnly from formModel bcaz attributePresentation for file fields in detailed view has readOnly false
  const validations = getAttributeValidations(attributePresentation);
  let docInfo = kagamiFormModel.onStartData.constructOutputData['docInfo'];
  // this docInfo condition is used by embed grid file uploads
  if (CommonUtils.isNotEmpty(kagamiFormModel.docInfo) && CommonUtils.isEmpty(docInfo)) {
    docInfo = kagamiFormModel.docInfo.hasOwnProperty(attributeName) ? kagamiFormModel.docInfo[attributeName] : {};
  }
  const policyMap = isDynamicForm
    ? getPolicyMapFromAttributeEvent(attributeEvent)
    : getPolicyMap(attributePresentation);
  const formDataIdentifier = kagamiFormPresentationModel.formDataIdentifier;
  const controlIdentifier = kagamiFormPresentationModel.formEntityId + '.' + attributeName;
  isStepForm = isStepFormTrue(kagamiFormPresentationModel);
  let controlValue: any = isStepForm
    ? KagamiBeanUtils.getProperty(attributeName, kagamiFormModel.formData.formData)
    : KagamiBeanUtils.getProperty(attributeName, onLoadData);
  const pickAttribute = attributeName.includes('.') ? true : false;
  const enableToDate = getEnableDates(controlType, attributePresentation, 'dateToValue', validations);
  const enableFromDate = getEnableDates(controlType, attributePresentation, 'dateFromValue', validations);
  const associatedAttribute: any = getEntityConsumedInAttribute(attributePresentation);
  const presentationPolicy: any = isDynamicForm
    ? getPresentationPolicyForDynamicForm(attributeEvent)
    : getPresentationPolicy(attributePresentation);
  const businessPolicy: any = isDynamicForm
    ? getBusinessPolicyForDynamicForm(attributeEvent)
    : getBusinessPolicy(attributePresentation);
  const uiSettings = getUiSettings(attributePresentation);
  const isContact: boolean = isContactNumber(attributePresentation);
  /// Note : this html control is for file model
  const htmlControl = getHtmlControl(attributePresentation);
  let lovRecords: any;
  let formModel: KagamiFormModel = getFormModel();
  let entityConsumed = getEntityConsumedInAttribute(attributePresentation);
  if (entityConsumed) {
    lovRecords = CommonUtils.isNotEmpty(formModel.formData.data[entityConsumed])
      ? formModel.formData.data[entityConsumed]
      : [];
  }
  if (htmlControl === 'search') {
    if (CommonUtils.isNotEmpty(formModel.formData.formData[associatedAttribute]) && CommonUtils.isEmpty(controlValue)) {
      controlValue = formModel.formData.formData[entityConsumed].id;
    }
  }
  let component = null;
  switch (controlType) {
    case 'customactivitylog':
    case 'comments':
      let kagamiActivityLogModel = new KagamiActivityLogControlModel(
        controlIdentifier,
        attributeName,
        isMandatory,
        isVisible,
        kagamiFormPresentationModel.formEntityId,
        formModel.isKanbannDetailView ? true : isFileReadOnly,
        controlType,
        attributeLabel,
        true,
        controlValue,
        onChangeRequired,
        validations,
        pickAttribute,
        handleChange,
        policyMap,
        presentationPolicy,
        businessPolicy,
        isReadOnly,
        isVisible,
        uiSettings,
        htmlControl,
        formDataIdentifier,
        isGridField,
        isEmbddedField
      );
      kagamiActivityLogModel.htmlControl = controlType;
      return buildActivityLogControl(kagamiActivityLogModel);
    case 'date':
    case 'time':
    case 'dateTime':
      let kagamiDateModel: KagamiDateModel = new KagamiDateModel(
        controlIdentifier,
        attributeName,
        isMandatory,
        isVisible,
        kagamiFormPresentationModel.formEntityId,
        formModel.isKanbannDetailView ? true : isReadOnly,
        controlType,
        attributeLabel,
        true,
        getDateStringToPopulateInForm(controlValue, controlType),
        onChangeRequired,
        validations,
        pickAttribute,
        handleChange,
        enableToDate,
        enableFromDate,
        policyMap,
        presentationPolicy,
        businessPolicy,
        isReadOnly,
        isVisible,
        uiSettings,
        formDataIdentifier,
        isGridField,
        isEmbddedField,
        attributePresentation.weekendsSelected ? attributePresentation.weekendsSelected : []
      );
      let kagamiDateModelState: State<KagamiDateModel> = createState(kagamiDateModel);
      kagamiDateModel.createState = kagamiDateModelState;
      kagamiFormPresentationModel.formControls[controlIdentifier] = kagamiDateModel;
      return buildDateField(kagamiDateModel);
    case 'password':
    case 'text':
    case 'textarea':
      let kagamiTextModel: KagamiTextModel = new KagamiTextModel(
        controlIdentifier,
        attributeName,
        isMandatory,
        isVisible,
        kagamiFormPresentationModel.formEntityId,
        formModel.isKanbannDetailView ? true : isReadOnly,
        controlType,
        attributeLabel,
        true,
        controlValue,
        onChangeRequired,
        validations,
        pickAttribute,
        handleChange,
        null,
        null,
        policyMap,
        presentationPolicy,
        businessPolicy,
        isReadOnly,
        isVisible,
        uiSettings,
        formDataIdentifier,
        isGridField,
        isEmbddedField
      );
      kagamiTextModel.isContact = isContact;
      let kagamiTextModelState: State<KagamiTextModel> = createState(kagamiTextModel);
      kagamiTextModel.createState = kagamiTextModelState;
      kagamiFormPresentationModel.formControls[controlIdentifier] = kagamiTextModel;
      return buildTextBox(kagamiTextModel);
      case 'barcode':
        let kagamiBarCodeModel: KagamiBarCodeModel = new KagamiBarCodeModel(
          controlIdentifier,
          attributeName,
          isMandatory,
          isVisible,
          kagamiFormPresentationModel.formEntityId,
          formModel.isKanbannDetailView ? true : isReadOnly,
          controlType,
          attributeLabel,
          true,
          controlValue,
          onChangeRequired,
          validations,
          pickAttribute,
          handleChange,
          null,
          null,
          policyMap,
          presentationPolicy,
          businessPolicy,
          isReadOnly,
          isVisible,
          uiSettings,
          formDataIdentifier,
          isGridField,
          isEmbddedField
        );
        let kagamiBarCodeModelState: State<KagamiBarCodeModel> = createState(kagamiBarCodeModel);
        kagamiBarCodeModel.createState = kagamiBarCodeModelState;
        kagamiBarCodeModel.attributePresentation = attributePresentation;
        kagamiFormPresentationModel.formControls[controlIdentifier] = kagamiBarCodeModel;
        return buildBarCode(kagamiBarCodeModel);
    case 'number':
      if ((controlValue == null || controlValue === undefined) && controlType === 'number') {
        controlValue = '';
      }
      let kagamiNumberModel: KagamiNumberModel = new KagamiNumberModel(
        controlIdentifier,
        attributeName,
        attributePresentation,
        isMandatory,
        isVisible,
        kagamiFormPresentationModel.formEntityId,
        formModel.isKanbannDetailView ? true : isReadOnly,
        controlType,
        attributeLabel,
        true,
        controlValue,
        onChangeRequired,
        validations,
        pickAttribute,
        handleChange,
        null,
        null,
        policyMap,
        presentationPolicy,
        businessPolicy,
        isReadOnly,
        isVisible,
        uiSettings,
        formDataIdentifier,
        isGridField,
        isEmbddedField,
        hasExternalData(uiSettings)
      );
      if (CommonUtils.isNotEmpty(appContext) && CommonUtils.isNotEmpty(appContext.AppSetting[0])) {
        kagamiNumberModel.currencyFormat = appContext.AppSetting[0]?.NumberFormater?.type;
      }
      kagamiNumberModel.isCommaSeparated = isCommaSeparated(uiSettings);
      kagamiNumberModel.isHavedefaultValue = isDefaultValuesPresent(attributePresentation);
      kagamiNumberModel.iscurrencySymbol = currencySymbolValue(uiSettings);
      kagamiNumberModel.isconversion = isConversion(uiSettings);
      kagamiNumberModel.isPrefixtype = isPrefixType(uiSettings);
      let kagamiNumberModelState: State<KagamiNumberModel> = createState(kagamiNumberModel);
      kagamiNumberModel.createState = kagamiNumberModelState;
      kagamiFormPresentationModel.formControls[controlIdentifier] = kagamiNumberModel;
      return buildNumberBox(kagamiNumberModel);
    case 'search':
    case 'select':
    case 'multiselect':
      let kagamiSelectModel: KagamiSelectModel = new KagamiSelectModel(
        controlIdentifier,
        attributeName,
        isMandatory,
        isVisible,
        kagamiFormPresentationModel.formEntityId,
        formModel.isKanbannDetailView ? true : isReadOnly,
        controlType,
        attributeLabel,
        true,
        controlValue,
        onChangeRequired,
        validations,
        pickAttribute,
        handleChange,
        attributePresentation,
        policyMap,
        associatedAttribute,
        getPresenationIdFromPresentation(kagamiFormPresentationModel.presentation),
        presentationPolicy,
        businessPolicy,
        isReadOnly,
        isVisible,
        uiSettings,
        formDataIdentifier,
        isGridField,
        isEmbddedField,
        noValueMsg,
        broadcastRequired
      );
      if (controlType === 'multiselect') {
        if (CommonUtils.isNotEmpty(controlValue)) {
          kagamiSelectModel.selectValues = controlValue instanceof Array ? controlValue : [controlValue];
        } else {
          kagamiSelectModel.selectValues = [];
        }
      }
      if (controlValue !== null && controlValue !== undefined) {
        let associatedObject;
        if (isStepForm) {
          associatedObject = kagamiFormModel.formData.formData[associatedAttribute] ?? {};
        } else {
          associatedObject = onLoadData !== undefined ? onLoadData[associatedAttribute] ?? {} : {};
        }
        kagamiSelectModel.associatedObject = associatedObject;
      }
      if (lovRecords && lovRecords !== null) {
        kagamiSelectModel.lovRecords = lovRecords;
      }
      let kagamiSelectModelState: State<KagamiSelectModel> = createState(kagamiSelectModel);
      kagamiSelectModel.createState = kagamiSelectModelState;
      kagamiFormPresentationModel.formControls[controlIdentifier] = kagamiSelectModel;
      return buildSelectBoxComponent(kagamiSelectModel);
    case 'radio':
      let kagamiRadioModel: KagamiRadioModel = new KagamiRadioModel(
        controlIdentifier,
        attributeName,
        isMandatory,
        isVisible,
        kagamiFormPresentationModel.formEntityId,
        formModel.isKanbannDetailView ? true : isReadOnly,
        controlType,
        attributeLabel,
        true,
        controlValue,
        onChangeRequired,
        validations,
        pickAttribute,
        handleChange,
        attributePresentation,
        policyMap,
        associatedAttribute,
        presentationPolicy,
        businessPolicy,
        isReadOnly,
        isVisible,
        uiSettings,
        formDataIdentifier,
        isGridField,
        isEmbddedField
      );
      kagamiRadioModel.entityConsumed = entityConsumed;
      if (controlValue !== null && controlValue !== undefined) {
        let associatedObject;
        if (isStepForm) {
          associatedObject = kagamiFormModel.formData.formData[associatedAttribute] ?? {};
        } else {
          associatedObject =
            onLoadData !== undefined ? onLoadData[getEntityConsumedInAttribute(attributePresentation)] ?? {} : {};
        }
        kagamiRadioModel.associatedObject = associatedObject;
      }
      if (CommonUtils.isNotEmpty(lovRecords)) {
        kagamiRadioModel.selectValues = lovRecords;
      } else if (
        CommonUtils.isNotEmpty(controlValue) &&
        onLoadData &&
        CommonUtils.isNotEmpty(onLoadData[associatedAttribute])
      ) {
        kagamiRadioModel.selectValues = [onLoadData[associatedAttribute]];
      } else {
        kagamiRadioModel.selectValues = [];
      }
      let kagamiRadioModelState: State<KagamiRadioModel> = createState(kagamiRadioModel);
      kagamiRadioModel.createState = kagamiRadioModelState;
      kagamiFormPresentationModel.formControls[controlIdentifier] = kagamiRadioModel;
      return buildRadioButton(kagamiRadioModel);
    case 'file':
    case 'fileViewer':
    case 'preview':
    case 'fileManager':
      let kagamiFileModel: KagamiFileModel = new KagamiFileModel(
        controlIdentifier,
        attributeName,
        isMandatory,
        isVisible,
        kagamiFormPresentationModel.formEntityId,
        formModel.isKanbannDetailView ? true : isFileReadOnly,
        controlType,
        attributeLabel,
        true,
        controlValue,
        onChangeRequired,
        validations,
        pickAttribute,
        handleChange,
        null,
        null,
        policyMap,
        presentationPolicy,
        businessPolicy,
        isReadOnly,
        isVisible,
        uiSettings,
        htmlControl,
        formDataIdentifier,
        isGridField,
        isEmbddedField,
        kagamiFormPresentationModel,
        docInfo
      );
      let kagamiFileModelState: State<KagamiFileModel> = createState(kagamiFileModel);
      kagamiFileModel.createState = kagamiFileModelState;
      kagamiFileModel.isReadOnly = isReadOnlyAttribute(attributePresentation);;
      kagamiFormPresentationModel.formControls[controlIdentifier] = kagamiFileModel;
      if (kagamiFileModel.controlType == 'fileViewer') {
        const breadcrumbModel = getBreadcrumbsModel();
        if (CommonUtils.isNotEmpty(breadcrumbModel)) {
          breadcrumbModel.showBreadCrumb = false;
        }
      }
      return buildFile(kagamiFileModel);
    case 'image':
      let kagamiImageModel: KagamiImageModel = new KagamiImageModel(
        controlIdentifier,
        attributeName,
        isMandatory,
        isVisible,
        kagamiFormPresentationModel.formEntityId,
        formModel.isKanbannDetailView ? true : isFileReadOnly,
        controlType,
        attributeLabel,
        true,
        controlValue,
        onChangeRequired,
        validations,
        pickAttribute,
        handleChange,
        null,
        null,
        policyMap,
        presentationPolicy,
        businessPolicy,
        isReadOnly,
        isVisible,
        uiSettings,
        htmlControl,
        formDataIdentifier,
        isGridField,
        isEmbddedField,
        kagamiFormPresentationModel,
        docInfo
      );
      let kagamiImageModelState: State<KagamiImageModel> = createState(kagamiImageModel);
      kagamiImageModel.createState = kagamiImageModelState;
      kagamiFormPresentationModel.formControls[controlIdentifier] = kagamiImageModel;
      return buildImageField(kagamiImageModel);
    case 'checkbox':
    case 'boolean':
      let kagamiCheckboxModel: KagamiCheckboxModel = new KagamiCheckboxModel(
        controlIdentifier,
        attributeName,
        isMandatory,
        isVisible,
        kagamiFormPresentationModel.formEntityId,
        formModel.isKanbannDetailView ? true : isReadOnly,
        controlType,
        attributeLabel,
        true,
        controlValue,
        onChangeRequired,
        validations,
        pickAttribute,
        handleChange,
        policyMap,
        presentationPolicy,
        businessPolicy,
        isReadOnly,
        isVisible,
        uiSettings,
        formDataIdentifier,
        isGridField,
        isEmbddedField
      );
      if (lovRecords && lovRecords !== null) {
        kagamiCheckboxModel.selectValues = lovRecords;
      }
      let kagamiCheckboxModelState: State<KagamiCheckboxModel> = createState(kagamiCheckboxModel);
      kagamiCheckboxModel.createState = kagamiCheckboxModelState;
      kagamiFormPresentationModel.formControls[controlIdentifier] = kagamiCheckboxModel;
      kagamiCheckboxModel.isHavedefaultValue = isDefaultValuesPresent(attributePresentation);
      kagamiCheckboxModel.attributePresentation = attributePresentation;
      if (
        kagamiCheckboxModel.attributePresentation.htmlControl === 'checkbox' &&
       CommonUtils.isNotEmpty(kagamiCheckboxModel.attributePresentation.entityConsumed)
      ) {
        kagamiCheckboxModel.multiSelectCheckBox = true;
      }
      if (kagamiCheckboxModel.multiSelectCheckBox) {
        return buildMultiSelectCheckBox(kagamiCheckboxModel);
      } else {
        return buildCheckbox(kagamiCheckboxModel);
      }
    default:
    // TODO  return a div with un coded control message
  }
  return component;
}
