import { State, useState } from "@hookstate/core";

import { KagamiStateManager } from "../../../../../../../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../../../../../../../constants/SystemConstants";
import { KagamiListModel } from "../../../../../model/KagamiListModel";
import { KagamiPresentationFilterModel } from "../../kagamiPresentationFilter/model/KagamiPresentationFilterModel";

export class SelectedFilterModel{
    presentationFilterModel: KagamiPresentationFilterModel;
    dataRecordsForPresentationAttribute:any;
    filterDataForSearchableAttributes:any;
    attributeName :string;
    filterCheckedData: {};
    state: any;
    listModel:any;
    constructor(){
        this.attributeName = "";
        this.presentationFilterModel = new KagamiPresentationFilterModel(this.dataRecordsForPresentationAttribute, this.filterDataForSearchableAttributes, this.attributeName);
        this.dataRecordsForPresentationAttribute = "";
        this.filterDataForSearchableAttributes = "";
        this.filterCheckedData = {};

    }
}
export interface SelectedFilterProps {
    selectedFilterModel: SelectedFilterModel;
    kagamiListModel:KagamiListModel;
  }

  export const wrapSelectedFilterState = (state: State<SelectedFilterModel>) => {
    let selectedFilterState = state;
  
    return {
        getSelectedFilterValue() {
            return selectedFilterState.filterCheckedData.get();
          },
          setSelectedFilterValue(value: any) {
            selectedFilterState.filterCheckedData.set(value);
          },        
    }
};

export const useSelectedFilterState = (state: State<SelectedFilterModel>) => {
    const selectedFilterState = wrapSelectedFilterState(useState(state));
    KagamiStateManager.addStateToHolder(SystemConstants.SELECTED_FILTER_STATE, selectedFilterState);
    return selectedFilterState;
  };