import { Box, InputAdornment, List, ListItem, ListItemButton, ListItemText, Modal, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { KagamiGlobalSearchStyle } from '../styles/globalSearchstyles';
import { KagamiDashboardModel } from '../../dashboard/model/KagamiDashboardModel';
import { Typography } from '@mui/material';
import { executeProcess, setCurrentProcessName } from '../../../../navigator/builder/Menu/controller/KagamiMenuController';
import { useState, createRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { getDashboardModel } from '../../dashboard/ui/KagamiDashboard';
import { usePanelState } from '../../../../navigator/builder/Menu/model/KagamiPanelState';
import CloseIcon from '@mui/icons-material/Close';
import { isEnableTabs, translateToLocalistaion } from '../../process/service/PresentationService';
import { KagamiStateManager } from '../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { tabExistAndContentFromState } from '../../../../header/Tabs/ui/KagamiTabNavigation';
import { appContext } from '../../process/service/PolicyExecutionService';
import { getBreadcrumbsModel, resetBreadcrumbs } from '../../process/builder/breadcrumbs/controller/kagamiBreadcrumbsController';

const style = {
  position: 'absolute' as 'absolute',
  width: 700,
  boxShadow: 1,
  bgcolor: 'background.paper',
  p: 2.5
};

const useStyles = makeStyles(KagamiGlobalSearchStyle);

export const GlobalSearchComponent = (props: any) => {
  let regex = /^[a-z -]+$/i;
  const [cursor, setCursor] = useState<null | number>(null);
  const [showList, setShowList] = useState(false);
  const [allprocess, setAllProcess] = useState('');
  const panelState = usePanelState();
  let refList: any = {};
  let dashboardModel: KagamiDashboardModel = getDashboardModel();
  let translations = dashboardModel?.containerData?.otherProperties?.translation;
  let globalSearchName: any = [];
  let parents: string[] = [];
  function searchGlobalProcess(event: any) {
    if (event.target.value != '') {
      setShowList(true);
    } else {
      setShowList(false);
    }
    setAllProcess(event.target.value);
  }

  const excuteProcesCall = (processName: any, parents: string[], nameLabel: string) => {
    
    let clickedProcess = '';
    if (nameLabel.includes('-')) {
      clickedProcess = nameLabel.split('-')[1];
    } else {
      clickedProcess = nameLabel;
    }
    panelState.SetActiveItem(processName);
    panelState.SetActiveItemParents(parents);
    props.bodyState.setGlobalMenuSearchLoaded(false);
    setCurrentProcessName(processName);
    
    const breadcrumbsModel = getBreadcrumbsModel();
    resetBreadcrumbs(breadcrumbsModel);

    
    if(isEnableTabs(appContext)) {
      handleTab(processName, clickedProcess);
    } else {
      executeProcess(processName);
    }



  };

  const addTab = (process: any) => {
    let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
    let tabs = JSON.parse(JSON.stringify(tabNavigationState?.getTabs));
    tabNavigationState?.setTabs(tabs);
    if (process.name === 'Reports') {
      tabNavigationState?.setActiveTab(process?.name);
    } else {
      tabNavigationState?.setActiveTab(process?.name);
    }
  };

  const handleTab = (processName: string, name: string) => {
    let newName = translateToLocalistaion(translations, name);
    name = newName;
    let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
    let tabs = JSON.parse(JSON.stringify(tabNavigationState.getTabs));
    let isTabAlreadyExist = tabs?.findIndex((item: any) => item?.processName === processName);
    if (isTabAlreadyExist !== -1) {
      tabExistAndContentFromState(processName, name);
    } else {
      tabs?.push({ processName, name });
      tabNavigationState.setTabs(tabs);
      if (tabs?.length <= 10) {
        tabNavigationState.setActiveTab(processName);
        executeProcess(processName);
      }
    }
  };

  function recurseNavigationMenu(menuItems: any, groupName: string) {
    menuItems?.map((menuItem: any) => {
      if (menuItem?.type === "group" && menuItem?.subMenus?.length > 0) {
        recurseNavigationMenu(menuItem.subMenus, menuItem.name ?? SystemConstants.EMPTY);
      }
      if (menuItem?.type === "process") {
        globalSearchName.push({
          label: translateToLocalistaion(translations, groupName + ' - ' + menuItem?.name),
          processName: menuItem?.event?.process,
          parents: translateToLocalistaion(translations, parents)
        });
      }
    });
  };

  const classes = useStyles();
  if (dashboardModel?.containerData?.container) {
    dashboardModel.containerData.container.forEach((panel: any) => {
      if (panel.menuPanel?.menuItems?.length > 0) {
        if (panel?.panelType === 'leftPanel') {
          recurseNavigationMenu(panel.menuPanel?.menuItems, SystemConstants.EMPTY);
        } else if (panel?.panelType === 'userRole') {
          recurseNavigationMenu(panel.menuPanel?.menuItems, 'Setting');
        } else if (panel?.panelType === 'masterDataGlobal') {
          recurseNavigationMenu(panel.menuPanel?.menuItems, 'Master');
        }
      }
      return globalSearchName;
    });
  } else {
    return globalSearchName;
  }


  const globalSearchOptionsFilterData =
    globalSearchName.length > 0
      ? globalSearchName.filter((globalsearch: any) => {
          if (!allprocess) return true;
          if (regex.test(allprocess)) {
            if (
              globalsearch.label.toLowerCase().match(allprocess.toLowerCase()) ||
              globalsearch.label.toUpperCase().match(allprocess.toUpperCase())
            ) {
              return true;
            }
          } else {
            return false;
          }
          return false;
        })
      : [];

  const keyboardEventHandler = (e: any) => {
    if (cursor === null) {
      if (e.key === 'ArrowDown') {
        setCursor(0);
        refList[0]?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      } else if (e.key === 'ArrowUp') {
        setCursor(globalSearchOptionsFilterData.length - 1);
        refList[globalSearchOptionsFilterData.length - 1]?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    } else if (typeof cursor === 'number') {
      if (e.key === 'Enter') {
        let processLabel: any = globalSearchOptionsFilterData[cursor];
        excuteProcesCall(processLabel.processName, processLabel.parents, processLabel.label);
      } else {
        if (e.key === 'ArrowDown' && cursor === globalSearchOptionsFilterData?.length - 1) {
          setCursor(0);
          refList[0]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        } else if (e.key === 'ArrowUp' && cursor === 0) {
          setCursor(globalSearchOptionsFilterData.length - 1);
          refList[globalSearchOptionsFilterData.length - 1]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        } else if (e.key === 'ArrowDown') {
          setCursor((prev: any) => {
            refList[prev + 1]?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            return prev + 1;
          });
        } else if (e.key === 'ArrowUp') {
          setCursor((prev: any) => {
            refList[prev - 1]?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            return prev - 1;
          });
        }
      }
    }
  };

  return (
    <>
      <Modal
        style={{}}
        disableAutoFocus={true}
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.globalbreakpoint}>
          <CloseIcon
            className={classes.globalSearchClose}
            onClick={() => {
              props.bodyState.setGlobalMenuSearchLoaded(false);
            }}
          />
          <TextField
            autoFocus={true}
            autoComplete="off"
            color="primary"
            variant="standard"
            placeholder="Where Do You Want To Go..?"
            fullWidth
            onKeyDown={keyboardEventHandler}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              setCursor(null);
              searchGlobalProcess(e);
            }}
          />
          {showList ? (
            <div className={classes.globalSearchOptionsFilterData}>
              <List>
                {globalSearchOptionsFilterData.map((processLabel: any, index: any) => {
                  refList[index] = createRef();
                  return (
                    <ListItem
                      ref={refList[index]}
                      onClick={() =>
                        excuteProcesCall(processLabel.processName, processLabel.parents, processLabel.label)
                      }
                      className={classes.globalList}
                      style={{
                        backgroundColor: cursor === index ? '#007ecc' : 'white',
                        color: cursor === index ? 'white' : '#007ecc'
                      }}
                    >
                      <ListItemButton>
                        <ListItemText
                          className={classes.filterOptions}
                          primary={
                            <>
                              <Typography variant="caption" color="primary" className={classes.filterOptionsSecondTag}>
                                {processLabel.label.toLowerCase().includes(allprocess.toLowerCase()) ? (
                                  <span>
                                    <GetHighlightedString
                                      propcessLabel={translateToLocalistaion(translations, processLabel.label)}
                                      allprocess={allprocess}
                                      active={cursor === index ? true : false}
                                    />
                                  </span>
                                ) : (
                                  <span>{translateToLocalistaion(translations, processLabel.label)}</span>
                                )}
                              </Typography>
                            </>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          ) : null}
        </Box>
      </Modal>
    </>
  );
};

export const GetHighlightedString = (props: any) => {
  const { propcessLabel, allprocess, highlightedItemClass, active } = props;
  const getSearchKeys = propcessLabel.split(new RegExp(`(${allprocess})`, 'gi'));
  let dashboardModel: KagamiDashboardModel = getDashboardModel();
  let translations = dashboardModel?.containerData?.otherProperties?.translation;
  return (
    <>
      {' '}
      {getSearchKeys.map((searchkey: any, index: any) => {
        const highlightStyle =
          allprocess.length > 1 && searchkey.toLowerCase() === allprocess.toLowerCase() ? highlightedItemClass : '';
        return (
          <span
            key={index}
            className={highlightStyle}
            style={{
              color: active ? '#ffffff' : '#000000'
            }}
          >
            {translateToLocalistaion(translations, searchkey)}
          </span>
        );
      })}{' '}
    </>
  );
};
