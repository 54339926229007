import * as React from 'react';
import { createState } from '@hookstate/core';
import {
  HierarchyListReportModel,
  KagamiHierarchyListReportModelProps,
  useHierarchyListReportModelState
} from '../model/HierarchyListModel';
import { AgGridReact } from 'ag-grid-react';
import { Button, ButtonGroup, Menu, MenuItem, Grid, CircularProgress } from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import { buildReportModal } from '../../../../../ReportModal/controller/ReportModalController';
import { ReportModalModel } from '../../../../../ReportModal/model/ReportModalModel';
import { makeHierarchyReportData } from '../service/HierarchyListService';
import { downloadExcel, downloadPDF } from '../service/HierarchyListReportExportService';
import { ExcelStyle } from 'ag-grid-community';
import _ from 'lodash';
import { getFooterRowData } from '../../list/service/ListReportService';
import { currencyFormats } from '../../../../../ReportConstant/ReportConstant';

export const buildHierarchyListView = (listReportModel: HierarchyListReportModel) => {
  return <HierarchyListView key={'tree-data-list-report-view'} listReportModel={listReportModel}></HierarchyListView>;
};
export const HierarchyListView = (props: KagamiHierarchyListReportModelProps) => {
  const gridRef: any = React.useRef(null);
  const listReportModel = props.listReportModel;
  const listState = useHierarchyListReportModelState(createState(listReportModel));
  listReportModel.state = listState;
  const reportModalModel = new ReportModalModel();

  React.useEffect(() => {
    getReportDataForDynamicReports(props.listReportModel);
  }, []);

  const autoGroupColumnDef = React.useMemo(() => {
    return {
      headerName: `${listReportModel['treeColumn']}`,
      cellRendererParams: {
        suppressCount: true
      },
    };
  }, []);

  const excelStyles = React.useMemo<ExcelStyle[]>(() => {
    let currencySymbols: any[] = (currencyFormats.map((currency:any)=>({
      id: "currencySymbol_"+currency.symbol,
      numberFormat: {format: currency["format"]}
    })));
    return [
      {
        id: "hierarchy-nested-row",
        interior: {
          pattern: 'Solid',
          color: "#FFEFD5",
        }
      },
      {
        id: "right-alignment",
        alignment: {
          horizontal: 'Right', vertical: 'Bottom'
        },
      },
      {
        id: 'center-alignment',
        alignment: {
          horizontal: 'Center', vertical: 'Bottom'
        }
      },
      {
        id: 'header-and-footer-style',
        interior: {
          color: '#D9EAD3',
          pattern: 'Solid'
        },
        font: {
          bold: true,
          color: '#000000'
        }
      },
      {
        id: 'stringType',
        dataType: 'String',
      },
      ...currencySymbols
    ];
  }, []);
  
  const getDataPath = React.useMemo(() => {
    return (data: any) => {
      return data[listReportModel['treeColumn']];
    };
  }, []);

  if (!listState.isDataLoaded) {
    return <CircularProgress sx={{ marginTop: '4%', marginLeft: '48%' }} color="secondary" />;
  } else {
    if (listReportModel.reportData) {
        const onDownloadExcel = () => {
          downloadExcel(reportConfig,props.listReportModel,gridRef);
        };

      const updateFooterRowData = (listReportController: any, disptabelData: any) => {
        let dbChartData = JSON.parse(listReportController.reportData);
        let footerRowData = getFooterRowData(disptabelData, dbChartData, listReportController.reportConfig);
        if (footerRowData && footerRowData.length) {
          let footerValues = _.values(footerRowData[0]);
          if (footerValues && footerValues.length) {
            let footerData = _.filter(footerValues, function (val) {
              return val != "" || val == 0;
            })
            if (footerData?.length) {
              reportConfig['pinnedBottomRowData'] = footerRowData;
              if(gridRef?.current?.api) {
                gridRef.current.api.setPinnedBottomRowData(footerRowData);
              }
            }
          }
        }
      }
      let reportConfig: any = makeHierarchyReportData(listReportModel);
      if(gridRef?.current?.api?.gridBodyCtrl?.rowModel?.rowsToDisplay) {
        let displayRows =  _.map(gridRef?.current?.api?.gridBodyCtrl?.rowModel?.rowsToDisplay,'data');
        let isFooterData = _.filter(displayRows,function(rec) {
          return rec != undefined 
          });
        if(isFooterData?.length) {
          updateFooterRowData(listReportModel,displayRows);
        }
      }
      return (
        <div className="ag-theme-alpine kgm-report-aggrid" style={{height:'inherit'}}>
          {buildReportModal(reportModalModel)}
          <Grid
            className="hide-buttons-from-desktop-report-container"
            container
            style={{ margin: '10px', textAlign: 'right' }}
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={12}>
        <ButtonGroup className="report-rtl-btngroup" variant="text" aria-label="text button group">
          <Button  variant="contained" onClick={() => { onDownloadExcel() }}>
            Download Excel
          </Button>
          </ButtonGroup>
        </Grid>
          </Grid>
          {/* TABLE DISPLAY */}
          <Grid className='kgm-report-hw dashboard-report'>
          <AgGridReact
            ref={gridRef}
            suppressDragLeaveHidesColumns={true}
            columnDefs={reportConfig.columnDefs}
            defaultColDef={reportConfig.defaultColDef}
            getRowHeight={reportConfig.getRowHeight}
            suppressFieldDotNotation={reportConfig.suppressFieldDotNotation}
            rowData={reportConfig.rowData}
            autoGroupColumnDef={autoGroupColumnDef}
            sideBar={reportConfig.sideBar}
            icons={reportConfig.icons}
            groupIncludeTotalFooter = {reportConfig.groupIncludeTotalFooter}
            suppressAggFuncInHeader={reportConfig.suppressAggFuncInHeader}
            pinnedBottomRowData = {reportConfig.hideTotals ? [] : reportConfig.pinnedBottomRowData}
            treeData={true}
            getDataPath={getDataPath}
            groupDefaultExpanded={reportConfig.groupDefaultExpanded}
            excelStyles={excelStyles}
          ></AgGridReact>
          </Grid>
          <div style={{ position: 'absolute', opacity: '0.0' }}>
            <div id="printDiv" style={{ display: 'none' }}></div>
          </div>
        </div>
      );
    } else {
      return <p className="NoDataFound">No Data Found</p>;
    }
  }
};
