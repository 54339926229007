import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../constants/SystemConstants';

export class KagamiSlowApiLogModel {
  state: any;
  isOpen: boolean;
  isfromOpen: boolean;
  rowData: any;
  groupByEvent: boolean;
  nestedEvent: boolean;
  activeEvent: string;
  startTime: string;
  endTime: string;
  turnAroundTime: number;
  user: string;
  toTimeOpen: boolean;
  fromTimeOpen: boolean;
  eventIdFilter: string;
  radioValue: string;
  eventWiseChange: boolean;
  isPaginationPageSelector: boolean;
  nestedEventId: string;
  totalRecords: number | string;
  paginationPageSizeSelector: number;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
  errors: any;

  constructor() {
    this.isOpen = false;
    this.rowData = [];
    this.groupByEvent = false;
    this.nestedEvent = false;
    this.activeEvent = '';
    this.startTime = '';
    this.endTime = '';
    this.turnAroundTime = 2;
    this.user = '';
    this.toTimeOpen = false;
    this.fromTimeOpen = false;
    this.eventIdFilter = '';
    this.radioValue = 'sec';
    this.eventWiseChange = true;
    this.isfromOpen = false;
    this.nestedEventId = '';
    this.totalRecords = '';
    this.paginationPageSizeSelector = 10;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.totalPages = 0;
    this.totalElements = 0;
    this.errors = '';
    this.isPaginationPageSelector = false;
  }
}

export interface KagamiSlowApiLogModelProps {
  kagamiSlowApiLogModel: KagamiSlowApiLogModel;
}

export const wrapKagamiSlowApiLogModelState = (state: State<KagamiSlowApiLogModel>) => {
  let slowLogApiState = state;

  return {
    get isOpen() {
      return slowLogApiState.isOpen.get();
    },
    setIsOpen(value: boolean) {
      slowLogApiState.isOpen.set(value);
    },
    get totalRecords() {
      return slowLogApiState.totalRecords.get();
    },
    setTotalRecords(totalRecords: any) {
      slowLogApiState.totalRecords.set(totalRecords);
    },
    get isfromOpen() {
      return slowLogApiState.isfromOpen.get();
    },
    setFromIsOpen(value: boolean) {
      slowLogApiState.isfromOpen.set(value);
    },
    get rowData() {
      return slowLogApiState.rowData.get();
    },
    setRowData(data: any) {
      slowLogApiState.rowData.set(data);
    },
    get groupByEvent() {
      return slowLogApiState.groupByEvent.get();
    },
    setGroupByEvent(groupByEvent: boolean) {
      slowLogApiState.groupByEvent.set(groupByEvent);
    },
    get nestedEvent() {
      return slowLogApiState.nestedEvent.get();
    },
    setNestedEvent(nestedEvent: boolean) {
      slowLogApiState.nestedEvent.set(nestedEvent);
    },
    get activeEvent() {
      return slowLogApiState.activeEvent.get();
    },
    setActiveEvent(activeEvent: string) {
      slowLogApiState.activeEvent.set(activeEvent);
    },
    get startTime() {
      return slowLogApiState.startTime.get();
    },
    setStartTime(startTime: string) {
      slowLogApiState.startTime.set(startTime);
    },
    get endTime() {
      return slowLogApiState.endTime.get();
    },
    setEndTime(endTime: string) {
      slowLogApiState.endTime.set(endTime);
    },
    get turnAroundTime() {
      return slowLogApiState.turnAroundTime.get();
    },
    setTurnAroundTime(turnAroundTime: number) {
      slowLogApiState.turnAroundTime.set(turnAroundTime);
    },
    get user() {
      return slowLogApiState.user.get();
    },
    setUser(user: string) {
      slowLogApiState.user.set(user);
    },
    get toTimeOpen() {
      return slowLogApiState.toTimeOpen.get();
    },
    setToTimeOpen(toTimeOpen: boolean) {
      slowLogApiState.toTimeOpen.set(toTimeOpen);
    },
    get fromTimeOpen() {
      return slowLogApiState.fromTimeOpen.get();
    },
    setFromTimeOpen(fromTimeOpen: boolean) {
      slowLogApiState.fromTimeOpen.set(fromTimeOpen);
    },
    get radioValue() {
      return slowLogApiState.radioValue.get();
    },
    setRadioValue(radioValue: string) {
      slowLogApiState.radioValue.set(radioValue);
    },
    get eventWiseChange() {
      return slowLogApiState.eventWiseChange.get();
    },
    setEventWiseChange(eventWiseChange: boolean) {
      slowLogApiState.eventWiseChange.set(eventWiseChange);
    },
    get pageNumber() {
      return slowLogApiState.pageNumber.get();
    },
    setPageNumber(pageNumber: number) {
      slowLogApiState.pageNumber.set(pageNumber);
    },
    get pageSize() {
      return slowLogApiState.pageSize.get();
    },
    setPageSize(pageSize: number) {
      slowLogApiState.pageSize.set(pageSize);
    },
    get totalPages() {
      return slowLogApiState.totalPages.get();
    },
    setTotalPages(totalPages: number) {
      slowLogApiState.totalPages.set(totalPages);
    },
    get totalElements() {
      return slowLogApiState.totalElements.get();
    },
    setTotalElements(totalElements: number) {
      slowLogApiState.totalElements.set(totalElements);
    },
    get errors() {
      return slowLogApiState.errors.get();
    },
    setErrors(errors: string) {
      slowLogApiState.errors.set(errors);
    },
    get paginationPageSizeSelector() {
      return slowLogApiState.paginationPageSizeSelector.get();
    },
    setPaginationPageSizeSelector(paginationPageSizeSelector: number) {
      slowLogApiState.paginationPageSizeSelector.set(paginationPageSizeSelector);
    }
  };
};

export const useKagamiSlowApiLogModelState = (state: State<KagamiSlowApiLogModel>) => {
  let kagamiSlowApiLogModelState = wrapKagamiSlowApiLogModelState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.SLOWAPILOG_STATE, kagamiSlowApiLogModelState);
  return kagamiSlowApiLogModelState;
};
