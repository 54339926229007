import { createState } from '@hookstate/core'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { buildModal } from '../../../../../../../../../../../components/modal/controller/KagamiModalController'
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils'
import { buildFileAttachmentsModal } from '../builder/attachments/controller/AttachmentsModalController'
import { KagamiAttachmentsModalModel } from '../builder/attachments/model/KagamiAttachmentsModalModel'
import { KagamiFileModel, KagamiFileModelProps, useKagamiFileModelState } from '../model/KagamiFileModel'
import {  deleteDocument, getFileData, handleImageChange } from '../service/KagamiFileService'
import { KagamiFileStyle } from '../style/KagamiFileStyle'
import Upload_Doc from '../../../../../../../../../../../assets/images/custom-icons/multi_doc_upload.svg';
import ClearIcon from '@mui/icons-material/Clear'; 
import { getFormModel} from '../../../form/controller/KagamiFormController'
import { KagamiDMSModel } from '../../../dms/model/kagamiDMSModel'
import { getDmsViewUrl } from '../../../../../../../../../../service/URLService'
import { buildDms } from '../../../dms/controller/kagamiDMSController'
import { Button } from '@mui/material'




export function buildFileComponent  (kagamiFileModel:KagamiFileModel) {
    return <KagamiFileField kagamiFileModel={kagamiFileModel} />
}

function KagamiFileField(props: KagamiFileModelProps) {
    const {kagamiFileModel} = props;
    console.log(kagamiFileModel.controlType);
  const classes = makeStyles(KagamiFileStyle)();

  let fieldState = useKagamiFileModelState(createState(kagamiFileModel))
  props.kagamiFileModel.state = fieldState;
  getFormModel().formControlStates[kagamiFileModel.controlIdentifier] = fieldState;

  const uiSettingsClassName = props.kagamiFileModel.getControlWidth(props.kagamiFileModel.uiSettings);
  const fontProperties = props.kagamiFileModel.getFontStyling(props.kagamiFileModel.uiSettings);
  let controlValue = CommonUtils.isNotEmpty(kagamiFileModel.controlValue) ?  kagamiFileModel.controlValue.split(',') : []
  let nonEmptyControlValues:any[] =controlValue ? controlValue?.filter((item: any) => item !== ''):[]
  let isMultiUploadFile =   kagamiFileModel.uiSettings?.hasOwnProperty('singleFile') ? !kagamiFileModel.uiSettings.singleFile : true
  let disableFileUpload = !isMultiUploadFile && nonEmptyControlValues.length === 1
  let uploadedFiles = getFileData(kagamiFileModel)
  
  
  //attachments model
  let attachmentsModel: KagamiAttachmentsModalModel =   kagamiFileModel.attachmentsModel
  attachmentsModel.attrName=   kagamiFileModel.controlDisplayName || 'Attachments',
  attachmentsModel.kagamiFileModel =  kagamiFileModel 


  const openDialogBoxForFileUpload =(attachmentsModel:any) => {
    attachmentsModel.state.setOpen(true);
}
if(kagamiFileModel.controlType === 'fileViewer' && CommonUtils.isNotEmpty(kagamiFileModel.controlValue)) {
  const kagamiDMSModel: KagamiDMSModel = new KagamiDMSModel();
  kagamiDMSModel.dmsResponseUrl = getDmsViewUrl(kagamiFileModel.controlValue);
  return buildDms(kagamiDMSModel);

} 



  if (!fieldState.isVisible) {
    return <></>
  } else {
    return (
      <>
        <div className={`${uiSettingsClassName}`}>
          <div className={classes.root}>
          <div className={classes.read_only}>
          <FormLabel  required={fieldState.isMandatory}
                 style={{
                  color:fontProperties.labelFontColor,
                  fontWeight: fontProperties.labelFontBold ,
                  fontStyle: fontProperties.labelFontItalic,
                  textDecoration: fontProperties.labelFontUnderline,
                  display:'flex',
                  alignItems:'center',
                  gap:'10px'
                 }}
                component="span" ><span>{kagamiFileModel.controlDisplayName}</span>
              { !fieldState.isReadOnly && !disableFileUpload   && 
              <>
              <input
              id={kagamiFileModel.controlIdentifier+'input'}
              type="file"
              style={{ display: 'none' }}
              onChangeCapture={(event) => {
                handleImageChange(event, kagamiFileModel);
               event.currentTarget.value = ''
               }}
       
              onSuspend={(event) => {
                handleImageChange(event, kagamiFileModel);
              }}
              multiple ={isMultiUploadFile}
            />
            <label htmlFor={kagamiFileModel.controlIdentifier+'input'} style={{cursor: disableFileUpload ? 'not-allowed' :'pointer' }}>
           
              <img style={{ width: 23, height: 23  }} alt="Upload" src={Upload_Doc}  />
            
          </label>
              </>
             }
          </FormLabel>
           
                <>
                   <div style={{ display: 'flex' , position:'relative'}}>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' , flexWrap:'wrap' }}>
          <>
             {buildThumbnails(
              kagamiFileModel , classes
            )}
           { uploadedFiles.length > 3 ? (
              <h4
                style={{ margin: '0PX', cursor:"pointer" }}
                onClick={() => openDialogBoxForFileUpload(  attachmentsModel)}
              >
                {' '}
                + {uploadedFiles.length -
                  3} more{' '}
              </h4>
            ) : <></>} 
            
           
           { fieldState.isReadOnly && CommonUtils.isEmpty(props.kagamiFileModel.controlValue)
           ?<Typography variant="subtitle1" style={{ fontSize: '14px', color: '#cbc8c8' }}>
              ----
            </Typography> 
           :<></>
           }
           
           
          </>
        </div>
        
      </div>
                </>
             
          </div>
          </div>
          <div> 
            {CommonUtils.isEmpty(uploadedFiles) &&  
            <Typography color='error' variant='caption' >{kagamiFileModel.state.errorMessage}</Typography> }
              </div>
          </div>
          {buildFileAttachmentsModal(attachmentsModel)}
          {buildModal(props.kagamiFileModel.popupModel)}
      </>
    );
  }

} 
export default KagamiFileField

 


function  buildThumbnails (kagamiFileModel:KagamiFileModel , classes :any) {

  let controlValue = CommonUtils.isNotEmpty(kagamiFileModel.controlValue) ?  kagamiFileModel.controlValue.split(',') : []
  let nonEmptyControlValues = controlValue.filter((item:any) => item) ;
  let controlValuedocids:any[] =[] ;
  nonEmptyControlValues.map((item:any) => {
      if (item.includes('.')){
        controlValuedocids.push( item.split(':')[0] ) ;
      } else {
        controlValuedocids.push(item)
      }
    })
 
 
  let popupModel = kagamiFileModel.popupModel;


//DOCiNFO FROM ONSTARTDATA
  let docInfoData  = CommonUtils.isNotEmpty(kagamiFileModel.docInfo) ? kagamiFileModel.docInfo : {}; //using document data from onStartdata
  
  //docdata from new uploads
  let newuploadsDocInfo = CommonUtils.isNotEmpty(kagamiFileModel.newUploadsDocInfo) ? kagamiFileModel.newUploadsDocInfo : {}

  


  return controlValuedocids.map((item :any, i:any) => {
    let fileId = item;
    let fileName = '';
    let fileType= '';
    let baseUrl = '';
    let fileString = ''
    if(Object.keys(docInfoData).includes(item )) {
      
      fileName = docInfoData[item]['fileName'];
      fileType = docInfoData[item]['fileType'] 
      baseUrl  = docInfoData[item]['thumbnail'] 
      fileString = fileId + ":" + fileName

  } else if(Object.keys(newuploadsDocInfo).includes(item )){

    fileName  =  newuploadsDocInfo[item]['fileName'];
    fileType  =  newuploadsDocInfo[item]['fileType'] ;
    baseUrl   =   newuploadsDocInfo[item]['thumbnail'] ;
    fileString = fileId + ":" + fileName

  } else {
    
    baseUrl =''
    fileString = fileId 
  }


 if( i < 3) {


    return (
          <div key={i + '_file'}  style={{position:'relative'}} >

          {CommonUtils.isNotEmpty(baseUrl) 
            ?  <>
            <img title={fileName}
              onClick={(event: any) => {      
              CommonUtils.handlePreviewOrDownloadFileNew( kagamiFileModel, popupModel, fileString);
              }}
              className={classes.thumbnailImage}
              
              src={`data:image/png;base64,${baseUrl}`}
          />
            </>
            : <>
                <img
                   style={{ width: 30, height: 30  }}
                   alt={fileName} title={fileName} 
                   src={CommonUtils.getFileTypeICon(fileType)}
                   onClick={(event: any) => {            
                   CommonUtils.handlePreviewOrDownloadFileNew( kagamiFileModel, popupModel, fileString);
                }}
                />
            </>
            }




            <>
            {
                 //showing delete icon for non readOnly
                //  !kagamiFileModel.state.isReadOnly 
                kagamiFileModel.isReadOnly === false
                 &&    ( 
                 <div
                 title="Del image"
                 className={classes.deleteIconContainer}
                 
                 >
                   <ClearIcon
                     sx={{
                       fontSize: '13px'
                     }}
                     fontSize="small"
                     color="error"
                     onClick={() => {
                      deleteDocument( 
                         fileId,
                         kagamiFileModel
                       );
                     }}
                   />
                   {CommonUtils.isEmpty(fileId) &&
                    <Typography color='error' variant='caption' >{kagamiFileModel.state.errorMessage}</Typography>}
                  
                 </div> )   
                           
             }

            </>
          </div>
        
  ) 

 }



  })
}



export function buildDownloadButton(title:string,  fileId:string, fileName:string) {
  return (<div style={{display:'flex', gap:'15px', alignItems:'center'}}>
    <p style={{margin:'0'}}>{title}</p>
    <Button variant='outlined' color='primary' onClick={() => CommonUtils.downloadFile(fileId, fileName)}>download</Button>
  </div>)
}