import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const KagamiCardStyle = (theme: Theme) =>
  createStyles({
    container: {
      'width': '100%',
      'height': '100%',
      '& .box-title': {
        'width': '100%',
        'height': '50px',
        'backgroundColor': 'transparent',
        'borderBottom': '1px solid ' + theme.palette.grey[400],
        'color': '#006e7f',
        'display': 'flex',
        'alignItems': 'center',

        '&:hover': {
          cursor: 'grab'
        },
        '&:active': {
          cursor: 'grabbing'
        },

        '& .title-icon-visible': {
          visibility: 'visible',
          margin: '0 7px'
        },

        '& .title-icon-invisible': {
          visibility: 'hidden'
        },

        '& .title': {
          fontSize: '13px',
          fontWeight: 600,
          lineHeight: 1,
          paddingRight: '16px',
          textTransform: 'uppercase',
        }
      },
      '& .box-content': {
        'width': '100%',
        'height': 'calc(100% - 50px)',
        'minHeight': 'calc(100% - 50px)',
        'fontSize': '.875rem',
        'color': theme.palette.info.main,
        '&::-webkit-scrollbar': {
          width: theme.spacing(1),
          height: theme.spacing(1)
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.4)'
        },
        '&::-webkit-scrollbar-track': {
          background: 'hsla(0,0,0,.2)'
        }
      }
    }
  });
