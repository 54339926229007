import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const DashboardReportsStyle = (theme: Theme) =>
  createStyles({
    root: {
      'border': '1px solid #eee',
      'borderRadius': '4px',
      '& .hide-buttons-from-desktop-report-container': {
        display: 'none'
      },
      '&:hover': {
        '& .VisibleExpandedButton': {
          visibility: 'visible'
        }
      }
    },

    dashboard_header: {
      'display': 'flex',
      'color': '#006e7f',
      'maxHeight': '30px',
      'height': '30px',
      'width': 'inherit',
      '& .dashboard-handle-drag': {
        'margin': 'auto .6rem',
        'width': '80%',
        'whiteSpace': 'nowrap',
        'overflow': 'hidden',
        'textOverflow': 'ellipsis',
        '&:hover': {
          cursor: 'grab'
        },
        '&:active': {
          cursor: 'grabbing'
        }
      },
      '& .handle-filter-and-popup-icon': {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '17%',
        margin: '2px',
        '& .expandedButton': {
          'visibility': 'hidden',
          'height': 'fit-content',
          'width': 'fit-content',
          '&:hover': {
            cursor: 'pointer'
          },
          '& .expandedIcon': {
            width: '24px',
            padding: '3px',
            margin: '4px 0px'
          }
        },
        '& .filterIcon': {
          '&:hover': {
            cursor: 'pointer'
          },
          '&>svg': {
            margin: '4px 0px'
          }
        },
      }
    },
    dashboard_content: {
      'height': 'calc(100% - 30px)',
      'width': 'inherit',

      // '&::-webkit-scrollbar':{
      //   display:'none',
      // },

      '& .dashboard-list-report': {
        'marginTop': '5px',
        '& .ag-root-wrapper-body': {
          'borderRadius': '0px',
          'border': '1px solid #eee'
        }
      },
      '& .dashboard-pie-report-container': {
        'margin': '0px !important',
        'width': 'inherit',
        'height': '100%',
        '& .dashboard-chart-grid-container': {
          'padding': '0px !important',
          'height': '100%',
          '& .dashboard-highchart': {
            'height': '100%',
            '& div:first-child': {
              'height': 'inherit',
              '& .highcharts-container': {
                'height': 'inherit !important',
                '& .highcharts-root': {
                  'height': '100%',
                  '& .highcharts-credits': {
                    display: 'none'
                  }
                }
              }
            }
          }
        },

        '& .dashboard-no-margin': {
          margin: '0px !important'
        }
      },

      '& .dashboard-custome-report-container': {
        height: '100%',
        '& .custom-tiles-container': {
          height: 'inherit',
          '& .custom-tile-box': {
            margin: '0px',
            height: 'inherit',
            width: 'inherit',
            overflow: 'hidden',
            '& .card-box': {
              height: 'inherit',
              '& .chart-area': {
                height: 'inherit',
                '& .gauge-container, & .doughnut-container': {
                  height: 'inherit',
                  '& .chart-box': {
                    height: 'inherit',
                    '& > div': {
                      height: 'inherit',
                      '& .highcharts-container': {
                        height: 'inherit',
                        '& .highcharts-root': {
                          height: 'inherit',
                        }
                      }
                    }
                  }
                },

                '& .circle-container':{
                  height: 'inherit',
                  '& .chart-box': {
                    position: 'absolute',
                    width: 'calc(100% / 4)',
                    height: '90%',
                    '& > div': {
                      height: 'inherit',
                      '& .highcharts-container': {
                        height: 'inherit',
                        '& .highcharts-root': {
                          height: 'inherit',
                        }
                      }
                    }
                  },
                  '& .icon-box': {
                    position: 'relative',
                    top: '39%',
                    left: '95%',
                    width: 'fit-content',
                    zIndex: 1,
                  }
                }
              }
            },
            '& .custom-tile-matrix-card': {
              width: 'inherit',
              height: 'inherit',
              '& .chart-area': {
                width: 'inherit',
                height: 'inherit',
                overflow: 'hidden',
                '& .matrix-widget-container': {
                  width: 'inherit',
                  maxHeight: '200px',
                  '& .key-header': {
                    height: '100%', 
                    display: 'flex', 
                    alignItems: 'center'
                  },
                  '& .key-body': {
                    height: '100%', 
                    display: 'flex', 
                    alignItems: 'center',
                    '& .value-box': {
                      width: '100%',
                      height: '100%',
                      '& .value-item': {
                        padding: 'calc(100% / 50)', 
                        margin: 'calc(100% / 50)',
                        display: 'flex',
                        justifyContent: 'center',
                        '& .items': {
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },

      '& .dashboard-doughnut-container, & .dashboard-column-container': {
        width: 'inherit',
        margin: '0px',
        padding: '0px',
        height: '100%',
        '& .dashboard-doughnut-chart-container, & .dashboard-column-chart-container': {
          margin: '0',
          padding: '0',
          height: '100%',
        },
        '& .dashboard-table-container': {
          padding: '0',
          '& .dashboard-download-button': {
            display: 'none',
          },
          '& .dasboard-column-table-container': {
            height: '100%',
            
          }
        },
        '& .dashboard-no-margin': {
          display: 'none'
        }
      },

      '& .dashboard-highchart': {
        'height': '100%',
        '& div:first-child': {
          'height': 'inherit',
          '& .highcharts-container': {
            'height': 'inherit !important',
            '& .highcharts-root': {
              'height': '100%',
              '& .highcharts-credits': {
                display: 'none'
              }
            }
          }
        }
      },

      '& .NoDataFound': {
        color: 'red',
        fontSize: '12px',
        margin: '10px',
        padding: '0px'
      },
      '& .dashboard-report': {
        // height: 'calc(100% - 50px)'
        height: 'inherit'
      },
      '& .static-report-grid': {
        padding: '30px'
      },
      '& .ag-status-bar': {
        display: 'none'
      },
      '& .Report-MuiFormControl-root': {
        paddingTop: '15px !important',
        height: 'inherit'
      },
      '& .custom-dashboard-tile-report': {
        width: 'calc(100% + -22px)',
        margin: 'inherit'
      }
    },

    dashboard_header_report_name: {
      fontSize: '13px',
      fontWeight: '600'
    },

    dashboard_report_filter: {
      '& .drawer-filter-header': { 
        height: 'fit-content', 
        zIndex: '2', 
        margin: '8px', 
        fontWeight:'bold' 
      }, 
      '& .drawer-filter-content': {

      },
      '& .drawer-filter-footer': {
        width:'100%', 
        textAlign:'right', 
        padding:'1%',

        '& .apply-filter-button2': {
          background: '#007ecc', 
          borderColor:'#007ecc', 
          color: '#fff'
        }
      },
    },
  });
