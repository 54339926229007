import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const KagamiHierarchicalViewStyle = ((theme:Theme) => createStyles({
    container: {
      maxWidth: "100vw",
      // overflow:"hidden",
      // overflow: "auto",
      // maxHeight: "100vh",
      background: 'rgb(245 248 250)',
    },
  }));
  