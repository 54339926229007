import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
export const KagamiSelectFieldStyle = (theme: Theme) =>
  createStyles({
    searchPopper: {
      // zIndex: '5'
    },
    searchPaper: {
      // background: '#fff',
      // backgroundColor: '#fff !important',
      '& .MuiAutocomplete-listbox': {
      // backgroundColor: '#fff !important',

      }

    },
    paperForSearchPopper: {
      background:'#fff',
      // border: "5px solid black"
      '& .MuiAutocomplete-option': {
        fontSize:'13px',
        paddingTop:'5px',
        paddingBottom:'5px',
      },
      '& .MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor:'#fff'
      },
      '& .MuiAutocomplete-option:hover ':{
        backgroundColor:'#006e7f10'
      }
    },
    root: {
      '&  .MuiAutocomplete-popupIndicator': {
        color: theme.palette.secondary.main,
      },  
      '& .MuiAutocomplete-clearIndicator' : {
        color:theme.palette.error.main
      },
    //   '& .MuiFormControl-root': { 
    //     // height: '28px', 
    //     '& .MuiFormHelperText-root': {
    //       marginTop: '-3px'
    //     }, 
    //   },
    //   '& .css-8bc6am-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
    //     padding:'2.5px 4px 2.5px  8px'
    //   },
      
    //   '& .css-3q55mg-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
    //     // backgroundColor: theme.palette.background.default
    //     backgroundColor:'#fcfcfc'
    //   },
    //   '&.css-1kv37ap-MuiAutocomplete-listbox .MuiAutocomplete-popper li :hover ': {
    //     // backgroundColor: 'rgba(0, 51, 102, 0.08)'
    //     backgroundColor:'#fcfcfc'
    // },
    
    // '& .MuiAutocomplete-popper li:hover':{
    //   // backgroundColor: 'rgba(0, 51, 102, 0.08)',
    //   backgroundColor:'#fcfcfc'
    // },


    //   '& .input': {
    //   fontSize:'0.9rem'
    // },


    //   '& .MuiSelect-select ': {
    //   fontSize:'0.9rem', 

    //   },


    //   '& .css-8bc6am-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall':{
    //     paddingTop: '5px'
    //   },
    //   // '& input': {
    //   //   fontSize: theme.typography.caption.fontSize
    //   // },
    //   '& .css-1pplsn3-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
    //     fontSize:'14px'
    //   },
     
 
    //   '& .MuiOutlinedInput-root': {
    //     // 'background': theme.palette.common.white,
    //     height: '28px', 
    //     color: theme.palette.primary.dark
     
    //   },
    //   '& .MuiAutocomplete-popper': {
    //     '& li': {
    //       fontSize: `${theme.typography.caption.fontSize} !important`
    //     }
    //   }
    },
    // searchPaper: {
    //   background: '#fff',
    //   backgroundColor: '#fff !important',
    //   '& .MuiAutocomplete-listbox': {
    //   backgroundColor: '#fff !important',

    //   }

    // },
    root1: {
      '&  .MuiAutocomplete-popupIndicator': {
        color: theme.palette.secondary.main,
      },
      '& .MuiAutocomplete-clearIndicator' : {
        // color:'#ff7a49'
        color:theme.palette.error.main

      },
      '& .MuiFormControl-root': { 
        // height: '28px', 
        // '& .MuiFormHelperText-root': {
        //   marginTop: '-3px'
        // }, 
      },
      // '& .css-3q55mg-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
      //   backgroundColor: '#fff'
      // },
      // '& .css-8bc6am-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
      //   padding:'2.5px 4px 2.5px  8px'
      // },

      // '& .css-1kv37ap-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
      //   backgroundColor: '#fff'
      // },
      // '& .css-1kv37ap-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]:hover': {
      //   backgroundColor: 'rgba(0, 51, 102, 0.08)'
      // },
    
      // '& .MuiAutocomplete-listbox .MuiAutocomplete-option': {
      //   // backgroundColor: theme.palette.background.default,
      //   backgroundColor:'#fcfcfc'
  
      //   },
      //   '& .MuiAutocomplete-listbox .MuiAutocomplete-option:hover': {
      //     backgroundColor: "#e6faf3",
    
      //     },
    // '& .input': {
    //   fontSize:'0.9rem'
    // },
    //   '& .MuiSelect-select ': {
    //   fontSize:'0.9rem', 

    //   },
    //  '& .css-8bc6am-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall':{
    //     paddingTop: '5px'
    //   },
     
    //   '& .css-1pplsn3-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
    //     fontSize:'14px'
    //   },
     
 
      // '& .MuiOutlinedInput-root': {
      //   // 'background': theme.palette.common.white,
      //   height: '28px', 
      //   color: '#0059B3'
     
      // },
      // '& .MuiAutocomplete-popper': {
      //   '& li': {
      //     fontSize: `${theme.typography.caption.fontSize} !important`,
      //     // background: theme.palette.background.default
      //     background:'#fcfcfc'
      //   }
      // }
    },
    selectRoot: {

      '&  .MuiSelect-icon ': {
        color: theme.palette.secondary.main,
      },
     
      
    },
    
    selectPaper: {
      borderColor:"#fff",
      minHeight:'30px',
      // height: "220px",
      '& .MuiMenuItem-root ': {
        color:theme.palette.info.main
      },
      '& .MuiMenuItem-root': {
        fontSize: '13px',
        paddingTop:'5px',
        paddingBottom:'5px',
      },
      '& .MuiMenuItem-root:hover':{
        backgroundColor:'#006e7f10'

      }
      // backgroundColor: theme.palette.background.default,

      // '& ul.MuiList-root': {
      //   '& li': {
      //     fontSize: '13px',
      //     paddingTop:'5px',
      //     paddingBottom:'5px',

      //   }
      // }
    },
    controlLabel: {
      '&.MuiFormLabel-root ': {
        fontSize: '13px',
        color: theme.palette.info.dark
      }
    },
    readOnlyField: {
      margin: '0',
      fontSize: '14px',
      color: theme.palette.primary.dark,
      wordWrap:'break-word'
    },
    readOnlyEmpty:{
      color:'#ccc !important',
  },
  
    chipStyle: {
      '&.MuiChip-root': {
        width: 'fit-content',
        padding: `0 ${theme.spacing(1)}px`,
        fontSize: theme.typography.caption.fontSize
      },
      '&.MuiChip-deleteIcon': {
        color:theme.palette.error.main
      }
    },
    readOnlyField_cont: {
      // paddingLeft: theme.spacing(1.6),
      // marginTop: theme.spacing(-1.375)
    },
    
    popOverFont:{
      fontSize: theme.typography.caption.fontSize, 
    },
    mainVendorContainer: {
      textAlign: 'left'
    },
    popOverDelete:{
      fontSize: 'medium'
    },
   
  });




  export const KagamiMultiSelectFieldStyle = (theme: Theme) =>
  createStyles({

    root: {
      '&  .MuiAutocomplete-popupIndicator': {
        color: theme.palette.secondary.main,
      },
      '& .MuiAutocomplete-clearIndicator' : {
        // color:'#ff7a49'
        color:theme.palette.error.main

      },
      '& .MuiFormControl-root': {  
        // background: '#fff' ,
        // '& .MuiFormHelperText-root': {
        //   marginTop: '-3px'
        // },
      },

      

      '& .MuiChip-clickable':{
        borderRadius: '0',
        borderBottom: `1px solid ${theme.palette.primary.dark}`,
        // border: '1px solid #ccc',
        height: '23px',
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
        '& .MuiChip-label':{
          paddingLeft: '0',
          paddingRight: '0'
        }
      },



    '& .MuiChip-root': {  
      '&.MuiAutocomplete-tag': {
        margin: '1px',
        // marginRight: '100px',
        display: 'flex',
        height: '23px',
        borderRadius: '0',
        border: '1px solid #ccc',
        // backgroundColor: theme.palette.background.default,
        backgroundColor:'#f5f8fa',
        '& .MuiChip-label' :{
          paddingLeft:theme.spacing(1),
          fontSize: theme.typography.caption.fontSize,
        },
        '& .MuiChip-deleteIcon': {
          fontSize:'14px',
          color:theme.palette.error.main
        }
      }
    },

    // '& .input': {
    //   fontSize:theme.typography.caption.fontSize
    // },


      // '& .MuiSelect-select ': {
      // fontSize:'10px',
      // // background: '#fff' 

      // },


      // '& .css-8bc6am-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall':{
      //   paddingTop: '5px'
      // },
      // '& input': {
      //   fontSize: theme.typography.caption.fontSize
      // },
      // '& .css-1pplsn3-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
      //   fontSize: theme.typography.caption.fontSize
      // },
      // '& .css-8bc6am-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':{
      //   // paddingTop:'0.5px'
      // },
       
    },
    root1: {
      '&  .MuiAutocomplete-popupIndicator': {
        color: theme.palette.secondary.main,
      },
      '& .MuiAutocomplete-clearIndicator' : {
        // color:'#ff7a49'
        color:theme.palette.error.main

      },
      '& .MuiFormControl-root': { 
        // height: '28px', 
        // '& .MuiFormHelperText-root': {
        //   marginTop: '-3px'
        // }, 
      },
      // '& .css-8bc6am-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
      //   padding:'2.5px 4px 2.5px  8px'
      // },

 
      // '& .css-1kv37ap-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
      //   // backgroundColor: theme.palette.background.default
      //   backgroundColor: '#fcfcfc'
      // },
     
      // '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAutocomplete-paper.css-4ip2q2-MuiPaper-root-MuiAutocomplete-paper':{
      //   // backgroundColor: '#ECF8FF !important'
      //   backgroundColor:'#fcfcfc'
      // },

    '& .input': {
      fontSize:'0.9rem'
    },


      '& .MuiSelect-select ': {
      fontSize:'0.9rem', 

      },


      // '& .css-8bc6am-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall':{
      //   paddingTop: '5px'
      // },
      // // '& input': {
      // //   fontSize: theme.typography.caption.fontSize
      // // },
      // '& .css-1pplsn3-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
      //   fontSize:'14px'
      // },
     
 
      '& .MuiOutlinedInput-root': {
        // 'background': theme.palette.common.white,
        height: '28px', 
        color: '#0059B3'
     
      },
      '& .MuiAutocomplete-popper': {
        '& li': {
          fontSize: `${theme.typography.caption.fontSize} !important`
        }
      }
    },
    selectRoot: {

      '&  .MuiSelect-icon ': {
        color: theme.palette.secondary.main,
      },
    },
    select: {
      
      // backgroundColor: theme.palette.background.default,
      backgroundColor:'#f5f8fa',
      '& .MuiMenuItem-root ': {
        color:theme.palette.info.main
      },
      '& ul.MuiList-root': {
        '& li': {
          fontSize: theme.typography.caption.fontSize
        }
      }
    },
    controlLabel: {
      '&.MuiFormLabel-root ': {
        fontSize: '13px',
        color: theme.palette.info.dark
      }
    },
    readOnlyField: {
      margin: '0',
      fontSize: '14px',
      color: theme.palette.primary.dark,
    },
    


    chipStyle: {
      '&.MuiChip-root': {
        width: 'fit-content',
        color:theme.palette.primary.dark,
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
        fontSize: theme.typography.caption.fontSize,
        borderRadius: '100px',
        backgroundColor: 'rgba(0,0,0,0.08)',
        border: 'none'
      }
    },
    kgmSearchOptions: {
      
      '& li': {
        fontSize: `${theme.typography.caption.fontSize} !important`
      }
  },
  readOnlyEmpty:{
    color:'#ccc !important',
},
    readOnlyField_cont: {
      // paddingLeft: theme.spacing(1.6),
      // marginTop: theme.spacing(-1.375)
    },
    popOverFont:{
      fontSize: theme.typography.caption.fontSize, 
    },
    mainVendorContainer: {
      textAlign: 'left'
    },
    popOverDelete:{
      fontSize: 'medium'
    },
 
  })