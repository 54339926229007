import { getDmsViewUrl } from '../../../../../../../../../../../../service/URLService';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { buildDms } from '../../../../../../../process/builder/dms/controller/kagamiDMSController';
import { KagamiDMSModel } from '../../../../../../../process/builder/dms/model/kagamiDMSModel';
import { buildLedgerDetails } from '../../ledgerDetails/controller/LedgerDetailsController';
import { LedgerDetailsModel } from '../../ledgerDetails/model/LedgerDetailsModel';
import { LedgerAccountReportModel } from '../model/LedgerAccountReportModel';
import { BuildLedgerAccountReportView } from '../ui/LedgerAccountReport';

let ledgerAccountReport: LedgerAccountReportModel;

export function buildLedgerAccountReport(ledgerAccountReportModel: LedgerAccountReportModel) {
    ledgerAccountReport = ledgerAccountReportModel
    return BuildLedgerAccountReportView(ledgerAccountReportModel);
  }

export const handleOnLedgerClick = (ledgerInputs:any, row:any) => {
  if(row.data !== undefined){
    if(CommonUtils.isNotEmpty(row.data['otherDeatils']) && CommonUtils.isNotEmpty(row.data['otherDeatils']['file'])){
      let kagamiDMSModel:KagamiDMSModel = new KagamiDMSModel();
      kagamiDMSModel.dmsResponseUrl = getDmsViewUrl(row.data['otherDeatils']['file']);
      ledgerAccountReport.modelPopup.title = 'preview file';
      ledgerAccountReport.modelPopup.maxWidth = 'xl';
      ledgerAccountReport.modelPopup.content = buildDms(kagamiDMSModel);
      ledgerAccountReport.modelPopup.pdfIconVisible = false;
      ledgerAccountReport.modelPopup.state.setOpen(true);
    }else{
      const ledgerDetailsModel = new LedgerDetailsModel();
      ledgerDetailsModel.ledgerInputs = ledgerInputs;
      ledgerAccountReport.modelPopup.title = ledgerAccountReport.reportConfig.reportConfigSummary.name;
      ledgerAccountReport.modelPopup.content = buildLedgerDetails(ledgerDetailsModel);
      ledgerAccountReport.modelPopup.pdfIconVisible = true;
      ledgerAccountReport.modelPopup.state.setOpen(true);
    }
  }
}