



import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const kagamiPresentationFilterstyle = (theme: Theme) =>
  createStyles({
    root: {
        display: 'flex',
        textTransform: 'capitalize',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: theme.spacing(0.5),
        margin: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(1)}`,
        border: '1px solid #BABFC7',
        padding:`${theme.spacing(1.6)} ${theme.spacing(1)}`
    },
    listItems: {
      width: '100%',
      maxHeight: '260px',
      overflowY: 'scroll', 
      overflowX: 'hidden',
      "&::-webkit-scrollbar":{
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      '&::-webkit-scrollbar-thumb' :{
          backgroundColor: 'rgba(0,0,0,.4)',
      },
      '&::-webkit-scrollbar-track' :{
          background: 'hsla(0,0,0,.2)',
      },
    },

    filtersListBody: {
      maxWidth: theme.spacing(25),
      maxHeight: '300px',
      padding: theme.spacing(0.5),
      backgroundColor: theme.palette.background.default,
      '& .MuiOutlinedInput-root': {
        // '& fieldset': {
        //   borderColor: 'red',
        // },
        // '&:hover fieldset': {
        //   borderColor: 'yellow',
        // },
        '&.Mui-focused fieldset': {
          border: '0.8px solid',
        },
      },
     
      '& .MuiInputBase-root': {
        height: theme.spacing(3.75),
        fontSize: theme.typography.subtitle1.fontSize
      } ,
      '& .MuiListItemButton-root':{
        
        padding:`0 ${theme.spacing(1)}`,
        '&:hover':{
          backgroundColor:'#006e7f10'
        }
      },
      '& .MuiCheckbox-root': {
        padding: 0
      },
      '& .MuiListItemIcon-root ':{
        minWidth: theme.spacing(4),
        '& .MuiCheckbox-root':{
          marginLeft: '0'
        }

      },
      '& .MuiListItemText-root .MuiTypography-root ':{
        fontSize: theme.typography.caption.fontSize
      }
    },

    numberFilterListBody: {
      maxHeight: '23rem',
      padding: '.5rem',
      maxWidth: '16rem'
    },

    listItem: {
      color:theme.palette.info.main
    },
    filterLoading: {
      textAlign: 'center',
    },
    filterCircle: {
      color: theme.palette.secondary.main,
    },
    numberFilterInputBoxes: {
      display: 'flex',
      flexDirection: 'column'
    },
    numbertFilterFormControl: {
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'center', 
      marginBottom: ' 0.625rem' 
    },
    numbertFilterFormLabel: {
      flex: '0 0 15%', 
      minWidth: '15%', 
      padding: '0rem .25rem', 
      fontWeight: 700
    },
    numberFilterTextField: {
      flex: '1 0 85%', 
      minWidth: '85%'
    },
    numberFilterRangeFormControl: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '0.3125rem', 
      marginBottom: ' 0.625rem'
    },
    numberFilterRangeTextField: {
      minWidth: '4.6875 rem'
    },
    numberFilterSearchButton: {
      marginBottom: '0.375rem' 
    }
  });
