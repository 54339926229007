import { Button, ButtonGroup, Menu, MenuItem, Grid } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { downloadExcel, downloadPDF, onDetailedExcelDownload } from '../../view/report/builder/ledgers/ledgerAccounts/service/LedgerExportService';
import { downloadExcel as tbDownloadExcel } from '../../view/report/builder/ledgers/trialBalance/service/TrialBalanceReportService';
import { downloadExcel as bsDownloadExcel } from '../../view/report/builder/ledgers/balanceSheet/service/BalanceSheetReportService';
import { downloadExcel as plDownloadExcel } from '../../view/report/builder/ledgers/profitloss/service/ProfitLossReportService';
import { downloadExcel as ageingDownloadExcel, downloadPDF as ageingDownloadPDF } from '../../view/report/builder/ledgers/ageingReport/service/AgeingReportService';
import { downloadExcel as paymentDueListDownloadExcel, downloadPDF as pdlDownloadPDF } from '../../view/report/builder/ledgers/paymentDueList/service/PaymentDueListReportService';
import { downloadExcel as daybookDownloadExcel, downloadPDF as dayBookDownloadPDF } from '../../view/report/builder/ledgers/dayBook/service/DayBookExportService';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getStaticReportActions } from '../service/StaticReportUtil';

export const StaticReportActions = (props: any) => {
    let config = props.config;
    let translation: any = config?.reportModel?.languageTranslation || {};
    let actions = _.cloneDeep(getStaticReportActions(config?.inputReportConfig?.reportConfigSummary?.type));

    const [actionMenus, setActionMenus] = React.useState([]);
    const [buttonAnchorEl, setButtonAnchorEl] = React.useState<null | HTMLElement>(null);
    const buttonOpen = Boolean(buttonAnchorEl);
    const buttonHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        let buttonId = (event.target as HTMLButtonElement).id || (event.target as HTMLButtonElement)?.parentElement?.id;
        let actionIndex: any = buttonId?.split("_")?.[1];
        if (actionIndex >= 0 && actions[actionIndex]?.actions) {
            setActionMenus(actions[actionIndex]?.actions);
        } else {
            setActionMenus([]);
        }
        setButtonAnchorEl(event.currentTarget);
    };

    const buttonHandleClose = () => {
        setButtonAnchorEl(null);
    };
    const onDetailedExcel = () => {
        onDetailedExcelDownload(config?.inputReportConfig);
    };
    const onDownload = (ele: any, action: any) => {
        setButtonAnchorEl(null);
        switch (ele?.params?.reportType) {
            case 'LEDGER':
                action == 'EXCEL' ? downloadExcel(ele?.params?.index, config?.reportModel) : downloadPDF(ele?.params.printType, ele?.params?.index, config?.reportModel);
                break;
            case 'BLReport':
                action == 'EXCEL' && bsDownloadExcel(config?.reportModel);
                break;
            case 'PLReport':
                action == 'EXCEL' && plDownloadExcel(config?.reportModel);
                break;
            case 'TrialBLReport':
                action == 'EXCEL' && tbDownloadExcel(config?.reportModel);
                break;
            case 'AGEING':
                action == 'EXCEL' ? ageingDownloadExcel(config?.reportModel) : ageingDownloadPDF(config?.reportModel);
                break;
            case 'PAYMENTDUELIST':
                action == 'EXCEL' ? paymentDueListDownloadExcel(config?.reportModel) : pdlDownloadPDF(config?.reportModel);
                break;
            case 'DAYBOOK':
                action == 'EXCEL' ? daybookDownloadExcel(ele?.params?.index, config?.reportModel) : dayBookDownloadPDF(ele?.params.printType, ele?.params?.index, config?.reportModel);
                break;
            default:
                return;
        }

    };
    const handleAction = (ele: any) => {
        let eventType = ele?.eventType;
        switch (eventType) {
            case 'onDownloadExcel':
                onDownload(ele, 'EXCEL')
                break;
            case 'EXPAND':
                config.expandAll();
                break;
            case 'onDetailedExcel':
                onDetailedExcel();
                break;
            case 'onDownloadPdf':
                onDownload(ele, 'PDF')
                break;
            default:
                return '';
        }
    };


    return (
        <div className='static-report-exports-area'>
            <Grid container style={{ margin: '10px', textAlign: 'right' }} direction="row" justifyContent="flex-end" alignItems="flex-end" >
                <ButtonGroup >
                    {actions.map((ele: any, index: any) => {
                        if (ele.type == 'BUTTONS_LIST') {
                            return (
                                <div key={index}>
                                    <Button
                                        style={{ margin: '2px', textAlign: 'center' }}
                                        id={'actionbutton_' + index}
                                        variant="contained"
                                        onClick={buttonHandleClick}
                                    >
                                        {translation[ele.label] || ele.label}
                                        <ArrowDropDownIcon />
                                    </Button>
                                    <Menu
                                        id="action-menu"
                                        anchorEl={buttonAnchorEl}
                                        open={buttonOpen}
                                        onClose={buttonHandleClose}
                                    >
                                        {(actionMenus || [])?.map((innerElement: any) => {
                                            return <MenuItem onClick={() => handleAction(innerElement)}> {translation[innerElement.label] || innerElement.label}   </MenuItem>
                                        })
                                        }

                                    </Menu>
                                </div>
                            );
                        } else {
                            return (
                                <Button style={{ margin: '2px', textAlign: 'center' }} variant="contained" onClick={() => { handleAction(ele) }}>
                                    {translation[ele.label] || ele.label}
                                </Button>
                            );
                        }
                    })}
                </ButtonGroup>
            </Grid>
        </div>
    )
}


