import { createState } from '@hookstate/core';
import {
  CustomTileReportModel,
  CustomTileReportModelProps,
  useCustomTileReportModelState
} from '../model/CustomTileReportModel';
import { makeCustomTile } from '../service/CustomTileReportService';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import { CustomWidget } from './CustomWidget';

export const BuildCustomTileReportView = (customTileReportModel: CustomTileReportModel) => {
  return (
    <KagamiCustomTileChartReportView key={'customTile-chart-report'} customTileReportModel={customTileReportModel}></KagamiCustomTileChartReportView>
  );
};

const KagamiCustomTileChartReportView = (props: CustomTileReportModelProps) => {
  let customTileReportModel = props.customTileReportModel;
  const chartComponent: any = React.useRef(null);
  let customTileChartState = useCustomTileReportModelState(createState(customTileReportModel));
  customTileReportModel.state = customTileChartState;

  React.useEffect(() => {
    getReportDataForDynamicReports(props.customTileReportModel)
  }, [])

  if (!customTileChartState.isDataLoaded) {
    return <CircularProgress sx={{ marginTop: '4%', marginLeft: '48%' }} color="secondary" />
  } else {
    if (customTileReportModel.reportData) {
      const customTileConfig = makeCustomTile(customTileReportModel);

      return (
        <div className="kgm-report-hw dashboard-custome-report-container">
          <CustomWidget customTileReportModel={customTileReportModel} customTileConfig={customTileConfig} />
        </div>
      );
    } else {
      return <p className='NoDataFound'>No Data Found</p>
    }
  }
};
