import { BuildGrid } from '../ui/KagamiGrid';
import { KagamiGridModel } from '../model/KagamiGridModel';
import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import _, { delay } from 'lodash';
import { retrieveListRows } from '../../../service/kagamiListService';

let KgmGridModel : KagamiGridModel;

export const getKagamiGridModel = () => {
  return KgmGridModel;
}
export const setKagamiGridModel = (kagamiGridModel: KagamiGridModel ) => {
  KgmGridModel = kagamiGridModel;
}

export function buildGrid(kagamiGridModel: KagamiGridModel) {
  setKagamiGridModel(kagamiGridModel)
  return BuildGrid(kagamiGridModel);
}

export function reBuildGridView(kagamiGridModel : KagamiGridModel,dataRecords: any,listIdentifier : string,updateRecord : boolean = false,mergeOnloadData : boolean = false) {
  if(updateRecord) {
    let currentRecords : any[] = _.clone(kagamiGridModel.detailedObjects);
    if(CommonUtils.isNotEmpty(currentRecords) && dataRecords.length ===1) {
      let recordToBeChangedIndex : number = currentRecords.findIndex((record : any) => record.id === dataRecords[0].id)
      if(recordToBeChangedIndex !== -1) {
        currentRecords[recordToBeChangedIndex] = dataRecords[0]
        dataRecords = currentRecords;
      }
    }
  }
    if(mergeOnloadData && kagamiGridModel.detailedObjects instanceof Array){
      let newRecordsLength : number = dataRecords.length;
      let selectedNodes : any[] = kagamiGridModel.gridApi.getSelectedNodes()
      kagamiGridModel.detailedObjects.push(...dataRecords);
      if(CommonUtils.isEmpty(kagamiGridModel.onLoadData)) {kagamiGridModel.onLoadData = _.clone(kagamiGridModel.detailedObjects) ?? []}
      kagamiGridModel.onLoadData.push(...dataRecords)
      let rows : any[] = retrieveListRows(kagamiGridModel);
      kagamiGridModel.gridApi.setRowData(rows)
      if(kagamiGridModel.scrollEnd){
        kagamiGridModel.scrollEnd = false;
        kagamiGridModel.gridApi.ensureIndexVisible(kagamiGridModel.detailedObjects.length - newRecordsLength,'bottom');
      } 
      /// Note : While loading the new data in infinite scrolling nodes are rebuilding which is making the already existing nodes
      /// to lose its reference and unselecting the selected records. So to overcome this based on selected records id's 
      /// we are making the records selected in updated grid data
      if(selectedNodes.length > 0){
        delay(()=>{
          let nodeIds : any[] = selectedNodes.map((node) => node.data.id)
          kagamiGridModel.gridApi.forEachNode((node:any) => {
            if(nodeIds.includes(node.data.id)){
              node.setSelected(true)
            }
          })
        },1000)
      }
    }
    else{
      kagamiGridModel.detailedObjects = dataRecords;
      kagamiGridModel.onLoadData = dataRecords;
      //NOTE:- Adding setRowData([]) bcs when using multiselect in agGridCellEditor activeRowNode (it is an internal implementation of agGrid) 
      //is not getting empty due to this, when we are clicking reset button, data is getting empty from the rowData but it is still present in
      //activeRowNode of agGrid, due to this when we are selecting multiselect field again by default previous data is getting visible.
      kagamiGridModel?.gridApi?.setRowData([]);
      let kagamiGridState = kagamiGridModel.state
      kagamiGridState.setListLoaded(true);
    } 

  
}

export const updateTotalRecords = (listHeaderModel:any, totalRecords: any) => { 
   let updateTotalRecordCount = listHeaderModel.kagamiListHeaderModel?.state?.settotalRecords(totalRecords);
  return updateTotalRecordCount;
}


export function reBuildGridViewForPagination(
  kagamiGridModel: KagamiGridModel, 
  dataRecords: any[], 
  listIdentifier: string, 
  updateRecord: boolean = false, 
  mergeOnloadData: boolean = false, 
  isForwardPagination: boolean
) {
  if (updateRecord) {
    let currentRecords: any[] = _.clone(kagamiGridModel.detailedObjects);
    if (CommonUtils.isNotEmpty(currentRecords) && dataRecords.length === 1) {
      let recordToBeChangedIndex: number = currentRecords.findIndex((record: any) => record.id === dataRecords[0].id);
      if (recordToBeChangedIndex !== -1) {
        currentRecords[recordToBeChangedIndex] = dataRecords[0];
        dataRecords = currentRecords;
      }
    }
  }

  // Replace existing records with new data from the API
  kagamiGridModel.detailedObjects = dataRecords;
  kagamiGridModel.onLoadData = dataRecords;

  // Set row data to grid
  let rows: any[] = retrieveListRows(kagamiGridModel);
  kagamiGridModel.gridApi.setRowData(rows);

  // Adjust scroll position based on pagination direction
  if (isForwardPagination) {
    kagamiGridModel.gridApi.ensureIndexVisible(kagamiGridModel.detailedObjects.length - 1, 'bottom');
  } else {
    kagamiGridModel.gridApi.ensureIndexVisible(0, 'top');
  }

  // Restore selection for already selected records
  let selectedNodes: any[] = kagamiGridModel.gridApi.getSelectedNodes();
  if (selectedNodes.length > 0) {
    delay(() => {
      let nodeIds: any[] = selectedNodes.map((node) => node.data.id);
      kagamiGridModel.gridApi.forEachNode((node: any) => {
        if (nodeIds.includes(node.data.id)) {
          node.setSelected(true);
        }
      });
    }, 1000);
  }

  // Set the grid state to loaded
  let kagamiGridState = kagamiGridModel.state;
  kagamiGridState.setListLoaded(true);
}