import { createState } from '@hookstate/core';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { hideLoader, showLoader } from '../../../../../../../../../../../../../components/loader/controller/KagamiLoaderController';
import { LedgerDetailsModel, LedgerDetailsModelProps, useLedgerDetailsModelState } from '../model/LedgerDetailsModel';
import { getTallyVoucherDisplay } from '../service/LedgerDetailsService';

export const buildLedgerDetailsComponent = (ledgerDetailsModel: LedgerDetailsModel) => {
  return <LedgerDetails key={'ledger-details'} ledgerDetailsModel={ledgerDetailsModel} />;
};

export const LedgerDetails = (props: LedgerDetailsModelProps) => {
  const ledgerDetailsModel: LedgerDetailsModel = props.ledgerDetailsModel;
  let translation = ledgerDetailsModel?.ledgerInputs?.translation || {};
  props.ledgerDetailsModel.state = useLedgerDetailsModelState(createState(ledgerDetailsModel));
  let totalCredit: number = 0;
  let totalDebit: number = 0;
  useEffect(() => {
    getTallyVoucherDisplay(ledgerDetailsModel).then((response: any) => {
      console.log(response.data);
      props.ledgerDetailsModel.headerDeatils = response.data['headerDeatils'];
      props.ledgerDetailsModel.nestedFinanceRecord = response.data['nestedFinanceRecord'];
      props.ledgerDetailsModel.otherDeatils = response.data['otherDeatils'];
      props.ledgerDetailsModel.state.setDataLoaded(true);
    });
  }, []);
  if (props.ledgerDetailsModel.state.isDataLoaded) {
    hideLoader();
    return (
      <div style={{ background: '#c5f7d8', padding: '14px 30px', lineHeight: '2' }}>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <h4> {translation["Document No"] || "Document No"}</h4>
            </Grid>
            <Grid item xs={3}>
              <p>{props.ledgerDetailsModel.headerDeatils!==undefined && props.ledgerDetailsModel.headerDeatils['Document No']!==undefined?props.ledgerDetailsModel.headerDeatils['Document No']:''}</p>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <h4>{translation["Date"] || "Date"}  </h4>
            </Grid>
            <Grid item xs={3}>
              <p>{props.ledgerDetailsModel.headerDeatils!==undefined && props.ledgerDetailsModel.headerDeatils['Date']!==undefined?props.ledgerDetailsModel.headerDeatils['Date']:''}</p>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </div>

        <div
          style={{
            borderTop: '2px solid rgba(0,0,0,0.05)',
            borderBottom: '2px solid rgba(0,0,0,0.05)'
          }}
        >
          
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <h4> {translation["Particulars"] || "Particulars"}  </h4>
            </Grid>
            <Grid item xs={4}>
              <h4>{translation["Debit"] || "Debit"} </h4>
            </Grid>
            <Grid item xs={4}>
              <h4> {translation["Credit"] || "Credit"}  </h4>
            </Grid>
            </Grid>
            {props.ledgerDetailsModel.nestedFinanceRecord!==undefined && props.ledgerDetailsModel.nestedFinanceRecord.map((record: any) => {
              if (record['credit'] !== undefined) {
                totalCredit += Number(record['credit']);
              }
              if (record['debit'] !== undefined) {
                totalDebit += Number(record['debit']);
              }
              return (
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    {record['particulars'] ?? ''}
                  </Grid>
                  <Grid item xs={4}>
                    {record['debit'] ?? ''}
                  </Grid>
                  <Grid item xs={4}>
                    {record['credit'] ?? ''}
                  </Grid>
                </Grid>
              );
            })}
          
          <Grid container spacing={1}>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
              <h4> {translation["Total"] || "Total"} :</h4>
            </Grid>
            <Grid item xs={4}>
              {totalDebit.toFixed(2)}
            </Grid>
            <Grid item xs={4}>
              {totalCredit.toFixed(2)}
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={2}>
            <h4> {translation["Narration"] || "Narration"} : </h4>
            </Grid>
            <Grid item xs={2}>
              {props.ledgerDetailsModel.otherDeatils!==undefined && typeof props.ledgerDetailsModel.otherDeatils === 'object' ? Object.values(props.ledgerDetailsModel.otherDeatils): ''}
            </Grid>
            <Grid item xs={8}></Grid>
          </Grid>
        </div>
      </div>
    );
  } else {
    showLoader()
    // return <div style={{ background: '#c5f7d8', padding: '14px 30px', lineHeight: '2' }}></div>;
    return null;
  }
};
