export class RadioValidator {
    static errorRadio:any = '';
   
    static validate(validations:any, value:any) {
      
      if (null != validations && validations) {
        let k:any
        RadioValidator.errorRadio = null
        for ([k] of  Object.entries(validations)) {
          switch (k) {
            case 'notNull':
                RadioValidator.notNullValidation(value);
              if (RadioValidator.errorRadio != null) {
                return RadioValidator.errorRadio;
              }
              break;
           
  
            default:
              break;
          }
        }
        return RadioValidator.errorRadio;
      }
      return null;
    }
  
    static notNullValidation(value:any) {
        if(value instanceof Object){
          (value
            && Object.keys(value).length === 0 && value.constructor === Object)
           ? RadioValidator.errorRadio = null : RadioValidator.errorRadio = 'This field is mandatory'
        }else{
          value ? RadioValidator.errorRadio = null : RadioValidator.errorRadio = 'This field is mandatory';
        }
        
      }
}


  