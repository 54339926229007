import { sha256 } from 'js-sha256';
import { KagamiStateManager } from '../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../constants/SystemConstants';
import { VisibleConstants } from '../../../constants/VisibeConstants';
import {
  getAppplicationModel,
  onApplicationLoginFailure,
  onApplicationLoginSuccess
} from '../../../controller/KagamiApplicationController';
import { APIService } from '../../../service/ApiService';
import {
  DOMAIN,
  getAppAndUserContextUrl,
  getDeviceInfo,
  getForgotPasswordUrl,
  getLoginURL,
  getLogoURL,
  getUrl,
  getValidateTokenForPasswordReset,
  resetPasswordURL
} from '../../../service/URLService';
import { showErrorMessage } from '../controller/KagamiLoginController';
import { hideLoader, showLoader } from '../../../../components/loader/controller/KagamiLoaderController';
import { CommonUtils } from '../../../utils/CommonUtils';
import { KagamiApplicationModel } from '../../../model/KagamiApplicationModel';
import { DashboardType } from '../../home/builder/body/builder/dashboard/model/KagamiDashboardModel';
import { KagamiBeanUtils } from '../../../utils/KagamiBeanUtils';
import { isArray } from 'lodash';

export function loginRequest(userId: string, pwd: string): any {
  const loginData = {
    userdomain: DOMAIN,
    userId: userId,
    password: pwd
  };
  makeDeviceBasedLoginCall(loginData);
}

export function makeDeviceBasedLoginCall(loginData:any){
  showLoader();
  const appAndUserContextUrl = getAppAndUserContextUrl();
  APIService.getData(SystemConstants.GET, appAndUserContextUrl, {})
    .then((response: any) => {
      if (CommonUtils.isNotEmpty(response)) {
        let deviceBasedLogin : any = KagamiBeanUtils.getProperty('applicationContext.context.AppSetting.0.deviceBasedLogin',response.data)
        localStorage.setItem('deviceBasedLogin',deviceBasedLogin);
        if(deviceBasedLogin){
          makeDeviceInfoCall(loginData)
        }
        else{
         CommonUtils.isNotEmpty(loginData) ? makeLoginCall(loginData) : onApplicationLoginSuccess();
        }
      }
    })
    .catch((err: any) => {
      console.log('err', err);
      KagamiStateManager.getStateToHolder(SystemConstants.LOGIN_STATE).setNoDeviceFoundMsg("No Device Found");
    });
}

export function makeLoginCall(loginData:any){
  APIService.getData(SystemConstants.POST, getLoginURL(), loginData)
  .then((loginResponse: any) => {
    let response:any;
    if(CommonUtils.isNotEmpty(loginResponse.data.SUCCESS)) {
      response = JSON.parse(loginResponse.data.SUCCESS)
    }
    else if(CommonUtils.isNotEmpty(loginResponse.data.ERROR)){
      response = JSON.parse(loginResponse.data.ERROR)
    }
    hideLoader();
    if (CommonUtils.isEmpty(response)) {
      KagamiStateManager.getStateToHolder(SystemConstants.LOGIN_STATE)
        ? showErrorMessage('Invalid Username or password')
        : onApplicationLoginFailure();
    } else if (SystemConstants.ERROR_MESSAGE in response) {
      localStorage.getItem(SystemConstants.LOGIN_DATA);
      showErrorMessage(response.errorMessage);
    } else {
      if (localStorage.getItem(SystemConstants.LOGIN_DATA)) {
        localStorage.clear();
      }
      localStorage.setItem(SystemConstants.TOKEN, response.token);
      localStorage.setItem(SystemConstants.LOGIN_DATA, JSON.stringify(loginData));
      localStorage.setItem('currentDomain', DOMAIN);
      KagamiStateManager.addStateToHolder(SystemConstants.TOKEN, response.token);
      onApplicationLoginSuccess();
    }
  })
  .catch((error:any) => {
    hideLoader();
    showErrorMessage(VisibleConstants.UNEXPECTED_ERROR);
  });
}

export function makeDeviceInfoCall(loginData:any){
  const devicInfo = getDeviceInfo();
  APIService.getData(SystemConstants.GET, devicInfo, {}).then((response: any) => {
      if (CommonUtils.isNotEmpty(response)) {
        localStorage.setItem('hardwareId', response.data.hardware_id);
        localStorage.setItem('serialNo', response.data.serial_no);
        APIService.setDeviceInfo(response.data.hardware_id, response.data.serial_no)
        CommonUtils.isNotEmpty(loginData) ? makeLoginCall(loginData) : onApplicationLoginSuccess()
      }
    })
    .catch((err: any) => {
      console.log('err', err);
      KagamiStateManager.getStateToHolder(SystemConstants.LOGIN_STATE).setNoDeviceFoundMsg("No Device Found");
    });
}

export function convertToSha256(inputValue: string): string {
  const hash = sha256.create();
  hash.update(inputValue);
  return hash.hex();
}

export function logoutRequest(): any {
  // will call logout
  /// Note : Removing process state because Process page will not be in context anymore
  if(CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))){
      KagamiStateManager.removeStateToHolder(SystemConstants.PROCESS_STATE);
  }
  let appModel: KagamiApplicationModel = getAppplicationModel();
  appModel.logoutChannel.postMessage('Logout');
}

export function getLogoData() {
  const logoUrl = getLogoURL();
  return APIService.getData(SystemConstants.POST, logoUrl, {});
}

export function getAppAndUserContext() {
  const appAndUserContextUrl = getAppAndUserContextUrl();
  // return await APIService.getData(SystemConstants.GET,appAndUserContextUrl,{}).then((response:any) =>{
  //    localStorage.setItem("userContextDataForKcountry", JSON.stringify(response.data.applicationContext.context.AppSetting[0]['Kcountry']));
  // } )
  return APIService.getData(SystemConstants.GET, appAndUserContextUrl, {});
}

export async function forgotpassword(data: any) {
  return await APIService.getData(SystemConstants.POST, getForgotPasswordUrl(), data);
}
export async function validateTokenForResetPassword(data: any, token: string) {
  return await APIService.getData(SystemConstants.POST, getValidateTokenForPasswordReset(token), data);
}

export function getSummaryDashBoard(data: any) {
  if (CommonUtils.isNotEmpty(data.applicationContext.context.AppSetting[0].landingPageLevel)) {
    return true;
  } else {
    return false;
  }
}

export function getDashboardDefaultView(data: any) {
  let dashboardDefaultView = '';
  if (data?.userContext?.context?.UserSetting && Array.isArray(data?.userContext?.context?.UserSetting)
      && data?.userContext?.context?.UserSetting.length > 0) {
        dashboardDefaultView = data.userContext.context.UserSetting[0].dashboardDefaultView ?? '';
  }
  if (dashboardDefaultView.toLowerCase() === 'reports' || dashboardDefaultView.toLowerCase() === 'report') {
    return DashboardType.REPORTS;
  } else {
    return DashboardType.NOTIFICATIONS;
  }
}

export async function resetPassword(requestData: any, token: string) {
  return await APIService.getData(SystemConstants.POST, resetPasswordURL(token), requestData);
}
