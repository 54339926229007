import { createState } from '@hookstate/core';
import { Button, Drawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { buildReportFilter } from '../../filter/controller/KagamiReportFilterController';
import { DashboardReportBody } from '../builder/DashboardReportBody';
import { DashboardReportHeader } from '../builder/DashboardReportHeader';
import { DashboardReportsModel, DashboardReportsProps, useDashboardReportState } from '../model/DashboardReportsModel';
import { DashboardReportsStyle } from '../style/DashboardReports';

const useStyles = makeStyles(DashboardReportsStyle);

export const buildDashboardReportsComponent = (dashboardReportsModel: DashboardReportsModel) => {
  return <DashboardReports key={dashboardReportsModel.id} dashboardReportsModel={dashboardReportsModel} />;
};

const DashboardReports = (props: DashboardReportsProps) => {
  let dashboardReportsModel = props.dashboardReportsModel;
  const dashboardReportState:any = useDashboardReportState(createState(dashboardReportsModel));
  dashboardReportsModel.state = dashboardReportState;
  
  const handleDrawerOnClose = () => {
    dashboardReportsModel.state.setDrawerOpenDialog(false);
  }

  return (
      <div style={{height: "100%"}}>
        <DashboardReportHeader dashboardReportsModel={dashboardReportsModel} />
        <DashboardReportBody key={dashboardReportsModel.key} dashboardReportsModel={dashboardReportsModel} />
        <Drawer
          anchor={'right'}
          open={dashboardReportState.drawerOpenDialog}
          onClose={handleDrawerOnClose}
          PaperProps={{ style: { height: 'fit-content', width: '800px', borderBottomLeftRadius: '10px' } }} // , borderRadius: '6px 0px 0px 6px', transition: '2s width'
        >
          <ReportFilter dashboardReportsModel = {dashboardReportsModel} />
        </Drawer>
      </div>
  );
};

const ReportFilter = (props: DashboardReportsProps) => {
  const classes = useStyles();
  const dashboardReportsModel: DashboardReportsModel = props.dashboardReportsModel;
  
  const handleOnOk = async () => {
    const selectedValue: Map<string, any> = dashboardReportsModel.reportModel.reportFilterModel.selectedValue;
    const filterDataArray:string[] = Array.from(selectedValue?.values()).map((data:any)=>data.toString());
    dashboardReportsModel.key = filterDataArray.join();          
    dashboardReportsModel.state.setDrawerOpenDialog(false);
  }

  return (
    <div className={classes.dashboard_report_filter}>
      <div className='drawer-filter-header'> Filters</div>
      <div className='drawer-filter-content'>{buildReportFilter(dashboardReportsModel.reportModel.reportFilterModel, true)}</div>
      <div className='drawer-filter-footer'>
        <Button
          className='apply-filter-button2'
          onClick={handleOnOk}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
};
