import React from 'react';
import { buildErrorModal } from '../../../../components/errorModal/controller/KagamiErrorModalController';
import { buildLoader } from '../../../../components/loader/controller/KagamiLoaderController';
import { LoaderProps } from '../../../../components/loader/model/KagamiLoaderModel';
import { HomeModelProps } from '../model/KagamiHomeModel';
import { buildModalDialog } from './body/builder/process/builder/modal-dialog/controller/KagamiModalDialogController';
import { buildBody } from './body/controller/KagamiBodyController';
import { BodyModelProps } from './body/model/KagamiBodyModel';
import { buildhomeHeader } from './header/controller/KagamiPageHeaderController';
import { buildNavigator } from './navigator/controller/KagamiNavigatorController';
import { NavigatorProps } from './navigator/model/KagamiNavigatorModel';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiHomeStyle } from '../style/KagamiHome';
import { Typography } from '@mui/material';
import { getCopyrightYear } from '../../../controller/KagamiApplicationController';
import { KagamiPageHeaderProps } from './header/model/KagamiPageHeaderModel';
 

 
export const Header = (props: KagamiPageHeaderProps) => {  
  return buildhomeHeader(props.kagamiPageHeaderModel);
};

export const Navigator = React.memo((props: NavigatorProps) => {
  return buildNavigator(props.kagamiNavigatorModel, props.kagamiNavigatorModel.tabNavigationModel);
});

export const Body = (props: BodyModelProps) => {
  return buildBody(props.kagamiBodyModel);
};

export const Footer = () => {
  const classes = makeStyles(KagamiHomeStyle)();
  const currentYear = getCopyrightYear();
  return (<div className={classes.homeFooter}>
    <div className= {classes.footercopyright} style={{textDecoration: 'underline #006e7f'}}>
    <Typography variant='caption' color='secondary'  >
    Copyright &copy; 
    </Typography>
    <a  href="https://kagamierp.com/" target="_blank">
    <Typography variant='caption' color='secondary'>
      &nbsp; {currentYear} Kagami India Pvt. Ltd.
    </Typography>
    </a>
    </div>
    </div>
  );
};

export const ErrorModal = (props: HomeModelProps) => {
  return <>{buildErrorModal(props.homeModel.errorModal)} </>;
};

export const ModalDialogTemplateType = (props: HomeModelProps) => {
  return <>{buildModalDialog(props.homeModel.modalDialogModel)}</>;
};

export const Loader = (props: LoaderProps) => {
  return <>{buildLoader(props.loaderModel)}</>;
};

export const getTitleForAppSettingModel = () => {
  return 'Warning!';
};

export const getContentForAppSettingModel = () => {
  return <Timer value={5} />;
};

function Timer(props: any) {
  const { value } = props;
  const [count, setCount] = React.useState(value);
  React.useEffect(() => {
    const interval = setInterval(() => {
      const newcount = count - 1;
      setCount(newcount);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [count]);
  return (
    <>
      App Settings Saved! App refreshes Automatically in <b>{count}</b> seconds
    </>
  );
}
