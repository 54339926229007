import { CUSTOM_TILE_HEADER, CUSTOM_TILE_INNER_VALUE, CUSTOM_TILE_VALUE } from "../graphic/customTile/service/CustomTileReportUtil";

export const KeyValueWidget = (props: any) => {
    let chartObj = props.chartObj;
    return (
        <div className="keyvalue-widget-container">
            <div className="keyvalue-box">
                <div className="header">{chartObj[CUSTOM_TILE_HEADER] || ''} : </div>
                <div className="value">{chartObj[CUSTOM_TILE_VALUE] || ''}</div>
                {/* <div>{chartObj[CUSTOM_TILE_INNER_VALUE] || ''}</div> */}
            </div>
        </div>
    )
}


