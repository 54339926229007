import PDFDownload  from '../../../../service/exports/PDFService';
// import ExcelDownload from '../../../../service/exports/ExcelService';
import {getReportName, getValueInDateTimeFormatAsAppSetting, numberCellDisplayFormatter} from '../../../../service/ReportUtilService';
import {getFilterSelectedHeader} from '../../../../service/KagamiReportService';
import _ from 'lodash';
import { ColDef, ExcelExportParams, ExcelRow } from 'ag-grid-community';
import { GridApi } from 'ag-grid-community';
// import { ProcessCellForExportParams } from 'ag-grid-community';
/** EXCEL DOWNLOAD **/

export const downloadExcel = (reportConfig:any,reportModel:any, gridRef:any) => {
  const gridApi: GridApi = gridRef.current?.api;
  if (!gridApi) return;

  const getRows: () => ExcelRow[] = () => {
    let customData = [getReportName(reportModel) || 'Report'];
    if(getFilterSelectedHeader(reportModel))customData.push(getFilterSelectedHeader(reportModel));
    customData.push("As On Date :" +getValueInDateTimeFormatAsAppSetting(new Date(), 'date'));
    return customData.map((cellData) => {
      return {
        cells: [
          {
            data: {
              value: cellData,
              type: 'String'
            },
            styleId: ["header-and-footer-style", 'center-alignment'],
            mergeAcross: reportConfig.columnDefs.length
          }
        ]
      };
    });
};

  if (!gridApi) return;

  const options:ExcelExportParams = {
    fileName: getReportName(reportModel) || 'Report',
    sheetName: getReportName(reportModel) || 'Sheet',
    prependContent: getRows(),
    processCellCallback: (params:any) => {
      // Check if the column is part of the group and apply style
      if (params.column.getColId() === 'ag-Grid-AutoColumn') {
        params.column.colDef['cellClass'] = 'stringType'; // Apply style for group columns
      }
      return params.value;
    }

  }
  gridApi.exportDataAsExcel(options);
}

/** PDF DOWNLOAD **/
export const downloadPDF = (type:any,reportConfig:any,reportModel:any) => {
  let columnsInfo = getPdfTableColumns(reportConfig);
  let configInfo:any  = {
    pageOrientation:type,
    fileName: getReportName(reportModel) || 'Report',
    rowData:reportConfig['rowData'] || [],
    pdfTableRows : reportConfig['rowData'] || [],
    pdfHeaderInfo :getPdfHeaderInfo(reportConfig,reportModel),
    // pdfTableColumns : reportConfig.columnDefs, 
    // exportableColumnDefinitions : reportConfig.columnDefs,  //grid exportable column definitions
    // pdfTableColumnWidths : _.times(reportConfig.columnDefs.length, _.constant('auto')) || []
    pdfTableColumns : columnsInfo.exportableColumns,
    exportableColumnDefinitions : columnsInfo.columnsList, //grid exportable column definitions
    pdfTableColumnWidths : columnsInfo.widths,
    reportConfig :reportConfig,
    reportHeaderObject:reportModel.reportHeaderObject ||'',
  }
  configInfo['pdfTableRows'] =  makePdfData(configInfo,reportConfig);

  const pdfLib =  PDFDownload(configInfo);
  pdfLib.downLoad();
}

const getPdfTableColumns = (reportConfig: any) => {
  let exportableColumns: any = [];
  let columnsList:any = [];
  if (reportConfig.columnDefs && reportConfig.columnDefs.length > 0) {
    _.forEach(reportConfig.columnDefs, (col: any) => {
      let headerCol: any = {
        text: col.headerName,
        style: 'tableHeader'
      };
        exportableColumns.push(headerCol);
        columnsList.push(col);
    });
  }
  !reportConfig['treeData'] && exportableColumns.unshift({ text: 'SNo', style: 'tableHeader' });
  !reportConfig['treeData'] && columnsList.unshift({ field: 'sno', headerName: 'SNo' });

  return {
    exportableColumns: exportableColumns || [],
    columnsList: columnsList || [],
    widths: _.times(columnsList.length, _.constant(100/columnsList.length+"%")) || []
  };
};

const getPdfHeaderInfo = (reportConfig:any,reportModel:any) => {
  let headerConfig:any = {};
  if(reportConfig) {
    headerConfig['Title'] = getReportName(reportModel.reportConfig) ||''; // report name
    headerConfig['subTitle'] = getFilterSelectedHeader(reportModel) || '';
    headerConfig['exportedDate'] = getValueInDateTimeFormatAsAppSetting(new Date(), 'date');     
  }
  return headerConfig;
}
const makePdfData =(configInfo:any,reportConfig:any) => {
  let rowsData:any = [];
  let pdfRows = configInfo.pdfTableRows;
  let isFooterExists = false;
  if(reportConfig.pinnedBottomRowData?.length){
    isFooterExists = true;
    pdfRows = configInfo.pdfTableRows.concat(reportConfig.pinnedBottomRowData[0]);
  }
  if(configInfo.exportableColumnDefinitions && configInfo.exportableColumnDefinitions.length > 0 && reportConfig.rowData && reportConfig.rowData.length > 0) {
    _.forEach(pdfRows,(rec,index)=>{
      let _row:any = []
      _.forEach(configInfo.exportableColumnDefinitions,(col)=>{
        let value = rec[col.field];
        if(value == undefined || value == null) {
          value = '';
        }
        if (col.field === 'sno') {
          _row.push({ text: index + 1 });
        } else {
          // _row.push({ text: rec[col.field] || '0' });
          _row.push({text:value});
        }
      });
      rowsData.push(_row);
    })
  }
  if(isFooterExists && rowsData?.[rowsData.length-1]?.[0]){
    rowsData[rowsData.length-1][0].text='';
  }
  if(rowsData)
  return rowsData;
}


/** END PDF DOWNLOAD **/