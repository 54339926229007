import { AddRounded, ClearRounded, EditRounded } from '@mui/icons-material';
import React, { useCallback, useEffect, useRef} from 'react'
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { getActiveModel } from '../../../../../../controller/KagamiProcessController';
import { nonFieldButtonOnClickCall } from '../../../../../../service/PolicyExecutionService';
import { getPresenationIdFromPresentation } from '../../../../../../service/PresentationService';
import { addRecord, editRecord, removeRecord } from '../../../../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/controller/KagamiEmbeddedAccordionPresentationController';
import { getFormModel } from '../../../../../form/controller/KagamiFormController';
import { KagamiEmbeddedFormPresentationModel } from '../../../../../form/model/KagamiEmbeddedFormPresentationModel';
import { KagamiFormModel } from '../../../../../form/model/KagamiFormModel';
import { updateInFocusMap } from '../../../../../form/service/FormService';
import { getKagamiListModel, setKagamiListModel } from '../../../../controller/KagamiListController';
import { validatePreviousRecord } from '../../service/GridService';
import { getFirstEditableColumn } from '../../service/GridUtilService';

enum FloatingTriggerType {
  ADD,
  EDIT,
  DELETE,
  PICK_ATTR
}

function FloatingActionCellRenderer(params: any) {
  const fabRef:any = useRef(null);
  const { kagamiGridModel } = params;
  const gridCellContainer = params.eGridCell;
  const rowNode = params.node;
  const eContainer = params.api.ctrlsService.centerRowContainerCtrl.eContainer;
  let allowMove = true;
  let isMoving = false;


  // const mouseOverHandler = (event: React.MouseEvent) => {
  //   gridCellContainer.style.width = "auto";
  //   if (!isMoving) {
  //     allowMove = true;
  //   }
  // }

  const mouseOverHandler = (event:any) => {
    gridCellContainer.style.width = "auto";
    if (!isMoving){
      allowMove = true;
    }
    if(event.target.classList.contains("MuiCheckbox-root") || event.target.closest(".MuiCheckbox-root") || event.target.classList.contains("kgm-actions") || event.target.closest(".kgm-actions")){
      fabRef.current.style.display = "none";
    } else{
      fabRef.current.style.display = "flex";
    }

  }

  const mouseOutHandler = (event: React.MouseEvent) => {
    const rowDom = eContainer?.querySelector('[row-index="' + rowNode.rowIndex + '"]');
    if ((event.target as any).closest('.ag-row') !== rowDom) {
      isMoving = false;
    }
  }

  const mouseMoveHandler = (event: React.MouseEvent) => {
    const rowDom = eContainer?.querySelector('[row-index="' + rowNode.rowIndex + '"]');
    const rootWrapper = rowDom?.closest('.ag-root-wrapper');
    const rootWidth = rootWrapper?.clientWidth;
    const boundingRect = rowDom?.getBoundingClientRect();
    const pageX = event.pageX;
    let left = pageX - boundingRect?.x;
    if (left > rootWidth - 120) {
      left = left - 120;
    }
    if (allowMove) {
      isMoving = true;
      allowMove = false;
      gridCellContainer.style.left = left + "px";
    }
  }

  useEffect(() => {
    const _window = window as any;

    _window.removeEventListener('mouseover', mouseOverHandler);
    _window.removeEventListener('mouseout', mouseOutHandler);
    _window.removeEventListener('mousemove', mouseMoveHandler);

    _window.addEventListener('mouseover', mouseOverHandler);
    _window.addEventListener('mouseout', mouseOutHandler);
    _window.addEventListener('mousemove', mouseMoveHandler);
    return () => {
      _window.removeEventListener('mouseover', mouseOverHandler);
      _window.removeEventListener('mouseout', mouseOutHandler);
      _window.removeEventListener('mousemove', mouseMoveHandler);
    }
  }, []);


  let entityId = getEntityId(kagamiGridModel);
  if(kagamiGridModel.isPivot && params.node.footer){
    // fabRef.current.style.display = "none";
    return <p ref = {fabRef} style={{display:'none'}}></p>
  }
  else if (params?.colDef?.field || true) {
    const handleActionClick = useCallback((Trigger_Type: FloatingTriggerType, pickAttrTrigger: any = null) => {
      let embeddedTriggerControl = kagamiGridModel?.actions?.get("add")?.props?.embeddedTriggerModel;
      let embed_kagamiGridModel = kagamiGridModel?.actions?.get("add")?.props?.embeddedTriggerModel?.embeddedFormPresentationModel;
      if(!embed_kagamiGridModel){
        embed_kagamiGridModel = kagamiGridModel?.actions?.get("edit")?.embeddedFormPresentationModel
      }
      switch (Trigger_Type) {
        case FloatingTriggerType.ADD:
          params.api.stopEditing();
          if (embed_kagamiGridModel.isNested && CommonUtils.isNotEmpty(kagamiGridModel.parentEmbedIdForNestedEmbed)) {
            let formModel : KagamiFormModel = getFormModel();
            let entityHierarchy = formModel.formData.entityHierarchies.get(getPresenationIdFromPresentation(embed_kagamiGridModel.presentation))
            let parentEntityId : string = entityHierarchy.parentEntity
            updateInFocusMap(parentEntityId,kagamiGridModel.parentEmbedIdForNestedEmbed,formModel)
            // embed_kagamiGridModel = formModel.formControls[gridModel.mainEntityId + '.' + gridModel.parentEmbedIdForNestedEmbed]
          }
          addRecord(embed_kagamiGridModel || kagamiGridModel?.actions?.get("add")?.embeddedFormPresentationModel)
          break;
        case FloatingTriggerType.EDIT:
          params.api.stopEditing();
          // editRecord(embed_kagamiGridModel || kagamiGridModel?.actions?.get("edit")?.embeddedFormPresentationModel,  kagamiGridModel.defaultEmbedFormView || 'inline', params.data.id);
          let activeListModel: any = getKagamiListModel()
          if (activeListModel && activeListModel.mainEntityId !== kagamiGridModel.mainEntityId) setKagamiListModel(kagamiGridModel);
          if (kagamiGridModel.isEditableEmbedList) {
            let formModel: KagamiFormModel = getFormModel();
            if (CommonUtils.isEmpty(kagamiGridModel.activeRowNode) || (CommonUtils.isNotEmpty(kagamiGridModel.activeRowNode) && kagamiGridModel.activeRowNode.data.id !== params.data.id && validatePreviousRecord(kagamiGridModel))) {
              kagamiGridModel.activeRowNode = rowNode;
              updateInFocusMap(kagamiGridModel.mainEntityId, kagamiGridModel.activeRowNode.data.id, formModel);
              /// Note : setting expanded embed model in focus
              let embeddedFormPresentationModel: any = embed_kagamiGridModel//formModel.formControls[kagamiGridModel.mainEntityId]
              editRecord(embeddedFormPresentationModel, 'inline', kagamiGridModel.activeRowNode.data.id);

              const channel = new BroadcastChannel("inline-edit");
              channel.addEventListener("message", (event) => {
                const colDefs = params.api.columnModel.getAllDisplayedColumns();
                const firstEditableCol = getFirstEditableColumn(colDefs);
                if(firstEditableCol){
                  params.api.startEditingCell({
                    rowIndex: rowNode.rowIndex,
                    colKey: firstEditableCol
                  });
                }
                channel.close();
              });
            }
          }

          // editRecord(embed_kagamiGridModel || kagamiGridModel?.actions?.get("edit")?.embeddedFormPresentationModel,  'inline', params.data.id);
          break;
        case FloatingTriggerType.DELETE:
          // embeddedTriggerControl.embeddedFormPresentationModel.formDataIdentifier = params.data.id;
          // params.api.stopEditing();
          // kagamiGridModel.activeRowNode = params.node;
          // updateInFocusMap(embeddedTriggerControl.embeddedFormPresentationModel.formEntityId, params.data.id, getFormModel())
           let embeddedFormPresentationModel : KagamiEmbeddedFormPresentationModel = embeddedTriggerControl.embeddedFormPresentationModel;
        if (embeddedFormPresentationModel.isNested && CommonUtils.isNotEmpty(embeddedFormPresentationModel.parentEmbedIdForNestedEmbed)) {
          let formModel : KagamiFormModel = getFormModel();
          let entityHierarchy = formModel.formData.entityHierarchies.get(embeddedFormPresentationModel.presentationId)
          let parentEntityId : string = entityHierarchy.parentEntity
          updateInFocusMap(parentEntityId,embeddedFormPresentationModel.parentEmbedIdForNestedEmbed,formModel)
          embeddedFormPresentationModel = formModel.formControls[embeddedFormPresentationModel.formEntityId + '.' + embeddedFormPresentationModel.parentEmbedIdForNestedEmbed]
        }        
        embeddedFormPresentationModel.formDataIdentifier = params.data.id
        // updateInFocusMap(embeddedFormPresentationModel.formEntityId,params.data.id,getFormModel())
          removeRecord(embeddedFormPresentationModel || kagamiGridModel?.actions?.get("delete")?.embeddedFormPresentationModel);
          break;
        case FloatingTriggerType.PICK_ATTR:
          let modalModel = kagamiGridModel.kagamiModalModel;
          modalModel.isPickItem = true;
          modalModel.parentEntityId = pickAttrTrigger['mainEntityId'];
          params.api.stopEditing();
          nonFieldButtonOnClickCall(getActiveModel(), modalModel, pickAttrTrigger['fieldName'], pickAttrTrigger['presentationId']);
          break;
        default:
          break;
      }
    }, [])

    const addTrigger = kagamiGridModel.actions?.get('add');
    const editTrigger = kagamiGridModel.actions?.get('edit');
    const deleteTrigger = kagamiGridModel.actions?.get('remove');
    const hassAddTrigger: boolean = addTrigger?.props?.embeddedTriggerModel?.enableTrigger;
    const hasEditTrigger: boolean = editTrigger?.enableTrigger;
    const hasDeleteTrigger: boolean = deleteTrigger?.enableTrigger;
    const pickAttrTriggers = getNonFieldTriggers(kagamiGridModel);
    let actionList: any = [];
    // const pointerIcon = <div key={`${entityId}_pointer`} className="floating-action-pointer">
    //   <MoreVertRounded style={{ fontSize: '22px', color: "#003363" }} />
    // </div>;
    // actionList.push(pointerIcon);
    if (hassAddTrigger) {
      const actionTriggerEle =
        <div key={`${entityId}_add`} className="floating-action-icon" title="Add New Record" onClick={(e) => { handleActionClick(FloatingTriggerType.ADD) }}>
          <AddRounded style={{ fontSize: '22px', color: "#003363" }} />
        </div>;
      actionList.push(actionTriggerEle);
    }
    if (hasEditTrigger) {
      const editTriggerEle = <div key={`${entityId}_edit`} className="floating-action-icon" title="Edit Record" onClick={(e) => { handleActionClick(FloatingTriggerType.EDIT) }}>
        <EditRounded style={{ fontSize: '16px', color: "#6EBE44", top: "3px", left: "2px" }} />
      </div>;
      actionList.push(editTriggerEle);
    }
    if (hasDeleteTrigger) {
      const deleteTriggerEle = <div key={`${entityId}_delete`} className="floating-action-icon" title="Delete Record" onClick={(e) => { handleActionClick(FloatingTriggerType.DELETE) }}>
        <ClearRounded style={{ fontSize: '22px', color: "#F53F53", top: "1px" }} />
      </div>;
      actionList.push(deleteTriggerEle);
    }

    // NOTE: commented to show only add edit and remove icons in floating menu
    // if (pickAttrTriggers.length) {
    //   pickAttrTriggers.forEach((pickAttrTrigger: any) => {
    //     const pickAttributeTriggerEle = <div key={`${entityId}_pickattr_${pickAttrTrigger?.value?.identifier}`} title={pickAttrTrigger?.value?.metaData?.content} className="floating-action-icon" onClick={(e) => { handleActionClick(FloatingTriggerType.PICK_ATTR, pickAttrTrigger) }}>
    //       <ElectricBoltRounded style={{ fontSize: '21px', color: "#05263D" }} />
    //     </div>;
    //     actionList.push(pickAttributeTriggerEle);
    //   });
    // }

    if(kagamiGridModel.isPivot && params.node.footer){
      // fabRef.current.style.display = "none";
      return <p ref = {fabRef} style={{display:'none'}}></p>
    }
    return (
       <div ref = {fabRef} className="floating-action-container">{actionList}</div>
    )
  }
}
export default FloatingActionCellRenderer


const getEntityId = (kagamiGridModel: any) => {
  return kagamiGridModel.mainEntityId || 'dummy';
}

const getNonFieldTriggers = (kagamiGridModel: any) => {
  let nonFields: any = [];
  if (kagamiGridModel) {
    const attributePresentations = kagamiGridModel.presentation.presentationRules;
    if (attributePresentations) {
      for (let [key, value] of Object.entries(attributePresentations)) {
        const pRule: any = value;
        if (pRule.visible && pRule['@type'] === 'NonFieldPresentation' && pRule.type == "BUTTON") {
          let nonFieldInfo: any = {}
          nonFieldInfo['presentationId'] = kagamiGridModel?.presentation?.presentationId || '';
          nonFieldInfo['mainEntityId'] = kagamiGridModel.mainEntityId;
          nonFieldInfo['fieldName'] = key;
          nonFieldInfo['value'] = pRule;
          nonFields.push(nonFieldInfo);
        }
      }
    }
  }
  return nonFields;
}
