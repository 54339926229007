import { useEffect } from 'react';
import Barcode from 'react-barcode';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';

const KagamiBarCodeCellRenderer = (props: any) => {
  console.log('props', props);
  useEffect(() => {});
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        marginTop: '0px'
      }}
    >
      {gridBarCode(props)}
    </div>
  );
};

export default KagamiBarCodeCellRenderer;

export const gridBarCode = (props: any) => {
  if (CommonUtils.isNotEmpty(props) && CommonUtils.isNotEmpty(props.value)) {
    return <Barcode height={10} width={1.5} background="" value={props.value} displayValue={false} />;
  } else {
    return null;
  }
};
