import { CommonUtils } from "../../../../../../../../../../utils/CommonUtils";
import { getReportAdditionalSettingsByProperty } from "../../service/ReportUtilService";
import { CUSTOM_TILE_HEADER, CUSTOM_TILE_INNER_VALUE, CUSTOM_TILE_VALUE } from "../graphic/customTile/service/CustomTileReportUtil";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const CardWidget = (props: any) => {
    let chartObj = props.chartObj;
    let reportSettings = props.reportSettings;
    let chartColors = getReportAdditionalSettingsByProperty(reportSettings,'colors') ||  ['#659C45', '#E27C16', '#00A5FD', '#1E3148', '#1E3148', '#8F7849'];
    let iconsList = getReportAdditionalSettingsByProperty(reportSettings,'icons');
    let icon = iconsList?.[props.index]
    // let iconsList = ['DeleteIcon','AccessTimeIcon','DeleteIcon ']
    return (
        <div className="card-widget-container" style={{ background: chartColors[props.index] || CommonUtils.getRandomColor() }}>
            <div className="chart-box">
                <div className="icon-box">
                    <div className="icon">
                        {/* <AccessTimeIcon></AccessTimeIcon> */}
                        {
                            icon && <span  className="" dangerouslySetInnerHTML={{ __html: icon }} />
                        }
                    </div>
                </div>
                <div className="body">
                    <div className="value">{chartObj[CUSTOM_TILE_VALUE] || ''}</div>
                    {/* <div>{chartObj[CUSTOM_TILE_INNER_VALUE] || ''}</div> */}
                    <div className="header">{chartObj[CUSTOM_TILE_HEADER] || ''}</div>
                </div>
            </div>
        </div>
    )
}


