import { State, useState } from '@hookstate/core';
import { KagamiModalModel } from '../../../../../../../components/modal/model/KagamiModalModel';
import { KagamiStateManager } from '../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../constants/SystemConstants';

export class KagamiTabNavigationModel {
  state: any;
  tabs: [];
  activeTab: string;
  tabContent: any;
  tabNameInModalPopup: any;
  openNewTabPopupModal: boolean;
  alreadyExistTabPopupModal: boolean;
  kagamiModalModel: KagamiModalModel;
  tabState: any;
  projectId: string;

  constructor() {
    this.tabs = [];
    this.activeTab = '';
    this.tabContent = {};
    this.tabNameInModalPopup = null;
    this.openNewTabPopupModal = false;
    this.alreadyExistTabPopupModal = false;
    this.kagamiModalModel = new KagamiModalModel();
    this.tabState = {};
    this.projectId = '';
  }
}

export interface KagamiTabNavigationModelProps {
  tabNavigationModel: KagamiTabNavigationModel;
}

export const wrapKagamiTabNavigationModelState = (state: State<KagamiTabNavigationModel>) => {
  const KagamiTabNavigationModelState = state;

  return {
    get getTabs() {
      return KagamiTabNavigationModelState.tabs.get();
    },

    setTabs(tabs: []) {
      let newTab: any = tabs[tabs?.length - 1];
      if (tabs?.length > 10) {
        KagamiTabNavigationModelState.tabNameInModalPopup.set(newTab);
        KagamiTabNavigationModelState.openNewTabPopupModal.set(true);
      } else {
        KagamiTabNavigationModelState.tabs.set(tabs);
      }
    },

    get getActiveTab() {
      return KagamiTabNavigationModelState.activeTab.get();
    },

    setActiveTab(activeTab: string) {
      KagamiTabNavigationModelState.activeTab.set(activeTab);
    },

    get getTabContent() {
      return KagamiTabNavigationModelState.tabContent.get();
    },

    setTabContent(tabPageContent: any) {
      KagamiTabNavigationModelState.tabContent.set(tabPageContent);
    },

    get getTabNameInModalPopup() {
      return KagamiTabNavigationModelState.tabNameInModalPopup.get();
    },

    setTabNameInModalPopup(tabNameInModalPopup: any) {
      KagamiTabNavigationModelState.tabNameInModalPopup.set(tabNameInModalPopup);
    },

    get getOpenNewTabPopupModal() {
      return KagamiTabNavigationModelState.openNewTabPopupModal.get();
    },

    setOpenNewTabPopupModal(openNewTabPopupModal: boolean) {
      KagamiTabNavigationModelState.openNewTabPopupModal.set(openNewTabPopupModal);
    },

    get getAlreadyExistTabPopupModal() {
      return KagamiTabNavigationModelState.alreadyExistTabPopupModal.get();
    },

    setAlreadyExistTabPopupModal(alreadyExistTabPopupModal: boolean) {
      KagamiTabNavigationModelState.alreadyExistTabPopupModal.set(alreadyExistTabPopupModal);
    },

    get getProjectId() {
      return KagamiTabNavigationModelState.projectId.get();
    },

    setProjectId(projectId: string) {
      KagamiTabNavigationModelState.projectId.set(projectId);
    }
  };
};

export const useKagamiTabNavigation = (state: State<KagamiTabNavigationModel>) => {
  let tabNavigationState = wrapKagamiTabNavigationModelState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.TABNAVIGATION_STATE, tabNavigationState);
  return tabNavigationState;
};
