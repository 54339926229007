import * as React from 'react';
import { createState, useState } from '@hookstate/core';
import {
  StockLedgerReportModel,
  StockLedgerReportModelProps,
  useStockLedgerReportModelState
} from '../model/StockLedgerReportModel';
import { makeReport, makeLedgerData, getData, makeFooterRowData } from '../service/StockLedgerReportService';
import { buildLedgerDetailsModal } from '../../modal/controller/ledgerDetailsModalController';
import { StaticReportActions } from '../../../../../../staticReportFilters/ui/StaticReportActions';
import { StaticReportGrid } from '../../../../../../staticReportFilters/ui/StaticReportGrid';

export const BuildStockLedgerReportView = (stockLedgerReportModel: StockLedgerReportModel) => {
  return (
    <StockLedgerReportView key="{'StockLedgerReportView'}" stockLedgerReportModel={stockLedgerReportModel} />
  );
};
const StockLedgerReportView = (props: StockLedgerReportModelProps) => {
  let runReportConfig: any = useState({
    loadReport: false,
    loadFilterData: true,
    showLoader: false,
    reportInfo: {}
  });
  let stockLedgerReportModel = props.stockLedgerReportModel;
  let stockLedgerState = useStockLedgerReportModelState(createState(stockLedgerReportModel));
  stockLedgerReportModel.state = stockLedgerState;
  let staticFilterData: any;
  if (stockLedgerReportModel?.reportData?.staticFilterData) {
    staticFilterData = stockLedgerReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo: any = {}
  if (stockLedgerReportModel?.reportData?.gridReportInfo) {
    gridReportInfo = stockLedgerReportModel.reportData['gridReportInfo'];
  }
  let inputReportConfig = (props.stockLedgerReportModel.reportConfig);
  React.useEffect(() => {
    runReport();
  }, [])

  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo = makeReport(stockLedgerReportModel, inputReportConfig);
    let ledgerData = await getData(stockLedgerReportModel, inputReportConfig);
    let rowData = await makeLedgerData(stockLedgerReportModel, ledgerData);
    let footerRowData = await makeFooterRowData(ledgerData);
    reportInfo['pinnedBottomRowData'] = footerRowData;
    reportInfo['rowData'] = rowData;
    reportInfo['dbLedgerData'] = ledgerData;
    const obj = Object.assign({}, reportInfo);
    props.stockLedgerReportModel.reportData['gridReportInfo'] = obj;
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }
  let config = { reportModel: stockLedgerReportModel, inputReportConfig, gridReportInfo, runReportConfig };
  return (
    <div className="ag-theme-alpine static-report-container static-report-wbp static-report-grid" >
      {buildLedgerDetailsModal(props.stockLedgerReportModel.modelPopup)}
      <StaticReportActions config={config} ></StaticReportActions>
      <StaticReportGrid config={config}></StaticReportGrid>
    </div>
  );
};
