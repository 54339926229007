import makeStyles from '@mui/styles/makeStyles';
import { KagamiDashboardStyle } from '../style/KagamiDashboard';
import StarIcon from '@mui/icons-material/Star';
import { executeProcess } from '../../../../navigator/builder/Menu/controller/KagamiMenuController';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { isEnableTabs, translateToLocalistaion } from '../../process/service/PresentationService';
import { KagamiDashboardModel } from '../model/KagamiDashboardModel';
import { getDashboardModel } from '../ui/KagamiDashboard';
import { KagamiDashboardCardModel } from '../../../../../../../../components/cards/model/KagamiDashboardCardModel';
import { buildDashboardCard } from '../../../../../../../../components/cards/controller/KagamiDashboardCardController';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { tabExistAndContentFromState } from '../../../../header/Tabs/ui/KagamiTabNavigation';
import { KagamiStateManager } from '../../../../../../../../state/KagamiStateManager';
import { appContext } from '../../process/service/PolicyExecutionService';

const useStyles = makeStyles(KagamiDashboardStyle);

export function buildDashboardFavouriteSection(favouriteData: any) {
  return <FavouriteSection key={SystemConstants.FAVOURITE_WIDGET_KEY} favouriteData={favouriteData} />;
}
const FavouriteSection = (props: any) => {
  let dashboardModel: KagamiDashboardModel = getDashboardModel();
  let translations = dashboardModel?.containerData?.otherProperties?.translation;
  const classes = useStyles();
  let getGroupNamesFOrFavouriteData = CommonUtils.isNotEmpty(props.favouriteData)
    ? props.favouriteData.map((ele: any) => ele.groupName)
    : [];
  let uniqGroupNamesFavouriteData = getGroupNamesFOrFavouriteData.filter((obj: any, index: any) => {
    return getGroupNamesFOrFavouriteData.indexOf(obj) == index;
  });

  const title: JSX.Element = (
    <div>
      <div className={classes.dashboard_icon_header}>
        <StarIcon fontSize="medium" className={classes.dashboard_icon} />
        <span className={classes.dashboard_header}>{translateToLocalistaion(translations, "Favourites")}</span>
      </div>
    </div>
  );

  const active = true;

  const tabsFunction = (processName: any, name: any) => {
    let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
    let newName = translateToLocalistaion(translations, name);
    name = newName;
    let tabs = JSON.parse(JSON.stringify(tabNavigationState.getTabs));
    let isTabAlreadyExist = tabs?.findIndex((item: any) => item?.processName === processName);
    if (isTabAlreadyExist !== -1) {
      tabExistAndContentFromState(processName, name);
    } else {
      tabs?.push({ processName, name });
      tabNavigationState.setTabs(tabs);
      if (tabs?.length <= 10) {
        tabNavigationState.setActiveTab(processName);
        executeProcess(processName);
      }
    }
  };

  const content = (
    <div className={classes.notificationContent}>
      <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
        {uniqGroupNamesFavouriteData.map((ele: any, index: any) => {
          return (
            <li className={classes.dashboardNotificationListItem} key={index}>
              <p className={classes.dashboardNotificationGroupName}>
                <abbr title={ele || "Master Data"}>{ele || "Master Data"}</abbr>
              </p>
                {': '}
              <div className={classes.dashboardNotificationGroupProcess}>
                {props.favouriteData.map((processLinkData: any) => {
                  if (processLinkData.groupName === ele) {
                    return (
                      <span
                        key={processLinkData.id}
                        className={classes.dashboardNotificationGroupProcessLink}
                        // onClick={() => tabsFunction(processLinkData.processId, processLinkData.processName)}
                        onClick={() => isEnableTabs (appContext) ? tabsFunction(processLinkData.processId, processLinkData.processName) : executeProcess(processLinkData?.processId)}
                      >
                        {processLinkData.processName}
                      </span>
                    );
                  }
                })}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );

  const dashboardCardModel = new KagamiDashboardCardModel(title, content);
  return <div className={`col_4 ${classes.dashboardCard}`} style={{width: 'inherit', height: 'inherit'}}>{buildDashboardCard(dashboardCardModel)}</div>;
};
  