import PDFLedgerDownload  from '../../../../service/exports/PDFLedgerService';
import ExcelLedgerDownload  from '../../../../service/exports/ExcelLedgerService';
import {exportDetailedLedger} from '../../../../service/KagamiReportService';
import {getAppSettingDateTimeFormat, getReportType, getValueInDateTimeFormatAsAppSetting} from '../../../../service/ReportUtilService';
import {makeFooterRowData} from '../service/DayBookReportService';
import _ from 'lodash';
import { saveAs } from "file-saver";
import dayjs from 'dayjs';

export const onDetailedExcelDownload = async (inputReportConfig:any) => {
  let response: any = await exportDetailedLedger(inputReportConfig);
  saveDetailedExcel(response,'Detailed Ledger')
}

const saveDetailedExcel = (response:any,fileName:any) => {
  fileName = fileName +".xlsx";
  // let contentType = response.headers("content-type");
  let contentType = response.headers["content-type"]
  const byteCharacters = atob (response.data.data);
  const byteNumbers = new Array (byteCharacters.length);
  for (let i = 0; i <byteCharacters.length; i ++) {
    byteNumbers [i] = byteCharacters.charCodeAt (i);
  }
  const byteArray = new Uint8Array (byteNumbers);
  let blob = new Blob ([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  saveAs(blob, fileName);
}





/** EXCEL DOWNLOAD **/
export const downloadExcel = (printTypeIndex:any,reportModel:any) => {
  let gridInfo:any = {};
  if(reportModel?.reportData?.gridReportInfo) {
    gridInfo =reportModel.reportData.gridReportInfo;
  }
  let columnsInfo = getExcelTableColumns(gridInfo,printTypeIndex);
  let configInfo:any = {
    alternateRowColor:true,
    defaultColumnWidth:20,
    fileName: 'Ledger Report',
    rowData:gridInfo['rowData'] || [],
    reportColumns : columnsInfo.columnsList, 
    exportableColumnDefinitions : columnsInfo.columnsList, //grid exportable column definitions,
    excelHeaderInfo :getLedgerHeaderInfo(reportModel.reportConfig),
    excelFooterInfo :getLedgerFooterInfo(reportModel,gridInfo['dbLedgerData'] ),
    excelRTLfields :['debit','credit','balance'],
    asOnDate : dayjs().format(getAppSettingDateTimeFormat()),
    languageTranslation : reportModel.languageTranslation || {}
  }
  configInfo['excelTableRows'] = makeExcelData(configInfo,gridInfo,printTypeIndex);
  const excelLib =  ExcelLedgerDownload(configInfo);
  excelLib.downLoad();
}

const getExcelTableColumns = (gridInfo: any,printTypeIndex:any) => {
  let columnsList:any = [];
  if (gridInfo.columnDefs && gridInfo.columnDefs.length > 0) {
    _.forEach(gridInfo.columnDefs, (col: any) => {
      if(col.field != 'balance') { //closing balance
        columnsList.push(col);
      }
      if(( printTypeIndex == 2 || printTypeIndex == 3 ) && (col.field == 'balance')) { 
        columnsList.push(col);
      }
    });
  }
  return {
    columnsList: columnsList || [],
    widths: _.times(columnsList.length, _.constant('auto')) || []
  };
};


const makeExcelData =(configInfo:any,gridInfo:any,printTypeIndex:any) => {
  let rowsData:any = [];
  let isNarrationExists =  (printTypeIndex == 0 || printTypeIndex == 2)
  if(configInfo.exportableColumnDefinitions && configInfo.exportableColumnDefinitions.length > 0 && gridInfo.rowData && gridInfo.rowData.length > 0) {
    _.forEach(gridInfo.rowData,(rec)=>{
      rowsData.push(rec);
      if(isNarrationExists && rec.nestedFinanceRecord){
        let nestedRows = exportNestedDatatoExcel(configInfo.exportableColumnDefinitions,rec);
        if(nestedRows && nestedRows.length ) {
          rowsData = rowsData.concat(nestedRows);
        }
      }
    })
  }
  return rowsData;
}
const exportNestedDatatoExcel = (exportableColumnDefinitions: any, ledgerRow: any) => {
  let nestedRecords: any = [];
  if (ledgerRow.nestedFinanceRecord && ledgerRow.nestedFinanceRecord.length) {
    _.forEach(ledgerRow.nestedFinanceRecord, (eachrec: any) => {
      let accName = eachrec.accountName ? eachrec.accountName : '';
      let accountValue = eachrec.accountValue ? eachrec.accountValue : '';
      let _rec: any = {};
      _.forEach(exportableColumnDefinitions, (col: any, index: any) => {
        if (index == 2) {
          _rec[col.field] = accName;
        } else if (index == 3) {
          _rec[col.field] = accountValue;
        } else {
          _rec[col.field] = '';
        }
      });
      nestedRecords.push(_rec);
    });
  }
  return nestedRecords;
};




/** END EXCEL DOWNLOAD **/


/** PDF DOWNLOAD **/
export const downloadPDF = (type:any,printTypeIndex:any,reportModel:any) => {
  let gridInfo:any = {};
  if(reportModel?.reportData?.gridReportInfo) {
    gridInfo =reportModel.reportData.gridReportInfo;
  }
  let columnsInfo = getPdfTableColumns(gridInfo,printTypeIndex);
  let configInfo:any  = {
    pageOrientation:type,
    fileName: 'Daybook Report',
    rowData:gridInfo['rowData'] || [],
    pdfHeaderInfo :getLedgerHeaderInfo(reportModel.reportConfig),
    pdfFooterInfo :getLedgerFooterInfo(reportModel, gridInfo['dbLedgerData'] ),
    pdfTableColumns : columnsInfo.exportableColumns,
    exportableColumnDefinitions : columnsInfo.columnsList, //grid exportable column definitions
    pdfTableColumnWidths : columnsInfo.widths,
    pdfRTLfields :['debit','credit','balance'],
    languageTranslation : reportModel.languageTranslation || {}
  }
  // configInfo['pdfTableRows'] =  makePdfData(configInfo,gridInfo,printTypeIndex);
  let exportableData =  makePdfData(configInfo,gridInfo,printTypeIndex);
  makePdfFooterData(configInfo,exportableData); //add footer for ledger
  configInfo['pdfTableRows'] =  exportableData;
  const pdfLib =  PDFLedgerDownload(configInfo);
  pdfLib.downLoad();

}

const getPdfTableColumns = (gridInfo: any,printTypeIndex:any) => {
  let exportableColumns: any = [];
  let columnsList:any = [];
  if (gridInfo.columnDefs && gridInfo.columnDefs.length > 0) {
    _.forEach(gridInfo.columnDefs, (col: any) => {
      let headerCol: any = {
        text: col.headerName,
        style: 'tableHeader'
      };

      if(isExportableRightAlignmentField(col.field)){
        headerCol['alignment'] = 'right';
      }
      // if (col.field == 'debit' || col.field == 'credit' || col.field == 'balance') {
      //   headerCol['alignment'] = 'right';
      // }
      if(col.field != 'balance') { //closing balance
        exportableColumns.push(headerCol);
        columnsList.push(col);
      }
      if(( printTypeIndex == 2 || printTypeIndex == 3 ) && (col.field == 'balance')) { //closing balance
        exportableColumns.push(headerCol);
        columnsList.push(col);
      }
    });
  }
  return {
    exportableColumns: exportableColumns || [],
    columnsList: columnsList || [],
    // widths: _.times(columnsList.length, _.constant('*')) || []
    // widths: _.times(columnsList.length, _.constant('auto')) || []
    widths: _.times(columnsList.length, _.constant(100/columnsList.length+"%")) || []
  };
};

const getLedgerHeaderInfo = (reportConfig:any) => {
  let headerConfig:any = {};
  if(reportConfig) {
    headerConfig['reportType'] =  getReportType(reportConfig) || '';
    headerConfig['subOrganization'] = reportConfig.subOrganization ? reportConfig.subOrganization.join(" "):'';
    headerConfig['selectedValue'] =reportConfig.selectedValue || ''; 
    headerConfig['date'] = getLedgerSelectedDates(reportConfig);
    headerConfig['exportedDate'] = printFormatDate(new Date()) || '';
    headerConfig['title'] = 'Day Book';
  }
  return headerConfig;
}
const getLedgerFooterInfo = (reportModel:any ,ledgerData:any) => {
  return  makeFooterRowData(reportModel,ledgerData) || [];
}
const makePdfData =(configInfo:any,gridInfo:any,printTypeIndex:any) => {
  let rowsData:any = [];
  let isNarrationExists =  (printTypeIndex == 0 || printTypeIndex == 2)
  if(configInfo.exportableColumnDefinitions && configInfo.exportableColumnDefinitions.length > 0 && gridInfo.rowData && gridInfo.rowData.length > 0) {
    _.forEach(gridInfo.rowData,(rec)=>{
      let _row:any = []
      _.forEach(configInfo.exportableColumnDefinitions,(col)=>{
        let pdfRow:any = {text:rec[col.field] || ''}
        if(configInfo.pdfRTLfields && col.field &&  configInfo.pdfRTLfields.indexOf(col.field) !=-1) {
          pdfRow['alignment'] = 'right';
        }
        _row.push(pdfRow);
        //_row.push({text:rec[col.field] || ''});
      });
      rowsData.push(_row);
      if(isNarrationExists && rec.nestedFinanceRecord && rec.nestedFinanceRecord.length ){
        let nestedRow = exportNestedDataToPdf(configInfo.exportableColumnDefinitions,rec,printTypeIndex);
        rowsData.push(nestedRow);
      }

    })
  }
  if(rowsData)
  return rowsData;
}
const exportNestedDataToPdf = (exportableColumnDefinitions:any,ledgerRow:any,printTypeIndex:any)=> {
  let nestedtablebody:any =[]
    _.forEach(ledgerRow.nestedFinanceRecord, (eachrec: any, index: any) => {
      let accName =eachrec.accountName ?eachrec.accountName:"";
      let accountValue =eachrec.accountValue ?eachrec.accountValue : "";
       nestedtablebody.push([{ text: accName,fontSize:8},{ text: accountValue,fontSize:8} ]);
    })
   let nestedColVals = ["",{colSpan: exportableColumnDefinitions.length-2, table: { widths: [250, '*' ],body: nestedtablebody},layout: 'noBorders'}]; //2 columns done;
   _.forEach(exportableColumnDefinitions, (col: any, index: any) => {
     if (index != 0 && index != 1) {
       if (col.field != 'balance') {
          nestedColVals.push("");
       }
       if ((printTypeIndex == 2 || printTypeIndex == 3) && col.field == 'balance') {
          nestedColVals.push("");
       }
     }
   });
   return nestedColVals;
}
const makePdfFooterData = (configInfo:any,exportableData:any) => {
  if(configInfo.exportableColumnDefinitions && configInfo.exportableColumnDefinitions.length > 0 && configInfo.pdfFooterInfo ) {
    configInfo.exportableColumnDefinitions
    exportableData.push( _.times(configInfo.exportableColumnDefinitions.length, _.constant({text:''})));
    exportableData.push( _.times(configInfo.exportableColumnDefinitions.length, _.constant({text:''})));// empty rows
    _.forEach(configInfo.pdfFooterInfo,(rec)=>{
      let _row:any = []
      _.forEach(configInfo.exportableColumnDefinitions,(col)=>{
        _row.push({text:rec[col.field] || '','alignment':'right'});
      });
      exportableData.push(_row);
    })
  }
}
const isExportableRightAlignmentField =(field:any) => {
  return field == 'debit' || field == 'credit' || field == 'balance';
}   
const isExportableBoldField =(field:any) => {
  return field == 'debit' || field == 'credit' || field == 'balance';
}

const getLedgerSelectedDates = (reportConfig:any)=>{
  if(reportConfig.leftPanel){
    return printFormatDate(reportConfig.leftPanel.startDate)+" To "+printFormatDate(reportConfig.leftPanel.endDate);
  }
  return '';
}
const printFormatDate = (date:any) => {
  if(date){
       return getValueInDateTimeFormatAsAppSetting(date, 'date'); 
  }
  return '';
}

/** END PDF DOWNLOAD **/