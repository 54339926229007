import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React from 'react';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { CUSTOM_TILE_HEADER, CUSTOM_TILE_INNER_VALUE, CUSTOM_TILE_VALUE, getDoughnutWidgetData } from '../graphic/customTile/service/CustomTileReportUtil';
import { getReportAdditionalSettingsByProperty, getValueColumnForCustomTile, reportColumnDisplayFormatter } from '../../service/ReportUtilService';


export const DoughnutWidget = (props: any) => {
    let chartObj = props.chartObj;
    let reportSettings = props.reportSettings;
    let reportConfig = props.customTileReportModel?.reportConfig;
    const chartComponent: any = React.useRef(null);
    const containerRef = React.useCallback((node: any) => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            chartComponent?.current?.chart.reflow();
        });
        resizeObserver?.observe(node);
    }, []);

    let chartColors = getReportAdditionalSettingsByProperty(reportSettings, 'colors') || ['#659C45', '#E27C16', '#00A5FD', '#1E3148', '#1E3148', '#8F7849'];
    let column = getValueColumnForCustomTile(reportConfig);
    let formattedValue = column && reportColumnDisplayFormatter(reportConfig, chartObj[CUSTOM_TILE_VALUE] || '', 'Value',column);

    const doughnutConfig = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            // minHeight: 100,
            // maxHeight: 250,
            // minWidth: 100,
            // maxWidth: 250,
            // height: 150,
            events: {
                load: function () {
                    adjustTitlePosition(this);
                },
                redraw: function () {
                    adjustTitlePosition(this);
                }
            }
        },
        tooltip: {
            enabled: false // Disable tooltips
        },
        title: {
            text: (chartObj[CUSTOM_TILE_INNER_VALUE] || '') + "%", // Text to display in the center
            align: 'center',
            verticalAlign: 'middle',
            y: 10,
            style: {
                fontSize: '13px',
                fontWeight: 'bold'
            }
        },
        subtitle: {
            text: `<div style="text-align:center;">
                    <span style="font-size: 13px; font-weight: bold;">${formattedValue}</span><br/>   
                </div>
                <div style="text-align:bottom;">
                <span style="font-size: 11px;">${chartObj[CUSTOM_TILE_HEADER]}</span>
                </div>
            `,
            useHTML: true,//chartObj[CUSTOM_TILE_HEADER],
            verticalAlign: 'bottom',  // This keeps the subtitle at the bottom
            y: -90                     // Adjusts the vertical position
        },
        plotOptions: {
            pie: {
                innerSize: '70%',
                dataLabels: {
                    enabled: false,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        exporting: {
            enabled: false // Disable the export button
        },
        colors: chartColors,
        series: [{
            name: '',
            data: getDoughnutWidgetData(chartObj, chartColors[props.index])
        }],
    }

    // Function to adjust title and subtitle positions
    function adjustTitlePosition(chart: any) {
        const title = chart.title;
        const subtitle = chart.subtitle;

        // Calculate dynamic positions based on chart height
        const chartHeight = chart.chartHeight;

        title.update({ y: chartHeight / -30 });  // Set dynamic y position for title
        subtitle.update({ y: chartHeight / -20 });  // Set dynamic y position for subtitle
    }

    return (
        <div className={`doughnut-container`} >
            <div className={`chart-box`} ref={containerRef}>
                <Chart ref={chartComponent} highcharts={Highcharts} options={doughnutConfig} />
            </div>
        </div>
    )
}


            // <div className="content">
            //     {/* <div className="title">{chartObj[CUSTOM_TILE_VALUE]}</div> */}
            //     <div className="title">{formattedValue}</div>
            //     <div className="sub-title">{chartObj[CUSTOM_TILE_HEADER]}</div>
            // </div>
