import { createState, useState } from '@hookstate/core';
import _ from 'lodash';
import * as React from 'react';
import { BalanceSheetReportModel, BalanceSheetReportModelProps, useBalanceSheetReportModelState } from '../model/BalanceSheetReportModel';
import { getData, makeReport, makeReportData } from '../service/BalanceSheetReportService';
import { StaticReportActions } from '../../../../../../staticReportFilters/ui/StaticReportActions';
import { StaticReportGrid } from '../../../../../../staticReportFilters/ui/StaticReportGrid';

export const BuildBalanceSheetReportView = (balanceSheetReportModel: BalanceSheetReportModel) => {
  return (
    <BalanceSheetReportView key={'balance-sheet-report'} balanceSheetReportModel={balanceSheetReportModel}></BalanceSheetReportView>
  );
};
const BalanceSheetReportView = (props: BalanceSheetReportModelProps) => {
  let runReportConfig: any = useState({
    loadReport: false,
    loadFilterData: true,
    showLoader: false,
    reportInfo: {}
  });

  let balanceSheetReportModel = props.balanceSheetReportModel;
  let balanceSheetChartState = useBalanceSheetReportModelState(createState(balanceSheetReportModel));
  balanceSheetReportModel.state = balanceSheetChartState;
  let staticFilterData: any;
  if (balanceSheetReportModel?.reportData?.staticFilterData) {
    staticFilterData = balanceSheetReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo: any = {}
  if (balanceSheetReportModel?.reportData?.gridReportInfo) {
    gridReportInfo = balanceSheetReportModel.reportData['gridReportInfo'];
  }
  let inputReportConfig = (props.balanceSheetReportModel.reportConfig);

  React.useEffect(() => {
    runReport();
  }, [])

  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo = makeReport(balanceSheetReportModel);
    let balanceSheetReportData = await getData(balanceSheetReportModel, inputReportConfig)
    let rowData = await makeReportData(balanceSheetReportModel, balanceSheetReportData)
    reportInfo['rowData'] = rowData;
    reportInfo['reportDBData'] = balanceSheetReportData;
    const obj = Object.assign({}, reportInfo);
    props.balanceSheetReportModel.reportData['gridReportInfo'] = obj;
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }
  const gridRef: any = React.useRef();
  const expandAll = React.useCallback(() => {
    if (!balanceSheetReportModel.collapseAll) {
      gridRef.current!.api.expandAll();
      balanceSheetReportModel.collapseAll = true;
    } else {
      gridRef.current!.api.collapseAll();
      balanceSheetReportModel.collapseAll = false;
    }

  }, []);
  let config = { reportModel: balanceSheetReportModel, inputReportConfig, gridReportInfo, runReportConfig, expandAll, gridRef };
  return (
    <div className="Report-MuiFormControl-root  " style={{ width: '100%', background: '#fff', padding: '10px' }}>
      <StaticReportActions config={config} ></StaticReportActions>
      <StaticReportGrid config={config}></StaticReportGrid>
    </div>
  );
};
