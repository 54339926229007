import { notifyChangeToForm } from '../../../form/controller/KagamiFormController';
import { KagamiBarCodeModel } from '../model/BarCodeModel';
import { KagamiBarCode } from '../ui/BarCode';
import KagamiBarCodeValidator from '../../validations/KagamiControlValidator';

export function buildBarCode(kagamiBarCodeModel: KagamiBarCodeModel) {
  return KagamiBarCode(kagamiBarCodeModel);
}

export function validateBarCodeBox(kagamiBarCodeModel: KagamiBarCodeModel, value: any) {
  kagamiBarCodeModel.controlValue = value;
  let errorMsg = KagamiBarCodeValidator.validate(
    kagamiBarCodeModel?.controlType,
    value,
    kagamiBarCodeModel?.validation,
    kagamiBarCodeModel
  );
  if (errorMsg === null) {
    kagamiBarCodeModel?.state?.setErrorMessage(errorMsg);
    notifyChangeToForm(kagamiBarCodeModel, value);
  } else {
    kagamiBarCodeModel?.state?.setErrorMessage(errorMsg);
    notifyChangeToForm(kagamiBarCodeModel, value);
  }
}
