import dayjs from 'dayjs';
import { hideLoader, showLoader } from '../../../../../../../components/loader/controller/KagamiLoaderController';
import { APIService } from '../../../../../../service/ApiService';
import { SystemConstants } from '../../../../../../constants/SystemConstants';
import {
  getSlowLogApiEventPagination,
  getSlowLogApiPagination,
  slowApiLogEventFilter,
  slowApiLogFilter
} from '../../../../../../service/URLService';
import { CommonUtils } from '../../../../../../utils/CommonUtils';
import { addRow, addRowChildren, paginationSettings } from '../controller/KagamiSlowApiLogController';

export function dateConversionForFromDateAndFromTime(fromDate: any, fromTime: any) {
  let data: any = {
    startTime: ''
  };
  if (fromDate !== null && fromTime !== null) {
    let date = dayjs(fromDate);
    let time = dayjs(fromTime);
    date = date.set('hour', time.hour()).set('minute', time.minute()).set('second', time.second());
    data.startTime = formatDateWithOffset(date?.toDate());
  }
  return data.startTime;
}

export function dateConversionForToDateAndToTime(toDate: any, toTime: any) {
  let data: any = {
    endTime: ''
  };
  if (toDate !== null && toTime !== null) {
    let date = dayjs(toDate);
    let time = dayjs(toTime);
    date = date.set('hour', time.hour()).set('minute', time.minute()).set('second', time.second());
    data.endTime = formatDateWithOffset(date?.toDate());
  }
  return data.endTime;
}

export function formatDateWithOffset(date: Date): string {
  const pad: any = (number: number): string => String(number).padStart(2, '0');
  const year = date?.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date?.getDate());
  const hours = pad(date?.getHours());
  const minutes = pad(date?.getMinutes());
  const seconds = pad(date?.getSeconds());
  const absoluteOffsetMinutes = Math.abs(date?.getTimezoneOffset());
  const offsetHours = Math.floor(absoluteOffsetMinutes / 60);
  const offsetMinutesRemainder = absoluteOffsetMinutes % 60;
  const formattedOffset = `+${pad(offsetHours)}:${pad(offsetMinutesRemainder)}`;
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${formattedOffset}`;
}

export function fromAndToDateValidation(start: any, end: any) {
  var startDate = new Date(start);
  var endDate = new Date(end);
  if (endDate >= startDate) {
    return true;
  } else {
    return false;
  }
}

export function threeMonthDifferenceValidation(fromDate: any, toDate: any) {
  let diff: any;
  var startDate = new Date(fromDate);
  var endDate = new Date(toDate);
  diff = Math.abs(startDate.getTime() - endDate.getTime());
  return diff;
}

export function dateAndTimeValidations(fromDate: any, fromTime: any, toDate: any, toTime: any, props: any) {
  let dateRange: boolean = false;
  let errorMsg: string = '';
  if (!fromDate || !fromTime || !toDate || !toTime) {
    errorMsg = 'Please select Both Dates And Times';
    props?.kagamiSlowApiLogModel?.state?.setErrors(errorMsg);
    return;
  }
  dateRange = fromAndToDateValidation(fromDate, toDate);
  if (dateRange != true) {
    errorMsg = 'To Date Should Not Be Greater Than From Date';
    props?.kagamiSlowApiLogModel?.state?.setErrors(errorMsg);
    return;
  }
  let daysDiff = threeMonthDifferenceValidation(fromDate, toDate);
  if (daysDiff > 90 * 24 * 60 * 60 * 1000) {
    errorMsg = 'Days Difference Should Not Be More Than 90 Days';
    props?.kagamiSlowApiLogModel?.state?.setErrors(errorMsg);
    return;
  }
}

export function turnAroundTimeFormatting(props: any) {
  let turnAroundTime: any;
  if (props?.kagamiSlowApiLogModel?.state?.radioValue === 'notCompleted') {
    turnAroundTime = null;
  } else if (props?.kagamiSlowApiLogModel?.state?.radioValue === 'sec') {
    turnAroundTime = props?.kagamiSlowApiLogModel?.turnAroundTime * 1000;
  } else {
    turnAroundTime = props?.kagamiSlowApiLogModel?.turnAroundTime;
  }
  return turnAroundTime;
}

export function apiPayLoad(startTime: any, endTime: any, props: any) {
  let apiPayLoadData = {
    startTime: startTime,
    endTime: endTime,
    totalAroundTime: turnAroundTimeFormatting(props),
    userId: CommonUtils.isNotEmpty(props?.kagamiSlowApiLogModel?.state?.user)
      ? props?.kagamiSlowApiLogModel?.state?.user
      : null,
    event: CommonUtils.isNotEmpty(props?.kagamiSlowApiLogModel?.state?.activeEvent)
      ? props?.kagamiSlowApiLogModel?.state?.activeEvent
      : null
  };
  return apiPayLoadData;
}

export function filterSearch(props: any, fromDate: any, fromTime: any, toDate: any, toTime: any) {
  props?.kagamiSlowApiLogModel?.state?.setGroupByEvent(false);
  let startTime = dateConversionForFromDateAndFromTime(fromDate, fromTime);
  let endTime = dateConversionForToDateAndToTime(toDate, toTime);
  props?.kagamiSlowApiLogModel?.state?.setStartTime(startTime);
  props?.kagamiSlowApiLogModel?.state?.setEndTime(endTime);
  props?.kagamiSlowApiLogModel?.state?.setTurnAroundTime(props?.kagamiSlowApiLogModel?.turnAroundTime);
  props?.kagamiSlowApiLogModel?.state?.setUser(props?.kagamiSlowApiLogModel?.user);
  if (CommonUtils.isEmpty(props?.kagamiSlowApiLogModel?.state?.errors)) {
    showLoader(true);
    APIService.getData(SystemConstants.POST, slowApiLogFilter(), apiPayLoad(startTime, endTime, props))
      .then((response: any) => {
        if (response?.status === 200 && CommonUtils.isNotEmpty(response?.data)) {
          props?.kagamiSlowApiLogModel?.state?.setNestedEvent(false);
          props?.kagamiSlowApiLogModel?.state?.setTotalRecords(response?.data?.totalElements);
          addRow(response?.data, props);
          paginationSettings(props, response);
          hideLoader();
        }
      })
      .catch((err: any) => {
        console.log('err', err);
        hideLoader();
      });
  }
}

export function filterSearchForEvent(props: any, fromDate: any, fromTime: any, toDate: any, toTime: any) {
  let startTime = dateConversionForFromDateAndFromTime(fromDate, fromTime);
  let endTime = dateConversionForToDateAndToTime(toDate, toTime);
  props?.kagamiSlowApiLogModel?.state?.setStartTime(startTime);
  props?.kagamiSlowApiLogModel?.state?.setEndTime(endTime);
  if (CommonUtils.isEmpty(props?.kagamiSlowApiLogModel?.state?.errors)) {
    showLoader(true);
    APIService.getData(SystemConstants.POST, slowApiLogEventFilter(), apiPayLoad(startTime, endTime, props))
      .then((response: any) => {
        if (response?.status === 200 && CommonUtils.isNotEmpty(response?.data)) {
          addRow(response?.data, props);
          props?.kagamiSlowApiLogModel?.state?.setGroupByEvent(true);
          props?.kagamiSlowApiLogModel?.state?.setNestedEvent(true);
          props?.kagamiSlowApiLogModel?.state?.setTotalRecords(response?.data?.totalElements);
          paginationSettings(props, response);
          hideLoader();
        }
      })
      .catch((err: any) => {
        console.log('err', err);
        hideLoader();
      });
  }
}

export function nestedChildClick(data: any, props: any) {
  let eventId = JSON.parse(JSON.stringify(data?.event));
  let startTime = props?.kagamiSlowApiLogModel?.state?.startTime;
  let endTime = props?.kagamiSlowApiLogModel?.state?.endTime;
  showLoader(true);
  APIService.getData(SystemConstants.POST, slowApiLogFilter(), apiPayLoad(startTime, endTime, props))
    .then((response: any) => {
      if (response?.status === 200 && CommonUtils.isNotEmpty(response?.data)) {
        addRowChildren(response?.data?.content, eventId, props);
        props.kagamiSlowApiLogModel.nestedEventId = eventId;
        hideLoader();
      }
    })
    .catch((err: any) => {
      console.log('err', err);
    });
}

export function paginationPreviousPageHandler(props: any) {
  if (
    Number(props?.kagamiSlowApiLogModel?.state?.pageNumber) < Number(props?.kagamiSlowApiLogModel?.state?.totalPages)
  ) {
    let params = {
      pageNumber: Number(props?.kagamiSlowApiLogModel?.state?.pageNumber) - 1,
      pageSize: props?.kagamiSlowApiLogModel?.state?.pageSize
    };
    slowApiLogPagination(props, params);
  }
}

export function paginationNextPageHandler(props: any) {
  if (Number(props?.kagamiSlowApiLogModel?.state?.pageNumber) >= 0) {
    let params = {
      pageNumber: Number(props?.kagamiSlowApiLogModel?.state?.pageNumber) + 1,
      pageSize: props?.kagamiSlowApiLogModel?.state?.pageSize
    };
    slowApiLogPagination(props, params);
  }
}

export function slowApiLogPagination(props: any, pagination: any) {
  let params = {
    startTime: 'startTime',
    sortDir: 'ASC',
    pageNo: pagination?.pageNumber,
    pageSize: pagination?.pageSize
  };
  let startTime = props?.kagamiSlowApiLogModel?.state?.startTime;
  let endTime = props?.kagamiSlowApiLogModel?.state?.endTime;
  showLoader();
  if (props?.kagamiSlowApiLogModel?.state?.groupByEvent) {
    APIService.getData(
      SystemConstants.POST,
      getSlowLogApiEventPagination(params?.startTime, params?.sortDir, params?.pageNo, params?.pageSize),
      apiPayLoad(startTime, endTime, props)
    )
      .then((response: any) => {
        if (response?.status === 200 && CommonUtils.isNotEmpty(response?.data)) {
          props?.kagamiSlowApiLogModel?.state?.setRowData(response.data.content);
          paginationSettings(props, response);
          hideLoader();
        }
      })
      .catch((err: any) => {
        console.log('err', err);
      });
  } else {
    APIService.getData(
      SystemConstants.POST,
      getSlowLogApiPagination(params?.startTime, params?.sortDir, params?.pageNo, params?.pageSize),
      apiPayLoad(startTime, endTime, props)
    )
      .then((response: any) => {
        if (response?.status === 200 && CommonUtils.isNotEmpty(response?.data)) {
          props?.kagamiSlowApiLogModel?.state?.setRowData(response?.data?.content);
          paginationSettings(props, response);
          hideLoader();
        }
      })
      .catch((err: any) => {
        console.log('err', err);
      });
  }
}

export function getMinDate() {
  const today = new Date();
  const ninetyDaysAgo = today.setDate(today.getDate() - 89);
  return new Date(ninetyDaysAgo);
}
