import { createState } from '@hookstate/core';
import {
  DoughnutReportModel,
  DoughnutReportModelProps,
  useDoughnutReportModelState
} from '../model/DoughnutReportModel';
import { downloadDoughnutPDF, getTotalOfReportColumn, makeAdditionalReport, makeDoughnut } from '../service/DoughnutReportService';
import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React, {  useRef } from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts)



import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { buildReportsPageController } from '../../../../../../landingPage/builder/ReportsPage/controller/ReportsPageController';
import { getReportAdditionalSettingsByProperty } from '../../../../service/ReportUtilService';



export const BuildDoughnutReportView = (doughnutReportModel: DoughnutReportModel) => {
  return (
    <KagamiDoughnutChartReportView key={'doughnut-chart-report'} doughnutReportModel={doughnutReportModel}></KagamiDoughnutChartReportView>
  );
};


const KagamiDoughnutChartReportView = (props: DoughnutReportModelProps) => {
  const chartRef:any = useRef();
  let doughnutReportModel = props.doughnutReportModel;
  let doughnutChartState = useDoughnutReportModelState(createState(doughnutReportModel));
  doughnutReportModel.state = doughnutChartState;
  let _config :any ={};
  let totalValue :any = 0;
  if(doughnutReportModel.contentReport)console.log(doughnutReportModel?.contentReport[0]??'getting undefined');
  React.useEffect(() => {
    getReportDataForDynamicReports(props.doughnutReportModel);
    if(props.doughnutReportModel?.reportConfig?.reportSettings?.additionalReportId) {
        makeAdditionalReport(props.doughnutReportModel.reportConfig.reportSettings.additionalReportId,props.doughnutReportModel);
    }

  }, [])

  const containerRef = React.useCallback((node:any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => { 
      chartRef.current?.chart?.reflow();
    });
    resizeObserver.observe(node);
  }, []);

  
  const downLoad = ()=> {
    downloadDoughnutPDF(chartRef,_config,doughnutReportModel);
  }

  if(!doughnutChartState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" /> 
  }else{
    if (doughnutReportModel.reportData){
        
  _config  = makeDoughnut(doughnutReportModel);
  totalValue = getTotalOfReportColumn(_config);
  let isCustomLegend = getReportAdditionalSettingsByProperty(props.doughnutReportModel?.reportConfig?.reportSettings,'customlegend');
  return (
    <Grid container spacing={{ xs: 2, md: 3 }} style={{ marginTop:'5px'}} className='dashboard-doughnut-container'>
      <Grid item xs={(_config.tableConfig?.isBesideTableDetails || isCustomLegend ) && !doughnutChartState.isAdditionalReportLoaded ? 6:12} className='dashboard-doughnut-chart-container'>
        <div ref ={containerRef} className="ag-theme-alpine dashboard-highchart" style={{ width: '100%' }}>
          <Chart ref ={chartRef} highcharts={Highcharts} options={_config.doughnutConfig}/>
        </div>
      </Grid>
      {isCustomLegend &&
        <Grid item xs={6} style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
          {_config.doughnutConfig.customLegendData?.map((rec: any) => (
            <div style={{ display: 'flex', verticalAlign: 'middle', alignItems: 'center' }}> <div> <div style={{ width: '15px', height: '15px', background: rec.color, marginRight: '10px' }}> </div> </div>  <div style={{ marginRight: '10px' }}> {rec.name} </div> <div> {rec.value} </div> </div>
          ))}
        </Grid>
      }
        {(_config?.tableConfig?.showTableDetails && !doughnutChartState.isAdditionalReportLoaded) &&
        <Grid item xs={6}>
          <Grid item xs={12} style={{ float:'right',marginBottom:'10px'}}>
          {!doughnutChartState.isAdditionalReportLoaded &&  
            <Button
              id="print-button"
              variant="contained"
              onClick={downLoad}
            >
              Download
            </Button>
         }
          </Grid>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {_config?.tableConfig?.detailedTableconfig?.columns?.map((col: any) => (
                    <TableCell style={{background:'#f2f2f2'}} component="th" scope="row">
                      {col.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {_config?.tableConfig?.detailedTableconfig?.data?.map((rec:any) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                   {_config?.tableConfig?.detailedTableconfig?.columns?.map((col: any) => (
                    <TableCell component="th" scope="row">
                      {rec[col.field]}
                    </TableCell>
                  ))}
                  </TableRow>
                ))}
                  <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {_config?.tableConfig?.detailedTableconfig?.columns?.map((col: any) => (
                      <TableCell component="th" scope="row">
                        {col.field =='y' ? ("Total : "+totalValue) :''}
                      </TableCell>
                    ))}
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          </Grid>
        
        </Grid>
        }
        <Grid item xs={12} style={{ marginBottom:'25px'}} className='dashboard-no-margin'>
          {doughnutChartState.isAdditionalReportLoaded && 
            <div>
              {buildReportsPageController(doughnutReportModel?.additionalReportContent, true)}
            </div>
          }
        </Grid>

        <Grid item xs={12} style={{ marginBottom:'25px'}} className='dashboard-no-margin'>
          {
            doughnutChartState.isContentReportLoaded && <div>
              {buildReportsPageController(doughnutReportModel?.contentReport, true)}
            </div>
          }
        </Grid>
    </Grid>
  );
}else{
  return <p className='NoDataFound'>No Data Found</p>
}
}
};
