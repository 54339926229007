import { ReportFilterModel } from './../../../../../../filter/model/KagamiReportFilterModel';
import _ from 'lodash';
import { getFilterSelectedHeader } from '../../../../service/KagamiReportService';
import {
    getReportData, getReportNameByConfig, getReportUOM,
} from '../../../../service/ReportUtilService';
import { getCagtegories, getSeriesData, getXAxisCategoriesForGroupedChart, getYAxisLabels, makeMultipleGroupedStackedBarChartData, makeStackedBarMultipleYAxisSeriesData } from './StackedBarUtil';
const stackedBarDefaultConfig = (reportConfig: any) => {
    return {
        chart: {
            type: 'column',
        },
        exporting: {
            enabled: true
        },
        title: {
            text: getReportNameByConfig(reportConfig),
            align: 'center',
        },
        subtitle: {
            text: '',
        },
        tooltip: {
            shared: true,
            valueSuffix: ' '
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: true
            },
            series: {
                stacking: 'normal'
            }
        },
        credits: {
            enabled: false,
        },
    }
}

export const makeStackedBar = (stackedBarReportController: any) => {
    let defaultConfig = stackedBarDefaultConfig(stackedBarReportController.reportConfig);
    let dbChartData = JSON.parse(stackedBarReportController.reportData);
    let tableRecords = getReportData(dbChartData, stackedBarReportController.reportConfig);
    let userConfig = prapareUserConfigForChart(tableRecords, stackedBarReportController.reportConfig, false, dbChartData,stackedBarReportController);
    let dispchartOptions = _.extend(true, {}, defaultConfig, userConfig);
    return dispchartOptions;
}

const prapareUserConfigForChart = (tableRecords: any, reportConfig: any, isDashboard: any, dbChartData: any,stackedBarReportController:any) => {

    if (reportConfig?.reportSettings?.StackedbarChart?.customStackedBar) {
        if (reportConfig?.horizontalAxis?.length > 1) {
            if (reportConfig.reportSettings.StackedbarChart.customStackedBarGrouping) {
                return drawMultipleGroupedStackedBarChart(reportConfig, tableRecords, dbChartData,stackedBarReportController);
            } else {
                return drawStackedBarChartMultipleYAxis(reportConfig, tableRecords,stackedBarReportController);
            }

        } else {
            return drawStackedBarChartSingleYAxis(reportConfig, tableRecords, dbChartData,stackedBarReportController);
        }
    } else {
        return drawStackedBarchart(tableRecords, reportConfig, isDashboard,stackedBarReportController);
    }

}

const drawStackedBarchart = (tableRecords: any, reportConfig: any, isDashboard: any,stackedBarReportController:any) => {
    let userConfig: any = {
        subtitle: {
            text: getFilterSelectedHeader(stackedBarReportController) || reportConfig.header
        },
        yAxis: {
            title: {
                text: getYAxisLabels(reportConfig.horizontalAxis)
            },
            min: 0,
        },
        xAxis: {
            title: {
                text: reportConfig.verticalAxis
            },
            min: 0,
            categories: getCagtegories(reportConfig.verticalAxis, tableRecords)
        },
        series: []
    };
    let seriesData: any = [];
    let xaxis = reportConfig.verticalAxis[0];
    let yaxis = reportConfig.horizontalAxis;
    if (xaxis && yaxis) {
        let yaxisData: any = [];
        _.forEach(yaxis, function (axis, i) {
            _.forEach(tableRecords, function (rec, index) {
                yaxisData.push([rec[axis]]);
            });
            seriesData = [{
                name: axis,
                data: yaxisData
            }];
            userConfig.series.push(seriesData[0]);
        });
    }
    return userConfig;
}

/**  Stacked bar chart multiple grouped Y Axis with single X axis*/
const drawStackedBarChartSingleYAxis = (reportConfig: any, tableRecords: any, dbChartData: any,stackedBarReportController:any) => {
    // let filterHeader = '';
    let userConfig = {
        subtitle: {
            text: getFilterSelectedHeader(stackedBarReportController) || reportConfig.header
        },
        xAxis: {
            title: {
                text: reportConfig.verticalAxis
            },
            min: 0,
            categories: getXAxisCategories(reportConfig, dbChartData.table),
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: getYAxisLabels(reportConfig.horizontalAxis)
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                // stacking: 'normal',
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{y} ' + getReportUOM(reportConfig)
                }
            }
        },
        series: getSeriesData(reportConfig,tableRecords)
    };

    return userConfig;
}



/*Multiple Grouped x Axis */
const drawMultipleGroupedStackedBarChart = (reportConfig: any, tableRecords: any, dbChartData: any,stackedBarReportController:any) => {
    let userConfig =
    {
        subtitle: {
            text: getFilterSelectedHeader(stackedBarReportController) || reportConfig.header
        },
        xAxis: {
            categories: getXAxisCategoriesForGroupedChart(reportConfig, dbChartData),
        },
        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: getYAxisLabels(reportConfig.horizontalAxis)
            }
        },
        tooltip: {
            // formatter: function () {
            //     return '<b>' + this.x + '</b><br/>' +
            //         this.series.name + ': ' + this.y + '<br/>'
            // }
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true,
                    format: '{y} ' + getReportUOM(reportConfig)
                }
            }
        },
        series: makeMultipleGroupedStackedBarChartData(reportConfig, tableRecords)
    }
    return userConfig;
}


function drawStackedBarChartMultipleYAxis(reportConfig: any, tableRecords: any,stackedBarReportController:any) {
    let userConfig =
    {
        subtitle: {
            text: getFilterSelectedHeader(stackedBarReportController) || reportConfig.header
        },
        xAxis: {
            categories: getXAxisCategories(reportConfig, tableRecords),
        },
        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: getYAxisLabels(reportConfig.horizontalAxis)
            }
        },
        tooltip: {
            // formatter: function () {
            //     return '<b>' + this.x + '</b><br/>' +
            //         this.series.name + ': ' + this.y + '<br/>' +
            //         'Total: ' + this.point.stackTotal;
            // }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    format: '{y} ' + getReportUOM(reportConfig)
                }
            }
        },
        series: makeStackedBarMultipleYAxisSeriesData(reportConfig, tableRecords)
    }
    return userConfig;

}

const getXAxisCategories = (reportConfig: any, chartData: any) => {
    var xaxis = reportConfig.verticalAxis[0];
    var index = _.findIndex(chartData.cols, { label: xaxis });
    var categories = _.map(chartData.rows, function (row) {
        return row.c[index].v;
    });
    return _.uniq(categories);
}

