import { Key } from "react";
import { CircleWidget } from "../../../widgets/CircleWidget";
import { DoughnutWidget } from "../../../widgets/DoughnutWidget"
import { CardWidget } from "../../../widgets/CardWidget";
import { getReportAdditionalSettings } from "../../../../service/ReportUtilService";
import { GaugeWidget } from "../../../widgets/GaugeWidget";
import { MatrixWidget } from "../../../widgets/MatrixWidget";
import { KeyValueWidget } from "../../../widgets/KeyValueWidget";

export const CustomWidget = (config: any) => {
    let chartRecords = config?.customTileConfig?.tableRecords || [];
    let customTileReportModel = config?.customTileReportModel;
    let reportSettings = getReportAdditionalSettings(config);
    let reportType = reportSettings?.uiSettings?.chartType;
    return (
        <div className="custom-tiles-container">
            {reportType != 'matrix' && <div className={`custom-tile-box`} >
                {chartRecords?.map((rec: any, index: Key | null | undefined) => (
                    <div key={index} className={`custom-tile-row ${reportSettings?.uiSettings?.separator} custom-tile-${reportType}-card`} 
                        style={{ 
                            width: "inherit", 
                            minWidth: `calc(100% / ${chartRecords.length})`, 
                            maxWidth: `calc(100% / ${chartRecords.length})`,
                            height: "inherit",
                        }}>
                        <div className="card-box">
                            <div className="header"></div>
                            <div className="chart-area">
                                {reportType == 'donut' && <DoughnutWidget key={index} index={index} customTileReportModel={customTileReportModel} reportSettings={reportSettings} chartObj={rec} ></DoughnutWidget>}
                                {reportType == 'circle' && <CircleWidget key={index} index={index} reportSettings={reportSettings} chartObj={rec} hideArrow={chartRecords.length-1 === index}></CircleWidget>}
                                {reportType == 'card' && <CardWidget key={index} index={index} reportSettings={reportSettings} chartObj={rec}></CardWidget>}
                                {reportType == 'gauge' && <GaugeWidget key={index} index={index} customTileReportModel={customTileReportModel} reportSettings={reportSettings} chartObj={rec} ></GaugeWidget>}
                                {reportType == 'keyValue' && <KeyValueWidget key={index} index={index} reportSettings={reportSettings} chartObj={rec}></KeyValueWidget>}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            }
            {reportType == 'matrix' &&
                <div className="custom-tile-box">
                    <div className={`custom-tile-row ${reportSettings?.uiSettings?.separator} custom-tile-${reportType}-card  `} >
                        <MatrixWidget reportSettings={reportSettings} chartRecords={chartRecords}></MatrixWidget>
                    </div>
                </div>
            }
        </div>
    )
}