import makeStyles from '@mui/styles/makeStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { KagamiDateFieldStyle } from '../styles/KagamidateFieldStyle';
import { openCloseDatePicker, validateDateOnChange } from '../controller/kagamiDateController';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { FormControl, FormLabel, IconButton, TextField } from '@mui/material';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import moment from 'moment';
import { getFormModel } from '../../../form/controller/KagamiFormController';
import { useKagamiControlModelState } from '../../model/kagamiControlModel';
import { getDateStringToPopulateInForm } from '../../../../../../../../../../utils/DateUtils';
import { getMaxDate, getMinDate } from '../service/DateService';

function DateTimeField(props: any) {
  const classes = makeStyles(KagamiDateFieldStyle)();
  const uiSettingsClassName = props.kagamiDateModel.getControlWidth(props.kagamiDateModel.uiSettings);
  let fieldState = useKagamiControlModelState(props.kagamiDateModel.createState);
  props.kagamiDateModel.state = fieldState;
  props.kagamiDateModel.displayValue = getDateStringToPopulateInForm(fieldState.controlValue, fieldState.controlType);
  getFormModel().formControlStates[props.kagamiDateModel.controlIdentifier] = fieldState;
  const fontProperties = props.kagamiDateModel.getFontStyling(props.kagamiDateModel.uiSettings);
  let minDate: any = props.kagamiDateModel.uiSettings
    ? getMinDate(props.kagamiDateModel.uiSettings, props.kagamiDateModel)
    : null;
  let maxDate: any = props.kagamiDateModel.uiSettings
    ? getMaxDate(props.kagamiDateModel.uiSettings, props.kagamiDateModel)
    : null;
  if (fieldState.isVisible === false) {
    return <></>;
  } else {
    return (
      <div className={`${uiSettingsClassName} ${classes.root}`}>
        {!fieldState.isReadOnly ? (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl fullWidth>
              <FormLabel
                component="span"
                required={fieldState.isMandatory}
                style={{
                  color: fontProperties.labelFontColor,
                  fontWeight: fontProperties.labelFontBold,
                  fontStyle: fontProperties.labelFontItalic,
                  textDecoration: fontProperties.labelFontUnderline
                }}
              >
                {props.kagamiDateModel.controlDisplayName}
              </FormLabel>
              <DateTimePicker
                PopperProps={{
                  className: 'customPopover'
                }}
                inputFormat="dd/MM/yyyy hh:mm a"
                ampm
                open={fieldState.isOpen}
                onOpen={() => openCloseDatePicker(props.kagamiDateModel, true)}
                onClose={() => openCloseDatePicker(props.kagamiDateModel, false)}
                key={props.kagamiDateModel.controlIdentifier}
                value={fieldState.displayValue}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(newvalue: any) => {
                  console.log(newvalue);
                }}
                disabled={fieldState.isReadOnly}
                onAccept={(newvalue: any) => {
                  let changedValue = moment(newvalue).set({second : 0,millisecond : 0})
                  fieldState.setDisplayValue(changedValue.format('DD/MM/YYYY hh:mm a'));
                  validateDateOnChange(props.kagamiDateModel, newvalue);
                }}
                renderInput={(params: any) => (
                  <div style={{ position: 'relative' }}>
                    <TextField
                      fullWidth
                      size="small"
                      {...params}
                      color="primary"
                      sx={{
                        '& .MuiInputLabel-root ': {
                          color: fontProperties.labelFontColor,
                          fontWeight: fontProperties.labelFontBold,
                          fontStyle: fontProperties.labelFontItalic,
                          textDecoration: fontProperties.labelFontUnderline
                        },
                        '& .MuiInputLabel-root.Mui-focused ': {
                          color: fontProperties?.labelFontColor,
                          fontWeight: fontProperties?.labelFontBold,
                          fontStyle: fontProperties?.labelFontItalic,
                          textDecoration: fontProperties?.labelFontUnderline
                        },
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: fontProperties.controlBackgroundColor,
                          color: fontProperties.controlFontColor + ' ' + '!important',
                          fontWeight: fontProperties.controlFontBold,
                          fontStyle: fontProperties.controlFontItalic,
                          textDecoration: fontProperties.controlFontUnderline
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <EventOutlinedIcon
                            sx={{
                              color: (theme) => theme.palette.secondary.main
                            }}
                            onKeyPress={(event: any) => {
                              /// Note : event added to prevent user from entering date through keyboard
                              if(event.code !== "Tab") {
                                event.preventDefault();
                                }
                            }}
                            onKeyDown={(event: any) => {
                                   /// Note : event added to prevent user from entering date through keyboard
                              if(event.code !== "Tab") {
                              event.preventDefault();
                              }
                            }}
                            fontSize="small"
                            color="action"
                          />
                        ),
                        readOnly: true
                      }}
                      onKeyDown={(event: any) => {
                        /// Note : event added to prevent user from entering date through keyboard
                      if(event.code !== "Tab") {
                      event.preventDefault();
                      }
                    }}
                      required={fieldState.isMandatory}
                      error={fieldState.errorMessage ? true : false}
                      helperText={fieldState.errorMessage}
                      onClick={() => openCloseDatePicker(props.kagamiDateModel, true)}
                    />
                    {CommonUtils.isNotEmpty(fieldState.displayValue) ? (
                      <IconButton
                        size="small"
                        style={{ position: 'absolute', top: '2px', right: '10px' }}
                        onClick={() => {
                          validateDateOnChange(props.kagamiDateModel, null);
                        }}
                      >
                        <ClearOutlinedIcon
                          sx={{
                            color: (theme) => theme.palette.error.main
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              />
            </FormControl>
          </LocalizationProvider>
        ) : (
          <div className={classes.readOnlyField_cont}>
            <FormLabel
              component="span"
              className={classes.controlLabel}
              required={fieldState.isMandatory}
              style={{ color: fontProperties.labelFontColor }}
            >
              {' '}
              {props.kagamiDateModel.controlDisplayName}
            </FormLabel>
            {fieldState.controlValue ? (
              <p className={classes.readOnlyField} style={{ color: fontProperties.controlFontColor }}>
                {moment(fieldState.displayValue).format('DD/MM/YYYY hh:mm a')}
              </p>
            ) : (
              <p className={classes.readOnlyEmpty}>----</p>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default DateTimeField;
