import times from "lodash/times";
import forEach from 'lodash/forEach';
import keys from 'lodash/keys';
import { getReportData } from "../../../../service/ReportUtilService";

export const makeTile = (tileChartReportModel:any) => {
    let eachGrid:any={};
    let dbChartData = JSON.parse(tileChartReportModel.reportData);
    let tablerecords = getReportData(dbChartData, tileChartReportModel.reportConfig);
     times(tablerecords.length,(index:number)=>{
        return  forEach( keys(tablerecords[index]),(key:any)=>{
            if(eachGrid[key]===undefined){
               return eachGrid[key] = [tablerecords[index][key]]
            }else{
               return eachGrid[key].push(tablerecords[index][key])
            }
            
        })
    })
    return eachGrid;
}