import { State, useState } from "@hookstate/core";
import { ReportFilterModel } from "../../../model/KagamiReportFilterModel";
export class ReportDateFilterModel{
    filterConfig: any;
    filterName: string;
    filterRelationName: string;
    reportFilterModel: ReportFilterModel;

    isFromDateChangedManually:boolean;
    fromCalendar:boolean;
    toCalendar:boolean;
    state: any;
    translations: any;
    constructor(
      filterConfig: any,
      filterName: string,
      filterRelationName: string,
      kagamiReportFilterModel: ReportFilterModel
    ) {
      this.fromCalendar = false;
      this.toCalendar = false;
      this.isFromDateChangedManually = false;
      this.filterConfig = filterConfig;
      this.reportFilterModel = kagamiReportFilterModel;
      this.filterName = filterName;
      this.filterRelationName = filterRelationName;
    }

}

export interface ReportDateFilterModelProps{
    reportDateFilterModel:ReportDateFilterModel;
    translations: any;
}

export const useReportDateFilterModel:any = (state:State<ReportDateFilterModel>) => {
  const dateFilterState = useState(state);
  return {
    get fromCalendar(){
      return dateFilterState.fromCalendar.get();
    },

    setFromCalendar(fromCalendar:boolean){
      dateFilterState.fromCalendar.set(fromCalendar);
    },

    get toCalendar(){
      return dateFilterState.toCalendar.get();
    },

    setToCalendar(toCalendar:boolean){
      dateFilterState.toCalendar.set(toCalendar);
    },

  }
}