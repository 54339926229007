import _ from 'lodash';
import { Key } from 'react';
import { CUSTOM_TILE_HEADER, CUSTOM_TILE_VALUE1 } from '../graphic/customTile/service/CustomTileReportUtil';

export const MatrixWidget = (props: any) => {
    let chartRecords = props.chartRecords;
    let groupByRecords = _.groupBy(chartRecords, 'Type');
    let recKeys = _.keys(groupByRecords); ''

    return (

        <div className='chart-area'>
            {recKeys?.map((item: any, index: Key | null | undefined) => (
                <div key={index} className="matrix-widget-container" style={{height: `calc(100% / ${recKeys.length})`}}>
                    <div className="key-header" style={{width: `calc(100% / ${groupByRecords[item].length + 1})`}}> {item} : </div>
                    <div className='key-body' style={{width: `calc(100% - (100% / ${groupByRecords[item].length + 1}))`}}>
                        <div className="value-box">
                            {groupByRecords[item]?.map((rec: any, subIndex: Key | null | undefined) => (
                                <div className='value-item' style={{width: `calc(100% / ${groupByRecords[item].length})`}}>
                                    <div className='items'>
                                        <div className="value">{rec[CUSTOM_TILE_VALUE1] || ''}</div>
                                        <div className="header">{rec[CUSTOM_TILE_HEADER] || ''}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}


