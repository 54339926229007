import trimEnd from "lodash/trimEnd"
import get from "lodash/get"
import moment from "moment";
import { getVariableValue } from "../../../../service/PolicyExecutionService";
import { getFormModel } from "../../../form/controller/KagamiFormController";
import { KagamiFormModel } from "../../../form/model/KagamiFormModel";
import { KagamiDateModel } from "../model/kagamiDateModel";

export function getMaxDate(uiSettings:any, kagamiDateModel:any) {
  const kagamiFormModel: KagamiFormModel = getFormModel();

    let endDate;
    if (uiSettings) {
        if ( uiSettings.dateToType === 'literal') {
            if (typeof  uiSettings.dateToValue == 'number') {
                endDate = uiSettings.dateToValue;
            } else if ( uiSettings.dateToValue) {
                let word,date;
                if ( uiSettings.dateToValue.indexOf('-') !== -1) {
                    word = uiSettings.dateToValue.split('-');
                    date = moment().subtract(parseInt(word[1]), 'days').format('MM/DD/YYYY');
                } else {
                    word = uiSettings.dateToValue.split('+');
                    date = moment().add(parseInt(word[1]), 'days').format('MM/DD/YYYY');
                }
                endDate = moment(new Date(date)).endOf('day').valueOf();
            }
            // return endDate;
        } 
        
        else if ( uiSettings.dateToType === 'compute') {
    let entityName =  kagamiFormModel.onStartData?.constructOutputData.uiResource.presentations.presentationRuleMap[kagamiFormModel.mainEntityId][0]['entityName'];
             
            if (typeof uiSettings.dateToValue == 'number') {
                endDate =  uiSettings.dateToValue;
            } else if ( uiSettings.dateToValue) {
                var word,date;
                if( uiSettings.dateToValue.indexOf('addDay')!== -1){
                    word =  uiSettings.dateToValue.split(',');
                    word[1]= trimEnd(word[1],"]");
                    var tempStr=word[0].replace(/\s/g,"");
                    var splitWord=tempStr.split("["+ entityName+".");
                    var endVal= get(kagamiFormModel.formData.formData,splitWord[1])
                    if ( uiSettings.dateToValue.indexOf('-') !== -1) {
                        //subtract from add date
                        date = moment(endVal).subtract(parseInt(word[1].split('-')[1]), 'days').format('MM/DD/YYYY');
                    } else {
                        //add from end date
                        date = moment(endVal).add(parseInt(word[1]), 'days').format('MM/DD/YYYY');
                    }
                }else{
                    word =  uiSettings.dateToValue.split('-');
                    date = moment().subtract(parseInt(word[1]), 'days').format('MM/DD/YYYY');
                    
                }
                endDate = moment(new Date(date)).endOf('day').valueOf();
            }
            return endDate;
      
    }
    else if ( uiSettings.dateToType === 'variable' &&  uiSettings.dateToValue) {
        let date;
        let name = uiSettings.dateToValue.name;
        if (name && name.split('.').length > 1) {
          kagamiFormModel.mainEntityId === name.split('.')[0]
            ? (date = get(kagamiFormModel.formData.formData, name.split('.')[1]))
            : (date = '');
        }
        if(kagamiDateModel?.policyMap?.businessValidations && kagamiDateModel.uiSettings.dateFromValue){
            date = getVariableValue(kagamiDateModel.uiSettings.dateFromValue.name,kagamiFormModel.formData.formData)
          }
          endDate = moment(new Date(date)).endOf('day').valueOf()
    
}
    return endDate;
}
}
export function getMinDate(uiSettings:any,kagamiDateModel:KagamiDateModel) {
      
    const kagamiFormModel: KagamiFormModel = getFormModel();
    let entityName =  kagamiFormModel.onStartData?.constructOutputData.uiResource.presentations.presentationRuleMap[kagamiFormModel.mainEntityId][0]['entityName'];

    let startDate;
    if (uiSettings) {
        if ( uiSettings.dateFromType === 'literal') {
        
            if (typeof uiSettings.dateFromValue == 'number') {
                startDate =  uiSettings.dateFromValue;
            } else if ( uiSettings.dateFromValue) {
                let word;
                let date;
                if ( uiSettings.dateFromValue.indexOf('-') !== -1) {
                    word =  uiSettings.dateFromValue.split('-');
                    date = moment().subtract(parseInt(word[1]), 'days').format('MM/DD/YYYY');
                } else {
                    word =  uiSettings.dateFromValue.split('+');
                    date = moment().add(parseInt(word[1]), 'days').format('MM/DD/YYYY');
                }
                startDate = moment(new Date(date)).valueOf();
            }
            // return startDate;
        } 
        else if ( uiSettings.dateFromType === 'compute') {
        if (typeof uiSettings.dateFromValue == 'number') {
            startDate =  uiSettings.dateFromValue;
        } 
        else if ( uiSettings.dateFromValue) {
            var word,date;
            if( uiSettings.dateFromValue.indexOf('addDay')!== -1){
                word =  uiSettings.dateFromValue.split(',');
                word[1]= trimEnd(word[1],"]");
                var tempStr=word[0].replace(/\s/g,"");
                var splitWord=tempStr.split("["+entityName+".");
                var startVal= get(kagamiFormModel.formData.formData,splitWord[1])
                if ( uiSettings.dateFromValue.indexOf('-') !== -1) {
                    //subtract from start date
                    date = moment(startVal).subtract(parseInt(word[1].split('-')[1]), 'days').format('MM/DD/YYYY');
                } else {
                    //add from start date
                    date = moment(startVal).add(parseInt(word[1]), 'days').format('MM/DD/YYYY');
                }
            }else{
                word =  uiSettings.dateFromValue.split('-');
                date = moment().subtract(parseInt(word[1]), 'days').format('MM/DD/YYYY');
               
            }
            startDate = moment(new Date(date)).valueOf();
        }
        // return startDate;
        }  
        else if ( uiSettings.dateFromType === 'variable'  &&  uiSettings.dateFromValue) {
            let date;
            let name = uiSettings.dateFromValue.name
            if(name && name.split('.').length > 1) {
                kagamiFormModel.mainEntityId === name.split('.')[0] ? date = get(kagamiFormModel.formData.formData,name.split('.')[1]) :date = ''
            }
            if(kagamiDateModel?.policyMap?.businessValidations && kagamiDateModel.uiSettings.dateFromValue){
                date = getVariableValue(kagamiDateModel.uiSettings.dateFromValue.name,kagamiFormModel.formData.formData)
            }
            startDate = moment(new Date(date)).endOf('day').valueOf()
            
        }

        return startDate;
    }

}

export function getMinTime(uiSettings:any,kagamiDateModel:KagamiDateModel) {
    const kagamiFormModel: KagamiFormModel = getFormModel();
    let startTime;
    if (uiSettings) {
         if ( uiSettings.dateFromType === 'variable'  &&  uiSettings.dateFromValue) {
            let name = uiSettings.dateFromValue.name
            if(name && name.split('.').length > 1) {
              startTime =  kagamiFormModel.mainEntityId === name.split('.')[0] ?  get(kagamiFormModel.formData.formData,name.split('.')[1]) :undefined;
            }
            if(kagamiDateModel?.policyMap?.businessValidations && kagamiDateModel.uiSettings.dateFromValue){
                startTime = getVariableValue(kagamiDateModel.uiSettings.dateFromValue.name,kagamiFormModel.formData.formData)
            }  
        }
        startTime = moment(new Date(startTime)).valueOf()
    }
    return startTime;
}