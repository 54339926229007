import { Autocomplete, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { times } from "lodash";

export const makeStaticReportFields = (item: any, index: any, config: any) => {
    let translation: any = config?.staticReportFiltersModel?.languageTranslation || {};
    switch (item.type) {
        case "SELECT":
        case "MULTISELECT":
            return (
                <Grid item xs={4}>
                    <Autocomplete
                        size="small"
                        id={item.field + "" + index}
                        multiple={item.type == 'MULTISELECT'}
                        options={config?.staticFilterData?.[item.dataField] || []}
                        getOptionLabel={(option: any) => option}
                        filterSelectedOptions
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                required = {item.required || false}
                                label={translation[item.header] || item.header}
                                placeholder={"Select " + item.header}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        )}
                        value={config?.reportsFields[item.field]}
                        onChange={(event: any, newValue: any | null) => {
                            config?.handleFilterChange(item, newValue);
                        }}
                    />
                </Grid>
            )
        case "DATE":
            return (<Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        views={['year', 'month', 'day']}
                        label={translation[item.header] || item.header}
                        value={config?.reportsFields[item.field]}
                        inputFormat={config?.dateformat}
                        onChange={(newValue: any) => {
                            config?.handleFilterChange(item, newValue);
                        }}
                        renderInput={(params: any) => {
                            return (
                                <TextField
                                    fullWidth
                                    required
                                    className='full-width'
                                    {...params}
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            );
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            )

        case "NUMBER":
            return (
                <>
                    <Grid item xs={4}>
                        <TextField
                            size="small"
                            label={translation[item.header] || item.header}
                            placeholder={item.header}
                            type="number"
                            fullWidth 
                            value={config?.reportsFields[item.field]}
                            onChange={(event: any) => {
                                config?.handleFilterChange(item, event.target.value);
                            }}
                        />
                    </Grid>

                    {
                        item.field == 'agingPeriod' && (
                            <Grid container style={{ padding: '10px 25px' }} spacing={{ xs: 1, md: 2 }}>
                                {times(config?.reportsFields[item.field], (index: any) => (
                                    <>
                                        {item.options?.map((option: any, subIndex: any) => (
                                            <Grid item xs={6}>
                                                <TextField
                                                    size="small"
                                                    label={translation[option.label] || option.label}
                                                    type="text"
                                                    variant="standard"
                                                    value={config?.reportsFields?.agingRange?.[option.value +''+ index]}
                                                    onChange={(event: any) => {
                                                        config?.handleAgingPeriodRangeChange(event.target.value, option.value, index);
                                                    }}
                                                />
                                            </Grid>
                                        ))}
                                    </>
                                ))}
                            </Grid>
                        )
                    }
                </>

            )
            break;

        case "RADIO":
            return (
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup row name="row-radio-buttons-group"
                            onChange={(event: any, newValue: any | null) => {
                                config?.handleDependedChange(newValue);
                            }}>
                            {item?.options?.map((item: any) => <FormControlLabel value={item.value} control={<Radio />} label={translation[item.label] || item.label} />)}
                        </RadioGroup>
                    </FormControl>
                </Grid>
            )
        default:
            return <></>
    }
};