import { getDynamicModalWidth } from '../../../../../../../../../../components/modal/controller/KagamiModalController';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { getHomeModel, showBody } from '../../../../../../../controller/KagamiHomeController';
import { getTabNavigationModel } from '../../../../../../header/Tabs/controller/KagamiTabNavigationController';
import { eopTrueAndNoDetailedObjectsForTabs } from '../../../../../../header/Tabs/ui/KagamiTabNavigation';
import { loadProcessContent, onSubmitCall, updateActiveModel } from '../../../controller/KagamiProcessController';
import { KagamiProcessRequestModel } from '../../../model/KagamiProcessRequestModel';
import { KagamiProcessResponseModel } from '../../../model/KagamiProcessResponseModel';
import {
  getMainEntityIdForPresentation,
  getPresenationHeaderFromPresentation
} from '../../../service/PresentationService';
import { getPageOnloadContent } from '../../../service/ProcessPageService';
import { buildPresentation } from '../../form/builder/FormBuilder';
import { BuildValidationError } from '../../form/builder/FormErrorBuilder';
import { getFormModel, setFormModel, showServerError } from '../../form/controller/KagamiFormController';
import { KagamiFormModel } from '../../form/model/KagamiFormModel';
import {
  _getChangeForDetail,
  _getUnSyncedFormRecord,
  updateFormHierarchyAndOnloadData
} from '../../form/service/FormService';
import { validate } from '../../form/service/FormValidationService';
import { getKagamiListModel, setKagamiListModel } from '../../list/controller/KagamiListController';
import { KagamiListModel } from '../../list/model/KagamiListModel';
import { processPageResponse } from '../../ProcessPageBuildler';

let processRequestModel: KagamiProcessRequestModel;
let responseModel: any;
export function buildModalDialogContent(
  processResponseModel: KagamiProcessResponseModel,
  activeContextModel: any,
  fromDashboard?: boolean,
  isModalView?: boolean
) {
  var mainEntityId: string = getMainEntityIdForPresentation(processResponseModel.onStartData);
  getPageOnloadContent(processResponseModel);

  let homeModel = getHomeModel();

  function handleModalDialog() {
    if (activeContextModel instanceof KagamiFormModel) {
      setFormModel(activeContextModel);
      updateActiveModel(activeContextModel);
    } else if (activeContextModel instanceof KagamiListModel) {
      setKagamiListModel(activeContextModel.childListModel);
      updateActiveModel(activeContextModel);
    }
    homeModel.modalDialogModel.state.setOpen(false);
  }

  function onSubmitSuccess(content: any) {
    homeModel.modalDialogModel.state.setOpen(false);
    loadProcessContent(content);
  }

  processRequestModel = new KagamiProcessRequestModel(
    processResponseModel.processName,
    onSubmitSuccess,
    showServerError
  );

  processRequestModel.fromDashboard = false;
  processRequestModel.data = null;
  processRequestModel.submitCall = true;
  processRequestModel.isToastMessageHide = false;
  processRequestModel.isModalResponse = true;
  processRequestModel.processStepName = processResponseModel.processStepName;

  const kagamiFormModel: KagamiFormModel = new KagamiFormModel(
    processResponseModel.onStartData,
    mainEntityId,
    processResponseModel.processName,
    processResponseModel.processStepName,
    processResponseModel.templateType
  );

  updateActiveModel(kagamiFormModel);

  let kagamiHomeModel = getHomeModel();
  function onCloseCall() {
    if (fromDashboard) {
      // showBody();
      let tabNavigationState = KagamiStateManager.getStateToHolder(SystemConstants.TABNAVIGATION_STATE);
      let tabNavigationModel = getTabNavigationModel();
      eopTrueAndNoDetailedObjectsForTabs(processResponseModel.processName, tabNavigationState, tabNavigationModel);
    } else {
      updateActiveModel(activeContextModel);
      if (activeContextModel instanceof KagamiFormModel) {
        setFormModel(activeContextModel);
      } else if (activeContextModel instanceof KagamiListModel) {
        let activeListModel: KagamiListModel = getKagamiListModel();
        if (activeListModel === undefined || activeListModel.mainEntityId !== activeContextModel.mainEntityId) {
          setKagamiListModel(activeContextModel);
        }
      }
    }
  }

  function onClickCall() {
    const formModel = getFormModel();
    let mainObject: any = formModel.formData.formData;
    let validations = validate(formModel, formModel.mainEntityId, false);
    if (validations.size === 0) {
      updateActiveModel(activeContextModel);
      if (activeContextModel instanceof KagamiFormModel) {
        setFormModel(activeContextModel);
      } else if (activeContextModel instanceof KagamiListModel) {
        setKagamiListModel(activeContextModel);
      }

      if (mainObject && mainObject != null) {
        let mainObjectMap: any = new Map();
        for (const id in mainObject) {
          mainObjectMap[id] = mainObject[id];
        }
        formModel.onLoadData = mainObject;
        formModel.inFocusFormDataIdentifiers[formModel.mainEntityId] = mainObject['id'];
      }
      if (CommonUtils.isNotEmpty(formModel.formData.changedData)) {
        formModel.formData.changedData[formModel.mainEntityId][0]['id'] = mainObject['id'];
      }
      _getUnSyncedFormRecord(formModel, formModel.mainEntityId);
      processRequestModel.data = formModel.formData.changedData;
      processRequestModel.changeFor = _getChangeForDetail(formModel, formModel.mainEntityId, null, null);

      return onSubmitCall(processRequestModel);
    } else {
      let kagamiHomeModel = getHomeModel();
      kagamiHomeModel.errorModal.content = BuildValidationError(validations);
      kagamiHomeModel.errorModal.title = 'Error';
      kagamiHomeModel.errorModal.modalWidth = 'sm';

      let alertDialogState = kagamiHomeModel.errorModal.state;
      if (alertDialogState) {
        alertDialogState.setOpen(true);
      }
    }
  }

  let modalContent = isModalView ? buildModalListContent(processResponseModel) : buildContent(kagamiFormModel);
  (kagamiHomeModel.modalDialogModel.title = isModalView
    ? ''
    : getPresenationHeaderFromPresentation(kagamiFormModel.onStartData.constructOutputData.uiResource.presentations)),
    (kagamiHomeModel.modalDialogModel.content = modalContent);
  kagamiHomeModel.modalDialogModel.numberofListRecords = getNumberofListRecords(processResponseModel);
  kagamiHomeModel.modalDialogModel.modalWidth = isModalView
    ? 'lg'
    : getDynamicModalWidth(modalContent[0]['props']['kagamiFormPresentationModel'], modalContent);
  kagamiHomeModel.modalDialogModel.handleOnOk = isModalView ? handleModalDialog : onClickCall;
  kagamiHomeModel.modalDialogModel.handleOnClose = isModalView ? handleModalDialog : onCloseCall;
  kagamiHomeModel.modalDialogModel.state.setOpen(true);
}

function buildContent(kagamiFormModel: KagamiFormModel) {
  setFormModel(kagamiFormModel);
  updateFormHierarchyAndOnloadData(kagamiFormModel);
  let formComponents = buildPresentation(kagamiFormModel);
  return formComponents.mainTransaction;
}

function buildModalListContent(processResponseModel: KagamiProcessResponseModel) {
  let content: JSX.Element | null = processPageResponse(processResponseModel);
  return content;
}

function getNumberofListRecords(processResponseModel: KagamiProcessResponseModel) {
  let mainEntityId: string = getMainEntityIdForPresentation(processResponseModel.onStartData);

  let records = processResponseModel.detailedObjects[mainEntityId];
  return CommonUtils.isEmpty(records) ? 0 : records.length;
}

export function setModalDialog(processResponseModel: any) {
  responseModel = processResponseModel;
}

export function getModalDialog() {
  return responseModel;
}
