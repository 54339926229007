import { CommonUtils } from '../../../../../../../../utils/CommonUtils';
import { buildReportFilter } from '../../filter/controller/KagamiReportFilterController';
import { ReportFilterModel } from '../../filter/model/KagamiReportFilterModel';
import { handleStaticReportFilters } from '../../staticReportFilters/service/StaticReportFiltersService';
import { executeReportWithOrWithoutFilter } from '../../view/report/builder/KagamiReportBuilder';
import { executeReportOnClick, getReportModel } from '../../view/report/controller/KagamiReportController';
import { ParentId, ReportLandingPageModel } from '../model/ReportsLandingPageModel';
import { buildReportLandingPage } from '../ui/ReportsLandingPage';

let landingPageModel: ReportLandingPageModel;

export const buildReportLandingPageController = (reportLandingPageModel: ReportLandingPageModel) => {
  landingPageModel = reportLandingPageModel;
  return buildReportLandingPage(reportLandingPageModel);
};

export const getLandingPageModel = () => {
  return landingPageModel;
};

export function setLandingPageModel(reportLandingPageModel: ReportLandingPageModel) {
  landingPageModel = reportLandingPageModel;
}

export const handleReportTileClick = (reportConfigSummary: any, translations?: any) => {
  landingPageModel.reportConfigSummary = reportConfigSummary;
  executeReportOnClick(reportConfigSummary.id, translations);
};

export const handleReportLinkCLick = (reportConfig: any, reportFilterModel: ReportFilterModel) => {
  landingPageModel.reportConfigSummary = reportConfig.reportConfigSummary;
  // landingPageModel.fromLink = true;
  let reportModel = getReportModel();
  reportFilterModel.filters = reportConfig.dynamicParameterList;
  if (reportFilterModel?.displayFilterValue) {
    reportFilterModel.displayFilterValue = [];
  }
  landingPageModel.modalModel.title = 'Filters';
  landingPageModel.modalModel.content = buildReportFilter(reportFilterModel);
  landingPageModel.modalModel.isReportModel = true;
  if (reportFilterModel.filters.some((filterConfig: any) => !filterConfig.isuser))
    landingPageModel.modalModel.state.setOpen(true);
  landingPageModel.modalModel.handleOnOk = async () => {
    reportModel.reportFilterModel = reportFilterModel;
    landingPageModel.content = await executeReportWithOrWithoutFilter(reportModel);
    landingPageModel.isContentLoaded = true;
    landingPageModel.state.setDataLoaded(true);
  };
};

export const handleStaticReportLinkClick = (reportConfig: any, reportFilterModel: ReportFilterModel) => {
  let reportModel = getReportModel();
  handleStaticReportFilters(landingPageModel,  reportModel)
};

export const handleFolderTileClick = (folderConfig: any) => {
  landingPageModel.folderConfig = folderConfig;
  landingPageModel.folderId = folderConfig.id;
  if (folderConfig.id === undefined) {
    landingPageModel.newReportList = landingPageModel.reportTiles[ParentId.id];
    landingPageModel.newFolderList = landingPageModel.folderTiles[ParentId.id];
  } else {
    landingPageModel.newReportList = landingPageModel.reportTiles[folderConfig.id];
    landingPageModel.newFolderList = landingPageModel.folderTiles[folderConfig.id];
  }
  landingPageModel.state.setDataLoaded(true);
};

export const handleOnBackForReport = () => {
  if (landingPageModel.isContentLoaded) {
    landingPageModel.isContentLoaded = false;
  } else {
    landingPageModel['folderConfig'] && handleOnBack(landingPageModel.folderConfig);
  }
  landingPageModel.state.setDataLoaded(true);
};

export const handleOnBack = (config: any) => {
  if (config.type === 'reportFolder') {
    landingPageModel.folderId = config['parentId'];
    landingPageModel.setOfFolderConfig.map((folderConfig: any) => {
      if (folderConfig.id === landingPageModel.folderId) {
        landingPageModel.folderConfig = folderConfig;
      }
    });
  } else {
    landingPageModel.folderId = config['folderId'];
    landingPageModel.setOfFolderConfig.map((folderConfig: any) => {
      if (folderConfig.id === landingPageModel.folderId) {
        landingPageModel.folderConfig = folderConfig;
      }
    });
    if (landingPageModel.isContentLoaded) {
      landingPageModel.isContentLoaded = false;
    } else {
      landingPageModel.isContentLoaded = true;
    }
  }
  if (landingPageModel.folderId === undefined) {
    landingPageModel.newFolderList = landingPageModel.folderTiles[ParentId.id];
    landingPageModel.newReportList = landingPageModel.reportTiles[ParentId.id];
  } else {
    landingPageModel.newFolderList = landingPageModel.folderTiles[landingPageModel.folderId];
    landingPageModel.newReportList = landingPageModel.reportTiles[landingPageModel.folderId];
  }
  // landingPageModel.state.setDataLoaded(true);
};

// old code
//-----------------------------------------------------------------------------------
// export const mappingFolderWithRelatedFolder = (strucutureList: any) => {
//   Array.isArray(strucutureList) && strucutureList.map((folderConfig: any) => {
//     if(CommonUtils.isNotEmpty(folderConfig.strucutureList)){
//       landingPageModel.folderTiles[folderConfig.id] = folderConfig.strucutureList;
//       mappingFolderWithRelatedFolder(folderConfig.strucutureList);
//     } else {
//       landingPageModel.folderTiles[folderConfig.id] = folderConfig.strucutureList;
//     }
//   });
// };

// new code
//-----------------------------------------------------------------------------------
export const mappingFolderWithParentFolder = (strucutureList: any, callbackFolderTiles: any, translations?: any) => {
  Array.isArray(strucutureList) &&
    strucutureList.map((folderConfig: any) => {
      if (CommonUtils.isNotEmpty(folderConfig.strucutureList)) {
        callbackFolderTiles[folderConfig.id] = folderConfig.strucutureList;
        mappingFolderWithParentFolder(folderConfig.strucutureList, callbackFolderTiles, translations);
      } else {
        callbackFolderTiles[folderConfig.id] = folderConfig.strucutureList;
      }
    });
};

// old code
//-----------------------------------------------------------------------------------
// export const mappingReportsWithRelatedFolder = () => {
//   for (const folderId of Object.keys(landingPageModel.folderTiles)) {
//     let eachFolderReports: any[] = [];
//     landingPageModel.reportList.map((reportConfig: any) => {
//       if (reportConfig['folderId'] === undefined && folderId === ParentId.id) {
//         eachFolderReports.push(reportConfig);
//       }
//       if (reportConfig['folderId'] === folderId) {
//         eachFolderReports.push(reportConfig);
//       }
//     });
//     landingPageModel.reportTiles[folderId] = eachFolderReports;
//   }
// };

// new code
//-----------------------------------------------------------------------------------
export const mappingReportsWithParentFolder = (
  reportList: any,
  folderTiles: any,
  callbackReportTiles: any,
  translations?: any
) => {
  for (const folderId of Object.keys(folderTiles)) {
    let eachFolderReports: any[] = [];
    reportList.forEach((reportConfig: any) => {
      if (folderId == reportConfig['folderId']) {
        eachFolderReports.push(reportConfig);
      } else if (folderId === ParentId.id) {
        if (CommonUtils.isEmpty(reportConfig['folderId'])) {
          eachFolderReports.push(reportConfig);
        }
      }
    });
    callbackReportTiles[folderId] = eachFolderReports;
  }
};

// old code
//-----------------------------------------------------------------------------------
export const flatteningFoldersValueToArray = () => {
  let flattenedArray: any[] = [];
  for (const setOfValues of Object.values(landingPageModel.folderTiles)) {
    if (Array.isArray(setOfValues)) {
      flattenedArray = flattenedArray.concat(setOfValues);
    }
  }
  landingPageModel.setOfFolderConfig = flattenedArray;
};

// new code
//-----------------------------------------------------------------------------------
// export const flatteningFolders = (folderTiles:any, callbackSetOfFolderConfig:any) => {
//   let flattenedArray: any[] = [];
//   for (const setOfValues of Object.values(folderTiles)) {
//     if (Array.isArray(setOfValues)) {
//       flattenedArray = flattenedArray.concat(setOfValues);
//     }
//   }
//   callbackSetOfFolderConfig = flattenedArray;
// };

// Search any report from any Page or Folders in Reports
export const globalSearchData = (event: any, reportLandingPageModel: any) => {
  let folderTiles: any[] = [];
  let reportTiles: any[] = [];

  folderTiles = reportLandingPageModel.setOfFolderConfig;
  reportTiles = reportLandingPageModel.reportList ?? [];
  reportLandingPageModel.newFolderList = [];
  reportLandingPageModel.newReportList = [];

  if (event.target.value === '') {
    if (reportLandingPageModel.folderId === undefined) {
      folderTiles = reportLandingPageModel.folderTiles[ParentId.id];
      reportTiles = reportLandingPageModel.reportTiles[ParentId.id];
    } else {
      folderTiles = reportLandingPageModel.folderTiles[reportLandingPageModel.folderId];
      reportTiles = reportLandingPageModel.reportTiles[reportLandingPageModel.folderId];
    }
  }

  folderTiles.map((folderConfig: any) => {
    if (folderConfig.name.toUpperCase().search(event.target.value.toUpperCase()) > -1) {
      reportLandingPageModel.newFolderList.push(folderConfig);
    }
  });

  reportTiles.map((reportConfig: any) => {
    if (reportConfig.name.toUpperCase().search(event.target.value.toUpperCase()) > -1) {
      reportLandingPageModel.newReportList.push(reportConfig);
    }
  });
  if (reportLandingPageModel.isContentLoaded) {
    reportLandingPageModel.isContentLoaded = false;
  }
  reportLandingPageModel.state.setDataLoaded(true);
};

// This function is not in use right now but may be later we use
// Search reports and folder in current page or folder only
export const getSearchData = (event: any, reportLandingPageModel: any) => {
  let folderTiles: any[] = [];
  let reportTiles: any[] = [];
  if (reportLandingPageModel.folderId === undefined) {
    folderTiles = reportLandingPageModel.folderTiles[ParentId.id];
    reportTiles = reportLandingPageModel.reportTiles[ParentId.id];
  } else {
    folderTiles = reportLandingPageModel.folderTiles[reportLandingPageModel.folderId];
    reportTiles = reportLandingPageModel.reportTiles[reportLandingPageModel.folderId];
  }

  reportLandingPageModel.newFolderList = [];
  reportLandingPageModel.newReportList = [];
  folderTiles.map((folderConfig: any) => {
    if (folderConfig.name.toUpperCase().search(event.target.value.toUpperCase()) > -1) {
      reportLandingPageModel.newFolderList.push(folderConfig);
    }
  });
  reportTiles.map((reportConfig: any) => {
    if (reportConfig.name.toUpperCase().search(event.target.value.toUpperCase()) > -1) {
      reportLandingPageModel.newReportList.push(reportConfig);
    }
  });
  reportLandingPageModel.state.setDataLoaded(true);
};

export const reloadReportOnLanguageChange= async (val :any) => {
  let reportModel = getReportModel();
  reportModel.translationLanguage = val;
  landingPageModel.content = await executeReportWithOrWithoutFilter(reportModel,false,true);
  landingPageModel.isContentLoaded = true;
  landingPageModel.state.setDataLoaded(true);
}
