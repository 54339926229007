
import { useState, State } from "@hookstate/core";
import { KagamiStateManager } from "../../../../../../../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../../../../../../../constants/SystemConstants";
import { KagamiListModel } from "../../../../../model/KagamiListModel";
import { KagamiListFilterModel } from "../../../model/KagamiListFilterModel";


export class KagamiPresentationFilterModel {
  dataRecordsForPresentationAttribute:any;
  filterDataForSearchableAttributes:any;
  attributeName :string;
  checkedList: any[];
  searchProcessName:string;
  state: any
  constructor(dataRecordsForPresentationAttribute:any , filterDataForSearchableAttributes:any ,attributeName:string) {
     this.dataRecordsForPresentationAttribute = dataRecordsForPresentationAttribute;
     this.filterDataForSearchableAttributes = filterDataForSearchableAttributes;
     this.attributeName = attributeName;
     this.checkedList=[];
     this.searchProcessName = ''
    }
}
export interface KagamiPresentationModelProps {
  kagamiPresentationFilterModel: KagamiPresentationFilterModel;
  kagamiListFilterModel: KagamiListFilterModel
}

export const wrapPresentationFiltersState = (state: State<KagamiPresentationFilterModel>) => {
  let filterState = useState(state)  
  return {
      get checkedList() {
        return filterState.checkedList.get();
      },

      SetCheckedList(value: string[]) {
        filterState.checkedList.set(value)
      },

      get searchProcessName() {
        return filterState.searchProcessName.get();
      },

      SetSearchProcessName(value: string) {
        filterState.searchProcessName.set(value)
      }     
  };
};


export const usePresentationFiltersState =  (state: State<KagamiPresentationFilterModel>) => {
  const clearListState = wrapPresentationFiltersState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.CLEAR_LIST_FILTER_STATE_UPDATER, clearListState)
  return clearListState
}

export interface GetListFilterListProps{
  kagamiListModel:KagamiListModel;
  kagamiPresentationFilterModel:KagamiPresentationFilterModel;
  kagamiListFilterModel:KagamiListFilterModel;
  setFilterData:any;
}
