import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { extractDropdownOption} from '../../../../../../service/PresentationService';
import { fetchEmbedRecordsForAgGrid } from '../../../../service/kagamiListService';
import { cellRendererCustomComponentsStyle } from '../../style/CellRendererCustomComponents';
import { useEffect, useRef } from 'react';
import React from 'react';
const useStyles = makeStyles(cellRendererCustomComponentsStyle);

 const listEmbedFieldRenderer = (props: any) => {
  const handleClick = () => {
    let popupModel = props.popupModel;
    popupModel.modalWidth = 'lg';
    fetchEmbedRecordsForAgGrid(
      props.attributePresentation,
      props.data.id,
      props.popupModel,
      props.mainEntityId,
      props.kagamiGridModel,
      props.data
    );
  };
  // let embedValues = getDataForDisplayAttribute(props.attributePresentation, props.detailObjects, props.data.id);
  let embedValues: any[] = getEmbedFields(props.value, props.attributePresentation);
  if (embedValues.length > 2) {
    let moreNum  = embedValues.length - 2;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <MultiEmbedChips embedValues={embedValues} handleClick={handleClick} popupModel={props.popupModel} />
        <Chip style={{ background: '#E1E6FA' }} label={'+'+moreNum.toString()} onClick={handleClick} />

        {/* <Chip
          style={{
            background: '#E1E6FA',
            // display: 'flex',
            // justifyContent: 'space-between',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'block',
            maxWidth: 75
          }}
          label={embedValues.slice(0, 7)}
          onClick={handleClick}
        /> */}
      </div>
    );
  } else {
    if(embedValues.length > 1 && embedValues[1].indexOf(embedValues[0]) >= 0) embedValues = embedValues.splice(1); // This is spliced because of duplicate values Displaying. (Pending Requistion list view indent items are showing 2times)
    return (
      <div style={{ display: 'flex' }}>
        <DisplayTwoEmbedChips embedValues={embedValues} handleClick={handleClick} popupModel={props.popupModel} />
      </div>
    );
  }
};

const MultiEmbedChips = (props: any) => {
  const { embedValues, handleClick } = props;
  return embedValues.slice(0, 2).map((embedValue: any) => {
    return (
      <>
        <EmbedChips embedValues={embedValue} handleClick={handleClick} popupModel={props.popupModel} />
      </>
    );
  });
};

const DisplayTwoEmbedChips = (props: any) => {
  const { embedValues, handleClick } = props;
  return embedValues.map((embedValue: any) => {
    return (
      <>
        <EmbedChips embedValues={embedValue.includes('--') ? embedValue.replace('--' , "") : embedValue} handleClick={handleClick} popupModel={props.popupModel} />
      </>
    );
  });
};

function getEmbedFields(controlValue: any, attributePresentation: any) {
  if (controlValue instanceof Array) {
    let multiSelectValues: any[] = [];
    controlValue.forEach((record) => {
      multiSelectValues.push(extractDropdownOption(attributePresentation, record, true));
    });
    return multiSelectValues;
  } else if (controlValue && controlValue !== null && controlValue.length != 0) {
    let value = extractDropdownOption(attributePresentation,controlValue)
    return [value];
  }
  return [];
}

const EmbedChips = (props: any) => {
  const { embedValues, handleClick } = props;
  const classes = useStyles();
  let chips: any[] = [];
  if (embedValues.length > 0) {
    let truncatedString: string;
    let splitEmbddedValue: any;
    if(embedValues.includes('dev')){
      if(embedValues.includes("-")){
        splitEmbddedValue = embedValues.split('-');
        truncatedString = splitEmbddedValue[0]
      }else{
        truncatedString = embedValues;
      }
    }else{
      if (embedValues.includes('-')) {
        splitEmbddedValue = embedValues;
        truncatedString = splitEmbddedValue
      } else if (embedValues.includes('--')) {
        splitEmbddedValue = embedValues.split('--');
        if (splitEmbddedValue > 1) {
          truncatedString = embedValues[0] + '--' + embedValues[1];
        } else {
          truncatedString = embedValues[0];
        }
      } else {
        truncatedString = embedValues;
      }
    }
   
    // chips.push(<Chip style={{ background: '#E1E6FA' }} label={truncatedString.includes('--') ? truncatedString.slice(0,truncatedString.length-2) : truncatedString} onClick={props.handleClick} />)
    chips.push(
      <Chip
        // style={{ background: '#E1E6FA' }}
        style={{
          background: '#E1E6FA',
          display: 'flex',
          // justifyContent: 'space-between',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          // display: 'block',
          maxWidth: 180
        }}
        label={ truncatedString}
        onClick={props.handleClick}
      />
    );
  } else {
    <span>null</span>;
  }
  // chips.push(<Chip style={{ background: '#E1E6FA' }} label={embedValues} onClick={props.handleClick} />)
  return <div className={classes.chipContainer}>{chips}</div>;
};

export default listEmbedFieldRenderer ;
