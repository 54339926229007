import {getStaticFilterData,getPLReportData,getStaticFilterInfo} from '../../../../service/KagamiReportService';
import {getReportType,handleStaticReportDates,getSelectedCurrency,getLocaleValue, getValueInDateTimeFormatAsAppSetting, getAppSettingDateTimeFormat} from '../../../../service/ReportUtilService';
import _ from 'lodash';
import ExcelTreeDataDownload  from '../../../../service/exports/ExcelTreeDataService';
import dayjs from 'dayjs';

export async function getDBStaticFilterData(reportController: any) {
    let reportType =  getReportType(reportController.reportConfig);
    return await getStaticFilterData(reportType);
}
export const makeReport = (reportController: any) => {
        let translation = reportController.languageTranslation || {}; 
        let defaultConfig ={
          autoGroupColumnDef: {headerName: translation['Account Name'] || 'Account Name',  width: 200,cellRendererParams: {suppressCount: true,checkbox: false}},    
          columnDefs: [],
          defaultColDef: { flex :1,sortable: false,minWidth: 125,filter: true,resizable: true,editable: false },
          groupDefaultExpanded: 0,//expand everything by default
          groupHideOpenParents:true,
          groupIncludeTotalFooter: false,
          getDataPath: (data:any) => {
            return data.subMenus;
          },
          getRowHeight :(params: any) => 30,
          icons: {
            groupContracted:'<div class="kgm-plus-icon no-border"></div>',
            groupExpanded:'<div class="kgm-minus-icon no-border"></div>'
          },
          pivotMode : false,
          pagination: true,
          paginationPageSize: 50,
          rowData:[],
          suppressAggFuncInHeader: true, //hide aggregate name in a row 
          treeData: true,
        };
        let userConfig = prapareUserConfigForReport(reportController,defaultConfig);  
        let reportConfig = _.extend(true, {}, defaultConfig, userConfig);

        return reportConfig;

}
function prapareUserConfigForReport (reportController: any,defaultConfig:any) {
    let translation = reportController.languageTranslation || {}; 
    let userConfig:any = {
      columnDefs: [],
      rowData: [],
    };
    let columnDefs: any = [];
    columnDefs =[
       {headerName: translation['Account Name'] || 'Account Name', field: 'name',hide:true},
       {headerName: translation['Value'] || 'Value', field: 'column',type: 'rightAligned'},
       {headerName: translation['Total'] || 'Total', field: 'result',type: 'rightAligned'},
      ]
    userConfig['columnDefs'] = columnDefs;
    return userConfig;
  }
 export function makeReportData(reportModel:any,dbReportData: any) {
    let dbData = []; 
    if(dbReportData.menuItems) {
        dbData = dbReportData.menuItems;
     }
     if(dbData && dbData.length > 3) {
      dbData[1].name = dbData[0].name +" "+ dbData[1].name;
      dbData[3].name = dbData[2].name +" "+ dbData[3].name;
     }
     let currency = getSelectedCurrency(reportModel.reportConfig);
    return getReportData(_.cloneDeep(dbData),currency) ||[];
 }
  export const getData = async (reportModel:any,inputReportConfig:any) => {
    if((!inputReportConfig.state || inputReportConfig.state.length == 0 ) && (reportModel?.reportData?.staticFilterData?.data?.state)){
      inputReportConfig['state'] = "NOPOP";
    }
    if((!inputReportConfig.projectPlan || inputReportConfig.projectPlan.length == 0) && (reportModel?.reportData?.staticFilterData?.data?.projectPlan)){
      inputReportConfig['projectPlan'] = "NOPOP";
    }
    if(!inputReportConfig.selectedValue ){
      inputReportConfig['selectedValue'] = inputReportConfig.subOrganization?.[0];
    }
    
    handleStaticReportDates(inputReportConfig);
    let res =  await getPLReportData(inputReportConfig);
    if(res?.data ){
        return res.data['leftPanel'];
    }
    return [];
  }

  const getReportData = (inputReportData:any,currency:any) => {
    let reportData = makePLReportData(inputReportData,currency);
    let data:any = [];
    if(reportData && reportData.length ){
      _.forEach(reportData,(rec:any)=> {
        let temp = {
              name: rec.name ||'',
              column: getLocaleValue(rec.column,currency) || '',
              result: getLocaleValue(rec.result,currency) ||'',
              subMenus:[rec.name]
          }
          data.push(temp);
          if(rec?.subMenus && rec.subMenus.length>0 ){
              rec.subMenus.forEach((subRec:any) => {
                recursivelyGetSubMenusData(data,subRec,[rec.name],currency)
              });
          }
      })
    }
    
    return data;
  }

  const recursivelyGetSubMenusData = (data:any,subMenuRecs:any,path:any,currency:any) => {
    let temp = {
      name: subMenuRecs.name ||'',
      column: getLocaleValue(subMenuRecs.column,currency) || '',
      result: getLocaleValue(subMenuRecs.result,currency) ||'',
      subMenus:path
    }
    if(temp.subMenus && temp.subMenus.length) {
      temp.subMenus.push(subMenuRecs.name);
    }
    data.push(temp);
    if(subMenuRecs?.subMenus && subMenuRecs.subMenus.length>0 ){
      subMenuRecs.subMenus.forEach((subRec:any) => {
        recursivelyGetSubMenusData(data,subRec,_.cloneDeep(temp.subMenus),currency);
      });
    }
  }

  export const onChangeState = async (state:any,ledgerAccountReportController:any) => {
    let reportType =  getReportType(ledgerAccountReportController.reportConfig);
    if(reportType && state && state.length >0) {
        let QueryParam='';
        QueryParam = QueryParam.concat("ledgerType=" + reportType +'&');
        QueryParam = QueryParam.concat("dependant=" + "state|" + state.join(","));
        let staticData = await getStaticFilterInfo(QueryParam);
        if (staticData?.data) {
          return _.cloneDeep(staticData.data.projectPlan || []);
        }
    }
    return [];
}

export const downloadExcel = (reportModel:any) => {
  // let reportInfo  = JSON.parse(JSON.stringify(runReportConfig.reportInfo.get()));
  let gridInfo:any = {};
  if(reportModel?.reportData?.gridReportInfo) {
    gridInfo =reportModel.reportData.gridReportInfo;
  }
  let currency = getSelectedCurrency(reportModel.reportConfig);

  let exportableReportData =  makePLReportData(gridInfo.reportDBData?.menuItems ? (gridInfo.reportDBData.menuItems) : []);

  let configInfo:any = {
    alternateRowColor:true,
    defaultColumnWidth:20,
    fileName: 'Profit Loss Report',
    reportDBData: gridInfo.reportDBData?.menuItems ? (gridInfo.reportDBData.menuItems) : [],
    reportColumns :gridInfo.columnDefs ||[],
    nestedKey:'subMenus',
    nestedRecordRowColor:false,
    excelHeaderInfo :getReportHeaderInfo(reportModel.reportConfig),
    excelRTLfields :['column','result'],
    asOnDate : dayjs().format(getAppSettingDateTimeFormat()),
    languageTranslation : reportModel.languageTranslation || {}
  }


  // let excelTableRows  =  _.cloneDeep(gridInfo.reportDBData);
  let excelTableRows  =  _.cloneDeep(exportableReportData);
  let dbData = []; 
  if(excelTableRows.menuItems) {
      dbData = excelTableRows.menuItems;
   }else {
    dbData = excelTableRows;
   }
   if(dbData && dbData.length > 3) {
    dbData[1].name = dbData[0].name +" "+ dbData[1].name;
    dbData[3].name = dbData[2].name +" "+ dbData[3].name;
   }
  makeExportData(dbData,currency);
  configInfo['excelTableRows'] = dbData;
  const excelLib =  ExcelTreeDataDownload(configInfo);
  excelLib.downLoad();
}
const getReportHeaderInfo = (reportConfig:any) => {
  let headerConfig:any = {};
  if(reportConfig) {
    headerConfig['reportType'] =  getReportType(reportConfig) || '';
    headerConfig['subOrganization'] =reportConfig.selectedValue || ''; 
    headerConfig['date'] = getReportSelectedDates(reportConfig);
    headerConfig['exportedDate'] = printFormatDate(new Date()) || '';
  }
  return headerConfig;
}
const getReportSelectedDates = (reportConfig:any)=>{
  if(reportConfig.leftPanel){
    return printFormatDate(reportConfig.leftPanel.startDate)+" To "+printFormatDate(reportConfig.leftPanel.endDate);
  }
  return '';
}
const printFormatDate = (date:any) => {
  if(date){
       return getValueInDateTimeFormatAsAppSetting(date, 'date');
  }
  return '';
}


/** EXPORT DATA HANDLING  */
const makeExportData = (reportData:any,currency:any) => {
  if(reportData && reportData.length ){
    _.forEach(reportData,(rec:any)=> {
      rec['column'] = getLocaleValue(rec.column,currency) || rec.column;
      rec['result'] = getLocaleValue(rec.result,currency) ||'';
        if(rec?.subMenus && rec.subMenus.length>0 ){
            rec.subMenus.forEach((subRec:any) => {
              recursivelyGetExportSubMenusData(subRec,currency)
            });
        }
    })
  }
}
const recursivelyGetExportSubMenusData = (subMenuRecs:any,currency:any) => {
  subMenuRecs['column'] = getLocaleValue(subMenuRecs.column,currency) || subMenuRecs.column;
  subMenuRecs['result'] = getLocaleValue(subMenuRecs.result,currency) ||'';
  if(subMenuRecs?.subMenus && subMenuRecs.subMenus.length>0 ){
    subMenuRecs.subMenus.forEach((subRec:any) => {
      recursivelyGetExportSubMenusData(subRec,currency);
    });
  }
}


const makePLReportData = function (chartData:any,currency?:any) {
  handleEmptyValueRecords(chartData);
  let _plData:any = [];
  if (chartData && chartData.length == 4 && chartData[0]['name'] == 'EXPENSES') {
      let plDatachunk = _.chunk(chartData, 2);
      _plData = plDatachunk[1];
      _plData = _plData.concat(plDatachunk[0]);

      let netProfit:any =""+( parseInt(_plData[1].result.replaceAll(",", "")) - parseInt(_plData[3].result.replaceAll(",", "")));
      _plData.push(
          {
              "name": "NET PROFIT",
              "id": "P4",
              "result": netProfit
          })
  }else {
      _plData = chartData;
  }
  return _plData;
}

const handleEmptyValueRecords = function(chartData:any) {
   if(chartData && chartData.length > 0) {
      for(let index=0;index < chartData.length; index++) {
          if(chartData[index] && chartData[index].subMenus && chartData[index].subMenus.length > 0) {
               recursivelyRemoveEmptyValueRecords(chartData[index].subMenus,index);
           }
      }
   }
}
const recursivelyRemoveEmptyValueRecords = function(subMenus:any,index:any)  {
  if(subMenus && subMenus.length > 0) {
         for(let _index =0; _index < subMenus.length; _index++)  {
          if(subMenus[_index].column ) {
              let itemValue = subMenus[_index].column.replaceAll(",","");
              if(itemValue && parseInt(itemValue) !=0){
                  if(subMenus[_index].subMenus &&  subMenus[_index].subMenus.length > 0 ){
                      recursivelyRemoveEmptyValueRecords(subMenus[_index].subMenus,_index);
                  }
              }else {
                  subMenus.splice(_index,1);
                  _index = _index -1;
              }

          }
      }
  }
}

/**  END EXPORT DATA HANDLING  */