import { getDmsViewUrl } from '../../../../../../../../../../../../service/URLService';
import { buildDms } from '../../../../../../../process/builder/dms/controller/kagamiDMSController';
import { KagamiDMSModel } from '../../../../../../../process/builder/dms/model/kagamiDMSModel';
import { buildLedgerDetails } from '../../ledgerDetails/controller/LedgerDetailsController';
import { LedgerDetailsModel } from '../../ledgerDetails/model/LedgerDetailsModel';
import { SubLedgerReportModel } from '../model/SubLedgerReportModel';
import { BuildSubLedgerReportView } from '../ui/SubLedgerReport';



let subLedgerReport: SubLedgerReportModel;

export function buildSubLedgerReport(subLedgerReportModel: SubLedgerReportModel) {
  subLedgerReport = subLedgerReportModel
    return BuildSubLedgerReportView(subLedgerReportModel);
  }

export const handleOnLedgerClick = (ledgerInputs:any,row:any) => {
  if(row.data !== undefined){
    if(row.data['otherDeatils']!==undefined && row.data['otherDeatils']['file']){
      let kagamiDMSModel:KagamiDMSModel = new KagamiDMSModel();
      kagamiDMSModel.dmsResponseUrl = getDmsViewUrl(row.data['otherDeatils']['file']);
      subLedgerReport.modelPopup.title = 'preview file';
      subLedgerReport.modelPopup.maxWidth = 'xl';
      subLedgerReport.modelPopup.content = buildDms(kagamiDMSModel);
      subLedgerReport.modelPopup.pdfIconVisible = false;
      subLedgerReport.modelPopup.state.setOpen(true);
    }else{
      const ledgerDetailsModel = new LedgerDetailsModel();
      ledgerDetailsModel.ledgerInputs = ledgerInputs;
      subLedgerReport.modelPopup.title = subLedgerReport?.reportConfig?.reportConfigSummary?.name;
      subLedgerReport.modelPopup.content = buildLedgerDetails(ledgerDetailsModel);
      subLedgerReport.modelPopup.pdfIconVisible = true;
      subLedgerReport.modelPopup.state.setOpen(true);
    }
  }
}
