import { sys } from 'typescript';
import {
  hideLoader,
  showLoader
} from '../../../../../../../../../../components/loader/controller/KagamiLoaderController';
import { KagamiModalModel } from '../../../../../../../../../../components/modal/model/KagamiModalModel';
import { APIService } from '../../../../../../../../../../service/ApiService';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { showServerErrorPopUp } from '../../../../../../../../../controller/KagamiApplicationController';
import { getEntity, getPresentationRuleForId } from '../../../../../../../../../service/URLService';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { getDateStringToPopulateInForm, isTypeDate } from '../../../../../../../../../utils/DateUtils';
import { convertDecimalValueToMinutes } from '../../../../../../../../../utils/DateUtils';
import { KagamiBeanUtils } from '../../../../../../../../../utils/KagamiBeanUtils';
import { getActiveModel } from '../../../controller/KagamiProcessController';
import { EventType, KagamiSubProcessRequestModel } from '../../../model/KagamiSubProcessRequestModel';
import { RuntimeInput } from '../../../model/RuntimeInput';
import {
  extractDropdownOption,
  getAttributeName,
  getAttributePresentations,
  getEntityConsumed,
  getEntityConsumedInAttribute,
  getForAttrFromPresentation,
  getHtmlControl,
  isTypeText,
  isVisibleAttribute,
  retrievePresentation
} from '../../../service/PresentationService';
import { updateErrorContent } from '../../../service/ProcessPageService';
import { makeOnClickCall } from '../../../service/ProcessService';
import { onSearchSuccess, onSubProcessCall } from '../../../service/SubProcessService';
import { KagamiControlModel } from '../../controls/model/kagamiControlModel';
import { KagamiEmbedTriggerModel } from '../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/builder/Embed-Triggers/model/KagamiEmbedTriggerModel';
import { getFormModel, handleFormDataChange, showServerError } from '../../form/controller/KagamiFormController';
import { updateInFocusMap, _getUnSyncedFormRecord } from '../../form/service/FormService';
import { KagamiGridPaginationModel } from '../builder/grid/builder/pagination/model/KagamiGridPaginationModel';
import { buildGrid, reBuildGridView } from '../builder/grid/controller/KagamiGridController';
import { KagamiGridModel } from '../builder/grid/model/KagamiGridModel';
import { buildGridControlModels } from '../builder/grid/service/GridService';
import { bulidEntityConsumedGrid, getContentForEmbdeeddata } from '../builder/ListBuilder';
import { getKagamiListModel, reBuildList, setKagamiListModel } from '../controller/KagamiListController';
import { KagamiListModel } from '../model/KagamiListModel';
import { any } from 'prop-types';
import { KagamiFormModel } from '../../form/model/KagamiFormModel';
import _ from 'lodash';
import { listpopupHeaderwithTotal } from '../ui/KagamiList';
import { appContext } from '../../../service/PolicyExecutionService';

//embed entity for list on click functionality to fetch embed data and display in modal popup
export function fetchEmbedRecords(attributePresentation: any, params: any) {
  let currentActiveTemplateModel = getActiveModel();
  let presenation: any;
  let processName = currentActiveTemplateModel.processName;
  let processStepName = currentActiveTemplateModel.processStepName;
  let mainEntityId = currentActiveTemplateModel.mainEntityId;
  let detailedObjects = params.id;
  let mainPresentationId: string;
  let existingMainPresentationId = localStorage.getItem(SystemConstants.MAIN_PRESENTATION_ID);
  if (existingMainPresentationId != null) {
    mainPresentationId = existingMainPresentationId;
  } else {
    mainPresentationId = getKagamiListModel().presentation.presentationId;
    localStorage.setItem(SystemConstants.MAIN_PRESENTATION_ID, mainPresentationId);
  }
  var fetchEmbedPresentationUrl = getPresentationRuleForId(attributePresentation.embeddedPresentationId);
  APIService.getData(SystemConstants.GET, fetchEmbedPresentationUrl, null).then((response: any) => {
    // console.log('OnClick data for ', attributePresentation.attrName, response.data);
    presenation = response.data;
    let embeddedEntityId = response.data.entityPrefix + response.data.entityId;
    makeOnClickCall(processName, mainEntityId, embeddedEntityId, detailedObjects, mainPresentationId).then(
      (response: any) => {
        // console.log(response);
        let paginationModel = new KagamiGridPaginationModel(
          false,
          false,
          getListTotalRecords(currentActiveTemplateModel.onStartData),
          getStartRecord(currentActiveTemplateModel.onStartData),
          getEndRecord(currentActiveTemplateModel.onStartData),
          currentActiveTemplateModel.mainEntityId,
          currentActiveTemplateModel.processName,
          retrievePresentation(currentActiveTemplateModel.onStartData)
        );
        let kagamiGridModel: KagamiGridModel = new KagamiGridModel(
          processName,
          processStepName,
          mainEntityId,
          presenation,
          paginationModel
        );
        if (CommonUtils.isNotEmpty(appContext) && CommonUtils.isNotEmpty(appContext.AppSetting[0])) {
          kagamiGridModel.pageSize = appContext.AppSetting[0]?.pageSize;
          kagamiGridModel.paginationForList = getPaginationForList(appContext);
          kagamiGridModel.paginationForEmbedForm = getPaginationForEmbedForm(appContext);
        }
        let totalPages =
          Number(getListTotalRecords(currentActiveTemplateModel.onStartData)) / Number(kagamiGridModel.pageSize);
        kagamiGridModel.totalPages = Math.ceil(totalPages);
        kagamiGridModel.paginationModel.totalRecords = kagamiGridModel.totalRecords;
        buildGridControlModels(kagamiGridModel);
        let component = buildGrid(kagamiGridModel);
        getKagamiListModel().embedModal.content = component;
        getKagamiListModel().embedModal.title = attributePresentation.label;
        let alertDialogState = getKagamiListModel().embedModal.state;
        alertDialogState.setOpen(true);
        if (response.data.constructOutputData != null) {
          reBuildGridView(
            currentActiveTemplateModel,
            response.data.constructOutputData.detailedObjects[mainEntityId],
            mainEntityId
          );
        }
      }
    );
  });
}

export function fetchEmbedRecordsForAgGrid(
  attributePresentation: any,
  activeId: any,
  kagamiModalModel: KagamiModalModel,
  mainEntityId: string,
  activeModel: any = null,
  data?: any
) {
  let currentActiveTemplateModel = activeModel !== null ? activeModel : getActiveModel();
  let presenation: any;
  let formHeader: string = currentActiveTemplateModel.formHeader;
  let processName = currentActiveTemplateModel.processName;
  let processStepName = currentActiveTemplateModel.processStepName;
  // let mainEntityId = currentActiveTemplateModel.mainEntityId;
  let mainPresentationId: string;
  let existingMainPresentationId = localStorage.getItem(SystemConstants.MAIN_PRESENTATION_ID);
  if (existingMainPresentationId != null) {
    mainPresentationId = existingMainPresentationId;
  } else {
    mainPresentationId = getKagamiListModel().presentation.presentationId;
    localStorage.setItem(SystemConstants.MAIN_PRESENTATION_ID, mainPresentationId);
  }
  if (CommonUtils.isNotEmpty(attributePresentation.embeddedPresentationId)) {
    if (currentActiveTemplateModel instanceof KagamiListModel) {
      var fetchEmbedPresentationUrl = getPresentationRuleForId(attributePresentation.embeddedPresentationId);
      showLoader();
      APIService.getData(SystemConstants.GET, fetchEmbedPresentationUrl, null).then((response: any) => {
        hideLoader();
        presenation = response.data;
        let embeddedEntityId = response.data.entityPrefix + response.data.entityId;
        makeOnClickCall(processName, mainEntityId, embeddedEntityId, activeId, mainPresentationId).then(
          (response: any) => {
            if (Object.keys(response.data.constructOutputData.detailedObjects[mainEntityId][0]).length > 1) {
              if (response.data.constructOutputData) {
                let paginationModel = new KagamiGridPaginationModel(
                  false,
                  false,
                  getListTotalRecords(currentActiveTemplateModel.onStartData),
                  getStartRecord(currentActiveTemplateModel.onStartData),
                  getEndRecord(currentActiveTemplateModel.onStartData),
                  currentActiveTemplateModel.mainEntityId,
                  currentActiveTemplateModel.processName,
                  retrievePresentation(currentActiveTemplateModel.onStartData)
                );
                let kagamiGridModel: KagamiGridModel = new KagamiGridModel(
                  processName,
                  processStepName,
                  mainEntityId,
                  presenation,
                  paginationModel
                );
                if (CommonUtils.isNotEmpty(appContext) && CommonUtils.isNotEmpty(appContext.AppSetting[0])) {
                  kagamiGridModel.pageSize = appContext.AppSetting[0]?.pageSize;
                  kagamiGridModel.paginationForList = getPaginationForList(appContext);
                  kagamiGridModel.paginationForEmbedForm = getPaginationForEmbedForm(appContext);
                }
                kagamiGridModel.isListEmbedPopModel = true;
                kagamiGridModel.isEditableEmbedList = false;
                kagamiGridModel.isReadOnly = true;
                buildGridControlModels(kagamiGridModel);
                kagamiModalModel.content = buildGrid(kagamiGridModel);
                kagamiModalModel.title = listpopupHeaderwithTotal(
                  attributePresentation,
                  response.data.constructOutputData.detailedObjects[mainEntityId][0][attributePresentation.attrName]
                    .length
                );
                kagamiGridModel.totalRecords = CommonUtils.isNotEmpty(
                  response.data.constructOutputData.detailedObjects[mainEntityId][0][attributePresentation.attrName]
                )
                  ? response.data.constructOutputData.detailedObjects[mainEntityId][0][attributePresentation.attrName]
                      .length
                  : 0;
                let totalPages = Number(kagamiGridModel.totalRecords) / Number(kagamiGridModel.pageSize);
                kagamiGridModel.totalPages = Math.ceil(totalPages);
                kagamiGridModel.paginationModel.totalRecords = kagamiGridModel.totalRecords;
                kagamiModalModel.state.setOpen(true);
                if (response.data.constructOutputData != null) {
                  reBuildGridView(
                    kagamiGridModel,
                    response.data.constructOutputData.detailedObjects[mainEntityId][0][attributePresentation.attrName],
                    mainEntityId
                  );
                }
              } else if (response.data.constructError) {
                showServerErrorPopUp(response.data.constructError);
              }
            } else {
              let entityId = attributePresentation.attrName;
              let getDataAgainstEntityId = data[entityId];
              kagamiModalModel.title = attributePresentation.label;
              kagamiModalModel.content = getContentForEmbdeeddata(attributePresentation, getDataAgainstEntityId);
              kagamiModalModel.modalWidth = 'sm';
              kagamiModalModel.state.setOpen(true);
            }
          }
        );
      });
    }
  } else if (
    CommonUtils.isEmpty(attributePresentation.embeddedPresentationId) &&
    CommonUtils.isNotEmpty(attributePresentation.entityConsumed)
  ) {
    console.log(attributePresentation.entityConsumned);
    APIService.getData(SystemConstants.POST_SINGLE_VALUE, getEntity(), attributePresentation.entityConsumed).then(
      (res: any) => {
        if (CommonUtils.isNotEmpty(res.data)) {
          kagamiModalModel.title = listpopupHeaderwithTotal(
            attributePresentation,
            data[attributePresentation.attrName].length
          );
          kagamiModalModel.content = bulidEntityConsumedGrid(
            res?.data?.attributes,
            data[attributePresentation.attrName]
          );
          kagamiModalModel.modalWidth = 'lg';
          kagamiModalModel.state.setOpen(true);
        } else {
          return null;
        }
      }
    );
  } else {
    let entityId = attributePresentation.attrName;
    let getDataAgainstEntityId = data[entityId];
    kagamiModalModel.title = attributePresentation.label;
    kagamiModalModel.content = getContentForEmbdeeddata(attributePresentation, getDataAgainstEntityId);
    kagamiModalModel.modalWidth =
      CommonUtils.isNotEmpty(attributePresentation.displayAttributes) &&
      attributePresentation.displayAttributes?.length > 3
        ? 'md'
        : 'sm';
    kagamiModalModel.state.setOpen(true);
  }
}

export async function getEntityUrl(entityId: string) {
  let response = await APIService.getData(SystemConstants.POST_SINGLE_VALUE, getEntity(), entityId);
  let responseData = await response.data;
  let attr = responseData.attributes;
  bulidEntityConsumedGrid(attr, data);
}

export function retrieveListRows(kagamiGridModel: KagamiListModel) {
  // kagamiGridModel.textWidth = [];
  var prevLength: number = 0,
    curLength: number = 0,
    sum: number = 0,
    avg: number = 0,
    dataObj: any = { rCount: 0, type: '', isFirst: true, processStepName: '', textWidth: kagamiGridModel.textWidth };
  let mainObject: any = [];
  let presentation: any = kagamiGridModel.presentation;
  const attributePresentations: any[] = getAttributePresentations(presentation);

  let retrievedMainObject: any = retrieveMainObject(kagamiGridModel);
  if (retrievedMainObject && retrievedMainObject.length !== undefined) {
    retrievedMainObject.map((object: any) => {
      if (kagamiGridModel.presentation.entityId in object) {
        if (object[kagamiGridModel.presentation.entityId] instanceof Array) {
          object[kagamiGridModel.presentation.entityId].map((obj: any) => {
            mainObject.push(
              buildRowData(
                attributePresentations,
                obj,
                kagamiGridModel.presentation.entityPrefix + kagamiGridModel.presentation.entityId,
                kagamiGridModel
              )
            );
          });
        } else {
          mainObject.push(
            buildRowData(
              attributePresentations,
              object[kagamiGridModel.presentation.entityId],
              kagamiGridModel.presentation.entityPrefix + kagamiGridModel.presentation.entityId,
              kagamiGridModel
            )
          );
        }
      } else if (object === SystemConstants.REMOVED_) {
        /// Note : avoiding adding unnecessary records
        console.log('removed_ record ');
      } else {
        mainObject.push(
          buildRowData(
            attributePresentations,
            object,
            kagamiGridModel.presentation.entityPrefix + kagamiGridModel.presentation.entityId,
            kagamiGridModel
          )
        );
      }
    });
  }

  var rc = localStorage.getItem('rowsCount');
  //                      (Reloading same Page Again)
  dataObj =
    rc !== null
      ? JSON.parse(rc).processStepName === kagamiGridModel.processStepName &&
        CommonUtils.isNotEmpty(kagamiGridModel.detailedObjects) &&
        kagamiGridModel.detailedObjects.length > 0
        ? JSON.parse(rc)
        : dataObj
      : dataObj;

  if (
    (kagamiGridModel.processStepName !== dataObj.processStepName && dataObj.isFirst) ||
    (kagamiGridModel.processStepName !== dataObj.processStepName &&
      !dataObj.isFirst &&
      kagamiGridModel.presentation.uiTemplate !== 'list' &&
      dataObj.rCount !== mainObject.length) ||
    (kagamiGridModel.processStepName === dataObj.processStepName &&
      !dataObj.isFirst &&
      kagamiGridModel.presentation.uiTemplate !== 'list' &&
      dataObj.rCount !== mainObject.length)
  ) {
    kagamiGridModel.textWidth = [];
    console.time('Column Widths');
    var res = Object.keys(attributePresentations).map(function (name: any) {
      if (
        (attributePresentations[name].htmlControl === 'text' ||
          attributePresentations[name].htmlControl === 'textarea' ||
          attributePresentations[name].htmlControl === 'number' ||
          attributePresentations[name].htmlControl === 'customNumber') &&
        attributePresentations[name].visible
      ) {
        sum = 0;
        prevLength = 0;
        mainObject.map((row: any) => {
          curLength = row[name] === undefined || row[name] === null ? 0 : row[name].length;
          curLength = curLength == 0 ? prevLength : curLength;
          sum += curLength;
          prevLength = curLength;
        });
        setColumnWidth(sum, mainObject.length, kagamiGridModel, name, attributePresentations);
      } else if (
        (attributePresentations[name].htmlControl === 'search' ||
          attributePresentations[name].htmlControl === 'select') &&
        attributePresentations[name].visible
      ) {
        sum = 0;
        prevLength = 0;
        mainObject.map((row: any) => {
          let entityConsumed = getEntityConsumedInAttribute(attributePresentations[name]);
          let rslt = extractDropdownOption(attributePresentations[name], row[entityConsumed]);
          curLength = rslt === '' ? 0 : rslt.length;
          curLength = curLength == 0 ? prevLength : curLength;
          sum += curLength;
          prevLength = curLength;
        });
        setColumnWidth(sum, mainObject.length, kagamiGridModel, name, attributePresentations);
      } else if (attributePresentations[name].htmlControl === 'multiselect' && attributePresentations[name].visible) {
        sum = 0;
        prevLength = 0;
        mainObject.map((row: any) => {
          let entityConsumed = getEntityConsumedInAttribute(attributePresentations[name]);
          let rslt = extractDropdownOption(attributePresentations[name], row[entityConsumed]);
          curLength = rslt === '' ? 0 : rslt.length;
          curLength = curLength == 0 ? prevLength : curLength;
          sum += curLength;
          prevLength = curLength;
        });
        setColumnWidth(sum, mainObject.length, kagamiGridModel, name, attributePresentations);
      }
    });
    console.timeEnd('Column Widths');
    dataObj = {
      rCount: mainObject.length,
      type: kagamiGridModel.presentation.uiTemplate,
      isFirst: false,
      processStepName: kagamiGridModel.processStepName,
      textWidth: kagamiGridModel.textWidth
    };
    localStorage.setItem('rowsCount', JSON.stringify(dataObj));
  }
  kagamiGridModel.textWidth = dataObj.textWidth;
  if (kagamiGridModel.isPivot) {
    mainObject.map((dataRow: any) => {
      var hrs: number = 0;
      Object.keys(attributePresentations).map(function (ColName: any) {
        if (
          attributePresentations[ColName].matrixAxis !== undefined &&
          attributePresentations[ColName].matrixAxis === 'x'
        ) {
          hrs += dataRow[ColName] === undefined ? 0 : dataRow[ColName];
        }
      });
      dataRow['hours'] = Number(hrs.toFixed(1));
    });
  }
  return mainObject;
}
function setColumnWidth(
  sum: number,
  mainObjectLength: number,
  kagamiGridModel: any,
  name: any,
  attributePresentations: any
) {
  var avg: number = sum / mainObjectLength;
  avg = avg > 100 ? 100 : avg;
  avg = Math.round(((avg + 5) * 8) / 10) * 10;
  if (kagamiGridModel.isEmbedModel) {
    var hdrAvg =
      ((CommonUtils.isNotEmpty(attributePresentations[name].label)
        ? attributePresentations[name].label.length
        : name.length) +
        5) *
      8;
    avg = avg > hdrAvg ? avg : hdrAvg;
  }
  kagamiGridModel.textWidth.push({ key: name, minWidth: avg, attrName: name });
}
export function retrieveMainObject(kagamiGridModel: KagamiListModel) {
  let detailedObjects: any = kagamiGridModel.detailedObjects;
  // return kagamiGridModel.kagamiGridModel.detailedObjects;
  if (detailedObjects) {
    return detailedObjects instanceof Array ? detailedObjects : [detailedObjects];
  } else {
    // return detailedObjects;
    return [];
  }
}

export function searchRecord(
  listModel: KagamiListModel,
  presentationId: any,
  attributePresentation: any,
  searchQuery: any,
  searchRecord?: any,
  mergeOnloadData?: boolean
): any {
  let runtimeInput: RuntimeInput = new RuntimeInput();
  runtimeInput.id = 'RuntimeInput';
  runtimeInput.searchKey = searchQuery;
  listModel.filterList = searchRecord;
  let requestModel: KagamiSubProcessRequestModel = new KagamiSubProcessRequestModel(
    listModel.processName,
    getAttributeName(attributePresentation),
    attributePresentation,
    null,
    EventType.ONSEARCH,
    onSearchSuccess,
    updateErrorContent
  );
  requestModel.presentationId = presentationId;
  requestModel.runtimeInput = runtimeInput;
  requestModel.searchRecord = searchRecord;
  showLoader();
  onSubProcessCall(requestModel, '', '', listModel.pageNumber).then((response) => {
    hideLoader();
    if (response != null) {
      if (response.data && response.data['constructOutputData'] != null) {
        reBuildList(response.data, false, listModel.triggersModel.viewType, mergeOnloadData);
      } else if (response.data && response.data['constructError'] != null) {
        showServerError(response.data['constructError']);
      }
    }
  });
}

function buildRowData(attributePresentations: any, object: any, embedEntity: any, kagamiGridModel: KagamiListModel) {
  //map converter
  let ObjectData = new Map();
  for (var value in object) {
    if (object[value] === 'removed_') {
      ObjectData.set(value, null);
    } else {
      ObjectData.set(value, object[value]);
    }
  }
  // ObjectData.set('grnNoWidth', object['grnNo'].length);
  for (const attribute in attributePresentations) {
    if (!attribute.includes('NonFieldPresentation')) {
      let value: any;
      let attributePresentation = attributePresentations[attribute];
      if (isVisibleAttribute(attributePresentation)) {
        let attributeName = getAttributeName(attributePresentation);
        let htmlControl = getHtmlControl(attributePresentation);
        if (isTypeDate(htmlControl)) {
          value = getDateStringToPopulateInForm(ObjectData.get(attributeName), htmlControl);
          ObjectData.set(attributeName, value);
        } else if (isTypeText(htmlControl)) {
          value = ObjectData.get(attributeName);
          value = value === SystemConstants.REMOVED_ ? null : value;
          if (
            htmlControl === 'number' &&
            CommonUtils.isNotEmpty(attributePresentation.uiSettings) &&
            attributePresentation.uiSettings.conversion === 'hh:mm'
          ) {
            // conversion of decimals to Time
            let displayValue: any;
            value = CommonUtils.isNotEmpty(value) ? value : 0;
            if (value.toString().includes('.')) {
              let x = value.toString().split('.');
              let bLogic;
              if (x[1] == '5') {
                bLogic = Math.round((x[1] * 600) / 100);
              } else {
                if (x[1].length > 1) {
                  bLogic = Math.round((x[1] * 60) / 100);
                } else {
                  bLogic = Math.round((x[1] * 600) / 100);
                }
              }
              let Blogic = bLogic.toString().length > 1 ? bLogic : '0' + bLogic;
              x[0].length > 1 ? (displayValue = x[0] + ':' + Blogic) : (displayValue = '0' + x[0] + ':' + Blogic);
              value = displayValue;
            } else if (value === 0) {
              value = '00:00';
            } else if (typeof value === 'number' && value > 0) {
              value = convertDecimalValueToMinutes(value);
            }
          }
          ObjectData.set(attributeName, value);
        }

        if (getEntityConsumed(attributePresentation)) {
          if (
            kagamiGridModel instanceof KagamiGridModel &&
            kagamiGridModel.isEditableEmbedList &&
            htmlControl !== 'search' &&
            htmlControl != 'multiselect' &&
            htmlControl !== 'select' &&
            htmlControl !== 'radio'
          ) {
            value = retriveValueForLov(ObjectData, attributePresentation, htmlControl);
          } else if (htmlControl === 'multiselect') {
            // if (getForAttrFromPresentation(attributePresentation) !== null) {
            //   value = retriveValueForLov(ObjectData, attributePresentation, htmlControl);
            // } else {
            //   value = getDataForLovObjects(ObjectData.get(attributeName));
            // }
            value = ObjectData.get(attributeName);
          } else {
            value = ObjectData.get(attributeName);
          }
          ObjectData.set(attributeName, value);
        } else if (
          attributeName &&
          attributeName.includes('.') &&
          CommonUtils.isEmpty(attributePresentation.matrixAxis)
        ) {
          value = KagamiBeanUtils.getProperty(attributeName, object);
          if (isTypeDate(htmlControl)) {
            value = getDateStringToPopulateInForm(value, htmlControl);
          }
          ObjectData.set(attributeName, value);
        } else {
          //handling list embed values for embed modal popup
          if (object[embedEntity] != null && object[embedEntity] != undefined) {
            if (object[embedEntity][0] != null && object[embedEntity][0] != undefined) {
              ObjectData.set(attributeName, object[embedEntity][0][attributeName]);
            } else {
              ObjectData.set(attributeName, object[embedEntity][attributeName]);
            }
          }
        }
      }
    }
  }
  // json convert
  let jsonObject: any = {};
  Array.from(ObjectData.keys()).forEach((key) => {
    jsonObject[key] = ObjectData.get(key);
  });
  return jsonObject;
}
export function getValueForPickAttribute(attributeName: any, value: any) {
  for (let att of attributeName.split('.')) {
    if (value) {
      if (value instanceof Map) {
        value = value.get(att);
      } else {
        value = value[att];
      }
    }
  }
  return value;
}

export function getValueForTextPickAttribute(attributeName: any, value: any) {
  let getData: any;
  if (attributeName.includes('.')) {
    for (let att of attributeName.split('.')) {
      if (value) {
        if (value instanceof Map) {
          getData = value.get(att);
          return getData;
        } else {
          getData = value[att];
          if (CommonUtils.isNotEmpty(getData)) {
            if (Array.isArray(getData)) {
              return getData[0][attributeName.split('.').pop()];
            } else {
              return null;
            }
          } else if (CommonUtils.isEmpty(getData)) {
            return null;
          } else {
            if (attributeName.split('.').length === 3) {
              let getSecondPickattributeData = getData[attributeName.split('.')[2]];
              return getSecondPickattributeData[attributeName.split('.').pop()];
            } else if (attributeName.split('.').length === 2) {
              return getData[attributeName.split('.').pop()];
            }
          }
        }
      }
    }
  } else {
    return value[attributeName];
  }
}

// export function getValueForPickAttribute(attributeName: any, value: any) {

//   for (let att of attributeName.split('.')) {
//     // let data = value
//     if (value) {
//       if (value instanceof Object) {
//         data = data.get(att);
//        else {
//         data = data[att];
//       }
//     }
//   }
//   return data;
// }

//TODO PrsentationService extractDropdownOption can be used?
// tried using that but most of the values were not extracting 23 june 2021

function retriveValueForLov(objects: any, attribute: any, htmlControl: string) {
  let returnValue = new Map();
  let textValue: string = '';
  let entityConsumned = getEntityConsumed(attribute);
  let entityPrefix = attribute.entityPrefix;
  if (entityPrefix != null && entityPrefix !== undefined) {
    entityConsumned = entityPrefix.concat(entityConsumned);
  }
  let displayAttributes = getForAttrFromPresentation(attribute);
  /// Note : Commenting id's because unnecessarily we are showing id's in grid rows
  // if (displayAttributes == null) {
  //   displayAttributes = ['id'];
  // }
  let LovObject = objects.get(entityConsumned);
  if (displayAttributes != null && LovObject !== undefined) {
    if (LovObject instanceof Array) {
      let valuesToBeDisplayInEmbed: string[] = [];
      LovObject.forEach((obj: any) => {
        displayAttributes.forEach((displayAttribute: any) => {
          textValue = retrieveValueForEmbedIfObjectIsArray(
            obj,
            displayAttribute,
            valuesToBeDisplayInEmbed,
            returnValue,
            textValue
          );
        });
        if (htmlControl === 'multiselect' && !isDisplayAttributeContainsDot(displayAttributes)) {
          valuesToBeDisplayInEmbed[valuesToBeDisplayInEmbed.length - 1] =
            valuesToBeDisplayInEmbed[valuesToBeDisplayInEmbed.length - 1] + '---';
          if (textValue.charAt(textValue.length - 1) === '-') {
            textValue = textValue + '--';
          }
        }
      });
      returnValue.set('value', valuesToBeDisplayInEmbed);
    } else {
      let valuesToBeDisplayInEmbed: string[] = [];
      textValue = retreiveValueForEmbedFromResultSet(
        displayAttributes,
        returnValue,
        textValue,
        valuesToBeDisplayInEmbed,
        LovObject
      );
      returnValue.set('value', valuesToBeDisplayInEmbed);
    }
  } else if (displayAttributes === null && LovObject !== undefined) {
    textValue = getDataForLovObjects(LovObject);
  }
  if (!attribute.onClickRequired || getEntityConsumed(attribute)) {
    return textValue.length > 1 ? textValue.substring(0, textValue.length - 1) : textValue;
  }
  return returnValue;
}

function isDisplayAttributeContainsDot(displayAttributes: any[]) {
  let isContainsDot: boolean = false;
  let displayAttribute: any;
  for (displayAttribute of displayAttributes) {
    if (displayAttribute.name && displayAttribute.name.includes('.')) {
      isContainsDot = true;
      return isContainsDot;
    }
  }
  return isContainsDot;
}

function retrieveValueForEmbedIfObjectIsArray(
  obj: any,
  displayAttribute: any,
  valuesToBeDisplayInEmbed: string[],
  returnValue: any,
  textValue: string
) {
  if (displayAttribute !== null && displayAttribute.name !== 'id') {
    if (displayAttribute.type === 'file') {
      returnValue.set('file', obj[displayAttribute.name]);
    } else if (displayAttribute.name && displayAttribute.name.includes('.')) {
      let fieldToDisplay = displayAttribute.name.split('.');
      var entityIdToBeDisplayed: any = '';
      var columnToBeDisplayed: any = '';
      var key: any = '';
      var value: any = '';
      for ([key, value] of Object.entries(fieldToDisplay)) {
        if (!value.includes('_')) {
          entityIdToBeDisplayed = fieldToDisplay[key - 1];
          columnToBeDisplayed = value;
        }
      }
      if (obj[entityIdToBeDisplayed] && columnToBeDisplayed) {
        textValue = textValue.concat(obj[entityIdToBeDisplayed][columnToBeDisplayed], '---');
        valuesToBeDisplayInEmbed.push(textValue.substring(0, textValue.length - 1));
      }
    } else {
      textValue = textValue.concat(
        obj[
          displayAttribute.name != undefined &&
          obj[displayAttribute.name] != null &&
          obj[displayAttribute.name] != undefined
            ? displayAttribute.name
            : 'id'
        ],
        '-'
      );
      valuesToBeDisplayInEmbed.push(textValue.substring(0, textValue.length - 1));
    }
  }
  return textValue;
}

function getDataForLovObjects(LovObject: any[]) {
  if (LovObject && LovObject.length > 2) {
    let displayLovObjects: any = LovObject.length - 2;
    let getDataTOString: any = displayLovObjects.toString();
    return getDataTOString;
  } else {
    return null;
  }
}

function retreiveValueForEmbedFromResultSet(
  displayAttributes: any,
  returnValue: any,
  textValue: any,
  valuesToBeDisplayInEmbed: any,
  LovObject: any
) {
  if (CommonUtils.isNotEmpty(LovObject)) {
    displayAttributes.map((displayAttribute: any) => {
      if (displayAttribute.name !== 'id') {
        if (displayAttribute.type == 'file') {
          returnValue.set('file', LovObject[displayAttribute.name]);
        } else {
          if (LovObject[displayAttribute.name !== undefined ? displayAttribute.name : 'id'] !== undefined) {
            textValue = textValue.concat(
              LovObject[displayAttribute.name != undefined ? displayAttribute.name : 'id'],
              '-'
            );
          } else if (displayAttribute.name && displayAttribute.name.includes('.')) {
            textValue = textValue.concat(KagamiBeanUtils.getProperty(displayAttribute.name, LovObject));
          }
          valuesToBeDisplayInEmbed.push(
            textValue.length > 1 ? textValue.substring(0, textValue.length - 1) : textValue
          );
        }
      }
    });
  }
  return textValue;
}

export function getListTotalRecords(onStartData: any) {
  return onStartData.constructOutputData.verbProperties !== undefined
    ? onStartData.constructOutputData.verbProperties.totalRecords
    : '';
}

export function getPaginationForList(appContext:any) {
 if(CommonUtils.isNotEmpty(appContext.AppSetting[0]?.paginationForList)) {
  return appContext.AppSetting[0]?.paginationForList;
 }
}

export function getPaginationForEmbedForm(appContext:any) {
  if(CommonUtils.isNotEmpty(appContext.AppSetting[0]?.paginationForEmbedForm)){
    return appContext.AppSetting[0]?.paginationForEmbedForm;
  }
 }

export function getStartRecord(onStartData: any) {
  return onStartData.constructOutputData.verbProperties !== undefined
    ? onStartData.constructOutputData.verbProperties.startRecord
    : 0;
}

export function getEndRecord(onStartData: any) {
  return onStartData.constructOutputData.verbProperties !== undefined
    ? onStartData.constructOutputData.verbProperties.endRecord
    : 0;
}

export function getEmbedTotalRecords(onStartData: any, embedEntityId: string) {
  let totalEmbedRecords: any = 0;
  let entityPageMap: any = onStartData.constructOutputData?.embeddedInfo?.entityPageMap;
  if (entityPageMap && entityPageMap[embedEntityId]) {
    totalEmbedRecords = entityPageMap[embedEntityId].totalRecords ? entityPageMap[embedEntityId].totalRecords : 0;
  }
  return Number(totalEmbedRecords);
}

export function getEmbedStartRecord(onStartData: any, embedEntityId: string) {
  let startRecord: any = 0;
  let entityPageMap: any = onStartData.constructOutputData?.embeddedInfo?.entityPageMap;
  if (entityPageMap && entityPageMap[embedEntityId]) {
    startRecord = entityPageMap[embedEntityId].startRecord ? entityPageMap[embedEntityId].startRecord : 0;
  }
  return Number(startRecord);
}

export function getEmbedEndRecord(onStartData: any, embedEntityId: string) {
  let endRecord: any = 0;
  let entityPageMap: any = onStartData.constructOutputData?.embeddedInfo?.entityPageMap;
  if (entityPageMap && entityPageMap[embedEntityId]) {
    endRecord = entityPageMap[embedEntityId].endRecord ? entityPageMap[embedEntityId].endRecord : 0;
  }
  return Number(endRecord);
}

export function isMasterDetailEnabled(kagamiGridModel: KagamiGridModel) {
  return kagamiGridModel.nestedEmbedModels.length > 0;
}

export function onNestedEmbedOpened(params: any, gridModel: KagamiGridModel) {
  updateInFocusMap(gridModel.mainEntityId, params.data.id, getFormModel());
  /// Note : commented because its blocking row onload call , need to check all the cases to avoid any issues
  // gridModel.activeRowNode = params.node;
}
function data(data: any) {
  throw new Error('Function not implemented.');
}
export function haveHyperlink(data: any) {
  let availTriggers = data.actions;
  let hasHyperlink: boolean = false;
  for (let dataActions in availTriggers) {
    if (availTriggers[dataActions].actionName?.toLowerCase() === 'hyperlink') {
      hasHyperlink = true;
    }
  }
  return hasHyperlink;
}

export function hasReadOnlyColumns(kagamiGridModel: KagamiGridModel) {
  let readOnlyColumns: boolean = true;
  for (const [attrName, controlModel] of Object.entries(kagamiGridModel.gridControls)) {
    if (controlModel instanceof KagamiControlModel && !controlModel.readonly) {
      readOnlyColumns = false;
    }
  }
  return readOnlyColumns;
}

export function disableEmbedTriggers(kagamiGridModel: KagamiGridModel) {
  let actions: Map<string, any> = kagamiGridModel.actions;
  if (actions) {
    for (let triggerName of Array.from(actions.keys())) {
      let triggerModel: KagamiEmbedTriggerModel = actions.get(triggerName);
      if (triggerModel.state) {
        triggerModel.state.setEnableTrigger(false);
      }
    }
  }
}

export function enableHeaderCheckbox(kagamiGridModel: KagamiGridModel, rowData: any[]) {
  let enable: boolean =
    !kagamiGridModel.isEmbedModel &&
    !kagamiGridModel.isEditableEmbedList &&
    !kagamiGridModel.isReadOnly &&
    CommonUtils.isNotEmpty(kagamiGridModel.triggersModel.actions);
  if (kagamiGridModel.isPickItemListModel) {
    enable = CommonUtils.isNotEmpty(rowData);
  }
  return enable;
}

export function embedColumnSearch(gridModel: KagamiGridModel, attrName: string, searchKey: any, displayAttr: string) {
  let formModel: KagamiFormModel = getFormModel();
  let requestModel: KagamiSubProcessRequestModel = new KagamiSubProcessRequestModel(
    gridModel.processName,
    displayAttr,
    null,
    null,
    EventType.PAGECHANGE,
    onSearchSuccess,
    updateErrorContent
  );
  let runtimeInput: RuntimeInput = new RuntimeInput();
  runtimeInput.id = 'RuntimeInput';
  runtimeInput.searchKey = searchKey;
  requestModel.runtimeInput = runtimeInput;
  requestModel.entityPresentation = gridModel.presentation;
  _getUnSyncedFormRecord(formModel, formModel.mainEntityId);
  requestModel.data = _.clone(formModel.formData.changedData);
  // requestModel.changedAttribute = attrName;
  requestModel.searchRecord = searchKey;
  onSubProcessCall(requestModel, formModel, undefined).then((response: any) => {
    if (CommonUtils.isNotEmpty(response)) {
      if (response.data && CommonUtils.isNotEmpty(response.data.constructOutputData)) {
        //  reBuildList(response.data)
        handleFormDataChange(gridModel.mainEntityId, response.data, undefined, false, false, false, true);
      } else if (response.data && response.data.constructError) {
        showServerError(response.data['constructError']);
      }
    }
  });
}
