import { AgGridReact } from 'ag-grid-react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { getStaticReportGridOptions } from '../service/StaticReportUtil';
export const StaticReportGrid = (props: any) => {
    let config = props.config;
    let gridOptions: any = getStaticReportGridOptions(config) || {};
    return (
        <div>
            <Grid container style={{ margin: '10px', textAlign: 'center' }}  >
                <Grid item xs={12}>
                    {config.runReportConfig.showLoader.get() &&
                        <CircularProgress color="secondary" />
                    }
                </Grid>
            </Grid>
            {config?.runReportConfig.loadReport.get() &&
                <div className="ag-theme-alpine kgm-report-aggrid kgm-report-hwm dashboard-report ledgerAccountReport-custom" >
                    <AgGridReact
                        ref={config?.gridRef}
                        {...gridOptions}
                    />
                </div>
            }
        </div>

    )
}



