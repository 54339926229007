import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../constants/SystemConstants';
import { KagamiProfileModel } from '../builder/profile/model/KagamiProfileModel';

export class KagamiHeaderUserProfileModel extends KagamiProfileModel {
  open: boolean;
  constructor(content: any, userDataForProfile:any
    ) {
    super(content, userDataForProfile
      );
    this.content = content;
    this.userDataForProfile = userDataForProfile;
    this.open = false;
  }
}

export const wrapKagamiHeaderProfileState = (state: State<KagamiHeaderUserProfileModel>) => {
  let profileState = state;

  return {
    get isOpen() {
      return profileState.open.get();
    },

    setOpen(isOpen: boolean) {
      profileState.open.set(isOpen);
    },

    get content() {
      return profileState.content.get();
    },

    setContent(content: any) {
      profileState.content.set(content);
    },

  
  };
};

export const useKagamiHeaderProfileState = (state: State<KagamiHeaderUserProfileModel>) =>
  wrapKagamiHeaderProfileState(useState(state));
