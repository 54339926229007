import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { Button, Divider, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { createState } from '@hookstate/core';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import _ from 'lodash';
import { showBody } from '../../../../controller/KagamiHomeController';
import {
  KagamiTabNavigationModel,
  KagamiTabNavigationModelProps,
  useKagamiTabNavigation
} from '../model/KagamiTabNavigationModel';
import { KagamiStateManager } from '../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../constants/SystemConstants';
import { CommonUtils } from '../../../../../../utils/CommonUtils';
import { buildtoastNotification } from '../../../ToastNotification/controller/ToastNotificationController';
import { KagamiDashboardModel } from '../../../body/builder/dashboard/model/KagamiDashboardModel';
import { setDashboardModel } from '../../../body/builder/dashboard/ui/KagamiDashboard';
import { setBreadcrumbsModel } from '../../../body/builder/process/builder/breadcrumbs/controller/kagamiBreadcrumbsController';
import { setFormModel } from '../../../body/builder/process/builder/form/controller/KagamiFormController';
import { KagamiFormModel } from '../../../body/builder/process/builder/form/model/KagamiFormModel';
import { KagamiGroupModel } from '../../../body/builder/process/builder/group/model/KagamiGroupModel';
import { setKagamiListModel } from '../../../body/builder/process/builder/list/controller/KagamiListController';
import { KagamiListModel } from '../../../body/builder/process/builder/list/model/KagamiListModel';
import { KagamiMasterDetailModel } from '../../../body/builder/process/builder/master-detail/model/KagamiMasterDetailModel';
import {
  buildModalDialogContent,
  getModalDialog
} from '../../../body/builder/process/builder/modal-dialog/builder/KagamiModalDialogContentBuilder';
import {
  loadProcessContent,
  getActiveModel,
  getProcessPageModel,
  updateActiveModel
} from '../../../body/builder/process/controller/KagamiProcessController';
import { KagamiProcessPageModel } from '../../../body/builder/process/model/KagamiProcessModel';
import { ReportLandingPageModel } from '../../../body/builder/reports/landingPage/model/ReportsLandingPageModel';
import { renderReportPage } from '../../../body/controller/KagamiBodyController';
import { executeProcess } from '../../../navigator/builder/Menu/controller/KagamiMenuController';
import { KagamiScheduler } from '../../builder/profile/builder/KagamiScheduler';
import { getTabNavigationModel } from '../controller/KagamiTabNavigationController';
import { KagamiTabNavigationStyles } from '../style/KagamiTabNavigtaionStyle';
import { setGroupModel } from '../../../body/builder/process/builder/group/service/GroupService';
import { setLandingPageModel } from '../../../body/builder/reports/landingPage/controller/ReportsLandingPageController';
import { setGuidForProcessTabs } from '../../../body/builder/process/service/ServerEventService';

const useStyles = makeStyles(KagamiTabNavigationStyles);
interface Props {
  tabNavigationModel: KagamiTabNavigationModel;
}

export const buildTabs = (tabNavigationModel: KagamiTabNavigationModel) => {
  return <TabsComponent tabNavigationModel={tabNavigationModel} />;
};

export const TabsComponent = (props: KagamiTabNavigationModelProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabNavigationState = useKagamiTabNavigation(createState(props.tabNavigationModel));
  props.tabNavigationModel.state = tabNavigationState;
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  let tabs = JSON.parse(JSON.stringify(tabNavigationState?.getTabs));
  let activeTab = JSON.parse(JSON.stringify(tabNavigationState?.getActiveTab));
  let popupActive = JSON.parse(JSON.stringify(tabNavigationState?.getOpenNewTabPopupModal));
  let alreadyExistPopup = JSON.parse(JSON.stringify(tabNavigationState?.getAlreadyExistTabPopupModal));
  let popupData = JSON.parse(JSON.stringify(tabNavigationState?.getTabNameInModalPopup));

  const handleOnOk = () => {
    tabNavigationState?.setOpenNewTabPopupModal(false);
    let array: any = [...tabs];
    array.splice(1, 1);
    array.push(popupData);
    tabNavigationState?.setTabs(array);
    tabNavigationState?.setActiveTab(popupData?.processName);
    if (popupData?.processName === 'Dashboard') {
      showBody();
    } else if (popupData?.processName === 'Reports') {
      renderReportPage(addTab);
    } else {
      executeProcess(popupData?.processName);
    }
    tabNavigationState?.setTabNameInModalPopup(null);
  };

  const handleOnCancel = () => {
    tabNavigationState?.setOpenNewTabPopupModal(false);
    tabNavigationState?.setTabNameInModalPopup(null);
  };

  // const handleAlreadyOnOk = () => {
  //   tabNavigationState?.setAlreadyExistTabPopupModal(false);
  //   if (popupData?.processName === 'Dashboard') {
  //     showBody(addTab);
  //     tabNavigationState?.setActiveTab(popupData?.processName);
  //     tabNavigationState?.setTabNameInModalPopup(null);
  //   } else if (popupData?.processName === 'Reports') {
  //     renderReportPage(addTab);
  //     tabNavigationState?.setActiveTab(popupData?.processName);
  //     tabNavigationState?.setTabNameInModalPopup(null);
  //   } else if (popupData?.processName === 'Kagorithm') {
  //     renderKagorithmPage(addTab);
  //     tabNavigationState?.setActiveTab(popupData?.processName);
  //     tabNavigationState?.setTabNameInModalPopup(null);
  //   } else {
  //     executeProcess(popupData?.processName);
  //     tabNavigationState?.setAlreadyExistTabPopupModal(false);
  //     tabNavigationState?.setTabNameInModalPopup(null);
  //     tabNavigationState?.setActiveTab(popupData?.processName);
  //   }
  // };

  // const handleAlreadyOnCancel = () => {
  //   tabNavigationState?.setAlreadyExistTabPopupModal(false);
  //   tabNavigationState?.setTabNameInModalPopup(null);
  // };

  const closeFirstTabAfterTenTabs = (tabs: any) => {
    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          className={classes.MainDialogPaper}
          open={popupActive}
          aria-labelledby="responsive-dialog-title"
        >
          <CloseIcon className={classes.closeIcon} onClick={modalCloseHandler} />
          <DialogTitle style={{ color: '#006a7f' }} id="responsive-dialog-title">
            {'Warning'}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>
              <span>Are You Sure You Want To Close : </span>
              <span style={{ color: '#006a7f' }}>{tabs?.[1]?.name}</span>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="outlined" onClick={handleOnCancel} style={{ color: '#006a7f' }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleOnOk} style={{ color: '#fff', backgroundColor: '#006a7f' }}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const modalCloseHandler = () => {
    tabNavigationState.setOpenNewTabPopupModal(false);
  };

  // const tabAlreadyExistsModal = () => {
  //   return (
  //     <div>
  //       <Dialog fullScreen={fullScreen} open={alreadyExistPopup} aria-labelledby="responsive-dialog-title">
  //         <DialogTitle style={{ color: '#006a7f' }} id="responsive-dialog-title">
  //           {'Warning'}
  //         </DialogTitle>
  //         <Divider />
  //         <DialogContent>
  //           <DialogContentText>
  //             <span style={{ color: '#006a7f' }}>{popupData?.name} : </span>
  //             <span>Tab Is Already Opened, Do You Want To Redirect?</span>
  //           </DialogContentText>
  //         </DialogContent>
  //         <Divider />
  //         <DialogActions>
  //           <Button variant="outlined" onClick={handleAlreadyOnCancel} style={{ color: '#006a7f' }}>
  //             Cancel
  //           </Button>
  //           <Button
  //             variant="contained"
  //             onClick={handleAlreadyOnOk}
  //             style={{ color: '#fff', backgroundColor: '#006a7f' }}
  //           >
  //             Ok
  //           </Button>
  //         </DialogActions>
  //       </Dialog>
  //     </div>
  //   );
  // };

  const addTab = (process: any) => {
    let tabs = JSON.parse(JSON.stringify(tabNavigationState?.getTabs));
    tabNavigationState?.setTabs(tabs);
    if (process.name === 'Reports') {
      tabNavigationState?.setActiveTab(process?.name);
    } else {
      tabNavigationState?.setActiveTab(process?.name);
    }
  };

  const tabOnChangeHandler = (processName: string, name: string) => {
    tabExistAndContentFromState(processName, name);
  };

  const tabCloseHandler = (process: string) => {
    let previousTab: any;
    let nextTab: any;
    let breadcrumbModel: any;
    let activeModel: any;
    let processModel: KagamiProcessPageModel = getProcessPageModel();
    if (tabs?.length > 1) {
      if (process === activeTab) {
        let tabsArray = JSON.parse(JSON.stringify(tabNavigationState?.getTabs));
        let index = tabsArray?.findIndex((item: any) => item?.processName === process);
        if (index != -1) {
          if (index != 0) {
            previousTab = tabsArray[index - 1];
            let tabState = props?.tabNavigationModel?.tabState;
            breadcrumbModel = tabState?.[previousTab?.processName]?.breadcrumbModel;
            if (tabState?.hasOwnProperty(previousTab?.processName)) {
              activeModel = tabState?.[previousTab?.processName]?.activeModel;
              if (activeModel instanceof KagamiFormModel) {
                setFormModel(activeModel);
                renderPreviousTabFromState(processModel, breadcrumbModel, activeModel, tabState, previousTab);
              } else if (activeModel instanceof KagamiListModel) {
                setKagamiListModel(activeModel);
                renderPreviousTabFromState(processModel, breadcrumbModel, activeModel, tabState, previousTab);
              } else if (activeModel instanceof KagamiGroupModel) {
                setGroupModel(activeModel);
                renderPreviousTabFromState(processModel, breadcrumbModel, activeModel, tabState, previousTab);
              } else if (activeModel instanceof KagamiDashboardModel) {
                if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
                  setDashboardModel(activeModel);
                  renderPreviousTabFromState(processModel, breadcrumbModel, activeModel, tabState, previousTab);
                } else {
                  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
                    tabState?.[previousTab?.processName]?.content
                  );
                }
              } else if (activeModel instanceof ReportLandingPageModel) {
                if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
                  setLandingPageModel(activeModel);
                  renderPreviousTabFromState(processModel, breadcrumbModel, activeModel, tabState, previousTab);
                } else {
                  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
                    tabState?.[previousTab?.processName]?.content
                  );
                }
              }
              //  else if (activeModel instanceof KagamiMasterDetailModel) {
              //   if (
              //     CommonUtils.isNotEmpty(
              //       KagamiStateManager.getStateToHolder(
              //         SystemConstants.PROCESS_STATE
              //       )
              //     )
              //   ) {
              //     setMasterDetailModel(activeModel);
              //     if (processModel) {
              //       processModel.breadcrumbModel = breadcrumbModel;
              //       processModel.breadcrumbModel.showBreadCrumb = false;
              //     }
              //     setLhsContextModel(activeModel?.lhsContextModel);
              //     if (activeModel.openMasterForm) {
              //       setRhsContextModel(activeModel?.rhsContextModel);
              //     }
              //     loadProcessContent(
              //       tabState?.[previousTab?.processName]?.content
              //     );
              //   } else {
              //     KagamiStateManager.getStateToHolder(
              //       SystemConstants.BODY_STATE
              //     ).loadBodyContent(
              //       tabState?.[previousTab?.processName]?.content
              //     );
              //   }
              // }
              else {
                executeProcess(process);
                processTab(processModel, breadcrumbModel, activeModel);
              }
            } else if (previousTab?.name === 'Schedulers') {
              let content = <KagamiScheduler />;
              loadProcessContent(content);
            }
            tabNavigationState?.setActiveTab(previousTab?.processName);
            tabsArray.splice(index, 1);
            tabNavigationState?.setTabs(tabsArray);
            delete props?.tabNavigationModel?.tabState?.[process];
          } else {
            nextTab = tabsArray[index + 1];
            let tabState = props?.tabNavigationModel?.tabState;
            let breadcrumbModel: any = tabState?.[nextTab?.processName]?.breadcrumbModel;
            if (tabState?.hasOwnProperty(nextTab?.processName)) {
              activeModel = tabState?.[nextTab?.processName]?.activeModel;
              if (activeModel instanceof KagamiFormModel) {
                setFormModel(activeModel);
                renderNextTabFromState(processModel, breadcrumbModel, activeModel, tabState, nextTab);
              } else if (activeModel instanceof KagamiListModel) {
                setKagamiListModel(activeModel);
                renderNextTabFromState(processModel, breadcrumbModel, activeModel, tabState, nextTab);
              } else if (activeModel instanceof KagamiGroupModel) {
                setGroupModel(activeModel);
                renderNextTabFromState(processModel, breadcrumbModel, activeModel, tabState, nextTab);
              } else if (activeModel instanceof KagamiDashboardModel) {
                if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
                  setDashboardModel(activeModel);
                  renderNextTabFromState(processModel, breadcrumbModel, activeModel, tabState, nextTab);
                } else {
                  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
                    tabState?.[nextTab?.processName]?.content
                  );
                }
              } else if (activeModel instanceof ReportLandingPageModel) {
                if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
                  setLandingPageModel(activeModel);
                  renderNextTabFromState(processModel, breadcrumbModel, activeModel, tabState, nextTab);
                } else {
                  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
                    tabState?.[nextTab?.processName]?.content
                  );
                }
              }
              // else if (activeModel instanceof KagamiMasterDetailModel) {
              //   if (
              //     CommonUtils.isNotEmpty(
              //       KagamiStateManager.getStateToHolder(
              //         SystemConstants.PROCESS_STATE
              //       )
              //     )
              //   ) {
              //     setMasterDetailModel(activeModel);
              //     if (processModel) {
              //       processModel.breadcrumbModel = breadcrumbModel;
              //       processModel.breadcrumbModel.showBreadCrumb = false;
              //     }
              //     setLhsContextModel(activeModel?.lhsContextModel);
              //     if (activeModel.openMasterForm) {
              //       setRhsContextModel(activeModel?.rhsContextModel);
              //     }
              //     loadProcessContent(tabState?.[nextTab?.processName]?.content);
              //   } else {
              //     KagamiStateManager.getStateToHolder(
              //       SystemConstants.BODY_STATE
              //     ).loadBodyContent(tabState?.[nextTab?.processName]?.content);
              //   }
              // }
              else {
                executeProcess(process);
                processTab(processModel, breadcrumbModel, activeModel);
              }
            } else if (nextTab?.name === 'Schedulers') {
              let content = <KagamiScheduler />;
              loadProcessContent(content);
            }
            tabNavigationState?.setActiveTab(nextTab?.processName);
            tabsArray.splice(index, 1);
            tabNavigationState?.setTabs(tabsArray);
            delete props?.tabNavigationModel?.tabState?.[process];
          }
        }
      } else {
        delete props?.tabNavigationModel?.tabState?.[process];
        let tabsArray = JSON.parse(JSON.stringify(tabNavigationState?.getTabs));
        let index = tabsArray?.findIndex((item: any) => item?.processName == process);
        if (index != -1) {
          tabsArray.splice(index, 1);
          tabNavigationState?.setTabs(tabsArray);
        }
      }
    } else {
      tabNavigationState?.setTabs([]);
      executeProcess('');
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Tabs
        TabIndicatorProps={{
          style: {
            display: 'none'
          }
        }}
        scrollButtons
        variant="scrollable"
        allowScrollButtonsMobile
        aria-label="scrollable auto tabs example"
      >
        {tabs?.map((tab: any, i: number) => {
          return (
            <div
              className={`${classes.tabContainer} ${
                tab?.processName == activeTab ? classes.tabActive : classes.tabInActive
              } ${tab?.name === 'Dashboard' ? classes.dashBoardClass : ''}`}
              onClick={() => tabOnChangeHandler(tab?.processName, tab?.name)}
            >
              <Tab
                className={`${classes.tab} ${tab?.processName == activeTab ? classes.tabActive : classes.tabInActive}`}
                label={
                  <Tooltip title={tab?.name} arrow className={`${classes.ellipsis}`}>
                    <span>{tab?.name}</span>
                  </Tooltip>
                }
                disableRipple
              />
              {tab.name === 'Dashboard' ? null : (
                <HighlightOffIcon
                  className={classes.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    tabCloseHandler(tab?.processName);
                  }}
                />
              )}
            </div>
          );
        })}
        {}
        {closeFirstTabAfterTenTabs(tabs)}
        {/* {tabAlreadyExistsModal()} */}
      </Tabs>
    </div>
  );
};

export default buildTabs;

export const tabExistAndContentFromState = (processName: any, name: any) => {
  let tabNavigationModel = getTabNavigationModel();
  let tabState = tabNavigationModel.tabState;
  let processModel: KagamiProcessPageModel = getProcessPageModel();
  let dialogContent = getModalDialog();
  if (tabState?.hasOwnProperty(processName)) {
    let activeModel: any = tabState?.[processName]?.activeModel;
    let breadcrumbModel: any = tabState?.[processName]?.breadcrumbModel;
    let guid: string = tabState?.[processName]?.guid;
    setGuidForProcessTabs(guid);
    if (
      tabState?.[processName]?.activeModel.uiTemplate === 'modalDialog' &&
      guid === activeModel?.onStartData?.properties?.guid
    ) {
      loadProcessContent(<></>);
      buildModalDialogContent(dialogContent, activeModel);
    } else if (activeModel instanceof KagamiFormModel && guid === activeModel?.onStartData?.properties?.guid) {
      setFormModel(activeModel);
      updateActiveModel(activeModel);
      contentfromStateForTabs(processModel, breadcrumbModel, activeModel, tabState, processName);
    } else if (activeModel instanceof KagamiListModel && guid === activeModel?.onStartData?.properties?.guid) {
      setKagamiListModel(activeModel);
      activeModel.isListLoaded = true;
      updateActiveModel(activeModel);
      contentfromStateForTabs(processModel, breadcrumbModel, activeModel, tabState, processName);
    } else if (activeModel instanceof KagamiGroupModel && guid === activeModel?.onStartData?.properties?.guid) {
      setGroupModel(activeModel);
      updateActiveModel(activeModel);
      contentfromStateForTabs(processModel, breadcrumbModel, activeModel, tabState, processName);
    } else if (activeModel instanceof KagamiDashboardModel) {
      if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
        setDashboardModel(activeModel);
        updateActiveModel(activeModel);
        contentfromStateForTabs(processModel, breadcrumbModel, activeModel, tabState, processName);
      } else {
        KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
          tabState?.[processName]?.content
        );
      }
    } else if (activeModel instanceof ReportLandingPageModel) {
      if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
        setLandingPageModel(activeModel);
        updateActiveModel(activeModel);
        contentfromStateForTabs(processModel, breadcrumbModel, activeModel, tabState, processName);
      } else {
        KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
          tabState?.[processName]?.content
        );
      }
    }
    // else if (activeModel instanceof KagamiMasterDetailModel) {
    //   if (
    //     CommonUtils.isNotEmpty(
    //       KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE)
    //     )
    //   ) {
    //     setMasterDetailModel(activeModel);
    //     setLhsContextModel(activeModel?.lhsContextModel);
    //     if (processModel) {
    //       processModel.breadcrumbModel = breadcrumbModel;
    //       processModel.breadcrumbModel.showBreadCrumb = false;
    //     }

    //     if (activeModel.lhsContextModel instanceof KagamiListModel) {
    //       activeModel.lhsContextModel.isListLoaded = true;
    //     }
    //     if (activeModel.openMasterForm) {
    //       setRhsContextModel(activeModel?.rhsContextModel);
    //     }
    //     loadProcessContent(tabState?.[processName]?.content);
    //   } else {
    //     KagamiStateManager.getStateToHolder(
    //       SystemConstants.BODY_STATE
    //     ).loadBodyContent(tabState?.[processName]?.content);
    //   }
    // }
    else {
      executeProcess(processName);
      processTab(processModel, breadcrumbModel, activeModel);
    }
    tabNavigationModel?.state?.setActiveTab(processName);
  } else if (name === 'Schedulers') {
    let content = <KagamiScheduler />;
    loadProcessContent(content);
    tabNavigationModel?.state?.setActiveTab(processName);
  }
};

export const eopTrueAndNoDetailedObjectsForTabs = (
  process: string,
  tabNavigationState: any,
  tabNavigationModel: any
) => {
  let previousTab: any;
  let nextTab: any;
  let tabs = JSON.parse(JSON.stringify(tabNavigationState?.getTabs));
  let activeTab = JSON.parse(JSON.stringify(tabNavigationState?.getActiveTab));
  if (tabs?.length > 1) {
    if (process === activeTab) {
      let tabsArray = JSON.parse(JSON.stringify(tabNavigationState?.getTabs));
      let index = tabsArray?.findIndex((item: any) => item?.processName === process);
      if (index != -1) {
        if (index != 0) {
          previousTab = tabsArray[index - 1];
          let tabState = tabNavigationModel?.tabState;
          if (tabState?.hasOwnProperty(previousTab?.processName)) {
            let activeModel: any = tabState?.[previousTab?.processName]?.activeModel;
            if (activeModel instanceof KagamiFormModel) {
              setFormModel(activeModel);
              loadProcessContent(tabState?.[previousTab?.processName]?.content);
            } else if (activeModel instanceof KagamiListModel) {
              setKagamiListModel(activeModel);
              loadProcessContent(tabState?.[previousTab?.processName]?.content);
            } else if (activeModel instanceof KagamiGroupModel) {
              setGroupModel(activeModel);
              loadProcessContent(tabState?.[previousTab?.processName]?.content);
            } else if (activeModel instanceof KagamiDashboardModel) {
              if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
                setDashboardModel(activeModel);
                loadProcessContent(tabState?.[previousTab?.processName]?.content);
              } else {
                KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
                  tabState?.[previousTab?.processName]?.content
                );
              }
            } else if (activeModel instanceof ReportLandingPageModel) {
              if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
                setLandingPageModel(activeModel);
                loadProcessContent(tabState?.[previousTab?.processName]?.content);
              } else {
                KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
                  tabState?.[previousTab?.processName]?.content
                );
              }
            }
            // else if (activeModel instanceof KagamiMasterDetailModel) {
            //   if (
            //     CommonUtils.isNotEmpty(
            //       KagamiStateManager.getStateToHolder(
            //         SystemConstants.PROCESS_STATE
            //       )
            //     )
            //   ) {
            //     setMasterDetailModel(activeModel);
            //     setLhsContextModel(activeModel?.lhsContextModel);
            //     if (activeModel.openMasterForm) {
            //       setRhsContextModel(activeModel?.rhsContextModel);
            //     }
            //     loadProcessContent(
            //       tabState?.[previousTab?.processName]?.content
            //     );
            //   } else {
            //     KagamiStateManager.getStateToHolder(
            //       SystemConstants.BODY_STATE
            //     ).loadBodyContent(
            //       tabState?.[previousTab?.processName]?.content
            //     );
            //   }
            // }
            else {
              executeProcess(process);
            }
          }
          tabNavigationState?.setActiveTab(previousTab?.processName);
          tabsArray.splice(index, 1);
          tabNavigationState?.setTabs(tabsArray);
          delete tabNavigationModel?.tabState?.[process];
          buildtoastNotification('success', 'Cant Open Process As EOP Is True', 0);
        } else {
          nextTab = tabsArray[index + 1];
          let tabState = tabNavigationModel?.tabState;
          if (tabState?.hasOwnProperty(nextTab?.processName)) {
            let activeModel: any = tabState?.[nextTab?.processName]?.activeModel;
            if (activeModel instanceof KagamiFormModel) {
              setFormModel(activeModel);
              loadProcessContent(tabState?.[nextTab?.processName]?.content);
            } else if (activeModel instanceof KagamiListModel) {
              setKagamiListModel(activeModel);
              loadProcessContent(tabState?.[nextTab?.processName]?.content);
            } else if (activeModel instanceof KagamiGroupModel) {
              setGroupModel(activeModel);
              loadProcessContent(tabState?.[nextTab?.processName]?.content);
            } else if (activeModel instanceof KagamiDashboardModel) {
              if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
                setDashboardModel(activeModel);
                loadProcessContent(tabState?.[nextTab?.processName]?.content);
              } else {
                KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
                  tabState?.[nextTab?.processName]?.content
                );
              }
            } else if (activeModel instanceof ReportLandingPageModel) {
              if (CommonUtils.isNotEmpty(KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE))) {
                setLandingPageModel(activeModel);
                loadProcessContent(tabState?.[nextTab?.processName]?.content);
              } else {
                KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
                  tabState?.[nextTab?.processName]?.content
                );
              }
            }
            // else if (activeModel instanceof KagamiMasterDetailModel) {
            //   if (
            //     CommonUtils.isNotEmpty(
            //       KagamiStateManager.getStateToHolder(
            //         SystemConstants.PROCESS_STATE
            //       )
            //     )
            //   ) {
            //     setMasterDetailModel(activeModel);
            //     setLhsContextModel(activeModel?.lhsContextModel);
            //     if (activeModel.openMasterForm) {
            //       setRhsContextModel(activeModel?.rhsContextModel);
            //     }
            //     loadProcessContent(tabState?.[nextTab?.processName]?.content);
            //   } else {
            //     KagamiStateManager.getStateToHolder(
            //       SystemConstants.BODY_STATE
            //     ).loadBodyContent(tabState?.[nextTab?.processName]?.content);
            //   }
            // }
            else {
              executeProcess(process);
            }
          }
          tabNavigationState?.setActiveTab(nextTab?.processName);
          tabsArray.splice(index, 1);
          tabNavigationState?.setTabs(tabsArray);
          delete tabNavigationModel?.tabState?.[process];
          buildtoastNotification('success', 'Cant Open Process As EOP Is True', 0);
        }
      }
    } else {
      delete tabNavigationModel?.tabState?.[process];
      let tabsArray = JSON.parse(JSON.stringify(tabNavigationState?.getTabs));
      let index = tabsArray?.findIndex((item: any) => item?.processName == process);
      if (index != -1) {
        tabsArray.splice(index, 1);
        tabNavigationState?.setTabs(tabsArray);
      }
    }
  } else {
    tabNavigationState?.setTabs([]);
    executeProcess('');
  }
};

export const storingContentIntoStateForTabs = (
  currentProcessModel: any,
  content: any,
  tabNavigationModel: any,
  processResponseModel: any,
  isTodo: any,
  activeTab: any,
  referenceBreadCrumbModel: any
) => {
  let responseProcessName = processResponseModel?.processName;
  if (currentProcessModel.isMasterDetail) {
    if (currentProcessModel.fromDashboard) {
      let activeModel = content !== null ? Object.values(content.props)[0] : getActiveModel();
      tabNavigationModel.tabState = {
        ...tabNavigationModel.tabState,
        [responseProcessName]: {
          content: content,
          activeModel: activeModel,
          guid: processResponseModel?.onStartData?.properties?.guid,
          breadcrumbModel: referenceBreadCrumbModel
        }
      };
    }
  } else {
    if (isTodo) {
      let activeModel = getActiveModel();
      tabNavigationModel.tabState = {
        ...tabNavigationModel.tabState,
        [activeTab]: {
          content: content,
          activeModel: activeModel,
          guid: processResponseModel?.onStartData?.properties?.guid,
          breadcrumbModel: referenceBreadCrumbModel
        }
      };
    } else if (responseProcessName.includes(activeTab) || activeTab) {
      let isGroup: boolean = false;
      let activeModel = getActiveModel();
      if (activeModel instanceof KagamiListModel) {
        isGroup = activeModel.isGroupListModel;
      } else if (activeModel instanceof KagamiFormModel) {
        isGroup = activeModel.isGroupForm;
      }
      if (!isGroup) {
        tabNavigationModel.tabState = {
          ...tabNavigationModel.tabState,
          [activeTab]: {
            content: content,
            activeModel: activeModel,
            guid: processResponseModel?.onStartData?.properties?.guid,
            breadcrumbModel: referenceBreadCrumbModel
          }
        };
      }
    } else {
      let activeModel = getActiveModel();
      tabNavigationModel.tabState = {
        ...tabNavigationModel.tabState,
        [responseProcessName]: {
          content: content,
          activeModel: activeModel,
          guid: processResponseModel?.onStartData?.properties?.guid,
          breadcrumbModel: referenceBreadCrumbModel
        }
      };
    }
  }
};

export const contentfromStateForTabs = (
  processModel: any,
  breadcrumbModel: any,
  activeModel: any,
  tabState: any,
  processName: any
) => {
  if (processModel) {
    processModel.breadcrumbModel = breadcrumbModel;
  }
  processModel.activeModel = activeModel;
  setBreadcrumbsModel(processModel.breadcrumbModel);
  loadProcessContent(tabState?.[processName]?.content);
};

export const renderPreviousTabFromState = (
  processModel: any,
  breadcrumbModel: any,
  activeModel: any,
  tabState: any,
  previousTab: any
) => {
  if (processModel) {
    processModel.breadcrumbModel = breadcrumbModel;
  }
  processModel.activeModel = activeModel;
  setBreadcrumbsModel(processModel.breadcrumbModel);
  loadProcessContent(tabState?.[previousTab?.processName]?.content);
};

export const renderNextTabFromState = (
  processModel: any,
  breadcrumbModel: any,
  activeModel: any,
  tabState: any,
  nextTab: any
) => {
  if (processModel) {
    processModel.breadcrumbModel = breadcrumbModel;
  }
  processModel.activeModel = activeModel;
  setBreadcrumbsModel(processModel.breadcrumbModel);
  loadProcessContent(tabState?.[nextTab?.processName]?.content);
};

export const processTab = (processModel: any, breadcrumbModel: any, activeModel: any) => {
  if (processModel) {
    processModel.breadcrumbModel = breadcrumbModel;
  }
  processModel.activeModel = activeModel;
  setBreadcrumbsModel(processModel.breadcrumbModel);
};
