import { Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getTriggerName, translateToLocalistaion } from '../../../service/PresentationService';
import { buildListSearchBar } from '../../list/builder/searchbar/controller/KagamiListSearchController';
import { KagamiListSearchModel } from '../../list/builder/searchbar/model/KagamiListSearchModel';
import { getKagamiListModel } from '../../list/controller/KagamiListController';
import { buildProcessPageHeaderName } from '../../ProcessPageBuildler';
import { onTabChange } from '../controller/KagamiGroupController';
import { GroupModelProps,} from '../model/KagamiGroupModel';
import { KagamiGroupStyle } from '../style/KagamiGroupStyle';
import { getActiveModel } from '../../../controller/KagamiProcessController';

let useStyles = makeStyles(KagamiGroupStyle);

export const  TabWithCount = (props:any) => {
  let classes = useStyles();
  return (
  <>
  <span className= {`${classes}`}>{props.title} {props.count > 0  && props.show && 
  <b className= {`${classes.countBox}`}>{props.count}</b>}
  </span>
 </>
  )
}

export const GroupHeader = (props: GroupModelProps) => {
  let classes = useStyles();
  let listModel = getKagamiListModel();
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  const searchModel = new KagamiListSearchModel(listModel);
  let recordDataCount = props?.groupModel?.activeGroupModel?.totalRecords;

  const handleChange = (event: any, tabIndex: number) => {
     onTabChange(tabIndex, props.groupModel)  
  };

return (
  <>
  <div className= {`${classes.groupHeader}`}>
  {buildProcessPageHeaderName(props.groupModel)}
  {props.groupModel.state.showSearchBar ? buildListSearchBar(searchModel) :null}
  </div>   
   <Tabs
      classes={{
        root: classes.root,
        indicator: classes.indicator
      }}
      value={props.groupModel.state.activeIndex}
      centered={true}
      onChange={handleChange}
    >
    {props.groupModel.triggers.map((trigger: any , index:any) => {
          let triggerName = getTriggerName(trigger);
          const translatedName = translateToLocalistaion(translations, triggerName);
         return (
          <Tab label={<TabWithCount title={translatedName} count={recordDataCount} show={trigger.processName == props?.groupModel?.activeGroupModel?.processName && props.groupModel.state.activeIndex === index }  />} key={triggerName} />
        );
        })}
      </Tabs>	  
  </>
);
};





