import { SystemConstants } from '../../../../../constants/SystemConstants';
import { APIService } from '../../../../../service/ApiService';
import { getReportListURL } from '../../../../../service/URLService';
import { KagamiHeaderFavoritesModel } from '../model/kagamiHeaderFavorites';
// import { KagamiHomeHeaderModel } from '../model/KagamiPageHeaderModel';


// export function getNotificationData(kagamiHomeHeaderModel:KagamiHomeHeaderModel) {
//   const loginData = localStorage.getItem(SystemConstants.LOGIN_DATA);
//   if (loginData !== null) {
//     const loginObject = JSON.parse(loginData);
//     let notificationUrl = getNotificationURL() + '?userId=' + loginObject.userId;
  
//     APIService.getData(SystemConstants.GET, notificationUrl, {}).then((notificationResponse: any) => {
//       kagamiHomeHeaderModel.notificationModel.content = notificationResponse.data;
//       getReportsData(kagamiHomeHeaderModel.favoritesModel)
//     })
//     .catch((err:any) => {
//       console.log("failed to load dashboard data")
//     });
//   }
// }

let headerFavoritesModel: KagamiHeaderFavoritesModel;

export function getHeaderFavouritesModel () {
  return headerFavoritesModel;
}

function removeDuplicateObjectFromArray(array: any[], key: string | number) {
  return array.filter(
    (obj: { [x: string]: any }, index: any, self: any[]) =>
      index === self.findIndex((el: { [x: string]: any }) => el[key] === obj[key])
  );
}

export function getReportsData(kagamiHeaderFavoritesModel: KagamiHeaderFavoritesModel) {
  headerFavoritesModel = kagamiHeaderFavoritesModel;
  let reportDataUrl = getReportListURL();
  APIService.getData(SystemConstants.GET, reportDataUrl, {}).then((reportResponse: any) => {
    let favouriteReports: any[] = [];
    if(reportResponse.data?.respObject){
        favouriteReports = removeDuplicateObjectFromArray(
        reportResponse.data?.respObject.filter((ele: any) => ele.favourite),
        'id'
      );
    }
    kagamiHeaderFavoritesModel.content = favouriteReports
    kagamiHeaderFavoritesModel.state?.setOpen(true);
  }).catch((err:any)=>{
    console.error('KagamiPageHeaderService.ts - Failed to load report data for header.',err);
  });
}